import Axios from 'axios'

export default async function saveShare({ share, entity_id, onSave }) {
  const body = {
    authorized_shares: share.authorized_shares,
    certificates_required: share.certificates_required,
    class_type: share.class_type,
    currency: share.currency,
    director_approval_required: share.director_approval_required,
    has_restrictions: share.has_restrictions,
    issued_on: share.issued_on,
    class_name: share.class_name,
    value_per_share: share.value_per_share,
    restrictions: share.restrictions,
    rights_to_capital_on_winding_up:
      share.rights_to_capital_on_winding_up,
    rights_to_dividents: share.rights_to_dividents,
    voting_rights: share.voting_rights
  };

  if (share._state && share._state === "new") {
    // Create a new share class

    await Axios.post(`company/${entity_id}/share-class`, body)
      .then(() => {
        if (onSave) onSave();
      })
  } else {
    // Update a share class

    await Axios.put(
      `company/${entity_id}/share-class/${share.share_id}`,
      body
    )
      .then(() => {
        if (onSave) onSave();
      })
  }
};