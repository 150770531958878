import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";

export default function EntityConfigRoute(props) {
  const params = useParams();

  const [entityConfig, setEntityConfig] = useState({});
  const [newEntityConfig, setNewEntityConfig] = useState({});

  function handlePhoneChange(value) {
    setNewEntityConfig({ ...newEntityConfig, phone_number: value });
  }

  const setConfigField = e => {
    // TODO @cleanup @hack
    const value =
      e.target.type === "checkbox"
        ? e.target.checked
          ? "true"
          : "false"
        : e.target.value;
    setNewEntityConfig({ ...newEntityConfig, [e.target.name]: value });
  };

  const getConfig = () => {
    Axios.get(`company/${params.entity_id}/config`)
      .then(response => {
        if (response.data && response.data.company_config) {
          const config = {};
          const new_config = {};

          for (const item of response.data.company_config) {
            config[item.field_name] = item;
            new_config[item.field_name] = item.field_value;
          }

          setEntityConfig(config);
          setNewEntityConfig(new_config);
        }
      })
      .catch(() => { });
  };

  const [isSaving, setIsSaving] = useState(false)

  const saveConfig = () => {
    setIsSaving(true)
    Axios.post(`company/${params.entity_id}/config`, newEntityConfig)
      .then(response => {
        notify({ title: 'Success!', message: 'Configuration successfully saved!', type: "SUCCESS" })
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      .catch(() => { })
      .finally(() => {
        setIsSaving(false)
      });
  };

  // Get the config on mount
  useEffect(() => {
    if (!Object.keys(entityConfig).length) getConfig();
  }, []);

  let admin_user = null;

  if (props.basicEntityInfo.admin_users) {
    admin_user = props.basicEntityInfo.admin_users[0];
  }

  return (
    <div className="container px-0">
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header py-5">
            <div className="card-title w-50 ml-0">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Entity configuration
              </div>
              <div className="text-muted mt-3 font-weight-bold font-size-sm" />
            </div>
          </div>

          <div className="card-body config-container">
            <div className="field my-3">
              <div className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="override_esr_requirement"
                      onChange={setConfigField}
                      // value={ newEntityConfig.override_esr_requirement || false }
                      checked={
                        newEntityConfig.override_esr_requirement === "true"
                      }
                    />
                  }
                  label="Override ESR requirement"
                />
              </div>

              <div className="text-muted font-weight-semi-bold">
                If this checkbox is set, the Economic Substance Report task will
                be created for this entity no matter it's ESN filling.
              </div>

              {entityConfig.override_esr_requirement && (
                <div className="text-muted font-weight-semi-bold">
                  Last updated on{" "}
                  {entityConfig.override_esr_requirement.last_updated_at} by{" "}
                  {entityConfig.override_esr_requirement.user
                    ? entityConfig.override_esr_requirement.user.user_full_name
                    : "unknown"}
                </div>
              )}
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row justify-content-between">
              <div className="w-25" />
              <div>
                <button onClick={saveConfig} disabled={isSaving} className="btn btn-primary">
                  {isSaving ?
                    (<><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Saving...</>)
                    : 'Save'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
