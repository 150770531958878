import { IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

export default function SimpleModal({ title, onSave, children, onClose, open, disabled, size }) {
  const [loading, setLoading] = useState(false)

  const closeModal = (e) => {
    onClose(e)
  }

  const onSaveClick = (e) => {
    (async () => {
      try {
        setLoading(true)
        await onSave(e);
        closeModal(e)
        // notify({type: 'SUCCESS', title: notificationTitle, message: notificationMessage})
      } catch (error) {

      } finally {
        setLoading(false)
      }

    })();
  }

  return (
    <>
      <Modal
        size={size}
        show={open}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <IconButton onClick={closeModal}>
            <CloseIcon/>
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="btn-secondary">
            Cancel
          </Button>
          <Button disabled={loading || disabled} onClick={onSaveClick} className="d-flex align-items-center">
            {loading && (<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>)}
            {!loading && (<SaveIcon className="mr-2" />)}
            {!loading ? 'Save' : 'Saving...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}