import React, { useEffect, useState } from "react";
import EditProfileForm from "../../../components/EditProfileForm/EditProfileForm";
import { getHDDDocuments, getProfileSettings, getUserProfileLookups, saveUserProfileSettings } from "../../../api/user";
import { hddParse } from "../../../helpers/hddParse";
import * as yup from 'yup';
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions"
import { useSelector } from "react-redux";
import NationalitiesTable from "../../StaffOnboarding/pages/NationalitiesTable/NationalitiesTable";
import ProfileOverview from './OverviewRoute';
import useUnsaved from "../../../hooks/useUnsaved";
import LeaveBlocker from "../../../components/_common/LeaveBlocker";

export default function DetailsRoute(props) {
  const [prefixes, setPrefixes] = useState([])
  const [suffixes, setSuffixes] = useState([])
  const [regions, setRegions] = useState([])
  const [countries, setCountries] = useState([])
  const [genders, setGenders] = useState([])
  const [contactPreferences, setContactPreferences] = useState([])
  const [idTypes, setIdTypes] = useState([])
  const [ids, setIds] = useState(null)
  const [deletingNationalitiesIds, setDeletingNationalitiesIds] = useState([])

  const user = useSelector(state => state.auth.user)

  const [settings, setSettings] = useState({
    avatar: '',
    name_prefix: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    name_suffix: '',
    preferred_name: '',
    alias: '',
    email_address: '',
    phone_number: '',
    contact_preferences: '',
    date_of_birth: '',
    country_of_birth: '',
    gender: '',
  })

  const [nationalities, setNationalities] = useState([])
  const [nationalitiesIds, setNationalitiesIds] = useState([])

  const [ended, setEnded] = useState(false)

  useEffect(() => {
    getUserProfileLookups().then(response => {
      setPrefixes(Object.values(response.prefixes).map(v => ({
        value: v, title: v
      })))
      setSuffixes(Object.values(response.suffixes).map(v => ({
        value: v, title: v
      })))
      setRegions(response.jurisdiction_regions.map(region => ({
        value: region.region_id, title: region.region_title
      })))
      setCountries(Object.keys(response.countries).map(country_name => ({
        value: response.countries[country_name], title: country_name
      })))
      setGenders(Object.values(response.genders).map(v => ({
        value: v, title: v
      })))
      setContactPreferences(Object.values(response.contact_preferences).map(v => ({
        value: v, title: v
      })))
      setIdTypes(Object.keys(response.id_types).map(key => ({
        value: response.id_types[key], title: response.id_types_titles[key]
      })))
    })
    getProfileSettings().then(hdd_answers => {
      const [object, idObject, _nationalities, _nationalitiesIds] = hddParse(hdd_answers);
      setIds(idObject);
      setSettings(old => ({ ...old, ...object }))
      if (_nationalities[0]) _nationalities[0].is_primary = true
      setNationalities(_nationalities)
      setNationalitiesIds(_nationalitiesIds)

      return getHDDDocuments(user.user_id)
    })
      .then((documents) => {
        setNationalities(oldNationalities => {
          documents.forEach(doc => {
            const foundedNationality = oldNationalities.find(nationality => nationality.nationality_name === doc.document_country_of_issue)
            if (!foundedNationality) return;

            foundedNationality.supporting_documents_available = true
            foundedNationality.id_type = doc.document_type
            foundedNationality.id_number = doc.document_number
            foundedNationality.id_issue_date = doc.document_issue_date
            foundedNationality.id_expiry_date = doc.document_expiry_date
            foundedNationality.document_uploaded = true
            foundedNationality.document_id = doc.document_id
          })

          return [...oldNationalities]
        })

        setEnded(true)
      })
  }, [])

  const [unsaved, setUnsaved] = useUnsaved({ended}, [settings, nationalities])

  async function onSave(profileSettings) {
    await saveUserProfileSettings(profileSettings, ids, nationalities, deletingNationalitiesIds)
    await getUserProfileLookups()
    await getProfileSettings()

    setUnsaved(false)
    notify({ type: 'SUCCESS', message: 'Settings are saved!' })
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  const formOptions = [
    {
      name: 'avatar',
      title: 'Image / avatar',
      type: 'avatar',
    },
    {
      title: 'Name:',
      type: 'header'
    },
    {
      name: 'name_prefix',
      title: 'Prefix',
      type: 'select',
      options: prefixes,
    },
    {
      name: 'first_name',
      title: 'First Name',
      type: 'text',
      validation: () => yup.string().required('Cannot be empty!')
    },
    {
      name: 'middle_name',
      title: 'Middle Name',
      type: 'text'
    },
    {
      name: 'last_name',
      title: 'Last Name',
      type: 'text',
      validation: () => yup.string().required('Cannot be empty!')
    },
    {
      name: 'name_suffix',
      title: "Suffix",
      type: 'select',
      options: suffixes
    },
    {
      name: 'preferred_name',
      title: 'Preferred First Name (Nickname)',
      type: 'text'
    },
    {
      name: 'alias',
      title: 'Alias or previous names',
      type: 'text'
    },
    {
      name: 'Divider one',
      type: 'divider'
    },
    {
      title: 'Personal information:',
      type: 'header'
    },
    {
      name: 'email_address',
      title: 'Email address',
      type: 'text',
      validation: () => yup.string().email('Invalid email format').required('Cannot be empty!')
    },
    {
      name: 'phone_number',
      title: 'Phone number',
      type: 'phone',
      validation: () => yup.string().required('Cannot be empty!')
    },
    {
      name: 'contact_preferences',
      title: 'Contact Preferences',
      type: 'radio',
      options: contactPreferences
    },
    {
      name: 'Divider two',
      type: 'divider'
    },
    {
      name: 'date_of_birth',
      title: 'Date of Birth',
      type: 'date',
      validation: () => yup.date().required('Cannot be empty!')
    },
    {
      name: 'country_of_birth',
      title: 'Country of birth',
      type: 'country',
      options: countries,
      validation: () => yup.string().required('Cannot be empty!')
    },
    {
      name: 'gender',
      title: 'Gender',
      type: 'select',
      options: genders,
      validation: () => yup.string().required('Cannot be empty!')
    },
    {
      name: 'Divider three',
      type: 'divider'
    },
    {
      title: 'Identification:',
      type: 'header'
    },
    {
      name: 'nationalities',
      type: 'component',
      component: () => {
        return (
          <div className='col-12'>
            <NationalitiesTable
              deletingIds={deletingNationalitiesIds}
              setDeletingIds={setDeletingNationalitiesIds}
              nationalities={nationalities}
              setNationalities={setNationalities} />
          </div>
        )
      }
    }
  ]

  const [formValid, setFormValid] = useState({
    primary_nationality: true,
    secondary_nationalities: []
  })

  return (
    <>
    <LeaveBlocker unsavedExists={unsaved}/>
      <div className="col-lg-12">
        <ProfileOverview
          basicUserInfo={ props.basicUserInfo }
          additionalUserInfo={ props.additionalUserInfo }
          appendAdditionalInfo={ props.appendAdditionalInfo }
          queryAdditionalInfo={ props.queryAdditionalInfo }
        />
      </div>

      <div className="col-lg-12">
        <div className="card card-custom">
          <EditProfileForm
            formValid={formValid}
            value={settings}
            setValue={setSettings}
            options={formOptions}
            onFormSubmit={onSave}
            title={'Profile details'} />
        </div>
      </div>
    </>
  );
}
