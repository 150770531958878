/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from "react";

import useRequest from "../../../components/useRequest/useRequest";
import { getShareholdersFields } from "../../../api/company";
import _ from "lodash";

export default function useShareholdersFields(entity_category) {
  const [visibleFields, setVisibleFields] = useState({})
  const [allFields, setAllFields] = useState({})
  const [fetchShareholdersFields] = useRequest(getShareholdersFields, fields => {
    setAllFields(fields)
  })

  useEffect(() => {
    fetchShareholdersFields()
  }, [])

  useEffect(() => {
    if (entity_category && !_.isEmpty(allFields)) {
      const object = {};
      allFields[entity_category].forEach(field => object[field] = true)
      setVisibleFields(object)
    }
  }, [entity_category, allFields])

  return [visibleFields]
}