import React from 'react'
import BaseFieldsConfiguration from '../components/EditProfileForm/BaseFieldsConfiguration'
import SimpleModal from '../components/Modals/SimpleModal'
import useForm from '../hooks/useForm'
import { Table } from '../modules/Auth/pages/customers/customers-table/CustomersTable'
import AddIcon from '@material-ui/icons/Add';
import MoreDetailsDropdown from '../components/Dropdown/MoreDetailsDropdown'
import { simpleValidate } from '../helpers/validation'

export function useTable({ options, cols, abstractions, setAbstractions, defaultValue, modalTitle, cardTitle, limit, schema, disabled }) {

  const [current,
    modalOpened,
    setModalOpened,
    errors,
    editField,
    addAbstraction,
    editAbstraction,
    deleteAbstraction,
    saveAbstraction] = useForm(abstractions, setAbstractions, defaultValue, (current, setErrors) => simpleValidate(schema, current, setErrors))


  const restricted = (limit && abstractions?.length === limit) || disabled

  const tableCols = [
    ...cols,
    {
      extraData: disabled,
      name: 'actions',
      component: (row, rowIndex, disabled) => {
        return (
          <MoreDetailsDropdown options={
            [
              {
                id: 1,
                title: disabled ? 'Read' : 'Edit',
                onClick: () => editAbstraction(rowIndex)
              },
              {
                visible: !disabled,
                id: 2,
                title: 'Remove',
                onClick: () => deleteAbstraction(rowIndex)
              }
            ]
          } />
        )
      }
    }
  ]

  const component = (
    <>
      <SimpleModal disabled={disabled} onSave={() => saveAbstraction()} onClose={() => setModalOpened(false)} title={modalTitle} open={modalOpened} >
        {
          options.map(option => (
            <BaseFieldsConfiguration disabled={disabled} errors={errors} value={current} option={option} editTextFieldValue={(name, value) => editField(value, name)} />
          ))
        }
      </SimpleModal>
      <div className="card card-custom shadow-none">
        <div className="card-header">
          <div className="card-title">
            {cardTitle}
          </div>
          <div className="card-toolbar">
            <button disabled={restricted} onClick={() => {
              if (restricted) return;
              addAbstraction()
            }} className={`btn ${!restricted ? 'btn-primary' : 'btn-secondary cursor-disabled'} rounded-circle btn-circle-40`}>
              <AddIcon />
            </button>

          </div>
        </div>

        <div className="card-body">
          <Table nonSelectable cols={tableCols} entities={abstractions} />
        </div>
      </div>

    </>
  )

  return [component]
}