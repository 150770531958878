import { SET_AVAILABLE_CSPS, SET_AWAITING_RESULT, SET_CURRENT_CSP, SET_IS_AWAITING_CSP, SET_TENANT_SLUG, TRIGGER_CHECK_USER } from "./tenantActions";

export default function tenantReducer (state = {
  slug: null,
  csp: null,
  availableCSPs: [],
  awaitingResult: null,
  isAwaitingCSP: false,
  checkUserTrigger: null
}, action) {
  switch (action.type) {
    case SET_TENANT_SLUG : 
      return {...state, slug: action.payload.slug}
    case SET_CURRENT_CSP : 
      return {...state, csp: action.payload.csp}
    case SET_AVAILABLE_CSPS : 
      return { ...state, availableCSPs: action.payload.csps}
    case SET_AWAITING_RESULT : 
      return {...state, awaitingResult: action.payload}
    case SET_IS_AWAITING_CSP : 
      return {...state, isAwaitingCSP: action.payload}
    case TRIGGER_CHECK_USER : 
      return {...state, checkUserTrigger: action.payload}
    default : return state
  }
}