import React, { useEffect, useState } from 'react'
import Axios from "axios";
import { Button } from "react-bootstrap";
import MoreDetailsDropdown from '../../../components/Dropdown/MoreDetailsDropdown'
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable'

import CancelSharesModal from "../CancelSharesModal";

import SharesTransactionModal from "../../../components/SharesTransactionModal";
import AddShareholderModal from "../../../components/AddShareholderModal";

import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions"

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";

import { add_general_partner_category } from "../EntityProfilePage";
import ShareClassWindow from '../../create-company-page/components/share-class-window';
import saveShare from './api/saveShare';
import useRequest from '../../../components/useRequest/useRequest';
import useShareClasses from './hooks/useShareClasses';

const shareClassesNameMap = (data) => {
  const map = {
    share_authorized_shares: 'authorized_shares',
    share_certificates_required: 'certificates_required',
    share_class: 'class_type',
    share_currency: 'currency',
    share_director_approval_required: 'director_approval_required',
    share_has_restrictions: 'has_restrictions',
    share_id: 'share_id',
    share_issued_on: "issued_on",
    share_name: "class_name",
    share_nominal_value: 'value_per_share',
    share_outstanding_shares: 'outstanding_shares',
    share_restrictions: "restrictions",
    share_rights_to_capital_on_winding_up: "rights_to_capital_on_winding_up",
    share_rights_to_dividents: "rights_to_dividents",
    share_status: "status",
    share_voting_rights: 'voting_rights',
    _equity_percentage: '_equity_percentage',
    _issued_on: '_issued_on',
    _unissued_shares: '_unissued_shares'
  }
  const result = {}
  Object.keys(data).forEach(key => {
    result[map[key]] = data[key]
  })

  return result
}

const prepare_categories_for_backend = partnership_categories => {
  const final_categories = [];

  for (const category of partnership_categories) {
    if (category.category_id === -1) continue;

    final_categories.push({
      category_id: category.category_id,
      category_name: category.category_name
    });
  }

  return final_categories;
};

export default function OwnershipTypesRoute({ entity_id, category, ...props }) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const [selectedShare, setSelectedShare] = useState({ _state: "new" });
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const [editModalShown, setEditModalShown] = useState(false);
  const [cancelSharesModalShown, setCancelSharesModalShown] = useState(false);
  const [shareTransactionModalShown, setShareTransactionModalShown] = useState(false);
  const [addShareholderModalShown, setAddShareholderModalShown] = useState(false);

  const [partnershipCategories, setPartnershipCategories] = useState([]);

  const [awaiting, setAwaiting] = useState(false);
  const [unsavedChangesStatus, setUnsavedChangesStatus] = useState(false);

  const addPartnershipCategory = () => {
    setPartnershipCategories(partnership_categories => [...partnership_categories, { category_name: "New Category" }]);

    if (!unsavedChangesStatus) setUnsavedChangesStatus(true);
  }

  const onPartnershipCategoryRemove = (index, category_id) => {
    setAwaiting(true);

    return Axios.delete(`company/${props.basicEntityInfo.company_id}/partnership-categories/${category_id}`)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            notify({ type: "SUCCESS", title: "Category deleted", message: "Partnership category were successfully deleted" });

            const partnership_categories = add_general_partner_category(response.data.partnership_categories);

            props.setAdditionalEntityInfo(entity_info => ({
              ...entity_info,
              partnership_categories
            }));
          }
        }

        setAwaiting(false)
      })
      .catch(() => setAwaiting(false))
  }

  const onPartnershipCategoryUpdate = (e, index) => {
    const partnership_categories = partnershipCategories;

    partnership_categories[index][e.target.name] = e.target.value;

    if (!unsavedChangesStatus) setUnsavedChangesStatus(true);

    setPartnershipCategories([...partnership_categories]);
  }

  const savePartnershipCategories = () => {
    setAwaiting(true);

    Axios.post(`company/${props.basicEntityInfo.company_id}/partnership-categories`, {
      partnership_categories: prepare_categories_for_backend(partnershipCategories)
    })
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            notify({ type: "SUCCESS", title: "Categories saved", message: "Partnership categories were successfully saved" });

            const partnership_categories = add_general_partner_category(response.data.partnership_categories);

            props.setAdditionalEntityInfo(entity_info => ({
              ...entity_info,
              partnership_categories
            }));
          }
        }

        setUnsavedChangesStatus(false);
        setAwaiting(false)
      })
      .catch(() => setAwaiting(false))
  }

  const onShareUpdate = () => {
    // We have either created, cancelled or updated a share class. Close the edit modal and get the new shares

    setCancelSharesModalShown(false);
    setEditModalShown(false);

    // Get shares again (force)
    props.queryAdditionalInfo("shares", true);
  };

  const showCreateNewShareModal = () => {
    setSelectedShare({ _state: "new", share_currency: "USD" });
    setEditModalShown(true);
  };

  const showEditShareModal = selected_share => {
    setSelectedShare(shareClassesNameMap(selected_share));
    setEditModalShown(true);
  };

  const showCancelShareModal = selected_share => {
    setSelectedShare(selected_share);
    setCancelSharesModalShown(true);
  };

  const showTransferShareModal = (selected_share, transaction_type) => {
    setSelectedTransactionType(transaction_type);
    setSelectedShare(selected_share);
    setShareTransactionModalShown(true);
  };

  const onTransactionCreated = () => {
    setShareTransactionModalShown(false);
    props.queryAdditionalInfo("shares", true);
  };

  const cols = [
    {
      id: 1,
      name: 'share_name',
      title: 'Class',
      width: 250
    },
    {
      id: 2,
      name: '_issued_on',
      title: 'Issued on',
      width: 250
    },
    {
      id: 3,
      name: 'share_authorized_shares',
      title: 'Authorized shares',
      width: 250
    },
    {
      id: 4,
      name: 'share_outstanding_shares',
      title: 'Outstanding shares',
      width: 250
    },
    {
      id: 5,
      name: '_unissued_shares',
      title: 'Unissued shares',
      width: 250
    },
    {
      id: 6,
      name: 'share_nominal_value',
      title: 'Par Value',
      width: 250
    },
    {
      id: 7,
      name: 'share_voting_rights',
      title: 'Voting Rights',
      width: 250
    },
    {
      id: 8,
      name: 'share_status',
      title: 'Status',
      width: 250
    },
    {
      name: 'actions',
      component: (row) => {
        return (
          <MoreDetailsDropdown row={row} options={dropdown} />
        )
      }
    }
  ]

  const partnership_categories_columns = [
    {
      id: 1,
      name: 'category_name',
      title: 'Partnership Category Name',
      editable: (cell, row) => (row.category_id !== -1),
      width: 1000,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        onPartnershipCategoryUpdate(event, rowIndex);
      },
      component: (row) => {
        if (row.category_id !== -1) return row.category_name;
        else return (
          <div className="d-flex align-items-center">
            {row.category_name}

            <div className="alert alert-custom alert-light-info mb-0 ml-2 p-2 px-4">
              <div className="alert-text font-weight-bold">
                Special Category
              </div>
            </div>
          </div>
        )
      }
    },
    {
      id: 2,
      name: "actions",
      title: "Actions",
      width: 50,
      component: (row, rowIndex) => {
        if (row.category_id === -1) return <div></div>;

        return (
          <MoreDetailsDropdown
            options={[
              {
                id: 1,
                title: "Delete",
                icon: "/media/svg/icons/General/Trash.svg",
                onClick: () => onPartnershipCategoryRemove(rowIndex, row.category_id)
              },
            ]}
          />
        );
      }
    }
  ]

  const dropdown = [
    {
      id: 1,
      title: 'View details / edit',
      onClick: (e, share) => showEditShareModal(share)
    },
    {
      id: 2,
      title: 'Cancel shares',
      onClick: (e, share) => showCancelShareModal(share)
    },
    {
      id: 3,
      title: 'Issue Shares',
      onClick: (e, share) => showTransferShareModal(share, "issue")
    },
    {
      id: 4,
      title: 'Transfer Shares',
      onClick: (e, share) => showTransferShareModal(share, "transfer")
    },
    {
      id: 5,
      title: 'Buyback Shares',
      onClick: (e, share) => showTransferShareModal(share, "buyback")
    },
  ]

  useEffect(() => {
    if (props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      if (props.basicEntityInfo.company_category === "partnership") {
        props.queryAdditionalInfo("partnership_cap_table");
      } else {
        props.queryAdditionalInfo("shares");
      }

      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);



  useEffect(() => {
    if (props.additionalEntityInfo.partnership_categories) {
      setPartnershipCategories(props.additionalEntityInfo.partnership_categories);
    }
  }, [props.additionalEntityInfo.partnership_categories])


  const [shareClasses, ownershipTypes, visibleFields, currency, fetchShareTypes] = useShareClasses({
    category,
    entity_id
  })

  const [saveShareClass, saveShareClassLoading] = useRequest(async () => {
    await saveShare({ share: selectedShare, entity_id: props.basicEntityInfo.company_id })
    fetchShareTypes()
    setEditModalShown(false)
  })
  return (
    <>
      <ShareClassWindow
        modalOpened={editModalShown}
        setModalOpened={setEditModalShown}
        current={selectedShare}
        saveAbstraction={saveShareClass}
        editField={(name, value) => { setSelectedShare(old => { old[name] = value; return { ...old } }) }}
        loading={saveShareClassLoading}

        {...{
          visibleFields,
          shareErrors: {},
          ownershipTypes,
          currency,
        }} />

      <CancelSharesModal
        share={selectedShare}
        show={cancelSharesModalShown}
        onHide={state => setCancelSharesModalShown(state)}
        onCancel={onShareUpdate}
      />

      {(props.additionalEntityInfo && props.additionalEntityInfo.share_classes) && (
        <SharesTransactionModal
          type={selectedTransactionType}
          show={shareTransactionModalShown}
          onHide={state => setShareTransactionModalShown(state)}
          onSave={onTransactionCreated}
          shares={props.additionalEntityInfo.share_classes}
          selected_share={selectedShare}
          entity_id={props.basicEntityInfo.company_id}
          cap_table={props.additionalEntityInfo.cap_table}
        />
      )}

      <AddShareholderModal
        show={addShareholderModalShown}
        onHide={state => setAddShareholderModalShown(state)}
        entity_id={props.basicEntityInfo.company_id}
      />

      <div className="col-lg-12">
        <div className="card card-custom">

          <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Ownership Categories / Share Classes
              </div>
            </div>
            {props.basicEntityInfo.company_id && (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => setAddShareholderModalShown(true)}
                >
                  <span className="svg-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
                  </span>

                  Add a shareholder to this entity
                </Button>


                {/*
                Need clarification
                props.basicEntityInfo.company_category !== "partnership" && */ (
                    <Button
                      className="ml-2"
                      variant="primary"
                      onClick={showCreateNewShareModal}
                    >
                      Add a new share class
                    </Button>
                  )}
              </div>
            )}
          </div>

          {props.basicEntityInfo.company_category !== "partnership_ Need clarify" ? (
            <div className="card-body">
              {/* <DataTable columns={cols} rows={rows}/> */}
              {<Table nonSelectable entities={shareClasses} cols={cols} />}
            </div>
          ) : (
            <div className="card-body">
              {(props.additionalEntityInfo) ? (
                <Table
                  nonSelectable
                  cols={partnership_categories_columns}
                  idField="category_id"
                  entities={partnershipCategories}
                />
              ) : "Loading the data..."}

              <div className="mt-4 d-flex justify-content-between">
                <Button
                  className="ml-2"
                  variant="primary"
                  onClick={addPartnershipCategory}
                  disabled={awaiting}
                >
                  <span className="svg-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                  </span>

                  Add a partnership category
                </Button>
                <div className="d-flex align-items-center">
                  {unsavedChangesStatus && (
                    <div className="alert alert-custom alert-light-warning mb-0 mr-2 p-3 px-5">
                      <div className="alert-text font-weight-bold">
                        <span className="mr-2 svg-icon svg-icon-warning">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                        </span>

                        You have unsaved changes!
                      </div>
                    </div>
                  )}

                  <Button
                    className="ml-2"
                    variant="primary"
                    onClick={savePartnershipCategories}
                    disabled={awaiting}
                  >
                    <span className="svg-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")} />
                    </span>

                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
