export const availableQualifiers = {
	administrative_area_level_1: 'Administrative area level 1',
	administrative_area_level_2: 'Administrative area level 2',
	administrative_area_level_3: 'Administrative area level 3',
	administrative_area_level_4: 'Administrative area level 4',
	administrative_area_level_5: 'Administrative area level 5',
	archipelago: 'Archipelago',
	colloquial_area: 'Colloquial area',
	continent: 'Continent',
	country: 'Country',
	establishment: 'Establishment',
	finance: 'Finance',
	floor: 'Floor',
	food: 'Food',
	general_contractor: 'General contractor',
	geocode: 'Geocode',
	health: 'Health',
	intersection: 'Intersection',
	landmark: 'Landmark',
	locality: 'Locality',
	natural_feature: 'Natural feature',
	neighborhood: 'Neighborhood',
	place_of_worship: 'Place of worship',
	plus_code: 'Plus code',
	point_of_interest: 'Point of interest',
	political: 'Political',
	post_box: 'Post box',
	postal_code: 'Postal code',
	postal_code_prefix: 'Postal code prefix',
	postal_code_suffix: 'Postal code suffix',
	postal_town: 'Postal town',
	premise: 'Premise',
	room: 'Room',
	route: 'Route',
	street_address: 'Street address',
	street_number: 'Street number',
	sublocality: 'Sublocality',
	sublocality_level_1: 'Sublocality level 1',
	sublocality_level_2: 'Sublocality level 2',
	sublocality_level_3: 'Sublocality level 3',
	sublocality_level_4: 'Sublocality level 4',
	sublocality_level_5: 'Sublocality level 5',
	subpremise: 'Subpremise',
	town_square: 'Town square'
};