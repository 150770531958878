import React from "react";
import {selectOptions} from '../pages/StaffOnboarding/SelectOptions';

const CountrySelect = props => {
  const error = {borderColor: '#f018a6', padding: '18.5px 14px'}
  const helperText = { fontSize: '0.75rem', color: '#f018a6', marginLeft: 14, marginTop: 3}
  return (
    <>
      <select
        className="form-control h-auto py-5 px-6 my-2"
        style={props.error ? error : null}
        type="text"
        placeholder={ props.placeholder }
        name={ props.name }
        autoComplete="off"
        onBlur={ props.onBlur }
        onChange={ props.onChange }
        value={ props.value }
      >
        <option value="-1">{ props.placeholder }</option>
        {
          selectOptions.country.map(country => (
            <option key={country}>{ country }</option>)
          )
        }
      </select>
      {props.error && props.helperText && (
        <div style={helperText}>{props.helperText}</div>
      )}
    </>


  )
}

export default CountrySelect;
