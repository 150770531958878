import * as yup from 'yup'

export const EDDSchema = {
  business_activities_conducted_this_financial_year_check_all_that_apply: _ => ({
    validate() {
      if (!_.business_activities_conducted_this_financial_year_check_all_that_apply.length) {
        throw new Error('Select at least one option')
      }
    }
  }),
  revenue_expected_this_year_list: _ => ({
    validate() {
      if (_.revenue_expected_this_year && !_.revenue_expected_this_year_list.length) {
        throw new Error('Select at least one option')
      }
    }
  }),
  expenses_incurred_this_year_list: _ => ({
    validate() {
      if (_.expenses_incurred_this_year && !_.expenses_incurred_this_year_list.length) {
        throw new Error('Select at least one option')
      }
    }
  }),
  conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list: _ => ({
    validate() {
      if (
        _.conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies &&
        !_.conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list.length) {
        throw new Error('Select at least one option')
      }
    }
  }),
  is_government_organization: _ => yup.boolean().required(),
  specify_which_country: _ => _.is_government_organization === true && yup.string().required(),
  please_upload_copy_of_statuatory_evidence: _ => _.is_government_organization === true && ({
    validate() {
      if (!_.please_upload_copy_of_statuatory_evidence) {
        throw new Error('Please upload the document')
      }
    }
  }),
  former_name_s_trading_name_s_or_any_other_name_used_where_applicable: _ => yup.string(),
  management_decisions_occur_in: _ => yup.string().required(),
  description_of_business_activities: _ => yup.string().required(),
  initial_source_of_funds: _ => yup.string().required(),
  jurisdiction_originating: _ => yup.string().required(),
  revenue_expected_this_year: _ => yup.boolean().required(),
  expenses_incurred_this_year: _ => yup.boolean().required(),
  conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies: _ => yup.boolean().required(),
  entity_produces_financial_statements: _ => yup.string().required(),
  financials_audited: _ => yup.string().required(),
  accounting_service_provider: _ => yup.string().required(),
  contact_details_accounting_service_provider: _ => _.accounting_service_provider === 'yes' && yup.string().required(),
  legal_service_provider: _ => yup.string().required(),
  contact_details_legal_service_provider: _ => _.legal_service_provider === 'yes' && yup.string().required(),
}

export const eddTableSchema = {
  jurisdiction: _ => yup.string().required(),
  percent: _ => yup.string().required()
}