import React, { useEffect, useRef, useState } from 'react'

export default function ProfileAvatar({value, onChange = () => {}}) {
  const [avatarImage, setAvatarImage] = useState(null)
  const backgroundRef = useRef(null)

  const onFileChange = (e) => {
    const files = e.target.files;
    const file = files?.[0]

    if (!file) return;

    

    setAvatarImage(file)
  }

  useEffect(() => {
    const reader = new FileReader()

    reader.onloadend = () => {
      onChange(reader.result)
    }

    if (avatarImage) reader.readAsDataURL(avatarImage)
  }, [avatarImage])

  return (
  <div ref={backgroundRef} className="image-input image-input-empty image-input-outline" style={{backgroundImage: `url("${value}")`, backgroundPosition: 'center'}}>
    <div className="image-input-wrapper" />
    <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
      <i className="fa fa-pen icon-sm text-muted"/>
      <input type="file" onChange={onFileChange} name="profile_avatar" accept=".png, .jpg, .jpeg" />
      <input type="hidden" name="profile_avatar_remove" />
    </label>
    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title data-original-title="Cancel avatar">
      <i className="ki ki-bold-close icon-xs text-muted" />
    </span>
    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title data-original-title="Remove avatar">
      <i className="ki ki-bold-close icon-xs text-muted" />
    </span>
  </div>
)
}