import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import { Modal, Button } from "react-bootstrap";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';

function RequestedSignaturesModal(props) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  const onSelectionChange = selected => {
    setSelectedUsers(selected);
  };

  const getCompanyStakeholders = () => {
    Axios.get(`/company/${ props.entity_id }/stakeholders`).then(response => {
      const options = [];

      const all_staff = response.data.directors.concat(response.data.officers);

      for(const stakeholder of all_staff) {
        if(stakeholder.person_user_id && stakeholder.person_status === "active") {
          options.push({
            label: stakeholder.person_user ? stakeholder.person_user.user_full_name : (stakeholder.person_pending_full_name || stakeholder.staff_id),
            value: stakeholder.staff_id
          })
        }
      }
      
      setAvailableUsers(options);
    })
    .catch(() => {});
  }

  const onSave = () => {
    if(props.onSave) props.onSave(selectedUsers);
  }

  useEffect(() => {
    // Get the company users when we have the company id, the modal is shown (!) and we do not already have them
    if(props.entity_id && props.show && availableUsers.length === 0) getCompanyStakeholders();
  }, [props.entity_id, props.show]);
  
  useEffect(() => {
    if(props.requested_signers) {
      const selected_users = [];

      for(const requested_signer of props.requested_signers) {
        selected_users.push(requested_signer.signer_user_id);
      }

      setSelectedUsers(selected_users);
    }
  }, [props.requested_signers]);

  return (
    <Modal show={ props.show } onHide={ props.onHide }>
      <Modal.Header closeButton>
        <Modal.Title>Requested signatures</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Select users you require a signature from</div>

        <DualListBox
          showHeaderLabels
          canFilter
          className="ui-dual-listbox"
          options={availableUsers}
          selected={selectedUsers}
          onChange={onSelectionChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ () => props.onHide(false) }>
          Close
        </Button>
        <Button variant="primary" onClick={ onSave }>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RequestedSignaturesModal;
