import Axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function LinkPage(props) {
  const history = useHistory();

  const code = props.match.params.code;

  const getReferralLinkInfo = () => {
    Axios.get(`/csp/check-referral?code=${ code }`)
    .then((response) => {
      if(response.data && response.data.referral_info && response.data.referral_info[0]) {
        if(response.data.referral_info[0] === "create_company") {
          history.push(`/entity-formation?l=${ code }`);
        }
      }
    })
    .catch(() => {
      history.push("/");
    });
  }

  useEffect(() => {
    getReferralLinkInfo();
  }, []);

  return (
    <div></div>
  );
}
