import { useEffect, useState } from "react"
import { getRRNotes, saveRRNote } from "../../../../api/company"
import useRequest from "../../../../components/useRequest/useRequest"
import useUnsaved from "../../../../hooks/useUnsaved"

export default function useNotes({ entity_id }) {
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])

  const [saveComment, loadingSaveComment] = useRequest(async () => {
    await saveRRNote(entity_id, {
      note: comment
    })

    await fetchComments()
    setComment('')
    setUnsaved(false)
  })

  const [fetchComments, __, ended] = useRequest(() => getRRNotes(entity_id), c => {
    setComments(c)
    setUnsaved(false)
  })

  const [unsaved, setUnsaved] = useUnsaved({ ended }, [comments])

  useEffect(() => {
    fetchComments()
  }, [])

  return { comment, setComment, comments, saveComment, loadingSaveComment, fetchComments, unsavedNotes: unsaved, setUnsavedNotes: setUnsaved }
}