import { call, put, takeEvery, takeLatest } from "@redux-saga/core/effects";
import Axios from "axios";
import { deleteNotification } from "../../app/api/user";
import { DELETE_USER_NOTIFICATION, GET_ALL_USER_NOTIFICATIONS, GET_USER_NOTIFICATIONS_COUNT, READ_ALL_NOTIFICATIONS, SET_ALL_USER_NOTIFICATIONS, SET_USER_NOTIFICATIONS_COUNT } from "./userNotificationsActions";

function* countWorker() {
  const payload = yield call(Axios.get, '/user/user-notifications-count')

  const {
    notifications_count,
    new_notifications_count
  } = payload.data

  yield put({
    type: SET_USER_NOTIFICATIONS_COUNT,
    payload: {
      notifications_count,
      new_notifications_count
    }
  })
}

function* fetchWorker() {
  const payload = yield call(Axios.get, '/user/user-notifications')

  const {
    notifications
  } = payload.data

  yield put({
    type: SET_ALL_USER_NOTIFICATIONS,
    payload: {
      notifications
    }
  })
}

function* cleanWorker() {
  yield call(Axios.post, '/user/clean-user-notifications')

  yield put({
    type: GET_USER_NOTIFICATIONS_COUNT
  })

  yield put({
    type: GET_ALL_USER_NOTIFICATIONS
  })

}

function* deleteWorker(action) {
  yield call(deleteNotification, action.payload)

  yield put({
    type: GET_USER_NOTIFICATIONS_COUNT
  })
  
  yield put({
    type: GET_ALL_USER_NOTIFICATIONS
  })
}

export default function* userNotificationsSaga() {
  yield takeLatest(GET_USER_NOTIFICATIONS_COUNT, countWorker)

  yield takeEvery(GET_ALL_USER_NOTIFICATIONS, fetchWorker)

  yield takeEvery(READ_ALL_NOTIFICATIONS, cleanWorker)

  yield takeEvery(DELETE_USER_NOTIFICATION, deleteWorker)
}