import React, { useEffect, useState } from "react";
import EntityCreationSection, { entityCreationSchema } from "./entity-creation-section";
import useCountries from "../../../hooks/useCountries";
import { getCIMA } from "../../../api/ui";
import useTitles from "../../../hooks/useTitles";
import OverviewSection, { overviewValidationSchema } from "./overview-section";
import useJurisdictions from "../../create-company-page/hooks/useJurisdictions";
import RegulatorySection, { regulatoryValidationSchema } from "./regulatory-section";
import LicensesSection from "./licenses-section";
import SaveButton from "../../../components/_common/SaveButton";
import { getEntityBasicInfo, saveEntityProfile } from "../../../api/company";
import useRequest from "../../../components/useRequest/useRequest";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import { simpleValidate } from "../../../helpers/validation";
import useUnsaved from "../../../hooks/useUnsaved";
import LeaveBlocker from "../../../components/_common/LeaveBlocker";

export default function ProfileGeneralRoute({ entity_id, ...props }) {
  const [entityJurisdictions] = useJurisdictions()
  const [selectedJurisdiction, setSelectedJurisdiction] = useState({
    title: '',
    value: ''
  })
  const [cimas] = useTitles(getCIMA)
  const [countries] = useCountries()

  const [creationFlowObject, setCreationFlowObject] = useState({
    company_id: '',
    company_avatar: '',
    company_name: '',
    company_jurisdiction_id: '',
    company_category: '',
    company_type: '',
    company_formation_date: null,
    duration: '',
    company_status: '',
    company_primary_contact: '',
    company_financial_year_end: '',
    company_registration_number: '',
  })
  const [creationFlowErrors, setCreationFlowErrors] = useState({})


  useEffect(() => {
    const jurisdictionObject = entityJurisdictions.find(({ value }) => value === creationFlowObject.company_jurisdiction_id)
    if (jurisdictionObject) setSelectedJurisdiction(jurisdictionObject)
  }, [creationFlowObject.company_jurisdiction_id, entityJurisdictions])

  const [overviewObject, setOverviewObject] = useState({
    business_overview: "",
    website_domain: '',
    listed_on_recognized_stock_exchange: false,
    exchange_jurisdiction: '',
    stock_exchange: '',
    ticker_symbol: '',
    primary_industry: '',
    primary_sub_industry: [],
    entity_purpose: '',
    only_line_of_business: false,
    limited_partnership_name: '',
    registration_number: '',
    license_type: '-1',
    only_holding_shares: false,
    license_name: '',
  })
  const [overviewErrors, setOverviewErrors] = useState({})

  const [regulatoryObject, setRegulatoryObject] = useState({
    regulated_by_recognized_authority: "",
    regulatory_status: '',
    license_type: '',
    license_number: '',
    giin: '',
    fin: '',
    investment_manager_appointed: false,
    manager_location: '',
    manager_regulator: '',
    manager_approved_person: '',
  })
  const [regulatoryErrors, setRegulatoryErrors] = useState({})

  const [licenses, setLicenses] = useState([])

  const [submit, loading] = useRequest(() => {
    return saveEntityProfile(entity_id, creationFlowObject, overviewObject, regulatoryObject, licenses)
  }, () => {
    setUnsaved(false)
    notify({ type: "SUCCESS", title: 'Success', message: 'Profile successfully saved!' })
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  })

  const [fetchProfileInfo, __, ended] = useRequest(() => getEntityBasicInfo(entity_id), ({ entity_basic_info, overview_info, regulatory_info, licenses_info }) => {
    setCreationFlowObject(old => ({ ...old, ...entity_basic_info }))
    setOverviewObject(old => ({ ...old, ...overview_info }))
    setRegulatoryObject(old => ({ ...old, ...regulatory_info }))
    setLicenses(old => ([...old, ...licenses_info]))
  })

  const [unsaved, setUnsaved] = useUnsaved({ ended }, [creationFlowObject, overviewObject, regulatoryObject, licenses])

  useEffect(() => {
    fetchProfileInfo()
  }, [])

  async function onSubmit() {
    if (await simpleValidate(entityCreationSchema, creationFlowObject, setCreationFlowErrors) &
      await simpleValidate(overviewValidationSchema, overviewObject, setOverviewErrors) &
      await simpleValidate(regulatoryValidationSchema, regulatoryObject, setRegulatoryErrors)) {
      await submit()
    } else {
      notify({
        type: "ERROR",
        title: "Fill out all fields"
      })
    }
  }

  return (
    <>
      <LeaveBlocker unsavedExists={unsaved} />
      <div className="col-lg-12">
        <EntityCreationSection
          creationFlowErrors={creationFlowErrors}
          entityJurisdictions={entityJurisdictions}
          creationFlowObject={creationFlowObject}
          setCreationFlowObject={setCreationFlowObject}
          entity_id={entity_id} />

        <OverviewSection
          overviewErrors={overviewErrors}
          cimas={cimas}
          selectedJurisdiction={selectedJurisdiction}
          overviewObject={overviewObject}
          setOverviewObject={setOverviewObject}
          countries={countries} />

        <RegulatorySection
          regulatoryErrors={regulatoryErrors}
          regulatoryObject={regulatoryObject}
          setRegulatoryObject={setRegulatoryObject}
          selectedJurisdiction={selectedJurisdiction}
          cimas={cimas}
          countries={countries}
        />

        <LicensesSection licenses={licenses} setLicenses={setLicenses} countries={countries} />

        <div className="card gutter-t">
          <div className="card-body d-flex justify-content-end">
            <SaveButton loading={loading} onClick={onSubmit} />
          </div>
        </div>

      </div>
    </>
  );
}
