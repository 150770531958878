import Axios from 'axios'
import React from 'react'
import {useSelector} from 'react-redux';

export default function useJurisdictions() {
  const [jurisdictions, setJurisdictions] = React.useState([])
  const tenant = useSelector( state => state.tenant)
  const slug = tenant?.csp?.tenant?.tenant_slug;

  const getJurisdictionRegions = () => {
    return Axios.get('/company/jurisdiction-regions')
      .then(({ data }) => {
        setJurisdictions(data.jurisdiction_regions.map(({region_id, region_title}) => ({
          value: region_id,
          title: region_title
        })))
      })
  }

  React.useEffect(() => {
    // Send request as soon as tenant slug is defined;
    if (slug) getJurisdictionRegions();
  }, [slug]);

  return [jurisdictions]
}
