import React, { useEffect, useState } from "react";
import {
  Table
} from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import ActionsDropdown from "../../../components/Dropdown/ActionsDropdown";

import ActionTab from "../ActionTab";

const directorsCols = [
  {
    id: 1,
    name: "_name",
    title: "Name",
    width: 600
  },
  {
    id: 1,
    name: "_email",
    title: "Email address",
    width: 600
  },
  {
    id: 1,
    name: "person_status",
    title: "Status",
    width: 300
  },
];

const officersCols = [
  {
    id: 1,
    name: "_name",
    title: "Name",
    width: 600
  },
  {
    id: 1,
    name: "_email",
    title: "Email address",
    width: 600
  },
  {
    id: 1,
    name: "_position",
    title: "Position",
    width: 300
  },
  {
    id: 1,
    name: "person_status",
    title: "Status",
    width: 300
  },
];


export default function DirectorsOfficersRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const [selectedView, setSelectedView] = useState();

  const [selectedDirectorRows, setSelectedDirectorRows] = useState([]);
  const [selectedOfficerRows, setSelectedOfficerRows] = useState([]);

  const [selectedDirectors, setSelectedDirectors] = useState({});
  const [selectedOfficers, setSelectedOfficers] = useState({});

  const directors_dropdown = [
    {
      id: 1,
      title: "Add New",
      onClick: () => setView("add-director")
    },
    {
      id: 2,
      title: "Replace selected",
      onClick: () => setView("replace-director")
    },
    {
      id: 3,
      title: "Remove selected",
      onClick: () => setView("remove-director")
    },
  ];

  const officers_dropdown = [
    {
      id: 1,
      title: "Add New",
      onClick: () => setView("add-officer")
    },
    {
      id: 2,
      title: "Replace selected",
      onClick: () => setView("replace-officer")
    },
    {
      id: 3,
      title: "Remove selected",
      onClick: () => setView("remove-officer")
    },
  ];

  const setView = view => {
    const type = view.split("-")[1];

    if(type === "director") {
      // Create the array of selected directors
      const selected_directors = {};

      for(const index of selectedDirectorRows) {
        const director = props.entityStakeholders.directors[index];

        selected_directors[director.staff_id] = true;
      }

      setSelectedDirectors(selected_directors);
    }

    else if(type === "officer") {
      // Create the array of selected officers
      const selected_officers = {};

      for(const index of selectedOfficerRows) {
        const officer = props.entityStakeholders.officers[index];

        selected_officers[officer.staff_id] = true;
      }


      setSelectedOfficers(selected_officers);
    }

    setSelectedView(view);
  };

  const onFormLeave = () => {
    setSelectedView(null);
    // TODO Don't receive all the data, it's a waste
    // Does not query without force=true param // !??
    props.queryAdditionalInfo("stakeholders", true);
    props.queryAdditionalInfo("company_users", true);
  };

  useEffect(() => {
    if(props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      props.queryAdditionalInfo("stakeholders");
      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);

  return (
    <>
      <div className="col-lg-12">
        { (selectedView && props.entityStakeholders) ? (
          <div className="card card-custom">
            <div className="card-body">
              {/* Add a director */}
              {selectedView === "add-director" && (
                <ActionTab
                  action_type="add"
                  user_type="director"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.directors}
                  company_users={props.entityUsers}
                />
              )}

              {/* Remove a director */}
              {selectedView === "remove-director" && (
                <ActionTab
                  action_type="remove"
                  user_type="director"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.directors}
                  selected_stakeholders={selectedDirectors}
                />
              )}

              {/* Replace a director */}
              {selectedView === "replace-director" && (
                <ActionTab
                  action_type="replace"
                  user_type="director"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.directors}
                  selected_stakeholders={selectedDirectors}
                />
              )}

              {/* Add an officer */}
              {selectedView === "add-officer" && (
                <ActionTab
                  action_type="add"
                  user_type="officer"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.officers}
                  company_users={props.entityUsers}
                />
              )}

              {/* Remove an officer */}
              {selectedView === "remove-officer" && (
                <ActionTab
                  action_type="remove"
                  user_type="officer"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.officers}
                  selected_stakeholders={selectedOfficers}
                />
              )}
              
              {/* Replace an officer */}
              {selectedView === "replace-officer" && (
                <ActionTab
                  action_type="replace"
                  user_type="officer"
                  entity_id={props.basicEntityInfo.company_id}
                  onLeave={onFormLeave}
                  stakeholders={props.entityStakeholders.officers}
                  selected_stakeholders={selectedOfficers}
                />
              )}
            </div>
          </div>
        ) : (
          <>
          {(props.basicEntityInfo && props.basicEntityInfo.company_category !== "partnership") && (
            <div className="card card-custom mb-6">
              <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
                <div className="card-title w-50 ml-0 align-items-start flex-column">
                  <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Directors</div>
                </div>

                <div className="card-toolbar">
                  { props.entityStakeholders && (
                    <ActionsDropdown options={directors_dropdown} />
                  ) }
                </div>
              </div>

              <div className="card-body">
                { !selectedView && (
                  <>
                    { props.entityStakeholders ? (
                      <Table
                        selectedRows={ selectedDirectorRows }
                        setSelectedRows={ setSelectedDirectorRows }
                        entities={props.entityStakeholders.directors}
                        setEntities={() => void 0}
                        cols={directorsCols}
                      />
                    ) : "Loading the data..." }
                  </>
                ) }
              </div>
            </div>
          )}
            <div className="card card-custom">
              <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
                <div className="card-title w-50 ml-0 align-items-start flex-column">
                  <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Officers</div>
                </div>

                <div className="card-toolbar">
                  { props.entityStakeholders && (
                    <ActionsDropdown options={officers_dropdown} />
                  ) }
                </div>
              </div>

              <div className="card-body">
                { !selectedView && (
                  <>
                    { props.entityStakeholders ? (
                      <Table
                        selectedRows={ selectedOfficerRows }
                        setSelectedRows={ setSelectedOfficerRows }
                        entities={props.entityStakeholders.officers}
                        setEntities={() => void 0}
                        cols={officersCols}
                      />
                    ) : "Loading the data..." }
                  </>
                ) }
              </div>
            </div>
          </>
        ) }
      </div>
    </>
  );
}
