export const initialValues = {
	page1: {
		isCompany: false,
		former_names: '',
	},
	page2: {
		ex_c_manager_name: '',
		government_organisation_country : '',
		ex_a_jurisdiction: '',
		ex_a_stock_exchange: '',
		ex_a_ticker: '',
		ex_b_regulator: '',
		ex_b_regulatory_activity: '',
		ex_b_registration_number: '',
		is_government_organisation: false,
		is_pension_fund_or_other: false,
		is_ex_e1: false,
		is_ex_e2: false,
		is_ex_f: false,
		is_ex_a: false,
		is_ex_c: false,
		is_ex_b: false,
		ex_b_country: '',
		ex_e1_partnership_name: '',
		ex_e2_approved_person: '',
		ex_e2_partnership_name: '',
		ex_f_licensee_name: '',
	},
	page3: {
		purpose: '',
	}
};