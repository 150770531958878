import React, { useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const cayman = {
  countryCode: "1345",
  dialCode: "1345",
  format: "+. (...) ...-....",
  iso2: "ky",
  name: "Cayman Islands",
  priority: 0,
  regions: ['america']
}

export default function Phone(props) {
  const ref = React.useRef(null)

  useEffect(() => {
    ref.current.setState({
      onlyCountries: [...ref.current.state.onlyCountries,
        cayman
      ],
      preferredCountries: [
        cayman
      ],
    })
  }, [ref])

  return (
    <PhoneInput
      ref={ref}
      
      specialLabel=''
      inputClass="w-100 h-50"
      {...props}
    />
  )
}