import React, { useEffect, useState } from "react";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";

const historyCols = [
  {
    name: "_company_name",
    title: "Entity",
    width: 250
  },
  {
    name: "transaction_type",
    title: "Action"
  },
  {
    name: "_from",
    title: "From"
  },
  {
    name: "_to",
    title: "To"
  },
  {
    name: "transaction_number_of_shares",
    title: "Transaction size"
  },
  {
    name: "transaction_status",
    title: "Status"
  },
  {
    name: "_completed_on",
    title: "Completed on"
  }
];

const filters = [
  {
    id: 1,
    type: "dateRange",
    fieldName: "date",
    title: "Date"
  },
  {
    id: 2,
    type: "select",
    fieldName: "All",
    title: "select a jurisdiction ",
    options: [
      {
        id: 1,
        title: "Andrew"
      },
      {
        id: 2,
        title: "Joe"
      },
      {
        id: 3,
        title: "Axe"
      }
    ]
  },
  {
    id: 3,
    type: "select",
    fieldName: "All",
    title: "select an entity ",
    options: [
      {
        id: 1,
        title: "Andrew"
      },
      {
        id: 2,
        title: "Joe"
      },
      {
        id: 3,
        title: "Axe"
      }
    ]
  },
  {
    id: 4,
    type: "search"
  }
];

export default function HistoryRoute(props) {
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    if (
      props.basicUserInfo.user_id &&
      props.basicUserInfo.user_id !== currentUserId
    ) {
      props.queryAdditionalInfo("share_transactions");
      setCurrentUserId(props.basicUserInfo.user_id);
    }
  }, [props.basicUserInfo.user_id]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header d-flex border-0 justify-content-between">
            <div className="card-title m-0">
              <h3  style={{width: "300px"}} className="fw-bolder m-0">History</h3>
            </div>
          </div>

          <div className="card-body">

            {/*<div className="col-lg-12">*/}
            {/*  <DataTableFilter filters={filters} />*/}
            {/*</div>*/}

            <div className="col-lg-12">
              {props.additionalUserInfo.share_transactions && (
                  <Table nonSelectable cols={historyCols} entities={props.additionalUserInfo.share_transactions}/>
                  /*<Table
                      {...{
                        cols: historyCols,
                        entities: props.additionalUserInfo.share_transactions
                        title: ""

                      }}
                  />*/
              )}
            </div>

          </div>
        </div>
      </div>

    </>
  );
}
