export function hddParse(answers) {
  const object = {};

  const idObject = {}

  const primaryNationalities = [];
  const secondaryNationalities = [];

  const primaryNationalitiesIds = []
  const secondaryNationalitiesIds = []

  answers.forEach(answer => {
    if (answer.field_name === 'primary_nationality') {
      primaryNationalities.push({
        nationality_name: answer.field_value,
        supporting_documents_available: false,
        field_id: answer.id
      })
    } else if (answer.field_name.startsWith('nationality_')) {
      secondaryNationalities.push({
        nationality_name: answer.field_value,
        supporting_documents_available: false,
        field_id: answer.id
      })
    } else {
      idObject[answer.field_name] = answer.id;
      if (answer.field_is_json) {
        object[answer.field_name] = JSON.parse(answer.field_value)
      }
      else {
        object[answer.field_name] = answer.field_value
      }
    }
  })

  const nationalities = [...primaryNationalities, ...secondaryNationalities]
  const nationalitiesIds = [...primaryNationalitiesIds, ...secondaryNationalitiesIds]

  return [object, idObject, nationalities, nationalitiesIds];
}

export function hddRequest(object, idObject, nationalities, deletingNationalitiesIds) {
  const data = new FormData()
  const idObjectData = {}

  Object.keys(object).forEach(key => {
    if (key === 'primary_nationality'
      || key.startsWith('nationality_')
      || key === 'documents'
      || key === 'nationalities'
      || key === 'deleting_nationalities'
      || key.startsWith('file_')) return;

    if (object[key] === undefined) return
    data.append(key, object[key])
    idObjectData[key] = idObject[key]
  })

  data.append('deleting_nationalities', JSON.stringify(deletingNationalitiesIds))
  const file_descriptors = {};

  const documents = nationalities.map((nationality, index) => {
    if (nationality.is_primary) {
      data.append('primary_nationality', nationality.nationality_name)
      idObjectData['primary_nationality'] = nationality.field_id
    } else {
      data.append(`nationality_${index}`, nationality.nationality_name)
      idObjectData[`nationality_${index}`] = nationality.field_id
    }

    const fileName = `file_${index}`

    if (nationality.upload_copy_of_id) data.append(fileName, nationality.upload_copy_of_id)

    file_descriptors[index] = {
      scope: 'id',
      document_id: index
    };

    return {
      country_of_issue: nationality.nationality_name,
      document_expiry_date: nationality.id_expiry_date,
      document_number: nationality.id_number,
      document_issue_date: nationality.id_issue_date,
      document_type: nationality.id_type,
      document_filename: fileName,
      document_id: nationality.document_id
    }
  })

  data.append('documents', JSON.stringify(documents))

  data.append('ids', JSON.stringify(idObjectData))

  return data;
}