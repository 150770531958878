import React from 'react'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  rectangleButton: {
    background: "#595959",
    color: '#fff',
    padding: '14px 60px',
    fontSize: 16,
    border: 'none'
  }
})

export default function DashboardButton(props) {
  const styles = useStyles()
  return (
    <button className={styles.rectangleButton}>
      {props.children}
    </button>
  )
}