import React, { useState } from 'react'
import SimpleModal from '../../components/Modals/SimpleModal'
import EntityAddressAbstraction from './EntityAddressAbstraction'

export default function EntityAddressModal({
  defaultValue = {},
  submitSavingAddress = (currentAddress, setErrors) => { },
  currentAddress = {},
  setCurrentAddress = () => { },
  open = false,
  onClose = () => { }
}) {
  const [errors, setErrors] = useState({})

  return (
    <SimpleModal size='lg'
      open={open}
      onSave={() => submitSavingAddress(currentAddress, setErrors)}
      onClose={() => { onClose(); setCurrentAddress({ ...defaultValue }) }}
      title='Address'
    >
      <EntityAddressAbstraction errors={errors} currentAddress={currentAddress} setCurrentAddress={setCurrentAddress} />
    </SimpleModal>
  )
}