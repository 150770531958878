import React, { useState, useEffect } from 'react'
import Axios from "axios"
import ActionsDropdown from '../../../components/Dropdown/ActionsDropdown'
import { ExpandableTable, Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable'
import {Button, Modal} from "react-bootstrap";
import {notify} from "../../../../redux/NotificationSnackbar/snackbarActions";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";

const contextMenu = [
  {
    id: 1,
    title: 'View Details'
  },
  {
    id: 2,
    title: 'Edit Share Capital'
  },
  {
    id: 3,
    title: 'Issue Shares'
  },
  {
    id: 4,
    title: 'Transfer Shares'
  },
  {
    id: 5,
    title: 'Buyback Shares'
  },
  {
    id: 6,
    title: 'Generate Register'
  }
]

const cols = [
  {
    id: 1,
    name: 'name',
    title: "Name",
    width: 300
  },
  {
    id: 2,
    name: 'type',
    title: "Type",
    width: 200
  },
  {
    id: 3,
    name: 'shares_held',
    title: "Shares held",
    width: 200
  },
  {
    id: 4,
    name: 'ownership_percentage',
    title: "Ownership Percentage",
  },
  // {
  //   id: 5,
  //   name: 'row_action',
  //   component: () => {
  //     return (
  //       <MoreDetailsDropdown options={contextMenu} />
  //     )
  //   },
  //   width: 50
  // }
]

// const rows = [
//   {
//     id: 1,
//     shareholder_name: 'General Partner [Ownership Type 1]',
//     equity_interest: '45000',
//     ownership_percent: '75'
//   },
//   {
//     id: 2,
//     shareholder_name: 'Limited Partner [Ownership Type 2]',
//     equity_interest: '15,000',
//     ownership_percent: '25%'
//   },
//   {
//     id: 3,
//     shareholder_name: 'TOTAL',
//     ownership_percent: '100%'
//   }
// ]

const prepare_cap_table_for_backend = cap_table => cap_table.map(row => (
  {
    shareholder_id: row.shareholder_id,
    category_id: row.category_id,
    total_ownership: row.total_ownership,
    equity_interest: row.equity_interest,
  }
));

export default function OwnershipDistributionRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [partnershipCapTable, setPartnershipCapTable] = useState([{}]);
  const [partnershipCategoriesCleanList, setPartnershipCategoriesCleanList] = useState([]);

  const [awaiting, setAwaiting] = useState(false);
  const [unsavedChangesStatus, setUnsavedChangesStatus] = useState(false);

  useEffect(() => {
    if(props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      if(props.basicEntityInfo.company_category === "partnership") {
        props.queryAdditionalInfo("partnership_cap_table");
      } else {
        props.queryAdditionalInfo("shares");
      }

      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);

  const updateShareCapTable = () => {
    Axios.put(`company/${ props.basicEntityInfo.company_id }/update-share-cap`)
    .then(() => {
      props.queryAdditionalInfo("shares", true);
      notify({type: "SUCCESS", message:"Shares table is successfully updated", title:"Success"})
    })
    .catch(() => {});

  };

  const savePartnershipCapTable = () => {
    setAwaiting(true);

    Axios.post(`company/${ props.basicEntityInfo.company_id }/partnership-cap-table`, {
      cap_table: prepare_cap_table_for_backend(partnershipCapTable)
    })
    .then((response) => {
      if(response.data) {
        if(response.data.success) {
          setUnsavedChangesStatus(false);

          notify({ type: "SUCCESS", title: "Capitalization Table saved", message: "Capitalization table was successfully saved" });

          // TODO @performance @refactor We can return a new cap table from the backend on the POST call
          // without the need to call the API again (GET)
          props.queryAdditionalInfo("partnership_cap_table", true);
        }
      }
      setAwaiting(false)
    })
    .catch(() => setAwaiting(false))
  }

  const onCapTableUpdate = (name, value, index) => {
    const cap_table = partnershipCapTable;

    cap_table[index][name] = value;

    if(name === "category_id" && props.additionalEntityInfo.partnership_categories) {
      const category = props.additionalEntityInfo.partnership_categories.find(cat => cat.category_id + "" === value);

      if(category) {
        cap_table[index]._category_name = category.category_name;
      }
    }

    if(!unsavedChangesStatus) setUnsavedChangesStatus(true);
    setPartnershipCapTable([...cap_table]);
  }

  const dropdown = [
    {
      id: 1,
      title: 'Update Ownership distribution',
      onClick: () => setShowModal(true)
    },
  ]

  const partnership_capitalization_columns = [
    {
      id: 0,
      name: 'name',
      title: 'Partner',
      width: 200,
      component: (row) => (<>{ row.icon }{ row.name }</>)
    },
    {
      id: 1,
      name: 'category_id',
      title: 'Partnership Category',
      editable: true,
      editType: "select",
      editSelectOptions: partnershipCategoriesCleanList,
      width: 200,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        onCapTableUpdate("category_id", event.target.value, rowIndex);
      },
      component: (row) => row._category_name || row.category_id || "—"
    },
    {
      id: 2,
      name: 'total_ownership',
      title: 'Ownership percentage',
      editable: true,
      editType: "number",
      width: 150,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        onCapTableUpdate("total_ownership", event.target.value, rowIndex);
      },
      component: (row) => row.total_ownership ? `${ row.total_ownership }%` : "0"
    },
    {
      id: 3,
      name: 'equity_interest',
      title: 'Equity Interest',
      editable: true,
      editType: "number",
      width: 150,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        onCapTableUpdate("equity_interest", event.target.value, rowIndex);
      },
      component: (row) =>
        row.equity_interest ? `$${ row.equity_interest.toLocaleString() }` : "0"
    },
    // {
    //   id: 2,
    //   name: "actions",
    //   title: "Actions",
    //   width: 50,
    //   component: (row, rowIndex) => {
    //     return (
    //       <MoreDetailsDropdown
    //         options={[
    //           {
    //             id: 1,
    //             title: "Delete",
    //             // onClick: () => onPartnershipCategoryRemove(rowIndex, row.category_id)
    //           },
    //         ]}
    //       />
    //     );
    //   }
    // }
  ]

  useEffect(() => {
    if(props.additionalEntityInfo.partnership_cap_table) {
      const cap_table = props.additionalEntityInfo.partnership_cap_table.map(row => {
        const category = props.additionalEntityInfo.partnership_categories
        .find(cat => cat.category_id === row.customer_company_partnership_capitalization.category_id);

        let name;
        let type;
        let icon;

        if(row.user) {
          name = row.user.user_full_name;
          type = "human";
          icon =
            <span className="svg-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
          ;
        }
        else if(row.pc) {
          name = row.pc.pc_name;
          type = "pc";
          icon =
            <span className="svg-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
            </span>
          ;
        }
        else if(row.customer_company_available_shareholder
          && row.customer_company_available_shareholder.holder_company_name) {
            name = row.customer_company_available_shareholder.holder_company_name;
            type = "company";
            icon =
            <span className="svg-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
            </span>
            ;
        }

        return {
          ...row,

          name,
          type,
          icon,

          category_id: row.customer_company_partnership_capitalization.category_id,
          _category_name: category && category.category_name,

          total_ownership: row.customer_company_partnership_capitalization.total_ownership,
          equity_interest: row.customer_company_partnership_capitalization.equity_interest,
        }
      })

      setPartnershipCapTable(cap_table);
    }
  }, [props.additionalEntityInfo.partnership_cap_table])

  useEffect(() => {
    if(props.additionalEntityInfo.partnership_categories) {
      const clean_list = props.additionalEntityInfo.partnership_categories.map(category => ({
        value: category.category_id,
        title: category.category_name,
      }))

      setPartnershipCategoriesCleanList(clean_list)
    }
  }, [props.additionalEntityInfo.partnership_categories])

  return (
    <>
      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Ownership distribution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to update ownership distribution</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            NO
          </Button>
          <Button
              variant="primary"
              onClick={() => {
                updateShareCapTable()
                setShowModal(false)
              }}
          >
            YES
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="col-lg-12">
        <div className="card card-custom">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Ownership distribution</div>
          </div>
            <div className="card-toolbar">
                { props.basicEntityInfo.company_category && props.basicEntityInfo.company_category !== "partnership" && (
                  <ActionsDropdown options={dropdown} />
                ) }
            </div>
          </div>

          { (props.basicEntityInfo && props.basicEntityInfo.company_category !== "partnership") ? (
            <div className="card-body">
              { (props.additionalEntityInfo && props.additionalEntityInfo._share_class_groups) ? (
                <ExpandableTable
                  cols={cols}
                  entities={ props.additionalEntityInfo._share_class_group_definitions }
                  groups={ props.additionalEntityInfo._share_class_groups }
                />
              ) : "Loading the data..." }
            </div>
          ) : (
            <div className="card-body">
              <Table
                nonSelectable
                cols={partnership_capitalization_columns}
                idField="shareholder_id"
                entities={partnershipCapTable}
              />

              <div className="mt-4 d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  { unsavedChangesStatus && (
                    <div className="alert alert-custom alert-light-warning mb-0 mr-2 p-3 px-5">
                      <div className="alert-text font-weight-bold">
                        <span className="mr-2 svg-icon svg-icon-warning">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}/>
                        </span>

                        You have unsaved changes!
                      </div>
                    </div>
                  ) }

                  <Button
                    className="ml-2"
                    variant="primary"
                    onClick={savePartnershipCapTable}
                    disabled={ awaiting }
                  >
                    <span className="svg-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")}/>
                    </span>

                    Save
                  </Button>
                </div>
              </div>
            </div>
          ) }
        </div>
      </div>
    </>
  )
}
