import React, { useEffect, useState } from "react";
import moment from "moment";
import ProfileViewWidget from "../../../components/ProfileWiewVidget/ProfileViewWidget";

export default function ComplianceRoute(props) {
  const [items, setItems] = useState();
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    if(props.basicUserInfo.user_id && props.basicUserInfo.user_id !== currentUserId) {
      props.queryAdditionalInfo("share_transactions");
      setCurrentUserId(props.basicUserInfo.user_id)
    }
  }, [props.basicUserInfo.user_id]);

  // Basic user info was upadated
  useEffect(() => {
    if(!props.basicUserInfo || !props.basicUserInfo.dd) return;

    let dd_status;
    let dd_risk_rating = "N/A";
  
    if(!props.basicUserInfo.dd) dd_status = "Not completed";
    else if(!props.basicUserInfo.dd_csp_decision) dd_status = "Completed, unchecked";
    else if(props.basicUserInfo.dd_csp_decision) {
      dd_status = "Completed, checked";
      dd_risk_rating = props.basicUserInfo.dd_csp_decision.risk_category
    }
    else dd_status = "Unknown"
  
    setItems({
      RiskRating: [
        {
          name: "DD Status:",
          value: dd_status
        },
        {
          name: "Summary Risk Rating:",
          value: dd_risk_rating
        },
        {
          name: "Date Last Reviewed:",
          value: (props.basicUserInfo?.dd_csp_decision?.created_at
          && moment(props.basicUserInfo.dd_csp_decision.created_at).format("DD/MMM/yyyy")) || 'No data'
        },
        {
          name: "Next Review Date:",
          value: "@placeholder"
        }
      ],
      
      FactorsDetailed: [
        {
          name: "Geographic Risk:",
          value: "@placeholder"
        },
        {
          name: "Industry / Service Risk:",
          value: "@placeholder"
        },
        {
          name: "Political Exposure Risk (PEP):",
          value: "@placeholder"
        },
        {
          name: "Criminal Risk:",
          value: "@placeholder"
        },
        {
          name: "Financial Risk:",
          value: "@placeholder"
        }
      ]
    });
  }, [props.basicUserInfo]);

  return (
    <>
      <div className="col-lg-12">
        { items && (
          <div className="row">
            <div className=" h-50 col-lg-6">
              <ProfileViewWidget
                  title="Summary Risk Rating"
                  items={items.RiskRating}
              />
            </div>
            <div className="col-lg-6">
              <ProfileViewWidget
                  title="Risk Factors Detailed"
                  items={items.FactorsDetailed}
              />
            </div>
          </div>
        ) }
      </div>
      {/* <div className="col-lg-12">
        <WidgetTable
            {...{
              cols: notesCols,
              rows: notesRows,
              title: "Notes"
            }}
        />
      </div>
      <div className="col-lg-12">
        <ProfileViewWidget
            title="API Worldcheck Data"
            items={WorldcheckData_items}
        />
      </div> */}
    </>
  );
}
