import Axios from "axios";
import moment from "moment";

export const getCompanyPartnershipCategories = (entity_id) => {
  return Axios.get(`company/${ entity_id }/partnership-categories`)
  .then(response => {
    if(response.data && response.data.partnership_categories) {
      return response.data.partnership_categories
    }
  })
}

export const getShareClasses = async (entity_id) => {
  const response = await Axios.get(`company/${ entity_id }/share-classes`)
  if(response.data && response.data.share_classes) {
    const share_classes = response.data.share_classes.map(share_class => {
      const issued_on = moment(share_class.share_issued_on);

      share_class._unissued_shares = share_class.share_authorized_shares - share_class.share_outstanding_shares;
      share_class._issued_on = issued_on ? issued_on.format("DD/MMM/YYYY") : "N/A";

      share_class.share_issued_on = new Date(share_class.share_issued_on)
      .toJSON()
      .split("T", 1)[0];

      return share_class;
    });

    return share_classes
  }
}