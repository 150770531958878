import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import { injectIntl } from "react-intl";

const STATUS_COLORS = {
  approve: "label-light-success",
  approved: "label-light-success",
  reject: "label-light-danger",
  rejected: "label-light-danger"
};

function detailedInfo(approval_request) {
  if (approval_request.request_type === "shares_issue") {
    const initiator_name = approval_request.share_transaction.initiator_user
      ? approval_request.share_transaction.initiator_user.user_full_name
      : "unknown";
    const to_name = approval_request.share_transaction.transaction_to_name_hint || "unknown";
    const transaction_size =
      approval_request.share_transaction.transaction_number_of_shares;

    return (
      <>
        <div className="request-description">
          {initiator_name} has initiated an issuance of {transaction_size}{" "}
          shares to {to_name}.
        </div>

        <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
          <div className="container-title">Responses</div>

          {approval_request.approval_request_users.map(user => (
            <div className="pair">
              <div>{user.user.user_full_name}</div>
              <div>
                <div className={`label py-2 px-3 my-1 mr-1 label-inline`}>
                  {user.user_approval_type.replace(/_/g, " ")}
                </div>
                <div
                  className={`label py-2 px-3 my-1 label-inline ${
                    STATUS_COLORS[user.response]
                  }`}
                >
                  {user.response || "pending"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  } else if (approval_request.request_type === "shares_transfer") {
    const initiator_name = approval_request.share_transaction.initiator_user
      ? approval_request.share_transaction.initiator_user.user_full_name
      : "unknown";
    const from_name = approval_request.share_transaction.transaction_from_name_hint || "unknown";
    const to_name = approval_request.share_transaction.transaction_to_name_hint || "unknown";
    const transaction_size =
      approval_request.share_transaction.transaction_number_of_shares;

    return (
      <>
        <div className="request-description">
          {initiator_name} has initiated a transfer of {transaction_size} shares
          from {from_name} to {to_name}.
        </div>

        <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
          <div className="container-title">Responses</div>

          {approval_request.approval_request_users.map(user => (
            <div className="pair">
              <div>{user.user.user_full_name}</div>
              <div>
                <div className={`label py-2 px-3 my-1 mr-1 label-inline`}>
                  {user.user_approval_type.replace(/_/g, " ")}
                </div>
                <div
                  className={`label py-2 px-3 my-1 label-inline ${
                    STATUS_COLORS[user.response]
                  }`}
                >
                  {user.response || "pending"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  } else if (approval_request.request_type === "shares_buyback") {
    const initiator_name = approval_request.share_transaction.initiator_user
      ? approval_request.share_transaction.initiator_user.user_full_name
      : "unknown";
    const from_name = approval_request.share_transaction.from_user
      ? approval_request.share_transaction.from_user.user_full_name
      : "unknown";
    const transaction_size =
      approval_request.share_transaction.transaction_number_of_shares;

    return (
      <>
        <div className="request-description">
          {initiator_name} has initiated a buyback of {transaction_size} shares
          from {from_name}.
        </div>

        <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
          <div className="container-title">Responses</div>

          {approval_request.approval_request_users.map(user => (
            <div className="pair">
              <div>{user.user.user_full_name}</div>
              <div>
                <div className={`label py-2 px-3 my-1 mr-1 label-inline`}>
                  {user.user_approval_type.replace(/_/g, " ")}
                </div>
                <div
                  className={`label py-2 px-3 my-1 label-inline ${
                    STATUS_COLORS[user.response]
                  }`}
                >
                  {user.response || "pending"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

function ApprovalRequestsPage(props) {
  const { intl } = props;
  const history = useHistory();

  const [approvalRequests, setApprovalRequests] = useState([]);

  const getApprovalRequests = () => {
    Axios.get("user/approval-requests")
      .then(({ data }) => {
        setApprovalRequests(data.approval_requests);
      })
      .catch(() => {});
  };

  const approveRequest = request_id => {
    Axios.put(`/approval-request/${request_id}/approve`)
      .then(() => {
        // TODO @performance
        getApprovalRequests();
      })
      .catch(() => {});
  };

  const rejectRequest = request_id => {
    Axios.put(`/approval-request/${request_id}/reject`)
      .then(() => {
        // TODO @performance
        getApprovalRequests();
      })
      .catch(() => {});
  };

  useEffect(() => {
    getApprovalRequests();
  }, []);

  return (
    <div className="card card-custom card-stretch gutter-b pb-5">
      <div className="card-header border-0 py-5">
        <div className="card-title w-50 ml-0 align-items-start flex-column">
          <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Approval requests</div>
          <div className="text-muted mt-3 font-weight-bold font-size-sm">All approval requests that you are an approver in</div>
        </div>
      </div>
      <div className="card-body pt-3 pb-0">
        {approvalRequests.map(item => (
          <div className="ui-approval-request card px-5 py-4 mt-4">
            <div className="left">
              <div className="request-name">
                {intl.formatMessage({
                  id: `APPROVAL_REQUEST.NAME.${item.request_type}`
                })}
              </div>

              {detailedInfo(item)}
            </div>
            <div className="right">
              {item.request_status === "active" ? (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => approveRequest(item.request_id)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => rejectRequest(item.request_id)}
                  >
                    Reject
                  </button>
                </>
              ) : (
                <div
                  className={`label label-xl p-6 w-100 label-inline mt-5  text-capitalize font-weight-bolder ${
                    STATUS_COLORS[item.request_status]
                  }`}
                >
                  {item.request_status}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default injectIntl(ApprovalRequestsPage);
