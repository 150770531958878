import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import ActionsDropdown from "../../../components/Dropdown/ActionsDropdown";
import ActionTab from "../ActionTab";
import { FormInput } from "../../../components/Basic";
import { Button } from "react-bootstrap";
import SaveButton from "../../../components/_common/SaveButton";
import useRequest from "../../../components/useRequest/useRequest";

export default function OtherRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedOtherPerson, setSelectedOtherPerson] = useState({});

  const [selectedView, setSelectedView] = useState();

  useEffect(() => {
    if (props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      props.queryAdditionalInfo("stakeholders");
      props.queryAdditionalInfo("company_users");

      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);

  const showOtherEditView = other_person => {
    setSelectedOtherPerson(other_person || {});
    setSelectedView("edit-other");
  };

  const showOtherAddView = () => {
    setSelectedOtherPerson({});
    setSelectedView("add-other");
  };

  const updateOtherPersonInfo = e => {
    setSelectedOtherPerson({
      ...selectedOtherPerson,
      [e.target.name]: e.target.value
    });
  };

  const [updateOtherUser, loading] = useRequest(() => Axios.put(`/company/${props.basicEntityInfo.company_id}/other-person`, selectedOtherPerson).then(
    response => {
      setSelectedView(null);

      // TODO @performance @refactor. We get the whole list of ALL the stakeholders + owners + log. This is very dumb
      props.queryAdditionalInfo("stakeholders", true);
    }
  ))

  const removeOthers = selected_person => {
    let selected_person_ids = [];

    if (selected_person && selected_person.person_id) {
      selected_person_ids = [selected_person.person_id];
    } else {
      for (const index of selectedRows) {
        const person = props.entityStakeholders.other_staff[index];

        if (person) selected_person_ids.push(person.person_id);
      }
    }

    Axios.post(`/company/${props.basicEntityInfo.company_id}/remove-other-persons`, {
      selected_persons: selected_person_ids
    }).then(response => {
      // TODO @performance @refactor. We get the whole list of ALL the stakeholders + owners + log. This is very dumb
      props.queryAdditionalInfo("stakeholders", true);
    });
  };

  const onFormLeave = () => {
    setSelectedView(null);
    // TODO Don't recieve all the data, it's a waste
    props.queryAdditionalInfo("stakeholders", true);
  };

  const stakeholdersCols = [
    {
      id: 1,
      name: "person_full_name",
      title: "Name",
      width: 600
    },
    {
      id: 2,
      name: "person_role",
      title: "Role",
      width: 300
    },
    {
      id: 3,
      name: "person_email_address",
      title: "Email address",
      width: 300
    },
    {
      id: 4,
      name: "person_telephone_number",
      title: "Phone number",
      width: 300
    },
    {
      id: 5,
      name: "person_location",
      title: "Location",
      width: 300
    },
    {
      name: "actions",
      component: row => {
        return <MoreDetailsDropdown options={stakeholderActions} row={row} />;
      }
    }
  ];

  const globalActions = [
    {
      id: 1,
      title: "Add New",
      onClick: showOtherAddView
    },
    {
      id: 2,
      title: "Remove selected",
      onClick: removeOthers
    }
  ];

  const stakeholderActions = [
    {
      id: 1,
      title: "Edit",
      onClick: (e, person) => showOtherEditView(person)
    },
    {
      id: 2,
      title: "Remove",
      onClick: (e, person) => removeOthers(person)
    },
  ];

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">Stakeholders</div>
            </div>

            <div className="card-toolbar">
              {!selectedView && (
                <ActionsDropdown options={globalActions} />
              )}
            </div>
          </div>

          {/* Move to a component? */}
          <div className="card-body">
            {/* Add/Edit "other" */}
            {(selectedView === "add-other" || selectedView === "edit-other") && (
              <div id="edit-other" style={{ width: "50%", margin: "0 auto" }}>
                <FormInput
                  w-title
                  placeholder="Name"
                  type="text"
                  name="person_full_name"
                  onChange={updateOtherPersonInfo}
                  value={selectedOtherPerson.person_full_name}
                />

                <FormInput
                  w-title
                  placeholder="Role"
                  type="text"
                  name="person_role"
                  onChange={updateOtherPersonInfo}
                  value={selectedOtherPerson.person_role}
                />

                <FormInput
                  w-title
                  placeholder="Email address"
                  type="email"
                  name="person_email_address"
                  onChange={updateOtherPersonInfo}
                  value={selectedOtherPerson.person_email_address}
                />

                <FormInput
                  w-title
                  placeholder="Phone number"
                  type="text"
                  name="person_telephone_number"
                  onChange={updateOtherPersonInfo}
                  value={selectedOtherPerson.person_telephone_number}
                />

                <FormInput
                  w-title
                  placeholder="Location"
                  type="text"
                  name="person_location"
                  onChange={updateOtherPersonInfo}
                  value={selectedOtherPerson.person_location}
                />

                <div className="d-flex justify-content-between mb-3 mt-8">
                  <Button onClick={() => setSelectedView(null)}>Back</Button>
                  <SaveButton color="primary" loading={loading} onClick={updateOtherUser}>
                    Save
                  </SaveButton>
                </div>
              </div>
            )}

            {/* Remove "other" */}
            {selectedView === "remove-other" && (
              <ActionTab
                action_type="remove"
                user_type="other"
                entity_id={props.basicEntityInfo.company_id}
                onLeave={onFormLeave}
                stakeholders={props.entityStakeholders.directors}
                company_users={props.entityUsers}
              />
            )}

            {!selectedView && (
              <>
                {(props.entityStakeholders) ? (
                  <Table
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setEntities={() => void 0}
                    entities={props.entityStakeholders.other_staff}
                    cols={stakeholdersCols}
                  />
                ) : "Loading the data..."}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
