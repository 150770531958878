import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { downloadFile } from '../../util';
import DocumentModal from '../components/DocumentModal';
import SignModal from '../components/SignModal';
import FileBox from '../components/FileBox';
import RequestedSignaturesModal from '../components/RequestedSignaturesModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { AnnualReturnValidated, EsnValidated } from './TaskPage/TaskStates';
import { Table } from '../modules/Auth/pages/customers/customers-table/CustomersTable';
import moment from 'moment/moment';
import ProfileViewWidget from '../components/ProfileWiewVidget/ProfileViewWidget';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import { BrowseFile, FormInput, FormSelect } from '../components/Basic';
import { useSelector } from "react-redux";
import { notify } from '../../redux/NotificationSnackbar/snackbarActions';
import ActionsDropdown from '../components/Dropdown/ActionsDropdown';
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { updateTask } from '../api/task';
import { getDocument } from '../api/document';
import EddReview from './TaskPage/EddReview';
import { CustomDialog } from '../components/CustomControllers';
import TaskTitle from './TaskPage/components/TaskTitle';
import EDDStatusTaskPage from './TaskPage/components/EDDStatus';
import InitialSubscriberTaskPage from './TaskPage/components/InitialSubscriber';
import useShareholders from './TaskPage/hooks/useShareholders'
import ShareholdersTaskPage from './TaskPage/components/ShareHolders';
import AssignationTaskPage from './TaskPage/components/Assignation';

// Task status colors
const STATUS_COLORS = {
  new: '',
  rejected: 'label-light-danger',
  approved: 'label-light-info',
  reviewed: 'label-light-info',
  ready_to_file: 'label-light-success',
  filed: 'label-light-success'
};

// Status colors for stuff like stakeholder and share classes
const OTHER_STATUS_COLORS = {
  cancelled: 'text-danger',
  removed: 'text-danger',
  pending: 'text-warning',
  active: 'text-success'
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function ActionConfirmationModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Action confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to execute this action?</div>
        {props.action_description && <div className="mt-3">{props.action_description}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.executeAction(props.action_type)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function GenerateDocumentModal(props) {
  const [templates, setTemplates] = useState([]);
  const [areSignaturesRequested, setAreSignaturesRequested] = useState(false);
  const [documentInfo, setDocumentInfo] = useState({
    document_title: '',
    document_description: '',
    template: {
      id: '',
      name: ''
    }
  });

  function setDocumentField(field, value) {
    setDocumentInfo(prev => {
      return {
        ...prev, [field]: value
      }
    })
  }

  const [documentIsGenerating, setDocumentIsGenerating] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  const onSelectionChange = selected => {
    setSelectedUsers(selected);
  };

  const getAvailableSigners = () => {
    Axios.get(`/company/users/${props.entity_id}`).then(response => {
      const options = [];

      for (const user of response.data.users) {
        options.push({
          label: user.user_full_name,
          value: user.user_id
        });
      }

      setAvailableUsers(options);
    })
      .catch(() => { });
  }

  const getTemplates = () => {
    Axios.get(`/csp/templates`).then(response => {
      setTemplates(response.data.csp_templates);
      getAvailableSigners();
    });
  };

  useEffect(() => {
    if (props.show && templates.length === 0) getTemplates();
  }, [props.show])

  const generateDocument = async () => {
    try {
      setDocumentIsGenerating(true);

      await Axios.post(`/task/${props.task_id}/generate-document`, {
        template_id: documentInfo.template.id,
        document_title: documentInfo.document_title || documentInfo.template.name,
        document_description: documentInfo.document_description || undefined,
        requested_signers: areSignaturesRequested ? selectedUsers : undefined
      });

      await props.getTask();

      props.onHide();

      notify({ type: 'SUCCESS', title: 'Document generated', message: `Document has been successfully generated.${areSignaturesRequested ? " All the requested signers will be messaged shortly" : ""}` });

      if (!areSignaturesRequested) {
        window.scrollTo({
          top: document.querySelector('#documents_section').offsetTop,
          behavior: 'smooth'
        });
      }
    } catch (error) {
    } finally {
      setDocumentIsGenerating(false);
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Generate a document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormSelect
          mui
          toLowerCase
          label="Select Document Template"
          name="held_position_political_advisor"
          value={documentInfo.template.id}
          onChange={e => {
            setDocumentField('template', {
              id: e.target.value,
              name: e.currentTarget.textContent
            })
            setDocumentField('document_title', e.currentTarget.textContent)
          }}
        >
          {templates.map((template, key) => {
            const title = template.document_title || `Document ${template.document_id}`
            return <MenuItem value={template.document_id} key={key}>{title}</MenuItem>
          })}
        </FormSelect>

        <FormControlLabel
          control={
            <Checkbox
              checked={areSignaturesRequested}
              onChange={e => setAreSignaturesRequested(e.target.checked)}
              color="primary"
            />
          }
          label="Request signatures"
        />

        {areSignaturesRequested && (
          <DualListBox
            showHeaderLabels
            canFilter
            className="ui-dual-listbox"
            options={availableUsers}
            selected={selectedUsers}
            onChange={onSelectionChange}
          />
        )}

        <FormInput
          mui
          label="Document title"
          value={documentInfo.document_title}
          onChange={e =>
            setDocumentField('document_title', e.target.value)
          }
        />
        <FormInput
          mui
          label="Description"
          value={documentInfo.document_description}
          onChange={e =>
            setDocumentField('document_description', e.target.value)
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button
          className="d-flex align-items-center"
          disabled={documentIsGenerating}
          variant="primary"
          onClick={generateDocument}
        >
          {documentIsGenerating && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
          <span>{documentIsGenerating ? 'Document is generating' : 'Generate document'}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


function TaskPage(props) {
  const { intl } = props;

  const { user } = useSelector((state) => state.auth);

  const [task, setTask] = useState({
    hdd_answers: null,
    documents: [],
    task_category_info: {
      category_name: null
    },
    task_associations: []
  });
  const [taskCategory, setTaskCategory] = useState(null)
  useEffect(() => {
    if (task?.task_category_info?.category_name) {
      setTaskCategory(task.task_category_info.category_name)
    }
  }, [task.task_category_info.category_name])

  // useRequest
  const [taskStates, setTaskStates] = useState(null);
  const [taskComments, setTaskComments] = useState([]);
  const [taskHistoryItems, setTaskHistoryItems] = useState([]);
  const [newCommentText, setNewCommentText] = useState('');
  const [selectedButtonView, setSelectedButtonView] = useState('comments');
  const [newSelectedFiles, setNewSelectedFiles] = useState([]);
  const [newFilesUploadAvailable, setNewFilesUploadAvailable] = useState(true);

  const [selectedActionType, setSelectedActionType] = useState();
  const [selectedActionDescription, setSelectedActionDescription] = useState();
  const [actionConfirmationModalShown, setActionConfirmationModalShown] = useState(false);

  const [documentModalShown, setDocumentModalShown] = useState(false);
  const [signModalShown, setSignModalShown] = useState(false);
  const [templateSelectorModalShown, setTemplateSelectorModalShown] = useState(false);

  const [requestedSignaturesModalShown, setRequestedSignaturesModalShown] = useState(false);

  const [selectedPendingDocumentId, setSelectedPendingDocumentId] = useState();

  const [hddRiskCategory, setHddRiskCategory] = useState('unknown');
  const [hddNote, setHddNote] = useState();

  const [messageToUser, setMessageToUser] = useState();
  const [messageUserModalShown, setMessageUserModalShown] = useState(false);
  const [leaveMessageInComments, setLeaveMessageInComments] = useState(true);
  const [options, setOptions] = useState([]);

  const [shareholders, fetchShareholders] = useShareholders(task.task_company_id)

  const file_input = useRef();

  const task_id = props.match.params.task_id;

  const classes = useStyles();

  function getInvitationStatus({ person_name }) {
    // temporary solution
    const { related_invitations } = task;
    if (related_invitations?.length) {
      const invitation = related_invitations.find(ri => ri.invitee_name === person_name);
      if (!invitation) return <div>-</div>;
      return <div className="label label-inline">{invitation.invitation_status}</div>
    }
    return <div>-</div>
  }

  function getInvitation(row) {
    let invitation;
    const { related_invitations } = task;
    if (related_invitations?.length) {
      invitation = related_invitations.find(i => i.invitee_name === row.person_name);
    } return invitation
  }

  function openInvitationDialog(invitation) {
    setInvitationId(invitation.invitation_id);
    setInvitationEmail(invitation.invitation_email);
    setInvitationDialog(true);
  }

  function StakeholderLink({ person_csp_id, person_entity_id, person_user_id, person_name, person_type }) {
    if (person_type === "Human") {
      if (person_csp_id && person_user_id) {
        if (csp_id === person_csp_id) {
          return <Link to={`/user/${person_user_id}/profile`}>{person_name}</Link>;
        } else {
          return <Link to={`/user/${person_user_id}/due-diligence`}>{person_name}</Link>;
        }
      }
    } else {
      if (person_csp_id && person_entity_id) {
        if (csp_id === person_csp_id) {
          return <Link to={`/entity/${person_entity_id}/profile`}>{person_name}</Link>;
        } else {
          return <Link to={`/entity-due-diligence?entity_id=${person_entity_id}&csp_id=${person_csp_id}`}>{person_name}</Link>
        }
      }
    }
    return <div>{person_name}</div>;
  }

  const stakeholdersTableCols = [
    {
      name: 'name',
      title: 'Name',
      component: row => (<StakeholderLink {...row} />)
    },
    {
      name: 'type',
      title: 'Type',
      component: row => <div>{row.person_type}</div>
    },
    {
      name: 'role',
      title: 'Role',
      component: row => <div>{row.person_role}</div>
    },
    {
      name: 'invites',
      title: 'Invites',
      component: row => getInvitationStatus(row)
    },
    {
      name: 'dd_status',
      title: 'DD Status',
      component: row => <div>{row.person_status || '-'}</div>
    },
    {
      name: 'risk_rating',
      title: 'Risk Rating',
      component: row => <div>{row.person_risk_rating || '-'}</div>
    },
    {
      name: 'resend_invitation',
      component: row => {
        const invitation = getInvitation(row);
        if (invitation && row.person_type === 'Human') {
          return <i className="ki ki-gear icon-md" onClick={() => openInvitationDialog(invitation)} />;
        }
      }
    }
  ];

  const getTask = async (returned_task) => {
    try {
      let new_task;

      if (returned_task && returned_task.task_id) {
        new_task = returned_task
      } else {
        const { data } = await Axios.get(`task/${task_id}`);

        new_task = data.task;
      }

      // Update the comments
      setTaskComments(new_task.task_comments || []);

      // Update the history
      setTaskHistoryItems(new_task.task_histories);

      if (new_task?.customer_company?.stakeholders) {
        setStakeholders(new_task.customer_company.stakeholders);
      }

      // Set the states
      if (new_task.task_states) {
        new_task.initial_states = [];

        const task_states = {};

        for (const state of new_task.task_states) {
          task_states[state.state_id] = state.state_bool;
          new_task.initial_states[state.state_id] = state.state_bool;
        }

        setTaskStates(task_states);
      }

      // Update HDD answers
      if (new_task.hdd_answers) {
        setHddAnswersView(
          new_task.hdd_answers.map(answer => {
            let value = null;
            if (answer.field_is_json || answer.field_name === 'avatar') return '';

            if (answer.field_name === 'date_of_birth') value = moment(answer.field_value).format('DD-MMM-YYYY');

            return {
              name: answer.field_name + answer.id,
              title: snakeToTitle(answer.field_name),
              value: value || (!!answer.field_is_json ? '' : cvBool(answer.field_value)) || '—'
            };
          })
        );
      }

      if (new_task.pending_documents && new_task.pending_documents.length) {
        new_task.pending_documents = new_task.pending_documents.map(document => {
          for (const signer of document.task_pending_document_signatures) {
            if (!signer.is_signature_ready && signer.signer_user_id === user.user_id) {
              document.current_user_signature_required = true;

              break;
            }
          }

          return document;
        });
      }

      // Update the current task
      setTask(() => ({ ...new_task }));

      // Update the actions
      const localOptions = [];
      for (const action_name in new_task.task_category_info.actions) {
        const action_descriptor = new_task.task_category_info.actions[action_name];

        if (!action_descriptor) continue;
        if (taskCategory === 'company.create') {
          localOptions.push({
            id: action_name,
            title: intl.formatMessage({ id: `TASK_ACTION_NAME.${action_name}` }) === 'Approve'
              ? 'Ready for formation'
              : 'Decline the business',
            disabled: !!action_descriptor.disabled,
            onClick: () => showActionConfirmationModal(action_name, action_descriptor.action_description)
          });
        } else {
          localOptions.push({
            id: action_name,
            title: intl.formatMessage({ id: `TASK_ACTION_NAME.${action_name}` }),
            disabled: !!action_descriptor.disabled,
            onClick: () => showActionConfirmationModal(action_name, action_descriptor.action_description)
          });
        }
        
      }

      setOptions(localOptions);
      // Update the hdd decision (if hdd.review task)
      if ((new_task.task_category_info.category_name === 'edd.review' || new_task.task_category_info.category_name === 'hdd.review') && new_task.hdd_decision) {
        if (new_task.hdd_decision.note) setHddNote(new_task.hdd_decision.note);
        if (new_task.hdd_decision.risk_category) setHddRiskCategory(new_task.hdd_decision.risk_category);
      }

      // Format the signatures
      const final_signatures = [];
      const processed_signers = [];

      // Go through requested signatures
      if (new_task.requested_signers) {
        for (const requested_signature of new_task.requested_signers) {
          processed_signers.push(requested_signature.signer_user_id);

          const target_signature = new_task.task_signatures.find(
            signature => signature.signer_user_id === requested_signature.signer_user_id
          );

          final_signatures.push(
            <div className="pair">
              <div>{requested_signature.signer_user.user_full_name}</div>
              <div>
                requested,{' '}
                {target_signature ? (
                  <span className="text-success">signed (as {target_signature.signed_as})
                  </span>
                ) : (
                  <span className="text-warning">waiting for signature</span>
                )}
              </div>
            </div>
          );
        }
      }
    } catch (e) {
      console.error("getTask error", e);
    }
  };

  const postNewComment = () => {
    Axios.put(`task/${task_id}/comment`, {
      comment_body: newCommentText
    })
      .then(() => {
        // TODO @performance just get the new comment from the API and append, instead of getting the whole task again
        getTask();

        setNewCommentText('');
      })
      .catch(() => { });
  };

  const handleNewCommentChange = e => {
    setNewCommentText(e.target.value);
  };

  const [jurisdictions, setJurisdictions] = useState([])

  const getJurisdictions = () => {
    Axios.get('ui/countries')
      .then(({ data }) => {
        setJurisdictions(Object.keys(data.COUNTRIES_TITLES).map(key => ({
          value: key,
          title: data.COUNTRIES_TITLES[key]
        })));
      })
      .catch(() => { });
  };

  useEffect(() => {
    getJurisdictions();
  }, []);

  const saveHddDecision = () => {
    const url = task.task_category_info.category_name === "edd.review"
      ? `task/${task_id}/edd-decision`
      : `task/${task_id}/hdd-decision`;

    Axios.put(url, {
      note: hddNote,
      risk_category: hddRiskCategory
    })
      .then(() => {
        // TODO notify the user that the data was saved
        notify({ type: 'SUCCESS', title: 'Success!', message: 'New changes have been applied' });
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch(() => { });
  };

  const messageUser = () => {
    Axios.post(`task/${task_id}/message-user`, {
      message: messageToUser,
      create_comment: leaveMessageInComments
    })
      .then(() => {
        setMessageUserModalShown(false);
        getTask();
      })
      .catch(() => { });
  };

  useEffect(() => {
    getTask();
  }, []);

  const showActionConfirmationModal = (action_name, action_description) => {
    setSelectedActionType(action_name);
    setSelectedActionDescription(action_description);
    setActionConfirmationModalShown(true);
  };

  const executeAction = action_type => {
    setActionConfirmationModalShown(false);

    updateTask(task_id, action_type)
      .then((returnedTask) => {
        // TODO @performance would be better to return the updated task and use it from data instead of making another API call
        getTask(returnedTask);
      })
      .catch(() => { });
  };

  const selectBottomView = new_view => {
    setSelectedButtonView(new_view);
  };

  const addDocumentsClick = () => {
    file_input.current.click();
  };

  const fileInputChange = e => {
    e.stopPropagation();
    e.preventDefault();

    setNewSelectedFiles([...e.target.files]);
  };

  const uploadNewDocuments = () => {
    setNewFilesUploadAvailable(false);

    const form_data = new FormData();
    let file_i = 0;

    for (const file of newSelectedFiles) {
      form_data.append(file_i++, file);
    }

    return Axios.post(`task/${task_id}/documents-upload`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        // Files uploaded, refresh the task
        setNewSelectedFiles([]);
        getTask();
        setNewFilesUploadAvailable(true);
      })
      .catch((e) => {
        // Some error occurred
        setNewFilesUploadAvailable(true);
        notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
        return Promise.reject(e)
      });
  };

  const getPendingDocument = (pending_document_id) => {
    // TODO @cleanup make this nicer
    Axios.get(`/task/${task_id}/pending-document/${pending_document_id}`, {
      responseType: 'blob'
    }).then(response => {
      downloadFile(response);
    });
  };

  const finalizePendingDocument = (pending_document_id, e) => {
    e.target.disabled = true;

    Axios.post(`/task/${task_id}/finalize-pending-document/${pending_document_id}`)
      .then(() => {
        getTask();

        notify({ type: 'SUCCESS', title: 'Added to queue', message: 'Document will be ready in a minute. Please, check back later' });
      })
      .catch((data) => {
        notify({
          type: 'ERROR',
          title: 'Could not finalize a document',
          message: data.response ? data.response.data.error_message : "Some error occurred",
        });
      });
  }

  const onDocumentStatusUpdate = (document_id, new_document_status, new_signing_status) => {
    // TODO @performance @cleanup
    const new_documents = task.documents.map(doc => {
      if (doc.document_id === document_id) {
        if (new_document_status) doc.document_status = new_document_status;
        else if (new_signing_status) doc.document_is_signed = new_signing_status;
      }
      return doc;
    });

    setTask({ ...task, documents: new_documents });
  }

  const onRequestedSignaturesSave = users => {
    setRequestedSignaturesModalShown(false);

    Axios.put(`/task/${task_id}/requested-signatures`, {
      requested_users: users
    }).then(() => { });
  };

  const handleTaskStateItemChange = (state_id, e) => {
    const new_state = e.target.checked;

    Axios.put(`/task/${task_id}/set-state/${state_id}/${new_state}`).then(() => {
      // Update the states
      const task_states = taskStates;

      task_states[state_id] = new_state;

      setTaskStates({ ...task_states });
    });
  };

  const csp_id = useSelector(state => state.tenant.csp?.csp_id);

  function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }

  function cvBool(val) {
    if (val === 'true') {
      return 'Yes'
    } if (val === 'false') {
      return 'No'
    }
    if (!!val === val) {
      return val ? 'Yes' : 'No'
    }
    return val
  }

  const [hddAnswersView, setHddAnswersView] = useState([]);

  const snakeToTitle = snakeCase => {
    const arr = snakeCase.split('_');
    arr[0] = arr[0].replace(/(^|\s)\S/g, letter => letter.toUpperCase());
    return arr.join(' ');
  };

  const showPendingDocumentSigningModal = pending_doc_id => {
    setSelectedPendingDocumentId(pending_doc_id);
    setSignModalShown(true);
  }

  const [invitationDialog, setInvitationDialog] = useState(false);
  const [invitationId, setInvitationId] = useState('');
  const [invitationEmail, setInvitationEmail] = useState('');


  const handleDialogClose = () => {
    setInvitationId('');
    setInvitationEmail('');
    setInvitationDialog(false);
  };

  const handleInvitationChange = () => {
    return Axios.put(`invitation/${invitationId}/update`, {
      new_email_address: invitationEmail
    })
      .then(() => getTask())
      .then(() => {
        notify({
          type: 'SUCCESS',
          title: 'Success',
          message: 'An invitation has been resent'
        });
      })
      .finally(() => handleDialogClose())
  };

  const [stakeholders, setStakeholders] = useState([]);

  return (
    <div className="d-flex justify-content-around align-items-start">
      <ActionConfirmationModal
        show={actionConfirmationModalShown}
        action_type={selectedActionType}
        action_description={selectedActionDescription}
        onHide={() => setActionConfirmationModalShown(false)}
        executeAction={executeAction}
      />

      <RequestedSignaturesModal
        show={requestedSignaturesModalShown}
        onHide={() => setRequestedSignaturesModalShown(false)}
        onSave={onRequestedSignaturesSave}
        entity_id={task?.task_company_id}
        requested_signers={task?.requested_signers}
      />

      <Modal id="message-user-modal" show={messageUserModalShown} onHide={() => setMessageUserModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Send a message to a user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            label="Message"
            multiline
            value={messageToUser}
            onChange={e => setMessageToUser(e.target.value)}
            className={classes.textField}
            margin="normal"
            rows={5}
            variant="outlined"
          />
          <label className="checkbox mt-5">
            <input
              type="checkbox"
              name="leave_message_in_comments"
              className="m-1"
              onChange={e => setLeaveMessageInComments(e.target.checked)}
              checked={leaveMessageInComments}
            />

            <span className="mr-2" />

            <a>Also leave the message in the task's comments</a>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMessageUserModalShown(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={messageUser}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <DocumentModal
        show={documentModalShown}
        onHide={() => setDocumentModalShown(false)}
        onStatusUpdate={onDocumentStatusUpdate}
      />

      <SignModal
        show={signModalShown}
        onHide={() => setSignModalShown(false)}
        taskId={task_id}
        onSigned={() => getTask()}
        pendingDocumentId={selectedPendingDocumentId}
      />

      <GenerateDocumentModal
        show={templateSelectorModalShown}
        onHide={() => setTemplateSelectorModalShown(false)}
        getTask={() => getTask()}
        task_id={task_id}
        entity_id={task?.task_company_id}
      />

      <div style={{ width: '75%' }}>
        <div className="card card-custom card-stretch gutter-b mb-4">
          <div className="card-header border-0 py-5">
            <h2 className="card-title w-100 ">
              <TaskTitle task={task} />
            </h2>
          </div>
          <div className="card-body pt-0">
            <div className="text-dark font-size-lg mb-4" dangerouslySetInnerHTML={{ __html: task.task_description }} />

            <div className={'d-flex'}>
              <div className="my-2">
                <div className="font-weight-lighter text-muted">Created at</div>
                <div className="font-weight-bolder text-dark"> {moment.utc(task.created_at).format('YYYY-MM-DD')}</div>
              </div>

              {task.created_by_user && (
                <div className="my-2 ml-6">
                  <div className="font-weight-lighter text-muted">Created by</div>
                  <div className="font-weight-bolder text-dark">
                    {task.created_by_user.user_full_name}
                    {task.created_by_user.user_name && <span className="text-muted"> (@{task.created_by_user.user_name})</span>}
                  </div>
                </div>
              )}
            </div>

            {/* Signatures block */}
            {(task?.pending_documents) && (
              <>
                {task.pending_documents.map(document => (
                  <div className="card px-5 py-4 mt-4">
                    <div className="d-flex flex-row">
                      <div className="left mr-5 w-50px">
                        <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")} />
                      </div>
                      <div className="right w-100">
                        <strong>{document.pending_document_name}</strong>
                        {document.pending_document_description && (
                          <div className="font-weight-lighter">{document.pending_document_description}</div>
                        )}

                        <div className="mt-2">
                          {document.task_pending_document_signatures.map(signature => (
                            <div className="d-flex align-items-center justify-content-between my-2">
                              <div>{signature.user.user_full_name}</div>
                              <div
                                className={`label label-inline${signature.is_signature_ready ? " label-light-success" : ""}`}
                              >
                                {signature.is_signature_ready ? "signed" : "pending"}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="w-100 mt-6 mb-4" style={{ height: '1px', backgroundColor: '#EBEDF3' }} />
                    <div className="d-flex align-items-center justify-content-between">
                      {document.current_user_signature_required ? (
                        <div>
                          <strong className="align-items-center d-flex">
                            <span className="mr-1 svg-icon svg-icon-warning">
                              <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                            </span>

                            Waiting for your signature
                          </strong>
                          <div className="font-weight-lighter">Your signature is required to finalize this document</div>
                        </div>
                      ) : (
                        <>
                          {document.pending_document_status === "signatures_pending" && (
                            <div>
                              <strong className="align-items-center d-flex">
                                <span className="mr-1 svg-icon svg-icon-info">
                                  <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/General/Other2.svg")} />
                                </span>

                                Waiting for signatures
                              </strong>
                              <div className="font-weight-lighter">The document will be finalized after all the signatures will be collected</div>
                            </div>
                          )}
                          {document.pending_document_status === "ready_to_finalize" && (
                            <div>
                              <strong className="align-items-center d-flex">
                                <span className="mr-1 svg-icon svg-icon-success">
                                  <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Waiting.svg")} />
                                </span>

                                Ready to finalize
                              </strong>
                              <div className="font-weight-lighter">All of the signatures were collected. This document is ready to be finalized</div>
                            </div>
                          )}
                          {document.pending_document_status === "processing" && (
                            <div>
                              <strong className="align-items-center d-flex">
                                <span className="mr-1 svg-icon svg-icon-info">
                                  <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
                                </span>

                                Processing...
                              </strong>
                              <div className="font-weight-lighter">System is processing this document. Check back in a minute</div>
                            </div>
                          )}
                          {document.pending_document_status === "finalized" && (
                            <div>
                              <strong className="align-items-center d-flex">
                                <span className="mr-1 svg-icon svg-icon-success">
                                  <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                                </span>

                                Signed
                              </strong>
                              <div className="font-weight-lighter">Document was successfully generated and signed</div>
                            </div>
                          )}
                        </>
                      )}
                      <div>
                        <button
                          className="btn btn-secondary px-6"
                          onClick={() => getPendingDocument(document.task_pending_document_id)}
                        >
                          {document.pending_document_status === "finalized" ? (
                            <>Unsigned version</>
                          ) : (
                            <>Download</>
                          )}
                        </button>
                        {document.pending_document_status === "finalized" && (
                          <button
                            className="btn btn-primary font-weight-bold px-6 ml-2"
                            onClick={() =>
                              getDocument(document.finalized_document_id, 'document', "csp")
                            }
                          >
                            Download
                          </button>
                        )}
                        {document.pending_document_status === "ready_to_finalize" && (
                          <button
                            className="btn btn-primary font-weight-bold px-6 ml-2"
                            onClick={(e) => finalizePendingDocument(document.task_pending_document_id, e)}
                          >
                            Finalize
                          </button>
                        )}
                        {document.current_user_signature_required && (
                          <button
                            className="btn btn-primary font-weight-bold px-6 ml-2"
                            onClick={() => showPendingDocumentSigningModal(document.task_pending_document_id)}
                          >
                            Leave a signature
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* Share class amendment task */}
            {task.task_category_info &&
              (task.task_category_info.category_name === 'company.update_shares' ||
                task.task_category_info.category_name === 'company.cancel_shares') &&
              task.target_share_class && (
                <>
                  <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
                    <div className="container-title">Target share class information</div>

                    <div className="pair">
                      <div>Share class name</div>
                      <div>{task.target_share_class.share_name}</div>
                    </div>
                    <div className="pair">
                      <div>Share class type</div>
                      <div>{task.target_share_class.share_class}</div>
                    </div>
                    <div className="pair">
                      <div>Currency</div>
                      <div>{task.target_share_class.share_currency}</div>
                    </div>
                    <div className="pair">
                      <div>Authorized shares</div>
                      <div>{task.target_share_class.share_authorized_shares}</div>
                    </div>
                    <div className="pair">
                      <div>Nominal value</div>
                      <div>{task.target_share_class.share_nominal_value}</div>
                    </div>
                    <div className="pair">
                      <div>Outstanding shares</div>
                      <div>{task.target_share_class.share_outstanding_shares}</div>
                    </div>
                    <div className="pair">
                      <div>Issued on</div>
                      <div>{task.target_share_class.share_issued_on}</div>
                    </div>
                    <div className="pair">
                      <div>Share class restrictions</div>
                      <div>{task.target_share_class.share_restrictions}</div>
                    </div>
                    <div className="pair">
                      <div>Rights to capital on winding up</div>
                      <div>{task.target_share_class.share_rights_to_capital_on_winding_up || '—'}</div>
                    </div>
                    <div className="pair">
                      <div>Rights to dividends</div>
                      <div>{task.target_share_class.share_rights_to_dividents || '—'}</div>
                    </div>
                    <div className="pair">
                      <div>Voting rights</div>
                      <div>{task.target_share_class.share_voting_rights || '—'}</div>
                    </div>
                    <div className="pair">
                      <div>Certification required</div>
                      <div>{task.target_share_class.share_certificates_required ? 'Yes' : 'No'}</div>
                    </div>
                    <div className="pair">
                      <div>Director approval required</div>
                      <div>{task.target_share_class.share_director_approval_required ? 'Yes' : 'No'}</div>
                    </div>
                    <div className="pair">
                      <div>Status</div>
                      <div className={`${OTHER_STATUS_COLORS[task.target_share_class.share_status]}`}>
                        {task.target_share_class.share_status}
                      </div>
                    </div>
                  </div>
                </>
              )}



            {/* Stakeholder-related task */}
            {task.task_category_info &&
              (task.task_category_info.category_name === 'company.add_stakeholder' ||
                task.task_category_info.category_name === 'company.remove_stakeholder') &&
              task.target_stakeholders &&
              task.target_stakeholders.map((stakeholder, i) => (
                <>
                  <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
                    <div className="container-title">Stakeholder {i + 1}</div>

                    <div className="pair">
                      <div>Name</div>
                      <div>{stakeholder.person_pending_full_name}</div>
                    </div>
                    <div className="pair">
                      <div>Email address</div>
                      <div>{stakeholder.person_pending_email_address}</div>
                    </div>
                    <div className="pair">
                      <div>Role</div>
                      <div>{stakeholder.person_role}</div>
                    </div>
                    <div className="pair">
                      <div>Status</div>
                      <div className={`${OTHER_STATUS_COLORS[stakeholder.person_status]}`}>{stakeholder.person_status}</div>
                    </div>
                    <div className="pair">
                      <div>User</div>
                      <div>
                        {stakeholder.person_user_id ? (
                          <Link to={`/users/${stakeholder.person_user_id}`}>See the user</Link>
                        ) : (
                          'Not assigned'
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {/* HDD review task */}
            {task.task_category_info && task.task_category_info.category_name === 'hdd.review' && (
              <>
                <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm">
                  <div>Due Diligence Api decision:</div>

                  <div>(WIP)</div>
                </div>

                <div className="bg-dark-o-10 mt-10 rounded-sm">
                  <ProfileViewWidget title="Individual Due Diligence answers" items={hddAnswersView} />
                </div>

                <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm">
                  <div>Identification documents info:</div>

                  {task.hdd_identification_documents?.map((document_item, index) => (
                    <>
                      <div className="font-weight-bold">Document {index + 1}</div>
                      <div>Country of issue: {document_item.document_country_of_issue}</div>
                      <div>Expiry date: {document_item.document_expiry_date}</div>
                      <div>Issue date: {document_item.document_issue_date}</div>
                      <div>Document number: {document_item.document_number}</div>
                      <div>Document type: {document_item.document_type}</div>
                    </>
                  ))}
                </div>
              </>
            )}

            {/* Company creation task */}
            {task.task_category_info && task.task_category_info.category_name === 'company.create' && (
              <>
                {task.related_invitations && (
                  <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-3 font-weight-bold">Stakeholders</div>

                      <CustomDialog
                        show={invitationDialog}
                        title="Edit invitations"
                        onClose={handleDialogClose}
                        onSave={handleInvitationChange}
                      >
                        <p>
                          An invitation will be sent to the new email address as soon as you
                          set it.
                        </p>
                        <TextField
                          label="New email address"
                          variant="outlined"
                          className="w-100 mt-5"
                          value={invitationEmail}
                          onChange={(e) => {
                            setInvitationEmail(e.target.value)
                          }}
                        />
                      </CustomDialog>
                    </div>

                    <Table
                      clickable
                      nonSelectable
                      cols={stakeholdersTableCols}
                      entities={stakeholders}
                    />
                  </div>
                )}

                <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm">
                  <div className="mb-3 font-weight-bold">Entity information</div>

                  <div className="font-weight-lighter">
                    Entity Name:{' '}
                    <strong>
                      <Link to={`/entity/${task.customer_company.company_id}`}>{task.customer_company.company_name}</Link>
                    </strong>
                  </div>
                  <div className="font-weight-lighter">
                    Entity Category: <strong>{ucFirst(task.customer_company.company_category)}</strong>
                  </div>
                  <div className="font-weight-lighter">
                    Entity Type: <strong>{ucFirst(task.customer_company.company_type.replaceAll('_', ' '))}</strong>
                  </div>
                </div>
                {
                  task.customer_company.customer_company_services && (
                    <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm">
                      <div className="mb-3 font-weight-bold">Additional Services</div>
                      {task.customer_company.customer_company_services.map(({ csp_services_config }) => (
                        <div className="font-weight-lighter" key={csp_services_config.service_title}>
                          <div>Service name: {csp_services_config.service_title}</div>
                          <div className="mb-2">Service price: {csp_services_config.service_price}$</div>
                        </div>
                      ))}
                    </div>
                  )
                }
              </>
            )}

            {task.task_category_info && task.task_category_info.category_allow_signatures && (
              <div className="bg-dark-o-10 mt-6 px-8 py-5 rounded-sm" id="documents_section">
                <div>Entity Formation Documents: </div>
                <div className="my-2">
                  {task.documents && task.documents.length ? (
                    task.documents.map(document => (
                      <FileBox
                        key={document.document_id}
                        onDownload={() => getDocument(document.document_id, 'document', 'csp')}
                        document={document}
                      />
                    ))
                  ) : (
                    <div>(none)</div>
                  )}
                </div>

                <div className="mt-5">Documents to be uploaded: </div>
                {newSelectedFiles.length ? (
                  newSelectedFiles.map((file, index) => (
                    <div key={file.name}>
                      {index}: {file.name} ({file.size}b)
                    </div>
                  ))
                ) : (
                  <div>(none)</div>
                )}
                <div className="d-flex flex-row mt-3">
                  <div className="w-30 mr-3">
                    <BrowseFile
                      title="+ add documents"
                      ref={file_input}
                      onChange={fileInputChange}
                      onClick={addDocumentsClick}
                    />
                  </div>
                  <div>
                    <button onClick={uploadNewDocuments} disabled={!newFilesUploadAvailable} className="btn btn-primary">
                      upload files
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Task state actions */}
        {task.task_category_info && task.task_category_info.task_state_descriptors?.length ? (
          <div className="card card-custom card-stretch gutter-b mb-4">
            {task.task_category_info.task_state_descriptors.map(state_descriptor => (
              <ExpansionPanel key={state_descriptor.state_id}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      {(taskStates ? (
                        taskStates[state_descriptor.state_id]
                      ) : (
                        task.initial_states[state_descriptor.state_id]
                      )) ? (
                        <CheckCircleOutlineIcon style={{ color: 'var(--green)' }} />
                      ) : (
                        <CancelIcon style={{ color: 'var(--red)' }} />
                      )}
                    </div>
                    <Typography className={classes.heading}>{state_descriptor.state_title}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    {state_descriptor.state_name === 'ar_validated' && <AnnualReturnValidated />}
                    {state_descriptor.state_name === 'esn_validated' && (
                      <EsnValidated onHide={_ => getTask()} task_id={task.task_id} company_id={task.task_company_id} jurisdictions={jurisdictions} />
                    )}

                    {state_descriptor.state_writable ? (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                taskStates
                                  ? taskStates[state_descriptor.state_id]
                                  : task.initial_states[state_descriptor.state_id]
                              }
                              onChange={e => handleTaskStateItemChange(state_descriptor.state_id, e)}
                              color="primary"
                            />
                          }
                          label={state_descriptor.state_title}
                        />

                        <Typography className="text-muted font-weight-semi-bold">
                          You can update this status at any time.
                        </Typography>
                      </>
                    ) : (
                      <Typography className="text-muted font-weight-semi-bold">
                        You can not update this status directly. Some action is required.
                      </Typography>
                    )}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : null}

        {
          taskCategory === 'edd.review' && (
            <EddReview entity_id={task?.task_associations?.find(({ association_type }) => association_type === 'company')?.association_row_id} />
          )
        }

        {
          taskCategory === 'company.create' && task.customer_company.company_category === 'company' && (
            <ShareholdersTaskPage shareholders={shareholders} onShareholderCheck={fetchShareholders} />
          )
        }

        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 py-5">
            <div className="card-title">
              <span className="card-label font-weight-bolder text-dark">
                {selectedButtonView === 'comments' ? 'Comments' : 'Task history'}
              </span>
            </div>
            <div>
              <button
                className={`btn ${selectedButtonView === 'comments' ? 'btn-secondary' : 'btn-hover-bg-secondary'
                  } btn-sm px-5 ml-2`}
                onClick={() => selectBottomView('comments')}
              >
                Comments
              </button>
              <button
                className={`btn ${selectedButtonView === 'history' ? 'btn-secondary' : 'btn-hover-bg-secondary'
                  } btn-sm px-5 ml-2`}
                onClick={() => selectBottomView('history')}
              >
                History
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            {selectedButtonView === 'comments' ? (
              <>
                <div className="comments-container">
                  {taskComments.map(comment => (
                    <div className="mb-5">
                      <div className="font-weight-bolder text-dark">{comment.poster_user.user_full_name}</div>
                      <div className="my-1">{comment.comment_text}</div>
                      <div className="font-weight-light text-muted">
                        commented on {new Date(comment.created_at).toLocaleString()}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="new-comment-container w-100">
                  <TextField
                    label="New comment"
                    multiline
                    value={newCommentText}
                    onChange={handleNewCommentChange}
                    className={classes.textField}
                    margin="normal"
                    rows={5}
                    variant="outlined"
                  />

                  <button className="btn btn-primary font-weight-bold mt-2 px-6 float-right" onClick={postNewComment}>
                    Post
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="history-container">
                  {taskHistoryItems.map(history_item => (
                    <div className="mb-5">
                      <div className="font-weight-light text-muted">{new Date(history_item.created_at).toLocaleString()}</div>
                      <div className="my-1">{history_item.history_item_text}</div>
                      {history_item.history_item_executor_id && (
                        <div className="font-weight-light text-muted">
                          Executed by: <strong>{history_item.history_item_executor_user.user_full_name}</strong>
                        </div>
                      )}
                      {history_item.history_item_referenced_user_id && (
                        <div className="font-weight-light text-muted">
                          Referenced user: <strong>{history_item.history_item_referenced_user.user_full_name}</strong>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ width: '23%' }}>
        <div className="card card-custom card-stretch gutter-b mb-4">
          <div className="card-body">
            <div
              className={`label label-xl p-6 w-100 label-inline text-capitalize font-weight-bolder ${STATUS_COLORS[task.task_status]
                }`}
            >
              {
                taskCategory === 'company.create' ? (
                  (task.task_status === 'approved' && 'Ready for formation') ||
                  (task.task_status === 'rejected' && 'Decline the business') ||
                  task.task_status
                ) : task.task_status
              }

            </div>
            <div className="mt-5">
              <ActionsDropdown
                title="Decision"
                options={options}
                toggleClassName={'btn btn-success font-weight-bold w-100'}
                menuClassName={'w-100'}
              />
            </div>
            {task.task_category_info && task.task_category_info.category_allow_document_generation && (
              <div className="mt-5 d-flex">
                <buttton
                  className="btn btn-primary w-100"
                  onClick={() => setTemplateSelectorModalShown(true)}
                >
                  Generate documents
                </buttton>
              </div>
            )}
          </div>
        </div>

        {/* HDD review actions */}
        {task.task_category_info && (task.task_category_info.category_name === 'hdd.review' || task.task_category_info.category_name === 'edd.review') && (
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body p-6">
              <select
                className="form-control h-auto py-2 px-4 rounded-sm mb-1"
                type="text"
                name="hdd_risk_category"
                onChange={e => setHddRiskCategory(e.target.value)}
                value={hddRiskCategory}
              >
                <option value="unset">No risk category</option>
                <option value="low">Low risk</option>
                <option value="medium">Medium risk</option>
                <option value="high">High risk</option>
              </select>
              <TextField
                label="Note"
                multiline
                value={hddNote}
                onChange={e => setHddNote(e.target.value)}
                className={classes.textField}
                margin="normal"
                rows={5}
                variant="outlined"
              />

              <button className="btn btn-primary font-weight-bold mt-2 px-6 w-100" onClick={saveHddDecision}>
                Save
              </button>
              <button className="btn btn-primary font-weight-bold mt-2 px-6 w-100" onClick={() => setMessageUserModalShown(true)}>
                Message user...
              </button>
            </div>
          </div>
        )}

        <AssignationTaskPage created_at={task.created_at} task_id={task.task_id} task_assignee={task.task_assignee} />

        {
          taskCategory === 'company.create' &&
          ['company', 'partnership'].includes(task?.customer_company.company_category)
          && (
            <InitialSubscriberTaskPage shareholders={shareholders} entity_id={task.task_company_id} />
          )
        }

        {
          taskCategory === 'company.create' && (
            <EDDStatusTaskPage entity_id={task.task_company_id} />
          )
        }
      </div>
    </div>
  );
}

export default injectIntl(TaskPage);
