import React from 'react';

const defaultItems = [
  { title: 'View' },
  { title: 'Edit' },
  { title: 'Delete' }
];
const defaultAdditional = [
  { title: 'Add new', component: <i className="ki ki-plus icon-sm" /> }
];

export function DropdownMenu({ items, additional }) {
  const menuItems = items || defaultItems;
  const additionalItems =  additional || defaultAdditional;
  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover">
        <li className="navi-header font-weight-bold py-5">
          <span className="font-size-lg">Decision:</span>
          <i
            className="flaticon2-information icon-md text-muted"
            data-toggle="tooltip"
            data-placement="right"
            title="Click to learn more..."
          />
        </li>
        <li className="navi-separator mb-3 opacity-70" />
        {menuItems.map((item, key) => (
          <li className="navi-item" key={key}>
            <a href="#" className="navi-link" onClick={item.onClick}>
              <span className="navi-text">
                <span className="">{item.title}</span>
              </span>
            </a>
          </li>
        ))}
        {additionalItems.length ? (
          <>
            <li className="navi-separator mt-3 opacity-70" />
            {additionalItems.map(item => (
              <li className="navi-footer pt-5 pb-4">
                <a
                  className="btn btn-clean font-weight-bold btn-sm"
                  href="#"
                  onClick={item.onClick}
                >
                  {item.component}
                  {item.title}
                </a>
              </li>
            ))}
          </>
        ) : null}
      </ul>
      {/*end::Navigation*/}
    </>
  );
}
