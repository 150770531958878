import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable';

const cols = [
  {
    name: 'company_name',
    title: 'Entity name',
  },
  {
    name: 'role_name',
    title: 'Role',
    component: (row) => {
      const roles = row.persons?.map(person => (<div key={person.person_id} className="text-capitalize">{person.person_role}</div>))
      return (<div className='d-flex flex-column'>
        {roles}
      </div>)
    }
  },
  {
    name: 'company_status',
    title: 'Role status',
    component: (row) => {
      const roles = row.persons?.map(person => 
        (<div key={person.person_id} className={`text-capitalize label ${
          person.person_status === 'active' && 'label-light-success'
        } label-inline`}>{person.person_status}</div>))
      return (<div className='d-flex flex-column align-items-start' style={{gap: 10}}>
        {roles}
      </div>)
    }
  },
  {
    name: 'jurisdiction.region_title',
    title: 'Jurisdiction'
  }
];

export default function RelatedEntitiesRoute(props) {
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    if (props.basicUserInfo.user_id && props.basicUserInfo.user_id !== currentUserId) {
      props.queryAdditionalInfo('related_entities');
      setCurrentUserId(props.basicUserInfo.user_id);
    }
  }, [props.basicUserInfo.user_id]);

  const history = useHistory()

  const rows = props.additionalUserInfo.related_entities
    ? props.additionalUserInfo.related_entities.map(row => ({
      ...row,
      link: `/entity/${row.company_id}`
    }))
    : [];

  return (
    <>
      <div className="col-lg-12">
        <div className="card card custom">
          <div className="card-header">
            <div className="card-title w-25 pl-5">
              Related Entities
            </div>
            <div className="card-body">
              <Table rowEvents={{
                onClick: (_, row) => history.push(row.link)
              }} nonSelectable clickable entities={rows} cols={cols} idField="entity_name" />
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}