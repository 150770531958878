import { Avatar } from '@material-ui/core';
import React from 'react'
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCSP, setTenantSlug } from '../../redux/Tenant/tenantActions';

export default function CSPDropdown () {
  const dispatch = useDispatch()
  const availableCsps = useSelector(state => state.tenant.availableCSPs)
  const currentCSP = useSelector(state => state.tenant.csp)

  return (
    <div className="px-4">
      <Dropdown className={`d-flex justify-content-center`}>
        <Dropdown.Toggle
            style={{background: "#394fb4", borderColor: "#394fb4"}}
            className="w-100 d-flex align-items-center justify-content-between"
            title= "(no csp selected)"
          >
          <div className="d-flex align-items-center">
            <Avatar src={currentCSP?.csp_logo} />
            {(<div className="pl-4 d-flex flex-column">
              <span>{(currentCSP ? currentCSP.csp_name : "(no csp selected)")}</span>
              {currentCSP && (
                <small className="text-left">
                  {currentCSP.csp_status}
                </small>
              )}

            </div>)}
          </div>

        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100" style={{minWidth: 200}}>
          <ul className="navi navi-hover" style={{maxHeight: 245, overflow: 'auto'}}>
          {
            availableCsps.map(csp => {
              return (<li  className="navi-item" key={csp.csp_id}>
                <a href="#" style={{ paddingLeft: 14 }}
                   className="navi-link" onClick={e=>
                  { e.preventDefault();
                  dispatch(setTenantSlug(csp.tenant.tenant_slug));
                  dispatch(setCurrentCSP(csp))
                  window.location.reload(); }}>
                  <Avatar src={csp.csp_logo}/>
                  <div className="pl-2 navi-text d-flex flex-column">
                    {csp.csp_name.length > 25 ? (
                        <span>
                          {csp.csp_name.length}{csp.csp_name.slice(0, 24) + "..."}
                        </span>
                    ) : (
                        <span>
                          {csp.csp_name}
                        </span>
                    )}
                    <small className="text-muted">
                      {csp.csp_status}
                    </small>
                  </div>

                </a>

              </li>)
              })
          }
          </ul>
        </Dropdown.Menu>
    </Dropdown>
    </div>

  )
}
