import React, {useEffect, useState} from 'react'
import { useSelector} from "react-redux";
import Axios from 'axios'
import { AWAITING_CSP, LOGGED_IN, NOT_LOGGED_IN, setUser, USER_NOT_CHECKED } from '../../modules/Auth';
import { useLocation} from 'react-router-dom';
import { CSP_AWAITING_STATUS } from '../../../constants';
import { setAwaitingResult, setIsAwaitingCSP } from '../../../redux/Tenant/tenantActions';

export function checkUserReferrals(user, location) {
  if(user.user_referrals && user.user_referrals[0]) {
    // Create company
    if(user.user_referrals[0].action_type === "create_company") {
      if(!location.pathname.startsWith("/entity-formation") && !location.pathname.startsWith("/logout")) {
        window.location.href = `/entity-formation`;
      }
    }
    // Create csp
    else if(user.user_referrals[0].action_type === "create_csp") {
      if(!location.pathname.startsWith("/create-csp") && !location.pathname.startsWith("/logout")) {
        window.location.href = `/create-csp/${ user.user_referrals[0].csp_id }`;
      }
    }
  }
}

export default function useAuth() {
  const [userChecked, setUserChecked] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const token = useSelector(state => state.auth.authToken)
  const isAwaitingCSP = useSelector(state => state.tenant.isAwaitingCSP)
  const checkUserTrigger = useSelector( state => state.tenant.checkUserTrigger)
  const awaitingResult = useSelector(state => state.tenant.awaitingResult)
  const tenant = useSelector(state => state.tenant?.csp?.tenant?.tenant_slug)

  const location = useLocation();

  const checkAwaitingStatus = async () => {
    const {data} = await Axios.get("user/current")
    if (data && data.user && data.user.user_csp_awaiting_status === CSP_AWAITING_STATUS.TENANT_CREATION_STARTED) {
        setTimeout(() => {checkAwaitingStatus()}, 5000)
    } else {
      setAwaitingResult(data.user.user_csp_awaiting_status)
    }
  }

  const checkCurrentUser = () => {
    if(token) {
      return Axios.get("user/current")
      .then(async ({ data }) => {
        if (data) {
          setUser(data.user)
          document.getElementById("app-version").innerText = data.app_version;
          if (awaitingResult !== data.user.user_csp_awaiting_status) setAwaitingResult(data.user.user_csp_awaiting_status)
          if (data.user.user_csp_awaiting_status === CSP_AWAITING_STATUS.TENANT_CREATION_STARTED) {
            setIsAwaitingCSP(true)
            checkAwaitingStatus()
          }
          
          checkUserReferrals(data.user, location);
        }

        setLoggedIn(true)
      })
      .catch(() => {

        setLoggedIn(false)
      })
      .finally(() => {
        setUserChecked(true)
      })
    } else {
      setLoggedIn(false)
      setUserChecked(true)
    }
  }
  useEffect(() => {
    checkCurrentUser();
  }, [token, checkUserTrigger, tenant]);

  if (isAwaitingCSP) return AWAITING_CSP
  if (!userChecked) return USER_NOT_CHECKED
  if (!loggedIn) return NOT_LOGGED_IN
  return LOGGED_IN
}