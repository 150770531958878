import store from "../store";
import { SET_REDIRECTING_PATH } from "./urlActionTypes";

export const SET_INVITATION_URL = 'SET_INVITATION_URL'

export function putPathToQuery(path) {
  store.dispatch({
    type: SET_REDIRECTING_PATH,
    payload:
      path
  })
}

export function setInvitationUrl(url) {
  store.dispatch({
    type: SET_INVITATION_URL,
    payload: url
  })
}