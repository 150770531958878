import axios from "axios";

export function login(email, password) {
  return axios.post("auth/login", { email, password });
}

export function register(email, fullname, username, password, link_code, invitation_code) {
  return axios.post("auth/register", { email, fullname, username, password, link_code, invitation_code });
}

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get("user/current");
}
