import React, { useCallback, useEffect, useRef, useState } from "react";
import Axios from "axios";
import { getUserFriendlyMIMEType } from "../../../../util";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Checkbox, makeStyles} from "@material-ui/core";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import moment from 'moment'
import UploadFilesModal from '../../../components/UploadFilesModal/UploadFilesModal';
import { documentStatus } from "../../../../constants";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import { getDocument } from "../../../api/document";
import { getDocumentContentCategories } from "../../../api/ui";

const filters = [
  {
    id: 1,
    type: 'select',
    fieldName: 'category', // mime_type
    title: 'Category',
    options: [
      {
        id: 1,
        fieldName: 'uploaded_document',
        title: 'Uploaded document'
      },
      {
        id: 2,
        fieldName: 'generated_document',
        title: 'Generated document'
      },
      {
        id: 3,
        fieldName: 'template',
        title: 'Template'
      },
      {
        id: 4,
        fieldName: 'hdd_id',
        title: 'HDD — Identification document'
      },
      {
        id: 5,
        fieldName: 'hdd_por',
        title: 'HDD — Proof of residence'
      },
      {
        id: 6,
        fieldName: 'edd_coi',
        title: 'edd_coi'
      },
      {
        id: 7,
        fieldName: 'edd_roo',
        title: 'edd_roo'
      },
      {
        id: 8,
        fieldName: 'edd_cogs',
        title: 'edd_cogs'
      },
      {
        id: 9,
        fieldName: 'edd_cd',
        title: 'edd_cd'
      },
      {
        id: 10,
        fieldName: 'edd_se',
        title: 'edd_se'
      },
      {
        id: 11,
        fieldName: 'edd_rcp',
        title: 'edd_rcp'
      }
    ]
  },
  {
    id: 2,
    type: 'select',
    fieldName: 'mime_type', // mime_type
    title: 'MIME Type',
    options: []
  }
];

const sharesStyles = makeStyles({
  formControl: {
    minWidth: 180
  },
  searchInput: {
    '& input': {
      height: '60%'
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 1
    },

    '& label': {
      transform: 'translate(14px, 23px) scale(1)'
    }
  }
});



export default function VaultRoute(props) {
  const [documents, setDocuments] = useState([]);
  const [contentCategories, setContentCategories] = useState([])

  useEffect(() => {
    getDocumentContentCategories()
      .then(categories => {
        setContentCategories(categories)
      })
  }, [])

  const onEditorSave = useCallback((fieldName) => ({column, row, rowIndex, event}) => {
    setDocuments(oldDocuments => {
      const newDocument = oldDocuments.find(document => document.document_id === row.document_id)
      newDocument[fieldName] = event.target.value;
      return [...oldDocuments]
    })
  }, [documents])
  
  const cols = [
    {
      id: 1,
      name: "title",
      title: "Name",
      width: "500px",
      sortable: true,
      // editable: true,
      onEditorSave: onEditorSave("document_title")
    },
    {
      id: 2,
      name: "category",
      title: "Category",
      width: "200px",
      sortable: true,
      // editable: true,
      editType: 'select',
      // fetch this from server
      editSelectOptions: () => {
        return Object.keys(contentCategories).map(categoryKey => ({
          value: categoryKey,
          title: contentCategories[categoryKey]
        }))
      },
      onEditorSave: onEditorSave("document_content_category"),
      component: (row) => {
        return contentCategories[row.category] || row.document_category || '-'
      }
    },
    {
      id: 3,
      name: "mime_type",
      title: "MIME type",
      width: "200px",
      sortable: true
    },
    {
      id: 4,
      name: "date",
      title: "Date",
      width: "200px",
      sortable: true,
      component: row => {
        return moment(row.date).format("DD MMM YYYY");
      },
      // editable: true,
      editType: 'date',
      onEditorSave: onEditorSave("updated_at")
    },
    {
      id: 5,
      name: "status",
      title: "Status",
      width: "200px",
      sortable: true,
      // editable: true,
      editType: 'select',
      editSelectOptions: () => {
        return Object.values(documentStatus).map(status => ({
          value: status,
          title: status
        }))
      },
      onEditorSave: onEditorSave("document_status")
    },
    {
      id: 51,
      name: "signed",
      title: 'Signed',
      width: 100,
      sortable: false,
      component: (row) => {
        return (<Checkbox checked={row.signed} onChange={() => {}}/>)
      }
    },
    {
      id: 6,
      name: "actions",
      title: "Actions",
      width: "150px",
      sortable: false,
      component: (row) => {
        return (
          <Dropdown drop="left">
          <Dropdown.Toggle className="btn btn-light">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Settings-2.svg"
                )}
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {getDocument(row.document_id, row.title, 'company')}}>
              Download
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        )
      }
    }
  ]

  const classes = sharesStyles();
  const entity_id = props.match.params.entity_id;

  const [documentsTable, setDocumentsTable] = useState([]);
  const [filteredDocumentsTable, setFilteredDocumentsTable] = useState([]);


  const [documentFilters, setDocumentFilters] = useState({
    category: null,
    mime_type: null,
    status: null
  });

  const [mimeTypes, setMimeTypes] = useState([]);

  useEffect(() => {
    const mimeTypeFilter = filters.find(f => f.fieldName === 'mime_type');
    mimeTypeFilter.options = mimeTypes.map((type, id) => ({
      id,
      fieldName: type,
      title: type
    }));
  }, [mimeTypes]);

  const [newSelectedFiles, setNewSelectedFiles] = useState([]);
  const [newFilesUploadAvailable, setNewFilesUploadAvailable] = useState(true);

  const file_input = useRef();

  const updateFilters = e => {
    setDocumentFilters({ ...documentFilters, [e.target.name]: e.target.value });
  };

  // Update the filters
  useEffect(() => {
    // clearTimeout(vault_filters_timeout);
    // vault_filters_timeout = setTimeout(updateFilteredDocuments, 500);

    // Don't use debounce - we don't have any free text fields
    updateFilteredDocuments();
  }, [documentFilters]);

  const updateFilteredDocuments = () => {
    const filtered_documents = [];

    for (const document of documentsTable) {
      // Filter by document category
      if (documentFilters.category && documentFilters.category !== '-1' && document.category !== documentFilters.category) {
        continue;
      }

      // Filter by document MIME type
      if (
        documentFilters.mime_type &&
        documentFilters.mime_type !== '-1' &&
        document.raw_mime_type !== documentFilters.mime_type
      ) {
        continue;
      }

      filtered_documents.push(document);
    }

    setFilteredDocumentsTable([...filtered_documents]);
  };

  const uploadNewDocuments = (_, documents) => {
    setNewFilesUploadAvailable(false);

    const form_data = new FormData();
    let file_i = 0;

    for (const file of documents) {
      form_data.append('document_title', file.document_title)
      form_data.append('document_content_category', file.document_content_category)
      form_data.append('document_status', file.document_status)
      form_data.append(file_i++, file.file);
    }

    return Axios.post(`company/${entity_id}/documents-upload`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        // Files uploaded, refresh the documents

        setNewSelectedFiles([]);
        setNewFilesUploadAvailable(true);

        return getCompanyDocuments();
      })
      .catch((e) => {
        // Some error occured
        setNewFilesUploadAvailable(true);
        notify({type: "ERROR", message: e?.response?.data?.error_message, title: "Error"})
        return Promise.reject(e)
      });
  };
  
  const getCompanyDocuments = () => {
    Axios.get(`/company/${entity_id}/documents`).then(response => {
      const documents_table = [];
      const mime_types = [];

      for (const doc of response.data.company_documents) {
        // Construct the document status string
        let status_string = doc.document_status;

        if (doc.document_is_signed) status_string += ', signed';

        // Add document's mimetype to the list of mimetypes that we have happened upon
        if (!mime_types.includes(doc.document_mime_type)) mime_types.push(doc.document_mime_type);

        // Add the document to the table
        documents_table.push({
          document_id: doc.document_id,

          raw_mime_type: doc.document_mime_type,
          mime_type: getUserFriendlyMIMEType(doc.document_mime_type),

          title: doc.document_title || doc.document_id,
          category: doc.document_content_category,
          date: doc.updated_at,
          status: status_string,

          actions: (
            <Dropdown drop="left">
              <Dropdown.Toggle className="btn btn-light">
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => getDocument(doc.document_id)}>Download</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )
        });
      }

      // setDocumentsTable(documents_table);

      setMimeTypes(mime_types);

      setDocuments(response.data.company_documents);
    });
  };

  useEffect(() => {
    const documents_table = []
    for (const doc of documents) {
      // Add the document to the table
      documents_table.push({
        document_id: doc.document_id,

        raw_mime_type: doc.document_mime_type,
        mime_type: getUserFriendlyMIMEType(doc.document_mime_type),

        title: doc.document_title || doc.document_id,
        category: doc.document_content_category || (<>&mdash;</>),
        document_category: doc.document_category,
        date: doc.updated_at,
        status: doc.document_status,
        signed: doc.document_is_signed
      });
    }

    setFilteredDocumentsTable([...documents_table]);
  }, [documents])

  useEffect(() => {
    getCompanyDocuments();
  }, []);

  const editFileFields = [
    {
      name: 'document_title',
      title: 'Title',
      type: 'text'
    },
    {
      name: 'document_content_category',
      title: 'Category',
      type: 'select',
      options: () => {
        return Object.keys(contentCategories).map(categoryKey => ({
          value: categoryKey,
          title: contentCategories[categoryKey]
        }))
      }
    },
    {
      name: 'document_status',
      title: 'Status',
      type: 'select',
      options: () => {
        return Object.values(documentStatus).map(status => ({
          value: status,
          title: status
        }))
      }
    },
  ]

  return (
    <div className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">

        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: '1.5rem' }} className="fw-bolder m-0">
              Company`s Vault
            </div>
          </div>
          <div className="card-toolbar">
            <UploadFilesModal editFileFields={editFileFields} onSave={uploadNewDocuments} title="Upload vault documents" />
          </div>
        </div>

        <div className="card-body">
          {/* TODO: ADD Server logic */}
          {/*<DataTableFilter filters={filters} />*/}

          <Table
            nonSelectable
            cols={cols}
            entities={filteredDocumentsTable}
            idField="document_id"
          />
        </div>
      </div>
    </div>
  );
}
