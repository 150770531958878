/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import '../ProfilePage.css';
import { FormInput } from '../../components/Basic';
import ActionsDropdown from '../../components/Dropdown/ActionsDropdown';
import UploadFilesModal from '../../components/UploadFilesModal/UploadFilesModal';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';
import SaveButton from '../../components/_common/SaveButton';
import moment from 'moment';


export default function ConfigRoute() {
  const [config, setConfig] = useState({});
  const [newConfig, setNewConfig] = useState({
    bo_ownership_threshold: ''
  });
  const [createCompanyReferralLink, setCreateCompanyReferralLink] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false)

  const getConfig = () => {
    Axios.get('csp/config')
      .then(response => {
        if (response.data && response.data.csp_config) {
          const config = {};
          const new_config = {};

          for (const item of response.data.csp_config) {
            config[item.field_name] = item;
            new_config[item.field_name] = item.field_value;
          }

          setConfig(config);
          setNewConfig(new_config);
          setCreateCompanyReferralLink(response.data.create_company_link);
        }
      })
      .catch(() => { });
  };

  const saveConfig = () => {
    setSaveLoading(true)
    return Axios.post('csp/config', newConfig)
      .then(response => { })
      .catch(() => { })
      .finally(() => setSaveLoading(false))
  };

  const triggerESN = () => {
    Axios.put('csp/trigger-esn')
      .then(response => { })
      .catch(() => { });
  };

  const triggerESR = () => {
    Axios.put('csp/trigger-esr')
      .then(response => { })
      .catch(() => { });
  };

  const importEntitiesCSV = (_, files) => {
    const form_data = new FormData();

    form_data.append('file', files[0].file);

    Axios.post(`csp/import-entities-csv`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .catch(e => {
        notify({
          type: "ERROR",
          message: e?.response?.data?.error_message,
          title: "Error!"
        });
      });
  };

  // Get the config on mount
  useEffect(() => {
    if (!Object.keys(config).length) getConfig();
  }, []);

  const dropDownActions = [
    {
      id: 1,
      title: 'Trigger ESN',
      onClick: triggerESN
    },
    {
      id: 2,
      title: 'Trigger ESR',
      onClick: triggerESR
    },
    {
      id: 3,
      title: 'Import Entities CSV',
      onClick: () => modalButtonRef.current.click()
    }
  ];

  const modalButtonRef = useRef()

  function setNewConfigField(event) {
    const name = event.target.name
    const value = event.target.value
    
    setNewConfig(o => {
      return ({ ...o, [name]: value })
    })
  }

  return (
    <>
      <div className="card-header py-5">
        <div className="card-title w-50 ml-0">
          <div style={{ fontSize: '1.5rem' }} className="fw-bolder m-0">
            CSP configuration
          </div>
          <div className="text-muted mt-3 font-weight-bold font-size-sm" />
        </div>
        <div className="card-toolbar">
          <ActionsDropdown options={dropDownActions} />
          <UploadFilesModal
            title='Add existing client entity'
            inputTitle='Upload Entities CSV'
            uploadButtonRef={modalButtonRef}
            onSave={importEntitiesCSV}
          />
        </div>
      </div>

      <div className="card-body config-container">
        <div className="field">
          <div className="font-weight-bolder">Your referral link</div>
          <div className="my-2">{createCompanyReferralLink || "..."}</div>
          <div className="text-muted font-weight-semi-bold">
            Use this link to invite people to create an entity in your CSP
          </div>
        </div>
        <div className="field">
          <FormInput
            w-title="BO ownerhip percentage threshold"
            placeholder="15"
            type="number"
            name="bo_ownership_threshold"
            onChange={setNewConfigField}
            value={newConfig.bo_ownership_threshold}
          />

          {config.bo_ownership_threshold && (
            <div className="text-muted font-weight-semi-bold">
              Last updated on {moment(config.bo_ownership_threshold.last_updated_at).format('DD-MMM-YYYY')} by{' '}
              {config.bo_ownership_threshold.user ? config.bo_ownership_threshold.user.user_full_name : 'unknown'}
            </div>
          )}
        </div>
        <div className="field">
          <FormInput
            w-title="Annual return / ESN Certification date"
            placeholder="01.05"
            type="date"
            dateFormat="DD MMM"
            name="esn_certification_date"
            onChange={setNewConfigField}
            value={newConfig.esn_certification_date}
          />

          {config.esn_certification_date && (
            <div className="text-muted font-weight-semi-bold">
              Last updated on {moment(config.esn_certification_date.last_updated_at).format('DD-MMM-YYYY')} by{' '}
              {config.esn_certification_date.user ? config.esn_certification_date.user.user_full_name : 'unknown'}
            </div>
          )}
        </div>
        <div className="field">
          <FormInput
            w-title="ESR Certification date"
            placeholder="01.05"
            type="date"
            dateFormat="DD MMM"
            name="esr_certification_date"
            onChange={setNewConfigField}
            value={newConfig.esr_certification_date}
          />

          {config.esr_certification_date && (
            <div className="text-muted font-weight-semi-bold">
              Last updated on {moment(config.esr_certification_date.last_updated_at).format('DD-MMM-YYYY')} by{' '}
              {config.esr_certification_date.user ? config.esr_certification_date.user.user_full_name : 'unknown'}
            </div>
          )}
        </div>
        <div className="field">
          <FormInput
            w-title="ESR Certification deadline"
            placeholder="01.08.2020"
            type="date"
            minDate={new Date("2020-03-01")}
            dateFormat="DD MMM"
            name="esr_certification_deadline_date"
            onChange={setNewConfigField}
            value={newConfig.esr_certification_deadline_date}
          />

          {config.esr_certification_deadline_date && (
            <div className="text-muted font-weight-semi-bold">
              Last updated on {moment(config.esr_certification_deadline_date.last_updated_at).format('DD-MMM-YYYY')} by{' '}
              {config.esr_certification_deadline_date.user
                ? config.esr_certification_deadline_date.user.user_full_name
                : 'unknown'}
            </div>
          )}
        </div>
        <div className="field">
          <FormInput
            w-title="CSP's fee tax, %"
            placeholder="2"
            type="number"
            min="0"
            max="100"
            name="fee_tax"
            onChange={setNewConfigField}
            value={newConfig.fee_tax}
          />

          {config.fee_tax && (
            <div className="text-muted font-weight-semi-bold">
              Last updated on {moment(config.fee_tax.last_updated_at).format('DD-MMM-YYYY')} by{' '}
              {config.fee_tax.user ? config.fee_tax.user.user_full_name : 'unknown'}
            </div>
          )}
        </div>
      </div>

      <div className="card-footer">
        <div className="d-flex flex-row justify-content-between">
          <div className="w-30"></div>

          <div>
            <SaveButton loading={saveLoading} onClick={saveConfig} className="btn btn-primary" />
          </div>
        </div>
      </div>
    </>
  );
}