import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FormInput, FormSelect, FormTextarea } from "../../components/Basic";

export default function CancelSharesModal(props) {
  const params = useParams();

  const [cancellationInfo, setCancellationInfo] = useState({});

  const onInfoUpdate = e => {
    setCancellationInfo({
      ...cancellationInfo,
      [e.target.name]: e.target.value
    });
  };

  const cancelShare = () => {
    Axios.post(
      `company/${params.entity_id}/share-class/${props.share.share_id}/cancel`,
      cancellationInfo
    )
      .then(() => {
        if (props.onCancel) props.onCancel();
      })
      .catch(() => {});
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel {props.share.share_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.share.share_outstanding_shares !== 0 ? (
          <div>
            You can not cancel a share class which has outstanding shares
          </div>
        ) : (
          <>
            <p>You are about to cancel share class {props.share.share_name}.</p>

            <FormInput
              w-title="Cancellation date"
              type="date"
              name="cancellation_date"
              onChange={onInfoUpdate}
              value={cancellationInfo.cancellation_date}
            />

            <FormSelect
              w-title="Cancellation reason"
              type="text"
              name="cancellation_reason"
              onChange={onInfoUpdate}
              value={cancellationInfo.cancellation_reason}
            >
              <option value="-1">Choose a cancellation reason</option>
              <option value="acquisition">Acquisition</option>
              <option value="clerical_error">Clerical Error</option>
              <option value="conversion">Conversion</option>
              <option value="dissolved">Dissolved</option>
              <option value="merger">Merger</option>
              <option value="other">Other</option>
              <option value="public_offering">Public Offering</option>
              <option value="recapitalzation">Recapitalzation</option>
              <option value="relationship_change">Relationship Change</option>
              <option value="spinoff">Spinoff</option>
              <option value="swap">Swap</option>
              <option value="write_off_cancel">Write off cancel</option>
            </FormSelect>

            <FormInput
              w-title="Realized value"
              description="Enter any cash value realized from this transaction"
              type="number"
              name="realized_value"
              onChange={onInfoUpdate}
              value={cancellationInfo.realized_value}
            />

            <FormTextarea
              w-title="Notes"
              description="Add any notes regarding the cancellation. They will be included in the membership unit notes"
              name="notes"
              onChange={onInfoUpdate}
              value={cancellationInfo.notes}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        {props.share.share_outstanding_shares === 0 && (
          <Button
            variant="primary"
            className="btn-danger"
            onClick={cancelShare}
          >
            Cancel share class
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
