import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

function FileBox({ options, ...props }) {
  const APPROVED_CLASS = "approved",
      REJECTED_CLASS = "rejected";

  const statusToCssClassMap = {
    "Verified": APPROVED_CLASS,
    "Expired": REJECTED_CLASS,
  }

  const onBoxClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (props.onReview) props.onReview();
    // else if(can_be_signed) props.onSign();
  }

  const onSign = e => {
    e.preventDefault();
    e.stopPropagation();

    props.onSign();
  }

  const onReview = e => {
    e.preventDefault();
    e.stopPropagation();

    props.onReview();
  }

  const onDownload = e => {
    e.preventDefault();
    e.stopPropagation();

    props.onDownload();
  }

  let preview_svg = "/media/svg/icons/Files/File.svg";

  if (props.document.document_mime_type) {
    if (props.document.document_mime_type.startsWith("image/")) {
      preview_svg = "/media/svg/files/jpg.svg";
    }
    else if (props.document.document_mime_type === "application/pdf") {
      preview_svg = "/media/svg/files/pdf.svg";
    }
  }
  const { document_status } = props.document;
  const document_title = props.document.document_title || `Document ${props.document.document_id}`;
  const statusClass = statusToCssClassMap[document_status] || document_status;
  return (
    <div
      title={document_title}
      onClick={onBoxClick}
      className={`ui-document btn btn-hover-bg-secondary btn-sm cursor-pointer ${props.className}`}
    >
      <div className="preview-container">
        <SVG src={toAbsoluteUrl(preview_svg)} />
      </div>
      <div className="info">
        <div className="document-name text-wrap">{document_title}</div>
        <div className={`document-status ${statusClass}`}>
          {document_status}
        </div>
      </div>
      {
        (props.onReview || props.onDownload || props.onReupload || options) && (
          <div className="actions">
            {/* {can_be_signed && (
              <button className="btn btn-primary btn-sm" onClick={ onSign }>
                Sign
              </button>
            )} */}
            {
              options ? options.map(option => (
                <button key={option.title} className="btn btn-primary btn-sm" onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  option.onClick()
                }}>
                  {option.title}
                </button>
              )) : null
            }
            {props.onReview && (
              <button className="btn btn-primary btn-sm" onClick={onReview}>
                Review
              </button>
            )}
            {props.onDownload && (
              <button className="btn btn-primary btn-sm" onClick={onDownload}>
                Download
              </button>
            )}
            {props.onReupload && (
              <button className="btn btn-primary btn-sm" onClick={props.onReupload}>
                Reupload
              </button>
            )}
          </div>
        )
      }

    </div>
  )
}

export default FileBox;
