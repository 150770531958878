import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import { setAvailableCSPs, setCurrentCSP, setTenantSlug } from "../../../../redux/Tenant/tenantActions";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

const Logout = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    localStorage.removeItem('tenant')
    dispatch(setTenantSlug(undefined))
    dispatch(setAvailableCSPs([]))
    dispatch(setCurrentCSP(null))
    props.logout();
  }, [])
  const { hasAuthToken } = props

  return (
    hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />
  )
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
