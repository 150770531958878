import React from 'react'
import BaseFieldsConfiguration from '../../components/EditProfileForm/BaseFieldsConfiguration'

export default function EntityAddressAbstraction({ currentAddress, setCurrentAddress, errors = {} }) {
  const residentialFields = [
    {
      id: 2,
      name: 'is_primary',
      title: 'Current primary Address?',
      type: 'switch',
      onChange: ctx => {
        if (ctx.is_primary) {
          setCurrentAddress(old => ({ ...old, to: null }))
        }
      }
    },
    {
      id: 3,
      name: 'address_location',
      qualifiers_name: 'address_quantifiers',
      place_id_name: 'address_google_key',
      title: 'Address',
      type: 'address'
    },
    {
      id: 6,
      name: 'document',
      title: 'Upload copy of document',
      type: 'file',
    }
  ]

  return (
    <>
      {
        residentialFields.map(option => (
          <BaseFieldsConfiguration errors={errors} value={currentAddress} option={option}
            editTextFieldValue={(value, name) => {
              setCurrentAddress(old => {
                return { ...old, [name]: value }
              })
            }} />
        ))
      }
    </>
  )
}