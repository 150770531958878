import React from 'react'
import ActionsDropdown from '../Dropdown/ActionsDropdown';
import { WidgetTable } from '../WidgetTable/WidgetTable'

const cols = [
  {
    name: 'task_name',
    title: 'Task name',
    width: 150
  },
  {
    name: 'task_status',
    title: 'Task Status',
    width: 150
  }
]

export default function TasksWidget(props) {
  const rows = [];

  const options = [
    {
      id: 1,
      title: 'Initiate new task',
      onClick: () => {}
    }
  ]

  if(props.tasks) {
    for(const task of props.tasks) {
      rows.push({
        id: task.task_id,
        task_name: task.task_name,
        task_status: task.task_status,

        link: `/tasks/${ task.task_id }`
      })
    }
  }

  return (
    <WidgetTable
    {...{cols, rows,
      title: 'Task List',
      subtitle: 'List of tasks'
    }}
    toolbar={(
      <div className="card-toolbar">
            <ActionsDropdown options={options}/>
      </div>
    )}
    />
  )
}
