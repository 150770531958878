import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'

export default function EntityExportDescription() {

  return (
    <>
      <h5>Description</h5>
      <br />
      <p>
        Your CSV file should have next column headers {' '}
        <b>'#name';
        '#category';
        '#type';
        '#registration_number';
        '#date_of_incorporation';
        '#business_overview';
        '#phone_number';
        '#website_url';
        '#fiscal_year_end';
        '#jurisdiction'</b>
      </p>

      <p>
        All columns should be separated with semicolon delimiter <b>";"</b> {' '}
        and consist correct data
      </p>

      <p>
        Category and type should be recorded in <b>snake_case</b>, f. e.{' '}
        <b>exempt_registered_by_way_of_continuation</b>
      </p>
      
      <p>
        <a href={'/template.csv'} download>
          Download template
        </a>
      </p>
    </>
  )
}