import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Table } from "../modules/Auth/pages/customers/customers-table/CustomersTable";

const cols = [
  {
    id: 1,
    name: "task_name",
    title: "Task"
  },
  {
    id: 1,
    name: "due_date",
    title: "Due Date"
  },
  {
    id: 1,
    name: "task_status",
    title: "Status"
  }
];

const filters = [
  {
    id: 1,
    type: "select",
    fieldName: "entity",
    title: "Entity",
    options: [
      {
        id: 1,
        title: "Entity"
      },
      {
        id: 2,
        title: "Entity"
      },
      {
        id: 3,
        title: "Entity"
      }
    ]
  },
  {
    id: 2,
    type: "select",
    fieldName: "status",
    title: "Status",
    options: [
      {
        id: 1,
        title: "New"
      },
      {
        id: 2,
        title: "New"
      },
      {
        id: 3,
        title: "New"
      }
    ]
  },
  {
    id: 3,
    type: "dateRange",
    fieldName: "date",
    title: "Date"
  }
];
/*
const dropdown = [
  {
    id: 1,
    title: "Invite New Task"
  }
];
*/

export function TasksPage() {
  const history = useHistory();
  const [selectedTask, setSelectedTask] = useState([]);
  const [tasks, setTasks] = useState([]);

  const getTasks = () => {
    Axios.get("task")
      .then(({ data }) => {
        setTasks(data.tasks);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    setTasks(tasks);
  }, []);

  /* const getRows = () => {
    let rows = [];
    tasks.forEach(task => {
      rows.push({
        id: task.task_id,
        task: task.task_name,
        due_date: "10.09.2021",
        status: task.task_status
      });
    });
    return rows;
  };*/

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Tasks</div>
            </div>
           {/* <div className="card-toolbar">
              <ActionsDropdown options={dropdown} />
            </div>*/}
          </div>

          <div className="card-body">
            {/*<div className="col-lg-12">*/}
            {/*  <DataTableFilter filters={filters} />*/}
            {/*</div>*/}

            <div className="col-lg-12">
              <Table
                clickable
                cols={cols}
                entities={tasks}
                setEntities={setTasks}
                selectedRows={ selectedTask }
                setSelectedRows={ setSelectedTask }
                idField="task_id"
                rowEvents={{
                  onClick: (event, item) => {
                    history.push(`/tasks/${item.task_id}`);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
    /*<div className="card card-custom card-stretch gutter-b pb-5">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
        <span className="card-label font-weight-bolder text-dark">
          Tasks
        </span>
        <span className="text-muted mt-3 font-weight-bold font-size-sm">
          All tasks in this CSP
        </span>
        </h3>
      </div>
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
        <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
              <th className="p-0 w-80"></th>
              <th className="p-0 w-200px"></th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(item => (
                <tr className="btn-light cursor-pointer mb-2" key={ item.task_id } onClick={ () => onTaskClick(item) }>
                  <td>
                    <a
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {item.task_id} { item.task_name }
                    </a>
                  </td>
                  <td className="text-right">
                    { item.task_status }
                  </td>
                </tr>
              ))}
            </tbody>
        </table>
        </div>
      </div>
    </div>*/
  );
}
