import {downloadFile, openInBrowser} from '../../util';
import Axios from 'axios'

export const getDocument = async (document_id, document_title, documentScope = 'global') => {
  const response = await Axios.get(`/document/${document_id}?scope=${documentScope}`, {
    responseType: "blob",
  });
  await downloadFile(response, document_title);
};

export const openDocument = async (document_id, document_title, documentScope = 'global') => {
  const response = await Axios.get(`/document/${document_id}?scope=${documentScope}`, {
    responseType: "blob",
  });
  const blobData = response.data;
  openInBrowser(blobData);
};

export function setDocumentStatus(documentId, new_status, documentScope = 'global') {
  return Axios.put(`document/${ documentId }/status?scope=${ documentScope || "global" }`, {
    new_status
  })
}


export const getUserDocuments = async (userId, sortOptions = { field: "date", order: "desc" }) => {
  const params = new URLSearchParams([
    ['sort', sortOptions.field],
    ['order', sortOptions.order],
  ]);

  return Axios.get(`/user/${userId}/documents`, { params }).then(response => {
    if (response?.data)
      return response.data.user_documents;
  });
}
export function deleteDocument(document_id) {
  return Axios.delete('document/' + document_id)
}

export function uploadDocument(document){
  return Axios.post(`user/documents-upload`, document, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}