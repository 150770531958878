import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { TextField, makeStyles } from '@material-ui/core';
import { redirectToTenant } from "../../util";
import { useSelector } from "react-redux";
import { setInvitationUrl } from "../../redux/Url/urlActions";
import { getCSPs } from '../api/csp';

const useStyles = makeStyles();

export default function InvitePage(props) {
  const history = useHistory();
  const classes = useStyles();

  const invitation_code = props.match.params.code;

  const inviteUrl = useSelector(state => state.url.invitationUrl)

  useEffect(() => {
    if (inviteUrl) {
      setInvitationUrl("")
      return;
    }
  }, [inviteUrl])

  const [invitationInfo, setInvitationInfo] = useState({});
  const [additionalInvitationInfo, setAdditionalInvitationInfo] = useState({});

  const [rejectionReason, setRejectionReason] = useState("");

  const [rejectModalShow, setRejectModalShow] = useState(false);

  const handleRejectModalClose = () => setRejectModalShow(false);

  const getInvitationInfo = () => {
    Axios.get(`invitation?code=${ invitation_code }`)
    .then(({ data }) => {
      if(data.invitations.length === 0) {
        history.push("/dashboard");
      } else {
        // Check if user can accept the invitation
        if(data.invitations[0].invitation_status !== "pending") {
          history.push("/dashboard");
        }

        setInvitationInfo(data.invitations[0]);
        setAdditionalInvitationInfo(data.additional_info);
      }
    })
    .catch((error) => {
      if(error?.response?.data.error_code === "GENERAL_UNAUTHORIZED") {
        history.push("/");
      }
    });
  }

  const onRejectClick = () => {
    setRejectModalShow(true);
  }

  const rejectInvitation = () => {
    if(!rejectionReason) return;

    Axios.put('invitation/reject', {
      code: invitation_code,
      rejection_comment: rejectionReason
    })
    .then(() => {
      history.push("/");
    })
    .catch(() => {});
  }


  const acceptInvitation = () => {
    return Axios.put('invitation/accept', {
      code: invitation_code
    })
      .then(async ({ data }) => {
        // Redirect to csp creation if this is a csp admin invitation
        const related_tenant_slug = additionalInvitationInfo.tenant && additionalInvitationInfo.tenant.tenant_slug;

        let isNewCSP = false

        if (invitationInfo.invitation_role_scope === 'csp' && invitationInfo.invitation_new_role === 'admin' && data.csp_id) {
          isNewCSP = true
          history.push(`/create-csp/${data.csp_id}`);
        } else {
          if (related_tenant_slug) redirectToTenant(related_tenant_slug, true);
        }
        await getCSPs();
        return isNewCSP
      })
      .then((isNewCSP) => {
        if (!isNewCSP) history.push(`/dashboard`);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getInvitationInfo();
  }, []);

  return (
      <div
        className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <Modal show={rejectModalShow} onHide={handleRejectModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please, provide some info about why you reject this invitation. This message will be sent to the user that has created the invitation.
            </p>

            <TextField
              label="Rejection comment"
              multiline
              value={rejectionReason}
              onChange={ e => setRejectionReason(e.target.value) }
              className={classes.textField}
              margin="normal"
              rows={ 5 }
              variant="outlined"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleRejectModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={rejectInvitation}>
              Reject invitation
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="d-flex flex-column-fluid flex-center">
          <div className="login-form login-signin">
            <div className="pb-13 pt-lg-0 pt-5 text-center">
            <h3
                className="font-weight-bolder text-dark font-size-h4 mb-4 font-size-h1-lg"
            >
                Invitation
            </h3>
            {invitationInfo.invitation_role_scope ? (
              <span>
                  {additionalInvitationInfo.entity_invite ? (
                    <div className="text-left" style={{ maxWidth: "800px" }}>
                      <p>Dear <strong>{ invitationInfo.invitee_name }</strong>,</p>

                      <p><strong>{ additionalInvitationInfo.entity_invite.entity_name }</strong> has been invited by <strong>{ additionalInvitationInfo.entity_invite.parent_entity_admin.user_full_name }</strong> to join <strong>{ additionalInvitationInfo.entity_invite.parent_entity_name }</strong> as a <strong>{ invitationInfo.invitation_role_scope } { invitationInfo.invitation_new_role }</strong>.</p>

                      <p>You have been identified as the primary point of contact for <strong>{ additionalInvitationInfo.entity_invite.entity_name }</strong>.
                      Please click on the link below to accept the invitation and Sign-up/Sign-in to the Online Portal to complete the due diligence process.</p>

                      <p>You have been identified as the primary point of contact with the legal authority to act on behalf of <strong>{ additionalInvitationInfo.entity_invite.entity_name }</strong>, a <strong>{ additionalInvitationInfo.entity_invite.entity_type }</strong> registered in the <strong>{ additionalInvitationInfo.entity_invite.entity_jurisdiction }</strong>.</p>

                      <p>By sumbitting this form, you are certifying that you have the legal authority to to act on behalf of <strong>{ additionalInvitationInfo.entity_invite.entity_name }</strong>.</p>

                      <p>Is this accurate and do you accept this role?</p>

                      <p><strong>Warning! Upon accepting this invitation your account will be converted to a company-account</strong></p>
                    </div>
                  ) : (
                    <>
                      You have been invited by <b>{ invitationInfo.user.user_full_name }</b> to become <b>{ invitationInfo.invitation_role_scope } { invitationInfo.invitation_new_role }</b>
                      { additionalInvitationInfo.csp && <> at <b>{ additionalInvitationInfo.csp.csp_name }</b> CSP</> }
                      { additionalInvitationInfo.customer_company && <> at <b>{ additionalInvitationInfo.customer_company.company_name }</b></> }.
                    </>
                  )}
              </span>
            ) : (
              <span>Loading...</span>
            )}
            </div>

            {invitationInfo.invitation_role_scope && (
              <div className="pt-10 pt-lg-0 d-flex flex-center">
                <button
                  className="btn text-black-50 font-size-h6 px-8 py-4 my-3 w-150px"
                  onClick={ onRejectClick }
                >
                  Reject
                </button>
                <button
                  className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-150px"
                  onClick={ acceptInvitation }
                >
                  Accept
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
