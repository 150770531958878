import React from 'react'
import { Modal } from "react-bootstrap";
import { MenuItem, TextField } from "@material-ui/core";
import "./style.css"
import CountrySelect from '../../../components/Dropdown/CountrySelect';
import SaveButton from '../../../components/_common/SaveButton';

export default function StaffWindow({
  showPersonnelModal,
  staffErrors,
  personnel,
  jurisdictions,
  roles,
  setShowPersonnelModal,
  editField,
  saveAbstraction,
  loading
}) {

  return (
    <Modal show={showPersonnelModal} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Add Controlling Person
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row align-items-start">
            <div className="col">
              <div className="font-size-h5 font-weight-bold mb-3">Controlling Person Details:</div>
              <TextField
                error={staffErrors.type}
                helperText={staffErrors.type}
                type="text"
                select
                variant="outlined"
                label="Type"
                name="type"
                className="w-100"
                onChange={e => { editField(e.target.name, e.target.value) }}
                value={personnel.type}
              >
                <MenuItem value="human">Individual</MenuItem>
                <MenuItem value="entity">Entity</MenuItem>
              </TextField>
              {personnel.type === "entity" ? (
                <>
                  <TextField
                    error={staffErrors.entity_name}
                    helperText={staffErrors.entity_name}
                    label="Entity name"
                    type="text"
                    variant="outlined"
                    className="w-100 mt-5"
                    name="entity_name"
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={personnel.entity_name}
                  />

                  <CountrySelect
                    error={staffErrors.jurisdiction}
                    helperText={staffErrors.jurisdiction}
                    options={jurisdictions}
                    label="Jurisdiction"
                    className="w-100 mt-5"
                    name="jurisdiction"
                    onChange={value => editField('jurisdiction', value)}
                    value={personnel.jurisdiction}
                  />

                  <TextField
                    label="Registration #"
                    type="text"
                    className="mt-5 w-100"
                    variant="outlined"
                    name="entity_registration_number"
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={personnel.entity_registration_number}
                  />
                  {(staffErrors.entity_registration_number) ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{staffErrors.entity_registration_number}</div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {personnel.type === "human" ? (
                <>
                  <TextField
                    error={staffErrors.name}
                    helperText={staffErrors.name}
                    label="Name"
                    type="text"
                    className="w-100 mt-5"
                    variant="outlined"
                    name={`name`}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={personnel.name}
                  />

                  <TextField
                    label="Email"
                    type="email"
                    className="w-100 mt-5"
                    variant="outlined"
                    name={`email_address`}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={personnel.email_address}
                    error={staffErrors.email_address}
                    helperText={staffErrors.email_address}
                  />
                </>
              ) : null}
            </div>
            <div className="col">
              <div className="font-size-h5 font-weight-bold mb-3">Role Details</div>
              <TextField
                error={staffErrors.role}
                helperText={staffErrors.role}
                type="text"
                select
                variant="outlined"
                label="Role"
                name="role"
                className="w-100"
                autoComplete="off"
                onChange={e => editField(e.target.name, e.target.value)}
                value={personnel.role}
              >
                {
                  roles.map(role => (<MenuItem key={role.value} value={role.value}>{role.title}</MenuItem>))
                }
              </TextField>
              {personnel.role !== "director" ? (
                <>
                  <TextField
                    error={staffErrors.position}
                    helperText={staffErrors.position}
                    label="Position"
                    type="text"
                    variant="outlined"
                    className="w-100 mt-5"
                    name="position"
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={personnel.position}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-btn font-weight-bold px-9 py-4 my-3" onClick={() => {
          setShowPersonnelModal(false)
        }}>
          Cancel
        </button>
        <SaveButton loading={loading} className="submit-btn font-weight-bold px-9 py-4 my-3" onClick={e => { e.preventDefault(); saveAbstraction() }}>
          Save
        </SaveButton>
      </Modal.Footer>
    </Modal>
  )
}