import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/Dashboard/DashboardPage";
import { CSPPage } from "./pages/CSPPage";
import { CSPUsersPage } from "./pages/CSPUsersPage";
import { CompanyUsersPage } from "./pages/CompanyUsersPage";
import { TasksPage } from "./pages/TasksPage";
import ApprovalRequestsPage from "./pages/ApprovalRequestsPage";
import TaskPage from "./pages/TaskPage";
import EntityProfilePage from "./pages/entity-profile/EntityProfilePage";
import UserProfilePage from "./pages/user-profile/UserProfilePage";
import CSPConfigPage from "./pages/CSPConfigPage";
import { useSelector } from "react-redux";
import ClientEntitiesPage from "./pages/ClientEntitiesPage";
import CSPESNPage from "./pages/CSPESNPage";
import AddNewClientRoute from "./pages/AddNewClient/AddNewClientRoute";
import LinkPage from "./pages/LinkPage";
import {USER_ROLE} from '../constants';
import {InvitationsPage} from './pages/InvitationsPage';

export default function BasePage() {

  const {user_csp_role, ...user} = useSelector(state => state.auth.user)
  const isCSP = user_csp_role === USER_ROLE.CSP_USER || user_csp_role === USER_ROLE.CSP_ADMIN

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <div className="container">
        <Switch>
        <Route path="/l/:code" component={LinkPage} />
        {
          (isCSP || !!user.global_user_role) && (
           [
             <ContentRoute path="/dashboard" component={DashboardPage} />,
             <ContentRoute path="/invitations" component={InvitationsPage} />,
             <ContentRoute path="/entity-users" component={CompanyUsersPage} />,
             <ContentRoute path="/csp-config" component={CSPConfigPage} />,
             <ContentRoute path="/esn" component={CSPESNPage} />,
             <ContentRoute path="/csp-users" component={CSPUsersPage} />,
             <ContentRoute path="/entities" component={ClientEntitiesPage} />
           ]
          )
        }
        <ContentRoute path="/csp/:csp_id" component={CSPPage} />
        <ContentRoute path="/tasks/:task_id" component={TaskPage} />
        <ContentRoute path="/tasks" component={TasksPage} />
        <ContentRoute path="/approval-requests" component={ApprovalRequestsPage} />
        <ContentRoute path="/entity/:entity_id" component={EntityProfilePage} />
        <ContentRoute path="/user/:user_id" component={UserProfilePage} />
        <ContentRoute path="/add-new-client" component={AddNewClientRoute} />
        <ContentRoute path="/builder" component={BuilderPage} />
        {
          (!!user_csp_role || !!user.global_user_role)
          ? <Redirect to="/dashboard" />
          : <Redirect to={`/user/${user.user_id}`} />
        }
        </Switch>
      </div>
    </Suspense>
  );
}
