/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './ProfilePage.css';
import { useSecondAside } from '../../_metronic/layout/components/aside/SecondAside';
import GeneralCSPProfileSettings from './csp-config-page/GeneralCSPProfileSettings';
import ConfigRoute from './csp-config-page/ConfigRoute';
import TemplatesRoute from './csp-config-page/TemapltesRoute';
import FeesConfigRoute from './csp-config-page/FeesConfigRoute';
import VaultRoute from './csp-config-page/VaultRoute';

function CSPConfigPage(props) {
  useSecondAside([
    {
      name: 'General Settings',
      path: '/csp-config/general',
      icon: "/media/svg/icons/General/Settings-1.svg"
    },
    {
      name: 'Config',
      path: '/csp-config/config',
      icon: "/media/svg/icons/General/Settings-2.svg"
    },
    {
      name: 'Fees config',
      path: '/csp-config/fees-config',
      icon: "/media/svg/icons/Shopping/Dollar.svg"
    },
    {
      name: 'Templates',
      path: '/csp-config/templates',
      icon: "/media/svg/icons/Files/Compilation.svg"
    },
    {
      name: 'Vault',
      path: '/csp-config/vault',
      icon: "/media/svg/icons/Files/Folder-cloud.svg"
    }
  ]);
  return (
    <div className="container">
      <div className="col-lg-12">
        <div className="card card-custom">
          <Switch>
            <Route path="/csp-config/general" exact component={GeneralCSPProfileSettings} />
            <Route path="/csp-config/config" exact component={ConfigRoute} />
            <Route path="/csp-config/templates" component={TemplatesRoute} />
            <Route path="/csp-config/fees-config" component={FeesConfigRoute} />
            <Route path="/csp-config/vault" component={VaultRoute} />
            <Route path="/csp-config">
              <Redirect to="/csp-config/general" />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default CSPConfigPage;
