import React from 'react'
import { MenuItem, TextField } from "@material-ui/core";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import * as Yup from "yup";
import "./style.css"

const page1_validation_schema = Yup.object({
  entity_category: Yup.string()
    .required("Entity category is required"),
  jurisdiction: Yup.string()
    .required("Jurisdiction is required"),
  entity_name: Yup.string()
    .required("Entity name is required"),
  entity_type: Yup.string()
    .required("Entity type is required"),
  duration: Yup.string().when('entity_type', {
    is: "exempt-limited-duration",
    then: Yup.string().required('Duration is required'),
  }).when('entity_type', {
    is: "exempt-limited-duration-foundation",
    then: Yup.string().required('Duration is required'),
  }),
});


export default function CreateCompanyPage1(
  {
    onQuestionaryValueChange,
    questionaryAnswers,
    configReady,
    config_questionary_fields,
    formik,
    currentPage,
    setCurrentPage,
    loading,
    goBack,
    entityCategories,
    entityTypes,
    jurisdictions
  }) {

  const [errors, setErrors] = React.useState({});

  const handleQuestionaryInfoChange = (e) => {
    onQuestionaryValueChange(e);
    if (errors && errors[e.target.name]) {
      errors[e.target.name] = undefined;

      setErrors(errors);
    }
  }

  const validate = async () => {
    let validation_ok = true;
    const new_errors = {};

    await page1_validation_schema.validate(questionaryAnswers, { abortEarly: false })

      .catch(validation_errors => {
        console.log(validation_errors)
        validation_ok = false;
        for (const err of validation_errors.inner) {
          console.log("Error", err)
          if (!new_errors.entity_category && !questionaryAnswers.entity_category) {
            new_errors.entity_category = "Entity category is required"
          }
          if (!new_errors.entity_type && !questionaryAnswers.entity_type) {
            new_errors.entity_type = "Entity type is required"
          }
          if (!new_errors.entity_name && !questionaryAnswers.entity_name) {
            new_errors.entity_name = "Entity name is required"
          }
          if (!new_errors.jurisdiction && !questionaryAnswers.jurisdiction) {
            new_errors.jurisdiction = "Jurisdiction is required"
          }


          new_errors[err.path] = err.message;

          // path is the field name, message is a user-friendly message that we have provided to Yup ourselves
        }
      });

    setErrors({ ...new_errors });
    return validation_ok;
  };


  const handleSubmit = async () => {
    const ok = await validate();
    if (ok) {
      setCurrentPage(currentPage + 1);
    } else {
      notify({ type: "ERROR", title: "Validation error", message: "There are some fields that need your attention" })
    }
  }

  return (
    <>
      {/* Static fields (the same for all csps and can not be removed) */}
      <div className="font-size-h2 font-weight-bold mb-4 mt-6 ml-2 form-header">Entity Type and Entity Name</div>
      <div className="form-group fv-plugins-icon-container">
        <TextField
          className="form-control h-auto  rounded-lg font-size-h6"
          type="text"
          select
          variant='outlined'
          helperText="This is the Jurisdiction where this entity will be registered"
          label="Jurisdiction"
          name="jurisdiction"
          autoComplete="off"
          onChange={e => handleQuestionaryInfoChange(e)}
          value={questionaryAnswers.jurisdiction}
        >
          {jurisdictions.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
          ))}

        </TextField>
        {(errors && errors.jurisdiction) ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errors.jurisdiction}</div>
          </div>
        ) : null}

      </div>

      <div className="form-group fv-plugins-icon-container">
        <TextField
          className="form-control h-auto  rounded-lg font-size-h6"
          type="text"
          select
          variant='outlined'
          label="Entity Category"
          name="entity_category"
          autoComplete="off"
          onChange={e => handleQuestionaryInfoChange(e)}
          value={questionaryAnswers.entity_category}
        >
          {entityCategories.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
          ))}
        </TextField>
        {(errors && errors.entity_category) ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errors.entity_category}</div>
          </div>
        ) : null}
      </div>
      {questionaryAnswers.entity_category ? (<div className="form-group fv-plugins-icon-container">
        <TextField
          className="form-control h-auto  rounded-lg font-size-h6"
          type="text"
          select
          variant='outlined'
          label="Entity Type"
          name="entity_type"
          autoComplete="off"
          onChange={e => handleQuestionaryInfoChange(e)}
          value={questionaryAnswers.entity_type}
        >
          {
            entityTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
            ))
          }
        </TextField>
        {(errors && errors.entity_type) ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errors.entity_type}</div>
          </div>
        ) : null}
      </div>) : null}

      {(questionaryAnswers.entity_type && questionaryAnswers.entity_type.indexOf("duration") !== -1) ? (
        <div className="form-group fv-plugins-icon-container">
          <TextField
            className="form-control h-auto  rounded-lg font-size-h6"
            type="number"
            variant="outlined"
            InputProps={{
              inputProps: {
                min: 1
              }
            }}
            helperText="Years"
            label="Duration"
            name="duration"
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            autoComplete="off"
            onChange={e => handleQuestionaryInfoChange(e)}
            value={questionaryAnswers.duration}
          >
          </TextField>
          {(errors && errors.duration) ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.duration}</div>
            </div>
          ) : null}

        </div>
      ) : null}


      <div className="form-group fv-plugins-icon-container">
        <TextField
          className="form-control h-auto  rounded-lg font-size-h6"
          type="text"
          variant='outlined'
          helperText="What is your preferred name for this entity?"
          label="Entity name"
          name="entity_name"
          autoComplete="off"
          onChange={e => handleQuestionaryInfoChange(e)}
          /*   onChange={ onQuestionaryValueChange }*/
          value={questionaryAnswers.entity_name}
        />
        {(errors && errors.entity_name) ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errors.entity_name}</div>
          </div>
        ) : null}
      </div>

      {configReady && config_questionary_fields.map(field => (
        <>
          {/* Show the field EITHER if the currenly selected options such as the entity_type/partnership_type/etc are the
                ones that have to be selected to show this field, OR just show it without looking at the previously selected
                options if the field is not dependent on the entity_type/company_type/partnership_type.

                TODO: @cleanup Rework this if statement. It is such a mess
            */}
          {((!field.entity_type || field.entity_type === questionaryAnswers.entity_category)
            && ((field.company_or_partnership_types && field.company_or_partnership_types.length === 0)
              || (field.company_or_partnership_types && (
                field.company_or_partnership_types.includes(questionaryAnswers.company_type)
                || field.company_or_partnership_types.includes(questionaryAnswers.partnership_type)
              )
              )
            )) && (
              <div key={field.field_name} className="form-group fv-plugins-icon-container">
                {field.field_type === "text" && (
                  <TextField
                    label={field.field_title}
                    type="text"
                    variant='outlined'
                    className="form-control h-auto "
                    name={field.field_name}
                    onChange={onQuestionaryValueChange}
                    value={questionaryAnswers[field.field_name]}
                  />
                )}

                {field.field_type === "select" && (
                  <select
                    style={{ height: "55px" }}
                    className="form-control "
                    type="number"
                    placeholder={field.field_title}
                    name={field.field_name}
                    autoComplete="off"
                    onChange={onQuestionaryValueChange}
                    value={questionaryAnswers[field.field_name]}
                  >
                    {/* TODO @cleanup @refactor. This is not good */}
                    {field.jurisdiction_region_field_options ? (
                      <>
                        {field.jurisdiction_region_field_options.map(item => (
                          <option key={item.option_id} value={item.option_value}>{item.option_text}</option>
                        ))}
                      </>
                    ) : (
                      <>
                        {field.csp_questionary_options.map(item => (
                          <MenuItem key={item.option_id} value={item.option_value}>{item.option_text}</MenuItem>
                        ))}
                      </>
                    )}
                  </select>
                )}
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
            )}
        </>
      ))}
      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="cancel-btn font-weight-bold px-9 py-4 my-3"
          onClick={goBack}
        >Cancel</button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="submit-btn font-weight-bold px-9 py-4 my-3"
        >
          <span>
            Next
          </span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>

      </div>
    </>
  )
}
