/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../../_metronic/_partials/dropdowns';
import { Menu } from '@material-ui/core';

import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

export default function MoreDetailsDropdown({ options = [], row, className, children}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!!anchorEl) {
      document.body.style.setProperty('overflow', 'hidden', 'important');
    } else {
      document.body.style.setProperty('overflow', 'unset', 'important');
    }
  }, [anchorEl]);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Dropdown.Toggle
        onClick={handleClick}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        variant="transparent"
        as={DropdownCustomToggler}
      >
        {children ? children : <i className="ki ki-bold-more-hor" />}
      </Dropdown.Toggle>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id="simple-menu"
        // className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ul className="navi navi-hover">
          {options.map(option => {
            if (option.visible === false) return null
            
            if (option.render === undefined || option.render){
              return (
                <li key={option.id} className="navi-item">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(null);
                      if (!option.disabled)
                      option.onClick(e, row);
                    }}
                    className={`navi-link`}
                  >
                    {option.icon && (
                      <span className="d-flex mr-2 svg-icon svg-icon-dark-75 svg-icon-sm">
                      <SVG src={toAbsoluteUrl(option.icon)} />
                    </span>
                    )}
                    <span className="navi-text">{option.title}</span>
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </Menu>
    </div>
  );
}