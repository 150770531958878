import moment from 'moment';
import {USER_ROLE} from '../../constants';

export function capitalize(string) {
  return string.length >= 2 ? string[0].toUpperCase() + string.slice(1) : string;
}

export function getDate(value, time) {
  if (!value && !time) {
    return moment().format();
  } else if (value < 0) {
    return moment()
      .subtract(Math.abs(value), time)
      .format();
  }
  return moment()
    .add(value, time)
    .format();
}

export function createEventObject(e, name, value) {
  return { ...e, target: { ...e.target, name, value } }
}

export function isCSP(role){
  return [USER_ROLE.CSP_USER, USER_ROLE.CSP_ADMIN].includes(role)
}