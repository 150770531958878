import React from 'react'

export default function HDDDeclaration({
  onPageSubmit,
  cspInfo,
  formik,
  goBack,
  loading
}) {

  return (
    <form onSubmit={onPageSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="form-group fv-plugins-icon-container">
        <p>
          I have completed this form to the best of my knowledge and have answered all questions truthfully and
          accurately. I undertake to notify {cspInfo.csp_name} in the event of any changes arising at the earliest
          opportunity
        </p>

        <p>
          I agree to indemnify {cspInfo.csp_name} and hold them harmless against any and all liability and harm
          should any of the information that I have provided prove to be false, misleading or untruthful in any way.
        </p>

        <p>
          I hereby authorise {cspInfo.csp_name} to make all such enquiries and share the contents of this form with
          approved third parties for the purpose of validating the information contained herein, or any related
          document that Is connected to this form, as well as to conduct due diligence and background checks.
        </p>

        <p>
          I understand that {cspInfo.csp_name} may hold personal data about me in electronic form and I hereby
          expressly consent to such personal and private data about me being stored in such manner. I also agree
          that communications between {cspInfo.csp_name} and myself shall continue to be carried out in electronic
          form and I accept, understand and agree to that and the risks involved.
        </p>

        <p>
          I understand that I am solely responsible for filing of tax returns in my country or countries of
          residence, domicile and potentially birth, and hereby absolve {cspInfo.csp_name} of any responsibility to
          do so on my behalf. I confirm that I shall file the necessary returns in order to be compliant in all
          relevant jurisdictions. I also understand that {cspInfo.csp_name} may be obliged to share certain
          information about me, in my capacity as Director, Shareholder, Beneficial Owner or Officer, with both
          domestic and international tax authorities, and expressly consent to that
        </p>

        <p>
          I agree to indemnify {cspInfo.csp_name} and hold {cspInfo.csp_name} harmless against any and all liability
          should I fail to perform the necessary reporting.
        </p>

        <p>
          <strong>By clicking finish you agree to all of the above</strong>
        </p>
      </div>
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 text-center w-100"
          onClick={e => formik.handleSubmit(e)}
        >
          TEST FINISH
        </button>
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Back
        </button>
        <button
          id="kt_create_csp_submit"
          type="submit"
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100"
        >
          <span>Finish</span>
          {loading && <span className="ml-3 spinner spinner-white" />}
        </button>
      </div>
    </form>
  )
}