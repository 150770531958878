export const menuDetails = [
	{
		name: "Home",
		path: `/user/{{user_id}}`,
		icon: "/media/svg/icons/Home/Home.svg"
	},
	{
		id: 1,
		name: "Profile",
		icon: "/media/svg/icons/General/User.svg",
		submenu: [
			{
				title: "Details",
				path: `/user/{{user_id}}/details`
			},
			{
				title: "Address",
				path: `/user/{{user_id}}/address`
			},
			{
				title: "Due Diligence",
				path: `/user/{{user_id}}/due-diligence`
			},
		]
	},
	{
		name: "Vault",
		path: `/user/{{user_id}}/vault`,
		icon: "/media/svg/icons/Files/Folder-cloud.svg"
	},
	{
		name: "Related Entities",
		icon: "/media/svg/icons/Home/Building.svg",
		submenu: [
			{
				title: "Entities",
				path: `/user/{{user_id}}/related_entities/entities`
			},
			{
				title: "History",
				path: `/user/{{user_id}}/related_entities/history`
			},

		]
	},
	{
		name: "Compliance",
		path: `/user/{{user_id}}/compliance`,
		icon: "/media/svg/icons/General/Shield-check.svg",
	}
];

