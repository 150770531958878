import React from 'react'
import BaseFieldsConfiguration from '../../../../components/EditProfileForm/BaseFieldsConfiguration'
import SaveButton from '../../../../components/_common/SaveButton'
import { Table } from '../../../../modules/Auth/pages/customers/customers-table/CustomersTable'

export default function StakeholderRRSection({
  stakeholderRiskOptions,
  editStakeholderRR,
  stakeHoldersRR,
  stakeholders,
  stakeholdersTableCols,
  stakeholderDecisionLoading,
  saveStakeholderDecision
}) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title w-25">
                Stakeholder Risk
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-7">
                  {
                    stakeholderRiskOptions.map(option =>
                    (<BaseFieldsConfiguration
                      option={option}
                      editTextFieldValue={editStakeholderRR}
                      errors={{}}
                      value={stakeHoldersRR}
                    />))
                  }
                </div>
                <div className="col-lg-3"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <Table nonSelectable entities={stakeholders} cols={stakeholdersTableCols} />
                </div>
                <div className="col-1"></div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end">
              <SaveButton title="Save decision" loading={stakeholderDecisionLoading} onClick={() => saveStakeholderDecision()} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}