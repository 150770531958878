import { Accordion, Button, Card, Dropdown, Modal } from 'react-bootstrap';
import React from 'react';

export function CustomDropdown({ title, items, component, ...props }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={props.as} variant={props.variant} className={props.className}>
        {component ? component() : null} {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map(({ disabled, title, onClick, link }, key) => (
          <Dropdown.Item href="#" {...{ disabled, title, onClick, key }}>
            {title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function CustomDialog({ title, show, onClose, onSave, ...props }) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {props.closeTitle || 'Close'}
        </Button>
        <Button variant="primary" onClick={onSave}>
          {props.confirmTitle || 'Save Changes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function CustomAccordion({ items, ...props }) {
  return (
    <Accordion defaultActiveKey="0">
      {items.map((item, key) => (
        <Card key={key}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={key}>
              {item.title}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={key}>
            <Card.Body>{item.body}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
}

