import { Checkbox } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'

const LoadingCheckbox = ({ checked, onChange }) => {
  const [loading, setLoading] = useState(false)

  return (<Checkbox
    disabled={loading}
    icon={(loading && <span className="spinner-border spinner-border-sm" style={{ height: '1em', width: '1em' }} role="status" aria-hidden="true" />) || undefined}
    checkedIcon={(loading && <span className="spinner-border spinner-border-sm" style={{ height: '1em', width: '1em' }} role="status" aria-hidden="true" />) || undefined}
    checked={checked}
    onChange={
      async e => {
        try {
          setLoading(true)
          await onChange(e)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }

      }
    } />)
}

export default LoadingCheckbox