import React, { useState, useEffect } from "react";
import Axios from "axios";
import AdminsWidget from "../../../components/EPHouseRoute/AdminsWidget";
import NotificationWidget from "../../../components/EPHouseRoute/NotificationWidget";
import TasksWidget from "../../../components/EPHouseRoute/TasksWidget";

export default function HomeRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const getTasks = () => {
    Axios.get(`company/${ props.basicEntityInfo.company_id }/tasks?limit=5`)
    .then(response => {
      if(response.data && response.data.tasks) {
        props.appendAdditionalInfo({
          company_tasks: response.data.tasks
        })
      }

      setCurrentCompanyId(props.basicEntityInfo.company_id);
    })
    .catch(() => {
    });
  }

  useEffect(() => {
    if(props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId && !props.additionalEntityInfo.company_tasks) {
      getTasks();
    }
  }, [props.basicEntityInfo.company_id, props.additionalEntityInfo.company_tasks])

  return (
    <>
      <div className="col-lg-12">
        <AdminsWidget admins={ props.basicEntityInfo ? props.basicEntityInfo.admin_users : [] } />
      </div>

      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6">
            <TasksWidget tasks={ props.additionalEntityInfo.company_tasks } />
          </div>
          <div className="col-lg-6">
            <NotificationWidget />
          </div>
        </div>
      </div>

      {/* <div className="col-lg-12">
        <FillingStatusWidget />
      </div> */}
    </>
  );
}
