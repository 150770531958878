import React from "react";
import ProfileViewWidget from "../../../components/ProfileWiewVidget/ProfileViewWidget";
import {WidgetTable} from "../../../components/WidgetTable/WidgetTable";

const Risk_items = [
  {
    name: "Select ... (High or Standard)",
    value: ""
  },
  {
    name: "Date Last Certified:",
    value: "dd-mm-yyyy"
  },
  {
    name: "Next Review Date:",
    value: "dd-mm-yyyy"
  }
];

const WorldcheckData_items = [
  {
    name: "Article #1",
    value: ""
  },
  {
    name: "Media item 2",
    value: ""
  }
];

const ClientRisk_items = [
    {
        name: "Country Incorporated: ",
        value: "Select Risk Rating..."
    },
    {
        name: "Country Operated:: ",
        value: "Select Risk Rating..."
    },
    {
        name: "Entity Type: ",
        value: "Select Risk Rating..."
    },
    {
        name: "Industry Risk: ",
        value: "Select Risk Rating..."
    },
    {
        name: "Entity Purpose: ",
        value: "Select Risk Rating..."
    }
]

const ServiceRisk_items = [
    {
        name: "Number of employees",
        value: "Select Risk Rating..."
    },
    {
        name: "TurnOver",
        value: "Select Risk Rating..."
    },
    {
        name: "Cash Dealings",
        value: "Select Risk Rating..."
    },
    {
        name: "Import/Expert",
        value: "Select Risk Rating..."
    },
    {
        name: "Produces Financial Statements",
        value: "Select Risk Rating..."
    }
]

const notesCols = [
    {
        name: "user",
        title: "CSP User",
        width: 250
    },
    {
        name: "date",
        title: "Date",
        width: 250
    },
    {
        name: "note",
        title: "Note",
        width: 250
    }
];

const notesRows = [
    {
        id: 1,
        user: "CSP User 1",
        date: "20.08.2021",
        note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    },
    {
        id: 2,
        user: "CSP User 2",
        date: "17.08.2021",
        note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    }
];


export default function ComplianceOverviewRoute() {
    return (
        <>
            <div className="col-lg-12">
                <div className="row">
                    <div className=" h-50 col-lg-6">
                        <ProfileViewWidget
                           /* action={(<a href="/metronic8/demo1/../demo1/account/settings.html" className="btn btn-primary align-self-center">Edit</a>)}*/
                            title="Summary Risk Rating"
                            items={Risk_items}
                        />
                    </div>
                    <div className="col-lg-6">
                        <ProfileViewWidget
                            title="API Worldcheck Data"
                            items={WorldcheckData_items}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-6">
                        <ProfileViewWidget
                            title="Client Entity Risk"
                            items={ClientRisk_items}
                        />
                    </div>
                    <div className="col-lg-6">
                        <ProfileViewWidget
                            title="Service Risk"
                            items={ServiceRisk_items}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <WidgetTable
                    {...{
                        cols: notesCols,
                        rows: notesRows,
                        title: "Notes"
                    }}
                />
            </div>
        </>
    )
}
