import React, { useEffect, useState } from "react";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import { getShareholders } from "../../../api/company";
import useRequest from "../../../components/useRequest/useRequest";

const cols = [
  {
    id: 1,
    name: 'name',
    title: 'Name',
    width: 600
  },
  {
    id: 2,
    name: 'type',
    title: 'Type',
    width: 300
  },
  {
    id: 3,
    name: 'percentage',
    title: 'Ownership, %',
    width: 300
  },
]

export default function OwnersRoute({entity_id}) {
  const [owners, setOwners] = useState([])

  const [fetchShareholders] = useRequest(() => getShareholders(entity_id), setOwners)

  useEffect(() => {
    fetchShareholders()
  }, [])

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Owners</div>
            </div>
          </div>

          <div className="card-body">
            <Table
              setEntities={() => void 0}
              entities={owners}
              cols={cols}
            />
          </div>
        </div>
      </div>
    </>
  );
}
