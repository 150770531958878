/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useMemo, useEffect} from 'react';
import {Tab, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import {useHtmlClassService} from '../../../_core/MetronicLayout';
import {toAbsoluteUrl} from '../../../../_helpers';
import {DropdownTopbarItemToggler} from '../../../../_partials/dropdowns';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {
	getAllUserNotifications, getUserNotificationsCount
} from '../../../../../redux/UserNotifications/userNotificationsActions';
import {Badge, IconButton, makeStyles} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { setNotificationStatus } from '../../../../../app/api/user';
import { notificationStatus } from '../../../../../constants';

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
};

const useStyles = makeStyles(() => ({
  notificationWrapper: {
    '& .close-notification': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& .close-notification': {
        visibility: 'visible'
      }
    }
  }
}));

export function getNotificationLink({notification_type, notification_json}, csp_id='') {
  const parsed = JSON.parse(notification_json);
	if (notification_type === 'hdd')
		return '/staff-onboarding';
  if (parsed) {
    if (notification_type === 'edd') {
      return `/entity-due-diligence?entity_id=${parsed.company_id}&csp_id=${csp_id}`;
    } else if (notification_type === 'csp_creation_finalization') {
      return `/create-csp/${parsed.csp_id}`;
    } else if (parsed.invitation && parsed.invitation.invitation_code) {
      return '/invite/' + parsed.invitation.invitation_code;
    }
  }
  return '/';
}

export function UserNotificationsDropdown() {
	const [key, setKey] = useState('Alerts');
	const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

	const styles = useStyles()

	const {
		notifications,
		notifications_count,
		new_notifications_count
	} = useSelector(state => state.userNotifications);

	const csp_id = useSelector(state => state.tenant.csp?.csp_id)

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			offcanvas:
				objectPath.get(uiService.config, 'extras.notifications.layout') ===
				'offcanvas'
		};
	}, [uiService]);

	const [toggle, setToggle] = useState(false);

	const eventListener = () => {
		if (!toggle) getAllUserNotifications();
		setToggle(oldToggle => !oldToggle);
	};

	useEffect(() => {
		document.querySelector('#kt_quick_notifications_toggle').addEventListener('click', eventListener);

		return () => document.querySelector('#kt_quick_notifications_toggle').removeEventListener('click', eventListener);
	}, [new_notifications_count, toggle]);

	function Notification({ notification }) {
		const link = getNotificationLink(notification, csp_id);
		const {
			notification_status,
			notification_id,
			notification_title,
			notification_message,
		} = notification;

		return (
			<div
				key={notification_id}
				className={`d-flex align-items-center mb-6 ${styles.notificationWrapper}`}
			>
				<div className="symbol symbol-40 symbol-light-primary mr-5">
					<Badge
						color="secondary"
						invisible={notification_status !== 'new'}
						variant="dot"
					>
              <span className="symbol-label">
                <SVG
									src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')}
									className="svg-icon-lg svg-icon-primary"
								/>
              </span>
					</Badge>
				</div>
				<div className="d-flex flex-column font-weight-bold">
					<NavLink
						to={link}
						className="text-dark text-hover-primary mb-1 font-size-lg link"
					>
						{notification_title}
					</NavLink>
					<span className="text-muted">
              {notification_message}
					</span>
				</div>
				<div className="align-self-start close-notification">
					<IconButton onClick={() =>
						setNotificationStatus(
							notification_id,
							notificationStatus.reviewed
						)
							.then(() => getAllUserNotifications())
							.then(() => getUserNotificationsCount())
					}
					>
						<Close />
					</IconButton>
				</div>
			</div>
		);
	}

	return (
		<>
			{layoutProps.offcanvas && (
				<div className="topbar-item">
					<div
						className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
						id="kt_quick_notifications_toggle"
					>
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')}/>
            </span>
						{new_notifications_count && (<span className='pulse-ring'/>)}
					</div>
				</div>
			)}
			{!layoutProps.offcanvas && (
				<Dropdown drop="down" alignRight>
					<Dropdown.Toggle
						as={DropdownTopbarItemToggler}
						id="kt_quick_notifications_toggle"
					>
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip id="user-notification-tooltip">
									User Notifications
								</Tooltip>
							}
						>

							<div
								className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
								id="kt_quick_notifications_toggle"
							>
								<Badge
									// overlap="circular"
									badgeContent={new_notifications_count}
									color="secondary"
								>
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
											src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')}
										/>
                  </span>
								</Badge>
								{!!new_notifications_count && (<span className='pulse-ring'/>)}
								{!!new_notifications_count && (<span className='pulse-ring'/>)}
							</div>

						</OverlayTrigger>
					</Dropdown.Toggle>

					<Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
						<form>
							{/** Head */}
							<div
								className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
								style={{backgroundImage: `url(${bgImage})`}}
							>
								<h4 className="d-flex flex-center rounded-top">
									<span className="text-white">User Notifications</span>
									<span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {notifications_count}
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {new_notifications_count ? (
													notifications.map(notification => {
                            return notification.notification_status === 'new'
															&& <Notification notification={notification} />;
                          })
                        ) : (
                          <div className="w-100 d-flex flex-column align-items-center">
                            {"You haven't any notifications"}
                          </div>
                        )}
											</PerfectScrollbar>
										</Tab.Pane>
										<Tab.Pane
											eventKey="Events"
											id="topbar_notifications_events"
										>
											<PerfectScrollbar
												options={perfectScrollbarOptions}
												className="navi navi-hover scroll my-4"
												style={{maxHeight: '300px', position: 'relative'}}
											>
												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-line-chart text-success'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New report has been received
															</div>
															<div className="text-muted">23 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-paper-plane text-danger'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																Finance report has been generated
															</div>
															<div className="text-muted">25 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-user flaticon2-line- text-success'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New order has been received
															</div>
															<div className="text-muted">2 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-pin text-primary'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New customer is registered
															</div>
															<div className="text-muted">3 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-sms text-danger'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																Application has been approved
															</div>
															<div className="text-muted">3 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-pie-chart-3 text-warning'/>
														</div>
														<div className="navinavinavi-text">
															<div className="font-weight-bold">
																New file has been uploaded
															</div>
															<div className="text-muted">5 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon-pie-chart-1 text-info'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New user feedback received
															</div>
															<div className="text-muted">8 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-settings text-success'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																System reboot has been successfully completed
															</div>
															<div className="text-muted">12 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon-safe-shield-protection text-primary'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New order has been placed
															</div>
															<div className="text-muted">15 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-notification text-primary'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																Company meeting canceled
															</div>
															<div className="text-muted">19 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-fax text-success'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New report has been received
															</div>
															<div className="text-muted">23 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon-download-1 text-danger'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																Finance report has been generated
															</div>
															<div className="text-muted">25 hrs ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon-security text-warning'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New customer comment recieved
															</div>
															<div className="text-muted">2 days ago</div>
														</div>
													</div>
												</a>

												<a href="#" className="navi-item">
													<div className="navi-link">
														<div className="navi-icon mr-2">
															<i className='flaticon2-analytics-1 text-success'/>
														</div>
														<div className="navi-text">
															<div className="font-weight-bold">
																New customer is registered
															</div>
															<div className="text-muted">3 days ago</div>
														</div>
													</div>
												</a>
											</PerfectScrollbar>
										</Tab.Pane>
										<Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
											<div className="d-flex flex-center text-center text-muted min-h-200px">
												All caught up!
												<br/>
												No new notifications.
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</div>
						</form>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</>
	);
}
