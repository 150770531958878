import QueryString from "qs"
import { useLocation } from "react-router-dom"

export default function useQueryParams() {
  const location = useLocation()
  const params = QueryString.parse(location.search, {
    ignoreQueryPrefix: true
  })
  
  return params
}