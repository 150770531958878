/* eslint-disable default-case */
import { TextField, MenuItem, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import React, { useState } from 'react'
import 'react-phone-input-2/lib/material.css'
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar'
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import CountrySelect from '../Dropdown/CountrySelect'
import ModalFieldsConfiguration from './ModalFieldsConfiguration'
import _ from 'lodash'
import FileInput from '../_common/FileInput'
import { validate } from '../../helpers/validation'
import Phone from '../_common/Phone'

export default function EditProfileForm({
  onFormSubmit = async () => { },
  title,
  options = [],
  value,
  setValue,
  onSave = async () => true,
  formValid
}) {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(() => {
    const errorsState = {}
    options.filter(option => !!option.validation).forEach(option => errorsState[option.name] = '')
    return errorsState
  })

  const addNewButtonClick = (e, option) => {
    e.preventDefault()
    setValue(oldValue => {
      oldValue[option.name].push(option.defaultValue)
      return {
        ...oldValue
      }
    })
  }

  const editTextFieldValue = (eventValue, optionName) => {
    setValue(old => {
      return {
        ...old,
        [optionName]: _.cloneDeep(eventValue)
      }
    })
  }

  async function onSaveClick(e) {
    e.preventDefault()

    try {
      setLoading(true)
      await submit()
    } catch (error) {
      throw(error)
    } finally {
      setLoading(false)
    }

  }

  async function submit() {
    const [validationResult, validationErrors] = await validate(options, value)
    const isFormsValid = await onSave();
    setErrors({ ...validationErrors })
    if (validationResult && isFormsValid) {
      await onFormSubmit(value)
    } else {
      notify({ type: "ERROR", message: 'There are some fields you need to fill' })
    }

  }

  return (
    <>
      <div className="card-header">
        <div className="card-title w-50 ml-0">
          <div style={{ fontSize: '1.5rem' }} className="fw-bolder m-0">
            {title}
          </div>
          <div className="text-muted mt-3 font-weight-bold font-size-sm"></div>
        </div>
      </div>
      <div className="card-body">
        <form className="form" id="kt_form" onSubmit={submit} _lpchecked={1}>
          <div className="tab-content">
            {/*begin::Tab*/}
            <div className="tab-pane show px-7 active" id="kt_user_edit_tab_1" role="tabpanel">
              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-2" />
                <div className="col-xl-7 my-2">
                  {/*begin::Row*/}
                  {/*end::Row*/}
                  {
                    options.map((option) => {
                      switch (option.type) {
                        case 'header': {
                          return (
                            <div className="row mt-8 mb-4">
                              <div className="section-header offset-3 col-9" key={option.title}>{option.title}</div>
                            </div>
                          )
                        }
                        case 'avatar': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 text-lg-right text-left">{option.title}</label>
                              <div className="col-9">
                                <ProfileAvatar value={value?.[option.name]} onChange={e => editTextFieldValue(e, option.name)} />
                              </div>
                            </div>
                          )
                        }
                        case 'text': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 d-flex align-items-center justify-content-end text-right">{option.title}</label>
                              <div className="col-9 d-flex align-items-center">
                                <TextField
                                  variant="outlined"
                                  error={errors[option.name]}
                                  helperText={errors[option.name]}
                                  onChange={e => editTextFieldValue(e.target.value, option.name)}
                                  fullWidth
                                  value={value?.[option.name]} />
                              </div>
                            </div>
                          )
                        }
                        case 'phone': {
                          return (<div className="form-group row">
                            <label className="col-form-label col-3 text-right d-flex align-items-center justify-content-end">Phone</label>
                            <div className="col-9 d-flex align-items-center">
                              <Phone
                                isValid={() => {
                                  if (errors[option.name]) return errors[option.name]
                                  else return true;
                                }}
                                onChange={v => editTextFieldValue(v, option.name)}
                                value={value?.[option.name]}
                                specialLabel=''
                                inputClass="w-100 h-50"
                                defaultErrorMessage={"Invalid phone number"}
                              />
                            </div>
                          </div>)
                        }
                        case 'divider': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <hr className="w-100" />
                            </div>
                          )
                        }
                        case 'select': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label text-right col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                              <div className="col-9 d-flex align-items-center">
                                <TextField
                                  error={errors[option.name]}
                                  helperText={errors[option.name]}
                                  onChange={e => editTextFieldValue(e.target.value, option.name)}
                                  value={value?.[option.name]}
                                  select variant="outlined" fullWidth>
                                  {
                                    option?.options?.map(selectOption => (
                                      <MenuItem key={selectOption.value} value={selectOption.value}>{selectOption.title}</MenuItem>
                                    ))
                                  }
                                </TextField>
                              </div>
                            </div>
                          )
                        }
                        case 'country': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 d-flex align-items-center justify-content-end text-right">{option.title}</label>
                              <div className="col-9 d-flex align-items-center">
                                <CountrySelect
                                  value={value[option.name]}
                                  onChange={e => editTextFieldValue(e, option.name)}
                                  options={option.options}
                                  error={errors[option.name]}
                                  helperText={errors[option.name]}
                                />
                              </div>
                            </div>
                          )
                        }
                        case 'radio': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 d-flex align-items-center text-right justify-content-end">{option.title}</label>
                              <div className="col-9">
                                <RadioGroup
                                  onChange={e => editTextFieldValue(e.target.value, option.name)}
                                  value={value?.[option.name]}
                                  aria-label="gender"
                                  name="radio-buttons-group"
                                >
                                  {
                                    option?.options?.map(radioOption => (<FormControlLabel value={radioOption.value} control={<Radio />} label={radioOption.title} />))
                                  }
                                </RadioGroup>
                              </div>
                            </div>

                          )
                        }
                        case 'date': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                              <div className="col-9">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <DatePicker
                                    error={errors[option.name]}
                                    helperText={errors[option.name]}
                                    onChange={v => editTextFieldValue(v, option.name)}
                                    value={value?.[option.name]}
                                    className="mt-0 mb-0"
                                    inputVariant="outlined"
                                    format={"DD-MMM-YYYY"}
                                    // maxDate={props.maxDate}
                                    // minDate={props.minDate}
                                    margin="normal"
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          )
                        }
                        case 'file': {
                          return (
                            <div className="form-group row" key={option.name}>
                              <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                              <div className="col-9">
                                <FileInput
                                  onChange={file => editTextFieldValue(file, option.name)}
                                  value={value[option.name]} />
                              </div>
                            </div>
                          )
                        }
                        case 'component': {
                          return (
                            <>
                              <div className="form-group row" key={option.name}>
                                {option.component()}
                              </div>
                            </>
                          )
                        }
                        case 'modal': {
                          return (
                            <>
                              <ModalFieldsConfiguration
                                error={!formValid[option.name]}
                                key={option.name}
                                value={value[option.name]}
                                currentOption={option}
                                onChange={v => editTextFieldValue(v, option.name)}
                              />
                            </>
                          )
                        }
                        case 'array': {
                          return (
                            <React.Fragment key={option.name}>
                              {
                                value[option.name]?.map((arrayValue, index) => {
                                  const pushValue = (e) => {
                                    setValue(old => {
                                      old[option.name][index] = { ...e };
                                      return {
                                        ...old,
                                      }
                                    })
                                  }

                                  return (<ModalFieldsConfiguration
                                    error={!_.isUndefined(formValid[option.name][index]) && !formValid[option.name][index]}
                                    value={arrayValue}
                                    validationContext={true}
                                    currentOption={option.options[0]}
                                    onChange={e => pushValue(e)} />)
                                })
                              }
                              <div className="form-group row" key={option.name}>
                                <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                                <div className="col-9">
                                  <button className="btn btn-primary" onClick={e => addNewButtonClick(e, option)}>Add new</button>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        }
                      }
                    })
                  }
                  {/*begin::Group*/}

                  {/*end::Group*/}



                </div>
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Tab*/}
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-primary" disabled={loading} type="submit" onClick={onSaveClick}>
              {loading ?
                (<><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Saving...</>)
                : 'Save'
              }</button>
          </div>
        </form>
      </div>
    </>)
}