import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Axios from "axios";

const initialValues = {
  email: "",
};

function ResetPassword(props) {
  const { intl } = props;
  const verification_code = props.match.params.verification_code;

  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 200 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      Axios.post('auth/reset_password', {
        new_password: values.password,
        verification_code
      })
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        if (!!error.response) setStatus(error?.response?.data.error_message);
        setSubmitting(false);
      });
    },
  });

  const checkVerificationCodeValidity = () => {
    Axios.get(`auth/check_password_reset_code?verification_code=${ verification_code }`)
    .then(({ data }) => {
      if(!data.code_valid) {
        history.push("/");
      } else {
        setIsCodeValid(true);
      }
    })
    .catch(() => {
      history.push("/");
    });
  };

  useEffect(() => {
    checkVerificationCodeValidity();
  }, []);

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <div className="text-muted font-weight-bold">
              Please, enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
                
            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="New password"
                type="password"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm new password"
                type="password"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "changepassword"
                )}`}
                name="changepassword"
                {...formik.getFieldProps("changepassword")}
              />
              {formik.touched.changepassword && formik.errors.changepassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.changepassword}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Back
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting || !isCodeValid}
              >
                Change password
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
