import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FormInput } from '../Basic'

export default function SetCleasingDateModal({ show, onClose, onSave, onValueChange, value }) {
  return (
    <Modal show={show} onHide={onClose} >
      <Modal.Header closeButton>
        <Modal.Title>Set cleasing date</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormInput
          placeholder="Date of cleasing"
          type="date"
          w-title="Date of cleasing"
          name="date_of_cleasing"
          onChange={onValueChange}
          value={value}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">Close</Button>
        <Button onClick={(e) => { onSave(e); onClose(e) }} variant="primary">Save changes</Button>
      </Modal.Footer>

    </Modal>
  )
}