import React, { useState } from "react";

import ESNForm from "../../components/ESNForm";

export function EsnValidated(props) {
  const [modalShown, setModalShown] = useState(false);

  return (
    <div className="description">
      <p>ESN text...</p>

      <ESNForm
        task_id={props.task_id}
        company_id={props.company_id}
        show={modalShown}
        jurisdictions={props.jurisdictions}
        onHide={state => {
          props.onHide();
          setModalShown(state);
        }}
      />

      <p>
        <button
          className="btn btn-primary font-weight-bold mt-2 px-6"
          onClick={ () => setModalShown(true) }
        >
          Fill out the notice
        </button>
      </p>
    </div>
  )
}

export function AnnualReturnValidated(props) {
  return (
    <div className="description">
      <p>
        Please review, update as needed, and validate the following information for this entity: 
      </p>

      <ul className="my-4">
        <li>Current Stakeholders (Owners, Controlling Persons, Directors, etc.)</li>
        <li>Equity Structure and Ownership distribution (share capital, equity interests, % Ownership)</li>
      </ul>
    </div>
  )
}
