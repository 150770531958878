import React, { useState, useEffect } from "react";
import Axios from "axios";

export function CSPPage(props) {
  const csp_id = props.match.params.csp_id;

  const [csp, setCsp] = useState({});

  const getCSPInfo = () => {
    Axios.get(`csp/${ csp_id }`)
    .then(({ data }) => {
      setCsp(data.csp);
    })
    .catch(() => {});
  }

  useEffect(() => {
    getCSPInfo();
  }, []);

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b pb-5">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
                CSP Info
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                This page will have a lot more functionality in the future
            </span>
            </h3>
          </div>
          {csp.csp_license_number && (
            <div className="card-body pt-3">
              <div className="font-weight-bolder text-dark">{ csp.csp_name }</div>
              <div className="font-weight-lighter text-muted">CSP name</div>

              <div className="font-weight-bolder text-dark mt-6">{ csp.csp_license_number }</div>
              <div className="font-weight-lighter text-muted">License number</div>

              <div className="font-weight-bolder text-dark mt-6">{ csp.csp_registration_number }</div>
              <div className="font-weight-lighter text-muted">Registration number</div>

              <div className="font-weight-bolder text-dark mt-6">{ csp.jurisdiction_region.region_title }</div>
              <div className="font-weight-lighter text-muted">Jurisdiction region</div>

              <div className="font-weight-bolder text-dark mt-6">{ csp.csp_status }</div>
              <div className="font-weight-lighter text-muted">CSP status</div>

              <div className="font-weight-bolder text-dark mt-6">{ csp.csp_address }</div>
              <div className="font-weight-lighter text-muted">CSP address</div>
            </div>
          )}
      </div>
    </div>
  )
}
