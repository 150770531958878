import { useState } from "react";
import { useEffect } from "react";
import { getShareholders } from "../../../api/company";
import useRequest from "../../../components/useRequest/useRequest";

export default function useShareholders(entity_id) {
  const [shareholders, setShareholders] = useState([])
  const [fetchShareholders] = useRequest(() => getShareholders(entity_id), data => data && setShareholders(data))

  useEffect(() => {
    if (entity_id) {
      fetchShareholders()
    }
  }, [entity_id])

  return [shareholders, fetchShareholders]
}