import { SET_USER_NOTIFICATIONS_COUNT, READ_ALL_NOTIFICATIONS, SET_ALL_USER_NOTIFICATIONS } from "./userNotificationsActions";
import _ from 'lodash'

export default function userNotificationsReducer(state = {
  notifications : [],
  notifications_count : 0,
  new_notifications_count : 0
}, action) {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS_COUNT: {
      return _.cloneDeep({
        ...state,
        ...action.payload
      })
    }
    case SET_ALL_USER_NOTIFICATIONS: {
      return _.cloneDeep({
        ...state,
        ...action.payload
      })
    }
    case READ_ALL_NOTIFICATIONS: {
      return _.cloneDeep({
        ...state,
        new_notifications_count: 0
      })
    }
    default: return state;
  }
}