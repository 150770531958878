import { Avatar } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { getEntityAvatar, getEntityBannerInfo } from '../../../api/company'
import { getCompanyStatusTitles, getCompanyTypesTitles, getEntityCategories, getRiskRatingExtendedColored } from '../../../api/ui'
import useRequest from '../../../components/useRequest/useRequest'
import useColoredTitles from '../../../hooks/useColoredTitles'
import useTitles from '../../../hooks/useTitles'

import LanguageIcon from '@material-ui/icons/Language';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';

const avatarStyle = { width: 150, height: 150 }

export default function EntityBanner({ entity_id }) {
  const [info, setInfo] = useState({})
  const [avatar, setAvatar] = useState('')

  const [fetchBannerInfo] = useRequest(() => getEntityBannerInfo(entity_id), (data) => {
    setInfo(data)
  })

  const [fetchAvatar] = useRequest(() => getEntityAvatar(entity_id), (a) => setAvatar(a))

  useEffect(() => {
    fetchBannerInfo()
    fetchAvatar()
  }, [])

  const [entityStatuses] = useColoredTitles(getCompanyStatusTitles)
  const [coloredRiskRatingsExtended] = useColoredTitles(getRiskRatingExtendedColored)
  const [entityCategories] = useTitles(getEntityCategories)
  const [entityTypes] = useTitles(getCompanyTypesTitles)


  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <Avatar src={avatar} variant={'rounded'} style={avatarStyle} />
              </div>
              <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between flex-wrap mt-1">
                <div className="d-flex mr-3">
                  <span className="text-dark-75 font-size-h4 font-weight-bold mr-3">
                    {info.name || '...'}
                  </span>
                </div>

              </div>

              <div className="row">
                <div className="col-6 d-flex flex-column mt-1">
                  <span className="mb-1"><LanguageIcon style={{color: 'gray'}} /> {info.jurisdiction_name || '---'}</span>
                  <span className="mb-1"><VpnKeyIcon style={{color: 'gray'}} /> {info.registration_number || '---'}</span>
                  <span className="mb-1"><CategoryIcon style={{color: 'gray'}} /> {entityCategories.find(e => e.value === info.category)?.title || '---'}</span>
                  <span className="mb-1"><ClassIcon style={{color: 'gray'}} /> {entityTypes.find(e => e.value === info.type)?.title || '---'}</span>
                </div>

                <div className="col-6 text-right">
                  <p className='h6 mb-5' style={{
                    color: entityStatuses.find((s) => s.value === info.status)?.color
                  }}>{
                      entityStatuses.find((s) => s.value === info.status)?.title
                    }</p>

                  <p className='h6' style={{
                    color: coloredRiskRatingsExtended.find((s) => s.value === info.risk_rating)?.color
                  }}>{
                      coloredRiskRatingsExtended.find((s) => s.value === info.risk_rating)?.title
                    }</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}