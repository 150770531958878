import React from 'react'
import { MenuItem, TextField } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import "./style.css"
import CountrySelect from '../../../components/Dropdown/CountrySelect';
import useShareholdersFields from '../hooks/useShareholdersFields';


export default function ShareholderWindow({
  modalOpened,
  setModalOpened,
  shareholderErrors = {},
  editField,
  shareholder,
  jurisdictions,
  sharesInfo,
  saveShareholder,
  category
}) {
  const [visibleFields] = useShareholdersFields(category)

  return (
    <Modal show={modalOpened} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Add an Owner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row align-items-start">
            <div className="col">
              <div className="font-size-h5 font-weight-bold mb-3">Owner Details</div>
              <TextField
                error={shareholderErrors.type}
                helperText={shareholderErrors.type}
                type="text"
                select
                className="w-100"
                variant="outlined"
                label="Type"
                name="type"
                onChange={e => {
                  editField('type', e.target.value)
                }}
                value={shareholder.type}
              >
                <MenuItem value="human">Individual</MenuItem>
                <MenuItem value="entity">Entity</MenuItem>
              </TextField>

              {shareholder.type === "entity" ? (
                <>
                  {
                    visibleFields.entity_name && (
                      <TextField
                        error={shareholderErrors.entity_name}
                        helperText={shareholderErrors.entity_name}
                        label="Entity name"
                        type="text"
                        className="mt-5 w-100"
                        variant="outlined"
                        name="entity_name"
                        onChange={e => {
                          editField('entity_name', e.target.value)
                        }}
                        value={shareholder.entity_name}
                      />
                    )
                  }
                  {
                    visibleFields.entity_region && (
                      <CountrySelect
                        error={shareholderErrors.entity_region}
                        helperText={shareholderErrors.entity_region}
                        label="Entity Region"
                        className="mt-5 w-100"
                        name="entity_region"
                        onChange={e => editField('entity_region', e)}
                        value={shareholder.entity_region}
                        options={jurisdictions}
                      >
                      </CountrySelect>
                    )
                  }

                  {
                    visibleFields.entity_registration_number && (
                      <TextField
                        error={shareholderErrors.entity_registration_number}
                        helperText={shareholderErrors.entity_registration_number}
                        label="Registration #"
                        type="text"
                        className="mt-5 w-100"
                        variant="outlined"
                        name="entity_registration_number"
                        onChange={e => editField('entity_registration_number', e.target.value)}
                        value={shareholder.entity_registration_number}
                      />
                    )
                  }
                </>


              ) : null
              }
              {shareholder.type === "human" ? (
                <>
                  {
                    visibleFields.name && (
                      <TextField
                        error={shareholderErrors.name}
                        helperText={shareholderErrors.name}
                        label="Name"
                        type="text"
                        className="mt-5 w-100"
                        variant="outlined"
                        name="name"
                        onChange={e => editField(e.target.name, e.target.value)}
                        value={shareholder.name}
                      />
                    )
                  }

                  {
                    visibleFields.email_address && (
                      <TextField
                        error={shareholderErrors.email_address}
                        helperText={shareholderErrors.email_address}
                        label="Email"
                        type="email"
                        className="mt-5 w-100"
                        variant="outlined"
                        name="email_address"
                        onChange={e => editField(e.target.name, e.target.value)}
                        value={shareholder.email_address}
                      />
                    )
                  }
                </>
              ) : null}
            </div>
            <div className="col">
              <div className="font-size-h5 font-weight-bold mb-3">Equity Distribution Detials:</div>
              {/*i have tried TextField component fot it, but it doesnt work and i dont know why*/}
              {
                visibleFields.share_class && (
                  <TextField
                    error={shareholderErrors.share_class}
                    helperText={shareholderErrors.share_class}
                    className="w-100"
                    label="Ownership Type"
                    name="share_class"
                    select
                    variant="outlined"
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={shareholder.share_class}>
                    {sharesInfo.map(share_item => (
                      <MenuItem
                        key={share_item.class_name}
                        value={share_item.class_name}>
                        {share_item.class_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }
              {
                visibleFields.ownership_percentage && (
                  <TextField
                    error={shareholderErrors.ownership_percentage}
                    helperText={shareholderErrors.ownership_percentage}
                    label="Ownership percentage (%)"
                    type="number"
                    variant="outlined"
                    className="mt-5 w-100"
                    name="ownership_percentage"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={shareholder.ownership_percentage}
                  />
                )
              }
              {
                visibleFields.sharesissued && (
                  <TextField
                    label="Shares to be Issued"
                    type="number"
                    className="w-100 mt-5"
                    variant="outlined"
                    name="sharesissued"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    InputProps={{
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={shareholder.sharesissued}
                  />
                )
              }
              {
                visibleFields.sharespaidup && (
                  <TextField
                    error={shareholderErrors.sharespaidup}
                    helperText={shareholderErrors.sharespaidup}
                    label="Shares paid up (%)"
                    type="number"
                    className="mt-5 w-100"
                    variant="outlined"
                    name="sharespaidup"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    InputProps={{
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={shareholder.sharespaidup}
                  />
                )
              }
              {
                visibleFields.equity_interest && (
                  <TextField
                    error={shareholderErrors.equity_interest}
                    helperText={shareholderErrors.equity_interest}
                    label="Equity Interest"
                    type="number"
                    className="mt-5 w-100"
                    variant="outlined"
                    name="equity_interest"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    InputProps={{
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={e => editField(e.target.name, e.target.value)}
                    value={shareholder.equity_interest}
                  />
                )
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-btn font-weight-bold px-9 py-4 my-3" onClick={() => {
          setModalOpened(false);
        }}>
          Cancel
        </button>
        <button className="submit-btn font-weight-bold px-9 py-4 my-3" onClick={() => {
          saveShareholder();
          setModalOpened(false);
        }}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}