import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import { getUserFriendlyMIMEType } from "../../../../util";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { getDocument, deleteDocument, uploadDocument, openDocument } from '../../../api/document';
import UploadFilesModal from "../../../components/UploadFilesModal/UploadFilesModal";
import { getUserDocumentContentCategories } from "../../../api/ui";
import { getUserDocuments } from "../../../api/document";
import { BROWSER_PREVIEWED_MIME_TYPES, userDocumentStatus } from "../../../../constants";
import { notify } from '../../../../redux/NotificationSnackbar/snackbarActions';
import {Link} from "react-router-dom";

const filters = [
  {
    id: 1,
    type: "select",
    fieldName: "category", // mime_type
    title: "Category",
    options: [
      {
        id: 1,
        fieldName: "uploaded_document",
        title: "Uploaded document"
      },
      {
        id: 2,
        fieldName: "generated_document",
        title: "Generated document"
      },
      {
        id: 3,
        fieldName: "template",
        title: "Template"
      },
      {
        id: 4,
        fieldName: "hdd_id",
        title: "HDD — Identification document"
      },
      {
        id: 5,
        fieldName: "hdd_por",
        title: "HDD — Proof of residence"
      },
      {
        id: 6,
        fieldName: "edd_coi",
        title: "edd_coi"
      },
      {
        id: 7,
        fieldName: "edd_roo",
        title: "edd_roo"
      },
      {
        id: 8,
        fieldName: "edd_cogs",
        title: "edd_cogs"
      },
      {
        id: 9,
        fieldName: "edd_cd",
        title: "edd_cd"
      },
      {
        id: 10,
        fieldName: "edd_se",
        title: "edd_se"
      },
      {
        id: 11,
        fieldName: "edd_rcp",
        title: "edd_rcp"
      }
    ]
  },
  {
    id: 2,
    type: "select",
    fieldName: "mime_type", // mime_type
    title: "MIME Type",
    options: []
  }
];


export default function VaultRoute(props) {
  const VAULT_COLUMNS = [
    {
      id: 1,
      name: "title",
      title: "Name",
      width: "200px",
      sortable: true,
      component: (row) => {
        if(canBeOpenedInBrowser(row.raw_mime_type))
          return <Link to="#" onClick={() => onItemClick(row)}>{row.title}</Link>
        return <span>{row.title}</span>
      }
    },
    {
      id: 2,
      name: "category",
      title: "Category",
      width: "200px",
      sortable: true
    },
    {
      id: 3,
      name: "mime_type",
      title: "MIME type",
      width: "200px",
      sortable: true
    },
    {
      id: 4,
      name: "date",
      title: "Date",
      width: "200px",
      sortable: true
    },
    {
      id: 5,
      name: "status",
      title: "Status",
      width: "200px",
      sortable: true
    },
    {
      id: 6,
      name: "actions",
      title: "Actions",
      width: "150px",
      sortable: false
    }
  ];

  const user_id = props.match.params.user_id;
  const [contentCategories, setContentCategories] = useState([])

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const getDocumentsTasks = [
      getUserDocumentContentCategories(),
      getUserDocuments(user_id),
    ];
    Promise.all(getDocumentsTasks).then(([categories, documents]) => {
      setContentCategories(categories);
      onGetUserDocuments(documents, categories);
    }).catch(error => {
      notify({
        type: "ERROR",
        message: "Error while getting user's documents",
        title: "Error"
      });
    })
  }, [])

  const editFileFields = [
    {
      name: 'document_title',
      title: 'Title',
      type: 'text'
    },
    {
      name: 'document_content_category',
      title: 'Category',
      type: 'select',
      options: () => {
        return Object.keys(contentCategories).map(categoryKey => ({
          value: categoryKey,
          title: contentCategories[categoryKey]
        }))
      }
    },
    {
      name: 'document_status',
      title: 'Status',
      type: 'select',
      options: () => {
        return Object.values(userDocumentStatus).map(status => ({
          value: status,
          title: status
        }))
      }
    },
  ]

  const [documents, setDocuments] = useState([]);
  const [documentsTable, setDocumentsTable] = useState([]);
  const [filteredDocumentsTable, setFilteredDocumentsTable] = useState([]);

  const [documentFilters, setDocumentFilters] = useState({
    category: null,
    mime_type: null,
    status: null
  });

  const [mimeTypes, setMimeTypes] = useState([]);

  useEffect(() => {
    const mimeTypeFilter = filters.find(f => f.fieldName === "mime_type");
    mimeTypeFilter.options = mimeTypes.map((type, id) => ({
      id,
      fieldName: type,
      title: type
    }));
  }, [mimeTypes]);

  // Update the filters
  useEffect(() => {
    // clearTimeout(vault_filters_timeout);
    // vault_filters_timeout = setTimeout(updateFilteredDocuments, 500);

    // Don't use debounce - we don't have any free text fields
    updateFilteredDocuments();
  }, [documentFilters]);

  const updateFilteredDocuments = () => {
    const filtered_documents = [];

    for (const document of documentsTable) {
      // Filter by document category
      if (
        documentFilters.category &&
        documentFilters.category !== "-1" &&
        document.category !== documentFilters.category
      ) {
        continue;
      }

      // Filter by document MIME type
      if (
        documentFilters.mime_type &&
        documentFilters.mime_type !== "-1" &&
        document.raw_mime_type !== documentFilters.mime_type
      ) {
        continue;
      }

      filtered_documents.push(document);
    }

    setFilteredDocumentsTable([...filtered_documents]);
  };

  const onGetUserDocuments = (user_documents, categories = []) => {
    const documents_table = [];
    const mime_types = [];
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    for (const doc of user_documents) {
      // Construct the document status string
      let status_string = doc.document_status;
      const category = categories[doc.document_content_category] || doc.document_content_category;

      if (doc.document_is_signed) status_string += ", signed";

      // Add document's mimetype to the list of mimetypes that we have happened upon
      if (!mime_types.includes(doc.document_mime_type))
        mime_types.push(doc.document_mime_type);

      if(doc.updated_at && !Number.isNaN(Date.parse(doc.updated_at)))
        doc.updated_at = dateFormatter.format(new Date(doc.updated_at));

      // Add the document to the table
      documents_table.push({
        document_id: doc.document_id,

        raw_mime_type: doc.document_mime_type,
        mime_type: getUserFriendlyMIMEType(doc.document_mime_type),

        title: doc.document_title || doc.document_id,
        category: category,
        date: doc.updated_at,
        status: status_string,

        actions: (
            <Dropdown drop="left">
              <Dropdown.Toggle className="btn btn-light">
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                      src={toAbsoluteUrl(
                          "/media/svg/icons/General/Settings-2.svg"
                      )}
                  />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    deleteDocument(doc.document_id).then(updateDocumentsList).then(() => {
                      notify({ type: 'SUCCESS', message: 'Document has been deleted!' });
                    })
                  }
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={() => getDocument(doc.document_id, doc.document_title)}>Download</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedFile(() => ({ ...doc }))}>Update</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        )
      });
    }

    setDocumentsTable(documents_table);
    setFilteredDocumentsTable(documents_table);
    setMimeTypes(mime_types);
    setDocuments(user_documents);
  }

  const updateDocumentsList = () => {
    return getUserDocuments(user_id).then((documents) => {
      onGetUserDocuments(documents, contentCategories);
    })
  }

  function uploadNewDocument(_, doc) {
    const formData = new FormData();
    const { document_title, document_content_category, document_status } = doc[0];
    formData.append('document_title', document_title);
    formData.append('document_content_category', document_content_category);
    formData.append('document_status', document_status);
    formData.append('update_document_id', selectedFile?.document_id);
    formData.append('0', doc[0]?.file);

    return uploadDocument(formData)
      .then(updateDocumentsList)
      .catch(e => {
        notify({
          type: 'ERROR',
          title: 'Error',
          message: e?.response?.data?.error_message
        });
        return Promise.reject(e);
      });
  }

  const sortDocuments = (query) => {
    const options = { field: query.sortField, order: query.sortOrder };
    return getUserDocuments(user_id, options).then((documents) => {
      onGetUserDocuments(documents, contentCategories)
    });
  }

  const canBeOpenedInBrowser = (mimeType) => {
    return BROWSER_PREVIEWED_MIME_TYPES.includes(mimeType);
  }

  const onItemClick = async (row) => {
    const { document_id, title } = row;
    if(document_id && title){
      await openDocument(document_id, title);
    }
  }

  return (
    <div className="col-lg-12">
      <div className="card  card-custom card-stretch gutter-b">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
              User's vault
            </div>
          </div>
          <div className="card-toolbar">
            <UploadFilesModal
              title="Upload vault documents"
              editFileFields={editFileFields}
              onSave={uploadNewDocument}
              onClose={() => setSelectedFile(null)}
              selectedFile={selectedFile}
            />
          </div>
        </div>

        <div className="card-body">
          <Table
            cols={VAULT_COLUMNS}
            entities={filteredDocumentsTable}
            idField="document_id"
            onTableChange={sortDocuments}
          />
        </div>
      </div>
    </div>
  );
}
