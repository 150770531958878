import React from 'react'
import { CustomDatePicker, FormButton, FormDivider, FormInput, FormSelect } from '../../../components/Basic';
import Phone from '../../../components/_common/Phone';

export default function HDDBasic({
  selectOptions,
  page1Formik,
  answersBasic,
  user,
  getPreferredName,
  setNameTouched,
  getDate,
  goBack,
  loading,
  onValueChange
}) {

  return !page1Formik ? 'Loading page' : (
    <form onSubmit={page1Formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="form-group fv-plugins-icon-container">
        <div className="font-size-h6 font-weight-bold ml-2">Legal name</div>
        <FormSelect
          mui
          label="Prefix"
          name="name_prefix"
          options={selectOptions.prefix}
          onChange={onValueChange}
          value={answersBasic.name_prefix}
        />
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormInput
          mui
          label="First name"
          name="first_name"
          value={page1Formik.values.first_name}
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
          error={page1Formik.errors.first_name && page1Formik.touched.first_name}
          helperText={page1Formik.touched.first_name ? page1Formik.errors.first_name : null}
        />
        <FormInput
          mui
          label="Middle name"
          name="middle_name"
          value={page1Formik.values.middle_name}
          error={page1Formik.errors.middle_name && page1Formik.touched.middle_name}
          helperText={page1Formik.touched.middle_name ? page1Formik.errors.middle_name : null}
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
        />
        <FormInput
          mui
          label="Last name"
          name="last_name"
          value={page1Formik.values.last_name}
          error={page1Formik.errors.last_name && page1Formik.touched.last_name}
          helperText={page1Formik.touched.last_name ? page1Formik.errors.last_name : null}
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
        />
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormSelect
          mui
          label="Suffix"
          name="name_suffix"
          options={selectOptions.suffix}
          onChange={onValueChange}
          value={answersBasic.name_suffix}
        />
        <FormInput
          mui
          name="preferred_name"
          label="Preferred name"
          value={getPreferredName()}
          onClick={() => setNameTouched(true)}
          onChange={page1Formik.handleChange}
          error={page1Formik.errors.preferred_name && page1Formik.touched.preferred_name}
          helperText={page1Formik.errors.preferred_name || 'Preferred, non-legal name (can be a nickname)'}
        />
        <FormInput
          mui
          label="Alias or previous names"
          name="alias"
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
          value={page1Formik.values.alias}
          error={page1Formik.touched.alias && page1Formik.errors.alias}
          helperText={page1Formik.touched.alias && page1Formik.errors.alias ? page1Formik.errors.alias : null}
        />
      </div>

      <FormDivider />

      <div className="form-group fv-plugins-icon-container">
        <div className="font-size-h6 font-weight-bold my-4 ml-2">Other basic information</div>
        <FormInput
          mui
          label="Email address"
          name="email_address"
          value={user?.emails?.[0]}
          disabled={!!user?.emails?.[0]}
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
          error={page1Formik.errors.email_address && page1Formik.touched.email_address}
          helperText={page1Formik.touched.email_address ? page1Formik.errors.email_address : null}
        />

        <Phone
          specialLabel=""
          inputProps={{ name: 'phone_number' }}
          onBlur={() => page1Formik.setFieldTouched('phone_number')}
          onChange={value => page1Formik.setFieldValue('phone_number', value)}
          disabled={!!user?.phone_number}
          value={user?.phone_number || page1Formik.values.phone_number}
          inputClass="w-100 phone-input"
        />
        {(page1Formik.touched.phone_number && page1Formik.errors.phone_number) ? (
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{page1Formik.errors.phone_number}</p>
        ) : null}

        <FormDivider />

        <CustomDatePicker
          className="w-100 my-3"
          name="date_of_birth"
          label="Date of birth"
          minDate={getDate(-100, 'y')}
          maxDate={getDate(-18, 'y')}
          value={page1Formik.values.date_of_birth}
          onChange={ val => page1Formik.setFieldValue('date_of_birth', val)}
          onBlur={page1Formik.handleBlur}
          error={page1Formik.errors.date_of_birth && page1Formik.touched.date_of_birth}
          helperText={page1Formik.touched.date_of_birth ? page1Formik.errors.date_of_birth : null}
        />
        <FormSelect
          mui
          label="Gender"
          name="gender"
          options={selectOptions.gender}
          value={page1Formik.values.gender}
          onChange={page1Formik.handleChange}
          onBlur={page1Formik.handleBlur}
          error={page1Formik.errors.gender && page1Formik.touched.gender}
          helperText={page1Formik.touched.gender ? page1Formik.errors.gender : null}
        />
      </div>
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Close
        </button>
        <FormButton label="Next" type="submit" id="kt_create_csp_submit" color="secondary">
          {loading && <span className="ml-3 spinner spinner-white" />}
        </FormButton>
      </div>
    </form>
  )
}