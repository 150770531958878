import React, { useState } from 'react'
import { useEffect } from 'react'
import { getControllingPersonRelations, getControllingPersonTitles } from '../../../api/ui'
import ActionsDropdown from '../../../components/Dropdown/ActionsDropdown'
import useRequest from '../../../components/useRequest/useRequest'
import useCountries from '../../../hooks/useCountries'
import useTitlesWithRelations from '../../../hooks/useTitlesWithRelations'
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable'
import StaffWindow from '../../create-company-page/components/staff-window'
import { getEntityStakeholders, saveEntityStakeholder } from './api/api'

export default function ControllingPersons({ entity_id, entity_category }) {
  const [staff, setStaff] = useState([])
  const [cpTitles, setCpTitles] = useState({})

  const [fetchStaff] = useRequest(() => getEntityStakeholders(entity_id), setStaff)
  const [fetchCpTitles] = useRequest(getControllingPersonTitles, setCpTitles)

  useEffect(() => { fetchStaff(); fetchCpTitles() }, [])

  const [editModalOpened, setEditModalOpened] = useState(false)
  function closeModal() {
    setPerson({})
    setEditModalOpened(false)
  }

  const [person, setPerson] = useState([])

  function editField(name, value) {
    setPerson(old => {
      old[name] = value
      return { ...old }
    })
  }

  const [savePerson, saveLoading] = useRequest(async () => {
    await saveEntityStakeholder(entity_id, person)
    closeModal()
    fetchStaff()
  })

  const [countries] = useCountries()
  const [roles] = useTitlesWithRelations(entity_category, getControllingPersonRelations, getControllingPersonTitles)


  const cols = [
    {
      name: 'person_name',
      title: 'Name'
    },
    {
      name: 'person_type',
      title: 'Type',
      component: (row) => (<span className='text-capitalize'>{row.person_type}</span>)
    },
    {
      extraData: cpTitles,
      name: 'person_role',
      title: "Role",
      component: (row, _, cpTitles) => (cpTitles[row.person_role])
    },
    {
      name: 'person_position',
      title: 'Position'
    },
    // NEED Clarify
    // {
    //   name: 'invitation'
    // },
    {
      name: 'person_status',
      title: 'Status'
    }
  ]

  return (<>
    <StaffWindow
      showPersonnelModal={editModalOpened}
      setShowPersonnelModal={closeModal}
      personnel={person}
      jurisdictions={countries}
      staffErrors={{}}
      roles={roles}
      editField={editField}
      saveAbstraction={savePerson}
      loading={saveLoading}
    />
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">Controlling persons</div>
          </div>

          <div className="card-toolbar">
            {(
              <ActionsDropdown options={[
                {
                  id: 1,
                  title: 'Add a person',
                  onClick() {
                    setEditModalOpened(true)
                  }
                }
              ]} />
            )}
          </div>
        </div>
        <div className="card-body">
          <Table nonSelectable entities={staff} cols={cols} />
        </div>
      </div>

    </div>
  </>)
}