import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CSP_AWAITING_STATUS } from '../../constants'
import { setIsAwaitingCSP } from '../../redux/Tenant/tenantActions'

export default function AwaitingCSPPage() {
  const awaitingResult = useSelector(state => state.tenant.awaitingResult)
  const history = useHistory()

  const goToDashboardClick = () => {
    setIsAwaitingCSP(false)
    history.push('/dashboard')
  }

  const [component, setComponent] = useState(null)

  useEffect(() => {
    switch (awaitingResult) {
      case CSP_AWAITING_STATUS.TENANT_CREATION_FINISHED: {
        setComponent(
          <>
            <h1>
              Creating CSP Process finished. Your CSP is ready for use...
            </h1>
            <button onClick={goToDashboardClick} className='btn btn-primary mt-10'>Go to dashboard</button>
          </>
        )
        break;
      }
      case CSP_AWAITING_STATUS.TENANT_CREATION_ERROR: {
        setComponent(
          <>
            <h1>
              Creating CSP Process failed with errors. Please contact our support for details...
            </h1>
            <button onClick={goToDashboardClick} className='btn btn-primary mt-10'>Go to dashboard</button>
          </>
        )
        break;
      }
      case CSP_AWAITING_STATUS.TENANT_CREATION_STARTED: {
        setComponent(
          <>
            <h1>
              Resources are being prepared for your company, please wait ...
            </h1>
            <CircularProgress className="mt-10" color="secondary" size="5rem"/>
          </>
        ) 
        break;
      }
    }
  }, [awaitingResult])

 

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="d-flex flex-column align-items-center ">
        {
          component    
        }
      </div>
      
    </div>
  )
}