import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { ProcessDashboards } from './ProcessDashboards';
import { EntityChart } from './EntityChart';
import { QuickLinks } from './QuickLinks';
import { Donut } from './Donut';
import { Card } from './Card';

const processDashboards = [
  {
    icon: '/media/svg/icons/Home/Library.svg',
    title: 'Annual Returns',
    sub: 'Filings Due 31 Mar',
    value: '28',
    valueType: '%',
    status: 'Completed'
  }
];

export function DashboardPage() {
  const [ddText, setDDText] = useState([]);

  const getDDStatus = () => {
    Axios.get('user/user-status')
      .then(({ data }) => {
        const new_text = [];

        if (data.required_dds.includes('edd')) {
          new_text.push(
            <span>
              Please, complete the <Link to={`/entity-due-diligence`}>EDD</Link> process.
            </span>
          );
        }

        if (data.required_dds.includes('hdd')) {
          new_text.push(
            <span>
              Please, complete the <Link to="/staff-onboarding">HDD</Link> process.
            </span>
          );
        }

        if (data.user_draft_csp) {
          new_text.push(
            <span>
              You have a draft csp. Please, <Link to={`/create-csp/${data.user_draft_csp}`}>finish it's creation</Link>
            </span>
          );
        }

        setDDText(new_text);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getDDStatus();
  }, []);

  return (
    <>
      {ddText.length ? (
        <Card title="Tasks to complete" className="mb-6">
          <div className="timeline timeline-6 mt-3">
            {ddText.map((item, key) => {
              return (
                <div className="timeline-item align-items-start" key={key}>
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-center">#{key + 1}</div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-danger icon-xl" />
                  </div>
                  <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">{item}</div>
                </div>
              );
            })}
          </div>
        </Card>
      ) : null}

      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <QuickLinks />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 my-6 my-lg-0">
          <Donut
            className="rounded-lg"
            title="Client Entities By Risk Rating"
            labels={['Low Risk', 'Standard Risk', 'High Risk']}
            series={[400, 2030, 450]}
            colors={['#007bff', '#1BC5BD', '#dc3545']}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-4 flex-xl-column">
          <EntityChart
            className="success mb-6 mt-sm-6 mt-xl-0"
            baseColor="success"
            title={'New Formations'}
            sub={'This Year'}
            value={57}
          />
          <EntityChart className="danger" baseColor="danger" title={'Entities Lost'} sub={'This Year'} value={-10} />
        </div>
        <div className="col-12 mt-6">
          <ProcessDashboards className="success" options={processDashboards} />
        </div>
      </div>
    </>
  );
}
