import React from 'react'
import { WidgetTable } from '../WidgetTable/WidgetTable'

const cols = [
  {
    name: 'name',
    title: 'Full name',
    width: 250
  },
  {
    name: 'username',
    title: 'Username',
    width: 250
  },
  {
    name: 'email',
    title: 'Email',
    width: 250
  }
]

export default function AdminsWidget(props) {
  const rows = [];

  if(props.admins) {
    for(const admin of props.admins) {
      rows.push({
        id: admin.user_id,
        name: admin.user_full_name,
        username: admin.user_name || "—",
        email: admin.emails[0],
      })
    }
  }

  return (
    <WidgetTable
    {...{cols, rows,
      title: "Admins",
      subtitle: "Company's admins"
    }} 
    />
  )
}