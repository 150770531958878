import React from 'react'
import { makeStyles } from '@material-ui/core'
import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router-dom';
import { checkIsActive, toAbsoluteUrl } from '../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setSecondAside, setSecondAsideVisibility } from '../../../../redux/Styles/styleActions';
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

const asideWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -70,
    height: 'calc(100% + 70px)',
    background: '#fff',
    zIndex: 99,
  },
  asideItems: {
    position: 'sticky',
    top: 63,
    height: 'calc(100vh - 75px)',
    overflowY: 'auto'
  },

  rootMobile: {
    top: 0,
    width: '100%',
    position: 'fixed',
    height: 'calc(100%)',
    background: '#00000015',
    zIndex: 99,
  },
  asideItemsMobile: {
    width: asideWidth,
    height: 'calc(100vh)',
    overflowY: 'auto',
    background: '#fff'
  },
}))



const MenuItemDropdown = ({ getMenuItemActive, name, submenu, path, icon }) => {
  const title = name;
  const [open, setOpen] = useState(false)

  return (
    <li
      className={`navi-item navi-item-submenu ${getMenuItemActive(
        "/google-material",
        true
      )}`}
      aria-haspopup="true"
      data-menu-toggle="hover"
    >
      <a className="navi-link navi-toggle" to="/google-material" onClick={e => setOpen(!open)}>
        <span className="svg-icon navi-icon pr-3">
          <SVG src={icon || toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
        </span>
        <span className='navi-text'>{title}</span>
        <i className="navi-arrow" style={{ transform: open ? 'rotate(90deg)' : 'rotate(0)' }} />
      </a>
      {
        !!open && (
          <div className="navi-submenu ">
            <i className="navi-arrow" />
            <ul className="navi-subnav">
              {
                submenu.map(item => (
                  <li
                    className={`navi-item navi-item-subnav ${getMenuItemActive(
                      item.path,
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="navi-link navi-toggle"
                      to={item.path}
                    >
                      <i className="navi-bullet navi-bullet-dot">
                        <span />
                      </i>
                      <span className="navi-text">{item.title}</span>
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
    </li>
  )
}

const MenuItemLocal = ({ name, path, submenu, icon: icon_url }) => {
  const title = name;
  const icon = toAbsoluteUrl(icon_url || "/media/svg/icons/Design/Cap-2.svg");
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = !!submenu) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-highlighted`
      : "";
  };
  
  return !submenu ? (
    <li
      className={`navi-item ${getMenuItemActive(path, false)}`}
      aria-haspopup="true"
    >
      <NavLink className="navi-link" to={path}>
        <span className="svg-icon navi-icon pr-3">
          <SVG src={toAbsoluteUrl(icon)} />
        </span>
        <span className="navi-text">{title}</span>
      </NavLink>
    </li>
  ) : (
    <MenuItemDropdown  {...{ getMenuItemActive, name, path, submenu, icon }} />
  )
}

export function useSecondAside(menuDetails) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSecondAside(menuDetails))
    return () => {
      dispatch(setSecondAside(null))
    }
  }, [menuDetails])
}

export default function SecondAside(props) {
  const styles = useStyles();
  const styleState = useSelector(state => state.style)

  const menuItems = !!styleState.secondAsideMenu && styleState.secondAsideMenu.map((data) => {
    if (!data) return null
    const { name, path, submenu, icon } = data
    return (
      <MenuItemLocal key={name} name={name} path={path} submenu={submenu} icon={icon} />
    )
  })

  const mobileView = useMediaQuery('(max-width: 992px)')

  if (!mobileView)
    return (
      !!styleState.secondAsideMenu && (
        <div className={`${styles.root}`} style={{ width: asideWidth }}>
          <div
            data-menu-vertical="1"
            className={styles.asideItems}
            /* {...layoutProps.asideMenuAttr} */
          >
            <ul className={`navi navi-hovers`}>{menuItems}</ul>
          </div>
        </div>
      )
    );

  return (
    !!styleState.secondAsideMenu && (
      <div
        className={`${styles.rootMobile} ${styleState.secondAsideVisibility ? 'd-static' : 'd-none'}`}
        onClick={e => {
          setSecondAsideVisibility(false);
        }}
      >
        <div
          data-menu-vertical="1"
          onClick={e => {
            e.stopPropagation();
          }}
          className={styles.asideItemsMobile}
        >
          <ul className={`navi navi-hovers`}>{menuItems}</ul>
        </div>
      </div>
    )
  );
}
