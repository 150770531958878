import { TextField, MenuItem, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import 'react-phone-input-2/lib/material.css'
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar'
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import CountrySelect from '../Dropdown/CountrySelect'
import BaseModal from '../Modals/BaseModal'
import FileInput from '../_common/FileInput'
import { validate } from '../../helpers/validation'
import Phone from '../_common/Phone'

export default function ModalFieldsConfiguration({ value, currentOption, onChange, validationContext, error}) {
  const [errors, setErrors] = useState(() => {
    const errorsState = {}
    currentOption.options.filter(option => !!option.validation).forEach(option => errorsState[option.name] = '')
    return errorsState
  })

  const [data, setData] = useState(value)

  const ref = useRef()

  ref.current = value

  useEffect(() => {
    setData(value)
  }, [value])

  const editTextFieldValue = (editedValue, fieldName) => {
    setData(old => {
      return {
        ...old,
        [fieldName]: editedValue
      }
    })
  }

  const onCloseModal = () => {
    setData(ref.current)
  }

  const onSaveModal = async () => {
    const [validationResult, validationErrors] = await validate(currentOption.options, data, validationContext)
    setErrors({ ...validationErrors })
    if (validationResult) {
      onChange(data)
    } else {
      notify({ type: "ERROR", message: 'There are some fields you need to fill' })
      throw new Error()
    }
  }

  return (
    <>
      <div className="form-group row" key={currentOption.name}>
        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{currentOption.title}</label>
        <div className="col-9">
          <BaseModal
          error={error}
            onSave={onSaveModal}
            onClose={onCloseModal}
            buttonBody={currentOption?.buttonBody(data)}
            title={currentOption.title}>
            {
              currentOption.options.map((option) => {
                switch (option.type) {
                  case 'avatar': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 text-lg-right text-left">{option.title}</label>
                        <div className="col-9">
                          <ProfileAvatar value={data[option.name]} onChange={e => editTextFieldValue(e, option.name)} />
                        </div>
                      </div>
                    )
                  }
                  case 'text': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <TextField

                            error={errors[option.name]}
                            helperText={errors[option.name]}
                            variant="outlined" onChange={e => editTextFieldValue(e.target.value, option.name)} fullWidth value={data[option.name]} />
                        </div>
                      </div>
                    )
                  }
                  case 'switch': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9 d-flex align-items-center justify-content-center">
                          <Switch
                            error={errors[option.name]}
                            helperText={errors[option.name]}
                            variant="outlined"
                            onChange={e => {editTextFieldValue(e.target.checked, option.name)}}
                            checked={data[option.name]}
                            fullWidth/>
                        </div>
                      </div>
                    )
                  }
                  case 'phone': {
                    return (<div className="form-group row">
                      <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Phone</label>
                      <div className="col-9">
                        {/* <TextField variant="outlined" fullWidth /> */}
                        <Phone
                          error={errors[option.name]}
                          helperText={errors[option.name]}
                          onChange={v => editTextFieldValue(v, option.name)}
                          value={data[option.name]}
                          specialLabel=''
                          inputClass="w-100 h-50"
                        />
                      </div>
                    </div>)
                  }
                  case 'divider': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <hr className="w-100" />
                      </div>
                    )
                  }
                  case 'select': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <TextField
                            error={errors[option.name]}
                            helperText={errors[option.name]}
                            onChange={e => editTextFieldValue(e.target.value, option.name)}
                            value={data[option.name]}
                            select variant="outlined" fullWidth>
                            {
                              option?.options?.map(selectOption => (
                                <MenuItem key={selectOption.value} value={selectOption.value}>{selectOption.title}</MenuItem>
                              ))
                            }
                          </TextField>
                        </div>
                      </div>
                    )
                  }
                  case 'country': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <CountrySelect
                            error={errors[option.name]}
                            helperText={errors[option.name]}

                            value={data[option.name]} onChange={e => editTextFieldValue(e, option.name)} options={option.options} />
                        </div>
                      </div>
                    )
                  }
                  case 'radio': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <RadioGroup
                            error={errors[option.name]}
                            helperText={errors[option.name]}
                            onChange={e => editTextFieldValue(e.target.value, option.name)}
                            value={data[option.name]}
                            aria-label="gender"
                            name="radio-buttons-group"
                          >

                            {
                              option?.options?.map(radioOption => (<FormControlLabel value={radioOption.value} control={<Radio />} label={radioOption.title} />))
                            }

                          </RadioGroup>
                        </div>
                      </div>

                    )
                  }
                  case 'date': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              error={errors[option.name]}
                              helperText={errors[option.name]}
                              onChange={v => editTextFieldValue(v, option.name)}
                              value={data[option.name]}
                              className="mt-0 mb-0"
                              inputVariant="outlined"
                              format={"DD-MMM-YYYY"}
                              // maxDate={props.maxDate}
                              // minDate={props.minDate}
                              margin="normal"
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    )
                  }
                  case 'file': {
                    return (
                      <div className="form-group row" key={option.name}>
                        <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
                        <div className="col-9">
                          <FileInput
                            error={errors[option.name]}
                            helperText={errors[option.name]}
                            onChange={file => editTextFieldValue(file, option.name)}
                            value={data[option.name]} />
                        </div>
                      </div>
                    )
                  }
                }
              })
            }
          </BaseModal>
        </div>
      </div>
    </>
  )




}
