import React from 'react'

function ProfileWidgetItem({name, value, title, component, separator}) {
  return separator ? (
    <>
      <div className="col-lg-13 mb-10">
        <hr />
      </div>
    </>
  ) : (
    <>
      <div className="row mb-7 ui-no-mb-if-last">
        <label className="col-lg-7 fw-bold text-muted">{title || name}</label>

        {!!component ? (
          component(value)
        ) : (
          <div className="col-lg-5">
            <span className="fw-bolder fs-6 text-gray-800">{value}</span>
          </div>
        )}
      </div>
    </>
  );
}

export default function ProfileViewWidget ({title, action, items}) {

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title m-0">
            <h3  style={{width: "300px"}} className="fw-bolder m-0">{title}</h3>
          </div>
          {action}
        </div>
        <div className="card-body p-9">
          {
            items.map(item => (<ProfileWidgetItem key={item.name} {...item} />))
          }
          {/*<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
              </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-bold">
                <h4 className="text-gray-900 fw-bolder">We need your attention!</h4>
                <div className="fs-6 text-gray-700">Your payment was declined. To start using tools, please
                  <a className="fw-bolder" href="/metronic8/demo1/../demo1/account/billing.html">Add Payment Method</a>.</div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>

    </>
  )
}
