import React from 'react'
import BaseFieldsConfiguration from '../../../components/EditProfileForm/BaseFieldsConfiguration'

export default function AddressAbstractionComponent({ currentAddress, setCurrentAddress, defaultValue, disabledType = false, errors = {} }) {
  const residentialFields = [
    {
      id: 1,
      disabled: disabledType,
      name: 'address_type',
      title: 'Address type',
      type: 'dropdown',
      onChange: () => {
        setCurrentAddress(old => ({
          ...defaultValue,
          from: null,
          to: null,
          address_type: old.address_type,
          address_location: old.address_location,
          address_quantifiers: old.address_quantifiers,
          address_google_key: old.address_google_key
        }))
      },
      options: [
        {
          value: 'residential',
          title: 'Residential'
        },
        {
          value: 'mailing',
          title: 'Mailing'
        },
        {
          value: 'business',
          title: 'Business'
        },
        {
          value: 'other',
          title: 'Other'
        }
      ]
    },
    {
      id: 2,
      name: 'is_primary',
      title: 'Current primary Address?',
      type: 'switch',
      visible: ctx => ctx.address_type === 'residential',
      onChange: ctx => {
        if (ctx.is_primary) {
          setCurrentAddress(old => ({ ...old, to: null }))
        }
      }
    },
    {
      id: 3,
      name: 'address_location',
      qualifiers_name: 'address_quantifiers',
      place_id_name: 'address_google_key',
      title: 'Address',
      type: 'address'
    },
    {
      id: 4,
      name: 'from',
      title: 'From',
      type: 'date',
      views: ['year'],
      dateFormat: 'yyyy',
      visible: ctx => ctx.address_type === 'residential'
    },
    {
      id: 5,
      name: 'to',
      title: 'To',
      type: 'date',
      views: ['year'],
      dateFormat: 'yyyy',
      disabled: currentAddress.is_primary === true,
      visible: ctx => ctx.address_type === 'residential'
    },
    {
      id: 6,
      name: 'document',
      title: 'Upload copy of document',
      type: 'file',
      visible: ctx => ctx.address_type === 'residential'
    }
  ]

  return (
    <>
      {
        residentialFields.map(option => (
          <BaseFieldsConfiguration errors={errors} value={currentAddress} option={option}
            editTextFieldValue={(value, name) => {
              setCurrentAddress(old => {
                return { ...old, [name]: value }
              })
            }} />
        ))
      }
    </>
  )
}