import React, { useEffect, useRef, useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { TextField, makeStyles } from '@material-ui/core';
import { API_BASE_URL } from "../../config";
import Axios from "axios";

import "./DocumentModal.css";
import { setDocumentStatus } from "../api/document";

const useStyles = makeStyles();

const STATUS_COLORS = {
  unreviewed: "",
  approved: "label-light-success",
  rejected: "label-light-danger"
}

function DocumentModal(props) {
  const [documentInfo, setDocumentInfo] = useState({});
  const [imgReady, setImgReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const document_view = useRef();

  const classes = useStyles();

  const updateDocumentStatus = new_status => {
    setLoading(true);
    
    setDocumentStatus(props.documentId, new_status, props.documentScope)
    .then((response) => {
      if(response.data.success) {
        setDocumentInfo({ ...documentInfo, document_status: new_status });
        if(props.onStatusUpdate) props.onStatusUpdate(props.documentId, new_status);
      }

      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  }

  const sendMessageToUploader = () => {
    setLoading(true);

    Axios.post(`document/${ props.documentId }/message-uploader`, {
      message
    })
    .then((response) => {
      setMessage("");
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  }

  const getDocument = () => {
    Axios({
      url: `${ API_BASE_URL }document/${ props.documentId }?get_uploader=1&scope=${ props.documentScope || "global" }`,
      method: 'GET',
      responseType: 'blob', // Important
    }).then((response) => {
      if(!document_view.current) return;

      const img_url = window.URL.createObjectURL(response.data);
      document_view.current.src = img_url;

      const document_info = JSON.parse(response.headers["x-document-info"]);

      document_info.uploaded_at_text = new Date(document_info.created_at).toLocaleString();

      setDocumentInfo(document_info);
    })
    .finally(() => {
      setImgReady(true);
      setLoading(false);
    })
  }

  useEffect(() => {
    if(props.documentId) {
      setImgReady(false);
      getDocument();
    }
  }, [props.documentId, props.requestId]);

  return (
    <Modal id="document-modal" show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Document #{ props.documentId || "?" }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="document-container">
          <div>{ imgReady !== true && "Loading document..." }</div>
          <img src="" alt="" ref={ document_view } className="document-view"/>
        </div>
        <div className={`right-container${ (imgReady && !loading) ? "" : " disabled" }`}>
          <div className="mb-2 font-weight-bolder">Document Status</div>
          <div
            className={`label label-xl p-6 w-100 label-inline text-capitalize font-weight-bolder ${ STATUS_COLORS[documentInfo.document_status] }`}
            >
            { documentInfo.document_status || "..." }
          </div>
          <div className="mt-5 mb-2 font-weight-bolder">Set new status</div>
          <div className="status-buttons-container">
            <button
                onClick={ () => updateDocumentStatus("approved") }
                className="btn btn-success">
                Approved
            </button>
            <button
                onClick={ () => updateDocumentStatus("rejected") }
                className="btn btn-danger">
                Rejected
            </button>
            <button
                onClick={ () => updateDocumentStatus("unreviewed") }
                className="btn btn-secondary">
                Unreviewed
            </button>
          </div>

          <div className="mt-5 mb-2 font-weight-bolder">Send a message to the uploader</div>
          <TextField
            label="Message for the uploader"
            multiline
            value={message}
            onChange={ e => setMessage(e.target.value) }
            className={classes.textField}
            margin="normal"
            rows={ 5 }
            variant="outlined"
          />

          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={ sendMessageToUploader }>
              Send message
            </Button>
          </div>

          <div className="spacer"></div>

          <div className="my-4">
            <div className="font-weight-lighter text-muted">Person's full name</div>
            <div className="font-weight-bolder text-dark">{ documentInfo.uploader?.user_full_name }</div>
          </div>
          <div className="my-4">
            <div className="font-weight-lighter text-muted">Person's email address</div>
            <div className="font-weight-bolder text-dark">{ documentInfo.uploader?.emails[0] }</div>
          </div>
          <div className="my-4">
            <div className="font-weight-lighter text-muted">Uploaded on</div>
            <div className="font-weight-bolder text-dark">{ documentInfo.uploaded_at_text }</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
          </div>
          <div>
            <Button variant="primary" className="ml-2">
              Previous Document
            </Button>
            <Button variant="primary" className="ml-2">
              Next Document
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DocumentModal;