import Axios from "axios";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";

export default async function SubmitEDD(entity_id, questions, ids) {
  const data = new FormData()

  data.append('questions', JSON.stringify(questions))
  data.append('please_upload_copy_of_statuatory_evidence', questions.please_upload_copy_of_statuatory_evidence)
  data.append('ids', JSON.stringify(ids))
  await Axios.post('/company/entity-due-diligence', data, {
    params: {
      entity_id
    }
  })

  notify({ type: "SUCCESS", title: "Entity Due Diligence Saved" })
  window.scroll({
    top: 0,
    behavior: 'smooth'
  })
}