import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';

export function Donut({ series, colors, labels, title, ...props }) {
  const uid = 'chart' + Date.now() + Math.floor(Math.random() * 100);
  useEffect(() => {
    var options = {
      colors,
      series,
      labels,
      chart: {
        type: 'donut',
        width: '100%'
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            chart: {
              width: 150
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: -5
              },
              value: {
                show: true,
                color: undefined,
                offsetY: +5,
                formatter: function(val) {
                  return val;
                }
              },
              total: {
                show: true,
                label: 'Total',
                color: '#ffa500',
                formatter: function(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                }
              }
            }
          }
        }
      }
    };

    const chart = new ApexCharts(document.getElementById(uid), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);
  return (
    <div style={{ height: '100%' }} className={`card card-custom gutter-b ${props.className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <div className="card-title w-100 ml-0 align-items-start flex-column">
          <div style={{ fontSize: '1.5rem' }} className="fw-bolder m-0 w-100">
            {title}
          </div>
        </div>
      </div>
      {/* Body */}
      <div id={uid} />
    </div>
  );
}
