/* eslint-env jquery */
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";
import DashboardButton from "../components/_common/DashboardButton";
import DashboardSearch from "../components/_common/DashboardSearch";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import { Table } from "../modules/Auth/pages/customers/customers-table/CustomersTable";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    background: "#fff",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "31px 2%"
  },
  headerRightSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 23
  }
});

const columns = [
  {
    id: 1,
    name: "company_name",
    title: "Entity name",
    width: "200px",
  },
  {
    id: 2,
    name: "company_type",
    title: "Entity Type",
    width: "200px"
  },
  {
    id: 3,
    name: "company_config.registration_number",
    title: "Registration Number",
    width: "200px"
  },
  {
    id: 4,
    name: "company_config.incorporation_date",
    title: "Date of formation",
    width: "200px",
    component: row => row.company_config.incorporation_date && moment(row.company_config.incorporation_date).format('DD MMM YYYY') || '-'
  },
  // {
  //   id: 5,
  //   name: "risk_rating",
  //   title: "Risk rating",
  //   width: "200px"
  // },
  {
    id: 6,
    name: "company_status",
    title: "Status",
    width: "200px"
  },
  // {
  //   id: 7,
  //   name: "issues",
  //   title: "Issues",
  //   width: "200px"
  // },
];

const Header = () => {
  const history = useHistory();
  const styles = useStyles();

  return (
    <>
      <div className={styles.header}>
        <span style={{ fontSize: 24 }}>Client Entities</span>

        <div className={styles.headerRightSection}>
          <DashboardSearch />

          <Link to="/add-new-client">
            <DashboardButton>Add entity</DashboardButton>
          </Link>
        </div>
      </div>
    </>
  );
};

const EntitiesTable = props => {
  const [companies, setCompanies] = useState([]);

  const getCompanies = () => {
    Axios.get("company/all")
      .then(response => {
        if (response.data && response.data.companies) {
          const profiles = [];

          for (const profile of response.data.companies) {
            profiles.push({
              ...profile,
              artifactId: profile.company_id
            });
          }

          setCompanies(profiles);
        }
      })
      .catch(() => {});
  };

  // On mount - get the companies in the csp
  useEffect(getCompanies, []);

  // TODO @placeholder. Getting all companies at once for now. In the future, limit to something like 50 per request, and
  // utilize the total param vvv
  return (
    <Table
      // loadMoreText="Showing {{c}} of {{t}} companies"
      // total={companies.length}
      nonSelectable
      clickable
      cols={columns}
      entities={companies}
      artifactBaseURL="entity"
      {...props}
    />
  );
};

export default function ClientEntitiesPage() {
  const history = useHistory();
  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header border-0 py-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Client Entities
              </div>
            </div>
            <div className="card-toolbar">
              <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                <li className="nav-item">
                  <Link to="/add-new-client" className="btn btn-primary">
                    Add entity
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="card-body">
            <div className="col-lg-12">
              <EntitiesTable
                rowEvents={{
                  onClick: (e, row) => {
                    history.push(`/entity/${row.company_id}/`);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
