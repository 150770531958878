import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Table } from "../modules/Auth/pages/customers/customers-table/CustomersTable";
import { useHistory } from "react-router-dom";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Modal, Button } from "react-bootstrap";
import { FormSelect, FormInput } from "../components/Basic";
import { notify } from "../../redux/NotificationSnackbar/snackbarActions";
import { MenuItem } from '@material-ui/core';

function RemoveCSPUsersModal(props) {
  const remove = () => {
    if(props.onRemove) props.onRemove();
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Removing CSP Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to remove selected users from this CSP?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={ remove }
        >
          Confirm deletion
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function AddCSPUserModal(props) {
  const [newUserInfo, setNewUserInfo] = useState({
    role: "user"
  });

  const updateUserInfo = e => {
    setNewUserInfo({ ...newUserInfo, [e.target.name]: e.target.value });
  }

  const addUser = () => {
    Axios.post("invitation/create", {
      scope: "csp",
      role: newUserInfo.role,
      target_csp: "current",
      invitee_email: newUserInfo.email_address
    })
    .then(() => {
      setNewUserInfo({...newUserInfo, email_address: undefined})
      notify({ type: "SUCCESS", title: "User invited", message: "An invitation was sent to a specified email address" });
    })
    .catch(() => {});
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add a new CSP User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>You are about to add a new user/admin to your currently selected CSP</div>
        <FormSelect
          mui
          label="Role"
          type="text"
          name="role"
          className="my-5 w-100"
          onChange={updateUserInfo}
          value={newUserInfo.role}
        >
          {[
              <MenuItem value="user" key='user'>CSP User</MenuItem>,
              <MenuItem value="admin" key='admin'>CSP Admin</MenuItem>
          ]}
        </FormSelect>

        <FormInput
          mui
          type="text"
          className="w-100"
          label="Email address"
          name="email_address"
          onChange={updateUserInfo}
          value={newUserInfo.email_address}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={ addUser }
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function CSPUsersPage() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [newUserModalShown, setNewUserModalShown] = useState(false);
  const [removeUsersModalShown, setRemoveUsersModalShown] = useState(false);

  const removeUsers = () => {
    Axios.post("csp/remove-users", {
      user_ids: selectedUsers
    })
    .then(() => {
      getUsers();
      setRemoveUsersModalShown(false);
      setSelectedUsers([]);

      notify({ type: "SUCCESS", title: "Users removed", message: "Selected users were successfully removed from this CSP" });
    })
    .catch((data) => {
      if(data.response.data) {
        notify({ type: "ERROR", title: "Could not remove selected users", message: data.response.data.error_message });

        setRemoveUsersModalShown(false);
      }
    });
  }

  const showRemoveModal = () => {
    if(selectedUsers.length) {
      setRemoveUsersModalShown(true);
    } else {
      notify({ type: "ERROR", title: "No users selected", message: "Please, select the users first" });
    }
  }

  const cols = [
    {
      id: 1,
      name: "fullName",
      title: "User Name",
    },
    {
      id: 2,
      name: "email",
      title: "Email",
    },
    {
      id: 3,
      name: "role",
      title: "Role",
    },
    {
      id: 4,
      name: "lastLoginDate",
      title: "Last Login",
    }
  ];

  const getUsers = () => {
    Axios.get("csp/users")
      .then(({ data }) => {
        const newUsers = data.users.map(user => ({
          ...user,
          id: user.user_id,
          fullName: user.user_full_name,
          email: user.emails[0],
          role: user.user_role,
          lastLoginDate: "@PLACEHOLDER"
        }));

        setUsers(newUsers);
      })
      .catch(() => {});
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/user/${row.id}`);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="col-lg-12">
      <AddCSPUserModal show={ newUserModalShown } onHide={ () => setNewUserModalShown(false) } />

      <RemoveCSPUsersModal
        show={ removeUsersModalShown }
        onHide={ () => setRemoveUsersModalShown(false) }
        onRemove={ removeUsers }
      />

      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Manage CSP users</div>
          </div>
          <div className="card-toolbar">
            <div className="my-lg-0 my-3 d-flex flex-row align-items-stretch">
              <span
                className="btn btn-sm btn-info font-weight-bolder text-uppercase mr-5"
                onClick={ showRemoveModal }
              >
                <DeleteOutlineIcon />
              </span>
              <span
                className="btn btn-sm btn-info font-weight-bolder text-uppercase pl-10 pr-10 d-flex flex-row align-items-center"
                onClick={ () => setNewUserModalShown(true) }
              >
                Create new access
              </span>
            </div>
          </div>
        </div>

        <div className="card-body">
          <Table
            setSelectedEntities={setSelectedUsers}
            idField="user_id"
            clickable
            cols={cols}
            entities={users}
            rowEvents={rowEvents}
          />
        </div>
      </div>
    </div>
  );
}
