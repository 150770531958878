import React from 'react'
import SaveIcon from '@material-ui/icons/Save';

export default function SaveButton({Icon = (props) => (<SaveIcon {...props}/>), loading = false, onClick = () => {}, className, title='Save', savingTitle='Saving...', type}) {
  return (
    <button type={type} disabled={loading} onClick={onClick} className={`d-flex align-items-center btn btn-primary ${className}`}>
      {loading && (<span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />)}
      {!loading && Icon && (<Icon className="mr-2" />)}
      {!loading ? title : savingTitle}
    </button>
  )
}