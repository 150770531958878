import Axios from 'axios'

export async function updateTask(task_id, action_type) {
  const response = await Axios.put(`task/${task_id}/${action_type}`)
  if (response && response.data.task) {
    return response.data.task
  }
}

export async function checkShareholder(shareholder_id, value) {
  await Axios.post('/company/shareholder-check', { value }, {
    params: {
      shareholder_id
    }
  })
}

export async function changeTaskAssignation(task_id, user_id) {
  await Axios.post('/task/assign', {
    task_id, user_id
  })
}