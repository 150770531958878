import { useEffect, useState } from "react"
import useRequest from "../../../../components/useRequest/useRequest"
import useOwnershipTypes from "../../../create-company-page/hooks/useOwnershipTypes"
import { getShareClasses } from "../api/api"

export default function useShareClasses({category, entity_id}) {
  const [shareClasses, setShareClasses] = useState([])

  const [ownershipTypes, visibleFields, currency] = useOwnershipTypes(category)

  const [fetchShareTypes] = useRequest(() => getShareClasses(entity_id), data => {
    if (data) {
      setShareClasses(data)
    }
  })
  
  useEffect(() => {
    if (entity_id && category) {
      fetchShareTypes()
    }
  }, [category, entity_id])

  return [shareClasses, ownershipTypes, visibleFields, currency, fetchShareTypes]
}