import Axios from 'axios'
import store from '../../redux/store';
import { setAvailableCSPs, setCurrentCSP, setTenantSlug } from '../../redux/Tenant/tenantActions';
import { b64toBlob } from '../helpers/api';

export async function getJurisdictionRegions() {
  try {
    const { data } = await Axios.get('csp/jurisdiction-regions');
    return data;
  } catch (e) { }
}

export async function getCSPById(id) {
  const response = await Axios.get('/csp/get/:csp_id'.replace(':csp_id', id));
  return response.data;
}

export async function updateCSP(body) {
  const data = new FormData()

  data.append('csp_logo', await b64toBlob(body.csp_logo))
  return Promise.all([Axios.post('csp/update-csp', { ...body, csp_logo: undefined }),
  // Axios.post('csp/update-csp_logo', new File([new Blob([body.csp_logo])], 'csp_logo'))])
  Axios.post('csp/update-csp_logo', data)])
}

export async function getCSPs() {
  const { data } = await Axios.get('csp/my');
  store.dispatch(setAvailableCSPs(data.csp_companies));
  setTenant();
}

export function setTenant() {
  const tenantState = store.getState().tenant;
  if (!localStorage.getItem("tenant")) {
    if (tenantState.availableCSPs[0]) {
      const tenant_slug = tenantState.availableCSPs[0].tenant.tenant_slug;
      localStorage.setItem("tenant", tenant_slug)
      store.dispatch(setTenantSlug(tenant_slug))
      store.dispatch(setCurrentCSP(tenantState.availableCSPs[0]))
    }
  } else {
    const current_tenant = localStorage.getItem("tenant")
    const current_csp = store.getState().tenant.availableCSPs.find(csp => csp.tenant.tenant_slug === current_tenant)
    if (current_tenant) store.dispatch(setTenantSlug(current_tenant))
    if (current_csp) store.dispatch(setCurrentCSP(current_csp))
  }
}

export function exportEntitiesFromCSV(form_data) {
  return Axios.post(`csp/import-entities-csv-next`, form_data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }) 
}

export async function getCSPUsers() {
  const response = await Axios.get('/csp/csp-users')
  if (response?.data) {
    return response.data.users
  }
}

export async function getTaskDueDate() {
  const response = await Axios.get('/csp/task-due-date')
  if (response?.data) {
    return response.data.days
  }
}