import Axios from 'axios'
import { b64toBlob } from '../helpers/api';
import { hddRequest } from '../helpers/hddParse';

export async function getUserProfileLookups() {
  const response = await Axios.get('/user/lookups')

  if (response) {
    const { prefixes, suffixes, jurisdiction_regions, countries, genders, contact_preferences, id_types, id_types_titles } = response.data;
    return { prefixes, suffixes, jurisdiction_regions, countries, genders, contact_preferences, id_types, id_types_titles }
  }

}

export async function getProfileSettings() {
  const response = await Axios.get('/user/profile')
  if (response) {
    const { hdd_answers, hdd_documents } = response.data;
    return hdd_answers;
  }
}

export async function saveUserProfileSettings(profile, idObject, nationalities, deletingNationalitiesIds) {
  const data = hddRequest({ ...profile, avatar: undefined }, { ...idObject, avatar: undefined }, nationalities, deletingNationalitiesIds)
  const avatarData = new FormData()
  const blob = await b64toBlob(profile.avatar)
  avatarData.append('avatar', blob)
  avatarData.append('id', idObject.avatar)

  await Promise.all([
    Axios.post('/user/profile', data, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    }),
    Axios.post('/user/profile_avatar', avatarData)
  ])
}

export function deleteNotification(id) {
  return Axios.delete(`/user/user-notifications/${id}`)
}

export function deleteAllNotifications(user_id) {
  return Axios.delete(`/user/all-notifications/${user_id}`)
}

export function setNotificationStatus(notification_id, notification_status) {
  return Axios.post('/user/notification-status', {
    notification_id,
    notification_status
  });
}

export async function getHDDDocuments(user_id) {
  const response = await Axios.get(`/user/profile-ids`)
  if (response && response.data) {
    return response.data.user_hdd_documents
  }
}

export async function getEddTitles() {
  const response = await Axios.get(`/user/edd-titles`)
  if (response && response.data) {
    return response.data
  }
}

export async function saveUserAddress(data, user_id) {
  const formData = new FormData()

  const quantifiers = function (quantifiers) {
    const result = {}

    quantifiers && quantifiers.forEach(({ qualifier_name = '', qualifier_value = '' }) => {
      result[qualifier_name] = qualifier_value
    })

    return result
  }(data.address_quantifiers)

  formData.append('address_body', JSON.stringify({
    id: data.id,
    user_id: data.user_id,
    address_type: data.address_type,
    is_primary: data.is_primary,
    address_location: data.address_location,
    address_google_key: data.address_google_key,
    address_quantifiers: quantifiers,
    from: data.from,
    to: data.to
  }))

  if (data.document) {
    formData.append('uploaded_document', data.document)
  }

  return Axios.post('/user/address', formData, {
    params: {
      user_id
    }
  })
}

export async function getUserAddresses(user_id) {
  function formatQualifiers(array) {
    array.forEach(object => {
      if (!object.address_quantifiers) return;
      
      object.address_quantifiers = Object.keys(object.address_quantifiers).map(key => ({
        qualifier_name: key,
        qualifier_value: object.address_quantifiers[key]
      }))
    })
  }
  const response = await Axios.get(`/user/address`, {
    params: {
      user_id
    }
  })
  if (response && response.data) {
    const data = response.data
    formatQualifiers(data.primary_addresses)
    formatQualifiers(data.other_residential)
    formatQualifiers(data.other_addresses)
    return data
  }
}

export async function deleteUserAddress(id, user_id) {
  await Axios.delete('/user/address', {
    params: {
      id,
      user_id
    }
  })
}

export async function saveAnotherResidential(data, user_id) {
  await Axios.post('/user/another-addresses', { data }, {
    params: {
      user_id
    }
  })
}

export async function getAnotherResidentials(user_id) {
  const response = await Axios.get('/user/another-addresses', {
    params: {
      user_id
    }
  })
  if (response && response.data) {
    return response.data.another_residentials
  }
}
