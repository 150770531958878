import { MenuItem, TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { getCSPUsers, getTaskDueDate } from '../../../api/csp'
import { changeTaskAssignation } from '../../../api/task'
import useRequest from '../../../components/useRequest/useRequest'

const roles = {
  'csp.admin': 'CSP Admin',
  'csp.user': 'CSP User'
}

export default function AssignationTaskPage({ created_at, task_id, task_assignee }) {
  const [users, setUsers] = useState([])
  const [days, setDays] = useState(0)
  const [fetchUsers] = useRequest(getCSPUsers, d => d && setUsers(d))
  const [fetchDays] = useRequest(getTaskDueDate, d => d && setDays(d))

  const [selected, setSelected] = useState(0)

  useEffect(() => {
    if (task_assignee) {
      setSelected(task_assignee)
    }
  }, [task_assignee])

  useEffect(() => {
    fetchUsers()
    fetchDays()
  }, [])

  const [submitAssignee] = useRequest((user_id) => changeTaskAssignation(task_id, user_id), (_, user_id) => setSelected(user_id))

  return (
    <div className="card card-custom mb-4">
      <div className="card-body">
        <div className="col-lg-12">
          <div className="row mb-4 d-flex justify-content-between">
            <b>Due Date: </b> <span>{moment(created_at).add(days, 'days').format('DD-MMM-YYYY')}</span>
          </div>

          <div className="row">
            <TextField
              value={selected}
              onChange={(e) => submitAssignee(e.target.value)}
              variant='outlined'
              label='Assigned by'
              fullWidth
              select>
              {
                users.map(user => (<MenuItem key={user.user_id} value={user.user_id}>{user.user_full_name} ({roles[user.user_role]})</MenuItem>))
              }
            </TextField>

          </div>

        </div>

      </div>

    </div>
  )
}