import React from 'react'
import { WidgetTable } from '../WidgetTable/WidgetTable'

const cols = [
  {
    name: 'name',
    title: 'Name',
    width: 100
  },
  {
    name: 'type',
    title: 'Type',
    width: 100
  },
  {
    name: '_total_percentage',
    title: 'Ownership, %',
    width: 100
  },
]

export default function OwnershipSummaryWidget({ rows = [] }) {
  return (
    <WidgetTable
    {...{cols, rows,
      title: 'Ownership Summary',
      subtitle: ''
    }} 
    />
  )
}