import axios from 'axios'

export async function getEntityStakeholders(entity_id, csp_id) {
  const response = await axios.get('/company/entity-staff', {
    params: {
      entity_id,
      csp_id
    }
  })
  
  if (response?.data) {
    return response.data.staff
  }
}

export async function saveEntityStakeholder(entity_id, person) {
  await axios.post('/company/entity-staff', {
    person
  }, {
    params: {
      entity_id
    }
  })
}