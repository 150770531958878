import React from 'react'
import moment from "moment";
import { Link } from "react-router-dom";

export function WidgetTableCell({col, row, index}) {
  let title;

  if(col.is_date) {
    const date = moment(row[col.name]);
    title = date ? date.format("DD/MMM/yyyy") : row[col.name];
  } else if(col.map) {
    title = col.map[row[col.name]]
  } else {
    title = row[col.name];
  }

  return (
    <>
      <td className={index === 0 && 'pl-7'}>
        { row.link ? (
          <Link to={ row.link } className="text-dark-75 font-weight-bolder d-block font-size-lg">{col.component ? col.component(row) : title}</Link>
        ) : (
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{col.component ? col.component(row) : title}</span>
        ) }
        <span className="text-muted font-weight-bold">{!col.component && col.title}</span>
      </td>
    </>
  )
}

export function WidgetTable({title, subtitle, toolbar, cols, rows}) {
  return (
    <>
      <div className="card card-custom gutter-b" style={{flex: 1}}>
        <div className="card-header border-0 py-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div className="font-weight-bolder m-0 table-title">{title}</div>
            <div className="text-muted mt-3 font-weight-bold font-size-sm">{subtitle}</div>
          </div>
          {
            !!toolbar && toolbar
          }
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-uppercase">
                  {
                    cols.map((col, index) => (
                      <th style={{minWidth: col.width}} className={index === 0 && "pl-7"}>
                        <span className="text-dark-75">{col.title}</span>
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
              {
                  rows.map(row => (
                  <tr key={row.id} >
                    {cols.map((col, index) => (
                      <WidgetTableCell key={col.name} index={index} col={col} row={row} />
                    ))}
                  </tr>))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}
