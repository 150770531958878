import React, { useEffect, useState } from 'react';
import moment from "moment";
import Axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import '../ProfilePage.css';
import VaultRoute from "./routes/VaultRoute";
import StakeholdersRoute from "./routes/StakeholdersRoute";
import SharesRoute from "./routes/SharesRoute";
import { ReportingPageRoute } from "./routes/ReportingPage";
import GeneralRoute from "./routes/GeneralRoute";
import BeneficialOwnersRoute from "./routes/beneficialOwnersRoute";
import AddressRoute from "../AddressRoute/AddressRoute";
import HomeRoute from './routes/HomeRoute';
import EntityConfigRoute from "./EntityConfigRoute/EntityConfigRoute";
import ProfileGeneralRoute from './GeneralRoute/ProfileGeneralRoute';
import SummaryOwnershipRoute from './OwnershipRoute/SummaryOwnershipRoute';
import OwnershipTypesRoute from './OwnershipRoute/OwnershipTypesRoute';
import OwnershipDistributionRoute from './OwnershipRoute/OwnershipDistributionRoute';
import TransactionHistoryRoute from './OwnershipRoute/TransactionHistoryRoute';
import FeesRoute from "./FeesRoute/EntityFeesRoute";
import ComplianceOverviewRoute from "./routes/ComplienceOverviewRoute";
import DirectorsOfficersRoute from "./StakeholdersRoute/DirectorsOfficersRoute"
import OwnersRoute from "./StakeholdersRoute/OwnersRoute";
import OtherRoute from "./StakeholdersRoute/OtherRoute";
import LogRoute from "./StakeholdersRoute/LogRoute";
import CompanyUsersRoute from "./StakeholdersRoute/CompanyUsersRoute";
import { useSelector } from 'react-redux';
import EntityRiskRatingsPage from './EntityRiskRatings/entity-risk-ratings';
import useEntitySecondAside from './hooks/useEntitySecondAside';
import EntityBanner from './Banner/EntityBanner';
import ControllingPersons from './ControllingPersons/ControllingPersons';
import EntityProfileDD from './entity-profile-dd';

export const Row = (props) => (
  <td className={props.first ? '' : `pl-0`}>
    <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
      {props.children}
    </span>
  </td>
);

const get_shareholder_name = shareholder => {
  let name = "N/A";
  let type = "N/A";

  if (shareholder.user) {
    name = shareholder.user.user_full_name;
    type = "Human";
  }
  else if (shareholder.pc) {
    name = shareholder.pc.pc_name;
    type = "PC";
  }
  else if (shareholder.customer_company_available_shareholder
    && shareholder.customer_company_available_shareholder.holder_company_name) {
    name = shareholder.customer_company_available_shareholder.holder_company_name;
    type = "Company";
  }

  return [name, type];
}

export const add_general_partner_category = categories => {
  categories.push({
    category_id: -1,
    category_name: "General Partner",
  });

  return categories;
}

function EntityProfilePage(props) {
  const entity_id = props.match.params.entity_id;

  const csp_id = useSelector(state => state.tenant.csp?.csp_id)

  const [basicEntityInfo, setBasicEntityInfo] = useState({});
  const [additionalEntityInfo, setAdditionalEntityInfo] = useState({});

  const [entityStakeholders, setEntityStakeholders] = useState();
  const [entityUsers, setEntityUsers] = useState();

  const appendAdditionalInfo = data => {
    setAdditionalEntityInfo({ ...additionalEntityInfo, ...data });
  }

  const getBasicCompanyInfo = () => {
    Axios.get(`company/${entity_id}/profile`)
      .then(response => {
        if (response.data && response.data.company_profile) {
          setBasicEntityInfo(response.data.company_profile);
        }
      })
      .catch(() => {
      });
  }

  const getCompanyUsers = () => {
    Axios.get(`company/users/${entity_id}`)
      .then(response => {
        if (response.data && response.data.success) {
          response.data.users = response.data.users.map(user => {
            user._email = user.emails ? user.emails[0] : "N/A";
            user._joined_on = user.became_company_user_on ? moment(user.became_company_user_on).format("DD/MMM/yyyy") : "N/A";

            return user;
          });

          setAdditionalEntityInfo({
            ...additionalEntityInfo,

            company_users: response.data.users
          });

          setEntityUsers(response.data.users);
        }
      })
      .catch(() => {
      });
  }

  const getCompanyAddresses = () => {
    Axios.get(`company/${entity_id}/addresses`)
      .then(response => {
        if (response.data && response.data.addresses) {
          setAdditionalEntityInfo({
            ...additionalEntityInfo,

            addresses: response.data.addresses
          });
        }
      })
      .catch(() => {
      });
  }

  const getCompanyPartnershipCategories = () => {
    Axios.get(`company/${entity_id}/partnership-categories`)
      .then(response => {
        if (response.data && response.data.partnership_categories) {
          const partnership_categories = add_general_partner_category(response.data.partnership_categories);

          setAdditionalEntityInfo({
            ...additionalEntityInfo,

            partnership_categories
          });
        }
      })
      .catch(() => {
      });
  }

  const getCompanyPartnershipCapTable = () => {
    Axios.get(`company/${entity_id}/partnership-cap-table`)
      .then(response => {
        if (response.data && response.data.partnership_cap_table) {
          const all_partners_sorted = response.data.partnership_cap_table.map(row => {
            const [name, type] = get_shareholder_name(row);

            row.name = name;
            row.type = type;

            row._total_percentage = row.customer_company_partnership_capitalization.total_ownership;

            return row;
          })

          // Limit the sorted short list to 5 entries
          let all_partners_sorted_short = [...all_partners_sorted];

          if (all_partners_sorted_short.length > 5) all_partners_sorted_short.length = 5;

          const partnership_categories = add_general_partner_category(response.data.partnership_categories);

          setAdditionalEntityInfo({
            ...additionalEntityInfo,

            partnership_cap_table: response.data.partnership_cap_table,
            partnership_categories,
            all_partners_sorted,
            all_partners_sorted_short
          });
        }
      })
      .catch(() => {
      });
  }

  const getStakeholdersInfo = () => {
    Axios.get(`company/${entity_id}/stakeholders`)
      .then(response => {
        if (response.data && response.data.success) {
          const officers = response.data.officers.map(officer => {
            officer._name = officer.person_user ? officer.person_user.user_full_name : officer.person_pending_full_name;
            officer._email = officer.person_user ? officer.person_user.emails[0] : officer.person_pending_email_address;

            officer._position = officer.person_position || officer.person_role;

            return officer;
          });

          const directors = response.data.directors.map(director => {
            director._name = director.person_user ? director.person_user.user_full_name : director.person_pending_full_name;
            director._email = director.person_user ? director.person_user.emails[0] : director.person_pending_email_address;

            return director;
          });

          const stakeholders_log = response.data.stakeholders_log.map(log_item => {
            log_item._stakeholder = log_item.staff_user.person_user
              ? (log_item.staff_user.person_user.user_full_name || log_item.staff_user.person_pending_full_name)
              : log_item.staff_id;

            log_item._initiator = log_item.initiator_user
              ? log_item.initiator_user.user_full_name
              : "N/A";

            log_item._date = moment(log_item.created_at).format("DD/MMM/yyyy");

            return log_item;
          });

          setAdditionalEntityInfo({
            ...additionalEntityInfo,

            stakeholders: {
              ...response.data,

              directors,
              officers,
              stakeholders_log
            }
          });

          setEntityStakeholders({
            ...response.data,

            directors,
            officers,
            stakeholders_log
          });
        }
      })
      .catch(() => {
      });
  }

  // TODO @cleanup @refactor
  const getSharesInfo = () => {
    Axios.get(`company/${entity_id}/share-classes`)
      .then(response => {
        if (response.data && response.data.share_classes) {
          response.data.share_classes = response.data.share_classes.map(share_class => {
            const issued_on = moment(share_class.share_issued_on);

            share_class._unissued_shares = share_class.share_authorized_shares - share_class.share_outstanding_shares;
            share_class._issued_on = issued_on ? issued_on.format("DD/MMM/YYYY") : "N/A";

            share_class.share_issued_on = new Date(share_class.share_issued_on)
              .toJSON()
              .split("T", 1)[0];

            return share_class;
          });

          response.data.share_transactions = response.data.share_transactions.map(transaction => {
            const completed_on = transaction.transaction_completed_on && moment(transaction.transaction_completed_on);

            transaction._from = transaction.from_user && transaction.from_user.user_full_name;
            transaction._to = transaction.to_user && transaction.to_user.user_full_name;

            transaction._initiator = transaction.initiator_user && transaction.initiator_user.user_full_name;
            transaction._completed_on = completed_on ? completed_on.format("DD/MMM/YYYY") : "N/A";

            return transaction;
          });

          const added_class_ids = [];
          const share_class_group_definitions = [];
          const share_class_groups = [];

          // TODO @refactor it would be much nicer to have a separate short_shareholders_list instead
          let all_shareholders_sorted = response.data.all_shareholders_sorted.map(shareholder => {
            const [name, type] = get_shareholder_name(shareholder);

            shareholder._total_percentage = shareholder._total_percentage.toFixed(2);

            shareholder.name = name;
            shareholder.type = type;

            return shareholder;
          });

          // Limit the sorted short list to 5 entries
          const all_shareholders_sorted_short = [...all_shareholders_sorted];

          if (all_shareholders_sorted_short.length > 5) all_shareholders_sorted_short.length = 5;

          for (const shareholder of response.data.cap_table) {
            const group = share_class_groups.find(group => group.id === shareholder.share_id);

            const [name, type] = get_shareholder_name(shareholder);

            const new_shareholder = {
              id: shareholder.shareholder_id,
              name,
              type,
              shares_held: shareholder.shares_held,
              ownership_percentage: `${((shareholder.shares_held / response.data.total_shares_held) * 100).toFixed(2)}%`
            }

            if (!group) {
              share_class_groups.push({
                id: shareholder.share_id,
                rows: [new_shareholder]
              });
            } else {
              group.rows.push(new_shareholder);
            }

            if (!shareholder.customer_company_share) continue;

            if (!added_class_ids.includes(shareholder.share_id)) {
              added_class_ids.push(shareholder.share_id);

              share_class_group_definitions.push({
                id: shareholder.share_id,
                name: shareholder.customer_company_share.share_name,
                type: "Share class"
              });
            }
          }

          setAdditionalEntityInfo({
            ...additionalEntityInfo,
            ...response.data,

            cap_table: response.data.cap_table,
            share_classes: response.data.share_classes,
            share_transactions: response.data.share_transactions,
            total_shares_held: response.data.total_shares_held,
            all_shareholders_sorted,
            all_shareholders_sorted_short,

            _share_class_group_definitions: share_class_group_definitions,
            _share_class_groups: share_class_groups
          });
        }
      })
      .catch(() => {
      });
  }

  const queryAdditionalInfo = (type, force) => {
    if (type === "shares" && (!additionalEntityInfo.cap_table || force)) getSharesInfo();
    else if (type === "partnership_categories" && (!additionalEntityInfo.partnership_categories || force)) getCompanyPartnershipCategories();
    else if (type === "partnership_cap_table" && (!additionalEntityInfo.partnership_cap_table || force)) getCompanyPartnershipCapTable();
    else if (type === "stakeholders" && (!additionalEntityInfo.stakeholders || force)) getStakeholdersInfo();
    else if (type === "company_users" && (!additionalEntityInfo.company_users || force)) getCompanyUsers();
    else if (type === "addresses" && (!additionalEntityInfo.addresses || force)) getCompanyAddresses();
  }

  // On mount
  useEffect(() => {
    if ((basicEntityInfo.company_id && basicEntityInfo.company_id !== entity_id) || !Object.keys(basicEntityInfo).length) {
      getBasicCompanyInfo();
    }
  }, [entity_id]);

  useEntitySecondAside({ csp_id, entity_id })

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        {/* <CompanyProfileHeader basicEntityInfo={basicEntityInfo} company_id={entity_id} /> */}
        <EntityBanner entity_id={entity_id}></EntityBanner>
        <div className="row">
          <Switch>
            <Route path="/entity/:entity_id/address">
              <AddressRoute
                entity_id={entity_id}
              />
            </Route>
            <Route path="/entity/:entity_id/vault" component={VaultRoute} />
            <Route path="/entity/:entity_id/stakeholders" component={StakeholdersRoute} />
            <Route path="/entity/:entity_id/reporting" component={ReportingPageRoute} />
            <Route path="/entity/:entity_id/beneficial-owners" component={BeneficialOwnersRoute} />
            <Route path="/entity/:entity_id/general" component={GeneralRoute} />
            <Route path="/entity/:entity_id/profile">
              <ProfileGeneralRoute entity_id={entity_id} basicEntityInfo={basicEntityInfo} />
            </Route>
            <Route path="/entity/:entity_id/ownership-summary">
              <SummaryOwnershipRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/ownership-config">
              <OwnershipTypesRoute
                entity_id={entity_id}
                category={basicEntityInfo.company_category}
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                setAdditionalEntityInfo={setAdditionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/ownership-distribution">
              <OwnershipDistributionRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/transaction-history">
              <TransactionHistoryRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/fee-table" component={FeesRoute} />
            <Route path="/entity/:entity_id/stakeholders-owners">
              <OwnersRoute
                entity_id={entity_id}
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/stakeholders-directors_officers">
              <DirectorsOfficersRoute
                basicEntityInfo={basicEntityInfo}
                queryAdditionalInfo={queryAdditionalInfo}
                entityUsers={entityUsers}
                entityStakeholders={entityStakeholders}
              />
            </Route>
            <Route path="/entity/:entity_id/stakeholders-other">
              <OtherRoute
                basicEntityInfo={basicEntityInfo}
                queryAdditionalInfo={queryAdditionalInfo}
                entityUsers={entityUsers}
                entityStakeholders={entityStakeholders}
              />
            </Route>
            <Route path="/entity/:entity_id/controlling-persons">
              <ControllingPersons entity_id={entity_id} entity_category={basicEntityInfo.company_category} />
            </Route>
            <Route path="/entity/:entity_id/stakeholders-log">
              <LogRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/entity/:entity_id/stakeholders-users">
              <CompanyUsersRoute
                basicEntityInfo={basicEntityInfo}
                queryAdditionalInfo={queryAdditionalInfo}
                entityUsers={entityUsers}
                entityStakeholders={entityStakeholders}
              />
            </Route>

            <Route path="/entity/:entity_id/shares-old" component={SharesRoute} />
            <Route path="/entity/:entity_id/stakeholders-old" component={StakeholdersRoute} />

            <Route path="/entity/:entity_id/compliance-overview" component={ComplianceOverviewRoute} />
            <Route path="/entity/:entity_id/config">
              <EntityConfigRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
                company_id={entity_id}
              />
            </Route>

            <Route path={"/entity/:entity_id/risk-ratings"}>
              <EntityRiskRatingsPage entity_id={entity_id} />
            </Route>

            <Route path={"/entity/:entity_id/entity-due-diligence"}>
              <EntityProfileDD entity_id={entity_id} />
            </Route>

            <Route path="/entity/:entity_id" exact>
              <HomeRoute
                basicEntityInfo={basicEntityInfo}
                additionalEntityInfo={additionalEntityInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default EntityProfilePage;
