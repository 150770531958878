import React from 'react'
import { checkShareholder } from '../../../api/task'
import LoadingCheckbox from '../../../components/_common/LoadingCheckbox'
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable'



export default function ShareholdersTaskPage({ shareholders = [], onShareholderCheck = () => Promise.resolve() }) {
  const cols = [
    {
      name: 'person_type',
      title: 'Type',
      component: (row) => (<span className='text-capitalize'>{row.person_type}</span>)
    },
    {
      name: 'name',
      title: 'Name'
    },
    {
      name: 'type',
      title: 'Ownership Type'
    },
    {
      name: 'shareholder_sharesissued',
      title: 'Shares to be issued'
    },
    {
      name: 'shareholder_sharespaidup',
      title: 'Shares paid-up'
    },
    {
      name: 'shareholder_checked_by_csp',
      title: 'Completed',
      component: (row) => (<LoadingCheckbox checked={row.shareholder_checked_by_csp}
        onChange={async e => {
          await checkShareholder(row.shareholder_id, e.target.checked)
          await onShareholderCheck()
        }} />)
    }
  ]

  return (<>
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title w-25">
          Ownership Distribution
        </div>
      </div>

      <div className="card-body">
        <Table nonSelectable cols={cols} entities={shareholders} />
      </div>
    </div>
  </>)
}