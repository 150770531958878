import Axios from 'axios';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';
import React, { useState, useRef, createRef, useEffect } from 'react';
import { selectOptions } from './SelectOptions';
import { values } from './InitialValues';
import { STEP_MAPPING } from './StepMapping';
import { validationSchema } from './ValidationSchemas';
import { useSelector } from 'react-redux';
import { getDate } from '../../components/Auxillary';
import { SuccessScreen } from '../SuccessScreen';
import HDDBasic from './pages/hdd-basic';
import HDDCitizenship from './pages/hdd-citizenship';
import HDDAddress from './pages/hdd-address';
import HDDProfessionalAndRegulatory from './pages/hdd-professional-n-regulatory';
import HDDAmlQuestions from './pages/hdd-aml-questions';
import HDDDeclaration from './pages/hdd-declaration';

const initialValues = {};

const STEP_NAMES = [
  /* page 0 */ '',
  /* page 1 */ 'Basic Information',
  /* page 2 */ 'Citizenship',
  /* page 3 */ 'Address',
  /* page 4 */ 'Professional and Regulatory',
  /* page 5 */ 'AML / KYC Questions',
  /* page 6 */ 'Declaration'
];

export default function IndividualDueDiligence(props) {
  const history = useHistory();

  const [nationalities, setNationalities] = useState([])

  const [cspInfo, setCspInfo] = useState({});

  const [page0Text, setPage0Text] = useState('Getting ready, please wait...');

  const [currentPage, setCurrentPage] = useState(0);

  const [answersBasic, setAnswersBasic] = useState({});

  const [answersIdentification, setAnswersIdentification] = useState({});
  const [answersIdentification_ids, setAnswersIdentification_ids] = useState([{}]);

  const [answersContactInfo, setAnswersContactInfo] = useState({});

  const [answersPersonalStatus, setAnswersPersonalStatus] = useState({});

  const [answersKYC, setAnswersKYC] = useState({});

  const [isNameTouched, setNameTouched] = useState(false);

  const user = useSelector(state => state.auth.user);

  const [documents, setDocuments] = useState({
    ids: [[]],
    pors: [[]]
  });

  const [fileInputRefs, setFileInputRefs] = useState({
    ids: [useRef()],
    pors: [useRef()]
  });

  const [loading, setLoading] = useState(false);

  const [physicalAddressQualifiers, setPhysicalAddressQualifiers] = useState([]);
  const [oldAddressQualifiers, setOldAddressQualifiers] = useState([]);
  const [serviceAddressQualifiers, setServiceAddressQualifiers] = useState([]);
  const [otherAddressQualifiers, setOtherAddressQualifiers] = useState([]);

  const page1Formik = useFormik({
    initialValues: values.page1,
    validationSchema: validationSchema.page1,
    validateOnChange: true,
    onSubmit: values => {
      answersBasic.name_prefix = values.name_prefix;
      answersBasic.first_name = values.first_name;
      answersBasic.middle_name = values.middle_name;
      answersBasic.last_name = values.last_name;
      answersBasic.preferred_name = values.preferred_name;
      answersBasic.name_suffix = values.name_suffix;
      answersBasic.email_address = values.email_address;
      answersBasic.date_of_birth = values.date_of_birth;
      answersBasic.phone_number = values.phone_number;
      answersBasic.alias = values.alias;
      answersBasic.gender = values.gender;
      setCurrentPage(currentPage + 1);
    }
  });

  if (user?.emails?.[0] && !page1Formik.values.email_address) {
    page1Formik.setFieldValue('email_address', user?.emails?.[0]);
  }

  const [isDocsFormValid, setDocsFormValid] = useState(true);

  const validateIdentificationDocument = async () => {
    let validation_ok = true;

    // Save all the errors that we have found
    // setIdsErrors({ ...new_errors });
    setDocsFormValid(validation_ok);
    return validation_ok;
  };

  const page2Formik = useFormik({
    initialValues: values.page2,
    validationSchema: validationSchema.page2,
    onSubmit: async values => {
      const ok = await validateIdentificationDocument();
      if (ok) {
        answersIdentification.country_of_birth = values.country_of_birth;
        answersIdentification.is_tax_resident = values.is_tax_resident;
        answersIdentification.tax_resident_country = values.tax_resident_country;
        setCurrentPage(currentPage + 1);
      } else {
        notify({ type: 'ERROR', title: 'Validation error', message: 'There are some fields that need your attention' });
      }
    }
  });

  const page3Formik = useFormik({
    initialValues: values.page3,
    validationSchema: validationSchema.page3,
    onSubmit: values => {
      answersContactInfo.moved_to_physical_address_at = values.moved_to_physical_address_at;
      answersContactInfo.also_operating_in_other_countries = values.also_operating_in_other_countries;
      answersContactInfo.also_operating_in_other_countries_country = values.also_operating_in_other_countries_country;
      setCurrentPage(currentPage + 1);
    }
  });

  const page4Formik = useFormik({
    initialValues: values.page4,
    validationSchema: validationSchema.page4,
    onSubmit: values => {
      answersPersonalStatus.has_professional_designations = values.has_professional_designations;
      answersPersonalStatus.approved_by_other_authority = values.approved_by_other_authority;
      answersPersonalStatus.approved_by_other_authority_country = values.approved_by_other_authority_country;
      answersPersonalStatus.approved_by_other_authority_name = values.approved_by_other_authority_name;
      answersPersonalStatus.approved_by_other_authority_license_number = values.approved_by_other_authority_license_number;
      answersPersonalStatus.professional_designations = values.professional_designations;
      setCurrentPage(currentPage + 1);
    }
  });

  const page5Formik = useFormik({
    initialValues: values.page5,
    validationSchema: validationSchema.page5,
    onSubmit: values => {
      answersKYC.involved_into_litigation = values.involved_into_litigation;
      answersKYC.involved_into_litigation_details = values.involved_into_litigation_details;
      answersKYC.ever_bankrupt = values.ever_bankrupt;
      answersKYC.ever_bankrupt_year = values.ever_bankrupt_year;
      answersKYC.ever_bankrupt_details = values.ever_bankrupt_details;
      answersKYC.ever_investigated = values.ever_investigated;
      answersKYC.ever_investigated_details = values.ever_investigated_details;
      answersKYC.ever_criminal_offense = values.ever_criminal_offense;
      answersKYC.ever_criminal_offense_details = values.ever_criminal_offense_details;
      answersKYC.ever_censured = values.ever_censured;
      answersKYC.ever_censured_details = values.ever_censured_details;

      answersKYC.has_held_position_political_office = values.has_held_position_political_office;
      answersKYC.has_held_position_government_official = values.has_held_position_government_official;
      answersKYC.has_held_position_political_advisor = values.has_held_position_political_advisor;
      answersKYC.has_held_position_contacts_with_government = values.has_held_position_contacts_with_government;

      answersKYC.held_position_political_office = values.held_position_political_office;
      answersKYC.held_position_government_official = values.held_position_government_official;
      answersKYC.held_position_political_advisor = values.held_position_political_advisor;
      answersKYC.held_position_contacts_with_government = values.held_position_contacts_with_government;
      setCurrentPage(currentPage + 1);
    }
  });

  // ===== Form-related =====
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      const file_descriptors = {};
      let file_i = 0;



      const form_data = new FormData();

      // Page 1
      const basic = answersBasic;

      form_data.append(`basic`, JSON.stringify(basic));

      // Page 2. Add relation to files
      const identification = answersIdentification;
      identification.ids = nationalities.map((nationality, index) => {
        if (nationality.is_primary) {
          form_data.append('primary_nationality', nationality.nationality_name)
        } else {
          form_data.append(`nationality_${index}`, nationality.nationality_name)
        }

        if (!nationality.supporting_documents_available && !nationality.is_primary) return null;

        const fileName = `file_${file_i}`

        form_data.append(fileName, nationality.upload_copy_of_id)

        file_descriptors[file_i] = {
          scope: 'id',
          document_id: index
        };

        file_i++

        return {
          country_of_issue: nationality.nationality_name,
          document_expiry_date: nationality.id_expiry_date,
          document_number: nationality.id_number,
          document_issue_date: nationality.id_issue_date,
          document_type: nationality.id_type,
          document_filename: fileName
        }
      }).filter(id_document => !!id_document)

      form_data.append(`identification`, JSON.stringify(identification));

      // Page 3
      const final_contact_info = answersContactInfo;

      for (const qualifier of physicalAddressQualifiers) {
        final_contact_info[`physical_address_${qualifier[0]}`] = qualifier[1];
      }

      for (const qualifier of serviceAddressQualifiers) {
        final_contact_info[`service_address_${qualifier[0]}`] = qualifier[1];
      }

      for (const qualifier of oldAddressQualifiers) {
        final_contact_info[`old_address_${qualifier[0]}`] = qualifier[1];
      }

      for (const qualifier of otherAddressQualifiers) {
        final_contact_info[`other_address_${qualifier[0]}`] = qualifier[1];
      }

      form_data.append(`contact_info`, JSON.stringify(final_contact_info));

      // Page 4
      const regulatory_status = answersPersonalStatus;

      form_data.append(`regulatory_status`, JSON.stringify(regulatory_status));

      // Page 5
      form_data.append(`kyc`, JSON.stringify(answersKYC));

      // Files


      for (const document_id in documents.pors) {
        for (const file of documents.pors[document_id]) {
          form_data.append(`file_${file_i}`, file);

          file_descriptors[file_i] = {
            scope: 'por',
            document_id
          };

          file_i++;
        }
      }

      form_data.append(`file_descriptors`, JSON.stringify(file_descriptors));

      // TODO @hack @DRY @cleanup
      let bo_id = window.location.search.split('bo_id=')[1];

      if (bo_id) {
        const amp_index = bo_id.indexOf('&');

        if (amp_index > -1) {
          bo_id = bo_id.substring(0, amp_index);
        }
      }

      Axios.post(`csp/hdd-data${bo_id ? `?bo_id=${bo_id}` : ''}`, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          // Navigate to success screen
          setCurrentPage(7);
        })
        .catch(({ response }) => {
          if (response) {
            setStatus(response.data.error_message);
          }

          disableLoading();
          setSubmitting(false);
        });
    }
  });

  const getCspInfo = () => {
    Axios.get('csp/current')
      .then(({ data }) => {
        setCspInfo(data.csp_info);
      })
      .catch(() => { });
  };

  const checkUserDDStatus = () => {
    // Check if this is HDD for some BO instead of a real user

    // TODO @hack
    let bo_id = window.location.search.split('bo_id=')[1];
    if (bo_id) {
      const amp_index = bo_id.indexOf('&');

      if (amp_index > -1) {
        bo_id = bo_id.substring(0, amp_index);
      }

      // TODO get bo's HDD completion answers here
      Axios.get(`csp/bo-hdd/${bo_id}`)
        .then(({ data }) => {
          const current_data = {};

          let ids = [];

          const new_physicalAddressQualifiers = [];
          const new_oldAddressQualifiers = [];
          const new_serviceAddressQualifiers = [];
          const new_otherAddressQualifiers = [];

          if (data.dd) {
            for (const answer of data.dd) {
              // TODO @refactor @cleanup

              if (answer.field_name.startsWith('physical_address_')) {
                const field_name = answer.field_name.substring(17);

                new_physicalAddressQualifiers.push([field_name, answer.field_value]);
              }

              if (answer.field_name.startsWith('service_address_')) {
                const field_name = answer.field_name.substring(16);

                new_serviceAddressQualifiers.push([field_name, answer.field_value]);
              }

              if (answer.field_name.startsWith('old_address_')) {
                const field_name = answer.field_name.substring(12);

                new_oldAddressQualifiers.push([field_name, answer.field_value]);
              }

              if (answer.field_name.startsWith('other_address_')) {
                const field_name = answer.field_name.substring(14);

                new_otherAddressQualifiers.push([field_name, answer.field_value]);
              }

              if (!current_data[STEP_MAPPING[answer.field_name]]) {
                current_data[STEP_MAPPING[answer.field_name]] = { [answer.field_name]: answer.field_value };
              } else {
                current_data[STEP_MAPPING[answer.field_name]][answer.field_name] = answer.field_value;
              }
            }
          }

          if (data.hdd_ids) {
            for (const id of data.hdd_ids) {
              // TODO @hack with dates

              ids.push({
                country_of_issue: id.document_country_of_issue,
                document_expiry_date: id.document_expiry_date && new Date(id.document_expiry_date).toJSON().split('T', 1)[0],
                document_issue_date: id.document_issue_date && new Date(id.document_issue_date).toJSON().split('T', 1)[0],
                document_number: id.document_number,
                document_type: id.document_type,
                document_id: id.document_id
              });
            }
          }

          setAnswersBasic(current_data.hdd_basic || {});
          setAnswersIdentification(current_data.hdd_identification || {});
          setAnswersContactInfo(current_data.hdd_contact_details || {});
          setAnswersKYC(current_data.hdd_kyc || {});

          setAnswersIdentification_ids(ids || [{}]);

          setPhysicalAddressQualifiers(new_physicalAddressQualifiers);
          setServiceAddressQualifiers(new_serviceAddressQualifiers);
          setOldAddressQualifiers(new_oldAddressQualifiers);
          setOtherAddressQualifiers(new_otherAddressQualifiers);
        })
        .catch(() => { });

      setCurrentPage(1);
    } else {
      Axios.get('user/user-status')
        .then(({ data }) => {
          if (data.completed_dds.includes('hdd')) {
            setPage0Text('You have already completed the Individual Due Diligence');
          } else {
            setCurrentPage(1);
          }
        })
        .catch(() => { });
    }
  };

  useEffect(() => {
    checkUserDDStatus();
    getCspInfo();
  }, []);

  // ===== Navigation =====
  const onPageSubmit = e => {
    e.preventDefault();
    if (currentPage === 1) {
      page1Formik.handleSubmit(e);
      setCurrentPage(currentPage + 1);
    }
    if (currentPage < 6) {
      // We have 6 pages in total
      setCurrentPage(currentPage + 1);
    } else {
      formik.handleSubmit(e);
    }
  };

  const goBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      history.push('/');
    }
  };

  // ===== Data modification =====
  const onValueChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (currentPage === 1) {
      setAnswersBasic({ ...answersBasic, [e.target.name]: value });
    } else if (currentPage === 2) {
      setAnswersIdentification({ ...answersIdentification, [e.target.name]: value });
    } else if (currentPage === 3) {
      setAnswersContactInfo({ ...answersContactInfo, [e.target.name]: value });
    } else if (currentPage === 4) {
      setAnswersPersonalStatus({ ...answersPersonalStatus, [e.target.name]: value });
    } else if (currentPage === 5) {
      setAnswersKYC({ ...answersKYC, [e.target.name]: value });
    }
  };

  const getFileInputRef = (scope, index) => {
    if (!fileInputRefs[scope][index]) {
      fileInputRefs[scope][index] = createRef();

      setFileInputRefs({ ...fileInputRefs });
    }

    return fileInputRefs[scope][index];
  };

  const addDocumentsClick = (scope, index) => {
    fileInputRefs[scope][index].current.click();
  };

  const setDocumentFiles = (e, scope, index) => {
    e.stopPropagation();
    e.preventDefault();

    const new_obj = documents;

    new_obj[scope][index] = [];

    for (const file of e.target.files) {
      new_obj[scope][index].push(file);
    }

    setDocuments({ ...new_obj });
  };

  let old_address_required = false;

  if (currentPage === 3) {
    // TODO @refactor @cleanup this is a mess
    let moved_to_physical_address_at_int = parseInt(answersContactInfo.moved_to_physical_address_at, 10) || -1;

    if (moved_to_physical_address_at_int !== -1) {
      const moved_date = new Date().setFullYear(moved_to_physical_address_at_int);

      const year_difference = (new Date() - moved_date) / 31556952000;

      if (year_difference >= 5) old_address_required = true;
    }
  }

  function getPreferredName() {
    let { first_name, last_name, preferred_name } = page1Formik.values;
    const concatenated = first_name || last_name ? first_name + ' ' + last_name : '';
    page1Formik.values.preferred_name = isNameTouched ? preferred_name : concatenated;
    return page1Formik.values.preferred_name;
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="create_csp_root"
        >
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <div className={`create-csp-form w-500px`}>
                <div className="text-center mb-10 mb-lg-10">
                  <h3 className="font-size-h1">Individual Due Diligence</h3>
                  {currentPage !== 0 && (
                    <p className="text-muted font-weight-semi-bold">
                      Step {currentPage}: {STEP_NAMES[currentPage]}
                    </p>
                  )}
                </div>

                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                )}

                {/* Page 0 */}
                {currentPage === 0 && <div className="form-group text-center">{page0Text}</div>}

                {/* Page 1 - basic info */}
                {currentPage === 1 && (
                  <HDDBasic
                    {...{
                      user,
                      getPreferredName,
                      setNameTouched,
                      getDate,
                      goBack,
                      loading,
                      selectOptions,
                      page1Formik,
                      answersBasic,
                      onValueChange,
                    }}
                  />
                )}

                {/* Page 2 - identification info */}
                {currentPage === 2 && (
                  <HDDCitizenship {
                    ...{
                      page2Formik,
                      selectOptions,
                      goBack,
                      isDocsFormValid,
                      validateIdentificationDocument,
                      loading,
                      availableNationalities: nationalities,
                      onReturnNationalities: (n) => {
                        setNationalities(n)
                      }
                    }
                  } />
                )}

                {/* Page 3 - contact details */}
                {currentPage === 3 && (
                  <HDDAddress {...{
                    page3Formik,
                    documents,
                    getFileInputRef,
                    setDocumentFiles,
                    addDocumentsClick,
                    onValueChange,
                    getDate,
                    physicalAddressQualifiers,
                    setPhysicalAddressQualifiers,
                    serviceAddressQualifiers,
                    answersContactInfo,
                    old_address_required,
                    oldAddressQualifiers,
                    setOldAddressQualifiers,
                    setServiceAddressQualifiers,
                    otherAddressQualifiers,
                    setOtherAddressQualifiers,
                    selectOptions,
                    goBack,
                    loading
                  }} />
                )}

                {/* Page 4 - professional and regulatory status */}
                {currentPage === 4 && (
                  <HDDProfessionalAndRegulatory {...{
                    page4Formik,
                    selectOptions,
                    goBack,
                    loading
                  }} />
                )}

                {/* Page 5 - KYC/AML questions */}
                {currentPage === 5 && (
                  <HDDAmlQuestions {...{
                    page5Formik,
                    getDate,
                    goBack,
                    loading
                  }} />
                )}

                {/* Page 6 - Declaration */}
                {currentPage === 6 && (
                  <HDDDeclaration {...{
                    onPageSubmit,
                    cspInfo,
                    formik,
                    goBack,
                    loading
                  }} />
                )}

                {/* Page 7  - Success Screen */}
                {currentPage === 7 && <SuccessScreen message="Individual Due Diligence process has been successfully completed" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
