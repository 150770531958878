import React from 'react';
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  MenuItem,
  Radio,
  Checkbox,
  RadioGroup,
  Select,
  TextField,
  InputLabel,
  FormHelperText,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import axios from "axios";
import {notify} from '../../redux/NotificationSnackbar/snackbarActions';
import CountrySelect from './Dropdown/CountrySelect';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const domesticCompanyTypes = {
  TBL:
    "Confirm entity is carrying on business in the Islands and licenced under Section 3(a) of the TBL",
  LCCL:
    "Confirm entity is carrying on business in the Islands and licenced under Section 4(1) of the LCCL",
  COL: "A company referred to in Section 80 of the Companies Law"
};

const relevantActivitiesList = [
  "Banking",
  "Distribution and Service Centre",
  "Financing or Leasing",
  "Headquarters",
  "Holding Company",
  "Fund Management",
  "Insurance",
  "Intellectual Property",
  "Shipping"
];
const CIMAlicencetypes = [
  "Banking Class A",
  "Banking Class B",
  "Banking Class B (Restricted)",
  "Class A External Insurer",
  "Class A Local Insurer",
  "Class B Insurer",
  "Class C Insurer",
  "Class D Insurer",
  "Company Manager",
  "Corporate Service Provider",
  "Director",
  "Insurance Agent",
  "Insurance Broker",
  "Insurance Manager",
  "Money Services",
  "Mutual Fund - Administered",
  "Mutual Fund - Licenced",
  "Mutual Fund - Master Fund",
  "Mutual Fund - Registered",
  "Mutual Fund Administrator - Full",
  "Mutual Fund Administrator - Restricted",
  "Nominee (Trust)",
  "Portfolio Insurance Company",
  "Securities - Excluded Persons",
  "Securities - Full",
  "Securities - Registered Person",
  "Securities - Restricted",
  "Trust",
  "Trust (Controlled Subsidiary)",
  "Trust (Registered PTC)",
  "Trust (Restricted)",
  "N/A"
];

const licenseTypes = ["CIMA", "General Registry", "TBL", "LCCL"];

function checkMultiSelection(selectionArray, checkArray) {
  if(!selectionArray) return false;

  return selectionArray.some(r => checkArray.includes(r));
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    color: "black"
  }
}));

function ESNForm(props) {
  const classes = useStyles();

  const [availableDirectors, setAvailableDirectors] = React.useState([]);

  const [firstRender, setFirstRender] = React.useState(true);

  // Get the current filling from the database
  async function getESNFilling(available_company_users) {
    setFirstRender(false);

    axios.get(`csp/certification/esn/${ props.company_id }`)
    .then((response) => {
      if(response && response.data && response.data.esn_data) {
        const new_data = {
          "1b": []
        };

        for(const field of response.data.esn_data) {
          // TODO @cleanup maybe have a field_is_json = true for fields like these, instead of hardcoding the fields that are json
          let value = field.field_value;

          if(field.field_name === "1b") {
            value = JSON.parse(field.field_value);
          }

          // TODO @cleanup @refactor @hack Pretty hacky solution
          // We need to do this because on the backend, we store the full name of a director in the database, but on the frontend,
          // 8a1 is a staff object. In order to get a staff object for the frontend, we just search all the available stakeholders and
          // find the one with the same name as in the 8a1 field on the backend

          // This is not VERY bad, but this WILL create problems in situations where a company has more than one person with the exact
          // same name. We should store the staff_id/user_id on the backend instead
          else if(field.field_name === "8a1") {
            value = available_company_users.find(director => {
              return (director.person_user && director.person_user.user_full_name === field.field_value)
            });

            setSelectedDirector(value);
          }

          new_data[field.field_name] = value;
        }

        setData({ ...new_data });
      }
    })
    .catch(() => {});
  }

  async function getAvailableCompanyUsers(get_esn_filling) {
    axios.get(`company/${ props.company_id }/stakeholders/`)
    .then((response) => {
      if(response && response.data) {
        const new_directors = [];

        for(const director of response.data.directors) {
          if(director.person_user) new_directors.push(director);
        }

        setAvailableDirectors(new_directors);

        getESNFilling(new_directors);
      }
    })
    .catch(() => {});
  }

  async function onSubmit() {
    if(!agreement) return;

    const form_data = new FormData();

    const data_json = JSON.stringify({
      ...data,
      "8a1": selectedDirector && selectedDirector.person_user.user_full_name,
      "8a2": selectedDirector && selectedDirector.person_user.emails[0],
      "7": selectedDate
    });

    form_data.append("tax_residency_evidence", selectedFile);
    form_data.append("form", data_json);

    axios.post(`csp/certification/esn/${ props.company_id }`, form_data, {
      params: {
        task_id: props.task_id
      }
    })
    .then((response) => {
      props.onHide(false);
      notify({type: 'SUCCESS', title: 'Success', message: 'Economic Substance Notice has been completed!'})
    })
    .catch(() => {});
  }

  const [data, setData] = React.useState({
    "1b": [],
    "1a": ""
  });

  function setFieldValue(field, value){
    setData({ ...data, [field]: value })
  }

  const updateData = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const [selectedDirector, setSelectedDirector] = React.useState();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date()
  );
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const [selectedFile, setSelectedFile] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const changeFileHandler = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsFilePicked(true);
  };

  const [agreement, setAgreement] = React.useState(false);
  const handleChangeAgreement = event => {
    setAgreement(event.target.checked);
  };

  // On mount
  React.useEffect(() => {
    if(props.show && firstRender) {
      getAvailableCompanyUsers(true);
    }
  }, [props.show]);

  return (
      <>
        <Dialog aria-labelledby="simple-dialog-title" open={props.show}>
          <div
              className="card card-custom"
              style={{
                width: "100%",
                paddingBottom: "20px",
              }}
          >

           {/* 1a - Relevant Activity (Y/N)*/}
            <FormControl className="m-4" component="fieldset">
              <FormLabel className={ classes.root } id="esn-1a">
                Was the Entity Carrying on a Relevant Activity during this past
                financial year?
              </FormLabel>
              <RadioGroup
                  labelId="esn-1a"
                  name="1a"
                  value={data["1a"]}
                  onChange={ updateData }
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* 7 - Date of Financial Year End*/}
            {data["1a"] === "true" ? (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div className="m-4">
                    <FormLabel className={ classes.root } id="esn-7">
                      Date of Financial Year End
                    </FormLabel>

                    <div>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD-MMM-YYYY"
                        margin="normal"
                        labelId="esn-7"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </div>

                    <FormHelperText>Please select the date of the Financial Year End for this Entity.</FormHelperText>
                  </div>
                </MuiPickersUtilsProvider>
            ) : null}

            {/*5a - Domestic Company*/}
            {data["3a"] === "domestic" ? (
                <FormControl className="m-4" component="fieldset">
                  <FormLabel className={ classes.root } id="esn-5a">
                    Domestic Company Type
                  </FormLabel>
                  <RadioGroup
                      labelId="esn-5a"
                      name="5a"
                      value={data["5a"]}
                      onChange={updateData}
                  >
                    <FormControlLabel
                        value="tbl"
                        control={<Radio />}
                        label={domesticCompanyTypes.TBL}
                    />
                    <FormControlLabel
                        value="lccl"
                        control={<Radio />}
                        label={domesticCompanyTypes.LCCL}
                    />
                    <FormControlLabel
                        value="col"
                        control={<Radio />}
                        label={domesticCompanyTypes.COL}
                    />
                  </RadioGroup>
                  <FormHelperText>Confirm entity is not part of MNE Group and select the Type of licence your entity operates under: Trade & Business = TBL, Local Companies Control Licence - LCCL, Companies limited by guarantee and associations not for profit = COL</FormHelperText>
                </FormControl>
            ) : null}

            {/*5b1 - Type of Licence*/}
            {data["5a"] === domesticCompanyTypes.TBL ||
            data["5a"] === domesticCompanyTypes.COL ? (
                <div className="m-4">
                  <InputLabel className={ classes.root } id="esn-5b1">
                    Type of License
                  </InputLabel>
                  <Select
                      labelId="esn-5b1"
                      value={data["5b1"]}
                      name="5b1"
                      onChange={updateData}
                      input={<Input />}
                  >
                    {licenseTypes.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                    ))}
                  </Select>
                  <div className="my-4">
                    <TextField
                        style={{ width: "40ch" }}
                        value={data["5b2"]}
                        name="5b2"
                        onChange={updateData}
                        label="Licence/Reference/Registration Number"
                        variant="outlined"
                        className="w-100"
                    />
                    <FormHelperText>Number Is describing the license type selected in 5b1</FormHelperText>
                  </div>
                </div>
            ) : null}

            {/*5c - Non-Profit (Y/N)*/}
            {data["5a"] === domesticCompanyTypes.COL ? (
                <FormControl className="m-4" component="fieldset">
                  <FormLabel className={ classes.root } id="esn-5c">
                    Is Entity an Association not for profit?
                  </FormLabel>
                  <RadioGroup
                      labelId="esn-5c"
                      name="5c"
                      value={data["5c"]}
                      onChange={updateData}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText>i.e. formed primarily for the promotion of charitable,
philanthropic, religious, cultural, educational, social or fraternal purposes</FormHelperText>
                </FormControl>
            ) : null}

            {/*1b - Relevant Activities*/}
            {data["1a"] === "true" ? (
                <div className="m-4">
                  <InputLabel className={ classes.root } id="esn-1b">
                    Relevant Activities
                  </InputLabel>
                  <Select
                    className="w-100"
                    labelId="esn-1b"
                    multiple
                    value={data["1b"]}
                    name="1b"
                    onChange={updateData}
                    input={<Input />}
                  >
                    {relevantActivitiesList.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Check all Relevant Activities that apply</FormHelperText>
                  <ExpansionPanel className="my-4">
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>More about relevant activities</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        {/* 1 */}
                        <h5 className="mt-5">Banking</h5>
                        <p>
                          The business of receiving (other than from a bank or trust company) and holding on current, savings, deposit or other similar account money which is repayable by cheque or order and may be invested by way of advances to customers or otherwise
                        </p>

                        {/* 2 */}
                          <h5 className="mt-5">Distribution and Service Centre</h5>
                        <p>
                          Means the business of either or both of the following:
                        </p>
                          <ol type="a" className="my-2">
                            <li>purchasing from an entity in the same Group:
                              <ol type="i">
                                <li>component parts or materials for goods; or</li>
                                <li>goods ready for sale, and reselling such component parts, materials or goods outside the Islands;</li>
                              </ol>
                            </li>
                            <li>providing services to an entity in the same Group in connection with the business outside the Islands,</li>
                          </ol>
                        <p>
                          but does not include any activity included in any other relevant activity except holding company business
                        </p>
                        <p>
                          For the avoidance of doubt, (b) above only falls within the definition in the specific circumstances where the relevant entity is operating as a service centre for entities in the same Group
                        </p>

                        {/* 3 */}
                        <h5 className="mt-5">Financing or Leasing</h5>
                        <p>
                          Means the business of providing credit facilities for any kind of consideration to another person but does not include financial leasing of land or an interest in land, banking business, fund management business or insurance business.
                        </p>

                        {/* 4 */}
                        <h5 className="mt-5">Fund Management</h5>
                        <p>
                          Means the business of managing securities as set out in paragraph 3 of Schedule 2 to the Securities Investment Business Law (2019 Revision) carried on by a relevant entity licensed or otherwise authorised to conduct business under that Law for an investment fund
                        </p>
                        <p>
                          [“Managing Securities” means managing securities belonging to another person in circumstances involving the exercise of discretion.]
                        </p>

                        {/* 5 */}
                        <h5 className="mt-5">Headquarters</h5>
                        <p>
                          Means the business of providing any of the following services to an entity in the same Group:
                        </p>
                        <ol type="a" className="my-2">
                          <li>the provision of senior management;</li>
                          <li>the assumption or control of material risk for activities carried out by any of those entities in the same Group; or</li>
                          <li>the provision of substantive advice in connection with the assumption or control of risk referred to in paragraph (b),</li>
                        </ol>
                        <p>
                          but does not include banking business, financing and leasing business, fund management business, intellectual property business, holding company business or insurance business
                        </p>

                        {/* 6 */}
                        <h5 className="mt-5">Holding Company</h5>
                        <p>
                          The business of a pure equity holding company
                        </p>
                        <p>
                          [“pure equity holding company” means a company that only holds equity participations in other entities and only earns dividends and capital gains]
                        </p>
                        <p>
                          For the avoidance doubt, an investment fund is not regarded as a pure equity holding company.
                        </p>

                        {/* 7 */}
                        <h5 className="mt-5">Insurance</h5>
                        <p>
                          Has the meaning given to that expression by section 2 of the Insurance Law, 2010
                        </p>
                        <p>
                          [where “insurance business” means the business of accepting risks by effecting or carrying out contracts of insurance, whether directly or indirectly, and includes running-off business including the settlement of claims]
                        </p>

                        {/* 8 */}
                        <h5 className="mt-5">Intellectual Property</h5>
                        <p>
                          Means the business of holding, exploiting or receiving income from intellectual property assets;
                        </p>
                        <p>
                          [“intellectual property asset” means an intellectual property right including a copyright, design right, patent and trademark.]
                        </p>

                        {/* 9 */}
                        <h5 className="mt-5">Shipping</h5>
                        <p>
                          Means any of the following activities involving the operation of a ship anywhere in the world other than in the territorial waters of the Islands or between the Islands:
                        </p>
                        <ol type="a" className="my-2">
                          <li>the business of transporting, by sea, passengers or animals, goods or mail for a charge;</li>
                          <li>the renting or chartering of ships for the purpose described in paragraph (a);</li>
                          <li>the sale of travel tickets and ancillary ticket related services connected with the operation of a ship;</li>
                          <li>the use, maintenance or rental of containers, including trailers and other vehicles or equipment for the transport of containers, used for the transport of anything by sea; or</li>
                          <li>the functioning as a private seafarer recruitment and placement service,</li>
                        </ol>

                        <p>
                          but does not include a holding company business or the owning, operating or chartering of a pleasure yacht.
                        </p>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
            ) : null}

            {/*2b1 - CIMA Entitlement Type*/}
            {checkMultiSelection(data["1b"], [
              "Banking",
              "Fund Management",
              "Insurance"
            ]) ? (
                <div className="m-4">
                  <div className="my-4">
                    <InputLabel className={ classes.root } id="esn-2b1">
                      CIMA Licence Type
                    </InputLabel>
                    <Select
                        className="w-100"
                        labelId="esn-2b1"
                        value={data["2b1"]}
                        name="2b1"
                        onChange={updateData}
                        input={<Input />}
                    >
                      {CIMAlicencetypes.map(name => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Please select the licence type granted to this Entity by CIMA or N/A for Non-Applicable</FormHelperText>
                  </div>
                  <div className="my-4">
                    <FormLabel className={ classes.root }>
                      Please, fill out the provided fields
                    </FormLabel>

                    <div className="my-2">
                      <TextField
                        value={data["2b2"]}
                        name="2b2"
                        onChange={updateData}
                        label="CIMA Reference Number"
                        variant="outlined"
                        className="w-100"
                      />
                      <FormHelperText>Enter NO DATA if Entity has not yet been issued a CIMA Reference Number</FormHelperText>
                    </div>
                    <div className="my-4">
                      <TextField
                        value={data["2c"]}
                        name="2c"
                        onChange={updateData}
                        label="FI Number"
                        variant="outlined"
                        className="w-100"
                      />
                      <FormHelperText>Enter NO DATA if Entity has not yet been issued a FI Number</FormHelperText>
                    </div>
                    <div className="my-4">
                      <TextField
                        value={data["2d"]}
                        name="2d"
                        onChange={updateData}
                        label="IRS GIIN"
                        variant="outlined"
                        className="w-100"
                      />
                      <FormHelperText>GIIN means the Global Intermediary Identification Number issued to a financial institution upon registration on the IRS's Foreign Account Tax Compliance Act ("FATCA") registration portal.   Enter NO DATA if Entity has not been issued a CIMA # yet.</FormHelperText>
                    </div>
                  </div>
                </div>
            ) : null}

            {/*2a - Investment Fund (Y/N)*/}
            {data["1a"] === "false" ? (
                <FormControl className=" m-4" component="fieldset">
                  <FormLabel className={ classes.root } id="esn-2a">
                    Is the Entity an Investment Fund?
                  </FormLabel>
                  <RadioGroup
                      labelId="esn-2a"
                      name="2a"
                      value={data["2a"]}
                      onChange={updateData}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <ExpansionPanel className="my-4">
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>More about investment funds</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        <Typography>
                          The ES Law defines an “investment fund” as an entity whose principal business is the issuing of investment interests to raise funds or pool investor funds with the aim of enabling a holder of such an investment interest to benefit from the profits or gains from the entity's acquisition, holding, management or disposal of investments and includes any entity through which an investment fund directly or indirectly invests or operates (but not an entity that is itself the ultimate investment held), but does not include a person licensed under the Banks and Trust Companies Law (2020 Revision) or the Insurance Law, 2010 [Law 32 of 2010], or a person registered under the Building Societies Law (2020 Revision) or the Friendly Societies Law (ϭϵϵϴ Revision). “Investment fund business” is defined as the business of operating as an investment fund. “Investment interests” means a share, trust unit, partnership interest or other right that carries an entitlement to participate in the profits or gains of the entity. For the avoidance of doubt, an entity which is carrying on “fund management business” for the purposes of the ES Law cannot be classified as an “investment fund” for the purpose of the ES Law.
                        </Typography>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </FormControl>
            ) : null}

            {/*2b2 - CIMA Reference Number
            2c - FI Number
            2d - IRS GIIN
            2e - Relate Investment (Y/N)
            3a - Type of Exemption*/}
            {data["2a"] === "true" ? (
              <>
                {/* 2b2, 2c, 2d */}
                <div className="m-4">
                  <FormLabel className={ classes.root }>
                    Please, fill out the provided fields
                  </FormLabel>

                  <div className="my-4">
                    <TextField
                      value={data["2b2"]}
                      name="2b2"
                      onChange={updateData}
                      label="CIMA Reference Number"
                      variant="outlined"
                      className="w-100"
                    />
                    <FormHelperText>Enter NO DATA if Entity has not yet been issued a CIMA Reference Number</FormHelperText>
                  </div>
                  <div className="my-4">
                    <TextField
                      value={data["2c"]}
                      name="2c"
                      onChange={updateData}
                      label="FI Number"
                      variant="outlined"
                      className="w-100"
                    />
                    <FormHelperText>Enter NO DATA if Entity has not yet been issued a FI Number</FormHelperText>
                  </div>
                  <div className="my-4">
                    <TextField
                      value={data["2d"]}
                      name="2d"
                      onChange={updateData}
                      label="IRS GIIN"
                      variant="outlined"
                      className="w-100"
                    />
                    <FormHelperText>GIIN means the Global Intermediary Identification Number issued to a financial institution upon registration on the IRS's Foreign Account Tax Compliance Act ("FATCA") registration portal.   Enter NO DATA if Entity has not been issued a CIMA # yet.</FormHelperText>
                  </div>
                </div>

                {/* 2e */}
                <FormControl className="m-4" component="fieldset">
                  <FormLabel className={ classes.root } id="esn-2e">
                    Relate Investment
                  </FormLabel>
                  <RadioGroup
                      labelId="esn-2e"
                      name="2e"
                      value={data["2e"]}
                      onChange={updateData}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText>i.e. Do the CIMA, FI, or GIIN numbers provided above relate to another investment fund that directly or indirectly invests or operates through this Entity?</FormHelperText>
                </FormControl>

                {/* 3a */}
                <div className="m-4">
                  <InputLabel className={ classes.root } id="esn-3a">
                    Do Exemptions apply to this Entity?
                  </InputLabel>
                  <Select
                      className="w-100"
                      labelId="esn-3a"
                      value={data["3a"]}
                      name="3a"
                      onChange={updateData}
                      input={<Input />}
                  >
                    <MenuItem value="outside">
                      Tax resident outside the Cayman Islands
                    </MenuItem>
                    <MenuItem value="domestic">
                      Domestic Company
                    </MenuItem>
                    <MenuItem value="na">
                      N/A
                    </MenuItem>
                  </Select>
                  <FormHelperText>Domestic Company means a Company carrying on business ONLY in the Cayman Islands and generally licensed with a Trade and Business license (TBL), a Local Companies Control License (LCCL), Companies limited by guarantee (COL), or as Associations not for profit. <a href="https://docs.google.com/document/d/1A9JRrdm4ZXSdz-lDc78b7rfRXyTe5WCxMJY_ccPNaoM/edit#heading=h.1fob9te">Read More…</a></FormHelperText>
                </div>
              </>
            ) : null}

            {/*8 - Contact Type*/}
            {data["1a"] === "true" ? (
                <div className="m-4">
                  <div className={ classes.root } id="esn-8">
                    Your entity will be required to file a more detailed ES Report at
                    the end of the year. Please select who will be responsible for
                    completing this ES Report
                  </div>
                  <Select
                      className="w-100"
                      labelId="esn-8"
                      value={data["8"]}
                      name="8"
                      onChange={updateData}
                      input={<Input />}
                  >
                    {[
                      "director",
                      "registered_office"
                    ].map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                    ))}
                  </Select>
                </div>
            ) : null}

           {/* 8a1 - Contact Name(Select Name of Entity Director)
            8a2 - Email address of Director*/}
            {data["8"] === "director" ? (
                <div className="m-4">
                  <InputLabel className={ classes.root } id="esn-8a1">
                    Select Name of Entity Director
                  </InputLabel>
                  <Select
                      className="w-100"
                      labelId="esn-8a1"
                      id="demo-contact"
                      name="8a1"
                      value={data["8a1"]}
                      onChange={e => setSelectedDirector(e.target.value)}
                      input={<Input />}
                  >
                    { availableDirectors.length ?
                      availableDirectors.map(director => (
                        <MenuItem key={director} value={director}>
                          {director.person_user.user_full_name}
                        </MenuItem>
                      )) : (
                        <MenuItem value="">
                          <em>No available directors</em>
                        </MenuItem>
                      )
                    }
                  </Select>
                  {/* <p style={{ fontWeight: "700", color: "rgba(0, 0, 0, 0.54)", fontSize:"1rem"}}>
                    Email address of Director : {data["8a1"] ? data["8a1"].emails[0] : "N/A"}
                  </p> */}
                </div>
            ) : null}

           {/* 4b - Where is the Entity Tax resident*/}
            {data["3a"] === "outside" ? (
                <div className="m-4">
                  <InputLabel className={ classes.root } id="esn-4b">
                    Where is the Entity Tax resident
                  </InputLabel>

                  <CountrySelect
                    name="4b"
                    label="Select a country"
                    className="w-100 mt-4"
                    options={props.jurisdictions}
                    value={data["4b"]}
                    onChange={value => setFieldValue('4b', value)}
                  />
                </div>
            ) : null}

           {/* 4c - Upload Evidence of tax residency*/}
            {data["4b"] !== "" ? (
                <div className="m-4">
                  <InputLabel className={ classes.root }>
                    Upload Evidence of tax residency
                  </InputLabel>

                  { selectedFile && (
                    <div className="ml-2">{selectedFile.name}</div>
                  )}

                  <label htmlFor="upload-evidence">
                    <Input id="upload-evidence" multiple type="file" onChange={changeFileHandler} hidden/>
                    <Button variant="contained" component="span">
                      Upload Evidence of tax residency
                      <CloudUploadIcon className="ml-2" />
                    </Button>
                  </label>

                </div>
            ) : null}

            {/*9 - Certify and Submit*/}
            <FormControl
                style={{ marginLeft: "auto", marginRight: "auto" }}
                className="m-4"
                component="fieldset"
            >
              <p>
                -I certify that the information provided above is, to the best of my
                knowledge, true, accurate and complete and that I have the capacity
                and authority on behalf of the Entity to make the representations and
                warranties set forth herein.
              </p>
              <p>
                -I declare that, by submitting this information, the Cayman entity
                acknowledges that there are sanctions as outlined in Section 13(2) of
                the ES Law 2018 for submitting inaccurate information to the TIA and
                hereby indemnify the recipients of this information (i.e. my Corporate
                Service Provider / Registered Office) against any losses or damages
                that may result from receipt of, submission of, or acting upon any
                information provided in this form.
              </p>
              <p>
                -I acknowledge that my Corporate Service Provider / Registered Office
                is legally obligated to report such information in accordance with
                applicable law and hereby consent to such information being provided
                to the required regulatory bodies, including the Tax Information
                Authority (TIA).
              </p>
              <p>
                -I consent to the processing and handling of this information for the
                purposes of Economic Substance related matters and filings in
                accordance with the relevant Data Protection Laws and Privacy Policies
                stated herein.
              </p>
              <p>
                -I consent to being contacted in relation to the information in this
                filing or in instances where I have requested assistance /
                professional guidance in relation to this filing.
              </p>

              <FormControlLabel
                  checked={agreement}
                  onChange={handleChangeAgreement}
                  control={<Checkbox />}
                  label="I agree to all of the above"
              />
            </FormControl>
            <div className="d-flex align-items-center justify-content-between mx-5">
              <Button style={{ width: "150px" }} variant="outlined" color="secondary" onClick={() => props.onHide(false)}>
                Close
              </Button>
              <Button style={{ width: "150px" }} variant="outlined" color="primary"  onClick={onSubmit} disabled={!agreement}>
                Submit
              </Button>
            </div>
          </div>
        </Dialog>
      </>
  )
}

export default ESNForm;
