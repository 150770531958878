import axios from 'axios'
import { b64toBlob } from '../helpers/api'

export async function getOwnershipTypesFields() {
  const response = await axios.get('/company/ownership-types-fields')
  if (response?.data) {
    return response.data.types_fields
  }
}

export async function getShareholdersFields() {
  const response = await axios.get('/company/shareholders-fields')
  if (response?.data) {
    return response.data.shareholders_fields
  }
}

export async function getEntityBasicInfo(entity_id) {
  const response = await axios.get('/company/basic-profile-info', {
    params: {
      entity_id
    }
  })
  if (response?.data) {
    return response.data
  }
}

export async function saveEntityProfile(entity_id, basic_info, overview, regulatory_overview, licenses) {
  const avatarData = new FormData()
  avatarData.append('company_avatar', await b64toBlob(basic_info.company_avatar))
  avatarData.append('entity_id', entity_id)

  await Promise.all([
    axios.post('/company/profile', {
      entity_id,
      basic_info: { ...basic_info, company_avatar: undefined },
      overview,
      regulatory_overview,
      licenses
    }),
    axios.post('/company/profile_avatar', avatarData)
  ])
}

export async function getEntityContacts(entity_id) {
  const response = await axios.get('/company/entity-contacts', {
    params: {
      entity_id
    }
  })
  if (response?.data) {
    return response.data
  }
}

export async function getEntityAvatar(entity_id) {
  const response = await axios.get('/company/entity-avatar', {
    params: {
      entity_id
    }
  })
  if (response?.data) {
    return response.data.avatar
  }
}

export async function getEntityDDData(entity_id) {
  const response = await axios.get(`/company/company-edd/${entity_id}`)
  if (response?.data) {
    return response.data.dd;
  }
}

export async function saveRRCriteria(entity_id, data) {
  await axios.post('/company/risk-rating-criteria', { data }, {
    params: {
      entity_id
    }
  })
}

export async function getRRCriteria(entity_id, csp_id) {
  const response = await axios.get('/company/risk-rating-criteria', {
    params: {
      entity_id,
      csp_id
    }
  })
  if (response?.data) {
    return response.data.criteria
  }
}

export async function getEntityStakeholders(entity_id) {
  const response = await axios.get('/company/rr-entity-stakeholders', {
    params: {
      entity_id
    }
  })

  if (response?.data) {
    return response.data.stakeholders
  }
}

export async function saveRRStakeholderDecision(entity_id, data) {
  await axios.post('/company/rr-entity-stakeholders-decision', data, {
    params: {
      entity_id
    }
  })
}

export async function getRRStakeholderDecision(entity_id) {
  const response = await axios.get('/company/rr-entity-stakeholders-decision', {
    params: {
      entity_id
    }
  })

  if (response?.data) {
    return response.data.decision
  }
}

export async function saveRRNote(entity_id, data) {
  await axios.post('/company/rr-note', data, {
    params: {
      entity_id
    }
  })
}

export async function getRRNotes(entity_id) {
  const response = await axios.get('/company/rr-note', {
    params: {
      entity_id
    }
  })

  if (response?.data) {
    return response.data.notes
  }
}

export async function saveRR(entity_id, data) {
  await axios.post('/company/risk-rating', data, {
    params: {
      entity_id
    }
  })
}

export async function getRR(entity_id) {
  const response = await axios.get('/company/risk-rating', {
    params: {
      entity_id
    }
  })

  if (response?.data) {
    return response.data.summary
  }
}

export async function getShareholders(entity_id) {
  const response = await axios.get('/company/entity-shareholders', {
    params: {
      entity_id
    }
  })

  if (response?.data) {
    return response.data.owners
  }
}

export async function getEntityBannerInfo(entity_id, csp_id) {
  const response = await axios.get('/company/entity-banner', {
    params: {
      entity_id,
      csp_id
    }
  })

  if (response?.data) {
    return response.data.banner
  }
}

export async function getInitialSubscriber(entity_id) {
  const response = await axios.get('/company/initial-subscriber', {
    params: {
      entity_id,
    }
  })

  if (response?.data) {
    return response.data.initial_subscriber
  }
}

export async function saveInitialSubscriber(entity_id, initial_subscriber) {
  await axios.post('/company/initial-subscriber', {
    initial_subscriber
  }, {
    params: {
      entity_id
    }
  })
}

export async function saveEntityAddress(entity_id, data) {
  const formData = new FormData()

  const quantifiers = function (quantifiers) {
    const result = {}

    quantifiers && quantifiers.forEach(({ qualifier_name = '', qualifier_value = '' }) => {
      result[qualifier_name] = qualifier_value
    })

    return result
  }(data.address_quantifiers)

  formData.append('address_body', JSON.stringify({
    id: data.id,
    entity_id: data.entity_id,
    is_primary: data.is_primary,
    address_location: data.address_location,
    address_google_key: data.address_google_key,
    address_quantifiers: quantifiers,
  }))

  if (data.document) {
    formData.append('uploaded_document', data.document)
  }

  return axios.post(`/company/${entity_id}/addresses`, formData)
}

export async function getEntityAddresses(entity_id) {
  function formatQualifiers(array) {
    array.forEach(object => {
      if (!object.address_quantifiers) return;

      object.address_quantifiers = Object.keys(object.address_quantifiers).map(key => ({
        qualifier_name: key,
        qualifier_value: object.address_quantifiers[key]
      }))
    })
  }
  const response = await axios.get(`/company/${entity_id}/addresses`)
  if (response && response.data) {
    const data = response.data
    formatQualifiers(data.addresses)
    return data.addresses
  }
}

export async function deleteEntityAddress(id, entity_id) {
  await axios.delete(`/company/${entity_id}/addresses`, {
    params: {
      id
    }
  })
}