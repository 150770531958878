import React from 'react'
import { AddressForm } from '../../../components/AddressForm/AddressForm';
import { CustomDatePicker, FormButton, FormSwitch, MultipleSelectChip } from '../../../components/Basic';

export default function HDDAddress({
  page3Formik,
  documents,
  getFileInputRef,
  setDocumentFiles,
  addDocumentsClick,
  onValueChange,
  getDate,
  physicalAddressQualifiers,
  setPhysicalAddressQualifiers,
  serviceAddressQualifiers,
  answersContactInfo,
  old_address_required,
  oldAddressQualifiers,
  setOldAddressQualifiers,
  setServiceAddressQualifiers,
  otherAddressQualifiers,
  setOtherAddressQualifiers,
  selectOptions,
  goBack,
  loading
}) {

  return (
    <form onSubmit={page3Formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="form-group fv-plugins-icon-container">
        <div className="font-size-h6 font-weight-bold mb-4 ml-2">Contact details</div>
      </div>
      <div className="form-group fv-plugins-icon-container">
        <div className="font-size-h6 font-weight-bold mb-4 ml-2">Physical address</div>

        <AddressForm
          qualifiers={physicalAddressQualifiers}
          setQualifiers={setPhysicalAddressQualifiers}
        />

        <div className="mt-3">
          {documents.pors[0].length ? <div>{documents.pors[0].length} document(s) uploaded</div> : null}

          {documents.pors[0].map((document, index) => (
            <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer" key={index}>
              Document {document.name} ({document.size})
            </div>
          ))}

          <input
            type="file"
            multiple
            ref={getFileInputRef('pors', 0)}
            onChange={e => setDocumentFiles(e, 'pors', 0)}
            hidden
          />
          <FormButton
            color="secondary"
            onClick={() => addDocumentsClick('pors', 0)}
            label="Upload document photos/scans"
          />
        </div>

        <div className="mt-4 mb-2">When did you move to this address?</div>
        <CustomDatePicker
          format="yyyy"
          name="moved_to_physical_address_at"
          label="Year when you moved"
          views={['year']}
          minDate={getDate(-74, 'y')}
          maxDate={getDate()}
          onBlur={page3Formik.handleBlur}
          onChange={value => {
            page3Formik.setFieldValue('moved_to_physical_address_at', value);
          }}
          value={page3Formik.values.moved_to_physical_address_at}
          error={
            page3Formik.errors.moved_to_physical_address_at
            && page3Formik.touched.moved_to_physical_address_at
          }
          helperText={
            page3Formik.touched.moved_to_physical_address_at
              ? page3Formik.errors.moved_to_physical_address_at
              : null
          }
        />
        <FormSwitch
          label="My Mailing Address the same as my Residential address"
          name="mailing_address_same_as_residential"
          className="m-1"
          onChange={onValueChange}
          value={answersContactInfo.mailing_address_same_as_residential}
        />
      </div>

      {old_address_required && (
        <div className="form-group fv-plugins-icon-container">
          <div className="font-size-h6 font-weight-bold mb-4 ml-2">Old address</div>

          <AddressForm
            qualifiers={oldAddressQualifiers}
            setQualifiers={setOldAddressQualifiers}
          />
        </div>
      )}

      {!answersContactInfo.mailing_address_same_as_residential && (
        <div className="form-group fv-plugins-icon-container">
          <div className="font-size-h6 font-weight-bold mb-4 ml-2">Service address</div>

          <AddressForm
            qualifiers={serviceAddressQualifiers}
            setQualifiers={setServiceAddressQualifiers}
          />
        </div>
      )}

      <FormSwitch
        label="Do you have any other residential address?"
        name="more_addresses"
        className="m-1"
        onChange={onValueChange}
        value={answersContactInfo.more_addresses}
      />

      {answersContactInfo.more_addresses && (
        <div className="form-group fv-plugins-icon-container">
          <div className="font-size-h6 font-weight-bold mb-4 mt-6 ml-2">Other residential address</div>

          <AddressForm
            qualifiers={otherAddressQualifiers}
            setQualifiers={setOtherAddressQualifiers}
          />
        </div>
      )}

      <FormSwitch
        label="I also operate in other country(ies) other than any of the addresses listed"
        name="also_operating_in_other_countries"
        className="m-1"
        value={page3Formik.values.also_operating_in_other_countries}
        onChange={page3Formik.handleChange}
      />

      {page3Formik.values.also_operating_in_other_countries && (
        <>
          <MultipleSelectChip
            name="also_operating_in_other_countries_country"
            value={page3Formik.values.also_operating_in_other_countries_country}
            onChange={(e, value) => page3Formik.setFieldValue('also_operating_in_other_countries_country', value)}
            options={selectOptions.country}
            label="Country"
            error={
              page3Formik.errors.also_operating_in_other_countries_country &&
              page3Formik.touched.also_operating_in_other_countries_country
            }
            helperText={
              page3Formik.touched.also_operating_in_other_countries_country &&
                page3Formik.errors.also_operating_in_other_countries_country
                ? page3Formik.errors.also_operating_in_other_countries_country
                : null
            }
          />
        </>
      )}
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Back
        </button>

        <FormButton label="Next" type="submit" color="secondary" id="kt_create_csp_submit">
          {loading && <span className="ml-3 spinner spinner-white" />}
        </FormButton>
      </div>
    </form>
  )
}