import React from 'react'
import BaseFieldsConfiguration from '../../components/EditProfileForm/BaseFieldsConfiguration'
import useCountries from '../../hooks/useCountries'

export default function EDDQuestionsSubPage({ managementDecisions, ddQuestions, setDDQuestions, errors, disabled }) {

  const [countries] = useCountries()

  const ddQuestionsOptions = [
    {
      name: 'is_government_organization',
      title: 'Is this Entity a central or local government organization, statutory body or agency of government',
      type: 'yes-no',
    },
    {
      visible: (ctx) => ctx['is_government_organization'] === true,
      name: 'specify_which_country',
      title: 'Specify which country.',
      type: 'country',
      options: countries
    },
    {
      visible: (ctx) => ctx['is_government_organization'] === true,
      name: 'please_upload_copy_of_statuatory_evidence',
      title: 'Please Upload copy of "statuatory evidence"',
      type: 'file'
    },
    {
      name: 'former_name_s_trading_name_s_or_any_other_name_used_where_applicable',
      title: 'Former name(s), trading name(s) / or any other name used where applicable',
      type: 'text'
    }
  ]

  const activitiesOptions = [
    {
      name: 'management_decisions_occur_in',
      title: 'Management decisions occur in:',
      type: 'country',
      options: countries
    },
    {
      name: 'business_activities_conducted_this_financial_year_check_all_that_apply',
      title: 'Business activities conducted this Financial Year (check all that apply)',
      type: 'chips',
      options: managementDecisions
    },
    {
      name: 'description_of_business_activities',
      title: 'Description of Business Activities',
      type: 'multiline-text'
    }
  ]

  return (
    <div className="row">
      <div className="col-lg-12 gutter-t gutter-b">
        <div className="card card-custom">
          <div className="card-header">
            <div className="card-title">
              EDD Questions
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7">
                {
                  ddQuestionsOptions.map(option => (
                    (<BaseFieldsConfiguration
                      disabled={disabled}
                      errors={errors}
                      key={option.name}
                      option={option}
                      value={ddQuestions}
                      editTextFieldValue={(value, name) => {
                        setDDQuestions(old => {
                          old[name] = value
                          return { ...old }
                        })
                      }} />)
                  ))
                }

                <h3 className='m-20'>Activities</h3>

                {
                  activitiesOptions.map(option => (
                    (<BaseFieldsConfiguration
                      disabled={disabled}
                      errors={errors}
                      key={option.name}
                      option={option}
                      value={ddQuestions}
                      editTextFieldValue={(value, name) => {
                        setDDQuestions(old => {
                          old[name] = value
                          return { ...old }
                        })
                      }} />)
                  ))
                }
              </div>
              <div className="col-lg-3"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}