import React, { useState, useEffect } from "react";
import BaseFieldsConfiguration from '../../../components/EditProfileForm/BaseFieldsConfiguration'
import useTitles from "../../../hooks/useTitles";
import { getCompanyStatusTitles, getCompanyTypesRelations, getCompanyTypesTitles, getEntityCategories } from "../../../api/ui";
import useTitlesWithRelations from "../../../hooks/useTitlesWithRelations";
import { getEntityContacts } from "../../../api/company";
import * as yup from 'yup'
import useColoredTitles from "../../../hooks/useColoredTitles";

export const entityCreationSchema = {
  company_name: () => yup.string().required('Cannot be empty'),
  company_jurisdiction_id: () => yup.number().required('Cannot be empty'),
  company_category: () => yup.string().required('Cannot be empty'),
  company_type: () => yup.string().required('Cannot be empty'),
  company_registration_number: () => yup.string().required('Cannot be empty'),
  company_formation_date: (context) => ({
    validate() {
      if (Date.now() <= new Date(context.company_formation_date)) {
        throw new Error('Cannot be in future')
      }
    }
  }),
  duration: _ => _.company_type.toLowerCase().includes("duration") &&
   yup.number().required('Cannot be empty').positive('Have to be positive'),
  company_financial_year_end: () => yup.date().required('Cannot be empty')
}

export default function EntityCreationSection({ creationFlowObject, setCreationFlowObject, entity_id, entityJurisdictions, saveButtonProps, creationFlowErrors }) {
  const [entityCategories] = useTitles(getEntityCategories)
  const [entityTypes] = useTitlesWithRelations(creationFlowObject.company_category, getCompanyTypesRelations, getCompanyTypesTitles)
  const [entityStatuses] = useColoredTitles(getCompanyStatusTitles)
  const [entityContacts, setEntityContacts] = useState([])

  useEffect(() => {
    getEntityContacts(entity_id).then(data => setEntityContacts(data.map(({ user_id, user_full_name }) => ({
      value: user_id,
      title: user_full_name
    }))))

  }, [])

  function editCreationFlow(value, fieldName) {
    setCreationFlowObject(old => {
      old[fieldName] = value
      return { ...old }
    })
  }

  const creationFlowOptions = [
    {
      name: 'company_avatar',
      title: 'Avatar',
      type: 'avatar',
    },
    {
      name: 'company_name',
      title: 'Entity Name',
      type: 'text'
    },
    {
      name: 'company_jurisdiction_id',
      title: 'Jurisdiction',
      type: 'select',
      options: entityJurisdictions,
      disabled: true
    },
    {
      name: 'company_category',
      title: 'Entity Category',
      type: 'select',
      options: entityCategories,
      disabled: true
    },
    {
      name: 'company_type',
      title: 'Entity Type',
      type: 'select',
      options: entityTypes
    },
    {
      name: 'company_registration_number',
      title: 'Registration number',
      type: 'text'
    },
    {
      name: 'company_formation_date',
      title: 'Formation Date',
      type: 'date',
    },
    {
      name: 'duration',
      title: 'Duration',
      type: 'number',
      visible: (context) => {
        return context.company_type.toLowerCase().includes("duration")
      }
    },
    {
      name: 'company_status',
      title: 'Entity Status',
      type: 'color-select',
      options: entityStatuses
    },
    {
      name: 'company_financial_year_end',
      title: 'Financial Year End',
      type: 'date',
      dateFormat: "DD-MMM"
    },
    {
      name: 'company_primary_contact',
      title: 'Primary contact',
      type: 'select',
      options: entityContacts
    }
  ]

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          Basic Entity Info
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-7">
            {
              creationFlowOptions.map(option =>
              (<BaseFieldsConfiguration
                errors={creationFlowErrors}
                key={option.name}
                option={option}
                value={creationFlowObject}
                editTextFieldValue={editCreationFlow} />))
            }
          </div>
          <div className="col-lg-3"></div>
        </div>

      </div>
    </div>
  )
}