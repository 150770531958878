import React, { useEffect, useState } from 'react'
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions'
import { getEPFS, getManagementDecisions, getPercents } from '../../api/ui'
import useRequest from '../../components/useRequest/useRequest'
import SaveButton from '../../components/_common/SaveButton'
import { simpleValidate } from '../../helpers/validation'
import useCountries from '../../hooks/useCountries'
import useTitles from '../../hooks/useTitles'
import fetchEDD from './api/fetchEDD'
import SubmitEDD from './api/submit'
import { EDDSchema } from './api/validation-schema'
import EDDFinancialSubPage from './financial-sub-page'
import EDDQuestionsSubPage from './questions-sub-page'
import _ from 'lodash'
import { Prompt, useHistory } from 'react-router-dom'
import LeaveBlocker from '../../components/_common/LeaveBlocker'

export default function EDDContent({ entity_id, csp_id, className }) {
  const [countries] = useCountries()
  const [managementDecisions] = useTitles(getManagementDecisions)
  const [epfs] = useTitles(getEPFS)
  const [isWritable, setIsWritable] = useState(false)

  const [submit, loading] = useRequest(() => SubmitEDD(entity_id, questions, ids))
  const [percents] = useTitles(getPercents)

  const [errors, setErrors] = useState({})

  const [questions, setQuestions] = useState({
    business_activities_conducted_this_financial_year_check_all_that_apply: [],
    revenue_expected_this_year_list: [],
    expenses_incurred_this_year_list: [],
    conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list: [],
    is_government_organization: '',
    specify_which_country: '',
    please_upload_copy_of_statuatory_evidence: '',
    former_name_s_trading_name_s_or_any_other_name_used_where_applicable: '',
    management_decisions_occur_in: '',
    description_of_business_activities: '',
    initial_source_of_funds: '',
    jurisdiction_originating: '',
    revenue_expected_this_year: '',
    expenses_incurred_this_year: '',
    conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies: '',
    entity_produces_financial_statements: '',
    financials_audited: '',
    accounting_service_provider: '',
    contact_details_accounting_service_provider: '',
    legal_service_provider: '',
    contact_details_legal_service_provider: '',
  })

  const [unsavedExists, setUnsavedExists] = useState(false)

  useEffect(() => {
    if (ended) {
      setUnsavedExists(true)
    }
  }, [questions])

  const [ids, setIds] = useState({})

  const [fetchData, __, ended] = useRequest(() => fetchEDD(entity_id, csp_id), ({ fields, ids, writable }) => {
    if (!_.isEmpty(fields)) setQuestions(fields)
    if (!_.isEmpty(ids)) setIds(ids)
    setIsWritable(writable)
  })
  useEffect(() => {
    fetchData()
  }, [])

  async function onSave() {
    if (await simpleValidate(EDDSchema, questions, setErrors)) {
      await submit()
      setUnsavedExists(false)
    } else {
      notify({ type: 'ERROR', title: 'FILL OUT ALL FIELDS' })
    }
  }

  const history = useHistory()


  return (
    <>
      <LeaveBlocker unsavedExists={unsavedExists} />
      <div className={className}>

        <EDDQuestionsSubPage disabled={!isWritable} errors={errors} ddQuestions={questions} setDDQuestions={setQuestions} managementDecisions={managementDecisions} />

        <EDDFinancialSubPage disabled={!isWritable} errors={errors} percents={percents} questions={questions} setQuestions={setQuestions} countries={countries} epfs={epfs} />

        <div className="card gutter-b">
          <div className="card-body d-flex justify-content-between">
            <button onClick={() => history.goBack()} className="btn btn-secondary">Back</button>
            {isWritable && <SaveButton loading={loading} onClick={onSave} />}
          </div>
        </div>


      </div>
    </>
  )
}