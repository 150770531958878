import React, { useEffect } from 'react'
import { useState } from 'react'
import { getInitialSubscriber, saveInitialSubscriber } from '../../../api/company'
import useRequest from '../../../components/useRequest/useRequest'
import { InitialSubscriberComponent, InitialSubscriberModal } from '../../create-company-page/components/initial-subscriber'

export default function InitialSubscriberTaskPage({ entity_id, shareholders }) {
  const [subscriber, setSubscriber] = useState({
    change_initial_subscriber: false,
    initial_subscriber: null
  })

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (entity_id) {
      fetch()
    }
  }, [entity_id])

  const [fetch] = useRequest(() => getInitialSubscriber(entity_id), data => data && setSubscriber(data))

  return (<>
    <InitialSubscriberModal onSave={async (data) => {
      await saveInitialSubscriber(entity_id, data)
      await fetch()
    }} onClose={() => setOpen(false)} open={open} shareholders={shareholders} value={subscriber} />
    <div className="card card-custom mb-4">
      <div className="card-body">
        <button onClick={() => setOpen(true)} className="btn btn-primary w-100">Change Initial Subscriber</button>
      </div>

    </div>

  </>)
}