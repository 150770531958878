import { Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Table } from '../../modules/Auth/pages/customers/customers-table/CustomersTable';
import MoreDetailsDropdown from "../../components/Dropdown/MoreDetailsDropdown";

export default function CSPSettingsTable(props) {
  const [settings, setSettings] = useState([]);

  const columns = [
    {
      id: 1,
      name: 'service_enabled',
      title: '',
      component: (row, index) => {

        return (
        <Switch
            checked={row.service_enabled}
            name="service_enabled"
            onChange={() => {props.onFeeUpdate('service_enabled', !row.service_enabled, index, row)}} />
        )
      }
    },
    {
      id: 2,
      name: 'service_title',
      title: 'Service title',
    },
    {
      id: 3,
      name: 'service_description',
      title: 'Description',
    },
    {
      id: 4,
      name: 'service_price',
      title: 'Price',
      editType: "number",
    },
    {
      id: 5,
      name: 'service_billing_type',
      title: 'Billing type',
      editType: "select",
      editSelectOptions: [
        {
          value: 'one_time',
          title: 'One time'
        },
        {
          value: 'monthly',
          title: 'Monthly'
        },
        {
          value: 'quarterly',
          title: 'Quarterly'
        },
        {
          value: 'annually',
          title: 'Annually'
        },
        {
          value: 'custom',
          title: 'Custom'
        },
      ],
    },
    {
      id: 6,
      name: 'service_account_id',
      title: 'Account ID',
      editType: "number",
    },
    {
      id: 7,
      name: 'service_account_code',
      title: 'Account code',
      editType: "number",
    },
    {
      id: 8,
      name: 'service_custom_billing_starting_date',
      title: 'Starting date',
      editType: "date",
    },
    {
      id: 9,
      name: 'service_custom_billing_frequency',
      title: 'Frequency (days)',
      editType: "number",
    },
    {
      id: 10,
      name: 'remove',
      title: '',
      component: row => {
        return <MoreDetailsDropdown row={row} options={dropdown} />;
      }
    },
  ]
  const dropdown = [
    {
      id: 1,
      title: "Edit",
      onClick: (e, row)  => {
        props.editService(row);
      }
    },
    {
      id: 2,
      title: "Delete",
      onClick: (e, row)  => {
        props.removeService(row.service_id);
      }
    },
  ];


  useEffect(() => {
    setSettings(props.settings.map(setting => {
      return {
        ...setting,
        id: setting.service_id
      }
    }))
  }, [props.settings])

  if (!props.settings) return;

  return (<Table cols={columns} nonSelectable entities={settings}/>)
}
