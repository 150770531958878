import React from 'react';
import { Badge } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../../../_metronic/_partials/dropdowns';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNotificationLink } from '../../../../_metronic/layout/components/extras/dropdowns/UserNotificationsDropdown';
import { notificationStatus } from '../../../../constants';
import { deleteAllNotifications, deleteNotification, setNotificationStatus } from '../../../api/user';
import {
  getAllUserNotifications,
  getUserNotificationsCount,
  readAllNotifications
} from '../../../../redux/UserNotifications/userNotificationsActions';
import { CustomDropdown } from '../../../components/CustomControllers';

function BadgeVariant({ status }) {
  let variant = 'primary';
  if (status === 'reviewed') variant = 'light';
  if (status === 'completed') variant = 'success';
  return (
    <Badge variant={variant} className="mr-8">
      {status}
    </Badge>
  );
}

function getDropdownActions({ notification_id }) {
  return [
    {
      title: 'Mark as New',
      onClick: () =>
        setNotificationStatus(
          notification_id,
          notificationStatus.new
        )
          .then(() => getAllUserNotifications())
          .then(() => getUserNotificationsCount())
    },
    {
      title: 'Mark as Reviewed',
      onClick: () =>
        setNotificationStatus(
          notification_id,
          notificationStatus.reviewed
        )
          .then(() => getAllUserNotifications())
          .then(() => getUserNotificationsCount())
    },
    {
      title: 'Mark as Completed',
      onClick: () =>
        setNotificationStatus(
          notification_id,
          notificationStatus.completed
        )
          .then(() => getAllUserNotifications())
          .then(() => getUserNotificationsCount())
    },
    {
      title: 'Remove task',
      onClick: () => deleteNotification(notification_id)
        .then(() => getAllUserNotifications())
        .then(() => getUserNotificationsCount())
    }
  ];
}

export function ActionCenter({ className, options }) {
  const csp_id = useSelector(state => state.tenant.csp?.csp_id);
  const user_id = useSelector(state => state.auth?.user?.user_id);

  function Toolbar() {
    const toolbarActions = [
      {
        title: 'Initiate New Task',
        onClick: () => {}
      },
      {
        title: 'Review All Tasks',
        disabled: !user_id,
        onClick: () => {
          readAllNotifications()
        }
      },
      {
        title: 'Remove Completed',
        disabled: !user_id,
        onClick: () => deleteAllNotifications(user_id)
          .then(() => getAllUserNotifications())
      }
    ]

    return (
      <div className="card-toolbar">
        <CustomDropdown
          title="Decision"
          className="px-10"
          variant="success"
          items={toolbarActions}
        />
      </div>
    );
  }

  function ActionCenterNotification({ notification, csp_id }) {
    const link = getNotificationLink(notification, csp_id);
    return (
      <div className="d-flex align-items-center mb-10">
        <div className="d-flex flex-column flex-grow-1">
          <Link to={link} className="text-dark-75 text-hover-primary font-weight-bold font-size-h4 mb-1">
            {notification.notification_title}
          </Link>
          <span className="text-muted font-weight-bold">{notification.notification_message}</span>
        </div>
        <BadgeVariant status={notification.notification_status} />
        <CustomDropdown
          as={DropdownCustomToggler}
          items={getDropdownActions(notification)}
          component={() => <i className="ki ki-bold-more-hor" />}
        />
      </div>
    );
  }

  return (
    <div className={`card card-custom bg-light rounded-lg ${className}`} style={{ minHeight: 220 }}>
      <div className="card-header border-0">
        <h3 className="card-title font-size-h3 w-50 font-weight-bolder table-title">Action Center</h3>
        <Toolbar />
      </div>
      {/* Body */}
      <div className="card-body pt-2">
        {options.map((notification, key) => {
          return <ActionCenterNotification {...{notification, csp_id, key}}/>
        })}
      </div>
    </div>
  );
}
