import React, { useEffect, useState } from 'react'
import useRequest from '../components/useRequest/useRequest'

export default function useTitles(getTitles = () => Promise.resolve()) {
  const [options, setOptions] = useState([])
  const [request] = useRequest(getTitles, (values) => {
    setOptions(
      Object.keys(values).map(key => ({
        value: key,
        title: values[key]
      }))
    )
  })

  useEffect(() => {
    request()
  }, [])

  return [options]
}