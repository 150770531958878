import store from "../../redux/store";

export const GET_USER_NOTIFICATIONS_COUNT = 'GET_USER_NOTIFICATIONS_COUNT'
export const SET_USER_NOTIFICATIONS_COUNT = 'SET_USER_NOTIFICATIONS_COUNT'
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS'
export const GET_ALL_USER_NOTIFICATIONS = 'GET_ALL_USER_NOTIFICATIONS'
export const SET_ALL_USER_NOTIFICATIONS = 'SET_ALL_USER_NOTIFICATIONS'
export const DELETE_USER_NOTIFICATION = 'DELETE_USER_NOTIFICATION'

export function getUserNotificationsCount() {
  store.dispatch({
    type: GET_USER_NOTIFICATIONS_COUNT
  })
}

export function getAllUserNotifications() {
  store.dispatch({
    type: GET_ALL_USER_NOTIFICATIONS
  })
}

export function readAllNotifications() {
  store.dispatch({
    type: READ_ALL_NOTIFICATIONS
  })
}

export function deleteUserNotification(id) {
  store.dispatch({
    type: DELETE_USER_NOTIFICATION,
    payload: id
  })
}