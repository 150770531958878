import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {FormInput, SearchField} from '../../../components/Basic';
import SetCleasingDateModal from "../../../components/BeneficialOwner/SetCleasingDateModal";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import ActionsDropdown from "../../../components/Dropdown/ActionsDropdown";
import {selectOptions} from '../../StaffOnboarding/SelectOptions';
import "../styles/beneficialOwnersRoute.css";

let debounce;

const cols = [
  {
    id: 1,
    name: "type",
    title: "Type"
  },
  {
    id: 2,
    name: "ownership_percentage",
    title: "Ownership Percentage"
  },
  {
    id: 3,
    name: "human_fullname",
    title: "Name"
  },
  {
    id: 7,
    name: "entity_name",
    title: "Entity name"
  },
  {
    id: 6,
    title: "",
    name: "actions_dropdown"
  }
];

function openEDDPage(pc_id) {
  window.open(
    `/edd?pc_id=${pc_id}`,
    "_blank",
    "menubar=no,toolbar=no,width=800,height=900"
  );
}

function openHDDPage(bo_id) {
  window.open(
    `/staff-onboarding?bo_id=${bo_id}`,
    "_blank",
    "menubar=no,toolbar=no,width=800,height=900"
  );
}

function EditUserContent(props) {
  return (
    <>
      <div className="ui-form-middle">
        <FormInput
          w-title
          placeholder="Full name"
          type="text"
          name="human_fullname"
          onChange={props.onNodeUpdate}
          value={props.currentNode.human_fullname}
        />
        <FormInput
          w-title
          placeholder="Date of birth"
          type="date"
          name="human_date_of_birth"
          onChange={props.onNodeUpdate}
          value={props.currentNode.human_date_of_birth}
        />
      </div>

      { props.is_edit && (
        <Button
          className="mt-2"
          variant="secondary"
          onClick={() => openHDDPage(props.currentNode.bo_id)}
        >
          Edit IDD information
        </Button>
      ) }
    </>
  );
}

function EditEntityContent(props) {
  return (
    <>
      <FormInput
        mui
        label="Entity name"
        type="text"
        name="entity_name"
        onChange={props.onNodeUpdate}
        value={props.currentNode.entity_name}
      />
      <div className="ui-form-middle w-100 mt-3">
        <FormInput
          mui
          label="Registration Number"
          name="registration_number"
          onChange={props.onNodeUpdate}
          value={props.currentNode.registration_number}
        />
        <SearchField
          name="jurisdiction"
          value={props.currentNode.jurisdiction}
          onChange={props.onNodeUpdate}
          label="Jurisdiction region"
          options={selectOptions.country}
        />
      </div>

      { props.is_edit && (
        <Button
          className="mt-2"
          variant="secondary"
          onClick={() => openEDDPage(props.currentNode.pc_id)}
        >
          Edit EDD information
        </Button>
      ) }
    </>
  );
}

function EditNodeModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Edit{" "}
          {(props.currentNode && props.currentNode.type) === "human"
            ? "Human"
            : "Entity"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(props.currentNode && props.currentNode.type) === "human" ? (
          <EditUserContent
            currentNode={props.currentNode}
            onNodeUpdate={props.onNodeUpdate}
            is_edit={ true }
          />
        ) : (
          <EditEntityContent
            currentNode={props.currentNode}
            onNodeUpdate={props.onNodeUpdate}
            is_edit={ true }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function BeneficialOwnersWizardRoute(props) {
  const params = useParams();

  // Map that contains the whole tree of shareholders and all their info
  const [fullMap, setFullMap] = useState([]);

  // Array of paths to the companies that are not yet fully completed (not all shareholders are defined)
  const [pendingCompanies, setPendingCompanies] = useState([]);

  // "Pointer" to the entity the user is currenly looking at. Used to show the shareholders to the client
  const [
    currentTargetShareholdersRef,
    setCurrentTargetShareholdersRef
  ] = useState({ info: { entity_name: "Root" }, shareholders: fullMap });

  const [shareholdersInfo, setShareholdersInfo] = useState([]);

  const setShareholders = () => {
    const sh = currentTargetShareholdersRef.shareholders.map(
      shareholder => shareholder.info
    );
    setShareholdersInfo(() => sh);
  };

  useEffect(() => {
    setShareholders();
  }, [currentTargetShareholdersRef]);

  // "Pointer" to the entity the user is currenly looking at. Used to add new shareholders to the list
  const [currentTargetRef, setCurrentTargetRef] = useState([]);

  const [breadcrumbs, setBreadcrumbs] = useState([{ entity_name: "Root" }]);

  // New node the user is creating
  const [currentNode, setCurrentNode] = useState({});

  // Already existing node that the user is editing
  const [currentEditNode, setCurrentEditNode] = useState({});

  // List of suggested entities. The user can click on one of them to emplate an already exisitng entity without adding all the info about it
  const [emplaceCompanySuggestions, setEmplaceCompanySuggestions] = useState(
    []
  );

  // Suggested entity that was selected by the user
  const [companyToEmplace, setCompanyToEmplace] = useState();

  const [editNodeModalShown, setEditNodeModalShown] = useState(false);

  const [isAwaiting, setIsAwaiting] = useState(false);

  // Modal window for setting date of cleasing
  const [cleasingDateModalOpened, setCleasingDateModalOpened] = useState(false);
  const onOpenCleasingDateWindow = (_event, shareholder) => {
    setCleasingDateModalOpened(true);
    setDateOfCleasingBO({
      bo_id: currentTargetShareholdersRef.info.bo_id,
      shareholder_bo_id: shareholder.info.bo_id
    });

    setShareholders();
  };

  const onCloseCleasingDateWindow = _event => {
    setCleasingDateModalOpened(false);
    setDateOfCleasingBO(null);
  };

  const onSaveCleasingDate = (event, shareholder) => {
    Axios.post(`company/${params.entity_id}/bo/set_date_of_cleasing`, {
      bo_id: dateOfCleasingBO.bo_id,
      shareholder_bo_id: dateOfCleasingBO.shareholder_bo_id,
      date_of_cleasing: dateOfCleasing
    });
  };

  // cleasing date data and
  const [dateOfCleasing, setDateOfCleasing] = useState();
  const [dateOfCleasingBO, setDateOfCleasingBO] = useState(null);

  const findEntity = () => {
    const formData = new FormData();

    formData.append('entity_name', currentNode.entity_name)
    formData.append('registration_number', currentNode.registration_number)
    formData.append('jurisdiction', currentNode.jurisdiction)
    formData.append('entity_type', currentNode.type === 'human' ? 'human' : 'entity')
    formData.append('human_fullname', currentNode.human_fullname)

    return Axios.post('csp/find-entity', formData)
      .then(({ data }) => {
        setEmplaceCompanySuggestions(data.companies);
      })
      .catch(console.error);
  };

  const onNodeUpdate = e => {
    // On every new node field input
    const field = e.target.name;
    const value = e.target.value;
    setCurrentNode({ ...currentNode, [field]: value });
    if (
      field === "entity_name" ||
      field === "registration_number" ||
      field === "human_fullname"
    ) {
      clearTimeout(debounce);

      debounce = setTimeout(() => {
        findEntity();
      }, 800);
    }
  };

  const onEditNodeUpdate = e => {
    // On every existing node field input
    setCurrentEditNode({ ...currentEditNode, [e.target.name]: e.target.value });
  };

  const addNode = type => {
    // Show a new node form to the user (after "add *something*" click)

    setCurrentNode({
      type
    });
  };

  const getCompanyRef = target => {
    // Get the "pointer" to the entity from the path to it

    if (!target.length) return fullMap;

    let ref = fullMap[target[0]];

    for (let i = 1; i < target.length; i++) {
      ref = ref.shareholders[target[i]];
    }

    return ref;
  };

  const saveEditedNode = () => {
    // TODO!!! @hack
    Axios.put(`company/${params.entity_id}/bo`, {
      ...currentEditNode,
      type: currentEditNode.type === "human" ? "human" : "entity",
      pc_id: undefined,
      is_direct: undefined
    })
      .then(({ data }) => {
        setEditNodeModalShown(false);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const goToNode = (parent_node_id, node_index) => {
    // Show shareholders of some company (basically go further in the tree)

    const is_root = parent_node_id === undefined && node_index === undefined;
    const new_breadcrumbs = breadcrumbs;

    if (!is_root && (parent_node_id === undefined || parent_node_id === null)) {
      return;
    }

    const update_refs = () => {
      // This function updates all of the references to the node that is currently in view

      if (is_root) {
        setCurrentTargetShareholdersRef({ ...currentTargetShareholdersRef });
      } else {
        setCurrentTargetRef([...new_current_target_ref]);
        setCurrentTargetShareholdersRef(node_ref);
        setBreadcrumbs(new_breadcrumbs);
      }
    };

    // Add a new "step" to get to the target company that we are adding
    const new_current_target_ref = currentTargetRef;

    if (!is_root) {
      new_current_target_ref.push(node_index);
    }

    let node_ref = getCompanyRef(new_current_target_ref);

    if (!node_ref) return;

    let shareholders_ref;

    if (Array.isArray(node_ref)) {
      // Case where the parent is the root (client's full company)
      shareholders_ref = node_ref;
    } else {
      shareholders_ref = node_ref.shareholders;

      new_breadcrumbs.push(node_ref.info);
    }

    if (shareholders_ref.length !== 0) {
      // We already have the list of shareholder of the selected company
      // That means that the user has probably already went into this company and loaded the list before
      // So, we don't need to load them again, we already have the list

      update_refs();
      return;
    }

    let url_params = "";

    if (is_root) {
      url_params += `?root=1`;
    } else {
      url_params += `?bo_id=${parent_node_id}`;
    }

    setIsAwaiting(true);

    // Get the info about a company the user is trying to dig into
    Axios.get(`company/${params.entity_id}/bo/${url_params}`)
      .then(({ data }) => {
        for (const bo of data.beneficial_owners) {
          let bo_type;

          if (data.direct_shareholders) {
            // Direct shareholder

            if (bo.user) bo_type = "human";
            else if (bo.pc) bo_type = "pseudo_company";
            else if (bo.company) bo_type = "real_company";

            const info = {
              type: bo_type,
              bo_id: bo.global_bo_definition && bo.global_bo_definition.bo_id,
              ownership_percentage: bo.ownership_percentage,
              is_direct: true
            };

            if (bo_type === "human") {
              // Direct bo is a human

              info.human_fullname = bo.user.user_full_name;
            } else if (bo_type === "pseudo_company") {
              // Direct bo is a PC

              info.pc_id = bo.pc.pc_id;
              info.entity_name = bo.pc.pc_name;
              info.jurisdiction = bo.pc.pc_jurisdiction_region;
              info.registration_number = bo.pc.pc_registration_number;
            } else if (bo_type === "real_company") {
              // Direct bo is a real C entity

              info.entity_name = bo.company.company_name;
            }

            shareholders_ref.push({
              info,
              shareholders: []
            });
          } else {
            // Deep shareholder

            if (bo.global_bo_definition.bo_type === "human") bo_type = "human";
            else if (bo.global_bo_definition.pseudo_company)
              bo_type = "pseudo_company";
            else if (
              bo.global_bo_definition &&
              bo.global_bo_definition.bo_company_id
            )
              bo_type = "real_company";

            const info = {
              type: bo_type,
              bo_id: bo.shareholder_bo_id,
              ownership_percentage: bo.ownership_percentage
            };

            if (bo_type === "pseudo_company") {
              // This is a PC entity

              info.pc_id = bo.global_bo_definition.pseudo_company.pc_id;
              info.entity_name = bo.global_bo_definition.pseudo_company.pc_name;
              info.jurisdiction =
                bo.global_bo_definition.pseudo_company.pc_jurisdiction_region;
              info.registration_number =
                bo.global_bo_definition.pseudo_company.pc_registration_number;
            } else if (bo_type === "real_company") {
              // This is a real C entity

              info.entity_name = bo.global_bo_definition.bo_company_name;
            } else if (bo.global_bo_definition.human_bo) {
              // This is a human

              info.human_fullname =
                bo.global_bo_definition.human_bo.human_fullname;
              info.human_date_of_birth =
                bo.global_bo_definition.human_bo.human_date_of_birth &&
                bo.global_bo_definition.human_bo.human_date_of_birth.split(
                  "T",
                  1
                )[0];
            }

            shareholders_ref.push({
              info,
              shareholders: []
            });
          }
        }

        update_refs();
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsAwaiting(false));
  };

  const pushNode = () => {
    // Add a new node (entity/human)

    let node_ref = getCompanyRef(currentTargetRef);

    const parent_bo_id =
      currentTargetRef.length === 0 ? "root" : node_ref.info.bo_id;

    // Case where the parent is the root (client's full company)
    const shareholders_ref = Array.isArray(node_ref)
      ? node_ref
      : node_ref.shareholders;

    let emplace_data;

    if (companyToEmplace) {
      emplace_data =
        companyToEmplace.node_type === "real_company"
          ? {
              shareholder_company_id: companyToEmplace.c_id,
              shareholder_company_csp_id: companyToEmplace.c_csp_id,
              ownership_percentage: currentNode.ownership_percentage,
              type: "entity",
              date_of_entry: currentNode.date_of_entry && currentNode.date_of_entry.getTime(),
            }
          : {
              shareholder_bo_id: companyToEmplace.bo_id,
              ownership_percentage: currentNode.ownership_percentage,
              date_of_entry: currentNode.date_of_entry && currentNode.date_of_entry.getTime(),
            };
    }

    Axios.post(
      `company/${params.entity_id}/bo/add?parent_bo_id=${parent_bo_id}${
        companyToEmplace ? "" : "&new_definition=1"
      }`,
      companyToEmplace ? emplace_data : currentNode
    )
      .then(({ data }) => {
        const new_node_data = companyToEmplace
          ? {
              type: companyToEmplace.node_type,
              bo_id: data.new_bo_id,
              ownership_percentage: currentNode.ownership_percentage,
              entity_name: companyToEmplace.display_name,
              jurisdiction:
                companyToEmplace.jurisdiction,
              registration_number:
                companyToEmplace.registration_number,
              human_fullname: companyToEmplace.display_name,
              human_date_of_birth: companyToEmplace.human_date_of_birth
            }
          : {
            ...currentNode,
            type: currentNode.type === "human" ? "human" : "pseudo_company"
          };

        const new_index =
          shareholders_ref.push({
            info: { ...new_node_data, bo_id: data.new_bo_id },
            shareholders: []
          }) - 1;

        if (new_node_data.type === "pseudo_company" && !companyToEmplace) {
          // TODO even if we are adding an existing bo, we might still need to add it to the pending list, in the case when this bo is not
          // yet finished

          const new_pending_companies = pendingCompanies;

          new_pending_companies.push([...currentTargetRef, new_index]);

          setPendingCompanies(new_pending_companies);
        } else {
          setCompanyToEmplace();
        }

        setCurrentNode({});
        
        back(breadcrumbs.length - 1, true);
      })
      .catch(() => {});
  };

  const back = (breadcrumbs_index, force = false) => {
    // Go some steps back

    // This is the root - can't go back further
    if (!currentTargetRef.length) return;

    let depth = 1;

    if (breadcrumbs_index !== undefined) {
      depth = breadcrumbs.length - breadcrumbs_index - 1;

      const new_breadcrumbs = breadcrumbs;

      new_breadcrumbs.length -= depth;

      setBreadcrumbs(new_breadcrumbs);
    }

    if (depth === 0 && !force) return;

    const back_to_root = breadcrumbs_index === 0;

    const new_current_target_ref = currentTargetRef;

    new_current_target_ref.length -= depth;

    const ref = getCompanyRef(new_current_target_ref);

    if (back_to_root) {
      setCurrentTargetShareholdersRef({
        info: { entity_name: "Root" },
        shareholders: ref
      });
    } else {
      setCurrentTargetShareholdersRef({...ref});
    }

    setCurrentTargetRef(new_current_target_ref);
  };

  const nextStep = () => {
    // Check if there are any pending companies that need to be completed

    if (!pendingCompanies.length) {
      return;
    }

    // Go to the last company that was added the the pending list
    const ref = getCompanyRef(pendingCompanies[pendingCompanies.length - 1]);

    setCurrentTargetRef(pendingCompanies[pendingCompanies.length - 1]);
    setCurrentTargetShareholdersRef(ref);

    // Remove this company from the pending list
    const new_pending_companies = pendingCompanies;
    pendingCompanies.length--;
    setPendingCompanies(new_pending_companies);
  };

  const showNodeEditModal = (e, node) => {
    e.stopPropagation();

    setEditNodeModalShown(true);
    setCurrentEditNode({
      ...node.info
    });

    setShareholders();
  };

  const deleteNode = (e, node) => {
    e.stopPropagation();

    let node_ref = getCompanyRef(currentTargetRef);

    if (!node_ref) return;

    const parent_bo_id = node_ref.info.bo_id;

    Axios.post(
      `company/${params.entity_id}/bo/remove?parent_bo_id=${parent_bo_id}&target_bo_id=${node.info.bo_id}`
    )
      .then(({ data }) => {
        if (data.success) {
          node_ref.shareholders = node_ref.shareholders.filter(
            bo => bo.info.bo_id !== node.info.bo_id
          );

          setFullMap([...fullMap]);

          setShareholders();
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    // Get the BOs of the root node (the actual company (C))
    goToNode(/* no args for root node */);
  }, []);


  cols.find(col => col.name === "actions_dropdown").component = (
    row,
    rowIndex
  ) => {
    const shareholder = currentTargetShareholdersRef.shareholders[rowIndex];
    const dropdown = [
      {
        id: 1,
        title: "Edit",
        onClick: e => showNodeEditModal(e, shareholder)
      },
      {
        id: 2,
        title: "Delete",
        onClick: e => deleteNode(e, shareholder)
      },
      {
        id: 3,
        title: "Set Review Date",
        onClick: e => onOpenCleasingDateWindow(e, shareholder)
      }
    ];

    return !!shareholder.info.type && shareholder.info.type === 'pseudo_company' && <MoreDetailsDropdown options={dropdown} /> || null;
  };

  const actions = [
    {
      id: 1,
      title: "Add a human",
      onClick: () => addNode("human")
    },
    {
      id: 2,
      title: "Add an entity",
      onClick: () => addNode("entity")
    }
  ];

  return (
    <div className="col-lg-12">
      <div className="card  card-custom card-stretch gutter-b">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
              Beneficial ownership
            </div>
          </div>

          <div className="card-toolbar">
            {!!currentTargetRef.length && <ActionsDropdown options={actions} />}
          </div>
        </div>

        <div className="card-body" style={ isAwaiting ? { pointerEvents: "none", opacity: "0.5", userSelect: "none" } : {} }>
          <EditNodeModal
            show={editNodeModalShown}
            onHide={() => setEditNodeModalShown(false)}
            currentNode={currentEditNode}
            onNodeUpdate={onEditNodeUpdate}
            onSave={saveEditedNode}
          />

          <div className="route-breadcrumbs d-flex flex-direction-row align-items-center flex-wrap">
            {breadcrumbs.map((place, index) => (
              <React.Fragment
                key={place.registration_number || place.entity_name}
              >
                <div
                  className="item btn btn-bg-light btn-sm item"
                  onClick={() => back(index)}
                >
                  {place.entity_name}
                </div>
                <div className="spacer p-4">&gt;</div>
              </React.Fragment>
            ))}
          </div>

          <Table
            cols={cols}
            idField="bo_id"
            clickable
            nonSelectable
            entities={shareholdersInfo}
            rowEvents={{
              onClick: (e, row, rowIndex) => {
                e.stopPropagation();
                row.type === "pseudo_company" && goToNode(row.bo_id, rowIndex);
              }
            }}
          />

          {currentNode.type && (
            <div className="new-node card p-4 mt-4">
              {companyToEmplace ? (
                <>
                  <div className="font-weight-bold">
                    Emplacing a node: {companyToEmplace.display_name}
                  </div>
                  <div className="border-xxl card card-custom mt-4 p-4">
                    {companyToEmplace.node_type === "human" ? (
                      <>
                        <div>
                          Date of birth: {companyToEmplace.human_date_of_birth}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          Jurisdiction region:{" "}
                          {companyToEmplace.jurisdiction}
                        </div>
                        <div>
                          Registration number:{" "}
                          {companyToEmplace.registration_number}
                        </div>
                      </>
                    )}
                    <button
                      className="btn btn-danger btn-sm mt-5 w-100px"
                      onClick={() => setCompanyToEmplace()}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    Adding a new{" "}
                    <span className="font-weight-bold">
                      {currentNode.type === "entity" ? "Entity" : "Human"}
                    </span>{" "}
                    shareholder
                  </div>

                  {emplaceCompanySuggestions.length !== 0 && (
                    <div className="card p-2 my-2">
                      {emplaceCompanySuggestions.map(suggestion => (
                        <div
                          key={suggestion.bo_id}
                          className="btn btn-default btn-sm text-left"
                          onClick={() => setCompanyToEmplace(suggestion)}
                        >
                          {suggestion.node_type === "human" ? (
                            <>
                              {suggestion.display_name} (
                              {suggestion.human_date_of_birth}, human)
                            </>
                          ) : (
                            <>
                              {suggestion.display_name} (
                              {suggestion.registration_number},{" "}
                              {suggestion.jurisdiction},{" "}
                              {suggestion.node_type})
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {currentNode.type === "entity" ? (
                    <EditEntityContent
                      currentNode={currentNode}
                      onNodeUpdate={onNodeUpdate}
                      is_edit={ false }
                    />
                  ) : (
                    <EditUserContent
                      currentNode={currentNode}
                      onNodeUpdate={onNodeUpdate}
                      is_edit={ false }
                    />
                  )}
                </>
              )}

              <FormInput
                w-title
                placeholder="Ownership percentage"
                type="number"
                name="ownership_percentage"
                onChange={onNodeUpdate}
                value={currentNode.ownership_percentage}
              />

              <FormInput
                w-title
                placeholder="Date of entry"
                type="date"
                name="date_of_entry"
                onChange={onNodeUpdate}
                value={currentNode.date_of_entry}
              />

              <div
                className="btn btn-primary float-right mt-4"
                onClick={pushNode}
              >
                Save
              </div>
            </div>
          )}

          <SetCleasingDateModal
            show={cleasingDateModalOpened}
            onClose={e => onCloseCleasingDateWindow(e)}
            onSave={e => onSaveCleasingDate(e)}
            onValueChange={e => setDateOfCleasing(e.target.value)}
            value={dateOfCleasing}
          />
        </div>
      </div>
    </div>
  );
}

export default BeneficialOwnersWizardRoute;
