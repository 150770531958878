import store from "./redux/store";
import { setTenantSlug } from "./redux/Tenant/tenantActions";
import { FILE_EXTENSION_FROM_MIMETYPE } from "./constants";

export const redirectToTenant = (tenant_slug, no_refresh) => {
  localStorage.setItem("tenant", tenant_slug);
  store.dispatch(setTenantSlug(tenant_slug))

  if (no_refresh !== true) window.location.reload();

  // TODO like this for now
  return;
  // const host_split = window.location.host.split(".");

  // // For dev environment, when the frontend is at `localhost` - just use the `localhost` as the "second" level domain
  // // For prod env - use the second level domain, like `resolute.com` as the base
  // //
  // // Then we will add a tenant slug to that base url (so the url will become `tenant.resolute.com` or `tenant.localhost`) 
  // const portal_domain = process.env.NODE_ENV === "development"
  // ? host_split[host_split.length - 1]
  // : `${ host_split[host_split.length - 2] }.${ host_split[host_split.length - 1] }`;

  // window.location.href = `//${ tenant_slug }.${ portal_domain }`;
};

export const downloadFile = async (res, custom_filename = 'document') => {
  const temp = window.URL.createObjectURL(res.data)
  const link = document.createElement('a');
  link.href = temp;

  const extension = res.headers['x-file-extension']
    || FILE_EXTENSION_FROM_MIMETYPE[res.headers['content-type']]

  link.setAttribute('download', `${custom_filename}.${extension}`); //or any other extension
  document.body.appendChild(link);
  link.click();
}

export const openInBrowser = (blobData) => {
  const href = window.URL.createObjectURL(blobData);
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href
  }).click();
}

const UI_MIME_TYPES = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
  "image/jpeg": "JPEG Image",
  "image/png": "PNG Image",
  "text/csv": "CSV Spreadsheet",
  "application/pdf": "PDF",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "application/vnd.ms-excel": "MS Excel Spreadsheet (CSV/XLS)",
  "application/csv": "CSV Spreadsheet",
  "application/msword": "DOC",
  "application/vnd.oasis.opendocument.text": "Open Office Document",
  "application/vnd.oasis.opendocument.spreadsheet": "Open Office Spreadsheet"
}

export const getUserFriendlyMIMEType = raw_mime_type => {
  return UI_MIME_TYPES[raw_mime_type] || raw_mime_type;
}

export function getURLQueryParams(search) {
  const result = {};

  search = search.substring(1);

  const params = search.split("&");

  for (const param of params) {
    const eq_sign_pos = param.indexOf("=");

    result[param.slice(0, eq_sign_pos)] = param.slice(eq_sign_pos + 1);
  }

  return result;
}
