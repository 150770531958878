import React, { useEffect } from 'react'
import { useState } from 'react'
import MoreDetailsDropdown from '../../../../components/Dropdown/MoreDetailsDropdown';
import { Table } from '../../../../modules/Auth/pages/customers/customers-table/CustomersTable';
import * as yup from 'yup'
import SimpleModalFieldsConfiguration from '../../../../components/EditProfileForm/SimpleModalFields';
import AddIcon from '@material-ui/icons/Add';
import { getIDTypes } from '../../../../api/ui';
import useCountries from '../../../../hooks/useCountries';

export default function NationalitiesTable({ nationalities = [], setNationalities = () => { }, deletingIds = [], setDeletingIds = () => {}}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [idTypes, setIdTypes] = useState([])

  const [countries] = useCountries()

  const [selectedCountries, setSelectedCountries] = useState([])

  useEffect(() => {
    if (nationalities[0]) nationalities[0].is_primary = true
    const countries = nationalities.map(({ nationality_name }) => nationality_name)

    setSelectedCountries(countries)
  }, [nationalities])

  useEffect(() => {
    getIDTypes().then(t => {
      setIdTypes(Object.keys(t).map(type => ({
        value: type,
        title: t[type]
      })))
    })
  }, [])

  const currentOption = {
    name: `primary_nationality`,
    type: 'modal',
    title: 'Primary nationality',
    options: [
      {
        name: 'nationality_name',
        title: 'Nationality',
        type: 'country',
        options: countries,
        bannedValues: selectedCountries,
        validation: () => yup.string().required('Cannot be empty!')
      },
      {
        name: 'id_type',
        title: 'ID Type',
        type: 'select',
        options: idTypes,
        validation: () => yup.string().required('Cannot be empty!')
      },
      {
        name: 'id_number',
        title: 'ID Number',
        type: 'text',
        validation: () => yup.string().required('Cannot be empty!')
      },
      {
        name: 'id_issue_date',
        title: 'ID Issue Date',
        type: 'date',
        validation: (context) => yup.date()
          .required('Cannot be empty!')
          .test('test_issue_date', 'Cannot be in future!', (value) => {
            return value <= Date.now()
          })
          .test('issue_date_before_expire', 'Cannot be after expired!', (value) => {
            return value <= new Date(context.id_expiry_date)
          })
      },
      {
        name: 'id_expiry_date',
        title: 'ID Expiry Date',
        type: 'date',
        validation: (context) => yup.date().required('Cannot be empty!')
          .test('test_expiry_date', 'Cannot be before issue date!', (value) => {
            return value >= new Date(context.id_issue_date)
          })
      },
      {
        name: 'upload_copy_of_id',
        title: 'Upload copy of ID',
        type: 'file',
        validation: (context) => ({
          validate: (value) => {
            if (!(value instanceof File) && !context.field_id)
              throw new Error('File is required')
          }
        })
      },
    ]
  }

  const secondaryNationalityOption = {
    name: `secondary_nationality`,
    type: 'modal',
    title: 'Secondary nationality',
    options: [
      {
        name: 'nationality_name',
        title: 'Nationality',
        type: 'country',
        options: countries,
        bannedValues: selectedCountries,
        validation: () => yup.string().required('Cannot be empty!')
      },
      {
        name: 'supporting_documents_available',
        title: 'Supporting documents available?',
        type: 'open-switch',
        disabled: (context) => {
          return context.document_id
        },
        options: [
          {
            name: 'id_type',
            title: 'ID Type',
            type: 'select',
            options: idTypes,
            validation: () => yup.string().required('Cannot be empty!')
          },
          {
            name: 'id_number',
            title: 'ID Number',
            type: 'text',
            validation: () => yup.string().required('Cannot be empty!')
          },
          {
            name: 'id_issue_date',
            title: 'ID Issue Date',
            type: 'date',
            validation: (context) => yup.date()
              .required('Cannot be empty!')
              .test('test_issue_date', 'Cannot be in future!', (value) => {
                return value <= Date.now()
              })
              .test('issue_date_before_expire', 'Cannot be after expired!', (value) => {
                return value <= new Date(context.id_expiry_date)
              })
          },
          {
            name: 'id_expiry_date',
            title: 'ID Expiry Date',
            type: 'date',
            validation: (context) => yup.date().required('Cannot be empty!')
              .test('test_expiry_date', 'Cannot be before issue date!', (value) => {
                return value >= new Date(context.id_issue_date)
              })
          },
          {
            name: 'upload_copy_of_id',
            title: 'Upload copy of ID',
            type: 'file',
            validation: (context) => ({
              validate: (value) => {
                if (!(value instanceof File) && !context.field_id)
                  throw new Error('File is required')
              }
            })
          },
        ]
      }
    ]
  }

  const nationality_default_value = {
    nationality_name: '',
    supporting_documents_available: false,
    id_type: '',
    id_number: '',
    id_issue_date: null,
    id_expiry_date: null,
    upload_copy_of_id: '',
  }

  // const [nationalities, setNationalities] = useState([])
  const [currentNationalityIndex, setCurrentNationalityIndex] = useState(-1)

  const tableCols = [
    {
      id: 1,
      name: 'nationality_type',
      title: 'Nationality',
      component: (row, rowIndex) => {
        if (rowIndex === 0) {
          return (
            <span>
              Primary Nationality
            </span>
          )
        } else {
          return (
            <span>
              Nationality {rowIndex + 1}
            </span>
          )
        }
      }
    },
    {
      id: 2,
      name: 'nationality_name',
      title: 'Country',
      extraData: countries,
      component: (row, _, extraData) => {
        return extraData.find(({ value }) => value === row.nationality_name)?.title
      }
    },
    {
      id: 3,
      name: 'options',
      width: '50px',
      title: '',
      component: (row, rowIndex) => (
        <>
          <MoreDetailsDropdown row={row} options={
            [
              {
                title: 'Edit',
                onClick: () => {
                  setModalOpen(true)
                  setCurrentNationalityIndex(rowIndex)
                }
              },
              {
                title: 'Delete',
                onClick: () => {
                  if (row.field_id) {
                    setDeletingIds(old => [...old, row.field_id])
                  }
                  
                  setNationalities(oldNationalities => {
                    return oldNationalities.filter((_, index) => index !== rowIndex)
                  })
                }
              }
            ]
          } />
        </>)
    }
  ]

  const addNewNationalityClick = (e) => {
    e.preventDefault()
    setCurrentNationalityIndex(-1)
    setModalOpen(true)
  }

  const onSaveNationality = (data) => {
    data.is_editing = true
    if (currentNationalityIndex === -1) {
      if (!nationalities.length) {
        setNationalities(_ => {
          return [
            { ...data, is_primary: true, }
          ]
        })
      } else {
        setNationalities(oldNationalities => {
          return [
            ...oldNationalities,
            { ...data }
          ]
        })
      }

    } else {
      setNationalities(oldNationalities => {
        oldNationalities[currentNationalityIndex] = { ...data }
        return [...oldNationalities]
      })
    }
  }

  const getCurrentOption = () => {
    if (currentNationalityIndex !== -1) {
      if (currentNationalityIndex === 0) {
        return currentOption
      } else {
        return secondaryNationalityOption
      }
    } else if (!nationalities.length) {
      return currentOption
    } else {
      return secondaryNationalityOption
    }
  }

  const getValue = () => {
    return (currentNationalityIndex === -1 && nationality_default_value) ||
      (currentNationalityIndex !== -1 && nationalities[currentNationalityIndex])
  }

  return (
    <div>
      <SimpleModalFieldsConfiguration
        open={modalOpen}
        onChange={onSaveNationality}
        onClose={_ => setModalOpen(false)}
        currentOption={getCurrentOption()}
        value={getValue()} />
      <div className="card card-custom shadow-none">
        <div className="card-header p-0 border-0">
          <div className="card-title">
            Nationalities
          </div>
          <div className="card-toolbar">
            <button onClick={addNewNationalityClick} className="btn btn-primary rounded-circle btn-circle-40">
              <AddIcon />
            </button>

          </div>

        </div>
        <div className="card-body p-0">
          <Table nonSelectable cols={tableCols} entities={nationalities} />
        </div>
      </div>

    </div>
  )
}