import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormInput, FormSelect, SearchField } from './Basic';
import { MenuItem } from '@material-ui/core';
import { selectOptions } from '../pages/StaffOnboarding/SelectOptions';
import { notify } from '../../redux/NotificationSnackbar/snackbarActions';

let debounce;

function AddShareholderModal(props) {
  const [form, setForm] = useState({});

  const [potentialHumanShareholders, setPotentialHumanShareholders] = useState([]);

  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const selectExistingEntity = entity_info => {
    setSelectedCompany(entity_info);
  };

  const entity_id = props.entity_id;

  const findEntity = () => {
    const formData = new FormData();

    formData.append('entity_name', form.entity_name)
    formData.append('jurisdiction', form.jurisdiction)
    formData.append('registration_number', form.registration_number)
    formData.append('entity_type', form.shareholder_type)
    formData.append('human_fullname', form.human_fullname)
    formData.append('entity_id', entity_id)

    return Axios.post('csp/find-entity', formData)
      .then(({ data }) => {
        setCompanySuggestions(
          data.companies.sort((a, b) => b.score - a.score)
        );
      })
      .catch(console.error);
  };

  const getPotentialHumanShareholders = () => {
    Axios.get(`company/${props.entity_id}/potential-shareholders`)
      .then(({ data }) => {
        const potential_shareholders = data.potential_shareholders.map(shareholder => {
          shareholder.title = shareholder.user_full_name;

          if (!shareholder.dd_completed) {
            shareholder.title += ' (IDD not completed)';
            shareholder.is_unavailable = true;
          } else if (!shareholder.dd_decision_ok) {
            shareholder.title += ' (unacceptable IDD review status / not reviewed yet)';
            shareholder.is_unavailable = true;
          } else if (shareholder.already_a_shareholder) {
            shareholder.title += ' (already a shareholder)';
            shareholder.is_unavailable = true;
          }

          return shareholder;
        });

        const potential_shareholders_sorted = potential_shareholders.sort((a, b) => (a.is_unavailable ? 1 : -1));

        setPotentialHumanShareholders(potential_shareholders_sorted);
      })
      .catch(() => {});
  };

  const nextStep = () => {
    if (form.shareholder_type === 'human' && form.human_user_id) {
      postNewShareholder('human');
    } else if (selectedCompany && selectedCompany.node_type === 'pseudo_company') {
      postNewShareholder('pseudo_company');
    } else if (selectedCompany && selectedCompany.node_type === 'real_company') {
      postNewShareholder('real_company');
    } else if (form.shareholder_type === 'entity') {
      postNewShareholder('pseudo_company');
    }
  };

  const postNewShareholder = shareholder_type => {
    const final_form = selectedCompany
      ? {
          shareholder_type,
          pc_id: selectedCompany.pc_id,
          c_id: selectedCompany.c_id,
          c_csp_id: selectedCompany.c_csp_id
        }
      : { ...form, shareholder_type };

    return Axios.post(`company/${props.entity_id}/add-shareholder`, final_form)
      .then(response => {
        if (response.data.success) {
          getPotentialHumanShareholders();
          props.onHide(false);
        }
      })
      .then(() => {
        notify({type: 'SUCCESS', title: 'SUCCESS', message: 'Shareholder has been added'})
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (props.show && !potentialHumanShareholders.length) {
      getPotentialHumanShareholders();
    }
  }, [props.show]);

  function editField(fieldName, val) {
    setForm(prev => ({
      ...prev,
      [fieldName]: val
    }))

    clearTimeout(debounce);

    debounce = setTimeout(() => {
      findEntity();
    }, 800);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new shareholder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>You are about to add a new entity/individual shareholder</div>

          <FormSelect
            mui
            label="Shareholder type"
            name="shareholder_type"
            onChange={e => editField('shareholder_type', e.target.value)}
            value={form.shareholder_type}
          >
            <MenuItem value="human">Individual</MenuItem>
            <MenuItem value="entity">Entity</MenuItem>
          </FormSelect>

          {form.shareholder_type === 'human' && (
            <>
              <div className="mt-4">Please, select an individual from the list of this company's users with completed DDs.</div>
              <FormSelect
                mui
                label="Individual"
                name="human_user_id"
                onChange={e => editField('human_user_id', e.target.value)}
                value={form.human_user_id}
              >
                {potentialHumanShareholders.map((human, key) => (
                  <MenuItem disabled={human.is_unavailable} key={key} value={human.user_id}>
                    {human.title}
                  </MenuItem>
                ))}
              </FormSelect>
            </>
          )}

          {form.shareholder_type === 'entity' && (
            <>
              <div className="mt-4">Please, provide some information about this entity.</div>

              {selectedCompany ? (
                <>
                  <div className="font-weight-bold">Emplacing a company: {selectedCompany.entity_name}</div>
                  <div className="border-xxl card card-custom mt-4 p-4">
                    <div>Jurisdiction region: {selectedCompany.entity_jurisdiction_region || 'N/A'}</div>
                    <div>Registration number: {selectedCompany.entity_registration_number || 'N/A'}</div>

                    <button className="btn btn-danger btn-sm mt-5 w-100px" onClick={() => setSelectedCompany()}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <FormInput
                    mui
                    label="Entity name"
                    name="entity_name"
                    onChange={e => editField('entity_name', e.target.value)}
                    value={form.entity_name}
                  />

                  <FormInput
                    mui
                    label="Registration Number"
                    name="registration_number"
                    onChange={e => editField('registration_number', e.target.value)}
                    value={form.registration_number}
                  />

                  <SearchField
                    label="Jurisdiction region"
                    options={selectOptions.country}
                    name="jurisdiction"
                    onChange={e => editField('jurisdiction', e.target.value)}
                    value={form.jurisdiction}
                  />

                  {companySuggestions.map((suggestion, key) => (
                    <div
                      key={key}
                      className="btn btn-default btn-sm text-left w-100 mb-1"
                      onClick={() => selectExistingEntity(suggestion)}
                    >
                      {suggestion.display_name} ({suggestion.entity_registration_number || '—'},{' '}
                      {suggestion.entity_jurisdiction_region || '—'}, {suggestion.node_type === 'real_company' ? 'C' : 'PC'})
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={nextStep}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddShareholderModal;
