/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import '../ProfilePage.css';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { getUserFriendlyMIMEType } from '../../../util';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { Table } from '../../modules/Auth/pages/customers/customers-table/CustomersTable';
import UploadFilesModal from '../../components/UploadFilesModal/UploadFilesModal';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';
import { documentStatus } from '../../../constants';
import { getDocument } from '../../api/document';
import { getDocumentContentCategories } from '../../api/ui';

export default function VaultRoute(props) {
  const [documentsTable, setDocumentsTable] = useState([]);
  const [filteredDocumentsTable, setFilteredDocumentsTable] = useState([]);

  const [documentFilters, setDocumentFilters] = useState({
    category: null,
    mime_type: null,
    status: null
  });

  const [contentCategories, setContentCategories] = useState([])

  useEffect(() => {
    getDocumentContentCategories()
      .then(categories => {
        setContentCategories(categories)
      })
  }, [])

  // Update the filters
  useEffect(() => {
    updateFilteredDocuments();
  }, [documentFilters]);

  const updateFilteredDocuments = () => {
    const filtered_documents = [];

    for (const document of documentsTable) {
      // Filter by document category
      if (documentFilters.category && documentFilters.category !== '-1' && document.category !== documentFilters.category) {
        continue;
      }

      // Filter by document MIME type
      if (
        documentFilters.mime_type &&
        documentFilters.mime_type !== '-1' &&
        document.raw_mime_type !== documentFilters.mime_type
      ) {
        continue;
      }

      filtered_documents.push(document);
    }

    setFilteredDocumentsTable([...filtered_documents]);
  };

  const uploadNewDocuments = (_, documents) => {

    const form_data = new FormData();
    let file_i = 0;

    for (const file of documents) {
      form_data.append('document_title', file.document_title)
      form_data.append('document_content_category', file.document_content_category)
      form_data.append('document_status', file.document_status)
      form_data.append(file_i++, file.file);
    }

    return Axios.post(`csp/documents-upload`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {

        return getCompanyDocuments();
      })
      .catch((e) => {
        notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
        return Promise.reject(e)
      });
  };

  const getCompanyDocuments = () => {
    return Axios.get(`/csp/documents`).then(response => {
      const documents_table = [];
      const mime_types = [];

      for (const doc of response.data.csp_documents) {
        // Construct the document status string
        let status_string = doc.document_status;

        if (doc.document_is_signed) status_string += ', signed';

        // Add document's mimetype to the list of mimetypes that we have happened upon
        if (!mime_types.includes(doc.document_mime_type)) mime_types.push(doc.document_mime_type);

        // Add the document to the table
        documents_table.push({
          document_id: doc.document_id,

          raw_mime_type: doc.document_mime_type,
          mime_type: getUserFriendlyMIMEType(doc.document_mime_type),

          title: doc.document_title || doc.document_id,
          category: doc.document_content_category,
          document_category: doc.document_category,
          date: doc.updated_at,
          status: status_string,

          actions: (
            <Dropdown drop="left">
              <Dropdown.Toggle className="btn btn-light">
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => getDocument(doc.document_id, doc.document_title, 'csp')}>Download</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )
        });
      }

      setDocumentsTable(documents_table);
      setFilteredDocumentsTable(documents_table);
    });
  };

  useEffect(() => {
    getCompanyDocuments();
  }, []);

  const editFileFields = [
    {
      name: 'document_title',
      title: 'Title',
      type: 'text'
    },
    {
      name: 'document_content_category',
      title: 'Category',
      type: 'select',
      options: () => {
        return Object.keys(contentCategories).map(categoryKey => ({
          value: categoryKey,
          title: contentCategories[categoryKey]
        }))
      },
    },
    {
      name: 'document_status',
      title: 'Status',
      type: 'select',
      options: () => {
        return Object.values(documentStatus).map(status => ({
          value: status,
          title: status
        }))
      }
    },
  ]

  const VAULT_COLUMNS = [
    {
      id: 1,
      name: 'title',
      title: 'Name',
      width: '500px',
      sortable: true
    },
    {
      id: 2,
      name: 'category',
      title: 'Category',
      width: '200px',
      sortable: true,
      component: (row) => {
        return contentCategories[row.category] || row.document_category || '-'
      }
    },
    {
      id: 3,
      name: 'mime_type',
      title: 'MIME type',
      width: '200px',
      sortable: true
    },
    {
      id: 4,
      name: 'date',
      title: 'Date',
      width: '200px',
      sortable: true,
      component: row => {
        return moment(row.date).format('DD MMM YYYY');
      }
    },
    {
      id: 5,
      name: 'status',
      title: 'Status',
      width: '200px',
      sortable: true
    },
    {
      id: 6,
      name: 'actions',
      title: 'Actions',
      width: '150px',
      sortable: false
    }
  ];

  return (
    <>
      <div className="card-header border-0">
        <div className="card-title w-50 text-dark mb-4">Vault</div>
        <div className="card-toolbar">
          <UploadFilesModal editFileFields={editFileFields} onSave={uploadNewDocuments} title="Upload vault documents" />
        </div>
      </div>

      <div className="card-body">

        <Table entities={filteredDocumentsTable} cols={VAULT_COLUMNS} idField="document_id" nonSelectable />

      </div>
    </>
  );
}
