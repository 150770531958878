/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Axios from "axios";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import DonutSmallOutlinedIcon from '@material-ui/icons/DonutSmallOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { FORM_REGEX } from "./formRegex";

import EntityBasicsPage from "./form-pages/entityBasicsPage";
import ServicePage from "./form-pages/servicesPage";
import OwnershipTypesPage from "./form-pages/ownershipTypesPage";
import OwnershipDistributionPage from "./form-pages/ownershipDistributionPage";
import ControllingPersonsPage from "./form-pages/controllingPersonsPage";
import DocumentsPage from "./form-pages/documentsPage";
import { uniqueId } from "lodash-es";
import { notify } from "../../../redux/NotificationSnackbar/snackbarActions";
import { redirectToTenant, getURLQueryParams } from "../../../util";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import { getCompanyTypesRelations, getCompanyTypesTitles, getEntityCategories } from "../../api/ui";
import useOwnershipTypes from "./hooks/useOwnershipTypes";
import useJurisdictions from "./hooks/useJurisdictions";
import useTitlesWithRelations from "../../hooks/useTitlesWithRelations";
import useTitles from "../../hooks/useTitles";
import { useSelector } from 'react-redux';
import { InitialSubscriberModal } from "./components/initial-subscriber";

const page5_validation_schema = Yup.object({
  type: Yup.string()
    .required("Type is required"),
  entity_name: Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Entity name is required')
      .matches(FORM_REGEX.NAME, "Name is invalid")
  }),
  jurisdiction: Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Jurisdiction is required')
  }),
  entity_registration_number: Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Registration number is required')
  }),
  role: Yup.string()
    .required("Role is required"),
  position: Yup.string().when('role', {
    is: "officer",
    then: Yup.string().required('Position is required')
  }),
  name: Yup.string().when('type', {
    is: "human",
    then: Yup.string().required('Entity name is required')
      .matches(FORM_REGEX.NAME, "Name is invalid")
  }),
  email_address: Yup.string().when('type', {
    is: "human",
    then: Yup.string().required("Email is required").matches(
      FORM_REGEX.EMAIL, "Email is invalid"
    ),
  }),
});

const initialValues = {};

const values_selected_services = {};

let config_questionary_fields = [];

let available_company_types = [];
let available_partnership_types = [];

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 500,
    fontSize: "15px",
    color: "black"
  },
}));

export default function CreateCompanyPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [personnel, setPersonnel] = useState({});
  const [existingPersonnel, setExistingPersonnel] = useState(false)
  const [personnelToChange, setPersonnelToChange] = useState({})
  const [showPersonnelModal, setShowPersonnelModal] = useState(false)
  const [configReady, setConfigReady] = useState();
  const [ownershipTypesAbstractions, setOwnershipTypesAbstractions] = useState([])

  const [currentPage, setCurrentPage] = useState(0);
  const [jurisdictions, setJurisdictions] = useState([])
  const [questionaryAnswers, setQuestionaryAnswers] = useState({
    entity_category: ''
  });
  const [physicalAddressQualifiers, setPhysicalAddressQualifiers] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [staff, setStaff] = useState([]);
  const [staffErrors, setStaffErrors] = React.useState({});
  const [isStaffValid, setIsStaffValid] = React.useState(false);
  const [documents, setDocuments] = useState([])
  const [entityCategories] = useTitles(getEntityCategories)
  const [isPartnership, setIsPartnerShip] = useState([])

  const [entityJurisdictions] = useJurisdictions()

  const [totalServicesPrice, setTotalServicesPrice] = useState(0);

  const [ownershipTypes, visibleFields, currency] = useOwnershipTypes(questionaryAnswers.entity_category)

  const [loading, setLoading] = useState(false);

  const [entityTypes] = useTitlesWithRelations(questionaryAnswers.entity_category, getCompanyTypesRelations, getCompanyTypesTitles)


  const [config_available_services, set_config_available_services] = useState([])

  const [initialModalOpened, setInitialModalOpened] = useState(false)
  const [initialSubscriber, setInitialSubscriber] = useState({
    change_initial_subscriber: false,
    initial_subscriber: null
  })

  const tenant = useSelector(state => state.tenant);
  const slug = tenant?.csp?.tenant?.tenant_slug;

  useEffect(() => {
    if (slug) {
      Axios.get('csp/entity-questionary-config')
        .then(({ data }) => {
          set_config_available_services(data.config.services);
        })
        .catch(() => { });
    }
  }, [slug])

  useEffect(() => {
    setIsPartnerShip(
      questionaryAnswers.entity_category === "partnership" ||
      questionaryAnswers.entity_category === "limited-liability-partnership" ||
      questionaryAnswers.entity_category === "limited-liability-company"
    )

    setOwnershipTypesAbstractions([])
    setShareholders([])
    setStaff([])
  }, [questionaryAnswers.entity_category])

  // ===== Form-related =====
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);

      const formData = new FormData();

      const url_parsed = getURLQueryParams(location.search)

      const directShares = ownershipTypesAbstractions.map(abstraction => {
        const share = {}
        Object.keys(visibleFields).map(key => share[key] = abstraction[key])
        return share
      })

      formData.append('questionary', JSON.stringify(questionaryAnswers))
      formData.append('services', JSON.stringify(values_selected_services))
      formData.append('shares', JSON.stringify(directShares))
      formData.append('shareholders', JSON.stringify(shareholders))
      formData.append('staff', JSON.stringify(staff))
      formData.append('partnership_categories', JSON.stringify([]))
      formData.append('initial_subscriber', JSON.stringify(initialSubscriber))

      documents.forEach(document => {
        formData.append(uniqueId('file_'), document.file)
      })

      if (url_parsed.l) {
        formData.append('referral_code', url_parsed.l)
      }

      // Make a "create company" request
      Axios.post('company/request', formData, {
        headers: {
          'Content-type': "multipart/form-data"
        }
      })
        .then((response) => {
          if (response && response.data && response.data.tenant_slug) {
            redirectToTenant(response.data.tenant_slug, true);
          }

          window.location.href = "/dashboard";
        })
        .catch((response) => {
          if (response && response.data) {
            setStatus(response.data.error_message);
          }
          notify({ type: "ERROR", message: response?.response?.data?.error_message, title: "Error" });
          disableLoading();
          setSubmitting(false);
        });
    },
  });

  // It does not fetch jurisdictions, it fetch list of all countries
  // Jurisdiction - is a limited list of regions, which is selected by CSP Admin
  const getJurisdictions = () => {
    Axios.get('ui/countries')
      .then(({ data }) => {
        setJurisdictions(Object.keys(data.COUNTRIES_TITLES).map(key => ({
          value: key,
          title: data.COUNTRIES_TITLES[key]
        })))
      })
      .catch(() => { });
  }

  const getConfig = () => {
    setCurrentPage(1);
    setConfigReady(true);
  }

  useEffect(() => {
    // On mount
    getJurisdictions();
    getConfig();
  }, []);



  // ===== Navigation =====
  const onPageSubmit = e => {
    e.preventDefault();

    if (currentPage < 6) {
      // We have 5 pages in total
      setCurrentPage(currentPage + 1);
    } else {
      formik.handleSubmit(e);
    }
  }

  const goBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      history.push("/dashboard");
    }
  }

  // ===== Data modification =====
  const onQuestionaryValueChange = e => {
    // TODO @performance
    const new_answers = { ...questionaryAnswers, [e.target.name]: e.target.value };

    setQuestionaryAnswers(new_answers);
  }

  const onStaffInfoChange = (e, index) => {
    const new_staff = staff;

    new_staff[index][e.target.name] = e.target.value;

    // Trick with the spread operator to force the component to re-render
    // TODO @performance this can potentially be SLOW, will have to refactor
    setStaff([...new_staff]);
  }

  const staffValidate = async () => {
    let validation_ok = true;
    const new_errors = {};

    await page5_validation_schema.validate(personnel, { abortEarly: false })

      .catch(validation_errors => {
        console.log(validation_errors)
        validation_ok = false;
        for (const err of validation_errors.inner) {
          new_errors[err.path] = err.message;
        }
      });

    setStaffErrors({ ...new_errors });
    return validation_ok;
  };

  const handleStaffValidationSubmit = async () => {
    const ok = await staffValidate();
    if (ok) {
      setIsStaffValid(true)
      setStaff([...staff, personnel]);
      setPersonnel({})
      setShowPersonnelModal(false)
    } else {
      notify({ type: "ERROR", title: "Validation error", message: "There are some fields that need your attention" })
    }
  }

  const handleStaffEditValidationSubmit = async () => {
    const ok = await staffValidate();
    if (ok) {
      for (let i = 0; i < staff.length; i++) {
        if (staff[i] === personnelToChange) {
          staff[i] = personnel
        }
      }
      setIsStaffValid(true)
      setExistingPersonnel(false)
      setPersonnel({})
      setShowPersonnelModal(false)
    } else {
      notify({ type: "ERROR", title: "Validation error", message: "There are some fields that need your attention" })
    }
  }



  const addStaff = () => {
    handleStaffValidationSubmit().then(() => {
    })
  }

  const editPersonnel = () => {
    handleStaffEditValidationSubmit().then(() => {
    })
  }

  const removeStaff = personnel => {
    // TODO @performance
    for (let n = 0; n < staff.length; n++) {
      if (staff[n] === personnel) {
        staff.splice(n, 1);
        break;
      }
    }
    // Trick with the spread operator to force the component to re-render
    setStaff([...staff]);
  }

  const onServiceSelect = (e, service) => {
    // TODO @performance

    if (e.target.checked) {
      values_selected_services[service.service_id] = service;
    } else {
      delete values_selected_services[service.service_id];
    }

    let total_price = 0;

    // Calculate total price of all the services
    for (const service_name in values_selected_services) {
      total_price += values_selected_services[service_name].service_price;
    }

    setTotalServicesPrice(total_price.toFixed(2));
  }

  return (
    <>
      {
        ['company', 'partnership'].includes(questionaryAnswers.entity_category) &&
        (
          <InitialSubscriberModal
            onSave={value => setInitialSubscriber(value)}
            onClose={() => setInitialModalOpened(false)}
            value={initialSubscriber}
            open={initialModalOpened}
            shareholders={shareholders} />
        )
      }

      <div className="d-flex justify-content-around flex-column-fluid flex-center  bg-white">
        <div className=" col-lg-4 col-md-4 col-sm-4">
          <List>
            <ListItem selected={currentPage === 1}>
              <ListItemAvatar>
                {currentPage === 1 ? (
                  <BusinessIcon className="form-icon" fontSize="large" />
                ) : (<BusinessIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Entity Details
                  </Typography>
                }
                secondary="Choose your entity type" />
            </ListItem>
            <ListItem selected={currentPage === 2}>
              <ListItemAvatar>
                {currentPage === 2 ? (
                  <CategoryOutlinedIcon className="form-icon" fontSize="large" />
                ) : (<CategoryOutlinedIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Ownership Types
                  </Typography>
                }
                secondary="Define share classes, partner types, etc" />
            </ListItem>
            <ListItem selected={currentPage === 3}>
              <ListItemAvatar>
                {currentPage === 3 ? (
                  <DonutSmallOutlinedIcon className="form-icon" fontSize="large" />
                ) : (<DonutSmallOutlinedIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Ownership Distribution
                  </Typography>
                }
                secondary="Identity owners and ownership %" />
            </ListItem>
            <ListItem selected={currentPage === 4}>
              <ListItemAvatar>
                {currentPage === 4 ? (
                  <PermIdentityOutlinedIcon className="form-icon" fontSize="large" />
                ) : (<PermIdentityOutlinedIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Controlling Persons
                  </Typography>
                }
                secondary="Identity directors, officers, gp, etc" />
            </ListItem>
            <ListItem selected={currentPage === 5}>
              <ListItemAvatar>
                {currentPage === 5 ? (
                  <NoteAddOutlinedIcon style={{ color: "#1BC5BD" }} fontSize="large" />
                ) : (<NoteAddOutlinedIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Founding Documents
                  </Typography>
                }
                secondary="Upload your docs or use our templates" />
            </ListItem>
            <ListItem selected={currentPage === 6}>
              <ListItemAvatar>
                {currentPage === 6 ? (
                  <MonetizationOnOutlinedIcon className="form-icon" fontSize="large" />
                ) : (<MonetizationOnOutlinedIcon fontSize="large" />)}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.text}
                    color="red"
                  >
                    Services & Fees
                  </Typography>
                }
                secondary="Select additional services & view costs" />
            </ListItem>
          </List>
        </div>

        <div className="col-lg-6 col-md-4 col-sm-4">
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className='flex-grow-1 d-flex flex-column '>
            <div>
              {currentPage === 0 && (
                <p className="text-center">Please, wait...</p>
              )}

              {/* Page 1 - questionary */}
              {currentPage === 1 && (
                <EntityBasicsPage
                  jurisdictions={entityJurisdictions}
                  {
                  ...{
                    onQuestionaryValueChange,
                    questionaryAnswers,
                    available_company_types,
                    available_partnership_types,
                    configReady,
                    config_questionary_fields,
                    formik,
                    currentPage,
                    setCurrentPage,
                    loading,
                    goBack,
                    physicalAddressQualifiers,
                    setPhysicalAddressQualifiers,
                    entityCategories,
                    entityTypes
                  }
                  }

                />
              )}

              {/* Page 2 - services */}
              {currentPage === 2 && (
                <OwnershipTypesPage
                  abstractions={ownershipTypesAbstractions}
                  setAbstractions={setOwnershipTypesAbstractions}
                  {
                  ...{
                    isPartnership,
                    setCurrentPage,
                    loading,
                    goBack,
                    ownershipTypes,
                    visibleFields,
                    currency
                  }
                  }

                />

              )}

              {/* Page 4 - Shareholders */}
              {currentPage === 3 && (
                <OwnershipDistributionPage
                  sharesInfo={ownershipTypesAbstractions}
                  category={questionaryAnswers.entity_category}
                  setInitialModalOpened={setInitialModalOpened}
                  initialSubscriber={initialSubscriber}
                  {
                  ...  {
                    shareholders,
                    setShareholders,
                    currentPage,
                    setCurrentPage,
                    loading,
                    goBack,
                    jurisdictions
                  }
                  }
                />
              )}

              {/* Page 4 - Shareholders */}
              {currentPage === 4 && (
                <ControllingPersonsPage
                  entityCategory={questionaryAnswers.entity_category}
                  {
                  ...  {
                    isPartnership,
                    staff,
                    removeStaff,
                    onStaffInfoChange,
                    addStaff,
                    personnel,
                    setPersonnel,
                    existingPersonnel,
                    setExistingPersonnel,
                    showPersonnelModal,
                    setShowPersonnelModal,
                    personnelToChange,
                    setPersonnelToChange,
                    editPersonnel,
                    currentPage,
                    setCurrentPage,
                    loading,
                    goBack,
                    staffErrors,
                    setStaffErrors,
                    isStaffValid,
                    setIsStaffValid,
                    jurisdictions,
                    setStaff
                  }
                  }
                />
              )}
              {/* Page 6 - Documents */}
              {
                currentPage === 5 && (
                  <DocumentsPage
                    {
                    ...  {
                      currentPage,
                      setCurrentPage,
                      loading,
                      goBack,
                    }
                    }
                    documents={documents}
                    onDocumentsChange={docs => setDocuments(docs)}
                  />
                )
              }
              {/* Page 5 - Directors & Officers */}
              {currentPage === 6 && (
                <ServicePage
                  {
                  ...  {
                    configReady,
                    config_available_services,
                    formik,
                    values_selected_services,
                    totalServicesPrice,
                    onServiceSelect,
                    currentPage,
                    setCurrentPage,
                    loading,
                    goBack,
                    onPageSubmit
                  }
                  }
                />
              )}


            </div>
          </div>
        </div>

      </div>
    </>
  )
}
