import React, {useEffect, useState} from 'react';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import JoditEditor from 'jodit-react';
import { Autocomplete } from '@material-ui/lab';
import './Basic.css';
import {
  Button,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {createEventObject} from './Auxillary';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function FormTextarea(props) {
  return (
    <div>
      {props['w-title'] && <div className="mb-2 mt-5">{props['w-title'] !== true ? props['w-title'] : props.placeholder}</div>}
      <textarea rows="4" className={`form-control h-auto py-5 px-6${!props['w-input'] ? ' mt-3' : ''}`} {...props} />
      {props.description && <div className="text-muted font-weight-light mt-1">{props.description}</div>}
    </div>
  );
}

export function FormInput({ className, value, mui, ...props }) {
  if (mui) {
    return (
      <TextField
        variant="outlined"
        autoComplete="off"
        value={value ?? ''}
        error={!!props.error}
        helperText={props.helperText}
        className={className || 'w-100 my-3'}
        {...props}
      />
    );
  }
  const { type, dateFormat = 'DD MMM YYYY' } = props;
  if (type === 'date')
    return (
      <div className={className || ''}>
        {props['w-title'] && <div className="mb-0 mt-5">{props['w-title'] !== true ? props['w-title'] : props.placeholder}</div>}

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            className="mt-0 mb-0"
            inputVariant="outlined"
            format={dateFormat}
            maxDate={props.maxDate}
            minDate={props.minDate}
            margin="normal"
            value={value || null}
            onChange={e =>
              props.onChange({
                target: {
                  name: props.name,
                  value: new Date(e)
                }
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );

  return (
    <div className={className || ''}>
      {props['w-title'] && <div className="mb-2 mt-5">{props['w-title'] !== true ? props['w-title'] : props.placeholder}</div>}
      <TextField
        error={!!props.error}
        className="mt-0 mb-0"
        value={value ?? ''}
        margin="normal"
        variant="outlined"
        multiline={!!props.multiline}
        {...props}
      />
      {props.description && <div className="text-muted font-weight-light mt-1">{props.description}</div>}
    </div>
  );
}

export function FormSelect({ mui, error, helperText, ...props }) {
  const errorStyle = { borderColor: '#f018a6', padding: '18.5px 14px' };
  const helperTextStyle = { fontSize: '0.75rem', color: '#f018a6', marginLeft: 14, marginTop: 3 };
  if (mui) {
    const options = props.options || [];
    const toLowerCase = !!props.toLowerCase;
    const convert = str => (toLowerCase ? str.toLowerCase() : str);
    return (
      <FormControl variant="outlined" className={props.className || 'w-100 my-3'}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          defaultValue=""
          autoComplete="off"
          label={props.label}
          name={props.name}
          value={props.value ?? ''}
          onChange={props.onChange}
          error={!!error}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {!props.children
            ? options.map((opt, i) => (
                <MenuItem value={opt.value ? convert(opt.value) : convert(opt)} key={i}>
                  {opt.name ? opt.name : opt}
                </MenuItem>
              ))
            : props.children}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
  return (
    <div>
      {props['w-title'] && <div className="mb-2 mt-5">{props['w-title']}</div>}
      <select {...props} value={props.value || ''} style={error ? errorStyle : null} className="form-control h-auto py-4 px-6">
        {props.children}
      </select>
      {error && helperText && <div style={helperTextStyle}>{helperText}</div>}
    </div>
  );
}

export function FormCheckbox(props) {
  return (
    <div>
      {props['w-title'] && <div className="mb-2 mt-5">{props['w-title']}</div>}
      <label className="checkbox">
        <input className="check" data-date-format="yyyy/mm/dd" {...props} type="checkbox" />

        <span />

        <a className="ml-2 my-1 text-dark-75">{props.text}</a>
      </label>
    </div>
  );
}

export function DateRange({ startDate, endDate, onApply }) {
  const [formattedDateSettings, setFormattedDateSettings] = useState('Select range');

  useEffect(() => {
    setFormattedDateSettings(startDate.format('DD MMM YYYY') + ' - ' + endDate.date_to.format('DD MMM YYYY'));
  }, [startDate, endDate]);

  return (
    <DateRangePicker
      onApply={(e, picker) => {
        onApply(picker.startDate, picker.endDate);
      }}
      opens="left"
      locale={{
        format: 'DD-MM-YYYY',
        applyLabel: 'ok',
        customRangeLabel: 'user define'
      }}
    >
      <input type="text" value={formattedDateSettings} className="form-control col-lg-2 mr-5" />
    </DateRangePicker>
  );
}

export function FormAdornment(props) {
  const color = props.color ? props.color(props.value) : '';
  const appendClass = props.appendClass ? props.appendClass(props.value) : '';
  const container = {
    minWidth: 100,
    maxWidth: 154
  };
  const span = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: 8,
    color: color
  };
  return (
    <div className={props.className} style={container}>
      <InputLabel style={{ fontWeight: 600, fontSize: 10 }}>{props.label}</InputLabel>
      <div className={'d-flex align-items-center'}>
        {props.children}
        {props.tooltip ? (
          <Tooltip title={<div style={{ fontSize: 14, padding: 4 }}>{props.value}</div>} placement="top">
            <span className={appendClass} style={span}>
              {props.value}
            </span>
          </Tooltip>
        ) : (
          <span className={appendClass} style={span}>
            {props.value}
          </span>
        )}
      </div>
    </div>
  );
}

export function FormRichText(props) {
  const buttons = ['ul', 'ol', 'eraser', '|', 'link'];
  const config = {
    readonly: false,
    toolbar: true,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarInlineForSelection: false,
    statusbar: false,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    buttons
  };
  return (
    <div>
      {props['w-title'] && <div className="mb-2 mt-5">{props['w-title'] !== true ? props['w-title'] : props.placeholder}</div>}
      <JoditEditor config={config} tabIndex={1} {...props} />
      {props.description && <div className="text-muted font-weight-light mt-1">{props.description}</div>}
    </div>
  );
}

export function CustomDatePicker({ className, ...props }) {
  return (
    <FormControl className={className || 'w-100 my-3'}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          invalidDateMessage="Invalid date"
          inputVariant="outlined"
          format={props.format || 'DD/MM/YYYY'}
          value={props.value || null}
          InputAdornmentProps={{ position: "end" }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export function SearchField({ className, options, label, error, helperText, ...props }) {
  const onChange = (e, value) => props.onChange(createEventObject(e, props.name, value), value)
  return (
    <FormControl className={className || 'w-100 my-3'}>
      <Autocomplete
        defaultValue={props.value || ''}
        onChange={onChange}
        value={props.value}
        onBlur={props.onBlur}
        options={options || []}
        getOptionLabel={option => option}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={!!error}
            InputProps={{ ...params.InputProps, readOnly: props.readOnly }}
            helperText={helperText}
          />
        )}
      />
    </FormControl>
  );
}

export function FormSwitch(props) {
  return (
    <FormGroup className={props.className}>
      <FormControlLabel
        control={<Switch name={props.name} checked={props.value} onChange={props.onChange} />}
        label={props.label || ''}
      />
    </FormGroup>
  );
}

export function FormDivider({ className, ...props }) {
  return (
    <div className={className || 'my-12'}>
      <Divider {...props} />
    </div>
  );
}

export function UploadButton({ className, ...props }) {
  return (
    <button
      {...props}
      className={className || 'btn btn-primary font-weight-bold px-9 py-4 my-3 w-100'}
      type={props.type || 'button'}
      onClick={props.onClick}
    >
      {props.label || 'Add document photos/scans'}
      <CloudUploadIcon className="ml-2" />
    </button>
  );
}

export function MultipleSelectChip(props) {
  return (
    <FormControl className={props.className || 'w-100 my-3'}>
      <Autocomplete
        multiple
        defaultValue={props.value || []}
        options={props.options || []}
        filterSelectedOptions
        getOptionLabel={option => option}
        onChange={props.onChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={props.label}
            name={props.name.toString()}
            error={!!props.error}
            onBlur={props.onBlur}
            helperText={props.helperText}
          />
        )}
      />
    </FormControl>
  );
}

export function FormButton({ label, helperText, className, formClassName, appendClass, children, ...props }) {
  return (
    <>
      {helperText ? (
        <FormControl className={formClassName || 'w-100'}>
          <Button
            {...props}
            variant={props.variant || 'contained'}
            style={{ textTransform: 'capitalize', ...props.style }}
            className={className || `font-size-h6 font-weight-bold px-9 py-4 w-100 ${appendClass}`}
          >
            <span>{label}</span>
            {children}
          </Button>
          {helperText && (
            <FormHelperText style={{ color: props.error ? 'red' : '' }} className="ml-2">
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <Button
          variant={props.variant || 'contained'}
          style={{ textTransform: 'capitalize' }}
          className={className || 'font-size-h6 font-weight-bold px-9 py-4 w-100'}
          {...props}
        >
          <span>{label}</span>
          {children}
        </Button>
      )}
    </>
  );
}


export function FormFab({color, className, children, ...props}) {
	return (
		<Fab color={props.color || 'secondary'} className={className} {...props}>
			{children}
		</Fab>
	);
}

export function FormSpacer({style}) {
	return (
		<div style={{style}}/>
	);
}

export function ToolTip({title, placement, children, show}){
  return (show && children) ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={title}>{title}</Tooltip>
      }
    >{children}</OverlayTrigger>
  ) : children
}

export function BrowseFile(props) {
  const id = props.id || 'customFile';
  return (
    <div className="custom-file">
      <input
        id={id}
        type="file"
        className="custom-file-input"
        multiple={props.multiple}
        onChange={props.onChange}
        ref={props.ref}
      />
      <label className="custom-file-label" htmlFor={id} onClick={props.onClick}>
        {props.title || 'Select a file'}
      </label>
    </div>
  );
}


