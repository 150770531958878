import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Menu } from '@material-ui/core';

export default function CustomDropdown({children}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <div>
      <Dropdown.Toggle className='w-100' onClick={handleClick}
       >
        Show Items
      </Dropdown.Toggle>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="simple-menu"
        // className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
}