import React, { useState } from "react";
import SaveButton from "../../../components/_common/SaveButton";
import ActionsDropdown from "../../../components/Dropdown/ActionsDropdown";
import ResidentialAddressModal from "../components/ResidentialAddressModal";
import useRequest from "../../../components/useRequest/useRequest";
import { deleteUserAddress, getAnotherResidentials, getUserAddresses, saveAnotherResidential, saveUserAddress } from "../../../api/user";
import { useEffect } from "react";
import AddressAbstractionComponent from "../components/AddressAbstractionComponent";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import moment from "moment";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import * as yup from 'yup'
import { simpleValidate } from '../../../helpers/validation'
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import { MenuItem, TextField } from "@material-ui/core";
import useCountries from "../../../hooks/useCountries";
import { Autocomplete } from "@material-ui/lab";

const modalDefaultValue = {
  address_type: '',
  is_primary: false,
  address_location: '',
  from: null,
  to: null,
  document: '',
  address_quantifiers: []
}

const defaultValue = {
  address_type: 'residential',
  is_primary: false,
  address_location: '',
  from: null,
  to: null,
  document: '',
}

export const validationSchema = {
  address_type: () => yup.string().required(),
  address_location: (ctx) => yup.string().required(),
  from: (ctx) => ctx.address_type === 'residential' && ({
    validate(value) {
      if (!value) throw new Error('Invalid data')
      if (value > Date.now()) throw new Error('Cannot be in future')
    }
  }),
  to: ctx => ctx.address_type === 'residential' && !ctx.is_primary && yup.date().required(),
  document: ctx => ctx.address_type === 'residential' && ctx.is_primary && !ctx.document_id && ({
    validate(value) {
      if (!(value instanceof File))
        throw new Error('File is required')
    }
  })
}

export default function AddressRoute({ user_id }) {
  const [residentialOpened, setResidentialOpened] = useState(false)
  const dropdownOpts = [
    {
      id: 1,
      title: 'Add new residential address',
      onClick: () => {
        setResidentialOpened(true)
      }
    }
  ]

  const [history, setHistory] = useState([])
  const [others, setOthers] = useState([])

  const [fetchAddresses] = useRequest(() => getUserAddresses(user_id), (data, refresh) => {
    if (data) {
      (data.primary_addresses[0] || refresh) && setCurrentAddress(data.primary_addresses[0] || { ...defaultValue })
      setHistory(data.other_residential)
      setOthers(data.other_addresses)
    }

  })

  useEffect(() => {
    fetchAddresses()
    fetchAnotherResidential()
  }, [])


  const [currentAddress, setCurrentAddress] = useState({ ...defaultValue })
  const [errors, setErrors] = useState({})

  const [save, loading] = useRequest(async () => {
    if (await simpleValidate(validationSchema, currentAddress, setErrors)) {
      await saveUserAddress(currentAddress, user_id)
      await fetchAddresses(true)
      notify({
        type: 'SUCCESS',
        title: 'Changes saved!'
      })
    }
  })

  async function submitSavingAddress(address, setErrors) {
    if (await simpleValidate(validationSchema, address, setErrors)) {
      await saveUserAddress(address, user_id)
      fetchAddresses()
      notify({
        type: 'SUCCESS',
        title: 'Changes saved!'
      })
    } else {
      throw new Error('Cannot save')
    }
  }

  const residentialCols = [
    {
      name: 'address_location',
      title: 'Location'
    },
    {
      name: 'from',
      title: 'From',
      component: (row) => {
        return moment(row.from).format('YYYY')
      }
    },
    {
      name: 'to',
      title: 'To',
      component: (row) => {
        return moment(row.to).format('YYYY')
      }
    },
    {
      name: 'actions',
      title: '',
      width: 30,
      component: (row) => (<MoreDetailsDropdown options={[
        {
          name: 'edit',
          title: 'Edit',
          onClick: () => {
            setEditing(row)
            setResidentialOpened(true)
          }
        },
        {
          name: 'delete',
          title: 'Delete',
          onClick: async () => {
            await deleteUserAddress(row.id, user_id)
            await fetchAddresses()
            notify({
              type: 'SUCCESS',
              title: 'DELETED'
            })
          }
        }
      ]} />)
    }
  ]

  const otherCols = [
    {
      name: 'address_type',
      title: 'Type',
      component: (row) => (<span className="text-capitalize">{row.address_type}</span>)
    },
    {
      name: 'address_location',
      title: 'Location'
    },
    {
      name: 'actions',
      title: '',
      width: 30,
      component: (row) => (<MoreDetailsDropdown options={[
        {
          name: 'edit',
          title: 'Edit',
          onClick: () => {
            setEditing(row)
            setResidentialOpened(true)
          }
        },
        {
          name: 'delete',
          title: 'Delete',
          onClick: async () => {
            await deleteUserAddress(row.id, user_id)
            await fetchAddresses()
            notify({
              type: 'SUCCESS',
              title: 'DELETED'
            })
          }
        }
      ]} />)
    }
  ]

  const [editing, setEditing] = useState(modalDefaultValue)

  const [countries] = useCountries()
  const [selectedCountries, setSelectedCountries] = useState([])
  const [anotherResidential, setAnotherResidential] = useState([])

  const [submitAnotherResidential, loadingAnotherResidential] = useRequest(() => saveAnotherResidential(anotherResidential, user_id))
  const [fetchAnotherResidential] = useRequest(() => getAnotherResidentials(user_id), data => {
    if (data) setAnotherResidential(data)
  })

  useEffect(() => {
    setSelectedCountries(countries.filter(c => anotherResidential.includes(c.value)))
  }, [anotherResidential, countries])

  return (
    <>
      <ResidentialAddressModal
        submitSavingAddress={submitSavingAddress}
        defaultValue={modalDefaultValue}
        fetchAddresses={fetchAddresses}
        user_id={user_id}
        currentAddress={editing}
        setCurrentAddress={setEditing}
        open={residentialOpened}
        onClose={() => setResidentialOpened(false)}
      />
      <div className="col-lg-12">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title w-25">Current residential address</div>

            <div className="card-toolbar">
              <ActionsDropdown options={dropdownOpts} />
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <AddressAbstractionComponent errors={errors} disabledType currentAddress={currentAddress} setCurrentAddress={setCurrentAddress} defaultValue={defaultValue} />
              </div>
              <div className="col-lg-3"></div>
            </div>

          </div>

          <div className="card-footer d-flex justify-content-end">
            <SaveButton onClick={save} loading={loading} />
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title w-25">
              Residential History
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <Table nonSelectable cols={residentialCols} entities={history} />
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title w-25">Other Addresses</div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <Table nonSelectable cols={otherCols} entities={others} />
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-body">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-7">
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center">
                      Other than the locations above, are there other countries where you regularly operate? (Select any that apply)
                    </label>
                    <div className="col-9">

                      <Autocomplete
                        value={selectedCountries}
                        onChange={(_, opts) => { setAnotherResidential(opts.map(o => o.value)) }}
                        multiple
                        options={countries}
                        getOptionLabel={o => o.title}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                          />)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <SaveButton onClick={submitAnotherResidential} loading={loadingAnotherResidential} />
          </div>
        </div>
      </div>
    </>
  )
}
