import React, { useEffect, useMemo, useState } from 'react'
import OwnershipSummaryWidget from '../../../components/EPOwnershipSummaryRoute/OwnershipSummaryWidget'
import PieChart from '../../../components/PieChart/PieChart'
import { SimpleStatWidget2, SimpleStatWidget4, SimpleStatWidget5 } from '../../../components/SimpleStatWidgets/SimpleStatWidgets'
import { WidgetTable } from '../../../components/WidgetTable/WidgetTable'

const cols = [
  {
    name: 'transaction_type',
    title: 'Action',
    sortable: true
  },
  {
    name: '_from',
    title: 'From'
  },
  {
    name: '_to',
    title: 'To'
  },
  {
    name: 'transaction_number_of_shares',
    title: 'Transaction size'
  },
  {
    name: 'transaction_status',
    title: 'Status'
  },
  {
    name: '_completed_on',
    title: 'Completed on'
  }
]

const formatter = Intl.NumberFormat("en-US");

export default function SummaryOwnershipRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  useEffect(() => {
    if(props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      if(props.basicEntityInfo.company_category === "partnership") {
        props.queryAdditionalInfo("partnership_cap_table");
      } else {
        props.queryAdditionalInfo("shares");
      }

      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);

  const pie_chart_config = useMemo(() => {
    const series = [];
    const labels = [];
    
    if(props.additionalEntityInfo.share_classes) {
      for(const share_class of props.additionalEntityInfo.share_classes) {
        series.push(share_class.share_authorized_shares);
        labels.push(share_class.share_name);
      }
    }

    return { series, labels };
  }, [props.additionalEntityInfo.share_classes]);

  return (
    <>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4">
            <SimpleStatWidget2
              title={
                props.basicEntityInfo.company_category !== "partnership"
                ? (props.additionalEntityInfo.cap_table && props.additionalEntityInfo.cap_table.length)
                : (props.additionalEntityInfo.partnership_cap_table && props.additionalEntityInfo.partnership_cap_table.length)}
              subtitle={ props.basicEntityInfo.company_category === "partnership" ? "Partners" : "Owners" }
            />
          </div>
          <div className="col-lg-4">
            <SimpleStatWidget5
              title={props.basicEntityInfo.company_category === "partnership" ? "—" : (props.additionalEntityInfo.total_shares_number ? formatter.format(props.additionalEntityInfo.total_shares_number) : "N/A")}
              subtitle='Equity Issued'
            />
          </div>
          <div className="col-lg-4">
            <SimpleStatWidget4
              title={props.basicEntityInfo.company_category === "partnership" ? "—" : `${ props.additionalEntityInfo.total_shares_currency || "" } ${ props.additionalEntityInfo.total_shares_price ? formatter.format(props.additionalEntityInfo.total_shares_price) : "N/A" }`}
              subtitle='Capital Committed'
            />
          </div>
        </div>
      </div>
      
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-stretch">
            <OwnershipSummaryWidget rows={
              props.additionalEntityInfo.all_partners_sorted_short ||
              props.additionalEntityInfo.all_shareholders_sorted_short
            } />
          </div>

          <div className="col-lg-6">
            <PieChart title='Equity by Ownership Type'
              series={ pie_chart_config.series }
              labels={ pie_chart_config.labels }
            />
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
              { (props.additionalEntityInfo && props.additionalEntityInfo.share_transactions) && (
                <WidgetTable
                  title='Recent transactions'
                  rows={props.additionalEntityInfo.share_transactions}
                  cols={cols}
                />
              ) }
          </div>
        </div>
        
      </div>
    </>
  )
}
