import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import useAuth from './components/useAuth/useAuth'
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, LOGGED_IN, NOT_LOGGED_IN, USER_NOT_CHECKED, AWAITING_CSP } from "./modules/Auth";
import InvitePage from './pages/InvitePage';
import CreateCSPPage from './pages/CreateCSPPage';
import CreateCompanyPage from "./pages/create-company-page/CreateCompanyPage";
import StaffOnboardingPage from "./pages/StaffOnboarding/StaffOnboardingPage";
import EDDPage from "./pages/EDD/EDDPage";
import { putPathToQuery } from '../redux/Url/urlActions';
import { useDispatch, useSelector } from 'react-redux';
import AwaitingCSPPage from './pages/AwaitingCSPPage';
import { setMainAsideVisibility } from '../redux/Styles/styleActions';
import EntityDueDiligencePage from './pages/entity-due-diligence/entity-due-diligence';
import Axios from 'axios';
import { setAvailableCSPs, setCurrentCSP, setTenantSlug } from '../redux/Tenant/tenantActions';
import { getUserNotificationsCount } from '../redux/UserNotifications/userNotificationsActions';

function useTenant() {
  const dispatch = useDispatch();

  const tenantState = useSelector(state => state.tenant)
  const getCSPs = () => {
    return Axios.get('csp/my')
      .then(({ data }) => {
        dispatch(setAvailableCSPs(data.csp_companies))
        setTenant()
      })
      .catch((e) => {
        console.error('An error occurred while trying to get current csps: ' + e);
      });
  }

  const setTenant = () => {
    if (!localStorage.getItem("tenant")) {
      if (tenantState.availableCSPs[0]) {
        const tenant_slug = tenantState.availableCSPs[0].tenant.tenant_slug;
        localStorage.setItem("tenant", tenant_slug)
        dispatch(setTenantSlug(0, tenant_slug))
        dispatch(setCurrentCSP(tenantState.availableCSPs[0]))
      } 
    } else {
      const current_tenant = localStorage.getItem("tenant")
      const current_csp = tenantState.availableCSPs.find(csp => csp.tenant.tenant_slug === current_tenant)
      if (current_tenant) dispatch(setTenantSlug(0, current_tenant))
      if (current_csp) dispatch(setCurrentCSP(current_csp))
    }
  }

  useEffect(() => {
    getCSPs()
  }, [])

  useEffect(() => {
    setTenant()
  }, [tenantState.availableCSPs])
}

const LoggedInRoute = ({path}) => {
  useTenant()
  const location = useLocation()

  useEffect(() => {
    getUserNotificationsCount()
  }, [location.pathname])

  useEffect(() => {
    putPathToQuery(null)
  }, [])

  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    // TODO!!! @placeholder
    // setMainAsideVisibility(!!user?.user_csp_role || !!user?.global_user_role)
    setMainAsideVisibility(true);
  }, [user]);

  return (
    <Switch>
      {!!path && <Redirect to={path} />}
      <Route path="/logout" component={Logout} />
      <Route path="/invite/:code" component={InvitePage} />
      <Route path="/create-csp/:csp_id" component={CreateCSPPage} />
      <Route path="/entity-formation" component={CreateCompanyPage} />
      <Route path="/staff-onboarding" component={StaffOnboardingPage} />
      <Route path="/edd" component={EDDPage} />
      <Route path="/entity-due-diligence" component={EntityDueDiligencePage} />
      <Layout>
        <BasePage />
      </Layout>
    </Switch>
  );
}

export default function RoutesNext() {
  const auth = useAuth()
  const location = useLocation()
  const url = useSelector(state => state.url)

  useEffect(() => {
    const path = location.pathname.startsWith('/auth') ? '' : (location.pathname + location.search)

    if (!url.path && path) putPathToQuery(path)
  }, [])

  switch (auth) {
    case LOGGED_IN: {
      const path = url.path 
      return (
        <LoggedInRoute path={path}/>
      )
    }
    case NOT_LOGGED_IN : {
       
      return (
        <Switch>
          <Route>
            <AuthPage/>
          </Route>
        </Switch>
      )
    }
    case USER_NOT_CHECKED : {
      return 'Please wait'
    }
    case AWAITING_CSP : {
      return (
        <AwaitingCSPPage />
      )
    }
    default: return 'Please wait'
  }
}