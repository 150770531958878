import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import Axios from 'axios';
import React from 'react'
import { useState } from 'react';
import useRequest from '../useRequest/useRequest';
import SaveButton from './SaveButton'
import { useTable } from '../../hooks/useTable'
import { availableQualifiers } from '../AddressForm/Qualifiers'
import { useEffect } from 'react';

const searchLocation = async (location) => {
  const { data } = await Axios.get(`user/find-location?query=${location}`);
  if (data?.suggestions) {
    return data.suggestions;
  }
};

const getPlaceQualifiers = place_id => {
  return Axios.get(`user/get-place-qualifiers?place_id=${place_id}`)
    .then(({ data }) => {
      if (data.qualifiers) {
        return Object.keys(data.qualifiers).map(key => {
          return ({
            qualifier_name: key,
            qualifier_value: data.qualifiers[key]
          })
        })
      }
    })
};


export default function AddressFormNext({
  location = '',
  setLocation = () => {},
  qualifiers = [],
  setQualifiers = () => {},
  setGooglePlaceId = () => {},
  locationError = ''
}) {
  const [suggestions, setSuggestions] = useState([])
  const [fetchLocations] = useRequest(() => searchLocation(location), setSuggestions)
  const [fetchQualifiers] = useRequest((place_id) => getPlaceQualifiers(place_id), data => {
    if (data) setQualifiers([...qualifiers, ...data])
  })  

  const tableOpts = [
    {
      id: 1,
      name: 'qualifier_name',
      title: 'Name',
      component: (row) => availableQualifiers[row.qualifier_name]
    },
    {
      id: 2,
      name: 'qualifier_value',
      title: 'Value'
    }
  ]

  const formOpts = [
    {
      id: 1,
      name: 'qualifier_name',
      title: 'Name',
      type: 'select',
      unique: true,
      restricted: qualifiers.map(q => q.qualifier_name),
      options: Object.keys(availableQualifiers).map(key => ({
        value: key,
        title: availableQualifiers[key]
      }))
    },
    {
      id: 2,
      name: 'qualifier_value',
      title: 'Value',
      type: 'text'
    }
  ]

  const [table] = useTable({
    cols: tableOpts,
    options: formOpts,
    abstractions: qualifiers,
    setAbstractions: (setStateCallback) => {
      const returnValue = setStateCallback(qualifiers)

      setQualifiers(returnValue)},
    modalTitle: 'Qualifier',
    cardTitle: 'Qualifiers',
    defaultValue: {
      qualifier_name: '',
      qualifier_value: ''
    }
  })

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <TextField
            value={location}
            onChange={e => setLocation(e.target.value)}
            variant='outlined'
            fullWidth
            error={locationError}
            helperText={locationError}
            label="Search for a place"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={() => fetchLocations(location)} >
                    <Search />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="suggestions-container mt-4 p-2 ">
            {suggestions.map(({ description, place_id }, key) => (
              <div
                key={key}
                className="btn btn-hover-bg-light p-4 suggestion text-left w-100"
                onClick={() => {
                  setLocation(description)
                  fetchQualifiers(place_id)
                  setGooglePlaceId(place_id)
                  setSuggestions([])
                }}
              >
                <div className="title">{description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
            {
              table
            }
        </div>
      </div>
    </>
  )
}