
import React from 'react'
import useQueryParams from '../../hooks/useQueryParams'
import EDDContent from './EDD-content'

export default function EntityDueDiligencePage() {
  const { entity_id, csp_id } = useQueryParams()

  return (
    <>
      <EDDContent {...{ entity_id, csp_id, className : "container h-100" }} />
    </>
  )
}