import { IconButton, TextField, MenuItem} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { uniqueId } from 'lodash-es';
import FileBox from '../FileBox';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';
import {BrowseFile} from '../Basic';

export default function UploadFilesModal(
  {
    description,
    multiple,
    uploadButtonClass,
    onSave = () => Promise.resolve(),
    title = 'Modal title',
    uploadButtonTitle = 'Upload',
    inputTitle = 'Select files',
    uploadButtonRef=null,
    notificationTitle = 'Success',
    notificationMessage = 'Successful load',
    fileName=null, onFileNameChange = () => {},
    editable = false, editFileFields=[],
    selectedFile=null,
    onClose=() => {},
  }) {

  const [modalOpened, setModalOpened] = useState(false)
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)

  const fileRef = useRef(null)


  useEffect(() => {
    if (!!selectedFile) {
      setModalOpened(true);
      setDocuments(prev => [...prev, selectedFile])
    }
  }, [selectedFile])

  const closeModal = () => {
    setModalOpened(false)
    setDocuments([])
    onClose()
  }

  const addDocumentsClick = () => {
    fileRef.current.click();
  };

  const fileInputChange = e => {
    const files = e.target.files;

    const newFiles = Array.prototype.slice.call(files);
    
    const newDocuments = newFiles.map(file => ({
      file,
      document_id: uniqueId(),
      document_mime_type: file.type,
      document_title: file.name.substr(0, file.name.lastIndexOf('.')) || file.name
    }))

    if (editable) {
      onFileNameChange(newFiles[0].name.split('.')[0])
    }

    setDocuments([...newDocuments])
  };

  const onSaveClick = (e) => {
    (async () => {
      try {
        setLoading(true)
        await onSave(e, documents);
        closeModal(e)
        notify({type: 'SUCCESS', title: notificationTitle, message: notificationMessage})
      } catch (error) {
        
      } finally {
        setLoading(false)
      }
      
    })();
  }

  const uploadButtonClick = () => {
    setModalOpened(true)
  }

  const onDocumentsPropertiesChange = (value, index, name) => {
    setDocuments(oldDocuments => {
      oldDocuments[index][name] = value;

      return [...oldDocuments]
    })
  }

  return (
    <>
      <Button
        style={{display: uploadButtonRef && 'none'}}
        ref={uploadButtonRef}
        onClick={uploadButtonClick}
        className={uploadButtonClass}
      >
        {uploadButtonTitle}
        <CloudUploadIcon className="ml-2" />
      </Button>

      <Modal
        show={modalOpened}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <IconButton>
            <CloseIcon onClick={closeModal}/>
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {
            description && (<>
              <div className="col-lg-12">
                {
                  description
                }
              </div>
            </>)
          }
          <div className="col-lg-12 gutter-b">
              <div className="row">
              {
                documents.map(
                  document => (
                    <div className="col-lg-3 file-card" key={document.document_id}>
                      <FileBox
                        document={document}
                      />
                    </div>
                  )
                )
              }
            </div>
          </div>

        <div className="col-lg-12 w-30">
          <BrowseFile
            multiple={multiple}
            ref={fileRef}
            onChange={fileInputChange}
            onClick={addDocumentsClick}
            title={inputTitle}
          />
        </div>

        {
          editable === true && (
          <div className="col-lg-12 gutter-t">
            <TextField
              value={fileName}
              onChange={e => onFileNameChange(e.target.value)}
              name="template_name"
              className="w-100"
              variant="outlined"
              label='File name'
            />
          </div>
          )
        }

        {
          documents.map((document, index) => {
            return editFileFields.map(({name, title, type, options}) => {
              switch (type) {
                case 'text': {
                  return (
                    <div key={index+name} className="col-lg-12 gutter-t">
                      <TextField
                        value={document[name]}
                        onChange={e => onDocumentsPropertiesChange(e.target.value, index, name)}
                        name={name}
                        className="w-100"
                        variant="outlined"
                        label={title}
                      />
                    </div>
                  )
                }
                case 'select': {
                  return (
                    <div key={index+name} className="col-lg-12 gutter-t">
                      <TextField
                        select
                        value={document[name]}
                        onChange={e => onDocumentsPropertiesChange(e.target.value, index, name)}
                        name={name}
                        className="w-100"
                        variant="outlined"
                        label={title}>
                          {options && options().map((option) => {
                            return (<MenuItem key={option.value} value={option.value}>
                              {option.title}
                            </MenuItem>)
                          })}
                        </TextField>
                    </div>
                  )
                }
              }
            })
          })
        }

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="btn-secondary">
            Cancel
          </Button>
          <Button disabled={!documents[0] || loading} onClick={onSaveClick} className="d-flex align-items-center">
            {loading && (<span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/>)}
            {!loading && (<SaveIcon className="mr-2" />)}
            {!loading ? 'Save' : 'Saving...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}