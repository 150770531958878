import React, {  } from "react";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import BaseFieldsConfiguration from "../../../components/EditProfileForm/BaseFieldsConfiguration";
import AddIcon from '@material-ui/icons/Add';
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable';
import SimpleModal from "../../../components/Modals/SimpleModal";
import useForm from "../../../hooks/useForm";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";

export default function LicensesSection({ licenses, setLicenses, countries }) {
  const defaultLicenseValue = {
    jurisdiction: "",
    regulator_name: '',
    license_type: '',
    license_number: '',
  }
  const [
    current,
    modalOpened,
    setModalOpened,
    errors,
    editField,
    addAbstraction,
    editAbstraction,
    deleteAbstraction,
    saveAbstraction
  ] = useForm(licenses, setLicenses, defaultLicenseValue, () => {
    return true
  })

  const licensesOptions = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction',
      type: 'country',
      options: countries
    },
    {
      name: 'regulator_name',
      title: 'Regulator Name',
      type: 'text'
    },
    {
      name: 'license_type',
      title: 'License Type',
      type: 'text'
    },
    {
      name: 'license_number',
      title: 'License Number',
      type: 'text'
    }
  ]

  const tableCols = [
    {
      name: 'regulator_name',
      title: 'Regulatory name',
    },
    {
      name: 'jurisdiction',
      title: 'Jurisdiction',
      extraData: countries,
      component: (row, _, extraData) => {
        const country = extraData.find(({value}) => value === row['jurisdiction'])

        if (country) {
          return country.title
        }

        return null
      }
    },
    {
      name: 'license_type',
      title: 'License Type'
    },
    {
      name: 'actions',
      component: (row, rowIndex) => (<MoreDetailsDropdown options={
        [
          {
            id: 0,
            title: 'Edit',
            onClick: () => editAbstraction(rowIndex)
          },
          {
            id: 1,
            title: 'Remove',
            onClick: () => deleteAbstraction(rowIndex)
          }
        ]
      }/>)
    }
  ]
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <h3>
          Other Regulatory Licences
        </h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SimpleModal onClose={() => setModalOpened(false)} title={'Add license'} open={modalOpened} onSave={saveAbstraction}>
          {
            licensesOptions.map(option => (<BaseFieldsConfiguration
              editTextFieldValue={(value, fieldName) => { editField(fieldName, value) }}
              option={option}
              value={current} />))
          }
        </SimpleModal>
        <div className="col-2" />
        <div className="card card-custom shadow-none col-8">
          <div className="card-header p-0 border-0">
            <div className="card-title">
              Licenses
            </div>
            <div className="card-toolbar">
              <button onClick={addAbstraction} className="btn btn-primary rounded-circle btn-circle-40">
                <AddIcon />
              </button>

            </div>

          </div>
          <div className="card-body p-0">
            <Table nonSelectable cols={tableCols} entities={licenses} />
          </div>
        </div>
        <div className="col-2" />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}