import { useEffect, useState } from "react";

export default function useUnsaved({ ended = false }, deps = []) {
  const [unsaved, setUnsaved] = useState(false)

  useEffect(() => {
    if (ended) {
      setUnsaved(true)
    }
  }, deps)

  return [unsaved, setUnsaved]
}