import React from 'react'
import { useSelector } from "react-redux";
import EDDContent from "../entity-due-diligence/EDD-content";


export default function EntityProfileDD({ entity_id }) {
  const csp_id = useSelector(state => state.tenant.csp?.csp_id)

  return (<>
    {
      csp_id ? <EDDContent entity_id={entity_id} csp_id={csp_id} className='col-lg-12' /> : null
    }
  </>)
}