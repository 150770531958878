import { TextField, InputAdornment } from '@material-ui/core'
import React, { useRef } from 'react'
import { AttachFile } from '@material-ui/icons'


export default function FileInput({ value, onChange, ...props }) {
  const fileRef = useRef(null)
  function onFileChange(e) {
    const file = Array.prototype.slice.call(e.target.files)[0];
    if (file)
      onChange(file)
  }

  return (
    <>
      <input onChange={onFileChange} ref={fileRef} type="file" className="d-none" />
      <TextField
        {...props}
        value={value?.name || value}
        onClick={e => fileRef.current.click()}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <AttachFile />
            </InputAdornment>
          )
        }}
        variant="outlined" fullWidth />
    </>
  )
}