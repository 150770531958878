export default function snackbarReducer(state = [], action) {
  switch (action.type) {
    case "ERROR": 
    case "SUCCESS":
      const newItem = {
        id: action.payload.id,
        type: action.type.toLowerCase(),
        title: action.payload.title,
        message: action.payload.message,
        duration: action.payload.duration
      }

      return [...state, newItem];

    case "POP_MESSAGE":
      const newState = state.filter(item => item.id !== action.payload.id);
      return newState;
      
    default: return state
  }
} 