import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from 'react-router-dom';

export function SuccessScreen(props) {
  return (
    <div className="overlay d-flex justify-content-center align-items-center p-5 p-sm-0">
      <div className="col-12 col-sm-7 rounded-lg screen-container">
        <div className="success-header bg-success text-center text-white rounded-lg">
          <CheckCircleOutlineIcon style={{ fontSize: 128 }} />
          <div className="font-weight-bolder mt-6 success-status">Success!</div>
          <div className="bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </div>
        </div>
        <div className="text-center success-body">
          <div className="font-size-h1 font-weight-bold">{props.message || 'Success message'}</div>
          <Link to="/dashboard" className="btn btn-lg mt-6 font-weight-bold">
            Go to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
