import React, { useRef } from "react";
import {
  SimpleStatWidget8,
  SimpleStatWidget9
} from "../../components/SimpleStatWidgets/SimpleStatWidgets";
import Axios from 'axios'
import { Link } from "react-router-dom";
import UploadFilesModal from "../../components/UploadFilesModal/UploadFilesModal";
import EntityExportDescription from "../../components/UploadFilesModal/EntityExportDescription";
import { exportEntitiesFromCSV } from "../../api/csp";

export default function AddNewClientRoute() {
  const modalButtonRef = useRef()

  const onFilesUpload = (_, documents) => {
    const form_data = new FormData();

    form_data.append("file", documents[0].file);

    return exportEntitiesFromCSV(form_data)
  }
  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom" style={{ height: "900px" }}>
          <div className="card-header border-0 pt-5">
            <Link to="/entities">
              <button type="button" className="btn bg-light-primary">
                Go back
              </button>
            </Link>
          </div>

          <div className="card-body" style={{ paddingBottom: "300px", marginTop: "200px" }}>
            <div className="mb-5">
              <span className=" font-size-h3 ml-4">
                New formation or importing an existing entity?
              </span>
              <br />
              <span className="font-size-h3 ml-4">
                If you need more info, please check out the Help page.
              </span>
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <Link to="/entity-formation">
                    <SimpleStatWidget8
                      title="New Entity Formation"
                      subtitle="Forming a brand new Entity"
                    />
                  </Link>
                </div>
                <div className="col-lg-6">
                  <UploadFilesModal
                    description={<EntityExportDescription />}
                    title='Add existing client entity'
                    inputTitle='Upload Entities CSV'
                    uploadButtonRef={modalButtonRef}
                    onSave={onFilesUpload}
                  />
                  <Link onClick={e => modalButtonRef.current.click()}>
                    <SimpleStatWidget9
                      title="Add Existing Client Entity"
                      subtitle="Upload details of an existing (already formed) Client Entity"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
