import {USER_ROLE} from '../../../../../constants';
import {isCSP} from '../../../../../app/components/Auxillary';
import {cloneDeep} from 'lodash';

const asideMenuItems = [
  {
    path: '/dashboard',
    icon: '/media/svg/icons/Speed.svg',
    title: 'Dashboard'
  },
  {
    path: '/entities',
    icon: '/media/svg/icons/Apartment.svg',
    title: 'Entities',
    general: true
  },
  {
    path: '/entity-users',
    icon: '/media/svg/icons/Person.svg',
    title: 'Individuals'
  },
  {
    path: '/tasks',
    icon: '/media/svg/icons/Inbox.svg',
    title: 'Inbox',
    general: true
  },
  {
    path: '/csp-config',
    icon: '/media/svg/icons/Settings.svg',
    title: 'CSP configuration'
  },
  {
    path: '/invitations',
    icon: '/media/svg/icons/Invitation.svg',
    title: 'Invitations'
  },
  {
    path: '/csp-users',
    icon: '/media/svg/icons/CSPUser.svg',
    title: 'CSP users'
  },
  {
    path: '/approval-requests',
    icon: '/media/svg/icons/Approval.svg',
    title: 'Approval requests',
    general: true
  },
  {
    path: '/entity-formation',
    icon: '/media/svg/icons/Company.svg',
    title: 'Request an entity',
    general: true
  },
  {
    path: '/esn',
    icon: '/media/svg/icons/Assessment.svg',
    title: 'CSP’s Annual Report Status'
  }
];

export function getAsideMenuItems(user) {
  const { user_id, user_csp_role, global_user_role } = user;

  const isGlobalAdmin =
		global_user_role?.user_role?.role_name === USER_ROLE.GLOBAL_ADMIN;

  if (isCSP(user_csp_role) || isGlobalAdmin) {
    return asideMenuItems;
  } else {
    const generalAside = cloneDeep(asideMenuItems).filter(item => item.general);
    const entityItem = generalAside.find( item => item.path === '/entities')
    if (entityItem) entityItem.path = `/user/${user_id}/related_entities/entities`;
    return generalAside
  }
}