import React from 'react'
import { Link } from 'react-router-dom';

export default function TaskTitle({ task }) {
  const user_id = task?.created_by_user?.user_id;
  const userProfileLink = '/user/' + user_id + '/details';
  return (
    <>
      {user_id ? (
        <Link to={userProfileLink}>
          <span className="card-label font-weight-bolder text-dark font-size-h4">
            {task.task_name}
          </span>
        </Link>
      ) : (
        <span className="card-label font-weight-bolder text-dark font-size-h4">
          {task.task_name}
        </span>
      )}
    </>
  );
}
