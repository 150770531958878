import React, { useState } from 'react'
import { FormButton, FormSwitch, MultipleSelectChip } from '../../../components/Basic';
import { notify } from '../../../../redux/NotificationSnackbar/snackbarActions';
import NationalitiesTable from './NationalitiesTable/NationalitiesTable';
import CountrySelect from '../../../components/Dropdown/CountrySelect';
import useCountries from '../../../hooks/useCountries';

export default function HDDCitizenship({
  page2Formik,
  selectOptions,
  goBack,
  isDocsFormValid,
  validateIdentificationDocument,
  loading,
  availableNationalities = [],
  onReturnNationalities = () => { }
}) {
  const [nationalities, setNationalities] = useState(availableNationalities)
  const [countries] = useCountries()

  const validatePage = (e) => {
    e.preventDefault()
    if (!nationalities.length) {
      return notify({ type: "ERROR", title: 'No nationalities selected', message: 'Please select nationalities' })
    }
    if (!nationalities[0].upload_copy_of_id) {
      return notify({ type: "ERROR", title: 'Primary nationality must have document', message: 'Please attach document' })
    }

    page2Formik.handleSubmit(e)
    validateIdentificationDocument(e);
    onReturnNationalities(nationalities);
  }

  return (
    <form onSubmit={validatePage} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="form-group fv-plugins-icon-container">
        <CountrySelect
          options={countries}
          name="country_of_birth"
          label="Country of birth"
          value={page2Formik.values.country_of_birth}
          onChange={(e) => {
            page2Formik.setFieldValue('country_of_birth', e);
          }}
          onBlur={() => {
            page2Formik.setFieldTouched('country_of_birth');
          }}
          error={page2Formik.errors.country_of_birth && page2Formik.touched.country_of_birth}
          helperText={page2Formik.touched.country_of_birth ? page2Formik.errors.country_of_birth : null}
        />
      </div>

      <NationalitiesTable nationalities={nationalities} setNationalities={setNationalities} />

      <div className="form-group fv-plugins-icon-container">
        <div className="font-size-h6 font-weight-bold mt-6 ml-2">Tax information</div>
        <FormSwitch
          label="I am a tax resident in the following jurisdictions"
          name="is_tax_resident"
          className="m-1"
          value={page2Formik.values.is_tax_resident}
          onChange={page2Formik.handleChange}
        />

        {page2Formik.values.is_tax_resident && (
          <>
            <MultipleSelectChip
              label="Tax resident country"
              name="tax_resident_country"
              value={page2Formik.values.tax_resident_country}
              onChange={(_, val) => {
                page2Formik.setFieldValue('tax_resident_country', val);
              }}
              options={selectOptions.country}
              onBlur={page2Formik.handleBlur}
              error={page2Formik.errors.tax_resident_country && page2Formik.touched.tax_resident_country}
              helperText={
                page2Formik.touched.tax_resident_country && page2Formik.errors.tax_resident_country
                  ? page2Formik.errors.tax_resident_country
                  : null
              }
            />
          </>
        )}
      </div>
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Back
        </button>

        <FormButton
          label="Next"
          disabled={!isDocsFormValid}
          color="secondary"
          id="kt_create_csp_submit"
          type="submit"
          onClick={validatePage}
        >
          {loading && <span className="ml-3 spinner spinner-white" />}
        </FormButton>
      </div>
    </form>
  )
}