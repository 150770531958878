// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import Axios from 'axios'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import cellEditFactory from 'react-bootstrap-table2-editor'

const cols = [
  {
    id: 1,
    name: 'company_name',
    title: 'Entity name',
    width: '200px',
    sortable: true
  },
  {
    id: 2,
    name: 'entity_type',
    title: 'Entity Type',
    width: '200px'
  },
  {
    id: 3,
    name: 'registration_number',
    title: 'Registration Number',
    width: '200px'
  },
  {
    id: 4,
    name: 'date_of_incorporation',
    title: 'Date of incorporation',
    width: '200px'
  },
  {
    id: 5,
    name: 'risk_rating',
    title: 'Risk rating',
    width: '200px'
  },
  {
    id: 6,
    name: 'company_status',
    title: 'Status',
    width: '200px'
  },
  {
    id: 7,
    name: 'issues',
    title: 'Issues',
    width: '200px'
  },
  {
    id: 8,
    name: 'additional',
    title: '',
    width: '130px',
    component: (row) => (<div>
      {row.company_name + ' ' + row.entity_name}
    </div>)
  }
]

function SelectionCheckbox({ isSelected, onChange }) {

  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected || false} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

/**
 * default column view
 * {
    id: 1,
    name: 'company_name',
    title: 'Entity name',
    width: '200px',
    sortable: true,
    component: (row) => JSX Element
  },

  Adapted column view
  {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
 */
export function CustomersTable() {
      const [companies, setCompanies] = useState([]);


      const getCompanies = () => {
        Axios.get("company/all")
        .then(response => {
          if(response.data && response.data.companies) {
            const profiles = [];

            for(const profile of response.data.companies) {
              profiles.push({
                ...profile,
                artifactId: profile.company_id,
                id: profile.company_id
              })
            }

            setCompanies(profiles);
          }
        })
        .catch(() => {
        });
      }

      useEffect(() => {
        getCompanies()
      }, [])


      return (<Table entities={companies} cols={cols} />)
}

/**
 *   entities,
  cols,
  idField,
  rowEvents,
  selectedRows = [],
  setSelectedRows=() => {},
  onTableChange=()=> {},
  nonSelectable = false
 */
export const Table = (({
  entities = [],
  clickable = false,
  cols = [],
  idField = "id",
  rowEvents = {},
  setSelectedEntities = () => {},
  onTableChange=()=> {},
  onSortButtonClick = () => {},
  nonSelectable = false
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  // TODO @performance this feels horrible
  useEffect(() => {
    setSelectedEntities(selectedRows.map(row => entities[row][idField]))
  }, [selectedRows])

  const  selectAllEntities = () => {
    const condition = selectedRows.length === entities.length;
    if (condition) {
      setSelectedRows(() => [])
    }
    else {
      setSelectedRows(() => {
        const newSelectedRows = [];
        entities.forEach((_, index) => newSelectedRows.push(index))
        return newSelectedRows;
      })
    }
  }

  function selectEntity(rowIndex) {
    if (selectedRows.find(index => rowIndex === index) !== rowIndex) setSelectedRows(oldRows => [...oldRows, rowIndex])
    else setSelectedRows(oldRows => oldRows.filter(index => index !== rowIndex))
  }

  const columns = ColumnsAdapter(cols);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: /* totalCount || */ 134,
    sizePerPageList: /* uiHelpers.sizePerPageList */ 12,
    sizePerPage: 10,
    page: 1,
  };

  const [enterRow, setEnterRow] = useState(null)

  if (clickable) {
    rowEvents.onMouseEnter = (e, row) => {
      setEnterRow({...row})
    }
    rowEvents.onMouseLeave = (e) => {
      setEnterRow(null)
    }
    rowEvents.onMouseDown = (e) => {
      if (e.button === 1 && rowEvents.onWheelClick) {
        rowEvents.onWheelClick(e, enterRow)
      }
    }
  }

  if (!cols?.length) return null
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <React.Fragment // Pagination
              /* isLoading={ *//* listLoading *//*  false} */
              /* paginationProps={paginationProps} */

            >
              <BootstrapTable
                cellEdit={ cellEditFactory({
                  mode: 'click',
                }) }
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                rowEvents={rowEvents}
                keyField={idField || "id"}
                data={entities === null ? [] : entities}
                columns={columns}
                rowClasses={clickable && 'link'}
                onTableChange={(action, options) => {
                  const query = {
                    sortField: options.sortField,
                    sortOrder: options.sortOrder,
                    page: options.page,
                    limit: options.sizePerPage
                  }
                  onTableChange(query)
                }}
                selectRow={{
                  mode: 'checkbox',
                  hideSelectColumn: nonSelectable,
                  selectionHeaderRenderer: () => {
                    const isSelected = selectedRows.length === entities.length;
                    return (
                      <SelectionCheckbox isSelected={isSelected}
                      onChange={e => selectAllEntities()}/>
                    )
                  },
                  selectionRenderer: ({rowIndex}) => {
                    const isSelected = selectedRows.find(index => rowIndex === index) === rowIndex

                    return (
                      <SelectionCheckbox isSelected={isSelected}
                      // onChange={e => selectEntity(rowIndex, selectedRows, setSelectedRows)}
                      onChange={e => selectEntity(rowIndex)}
                      />
                    )
                  }
                }}
                {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
                
              </BootstrapTable>
                {
                  (!entities || !entities.length) && 
                  <div className="d-flex align-items-center justify-content-center p-10 w-100">
                    <h3>
                      No data to display
                    </h3>
                  </div>
                  
                }
            </React.Fragment>
          );
        }}
      </PaginationProvider>
    </>
  );
})

export function ExpandableTable({entities, cols, groups}) {

  const columns = ColumnsAdapter(cols);

  const expandRow = {
    showExpandColumn: true,
    expandByColumnOnly: false,
    nonExpandable: [entities.length],
    expandHeaderColumnRenderer: ({isAnyExpands}) => {
      return (
        <div style={{width: 50}}>
          <ArrowForwardIosIcon style={{
            transition: '0.1s ease-in-out',
            transform: isAnyExpands ?  'rotate(90deg)' : 'rotate(0)'}} />
        </div>
      )
    },
    expandColumnRenderer: ({expanded, expandable}) => {
      return (
        <div style={{width: 50}}>
          {expandable && (<ArrowForwardIosIcon style={{
            transition: '0.1s ease-in-out',
            transform: expanded ?  'rotate(90deg)' : 'rotate(0)'}} />)}
        </div>
      )
    },
    // expanded: [1, 2],
    renderer: (row) => {

      return (
        <div>
          <BootstrapTable
            wrapperClasses="table-responsive subtable"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            expandRow={{
              showExpandColumn: true,
              expandByColumnOnly: true,
              renderer: () => null,
              expanded: [],
              expandHeaderColumnRenderer: ({isAnyExpands}) => {
                return (
                  <div onClick={e => e.preventDefault()} style={{width: 50}} />
                )
              },
              expandColumnRenderer: () => {
                return (
                  <div onClick={e => e.preventDefault()} style={{width: 50}} />
                )
              },
            }}
            keyField="id"
            data={groups.find(group => group.id === row.id).rows}
            columns={columns}
          >

          </BootstrapTable>
          {/* <WidgetTable cols={cols} rows={groups[0].rows} /> */}
        </div>
      )
    }
  }
  return (
    <>
      <div>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                expandRow={expandRow}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns.map(column => ({...column, formatter: column.hasFormatter ? () => null : column.formatter}))}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  0
                )}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              {
                  (!entities || !entities.length) && 
                  <div className="d-flex align-items-center justify-content-center p-20 w-100">
                    <h3>
                      No data to display
                    </h3>
                  </div>
                  
              }
      </div>
    </>
  );
}

function ColumnsAdapter(columns) {
  const adaptedColumns = columns.map(column => {

    return {
      id: column.id,
      dataField: column.name,
      text: column.title,
      sort: column.sortable,
      sortCaret: sortCaret,
      classes:  (cell, row, rowIndex, colIndex) => {
        if (typeof column.editable === 'function')
        return !!column.editable && !!column.editable(cell, row, rowIndex, colIndex) && 'link bg-light table-bordered'
        else if (!!column.editable) return 'link bg-light table-bordered'
      },
      formatter: column.component && ((_content, row, rowIndex, extraData) => column.component(row, rowIndex, extraData)),
      headerSortingClasses,
      headerStyle: () => {
        return {width: column.width}
      },
      editable: !!column.editable && column.editable,
      validator: !!column.validator && column.validator,
      formatExtraData: column.extraData,
      hasFormatter: !!column.component,
      editorRenderer: (editorProps, value, row, _column, rowIndex, columnIndex) => {
        return (<EditorRenderer
          {...editorProps}
          row={row}
          columnIndex={columnIndex}
          value={value || ''}
          column={column}
          onEditorSave={column.onEditorSave}
          rowIndex={rowIndex}
          />)
      }
    }
  })

  return adaptedColumns;
}

function EditorRenderer({editorProps, value, row, column, rowIndex, columnIndex, onEditorSave}) {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
    inputRef.current.value = value;
  }, [])

  if (column.editType === 'number') return <input {...editorProps}
    ref={inputRef}
    type="number"
    className='form-control'
    onBlur={event => onEditorSave({row, column, rowIndex, columnIndex, event})}
    name={column.name}
  />

  if (column.editType === 'date') return <input {...editorProps}
    ref={inputRef}
    type="date"
    className='form-control'
    onBlur={event => onEditorSave({row, column, rowIndex, columnIndex, event})}
    name={column.name}
    />

  if (column.editType === 'select')  return(
    <select
      ref={inputRef}
      class="form-control"
      onBlur={event => onEditorSave({row, column, rowIndex, columnIndex, event})}
     >
      {
        typeof column.editSelectOptions === 'function' ? column.editSelectOptions().map(_option =>
          (<option key={_option.value} value={_option.value}>{_option.title}</option>)) :
          column.editSelectOptions.map(_option =>
            (<option key={_option.value} value={_option.value}>{_option.title}</option>))
      }
    </select>
  )

  return <input {...editorProps}
    ref={inputRef}
    className='form-control'
    onBlur={event => onEditorSave({row, column, rowIndex, columnIndex, event})}
    name={column.name}
    />
}



