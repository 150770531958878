import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'

export default function ColoredSelect({ options = [], value = '', onChange = () => { }, ...props }) {

  return (
    <TextField
      {
      ...props
      }
      select
      value={value}
      onChange={e => onChange(e.target.value)}
      variant="outlined"
      fullWidth
    >
      {
        options.map(option => (
          <MenuItem
            style={{
              color: option.color
            }}
            key={option.value}
            value={option.value}>
            <span
              style={{
                color: option.color
              }}
              className="font-weight-bold">
              {
                option.title
              }
            </span>

          </MenuItem>
        ))
      }
    </TextField>
  )
}