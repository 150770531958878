import { Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getEntityDDData } from '../../api/company'
import { getEddTitles } from '../../api/user'
import useRequest from '../../components/useRequest/useRequest'
import { Table } from '../../modules/Auth/pages/customers/customers-table/CustomersTable'

export default function EddReview({entity_id}) {

  const [eddData, setEddData] = useState([])
  const [eddTitles, setEddTitles] = useState({})

  const [fetchEDD] = useRequest(() => getEntityDDData(entity_id), (data) => setEddData(data))
  const [fetchTitles] = useRequest(getEddTitles, setEddTitles)

  useEffect(() => {
    fetchEDD()
    fetchTitles()
  }, [])

  const cols = [
    {
      extraData: eddTitles,
      name: 'field_name',
      title: 'Question',
      component: (row, _, extraData) => {
        return extraData[row.field_name] || row.field_name
      }
    },
    {
      name: 'field_value',
      title: 'Answer',
      component: (row) => {
        if (row.field_value === 'false' || row.field_value === 'true') {
          return (<Checkbox checked={JSON.parse(row.field_value)} />)
        }

        return row.field_value || '-'
      }
    }
  ]

  return (
    <div className="card gutter-b">
      <div className="card-header">
        EDD Questionary
      </div>
      <div className="card-body">
        <Table nonSelectable cols={cols} entities={eddData}/>
      </div>
    </div>
  )
}