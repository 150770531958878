import React from 'react';
import { Dropdown } from 'react-bootstrap';

export default function ActionsDropdown({ options = [], title, menuClassName, toggleClassName }) {
  const defaultClass = 'btn btn-clean btn-hover-light-primary pl-12 pr-12';
  const isAllDisabled = options.every(option => option.disabled);
  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={isAllDisabled}
        className={toggleClassName || defaultClass}
        variant="success"
        id="dropdown-basic"
        alignLeft
      >
        {title || 'Actions'}
      </Dropdown.Toggle>
      <Dropdown.Menu className={menuClassName || ''}>
        {options.map(option => {
          return (
            <Dropdown.Item onClick={e => option.onClick?.call(0, e)} key={option.id} disabled={option.disabled}>
              <span className="navi-text">{option.title}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
