import React, { useEffect, useState } from "react";
import ActionsDropdown from "../../components/Dropdown/ActionsDropdown";
import EntityAddressModal from "./EntityAddressModal";
import { simpleValidate } from '../../helpers/validation'
import useRequest from '../../components/useRequest/useRequest'
import { deleteEntityAddress, getEntityAddresses, saveEntityAddress } from '../../api/company'
import * as yup from 'yup'
import { Table } from "../../modules/Auth/pages/customers/customers-table/CustomersTable";
import LoadingCheckbox from '../../components/_common/LoadingCheckbox'
import MoreDetailsDropdown from '../../components/Dropdown/MoreDetailsDropdown'
import { notify } from "../../../redux/NotificationSnackbar/snackbarActions";

const modalDefaultValue = {
  is_primary: false,
  address_location: '',
  document: '',
  address_quantifiers: []
}

export const validationSchema = {
  address_location: () => yup.string().required(),
  document: ctx => ctx.is_primary && !ctx.document_id && ({
    validate(value) {
      if (!(value instanceof File))
        throw new Error('File is required')
    }
  })
}

function AddressRoute({ entity_id }) {
  const [current, setCurrent] = useState(modalDefaultValue)
  const [open, setOpen] = useState(false)
  const [addresses, setAddresses] = useState([])

  const [fetchAddresses] = useRequest(() => getEntityAddresses(entity_id), data => data && setAddresses(data))

  useEffect(() => {
    fetchAddresses()
  }, [])

  const dropdownOpts = [
    {
      id: 1,
      title: 'Add new',
      onClick: () => {
        setOpen(true)
        setCurrent({ ...modalDefaultValue })
      }
    }
  ]

  const tableCols = [
    {
      id: 1,
      name: 'address_location',
      title: 'Location'
    },
    {
      id: 2,
      name: 'is_primary',
      title: 'Primary',
      width: 50,
      component: (row) => {
        return (<LoadingCheckbox checked={row.is_primary} onChange={(e) => saveEntityAddress(entity_id, {
          ...row,
          is_primary: e.target.checked
        }).then(fetchAddresses)} />)
      }
    },
    {
      id: 3,
      name: 'actions',
      title: '',
      width: 50,
      component: (row) => (<MoreDetailsDropdown options={[
        {
          title: 'Edit',
          onClick: () => {
            setCurrent({ ...row })
            setOpen(true)
          }
        },
        {
          title: 'Delete',
          onClick: () => {
            deleteEntityAddress(row.id, entity_id).then(fetchAddresses).then(() => notify({
              type: 'SUCCESS',
              title: 'Successfully deleted!'
            }))
          }
        }
      ]} />)
    }
  ]

  function onClose() {
    setOpen(false)
    setCurrent({ ...modalDefaultValue })
  }

  async function onSubmit(currentAddress, setErrors) {
    if (await simpleValidate(validationSchema, currentAddress, setErrors)) {
      await saveEntityAddress(entity_id, currentAddress)
      await fetchAddresses()
    } else {
      throw new Error('Saving error')
    }
  }

  return (
    <>
      <EntityAddressModal
        defaultValue={modalDefaultValue}
        setCurrentAddress={setCurrent}
        currentAddress={current}
        submitSavingAddress={onSubmit}
        open={open}
        onClose={onClose}
      />
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header">
            <div className="card-title w-25">
              Addresses
            </div>
            <div className="card-toolbar">
              <ActionsDropdown options={dropdownOpts} />
            </div>
          </div>

          <div className="card-body">
            <Table
              nonSelectable
              cols={tableCols}
              entities={addresses}
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default AddressRoute;
