/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from "react";

import { getOwnershipTypesRelations, getOwnershipTypesTitles } from "../../../api/ui";
import useRequest from "../../../components/useRequest/useRequest";
import { getOwnershipTypesFields } from "../../../api/company";
import {useSelector} from 'react-redux';
import Axios from "axios";


export default function useOwnershipTypes(entity_category) {
  const [ownershipTypesRelations, setOwnershipTypesRelations] = useState([])
  const [visibleFields, setVisibleFields] = useState({})
  const [ownershipTypes, setOwnershipTypes] = useState([])
  const [ownershipTypesTitles, setOwnershipTypesTitles] = useState([])

  const [fetchOwnershipTypesRelations] = useRequest(getOwnershipTypesRelations, (relations) => {
    setOwnershipTypesRelations(relations)
  })

  const tenant = useSelector(state => state.tenant);
  const slug = tenant?.csp?.tenant?.tenant_slug;

  const [fetchOwnershipTypesFields] = useRequest(getOwnershipTypesFields, (fields) => {
    const object = {};
    // eslint-disable-next-line no-unused-expressions
    fields[entity_category]?.forEach(field => object[field] = true)
    setVisibleFields(object)
  })
  const [fetchOwnershipTypesTitles] = useRequest(getOwnershipTypesTitles, (titles) => {
    setOwnershipTypesTitles(titles)
  })

  useEffect(() => {
    if (slug) {
      fetchOwnershipTypesRelations()
      fetchOwnershipTypesTitles()
      fetchOwnershipTypesFields()
    }
  }, [entity_category, slug])

  useEffect(() => {
    if (ownershipTypesRelations && ownershipTypesRelations[entity_category] && ownershipTypesTitles)
      setOwnershipTypes(ownershipTypesRelations[entity_category]?.map(category => ({
        value: category,
        title: ownershipTypesTitles[category]
      })))
  }, [entity_category, ownershipTypesRelations, ownershipTypesTitles])

  const [currency, setCurrency] = useState([])
  const getCurrency = () => {
    Axios.get('/company/all-currency')
      .then(({ data }) => {
        setCurrency(data.CUSTOMER_COMPANY_SHARE_CURRENCY)
      })
      .catch(() => { });
  }

  useEffect(() => {
    // On mount
    getCurrency()
  }, []);

  return [ownershipTypes, visibleFields, currency]
}