import React from 'react'
import ApexCharts from "apexcharts"
import { useEffect } from 'react'

export default function PieChart({series, labels, title}) {
  // TODO @refactor if we use multiple pie-charts on one page, we will have problems, because we use
  // a hard-coded 'pie_chart_component' DOM id
  useEffect(() => {
    var options = {
    series,
    labels,
    chart: {
      type: 'donut',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 150,
        },
        legend: {
          position: 'bottom'
        }
      }
      }],
      legend: {
        position: 'bottom'
      }
    };

    const chart = new ApexCharts(document.getElementById('pie_chart_component'), options)
    chart.render()

    return () => {
      chart.destroy()
    }
  }, [series, labels])

  return (
  <div style={{height: "auto"}} className={`card card-custom gutter-b`}>
    {/* Header */}
    <div className="card-header border-0 pt-5">
      <div className="card-title w-50 ml-0 align-items-start flex-column">
        <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">{title}</div>
      </div>
    </div>
    {/* Body */}
    <div className="card-body d-flex flex-column">
      <div className="flex-grow-1">
        <div id="pie_chart_component"></div>
      </div>
    </div>
  </div>
  )
}
