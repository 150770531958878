import React, { } from "react";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import BaseFieldsConfiguration from "../../../components/EditProfileForm/BaseFieldsConfiguration";
import useTitlesWithRelations from "../../../hooks/useTitlesWithRelations";
import { getPrimaryIndustries, getPrimarySubIndustriesRelations, getPrimarySubIndustriesTitles, getPurposes, getStockExchangeRelations, getStockExchangeTitles } from "../../../api/ui";
import useTitles from "../../../hooks/useTitles";
import * as yup from 'yup'

export const overviewValidationSchema = {
  business_overview: () => yup.string().required(),
  website_domain: () => yup.string().nullable()
    .test(
      {
        message: 'Enter valid url',
        test: (v) => /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(v)
      }),
  exchange_jurisdiction: (context) => yup.string().test({
    message: 'Required field',
    test: (v) => {
      return context.listed_on_recognized_stock_exchange ? !!v : true
    }
  }),
  stock_exchange: (context) => yup.string().test({
    message: 'Required field',
    test: (v) => {
      return context.listed_on_recognized_stock_exchange ? !!v : true
    }
  }),
  ticker_symbol: (context) => yup.string().test({
    message: 'Required field',
    test: (v) => {
      return context.listed_on_recognized_stock_exchange ? !!v : true
    }
  }),
  primary_industry: () => yup.string().required(),
  primary_sub_industry: () => yup.string().required(),
  entity_purpose: () => yup.string().required(),
  limited_partnership_name: (context) => ({
    validate(v) {
      if (context.entity_purpose === 'general_partner' && context.only_line_of_business && !v) {
        throw new Error('required field')
      }
    }
  }),
  registration_number: (context) => ({
    validate(v) {
      if (context.entity_purpose === 'general_partner' && context.only_line_of_business && !v) {
        throw new Error('required field')
      }
    }
  }),
  license_type: (context) => ({
    validate(v) {
      if (context.entity_purpose === 'general_partner' && context.only_line_of_business && !v) {
        throw new Error('required field')
      }
    }
  }),
}

export default function OverviewSection({ overviewErrors, overviewObject, setOverviewObject, selectedJurisdiction, cimas, countries }) {
  const [stockExchangeOptions] = useTitlesWithRelations(overviewObject['exchange_jurisdiction'], getStockExchangeRelations, getStockExchangeTitles)
  const [primaryIndustries] = useTitles(getPrimaryIndustries)
  const [primarySubIndustries] = useTitlesWithRelations(overviewObject['primary_industry'], getPrimarySubIndustriesRelations, getPrimarySubIndustriesTitles)
  const [purposes] = useTitles(getPurposes)

  const overviewOptions = [
    {
      name: 'business_overview',
      title: 'Business Overview Summary',
      type: 'multiline-text'
    },
    {
      name: 'website_domain',
      title: 'Website Domain',
      type: 'text'
    },
    {
      name: 'listed_on_recognized_stock_exchange',
      title: 'Listed on a recognized stock exchange?',
      type: 'open-switch',
      options: [
        {
          name: 'exchange_jurisdiction',
          title: 'In which jurisdiction is the Exchange?',
          type: 'country',
          options: countries
        },
        {
          name: 'stock_exchange',
          title: 'Please Specify which Stock Exchange?',
          type: 'select',
          options: stockExchangeOptions
        },
        {
          name: 'ticker_symbol',
          title: 'Ticker Symbol',
          type: 'text'
        }
      ]
    },
    {
      name: 'primary_industry',
      title: 'Primary Industry',
      type: 'select',
      options: primaryIndustries
    },
    {
      name: 'primary_sub_industry',
      title: 'Primary Sub-Industry',
      type: 'chips',
      options: primarySubIndustries
    },
    {
      name: 'entity_purpose',
      title: 'Entity Purpose (Primary)',
      type: 'select',
      options: purposes
    },
    {
      name: 'only_line_of_business',
      title: `Only line of business is as a General Partnership to a licensed, regulated or otherwise registered investment entity in the ${selectedJurisdiction.title
        }`,
      type: 'open-switch',
      visible: (context) => {
        return context.entity_purpose === 'general_partner'
      },
      options: [
        {
          name: 'limited_partnership_name',
          title: 'Name of Limited Partnership',
          type: 'text'
        },
        {
          name: 'registration_number',
          title: 'Registration Number',
          type: 'text'
        },
        {
          name: 'license_type',
          title: 'License Type',
          type: 'select',
          options: cimas
        }
      ]
    },
    {
      name: 'only_holding_shares',
      title: `Only holding shares in a regulated Entity in ${selectedJurisdiction.title}?`,
      type: 'open-switch',
      visible: (context) => {
        return context.entity_purpose === 'holding_company'
      },
      options: [
        {
          name: 'license_name',
          title: 'Name of Licensee',
          type: 'text'
        }
      ]
    }
  ]

  function editOverview(value, fieldName) {
    setOverviewObject(old => {
      old[fieldName] = value
      return { ...old }
    })
  }

  return (
    <ExpansionPanel className="border-0">
      <ExpansionPanelSummary>
        <h3>
          Overview
        </h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-7">
              {
                overviewOptions.map(option => (<BaseFieldsConfiguration errors={overviewErrors} editTextFieldValue={editOverview} option={option} value={overviewObject} />))
              }
            </div>
            <div className="col-lg-3" />
          </div>
        </div>


      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}