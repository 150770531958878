import store from "../store"

export const TOGGLE_ASIDE = 'TOGGLE_ASIDE'
export const SET_SECOND_ASIDE = 'SET_SECOND_ASIDE'
export const SET_MAIN_ASIDE_VISIBILITY = 'SET_MAIN_ASIDE_VISIBILITY'
export const SET_SECOND_ASIDE_VISIBILITY = 'SET_SECOND_ASIDE_VISIBILITY'

export function toggleAside() {
  return {
    type: TOGGLE_ASIDE
  }
}

export function setSecondAside(menuDetails) {
  return {
    type: SET_SECOND_ASIDE,
    payload: menuDetails
  }
}

export function setMainAsideVisibility(value) {
  store.dispatch({
    type: SET_MAIN_ASIDE_VISIBILITY,
    payload: value
  })
}

export function setSecondAsideVisibility(value) {
  store.dispatch({
    type: SET_SECOND_ASIDE_VISIBILITY,
    payload: value
  })
}