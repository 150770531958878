import * as Yup from 'yup';
import { getDate } from '../../components/Auxillary';

const re = {
  name: /^[\w-]{2,32}$/gi,
}

export const validationSchema = {
  page1: Yup.object().shape({
    first_name: Yup.string()
      .required('This field is required')
      .min(2, 'First name is too short')
      .max(32, 'First name is too long')
      .matches(re.name, 'First name should not contain numbers/symbols'),
    last_name: Yup.string()
      .required('This field is required')
      .min(2, 'Last name is too short')
      .max(32, 'Last name is too long')
      .matches(re.name, 'Last name should not contain numbers/symbols'),
    middle_name: Yup.string()
      .min(2, 'Middle name is too short')
      .max(100, 'Middle name is too long')
      .matches(re.name, 'Middle name should not contain numbers/symbols'),
    email_address: Yup.string()
      .required('This field is required')
      .email('Invalid email address'),
    date_of_birth: Yup.date('Provide a valid date')
      .required('This field is required')
      .min(new Date(getDate(-100, 'y')), 'Provide a valid date')
      .max(new Date(getDate(-17.9, 'y')), 'You must be at least 18 years old to proceed')
      .typeError('Provide a valid date'),
    phone_number: Yup.string()
      .required('This field is required')
      .min(10, 'Phone number is too short')
      .max(15, 'Phone number is too long'),
    preferred_name: Yup.string()
      .min(2, 'Preferred name is too short')
      .max(100, 'Preferred name is too long'),
    alias: Yup.string()
      .min(2, 'Alias is too short')
      .max(100, 'Alias is too long'),
    gender: Yup.string().required('This field is required')
  }),

  page2: Yup.object().shape({
    country_of_birth: Yup.string()
      .required('This field is required')
      .typeError('This field is required'),
    // nationalities: Yup.array()
    //   .required('This field is required')
    //   .min(1, 'Select at least 1 option')
    //   .typeError('This field is required'),
    tax_resident_country: Yup.string().when('is_tax_resident', {
      is: true,
      then: Yup.string().required('This field is required')
    })
  }),

  page3: Yup.object().shape({
    moved_to_physical_address_at: Yup.date('Provide a valid date')
      .required('This field is required')
      .min(new Date(getDate(-80, 'y')), 'Provide a valid date')
      .max(new Date(getDate(+1, 'd')), "This date can't be in the future")
      .typeError('Provide a valid date'),
    also_operating_in_other_countries_country: Yup.string().when('also_operating_in_other_countries', {
      is: true,
      then: Yup.string().required('This field is required')
    })
  }),

  page4: Yup.object().shape({
    approved_by_other_authority_country: Yup.string().when('approved_by_other_authority', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .typeError('This field is required')
    }),
    approved_by_other_authority_name: Yup.string().when('approved_by_other_authority', {
      is: true,
      then: Yup.string()
        .matches(/[a-z]/gi, 'Name of Authority should not contain numbers/symbols')
        .required('This field is required')
    }),
    approved_by_other_authority_license_number: Yup.string().when('approved_by_other_authority', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    professional_designations: Yup.string().when('has_professional_designations', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .min(2, 'Designation is to short')
        .max(200, 'Designation is to long')
    })
  }),

  page5: Yup.object().shape({
    involved_into_litigation_details: Yup.string().when('involved_into_litigation', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .min(2, 'Field value is too short')
        .max(100, 'Field value is too long')
    }),
    ever_bankrupt_year: Yup.date('Provide a valid date').when('ever_bankrupt', {
      is: true,
      then: Yup.date('Provide a valid date')
        .min(new Date(getDate(-80, 'y')), 'Provide a valid date')
        .max(new Date(getDate(+1, 'h')), 'Provide a valid date')
        .typeError('Provide a valid date')
    }),
    ever_bankrupt_details: Yup.string().when('ever_bankrupt', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    ever_investigated_details: Yup.string().when('ever_investigated', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    ever_criminal_offense_details: Yup.string().when('ever_criminal_offense', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    ever_censured_details: Yup.string().when('ever_censured', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    held_position_political_office: Yup.string().when('has_held_position_political_office', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    held_position_government_official: Yup.string().when('has_held_position_government_official', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    held_position_political_advisor: Yup.string().when('has_held_position_political_advisor', {
      is: true,
      then: Yup.string().required('This field is required')
    }),
    held_position_contacts_with_government: Yup.string().when('has_held_position_contacts_with_government', {
      is: true,
      then: Yup.string().required('This field is required')
    })
  }),

  identification_ids: Yup.object({
    country_of_issue: Yup.string()
      .required('Country of issue is required')
      .typeError('Country of issue is required 2'),
    document_type: Yup.string().required('Document type is required'),
    document_number: Yup.string().required('Document number is required'),
    document_issue_date: Yup.date('Provide a valid date')
      .required('This field is required')
      .min(new Date(getDate(-80, 'y')), 'Provide a valid date')
      .max(new Date(getDate()), 'Provide a valid date')
      .typeError('Provide a valid date'),
    document_expiry_date: Yup.date()
      .required('This field is required')
      .min(new Date(getDate()), 'Provide a valid date')
      .max(new Date(getDate(+80, 'y')), 'Provide a valid date')
      .typeError('Provide a valid date')
  })
};