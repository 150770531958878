import * as yup from 'yup'

export const summarySchema = {
  edd_status: _ => yup.string().required(),
  summary_risk_rating: _ => yup.string().required(),
  risk_rating_rationale: _ => _.summary_risk_rating === 'high' && yup.string().required(),
  date_last_reviewed: _ => yup.date().required().test('test_issue_date', 'Cannot be in future!', (value) => {
    return value <= Date.now()
  }),
  next_review_date: _ => yup.date().required().test('test_issue_date', 'Cannot be in past!', (value) => {
    return value >= Date.now()
  })
}

export const riskCriteriaSchema = {
  geographic_risk: _ => yup.string().required(),
  industry_risk: _ => yup.string().required(),
  delivery_channel_risk: _ => yup.string().required(),
  services_risk: _ => yup.string().required(),
  primary_purpose_risk: _ => yup.string().required(),
  regulated_entity: _ => yup.string().required(),
  cash_dealings: _ => yup.string().required(),
  import_export_dealings: _ => yup.string().required(),
  adverse_media: _ => yup.string().required(),
  bankruptcy: _ => yup.string().required(),
  litigation: _ => yup.string().required(),
  tax_investigation_risk: _ => yup.string().required(),
  // stakeholder_risk: _ => yup.string().required(),
}