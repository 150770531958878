import Axios from 'axios'

export async function getCountries() {
  const res = await Axios.get('/ui/countries')
  if (res.data)
    return res.data.COUNTRIES_TITLES
}

export function getIDTypes() {
  return Axios.get('/ui/id-types').then(res => {
    if (res.data) {
      return res.data.HUMAN_ID_TYPE_TITLES
    }
  })
}

export async function getDocumentContentCategories() {
  const response = await Axios.get('/ui/document-content-categories')
  if (response && response.data) {
    return response.data.DOCUMENT_CONTENT_CATEGORY_TITLES
  }
}

export async function getUserDocumentContentCategories() {
  const response = await Axios.get('/ui/user-document-content-categories');
  if (response && response.data) {
    return response.data.USER_DOCUMENT_CONTENT_CATEGORY_TITLES
  }
}

export async function getEntityCategories() {
  const response = await Axios.get('/ui/entity-categories')
  if (response && response.data) {
    return response.data.ENTITY_CATEGORIES_TITLES
  }
}

export async function getCompanyTypesRelations() {
  const response = await Axios.get('/ui/company-types-relations')
  if (response && response.data) {
    return response.data.COMPANY_TYPES_RELATIONS
  }
}

export async function getCompanyTypesTitles() {
  const response = await Axios.get('/ui/company-types-titles')
  if (response && response.data) {
    return response.data.COMPANY_TYPES_TITLES
  }
}

export async function getCompanyStatusTitles() {
  const response = await Axios.get('/ui/company-status-titles')
  if (response && response.data) {
    return response.data
  }
}

export async function getCompanyStatusColors() {
  const response = await Axios.get('/ui/company-status-color')
  if (response && response.data) {
    return response.data.ENTITY_STATUS_COLOR
  }
}

export async function getOwnershipTypesRelations() {
  const response = await Axios.get('/ui/ownership-types-relations')
  if (response && response.data) {
    return response.data.OWNERSHIP_TYPES_RELATIONS
  }
}

export async function getOwnershipTypesTitles() {
  const response = await Axios.get('/ui/ownership-types-titles')
  if (response && response.data) {
    return response.data.OWNERSHIP_TYPES_TITLES
  }
}

export async function getControllingPersonRelations() {
  const response = await Axios.get('/ui/controlling-persons-relations')
  if (response && response.data) {
    return response.data.CONTROLLING_PERSONS_RELATIONS
  }
}

export async function getControllingPersonTitles() {
  const response = await Axios.get('/ui/controlling-persons-titles')
  if (response && response.data) {
    return response.data.CONTROLLING_PERSONS_TITLES
  }
}

export async function getStockExchangeTitles() {
  const response = await Axios.get('/ui/stock-exchange-titles')
  if (response && response.data) {
    return response.data.STOCK_EXCHANGE_TITLES
  }
}

export async function getStockExchangeRelations() {
  const response = await Axios.get('/ui/stock-exchange-relations')
  if (response && response.data) {
    return response.data.STOCK_EXCHANGE_RELATIONS
  }
}

export async function getPrimaryIndustries() {
  const response = await Axios.get('/ui/primary-industries')
  if (response && response.data) {
    return response.data.PRIMARY_INDUSTRY_TITLES
  }
}

export async function getPrimarySubIndustriesTitles() {
  const response = await Axios.get('/ui/primary-sub-industry-titles')
  if (response && response.data) {
    return response.data.PRIMARY_INDUSTRY_DETAILED_TITLES
  }
}

export async function getPrimarySubIndustriesRelations() {
  const response = await Axios.get('/ui/primary-sub-industry-relations')
  if (response && response.data) {
    return response.data.PRIMARY_INDUSTRY_DETAILED_RELATIONS
  }
}

export async function getPurposes() {
  const response = await Axios.get('/ui/purposes')
  if (response && response.data) {
    return response.data.PURPOSES_TITLES
  }
}

export async function getCIMA() {
  const response = await Axios.get('/ui/cima')
  if (response && response.data) {
    return response.data.CIMA_TYPES_TITLES
  }
}

export async function getBool() {
  const response = await Axios.get('/ui/bool-answer')
  if (response && response.data) {
    return response.data.BOOL
  }
}

export async function getStatusAnswer() {
  const response = await Axios.get('/ui/status-answer')
  if (response && response.data) {
    return response.data.STATUS
  }
}

export async function getManagementDecisions() {
  const response = await Axios.get('/ui/management-decisions')
  if (response && response.data) {
    return response.data.MANAGEMENT_DECISION_TITLES
  }
}

export async function getEPFS() {
  const response = await Axios.get('/ui/entity-produces-financial-statements')
  if (response && response.data) {
    return response.data.ENTITY_PRODUCES_FINANCIAL_STATEMENTS
  }
}

export async function getPercents() {
  const response = await Axios.get('/ui/percents')
  if (response && response.data) {
    return response.data.PERCENTS_LOOKUP
  }
}

export async function getEddStatusColored() {
  const response = await Axios.get('/ui/edd-status-colored')
  if (response && response.data) {
    return response.data
  }
}

export async function getRiskRatingColored() {
  const response = await Axios.get('/ui/risk-rating-colored')
  if (response && response.data) {
    return response.data
  }
}

export async function getRiskRatingExtendedColored() {
  const response = await Axios.get('/ui/risk-rating-extended-colored')
  if (response && response.data) {
    return response.data
  }
}

export async function getRRYesNoColored() {
  const response = await Axios.get('/ui/rr-yes-no-colored')
  if (response && response.data) {
    return response.data
  }
}
