import React from 'react';
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import "./style.css"
import * as Yup from 'yup'
import { FORM_REGEX } from '../formRegex';
import { simpleValidate } from '../../../helpers/validation';
import useForm from '../../../hooks/useForm';
import ShareholderWindow from '../components/shareholder-window';

const validationSchema = {
  type: () => Yup.string()
    .required("Type is required"),
  entity_name: () => Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Entity name is required').matches(
      FORM_REGEX.NAME, "Full name is invalid"
    ),
  }),
  share_class: () => Yup.string()
    .required("Ownership type is required"),
  entity_region: () => Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Entity Region is required'),
  }),
  sharesissued: () => Yup.number(),
  sharespaidup: () => Yup.number()
    .min(0, 'Shares paid up must be greater than 0')
    .max(100, "Shares paid up must be less or equals 100"),
  entity_registration_number: () => Yup.string().when('type', {
    is: "entity",
    then: Yup.string().required('Entity registration number is required'),
  }),
  ownership_percentage: () => Yup.number().required("Ownership percentage is required")
    .min(0, 'Ownership percentage must be greater than 0')
    .max(100, "Ownership percentage must be less or equals 100"),
  name: () => Yup.string().when('type', {
    is: "human",
    then: Yup.string().required('Name is required').matches(
      FORM_REGEX.NAME, "Full name is invalid"
    ),
  }),
  email_address: () => Yup.string().when('type', {
    is: "human",
    then: Yup.string().required('Email address is required').matches(
      FORM_REGEX.EMAIL, "Email is invalid"
    ),
  }),
}

export default function OwnershipDistributionPage(
  {
    shareholders,
    setShareholders,
    sharesInfo,
    currentPage,
    setCurrentPage,
    loading,
    goBack,
    jurisdictions,
    category,
    initialSubscriber,
    setInitialModalOpened
  }
) {
  const [shareholder, modalOpened, setModalOpened, shareholderErrors, editField, addShareholder, editShareholder, deleteShareholder, saveShareholder]
    = useForm(shareholders, setShareholders, {}, (current, setErrors) => {
      return simpleValidate(validationSchema, current, setErrors)
    })

  const cols = [
    {
      id: 1,
      name: 'name',
      title: 'NAME',
      width: 300,
      component: (row) => {
        return (
          <span>{row.name || row.entity_name}</span>
        )
      }
    },
    {
      id: 2,
      name: 'type',
      title: 'type',
      width: 300,
      component: (row) => {
        if (row.type === "human") {
          return <span>Individual</span>
        } else {
          return <span>Entity</span>
        }
      }
    },
    {
      id: 2,
      name: 'share_class',
      title: 'OWNERSHIP TYPE',
      width: 300
    },
    {
      id: 3,
      name: 'ownership_percentage',
      title: 'OWNERSHIP %',
      width: 300
    },
    {
      name: 'actions',
      component: (row, index) => {
        return (
          <MoreDetailsDropdown row={row} options={[
            {
              id: 1,
              title: 'Edit',
              onClick: () => {
                editShareholder(index)
              }
            },
            {
              id: 2,
              title: 'Delete',
              onClick: () => {
                deleteShareholder(index)
              }
            },
          ]} />
        )
      }
    }
  ]

  const Submit = () => {
    if (shareholders?.length) {
      setShareholders(prev => {
        return prev.map(shareholder => {
          const isEntity = shareholder.type === 'entity';
          return (
            {
              ...shareholder,
              name: isEntity ? undefined : shareholder.name,
              email_address: isEntity ? undefined : shareholder.email_address,
              entity_name: !isEntity ? undefined : shareholder.entity_name,
              entity_region: !isEntity ? undefined : shareholder.entity_region,
              registration_number: !isEntity ? undefined : shareholder.registration_number,
            }
          )
        })
      });

      if (!initialSubscriber.change_initial_subscriber
        && !initialSubscriber.initial_subscriber
        && ['company', 'partnership'].includes(category)) {
        setInitialModalOpened(true)
      } else {
        setCurrentPage(currentPage => currentPage + 1);
      }
    } else {
      notify({ type: 'ERROR', message: 'You should add at least one shareholder' });
    }
  };

  return (
    <>
      <ShareholderWindow
        {...{
          modalOpened,
          setModalOpened,
          editField,
          shareholder,
          jurisdictions,
          sharesInfo,
          saveShareholder,
          shareholderErrors,
          category
        }}
      />

      <div className="d-flex align-items-center justify-content-between border-0 pt-5">
        <div className="w-50 ml-0 align-items-start flex-column">
          <div style={{ fontSize: "1.5rem", color: "black" }} className="fw-bolder font-size-h2  mt-6 ml-2 m-0">Ownership Distribution</div>
          <div style={{ fontSize: "10px", color: "#8F96A1" }} className="fw-bolder ml-2 m-0">Who are the owners of this entity and how much do they own?</div>
        </div>
        <div>
          <button
            className="submit-btn ml-2 font-weight-bold px-9 py-4 my-3"
            onClick={() => addShareholder(true)}
          >
            Add New
          </button>
          {
            ['company', 'partnership'].includes(category) && (
              <button
                onClick={() => setInitialModalOpened(true)}
                className='submit-btn ml-2 font-weight-bold px-9 py-4 my-3'
              >
                Initial Subscriber
              </button>
            )
          }

        </div>
      </div>
      <Table nonSelectable entities={shareholders} setEntities={() => void 0} cols={cols} />
      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="cancel-btn font-weight-bold px-9 py-4 my-3"
          onClick={goBack}
        >Back</button>
        <button
          type="submit"
          onClick={Submit}
          className="submit-btn font-weight-bold px-9 py-4 my-3"
        >
          <span>
            Next
          </span>
          {loading && <span className='ml-3 spinner spinner-white' />}
        </button>

      </div>
    </>
  )
}
