import QueryString from 'qs'
import React, {useEffect, useState} from 'react'
import { Table } from '../../../modules/Auth/pages/customers/customers-table/CustomersTable'
import _ from 'lodash'

const cols = [
  {
    name: 'transaction_type',
    title: 'Action',
    sortable: true
  },
  {
    name: '_from',
    title: 'From'
  },
  {
    name: '_to',
    title: 'To'
  },
  {
    name: 'transaction_number_of_shares',
    title: 'Transaction size'
  },
  {
    name: 'transaction_status',
    title: 'Status'
  },
  {
    name: '_completed_on',
    title: 'Completed on'
  }
]

const filters = [
  {
    id: 1,
    type: 'dateRange',
    fieldName: 'date',
    title: 'Date'
  },
  {
    id: 2,
    type: 'select',
    fieldName: 'owner',
    title: 'Owner',
    options: [
      {
        id: 1,
        title: 'Andrew'
      },
      {
        id: 2,
        title: 'Joe'
      },
      {
        id: 3,
        title: 'Axe'
      },
    ]
  },
  {
    id: 3,
    type: 'search',
    fieldName: 'search'
  }
]
function useOptions() {
  const [filterOptions, setFilterOptions] = useState({})
  const [sortOptions, setSortOptions] = useState({})

  useEffect(() => {
    const options = _.merge(filterOptions, sortOptions)
    const query = QueryString.stringify(options);
  }, [filterOptions, sortOptions])

  return [setFilterOptions, setSortOptions]
}

export default function TransactionHistoryRoute(props) {
  const [setFilterOptions, setSortOptions] = useOptions()
  const [currentCompanyId, setCurrentCompanyId] = useState();

  useEffect(() => {
    if(props.basicEntityInfo.company_id && props.basicEntityInfo.company_id !== currentCompanyId) {
      props.queryAdditionalInfo("shares");
      setCurrentCompanyId(props.basicEntityInfo.company_id)
    }
  }, [props.basicEntityInfo.company_id]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Transaction History</div>
          </div>
          </div>

          <div className="card-body">
            {/*<div className="col-lg-24">*/}
            {/*  <DataTableFilter filters={filters} onFilterApply={setFilterOptions} />*/}
            {/*</div>*/}

            <div className="col-lg-12">
              { (props.additionalEntityInfo && props.additionalEntityInfo.share_transactions) ? (
                <Table nonSelectable onTableChange={setSortOptions} entities={props.additionalEntityInfo.share_transactions} setEntities={() => void 0} cols={cols}/>
              ) : "Loading the data..." }

              {/* <Table cols={cols} entities={[]}/> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
