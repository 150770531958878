import React, { useEffect, useState } from "react";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import ActionTab from "../ActionTab";

export default function CompanyUsersRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const [selectedCompanyUser, setSelectedCompanyUser] = useState();
  const [selectedView, setSelectedView] = useState();

  const showUserPromotionView = (stakeholder_type, user_id) => {
    setSelectedCompanyUser(user_id);
    setSelectedView(`add-${stakeholder_type}`);
  };

  const onFormLeave = () => {
    setSelectedView(null);
    // TODO Don't recieve all the data, it's a waste
    props.queryAdditionalInfo("stakeholders", true);
  };

  const dropdown = [
    {
      id: 1,
      title: "Promote to an Officer",
      onClick: (e, user) => showUserPromotionView("officer", user.user_id)
    },
  ];

  if(props.basicEntityInfo && props.basicEntityInfo.company_category !== "partnership") {
    dropdown.push({
      id: 2,
      title: "Promote to a Director",
      onClick: (e, user) => showUserPromotionView("director", user.user_id)
    })
  }

  const companyUsersCols = [
    {
      id: 1,
      name: "user_full_name",
      title: "Name",
      width: 600
    },
    {
      id: 2,
      name: "_email",
      title: "Email",
      width: 300
    },
    {
      id: 3,
      name: "_joined_on",
      title: "Joined on",
      width: 300
    },
    {
      name: "actions",
      component: row => {
        return <MoreDetailsDropdown row={row} options={dropdown} />;
      }
    }
  ];

  useEffect(() => {
    if (
      props.basicEntityInfo.company_id &&
      props.basicEntityInfo.company_id !== currentCompanyId
    ) {
      props.queryAdditionalInfo("company_users");
      props.queryAdditionalInfo("stakeholders");

      setCurrentCompanyId(props.basicEntityInfo.company_id);
    }
  }, [props.basicEntityInfo.company_id]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Entity Users
              </div>
            </div>
          </div>
          <div className="card-body">
            {/* Add an officer */}
            {selectedView === "add-officer" && (
              <ActionTab
                action_type="add"
                user_type="officer"
                entity_id={props.basicEntityInfo.company_id}
                onLeave={onFormLeave}
                stakeholders={props.entityStakeholders.directors}
                company_users={props.entityUsers}
                selected_user={selectedCompanyUser}
              />
            )}

            {/* Add a director */}
            {selectedView === "add-director" && (
              <ActionTab
                action_type="add"
                user_type="director"
                entity_id={props.basicEntityInfo.company_id}
                onLeave={onFormLeave}
                stakeholders={props.entityStakeholders.directors}
                company_users={props.entityUsers}
                selected_user={selectedCompanyUser}
              />
            )}

            {!selectedView && (
              <>
                {props.entityUsers ? (
                  <Table
                    nonSelectable
                    setEntities={() => void 0}
                    entities={props.entityUsers}
                    cols={companyUsersCols}
                  />
                ) : (
                  "Loading the data..."
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
