export async function validate(options, valueObject) {
  const keys = options.filter(o => !!o.validation).map(o => o.name);
  const newErrors = {}
  let errorCount = 0

  keys.forEach(key => newErrors[key] = '')

  for (const key of keys) {
    try {
      const option = options.find(o => o.name === key);
      const validation = option.validation(valueObject)
      await validation.validate(valueObject[key])
    } catch (error) {
      newErrors[key] = error.message
      errorCount++;
    }
  }

  return [!errorCount, newErrors]
}

export async function validateWithVisibleFields(schema, visibleFields, data, setErrors) {
  const newErrors = { }
  let errCount = 0;
  for (const key in schema) {
    if (!visibleFields[key]) continue
    try {
      await schema[key](data).validate(data[key])
    } catch (error) {
      newErrors[key] = error.message
      errCount++;
    }
  }

  setErrors({...newErrors})

  return !errCount;
}

export async function simpleValidate(schema, data, setErrors) {
  const newErrors = { }
  let errCount = 0;
  for (const key in schema) {
    try {
      if (schema[key](data).validate) await schema[key](data).validate(data[key])
    } catch (error) {
      newErrors[key] = error.message
      errCount++;
    }
  }

  setErrors({...newErrors})
  console.log(newErrors)

  return !errCount;
}