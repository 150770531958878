import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react'

const useStyles = makeStyles({
  searchInput: {

    '& input' : {
      height: '100%'
    },

    '& .MuiOutlinedInput-root': {
      border: '1px solid black',
      borderRadius: 0
    },

    '& label' : {
      transform: 'translate(14px, 23px) scale(1)'
    },

    '& .Mui-focused' : {
      color: '#000',

      '& fieldset' : {
        borderColor: '#000 !important'
      }
    }
  }
})

export default function DashboardSearch(props) {
  const styles = useStyles();

  return (
    <TextField
          className={styles.searchInput}
          label="Search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          {...props}
    />
  )
}