import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';

export default function CountrySelect({ options, value, onChange, bannedValues = [], ...props }) {
  const [autocompleteValue, setAutocompleteValue] = React.useState([])

  React.useEffect(() => {
    if (options?.length && value) {
      setAutocompleteValue(options.find((option) => option.value === value))
    }
  }, [value, options])

  return (
    <Autocomplete
      fullWidth
      renderOption={(option, props) => {
        return (
          <div {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.value?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.value?.toLowerCase()}.png 2x`}
              alt=""
            />
            <span className="ml-5">
              {option.title} ({option.value})
            </span>
          </div>
        );
      }}
      disabled={props.disabled}
      getOptionLabel={option => option.title}
      value={autocompleteValue}
      onChange={(_, option) => onChange(option?.value)}
      options={options.filter(option => !bannedValues.includes(option.value))}
      sx={{ width: 300 }}
      renderInput={params => (
        <TextField
          error={props.error}
          helperText={props.error}
          className={props.className}
          label={props.label}
          fullWidth
          variant={'outlined'}
          {...params}
        />
      )}
    />
  );
}
