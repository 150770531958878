import { Avatar, Divider, Grid, TextField } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import SaveButton from '../../../../components/_common/SaveButton'

export default function NotesSection({ comment, setComment, comments, saveComment, loadingSaveComment }) {

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title w-25">
              Notes
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                {
                  comments.map(comment => (<>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar alt="" src={comment.user_avatar} />
                      </Grid>
                      <Grid justifyContent="left" item xs zeroMinWidth>
                        <h4 style={{ margin: 0, textAlign: "left" }}>{comment.user_fullname}</h4>
                        <p style={{ textAlign: "left" }}>
                          {
                            comment.note
                          }
                        </p>
                        <p style={{ textAlign: "left", color: "gray" }}>
                          posted {moment(comment.created_at).fromNow()}
                        </p>
                      </Grid>
                    </Grid>
                    <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
                  </>
                  ))
                }
              </div>
              <div className="col-1"></div>
            </div>

            <div className="row gutter-b">
              <div className="col-1"></div>
              <div className="col-10 pl-20">
                <TextField inputProps={{
                  style: { minHeight: 100 }
                }}
                  placeholder='Leave your comment here...'
                  multiline variant='outlined'
                  fullWidth
                  value={comment}
                  onChange={(e) => { setComment(e.target.value) }}
                />
              </div>
              <div className="col-1"></div>
            </div>

            <div className="row">
              <div className="col-11 d-flex justify-content-end">
                <SaveButton Icon={null} loading={loadingSaveComment} onClick={saveComment} title='Leave note' />
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}