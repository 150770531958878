import React from 'react'
import BaseFieldsConfiguration from '../../../../components/EditProfileForm/BaseFieldsConfiguration'
import SaveButton from '../../../../components/_common/SaveButton'

export default function RRCriteriaSection({
  riskCriteriaOptions,
  editCriteria,
  criteriaErrors,
  criteria,
  loadingCriteria,
  submitCriteria
}) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title w-25">
                Entity Risk Criteria
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-7">
                  {
                    riskCriteriaOptions.map(option =>
                    (<BaseFieldsConfiguration
                      option={option}
                      editTextFieldValue={editCriteria}
                      errors={criteriaErrors}
                      value={criteria}
                    />))
                  }
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end">
              <SaveButton title="Save criteria" savingTitle='Saving...' loading={loadingCriteria} onClick={submitCriteria} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}