import axios from 'axios'

export default async function fetchEDD(entity_id, csp_id) {
  const res = await axios.get("/company/entity-due-diligence", {
    params: {
      entity_id,
      csp_id
    }
  })
  return res.data
}