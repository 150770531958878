import React from 'react'
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import "./style.css"
import useTitlesWithRelations from '../../../hooks/useTitlesWithRelations';
import { getControllingPersonRelations, getControllingPersonTitles } from '../../../api/ui';
import useForm from '../../../hooks/useForm';
import StaffWindow from '../components/staff-window';

export default function CreateCompanyPage5(
  {
    staff,
    setStaff,
    currentPage,
    setCurrentPage,
    loading,
    goBack,
    jurisdictions,
    entityCategory
  }
) {
  const [
    personnel, showPersonnelModal, setShowPersonnelModal,
    staffErrors, editField, addAbstraction, editAbstraction, deleteAbstraction, saveAbstraction
  ] = useForm(staff, setStaff, { type: '' }, () => {
    setShowPersonnelModal(false)
    return Promise.resolve(true)
  })

  const [roles] = useTitlesWithRelations(entityCategory, getControllingPersonRelations, getControllingPersonTitles)

  const Submit = () => {
    // isStaffValid ? setCurrentPage(currentPage + 1) : notify({type: "ERROR", message: "You should add at least one shareholder"})

    // Not sure we need to require officers. The requester may very well be just the only officer in a company
    setCurrentPage(currentPage + 1);
  }


  const cols = [
    {
      id: 1,
      name: 'name',
      title: 'NAME',
      width: 300,
      sortable: true,
      component: (row) => {
        return (
          <span>{row.name || row.entity_name}</span>
        )
      }
    },
    {
      id: 2,
      name: 'type',
      title: 'TYPE',
      width: 300,
      sortable: true,
      component: (row) => {
        if (row.type === "human") {
          return <span>Individual</span>
        } else {
          return <span>Entity</span>
        }
      }
    },
    {
      id: 3,
      name: 'role',
      title: 'ROLE',
      width: 300,
      sortable: true,
      extraData: roles,
      component: (row, _, extraData) => {
        return extraData.find(({ value }) => value === row.role)?.title
      }
    },

    {
      id: 4,
      name: 'position',
      title: 'Position',
      width: 300,
      sortable: true
    },
    {
      name: 'actions',
      component: (row, index) => {
        return (
          <MoreDetailsDropdown row={row} options={[{
            id: 1,
            title: 'Edit',
            onClick: () => {
              editAbstraction(index)
            }
          },
          {
            id: 2,
            title: 'Delete',
            onClick: () => {
              deleteAbstraction(index)
            }
          },]} />
        )
      }
    }
  ]

  return (
    <>
      <StaffWindow
        {
        ... {
          showPersonnelModal,
          staffErrors,
          personnel,
          jurisdictions,
          roles,
          setShowPersonnelModal,
          editField,
          saveAbstraction
        }
        }
      />
      <div className="d-flex align-items-center justify-content-between border-0 pt-5">
        <div className="w-50 ml-0 align-items-start flex-column">
          <div style={{ fontSize: "1.5rem", color: "black" }} className="fw-bolder font-size-h2  mt-6 ml-2 m-0">Controlling Persons</div>
          <div style={{ fontSize: "10px", color: "#8F96A1" }} className="fw-bolder ml-2 m-0">Identity Directors, Officers, GP</div>
        </div>
        <div>
          <button
            className="submit-btn ml-2 font-weight-bold px-9 py-4 my-3"
            onClick={addAbstraction}
          >
            Add New
          </button>
        </div>
      </div>
      <Table nonSelectable entities={staff} cols={cols} />
      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="cancel-btn font-weight-bold px-9 py-4 my-3"
          onClick={goBack}
        >Back</button>
        <button
          type="submit"
          onClick={Submit}
          className="submit-btn font-weight-bold px-9 py-4 my-3"
        >
          <span>
            Next
          </span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>

      </div>

    </>
  )
}
