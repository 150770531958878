import Axios from "axios";
import store from "../store";

export const SET_TENANT_SLUG = "SET_TENANT_SLUG"
export const SET_CURRENT_CSP = "SET_CURRENT_CSP"
export const SET_AVAILABLE_CSPS = "SET_AVAILABLE_CSPS"
export const SET_AWAITING_RESULT = 'SET_AWAITING_RESULT'
export const SET_IS_AWAITING_CSP = 'SET_IS_AWAITING_CSP'
export const TRIGGER_CHECK_USER = 'TRIGGER_CHECK_USER'

export const triggerCheckUser = (value) => {
  store.dispatch({
    type: TRIGGER_CHECK_USER,
    payload: value
  })
}

export const setAwaitingResult = (awaitingResult) => {
  store.dispatch({
    type: SET_AWAITING_RESULT,
    payload: awaitingResult
  })
}

export const setIsAwaitingCSP = (value) => {
  store.dispatch({
    type: SET_IS_AWAITING_CSP,
    payload: value
  })
}

export const setTenantSlug = (tenant_slug) => {
  let current_tenant_slug = null;
  if (tenant_slug) {
    localStorage.setItem("tenant", tenant_slug);
    Axios.defaults.headers.common["x-tenant-slug"] = tenant_slug;
  } else {
    current_tenant_slug = localStorage.getItem("tenant")
    Axios.defaults.headers.common["x-tenant-slug"] = current_tenant_slug;
  }
  
  return {
    type: SET_TENANT_SLUG,
    payload: {
      slug: current_tenant_slug
    }
  }
}

export const setCurrentCSP = (csp) => {
  return {
    type: SET_CURRENT_CSP,
    payload: {
      csp
    }
  }
}

export const setAvailableCSPs = (csps) => {
  return {
    type: SET_AVAILABLE_CSPS,
    payload: {
      csps
    }
  }
}