import Axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import {getAsideMenuItems} from './asideMenuItems';
import { CANNY_FEEDBACK_CENTER } from '../../../../../config';
import {ToolTip} from '../../../../../app/components/Basic';
import KTLayoutAsideToggle from '../../../../_assets/js/layout/base/aside-toggle';
import {useSelector} from 'react-redux';

export function AsideMenuItem({ path, icon, title, submenu, tooltip, url }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = !!submenu) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-highlighted` : '';
  };

  if (url)
    return (
      <li className={`menu-item ${getMenuItemActive(path, false)}`} aria-haspopup="true">
        <a className="menu-link" href={url} target="_blank">
          <span className={`svg-icon menu-icon flex-center`}>
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
          <span className="menu-text pl-1">{title}</span>
        </a>
      </li>
    );

  return !submenu ? (
    <ToolTip title={title} placement="right" show={tooltip}>
      <li className={`menu-item ${getMenuItemActive(path, false)}`} aria-haspopup="true">
        <NavLink className="menu-link" to={path}>
          <span className={`svg-icon menu-icon flex-center`}>
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
          <span className="menu-text pl-1">{title}</span>
        </NavLink>
      </li>
    </ToolTip>
  ) : (
    <li
      className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material', true)}`}
      aria-haspopup="true"
      data-menu-toggle="hover"
    >
      <NavLink className="menu-link menu-toggle" to="/google-material">
        <span className={`svg-icon menu-icon flex-center`}>
          <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} />
        </span>
        <span className="menu-text">Material UI</span>
        <i className="menu-arrow" />
      </NavLink>
      <div className="menu-submenu ">
        <i className="menu-arrow" />
        <ul className="menu-subnav">
          {submenu.map((item, index) => (
            <li
              key={index}
              className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/inputs', true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">Inputs</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}

function FeedbackCenterItem({tooltip}) {
  const getToken = async e => {
    e.preventDefault();

    Axios.post('/auth/feedback-center-login').then(response => {
      if (response.data.token) {
        localStorage.setItem('cannyToken', response.data.token);
        // TODO
        window.open(CANNY_FEEDBACK_CENTER, '_blank');
      }
    });
  };

  return (
    <ToolTip title="Feedback Center" placement="right" show={tooltip}>
      <li className={`menu-item`} aria-haspopup="true">
        <a
          href="#"
          target="_blank"
          className="menu-link"
          onClick={e => {
            getToken(e);
          }}
        >
          <span className={`svg-icon menu-icon flex-center`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Feedback.svg')} />
          </span>
          <span className="menu-text pl-1">Feedback Center</span>
        </a>
      </li>
    </ToolTip>
  );
}

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.tenant?.csp?.tenant?.tenant_slug)
  const [asideMenuItems, setAsideMenuItems] = useState([])

  useEffect(() => {
    setAsideMenuItems(getAsideMenuItems(user))
  }, [tenant, user])

  const [tooltip, setTooltip] = useState(false)
  const secondAsideItems = useSelector(state => state.style.secondAsideMenu);

  // TODO replace EventListener with KTLayoutAsideToggle.onToggle
  useEffect(() => {
    const asideToggle = KTLayoutAsideToggle.getToggle()
    if (secondAsideItems) {
      asideToggle && asideToggle.toggleOn()
    } else {
      asideToggle && asideToggle.toggleOff()
    }
    setTooltip(asideToggle.state === 'on')
  }, [secondAsideItems])

  useEffect( () => {
    const asideTrigger = document.querySelector('#kt_aside_toggle')
    const state = KTLayoutAsideToggle.getToggle().state;
    setTooltip(state === 'on')
    asideTrigger.addEventListener('click', (e) => {
      const state = KTLayoutAsideToggle.getToggle().state
      setTooltip(state === 'on')
    })
  }, [])

  const location = useLocation();
  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      { asideMenuItems.map((item, index) => {
        return <AsideMenuItem {...item} tooltip={tooltip} key={index}/>;
      }) }
      <FeedbackCenterItem tooltip={tooltip} />
    </ul>
  );
}
