import React from "react";
import { useState } from "react";
import { FormInput } from "../../../components/Basic";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { TextField, MenuItem } from "@material-ui/core";
import useRequest from "../../../components/useRequest/useRequest";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";

export function ReportingPageRoute() {
  const params = useParams();

  const [selectedView, setSelectedView] = useState("standard");
  const [
    selectedStandardReportType,
    setSelectedStandardReportType
  ] = useState(-1);

  const [additionalInfo, setAdditionalInfo] = useState({});

  const [generateRequest, isSaving] = useRequest(() => {
    return Axios.post(
      `company/${params.entity_id}/standard_report/${selectedStandardReportType}`,
      additionalInfo
    )
  }, () => {
    notify({
      title: 'Success',
      type: 'SUCCESS',
      message: 'Report successfully generated'
    })
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  })

  const generate = () => {
    generateRequest()
  };

  return (
    <>
      <div className="col-lg-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div className="card-title w-50 ml-2 align-items-start flex-column">
            <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Reports</div>
          </div>
        </div>
        <div className=" col-lg-4 card-body pt-0 pb-5">
          {/* Standard reporting */}
          {selectedView === "standard" && (
            <div id="standard-reporting">
              <TextField
                variant="outlined"
                placeholder="Select report type"
                className="w-100"
                select
                w-title="Report type"
                type="text"
                name="report_type"
                onChange={e => setSelectedStandardReportType(e.target.value)}
                value={selectedStandardReportType}
              >
                <MenuItem value="-1">Select report type</MenuItem>
                <MenuItem value="register_of_directors">
                  Register of Directors
                </MenuItem>
                <MenuItem value="register_of_members_summary">
                  Register of Members Summary
                </MenuItem>
                <MenuItem value="register_of_members_log">
                  Register of Members Log
                </MenuItem>
                <MenuItem value="bos_report">Beneficial owners report</MenuItem>
                <MenuItem value="annual_return_partnership">
                  Annual return report — Partnership
                </MenuItem>
                <MenuItem value="annual_return_exempted">
                  Annual return report — Exempted
                </MenuItem>
                <MenuItem value="annual_return_ordinary">
                  Annual return report — Ordinary
                </MenuItem>
              </TextField>

              {/* Register of directors */}
              {selectedStandardReportType === "register_of_directors" && (
                <FormInput
                  w-title="Date"
                  type="date"
                  name="date"
                  onChange={e =>
                    setAdditionalInfo({ [e.target.name]: e.target.value })
                  }
                  value={additionalInfo.date}
                />
              )}

              {/* Register of members */}
              {(selectedStandardReportType === "register_of_members_summary" ||
                selectedStandardReportType === "register_of_members_log") && (
                <FormInput
                  w-title="Date"
                  type="date"
                  name="date"
                  onChange={e =>
                    setAdditionalInfo({ [e.target.name]: e.target.value })
                  }
                  value={additionalInfo.date}
                />
              )}

              {/* Annual return report — Ordinary */}
              {selectedStandardReportType === "annual_return_ordinary" && (
                <FormInput
                  w-title="Date"
                  type="date"
                  name="date"
                  onChange={e =>
                    setAdditionalInfo({ [e.target.name]: e.target.value })
                  }
                  value={additionalInfo.date}
                />
              )}

              <div className="mt-4">
              <button onClick={generate} disabled={isSaving} className="btn btn-primary">
                  {isSaving ?
                    (<><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Generating...</>)
                      : 'Generate'
                  }
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    </>
  );
}
