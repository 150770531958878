import React from 'react'
import { getCountries } from '../api/ui'
import useRequest from '../components/useRequest/useRequest'

export default function useCountries() {
  const [countries, setCountries] = React.useState([])
  const [fetchCountries] = useRequest(getCountries, (options) => {
    setCountries(Object.keys(options).map(key => ({
      value: key,
      title: options[key]
    })))
  })

  React.useEffect(() => {
    fetchCountries()
  }, [])

  return [countries]
}