export const values = {
	page1: {
		first_name: "",
		preferred_name: "",
		email_address: "",
		middle_name: "",
		last_name: "",
		date_of_birth: "",
		phone_number: "",
		alias: "",
		gender: "",
	},
	page2: {
		country_of_birth: "",
		nationalities: [],
		is_tax_resident: false,
		tax_resident_country: ''
	},
	page3: {
		moved_to_physical_address_at: "",
		also_operating_in_other_countries: false,
		also_operating_in_other_countries_country: ""
	},
	page4: {
		has_professional_designations: false,
		approved_by_other_authority: false,
		approved_by_other_authority_country: "",
		approved_by_other_authority_name: "",
		approved_by_other_authority_license_number: "",
		professional_designations: ""
	},
	page5: {
		involved_into_litigation: false,
		involved_into_litigation_details: "",
		ever_bankrupt: false,
		ever_bankrupt_year: "",
		ever_bankrupt_details: "",
		ever_investigated: false,
		ever_investigated_details: "",
		ever_criminal_offense: false,
		ever_criminal_offense_details: "",
		ever_censured: false,
		ever_censured_details: "",
		held_position_political_office: "",
		held_position_government_official: "",
		held_position_political_advisor: "",
		held_position_contacts_with_government: "",
		has_held_position_political_office: false,
		has_held_position_government_official: false,
		has_held_position_political_advisor: false,
		has_held_position_contacts_with_government: false,
	}
}