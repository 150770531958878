import React, { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import {FormInput, FormSelect, UploadButton} from '../../components/Basic';
import DualListBox from 'react-dual-listbox';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';

const steps_config = {
  add: ['Incoming', 'Add Documents', 'Get signatures'],
  remove: ['Outgoing', 'Add Documents', 'Get signatures'],
  replace: ['Outgoing', 'Incoming', 'Add Documents', 'Get signatures']
};

export default function ActionTab(props) {
  const [steps, setSteps] = useState(steps_config[props.action_type]);
  const [isLoading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [templates, setTemplates] = useState([]);

  const [user, setUser] = useState({
    related_documents: [],
    docs_status: 'available',
    user_type: 'individual',
    removal_reason: 'other'
  });

  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableSigners, setAvailableSigners] = useState([]);

  const [selectedSigners, setSelectedSigners] = useState([]);
  const [selectedUsersForRemoval, setSelectedUsersForRemoval] = useState([]);

  const [selectedUserInfo, setSelectedUserInfo] = useState(null);

  const related_documents_ref = useRef();

  const setFiles = e => {
    e.stopPropagation();
    e.preventDefault();

    const new_obj = [];

    for (const file of e.target.files) {
      new_obj.push(file);
    }

    setUser({ ...user, related_documents: new_obj });
  };

  const addDocumentsClick = () => {
    related_documents_ref.current.click();
  };

  const updateUser = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setUser({ ...user, [e.target.name]: value });
  };

  function handleNext() {
    if (!steps[activeStep + 1]) {
      if (props.action_type === 'add') {
        addStakeholder();
      } else if (props.action_type === 'remove') {
        removeStakeholder();
      } else if (props.action_type === 'replace') {
        replaceStakeholder();
      }

      return;
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    if (activeStep === 0) {
      if (props.onLeave) props.onLeave('directors');
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }

  const getTemplates = () => {
    Axios.get(`/csp/templates`).then(response => {
      setTemplates(response.data.csp_templates);
    });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // Update the available stakeholders list
  useEffect(() => {
    const users_options = [];
    const signers_options = [];

    for (const stakeholder of props.stakeholders) {
      const new_option = {
        label: stakeholder.person_user
          ? stakeholder.person_user.user_full_name
          : stakeholder.person_pending_full_name || stakeholder.staff_id,
        value: stakeholder.staff_id
      };

      users_options.push(new_option);

      if (stakeholder.person_user_id && stakeholder.person_status === 'active') {
        signers_options.push(new_option);
      }
    }

    // Set the available users
    setAvailableUsers(users_options);

    // Set the available signers
    setAvailableSigners(signers_options);
  }, [props.stakeholders]);

  // Update selected stakeholders list
  useEffect(() => {
    const new_arr = [];

    for (const staff_id in props.selected_stakeholders) {
      if (props.selected_stakeholders[staff_id]) new_arr.push(parseInt(staff_id, 10));
    }

    setSelectedUsersForRemoval([...new_arr]);
  }, [props.selected_stakeholders]);

  // Update the info about the selected user
  useEffect(() => {
    if (props.company_users && props.selected_user) {
      const user = props.company_users.find(user => user.user_id === props.selected_user);
      setSelectedUserInfo(user || null);
    }
  }, [props.selected_user, props.company_users]);

  const onSelectedSignersChange = selected => {
    setSelectedSigners(selected);
  };

  const onSelectedusersForRemovalChange = selected => {
    setSelectedUsersForRemoval(selected);
  };

  const replaceStakeholder = () => {
    setLoading(true);
    const form_data = new FormData();

    if (user.docs_status === 'available') {
      let file_i = 0;

      for (const file of user.related_documents) {
        form_data.append(file_i++, file);
      }
    }

    // Add info
    form_data.append('fullname', user.fullname);
    form_data.append('email', user.email);
    form_data.append('position', props.user_type === 'director' ? 'director' : user.position);
    form_data.append('date_of_appointment', user.date_of_appointment);
    form_data.append('date_of_resolution', user.date_of_resolution);
    form_data.append('user_type', user.user_type);
    form_data.append('docs_status', user.docs_status);

    // Remove info
    form_data.append('removal_reason', user.removal_reason);
    form_data.append('selected_stakeholders', selectedUsersForRemoval);
    form_data.append('date_of_removal', user.date_of_removal);

    if (user.selected_template_id) {
      form_data.append('selected_template_id', user.selected_template_id);
    }

    if (selectedSigners.length !== 0) {
      form_data.append('selected_signers', selectedSigners);
    }

    Axios.post(`company/${props.entity_id}/officer/replace`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then( () => {
        notify({ type: 'SUCCESS', title: 'Success!', message: 'Entity officer has been replaced' });
        if (props.onLeave) props.onLeave(props.user_type === 'director' ? 'directors' : 'officers');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeStakeholder = () => {
    setLoading(true);
    const form_data = new FormData();

    if (user.docs_status === 'available') {
      let file_i = 0;

      for (const file of user.related_documents) {
        form_data.append(file_i++, file);
      }
    }

    form_data.append('removal_reason', user.removal_reason);
    form_data.append('selected_stakeholders', selectedUsersForRemoval);
    form_data.append('date_of_removal', user.date_of_removal);
    form_data.append('date_of_resolution', user.date_of_resolution);
    form_data.append('docs_status', user.docs_status);

    if (user.selected_template_id) {
      form_data.append('selected_template_id', user.selected_template_id);
    }

    if (selectedSigners.length !== 0) {
      form_data.append('selected_signers', selectedSigners);
    }

    Axios.post(`company/${props.entity_id}/officer/delete`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then( () => {
        notify({ type: 'SUCCESS', title: 'Success!', message: 'Entity officer has been deleted' });
        if (props.onLeave) props.onLeave(props.user_type === 'director' ? 'directors' : 'officers');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addStakeholder = () => {
    setLoading(true);
    const form_data = new FormData();

    if (user.docs_status === 'available') {
      let file_i = 0;

      for (const file of user.related_documents) {
        form_data.append(file_i++, file);
      }
    }

    if (selectedUserInfo) {
      // User has selected a company user that is already a part of the company. We don't need their info because we already know that user
      form_data.append('selected_user_id', selectedUserInfo.user_id);

      // TODO @refactor is this a hack?
      form_data.append('fullname', selectedUserInfo.user_full_name);
      form_data.append('email', selectedUserInfo.emails[0]);
    } else {
      // User is adding a new director/officer that is not yet part of the system. We need info about that user
      form_data.append('fullname', user.fullname);
      form_data.append('email', user.email);
    }

    form_data.append('position', props.user_type === 'director' ? 'director' : user.position);
    form_data.append('date_of_appointment', user.date_of_appointment);
    form_data.append('date_of_resolution', user.date_of_resolution);
    form_data.append('user_type', user.user_type);
    form_data.append('docs_status', user.docs_status);

    if (user.selected_template_id) {
      form_data.append('selected_template_id', user.selected_template_id);
    }

    if (selectedSigners.length !== 0) {
      form_data.append('selected_signers', selectedSigners);
    }

    Axios.post(`company/${props.entity_id}/officer`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then( () => {
        notify({ type: 'SUCCESS', title: 'Success!', message: 'New Entity officer has been added' });
        if (props.onLeave) props.onLeave(props.user_type === 'director' ? 'directors' : 'officers');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const StepInbound = () => (
    <>
      {selectedUserInfo ? (
        <div>
          Selected user: <span style={{ fontWeight: 'bold' }}>{selectedUserInfo.user_full_name}</span>
        </div>
      ) : (
        <>
          <FormInput w-title placeholder="Name" type="text" name="fullname" onChange={updateUser} value={user.fullname} />
          <FormInput w-title placeholder="Email" type="email" name="email" onChange={updateUser} value={user.email} />
          <FormSelect w-title="Type" type="text" name="user_type" onChange={updateUser} value={user.user_type}>
            <option value="individual">Individual</option>
            <option value="entity">Entity</option>
          </FormSelect>
        </>
      )}
      {props.user_type !== 'director' && (
        <FormInput w-title placeholder="Position" type="text" name="position" onChange={updateUser} value={user.position} />
      )}
      <FormInput
        w-title="Date of appointment"
        placeholder="Date of appointment"
        type="date"
        name="date_of_appointment"
        onChange={updateUser}
        value={user.date_of_appointment}
      />
    </>
  );

  const StepOutbound = () => (
    <>
      <DualListBox
        showHeaderLabels
        canFilter
        className="ui-dual-listbox"
        options={availableUsers}
        selected={selectedUsersForRemoval}
        onChange={onSelectedusersForRemovalChange}
      />
      <FormSelect w-title="Reason" type="text" name="removal_reason" onChange={updateUser} value={user.removal_reason}>
        <option value="resigned">Resigned</option>
        <option value="removed">Removed</option>
        <option value="retired">Retired</option>
        <option value="deceased">Deceased</option>
        <option value="other">Other</option>
      </FormSelect>
      <FormInput
        w-title
        placeholder="Effective date"
        type="date"
        name="date_of_removal"
        onChange={updateUser}
        value={user.date_of_removal}
      />
    </>
  );

  const StepDocuments = () => (
    <div className="step-2">
      <FormSelect w-title="Documents status" type="text" name="docs_status" onChange={updateUser} value={user.docs_status}>
        <option value="available">I already have signed documents related to this user</option>
        <option value="not_available">I don't have any signed documents ready, use a template</option>
      </FormSelect>

      {user.docs_status === 'available' ? (
        <div className="mt-2">
          {user.related_documents.map(document => (
            <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer">
              Document {document.name} ({document.size}b)
            </div>
          ))}

          <input type="file" multiple ref={related_documents_ref} onChange={setFiles} hidden />

          <UploadButton onClick={addDocumentsClick}>
            + add documents
          </UploadButton>
        </div>
      ) : (
        <FormSelect
          w-title="Template"
          type="number"
          name="selected_template_id"
          onChange={updateUser}
          value={user.selected_template_id}
        >
          <option value="-1">Select a template</option>
          {templates.map(template => (
            <option value={template.document_id}>{template.document_title || `Document ${template.document_id}`}</option>
          ))}
        </FormSelect>
      )}

      <FormInput
        w-title
        placeholder="Date of resolution"
        type="date"
        name="date_of_resolution"
        onChange={updateUser}
        value={user.date_of_resolution}
      />
    </div>
  );

  const StepSignatures = () => (
    <DualListBox
      showHeaderLabels
      canFilter
      className="ui-dual-listbox"
      options={availableSigners}
      selected={selectedSigners}
      onChange={onSelectedSignersChange}
    />
  );

  return (
    <div className="add-director-tab">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="d-flex justify-content-center flex-column w-50" style={{ margin: '0 auto' }}>
        {activeStep === 0 && (
          <>
            {props.action_type === 'add' ? (
              <div className="step-1">{StepInbound()}</div>
            ) : (
              <div className="step-1">{StepOutbound()}</div>
            )}
          </>
        )}

        {activeStep === 1 && (
          <>
            {props.action_type === 'replace' ? (
              <div className="step-2">{StepInbound()}</div>
            ) : (
              <div className="step-2">{StepDocuments()}</div>
            )}
          </>
        )}

        {activeStep === 2 && (
          <>
            {props.action_type === 'replace' ? (
              <div className="step-3">{StepDocuments()}</div>
            ) : (
              <div className="step-3">{StepSignatures()}</div>
            )}
          </>
        )}

        {activeStep === 3 && <div className="step-4">{StepSignatures()}</div>}
        <div className="d-flex justify-content-between mb-3 mt-8">
          <Button onClick={handleBack}>Back</Button>
          <Button color="primary" onClick={handleNext} disabled={isLoading}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
