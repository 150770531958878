import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { TextField } from '@material-ui/core';

import { FormSelect } from "../components/Basic";
import {Table } from "../modules/Auth/pages/customers/customers-table/CustomersTable";
import _ from 'lodash'

const default_chart_data = {
  labels: ["CSP Review", "Client Review", "Ready to File", "Filed"],
  datasets: [
    {
      label: "Statistics",
      data: [0, 0, 0, 0, 0],
      backgroundColor: [
        "rgba(94, 212, 255, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)"
      ],
      borderColor: [
        "rgba(94, 212, 255, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)"
      ],
      borderWidth: 1
    }
  ]
};

const CHART_OPTIONS = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const TASK_COLUMNS = [
  {
    id: 1,
    name: "entity_name",
    title: "Entity",
    width: "200px"
  },
  {
    id: 2,
    name: "status",
    title: "Status",
    width: "200px"
  }
];

export default function CSPESNPage() {
  const [taskSets, setTaskSets] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksTable, setTasksTable] = useState([]);
  const [stateDescriptors, setStateDescriptors] = useState({});
  const [selectedTasks, setSelectedTasks] = useState([]);

  const [chartData, setChartData] = useState(default_chart_data);

  const [selectedTaskSet, setSelectedTaskSet] = useState();
  const [selectedTaskStatus, setSelectedTaskStatus] = useState();

  const [messageToAdmins, setMessageToAdmins] = useState("");

  const [changeTaskStatusModalShown, setChangeTaskStatusModalShown] = useState(
    false
  );
  const [sendMessageModalShown, setSendMessageModalShown] = useState(false);
  const [generateCSVModalShown, setGenerateCSVModalShown] = useState(false);

  const [selectedEntities, setSelectedEntities] = useState([])

  const getTaskSets = () => {
    Axios.get("csp/task-sets")
      .then(({ data }) => {
        setTaskSets(data.task_sets);
      })
      .catch(() => {});
  };

  const getTasks = () => {
    Axios.get(`csp/task-sets/${selectedTaskSet}`)
      .then(({ data }) => {
        const state_descriptors = {};

        // TODO @cleanup maybe do this on the backend?
        for (const state_descriptor of data.tasks_category
          .task_state_descriptors) {
          state_descriptors[state_descriptor.state_id] = state_descriptor;
        }

        setStateDescriptors(state_descriptors);

        // Create the tasks table
        const tasks_table = [];
        const new_chart_data = _.cloneDeep(chartData)

        for (const task of data.tasks) {
          let status = "";

          // Create the status text
          if (task.task_status === "ready_to_file") {
            status = "Ready to file";
            new_chart_data.datasets[0].data[2] += 1;
          }
          if (task.task_status === "filed") {
            status = "Filed";
            new_chart_data.datasets[0].data[3] += 1;
          } else if (task.task_status === "csp_review") {
            status = "CSP review";
            new_chart_data.datasets[0].data[0] += 1;
          } else if (task.task_status === "client_review") {
            status = "Client review";
            new_chart_data.datasets[0].data[1] += 1;
          }

          if (!status) status = task.task_status;

          tasks_table.push({
            task_id: task.task_id,
            entity_name: task.customer_company
              ? task.customer_company.company_name
              : "N/A",
            status
          });
        }

        setTasksTable(tasks_table);

        setChartData({ ...new_chart_data });

        setTasks(data.tasks);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateTaskStatuses = () => {
    const task_ids = [];

    for (const task_id in selectedTasks) {
      if (selectedTasks[task_id]) task_ids.push(task_id);
    }

    Axios.post("task/update-statuses", {
      task_ids: selectedEntities,
      new_status: selectedTaskStatus
    })
      .then(() => {
        getTasks();
        setChangeTaskStatusModalShown(false);
      })
      .catch(() => {});
  };

  const generateCSVReport = () => {
    const task_ids = [];

    for (const task_id in selectedTasks) {
      if (selectedTasks[task_id]) task_ids.push(task_id);
    }

    Axios.post("csp/certification/generate-csv", {
      task_ids: selectedEntities
    })
      .then(() => {
        setGenerateCSVModalShown(false);
      })
      .catch(() => {});
  };

  const sendMessageToAdmins = () => {
    const task_ids = [];

    for (const task_id in selectedTasks) {
      if (selectedTasks[task_id]) task_ids.push(task_id);
    }

    Axios.post("csp/certification/message-admins", {
      task_ids: selectedEntities,
      message: messageToAdmins
    })
      .then(() => {
        setSendMessageModalShown(false);
      })
      .catch(() => {});
  };

  const onSelectionChange = task => {
    setSelectedTasks({
      ...selectedTasks,
      [task.task_id]: !selectedTasks[task.task_id]
    });
  };

  // Get task sets on mount
  useEffect(getTaskSets, []);

  // Get the tasks on selected tasks set update
  useEffect(() => {
    if (selectedTaskSet && selectedTaskSet !== "-1") getTasks();
  }, [selectedTaskSet]);

  const plot = useMemo(() => <Bar data={chartData} options={CHART_OPTIONS} />, [chartData])

  return (
    <div>
      <Modal
        show={changeTaskStatusModalShown}
        onHide={() => setChangeTaskStatusModalShown(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change selected task's status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>You are about to change the status of selected tasks</div>

          <FormSelect
            type="text"
            name="selected_new_status"
            onChange={e => setSelectedTaskStatus(e.target.value)}
            value={selectedTaskStatus}
          >
            <option value="-1">Select a new status</option>
            <option value="csp_review">CSP review</option>
            <option value="client_review">Client review</option>
            <option value="ready_to_file">Ready to file</option>
            <option value="filed">Filed</option>
          </FormSelect>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setChangeTaskStatusModalShown(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={updateTaskStatuses}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={generateCSVModalShown}
        onHide={() => setGenerateCSVModalShown(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>You are about to generate a CSV report</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setGenerateCSVModalShown(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={generateCSVReport}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={sendMessageModalShown}
        onHide={() => setSendMessageModalShown(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send message to company admins</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            You are about to send a message to the admins of the entities that
            are related to the tasks you have selected
          </div>

          <TextField
            label="Message"
            multiline
            value={messageToAdmins}
            onChange={e => setMessageToAdmins(e.target.value)}
            margin="normal"
            rows={5}
            variant="outlined"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSendMessageModalShown(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={sendMessageToAdmins}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title w-50 mb-4 align-items-start flex-column py-4">
                <div className="card-label">Annual Report status</div>
                <div className="text-muted mt-3 font-weight-bold font-size-sm">CSP's ESN/ESR/Annual report status</div>
              </div>
            </div>

            <div className="card-body">
              <FormSelect
                type="number"
                name="selected_task_set"
                onChange={ e => setSelectedTaskSet(e.target.value) }
                value={ selectedTaskSet }
              > 
                <option value="-1">
                  Select a task set
                </option>
                { taskSets.map(task_set => (
                  <option key={ task_set.set_id } value={ task_set.set_id }>{ task_set.set_title }</option>
                )) }
              </FormSelect>

              <div className="text-muted mt-4 font-size-sm">Select a task set to view it's details</div>
            </div>
          </div>


          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title mb-4 w-50 align-items-start flex-column py-4">
                <div className="card-label">Task set details</div>
                <div className="text-muted mt-3 font-weight-bold font-size-sm">Detailed information about a selected task set</div>
              </div>

              <div className="card-toolbar">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle className="btn btn-light ui-dropdown">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
                      </span>
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={ () => setSendMessageModalShown(true) }>Send message / reminder</Dropdown.Item>
                      <Dropdown.Item onClick={ () => setChangeTaskStatusModalShown(true) }>Update status</Dropdown.Item>
                      <Dropdown.Item onClick={ () => setGenerateCSVModalShown(true) }>Create CSV file for bulk upload</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            
            
            <div className="card-body">
              <div className="d-flex align-items-start justify-content-between">
                <div className="w-50 pr-4">
                  <div className="my-4">
                    {/* <DataTable
                      isCheckbox
                      columns={ TASK_COLUMNS }
                      rows={ tasksTable }
                      artifactBaseURL="task"
                      onSelectionChange={ onSelectionChange }
                      idField="task_id"
                    /> */}
                    <Table
                      cols={TASK_COLUMNS}
                      entities={ tasksTable }
                      idField="task_id"
                      // selectedRows={selectedTasks}
                      // setSelectedRows={setSelectedTasks}
                      // selectedEntities={selectedEntities}
                      setSelectedEntities={setSelectedEntities}
                    />
                  </div>
                </div>
                <div className="w-50 pl-4">
                  {/* TODO!!!! @performance @cleanup use useMemo for the graph */}
                  {/* <Bar data={chartData} options={CHART_OPTIONS} /> */}
                  { plot }
                </div>
              </div>

            </div>

 
          </div>
        </div>
      </div>
    </div>
  );
}
