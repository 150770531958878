import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { Slide } from '@material-ui/core';


const style = {
  main: {
    // width: '300px',
    // height: '100px',
    // zIndex: '10000',
    // position: 'fixed',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    gap: 10
    // paddingBottom: '10px',
    // display: 'flex',
  },
  content: {
    backgroundColor: 'red',
    color: 'white',
    flex: 1
  },
  container: {
    position: 'fixed',
    zIndex: '10000',
    width: 0,
    height: 0,
    right: 320
  }
}

function SnackComponent({snack, index}) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
    setTimeout(() => {
      setMounted(false)
    }, snack.duration - 300)
    return () => {
    }
  }, [])

  return (
    <Slide in={mounted} direction="left" >
      <Alert severity={snack.type.toLowerCase()} variant="filled">
        <AlertTitle >
          {snack.title}
        </AlertTitle>
          {snack.message}
      </Alert>
    </Slide>


  )
}

export default function NotificationSnackbar () {
  const snackbar = useSelector(state => state.snackbar);

  const snacksList = snackbar.map((snack, index) =>
      <SnackComponent key={snack.id} index={index} snack={snack} />
    )

  return (
    <>
      <div style={style.container}>
        <div style={style.main}>
          {snacksList}
        </div>

      </div>
    </>
  )
}
