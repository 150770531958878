import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../../_metronic/_partials/dropdowns';
import { DropdownMenu } from './DropdownMenu';

export function Card({ className, title, children }) {
  return (
    <div className={`card card-custom  rounded-lg ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder text-dark font-size-h3 w-50">{title || 'Card Title'}</h3>
        <div className="card-toolbar">
          <ItemDropdown item="" />
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-2">{children}</div>
    </div>
  );
}

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
