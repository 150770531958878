import React from 'react'
import { CustomDatePicker, FormButton, FormInput, FormSelect, FormSwitch } from '../../../components/Basic';
import { selectOptions } from '../SelectOptions';

export default function HDDAmlQuestions({
  page5Formik,
  getDate,
  goBack,
  loading
}) {
  return (
    <form onSubmit={page5Formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="Have you been involved in any litigation?"
          name="involved_into_litigation"
          className="m-1"
          value={page5Formik.values.involved_into_litigation}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.involved_into_litigation && (
          <>
            <FormInput
              mui
              multiline
              label="Please explain"
              name="involved_into_litigation_details"
              value={page5Formik.values.involved_into_litigation_details}
              onBlur={page5Formik.handleBlur}
              onChange={page5Formik.handleChange}
              error={
                page5Formik.errors.involved_into_litigation_details &&
                page5Formik.touched.involved_into_litigation_details
              }
              helperText={
                page5Formik.errors.involved_into_litigation_details &&
                  page5Formik.touched.involved_into_litigation_details
                  ? page5Formik.errors.involved_into_litigation_details
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="Have you been declared bankrupt by a court in any jurisdiction?"
          name="ever_bankrupt"
          className="m-1"
          value={page5Formik.values.ever_bankrupt}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.ever_bankrupt && (
          <>
            <CustomDatePicker
              label="When did this happen"
              format="yyyy"
              name="ever_bankrupt_year"
              views={['year']}
              minDate={getDate(-80, 'y')}
              maxDate={getDate()}
              value={page5Formik.values.ever_bankrupt_year}
              onChange={value =>
                page5Formik.setFieldValue('ever_bankrupt_year', value)
              }
              onBlur={page5Formik.handleBlur}
              error={
                page5Formik.errors.ever_bankrupt_year
                && page5Formik.touched.ever_bankrupt_year}
              helperText={page5Formik.touched.ever_bankrupt_year ? page5Formik.errors.ever_bankrupt_year : null}
            />
            <FormInput
              mui
              multiline
              label="Please explain"
              name="ever_bankrupt_details"
              value={page5Formik.values.ever_bankrupt_details}
              onBlur={page5Formik.handleBlur}
              onChange={page5Formik.handleChange}
              error={page5Formik.errors.ever_bankrupt_details && page5Formik.touched.ever_bankrupt_details}
              helperText={
                page5Formik.touched.ever_bankrupt_details && page5Formik.errors.ever_bankrupt_details
                  ? page5Formik.errors.ever_bankrupt_details
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="Have you ever been subject to an investigation by any tax authorities in any jurisdiction?"
          name="ever_investigated"
          className="m-1"
          value={page5Formik.values.ever_investigated}
          onChange={page5Formik.handleChange}
        />

        {page5Formik.values.ever_investigated && (
          <>
            <FormInput
              mui
              multiline
              label="Please explain"
              name="ever_investigated_details"
              value={page5Formik.values.ever_investigated_details}
              onChange={page5Formik.handleChange}
              onBlur={page5Formik.handleBlur}
              error={page5Formik.errors.ever_investigated_details && page5Formik.touched.ever_investigated_details}
              helperText={
                page5Formik.touched.ever_investigated_details && page5Formik.errors.ever_investigated_details
                  ? page5Formik.errors.ever_investigated_details
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="Have you at any time been charged or convicted of any criminal offence by any court (whether civil,
          military or criminal) excluding traffic offences or spent convictions?"
          name="ever_criminal_offense"
          className="m-1"
          value={page5Formik.values.ever_criminal_offense}
          onChange={page5Formik.handleChange}
        />

        {page5Formik.values.ever_criminal_offense && (
          <>
            <FormInput
              mui
              multiline
              label="Please explain"
              name="ever_criminal_offense_details"
              value={page5Formik.values.ever_criminal_offense_details}
              onChange={page5Formik.handleChange}
              onBlur={page5Formik.handleBlur}
              error={
                page5Formik.errors.ever_criminal_offense_details &&
                page5Formik.touched.ever_criminal_offense_details
              }
              helperText={
                page5Formik.touched.ever_criminal_offense_details &&
                  page5Formik.errors.ever_criminal_offense_details
                  ? page5Formik.errors.ever_criminal_offense_details
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="Have you ever been censured, suspended or otherwise criticised by any professional body"
          name="ever_censured"
          className="m-1"
          value={page5Formik.values.ever_censured}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.ever_censured && (
          <>
            <FormInput
              mui
              multiline
              label="Please explain"
              name="ever_censured_details"
              value={page5Formik.values.ever_censured_details}
              onChange={page5Formik.handleChange}
              onBlur={page5Formik.handleBlur}
              error={page5Formik.errors.ever_censured_details && page5Formik.touched.ever_censured_details}
              helperText={
                page5Formik.touched.ever_censured_details && page5Formik.errors.ever_censured_details
                  ? page5Formik.errors.ever_censured_details
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="form-group fv-plugins-icon-container mt-5">
        <div className="font-size-h6 font-weight-bold mb-4 ml-2">
          Do you or have you held any of the following positions
        </div>

        <FormSwitch
          label="Political Office"
          name="has_held_position_political_office"
          className="m-1"
          value={page5Formik.values.has_held_position_political_office}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.has_held_position_political_office && (
          <FormSelect
            mui
            toLowerCase
            name="held_position_political_office"
            label="Political office"
            value={page5Formik.values.held_position_political_office}
            onChange={page5Formik.handleChange}
            onBlur={() => {
              page5Formik.setFieldTouched('held_position_political_office');
            }}
            options={selectOptions.heldPositionsOptions}
            error={
              page5Formik.errors.held_position_political_office &&
              page5Formik.touched.held_position_political_office
            }
            helperText={
              page5Formik.errors.held_position_political_office &&
                page5Formik.touched.held_position_political_office
                ? page5Formik.errors.held_position_political_office
                : null
            }
          />
        )}

        <FormSwitch
          label="Senior Government Official (with budgetary authority)"
          name="has_held_position_government_official"
          className="m-1"
          value={page5Formik.values.has_held_position_government_official}
          onChange={page5Formik.handleChange}
        />

        {page5Formik.values.has_held_position_government_official && (
          <FormSelect
            mui
            toLowerCase
            label="Senior Government Official (with budgetary authority)"
            name="held_position_government_official"
            value={page5Formik.values.held_position_government_official}
            onChange={page5Formik.handleChange}
            onBlur={() => page5Formik.setFieldTouched('held_position_government_official')}
            options={selectOptions.heldPositionsOptions}
            error={
              page5Formik.errors.held_position_government_official &&
              page5Formik.touched.held_position_government_official
            }
            helperText={
              page5Formik.touched.held_position_government_official &&
                page5Formik.errors.held_position_government_official
                ? page5Formik.errors.held_position_government_official
                : null
            }
          />
        )}

        <FormSwitch
          label="Political Advisor"
          name="has_held_position_political_advisor"
          className="m-1"
          value={page5Formik.values.has_held_position_political_advisor}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.has_held_position_political_advisor && (
          <FormSelect
            mui
            toLowerCase
            label="Political Advisor"
            name="held_position_political_advisor"
            value={page5Formik.values.held_position_political_advisor}
            onChange={page5Formik.handleChange}
            onBlur={() => page5Formik.setFieldTouched('held_position_political_advisor')}
            options={selectOptions.heldPositionsOptions}
            error={
              page5Formik.errors.held_position_political_advisor &&
              page5Formik.touched.held_position_political_advisor
            }
            helperText={
              page5Formik.errors.held_position_political_advisor &&
                page5Formik.touched.held_position_political_advisor
                ? page5Formik.errors.held_position_political_advisor
                : null
            }
          />
        )}
        <FormSwitch
          label="Contracts with government or supranational or sovereign entities"
          name="has_held_position_contacts_with_government"
          className="m-1"
          value={page5Formik.values.has_held_position_contacts_with_government}
          onChange={page5Formik.handleChange}
        />
        {page5Formik.values.has_held_position_contacts_with_government && (
          <FormSelect
            mui
            toLowerCase
            label="Contracts with government or supranational or sovereign entities"
            name="held_position_contacts_with_government"
            value={page5Formik.values.held_position_contacts_with_government}
            onBlur={() => page5Formik.setFieldTouched('held_position_contacts_with_government')}
            onChange={page5Formik.handleChange}
            options={selectOptions.heldPositionsOptions}
            error={
              page5Formik.errors.held_position_contacts_with_government &&
              page5Formik.touched.held_position_contacts_with_government
            }
            helperText={
              page5Formik.errors.held_position_contacts_with_government &&
                page5Formik.touched.held_position_contacts_with_government
                ? page5Formik.errors.held_position_contacts_with_government
                : null
            }
          />
        )}
      </div>
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Back
        </button>

        <FormButton label="Next" type="submit" color="secondary" id="kt_create_csp_submit">
          {loading && <span className="ml-3 spinner spinner-white" />}
        </FormButton>
      </div>
    </form>
  )
}