import React, { useState, useEffect } from "react";
import { WidgetTable } from "../../../components/WidgetTable/WidgetTable";
import {useSelector} from 'react-redux';
import {
  getAllUserNotifications
} from '../../../../redux/UserNotifications/userNotificationsActions';
import {ActionCenter} from './ActionCenter';

const statusCols = [
  {
    name: "filing",
    title: "Task",
    width: 350
  },
  {
    name: "next_due_date",
    title: "Next Due Date",
    width: 200
  },
  {
    name: "status",
    title: "Status",
    width: 200
  }
];

const statusRows = [
  // {
  //   id: 1,
  //   filing: "AAAA",
  //   next_due_date: "30-05-2023",
  //   status: "Overdue"
  // },
  // {
  //   id: 2,
  //   filing: "AAAA",
  //   next_due_date: "30-05-2023",
  //   status: "Overdue"
  // }
];


export default function HomeRoute(props) {
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    if(props.basicUserInfo.user_id && props.basicUserInfo.user_id !== currentUserId) {
      props.queryAdditionalInfo("tasks");
      setCurrentUserId(props.basicUserInfo.user_id)
    }
  }, [props.basicUserInfo.user_id]);

  const { notifications } = useSelector(state => state.userNotifications);

  useEffect(() => {
    getAllUserNotifications();
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <ActionCenter className="bg-white mb-6" options={notifications}/>
      </div>
      <div className="col-lg-12">
        <WidgetTable
          {...{ cols: statusCols, rows: statusRows, title: "Filing status" }}
        />
      </div>
    </>
  );
}
