import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import { Switch, Route } from "react-router-dom";
import { useSecondAside } from "../../../_metronic/layout/components/aside/SecondAside";
import UserProfileHeader from "./UserProfileHeader/UserProfileHeader";
import HomeRoute from "./routes/HomeRoute";
import VaultRoute from "./routes/VaultRoute";
import DueDiligenceRoute from "./routes/DueDiligenceRoute";
import DetailsRoute from "./routes/DetailsRoute";
import ComplianceRoute from "./routes/ComplianceRoute";
import HistoryRoute from "./RelatedEntities/HistoryRoute";
import RelatedEntitiesRoute from "./RelatedEntities/RelatedEntitiesRoute";
import AddressRoute from "./routes/AddressRoute";
import { menuDetails } from './profileDetails';
import { useSelector } from 'react-redux';
import IndividualDueDiligence from "../StaffOnboarding/StaffOnboardingPage";

function UserProfilePage(props) {
  const user_id = props.match.params.user_id;

  const [basicUserInfo, setBasicUserInfo] = useState({});
  const [additionalUserInfo, setAdditionalUserInfo] = useState({});


  const appendAdditionalInfo = data => {
    setAdditionalUserInfo({ ...additionalUserInfo, ...data });
  }

  const getBasicUserInfo = () => {
    Axios.get(`user/${user_id}/profile`)
      .then(response => {
        if (response.data && response.data.user_profile) {
          setBasicUserInfo(response.data.user_profile);
        }
      })
      .catch(() => { });
  }

  const getUserTasks = () => {
    Axios.get(`user/${user_id}/tasks?limit=5`)
      .then(response => {
        if (response.data && response.data.tasks) {
          const tasks = response.data.tasks.map(task => {
            task.link = "/tasks/" + task.task_id;

            return task;
          })

          setAdditionalUserInfo({ ...additionalUserInfo, tasks });
        }
      })
      .catch(() => {
      });
  }

  const getUserRelatedEntities = () => {
    Axios.get(`user/${user_id}/related-entities`)
      .then(response => {
        if (response.data && response.data.related_entities) {
          setAdditionalUserInfo({ ...additionalUserInfo, related_entities: response.data.related_entities });
        }
      })
      .catch(() => {
      });
  }

  const getUserIDs = () => {
    Axios.get(`user/${user_id}/ids`)
      .then(response => {
        if (response.data && response.data.ids) {
          setAdditionalUserInfo({ ...additionalUserInfo, ids: response.data.ids });
        }
      })
      .catch(() => {
      });
  }

  const getUserShareTransactions = () => {
    Axios.get(`user/${user_id}/share-transactions`)
      .then(response => {
        if (response.data && response.data.share_transactions) {
          const transactions = response.data.share_transactions.map(transaction => {
            const completed_on = transaction.transaction_completed_on && moment(transaction.transaction_completed_on);

            transaction._company_name = transaction.customer_company
              && transaction.customer_company.company_name;

            transaction._from = transaction.from_user && transaction.from_user.user_full_name;
            transaction._to = transaction.to_user && transaction.to_user.user_full_name;

            transaction._initiator = transaction.initiator_user && transaction.initiator_user.user_full_name;
            transaction._completed_on = completed_on ? completed_on.format("DD/MMM/YYYY") : "N/A";

            return transaction;
          })

          setAdditionalUserInfo({ ...additionalUserInfo, share_transactions: transactions });
        }
      })
      .catch(() => {
      });
  }

  const queryAdditionalInfo = type => {
    if (type === "tasks" && !additionalUserInfo.tasks) getUserTasks();
    else if (type === "related_entities" && !additionalUserInfo.related_entities) getUserRelatedEntities();
    else if (type === "ids" && !additionalUserInfo.ids) getUserIDs();
    else if (type === "share_transactions" && !additionalUserInfo.share_transactions) getUserShareTransactions();
  }

  const csp = useSelector(state => state?.tenant?.csp);

  useEffect(() => {
    const hasUserID = basicUserInfo.user_id && basicUserInfo.user_id !== user_id;
    if (csp && (hasUserID || !Object.keys(basicUserInfo).length)) {
      getBasicUserInfo();
    }
  }, [user_id, csp]);

  const replacedMenuDetails = menuDetails.map(detail => ({
    ...detail,
    submenu: detail.submenu?.map(submenuDetail => ({
      ...submenuDetail,
      path: submenuDetail.path?.replace("{{user_id}}", user_id)
    })),
    path: detail.path?.replace("{{user_id}}", user_id)
  }));

  useSecondAside(replacedMenuDetails);

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <UserProfileHeader basicUserInfo={basicUserInfo} />
        <div className="row">
          <Switch>
            <Route path="/user/:user_id/due-diligence">
              <div className="col-lg-12">
                <IndividualDueDiligence />
              </div>

            </Route>
            <Route path="/user/:user_id/vault" component={VaultRoute} />
            <Route path="/user/:user_id/address">
              <AddressRoute user_id={user_id} />
            </Route>
            <Route path="/user/:user_id/details">
              <DetailsRoute
                basicUserInfo={basicUserInfo}
                additionalUserInfo={additionalUserInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/user/:user_id/compliance">
              <ComplianceRoute
                basicUserInfo={basicUserInfo}
                additionalUserInfo={additionalUserInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/user/:user_id/related_entities/history">
              <HistoryRoute
                basicUserInfo={basicUserInfo}
                additionalUserInfo={additionalUserInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
            <Route path="/user/:user_id/related_entities/entities">
              <RelatedEntitiesRoute
                basicUserInfo={basicUserInfo}
                additionalUserInfo={additionalUserInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>

            <Route path="/user/:user_id" exact>
              <HomeRoute
                basicUserInfo={basicUserInfo}
                additionalUserInfo={additionalUserInfo}
                appendAdditionalInfo={appendAdditionalInfo}
                queryAdditionalInfo={queryAdditionalInfo}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default UserProfilePage;
