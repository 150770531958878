import { uniqueId } from 'lodash-es'
import React, { useEffect, useRef, useState } from 'react'
import { notify } from '../../../../redux/NotificationSnackbar/snackbarActions'
import ActionsDropdown from '../../../components/Dropdown/ActionsDropdown'
import FileBox from '../../../components/FileBox'
import "./style.css"

export default function CreateCompanyPage6({ onDocumentsChange = () => { }, goBack,
  setCurrentPage,
  currentPage,
  loading,
  ...props }) {
  const actionOptions = [
    {
      id: 1,
      title: 'Upload documents',
      onClick: (e) => {
        filesRef.current.click()
      }
    }
  ]

  const Submit = () => {
    documents?.length <= 10 ? setCurrentPage(currentPage + 1) : notify({ type: "ERROR", title: "Too much files", message: "Max count of documents - 10, please delete some" })
  }

  const [documents, setDocuments] = useState([...props.documents])
  const filesRef = useRef()

  useEffect(() => {
    onDocumentsChange(documents)
  }, [documents])

  const onFilesChange = (e) => {
    const newFiles = Array.prototype.slice.call(e.target.files)
    const newDocuments = newFiles.map(file => ({
      file: file,
      document_id: uniqueId(),
      document_title: file.name,
      document_mime_type: file.type
    }))
    setDocuments(oldDocuments => [...oldDocuments, ...newDocuments])
  }

  return (<>
    <div className="card">
      <div className="card-header border-0 d-flex justify-content-between">
        <div className="card-title w-50">
          Documents
        </div>

        <div className="card-toolbar">
          <ActionsDropdown options={actionOptions} />
        </div>
      </div>
      <div className="card-body">
        <input type="file" style={{ display: 'none' }} multiple ref={filesRef} onChange={onFilesChange} />
        <div className="row">
          {
            documents.map((document, index) => {
              return (
                <FileBox
                  className='col-2'
                  options={
                    [
                      {
                        title: 'Remove document',
                        onClick: () => {
                          setDocuments(old => old.filter((_, i) => i !== index))
                        }
                      }
                    ]
                  }
                  document={document} />

              )
            })
          }
        </div>

      </div>

    </div>

    <div className="form-group d-flex align-items-center justify-content-between">
      <button
        type="button"
        className="cancel-btn font-weight-bold px-9 py-4 my-3"
        onClick={goBack}
      >Back</button>
      <button
        type="submit"
        onClick={Submit}
        className="submit-btn font-weight-bold px-9 py-4 my-3"
      >
        <span>
          Next
        </span>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </button>

    </div>
  </>)
}
