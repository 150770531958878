import React from "react";
import "../styles/GeneralRoute.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles({
  root: {
    width: "97%",
    height: "100%",
    paddingLeft: 30,
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 30,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 15
  }
});

function GeneralOverview() {
  const classes = useStyles();
  return (
    <div>
      <div className="d-flex  align-items-center">
        <h3 className="block-title mr-5">General</h3>
        <button className="edit-btn">
          <CreateIcon fontSize={"small"} /> Edit
        </button>
      </div>
      <div className="mt-5">
        <span className="overview-list-title">Business Overview</span>
        <ul className="overview-list">
          <li>Reporting Requirements: </li>
          <li>conomic Substance Category: </li>
          <li>CRS / FATCA category: </li>
          <li>Regulatory Status: </li>
        </ul>
      </div>

      <div className="mt-5">
        <span className="overview-list-title">
          Beneficial Ownership Status (reporting or exempt)
        </span>
        <ul className="overview-list">
          <li>Entity Type: </li>
          <li>Jurisdiction: </li>
          <li>Registration #: </li>
          <li>Date of Incorporation: </li>
          <li>Phone #: </li>
          <li>Website: </li>
          <li>Fiscal Year End: </li>
        </ul>
      </div>
    </div>
  );
}

export default GeneralOverview;
