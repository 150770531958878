/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from 'react'
import 'react-phone-input-2/lib/material.css'
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions'
import _ from 'lodash'
import { validate } from '../../helpers/validation'
import SimpleModal from '../Modals/SimpleModal'
import BaseFieldsConfiguration from './BaseFieldsConfiguration'

export default function SimpleModalFieldsConfiguration({ value, currentOption, onChange, validationContext, open = false, onClose = () => { } }) {
  const [errors, setErrors] = useState(() => {
    const errorsState = {}
    currentOption.options.filter(option => !!option.validation).forEach(option => errorsState[option.name] = '')
    return errorsState
  })

  const [data, setData] = useState(value)

  const ref = useRef()

  ref.current = value

  useEffect(() => {
    setData(value)
  }, [value])

  const editTextFieldValue = (editedValue, fieldName) => {
    setData(old => {
      return {
        ...old,
        [fieldName]: editedValue
      }
    })
  }

  const onCloseModal = () => {
    setData(ref.current);
    onClose();
  }

  const onSaveModal = async () => {
    const openSwitchOptions = _.concat(...currentOption.options.filter(option => option.type === 'open-switch' && !!data[option.name]).map(option => option.options))
    const [validationResult, validationErrors] = await validate(_.concat(currentOption.options, openSwitchOptions), data, validationContext)
    setErrors({ ...validationErrors })
    if (validationResult) {
      onChange(data)
    } else {
      notify({ type: "ERROR", message: 'There are some fields you need to fill' })
      throw new Error()
    }
  }

  return (
    <>
      <SimpleModal
        title={currentOption.title}
        onSave={onSaveModal}
        onClose={onCloseModal}
        open={open}>
        {
          currentOption.options.map((option) => 
            (<BaseFieldsConfiguration option={option} editTextFieldValue={editTextFieldValue} errors={errors} value={data} />)
          )
        }
      </SimpleModal>
    </>
  )




}
