import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { notify } from "../../../../../redux/NotificationSnackbar/snackbarActions"
import { getRRCriteria, saveRRCriteria } from "../../../../api/company"
import useRequest from "../../../../components/useRequest/useRequest"
import { simpleValidate } from "../../../../helpers/validation"
import useUnsaved from "../../../../hooks/useUnsaved"
import { riskCriteriaSchema } from "../validation/schemas"


export default function useRiskCriteria({
  coloredRiskRatingsExtended,
  coloredRiskRatings,
  coloredYN,
  entity_id
}) {
  const csp_id = useSelector(state => state.tenant.csp?.csp_id)

  const [criteriaErrors, setCriteriaErrors] = useState({})
  const [criteria, setCriteria] = useState({
    geographic_risk: '',
    industry_risk: '',
    delivery_channel_risk: '',
    services_risk: '',
    primary_purpose_risk: '',
    regulated_entity: '',
    cash_dealings: '',
    import_export_dealings: '',
    adverse_media: '',
    bankruptcy: '',
    litigation: '',
    tax_investigation_risk: '',
  })

  const [fetchRRCriteria, __, ended] = useRequest(getRRCriteria.bind(0, entity_id, csp_id), (data) => {
    data && setCriteria(data)
  })

  const [unsaved, setUnsaved] = useUnsaved({ended}, [criteria])

  useEffect(() => {
    if (csp_id) {
      fetchRRCriteria()
    }
  }, [csp_id])

  const [submitCriteria, loadingCriteria] = useRequest(handleCriteria, () => {
    notify({
      type: 'SUCCESS',
      title: "CRITERIA SAVED"
    })
  })

  function editCriteria(value, name) {
    setCriteria(old => {
      old[name] = value
      return { ...old }
    })
  }

  async function handleCriteria() {
    if (await simpleValidate(riskCriteriaSchema, criteria, setCriteriaErrors)) {
      await saveRRCriteria(entity_id, criteria)
      setUnsaved(false)
    } else {
      throw new Error('Criteria validation error')
    }
  }


  const riskCriteriaOptions = [
    {
      name: 'geographic_risk',
      title: 'Geographic Risk',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'industry_risk',
      title: 'Industry Risk',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'delivery_channel_risk',
      title: 'Delivery Channel Risk',
      type: 'color-select',
      options: coloredRiskRatings
    },
    {
      name: 'services_risk',
      title: 'Services Risk',
      type: 'color-select',
      options: coloredRiskRatings,
    },
    {
      name: 'primary_purpose_risk',
      title: 'Primary Purpose Risk',
      type: 'color-select',
      options: coloredRiskRatings
    },
    {
      name: 'regulated_entity',
      title: 'Regulated Entity',
      type: 'color-select',
      options: coloredYN
    },
    {
      name: 'cash_dealings',
      title: 'Cash Dealings',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'import_export_dealings',
      title: 'Import / Export Dealings',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'adverse_media',
      title: 'Adverse Media',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'bankruptcy',
      title: 'Bankruptcy',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'litigation',
      title: 'Litigation',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    },
    {
      name: 'tax_investigation_risk',
      title: 'Tax Investigation Risk',
      type: 'color-select',
      options: coloredRiskRatingsExtended
    }
  ]

  return {
    criteriaErrors,
    submitCriteria,
    loadingCriteria,
    editCriteria,
    riskCriteriaOptions,
    criteria,
    criteriaUnsaved: unsaved
  }
}