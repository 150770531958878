import React, { useEffect, useState } from 'react';

import { withStyles, Checkbox, colors } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import { FormInput } from './Basic';
import { Table } from '../modules/Auth/pages/customers/customers-table/CustomersTable';
import { Switch } from '@material-ui/core';
import CustomDropdown from './Dropdown/CustomDropdown';


const CompanySpecificCheckbox = withStyles({
  root: {
    color: colors.purple[400],
    '&$checked': {
      color: colors.purple[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export function FeesTableNext(props) {
  const [columns, setColumns] = useState(() => [
    {
      id: 1,
      name: 'fee_title',
      title: 'Fee title'
    },
    {
      id: 2,
      name: 'fee_description',
      title: 'Description'
    },
    {
      id: 3,
      name: 'fee_price_company_specific',
      editType: "number",
      title: "CSP's Price",
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled && row.is_company_specific;
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_price_company_specific', event.target.value, rowIndex);
      },
      component: row => {
        return row.fee_enabled && row.is_company_specific ? row.fee_price_company_specific : row.fee_price;
      }
    },
    {
      id: 4,
      name: 'fee_regulator_price',
      title: "Regulator's Price"
    },
    {
      id: 5,
      name: 'fee_government_price',
      title: "Government's Price"
    },
    {
      id: 6,
      name: 'total_price',
      title: 'Total price'
    }
  ])

  useEffect(() => {
    if (!columns.find(col => col.id === 7))
      if (props.isCSPUser)
        setColumns(oldColumns => [
          {
            id: 7,
            name: 'is_company_specific',
            component: (row, rowIndex) => {
              return (
                <Switch
                  checked={row.fee_enabled && row.is_company_specific}
                  disabled={!row.fee_enabled}
                  name="is_company_specific"
                  onChange={() => {
                    props.onFeeUpdate('is_company_specific', !row.is_company_specific, rowIndex);
                  }}
                />
              );
            }
          },
          ...oldColumns
        ])
  }, [props.isCSPUser])

  const [fees, setFees] = useState([]);

  useEffect(() => {
    setFees(
      props.fees.map(fee => {
        return {
          ...fee,
          total_price:
            Number((fee.is_company_specific && fee.fee_price_company_specific) || fee.fee_price) +
            Number(fee.fee_regulator_price) +
            Number(fee.fee_government_price)
        };
      })
    );
  }, [props.fees]);

  if (!props.fees) return;

  return <Table nonSelectable cols={columns} idField="fee_id" entities={fees} />;
}

export function FeesTableConfig(props) {
  const convertMoney = money => {
    if (money) {
      return money.toLocaleString();
    }
  };

  const columns = [
    {
      id: 0,
      name: 'fee_enabled',
      component: (row, rowIndex) => {
        return (
          <Switch
            checked={row.fee_enabled}
            name="fee_enabled"
            onChange={() => {
              props.onFeeUpdate('fee_enabled', !row.fee_enabled, rowIndex);
            }}
          />
        );
      }
    },
    {
      id: 1,
      name: 'fee_title',
      title: 'Fee title'
    },
    {
      id: 2,
      name: 'fee_description',
      title: 'Description'
    },
    {
      id: 111,
      editType: 'number',
      name: 'fee_account_id',
      title: 'Account ID',
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled;
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_account_id', event.target.value, rowIndex);
      }
    },
    {
      id: 112,
      editType: 'number',
      name: 'fee_account_code',
      title: 'Account code',
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled;
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_account_code', event.target.value, rowIndex);
      }
    },
    {
      id: 3,
      editType: 'number',
      name: 'fee_price',
      title: "CSP's Price",
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled && !(row.price_ranges && row.price_ranges.length);
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_price', event.target.value, rowIndex);
      }
    },
    {
      id: 4,
      name: 'fee_regulator_price',
      editType: 'number',
      title: "Regulator's Price",
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled;
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_regulator_price', event.target.value, rowIndex);
      }
    },
    {
      id: 5,
      name: 'fee_government_price',
      title: "Government's Price",
      editType: 'number',
      editable: (cell, row, rowIndex, colIndex) => {
        return row.fee_enabled;
      },
      width: 100,
      onEditorSave: ({ column, row, rowIndex, event }) => {
        props.onFeeUpdate('fee_government_price', event.target.value, rowIndex);
      }
    },
    {
      id: 7,
      name: 'total_price',
      title: 'Total price'
    },
    {
      id: 6,
      name: 'ranges',
      title: "CSP's prices (share capital-dependent)",
      component: (row, index) => {
        return (
          <div className="fields">
            {!props.isCompanySpecific && (
              <CustomDropdown>
                <>
                  <div className="card border-0">
                    <div className="card-body">
                      {row.price_ranges?.map(price_range => {
                        const convertedFrom = convertMoney(price_range.range_from);
                        const convertedTo = convertMoney(price_range.range_to);
                        return (
                          <div className="pb-5" key={price_range.price_range_id}>
                            <span>{`${convertedFrom || '0'}$ ${price_range.range_to ? `— ${convertedTo}$` : ' and up'}`}</span>
                            <EditorRenderer
                              disabled={!row.fee_enabled}
                              value={row[`range_price_${price_range.price_range_id}`] || 0}
                              columnName={`range_price_${price_range.price_range_id}`}
                              onEditorSave={({ event }) =>
                                props.onFeeUpdate(`range_price_${price_range.price_range_id}`, event.target.value, index)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              </CustomDropdown>
            )}
          </div>
        );
      }
    }
  ];

  const [fees, setFees] = useState([]);

  useEffect(() => {
    setFees(
      props.fees.map(fee => {
        const price =
          Number(fee.fee_price)
          + Number(fee.fee_regulator_price)
          + Number(fee.fee_government_price)
        return {
          ...fee,

          total_price: fee.price_ranges
            ? '—'
            : (price || 0)
        };
      })
    );
  }, [props.fees]);

  if (!props.fees) return;

  return <Table nonSelectable cols={columns} idField="fee_id" entities={fees} />;
}

function EditorRenderer({ value, columnName, onEditorSave, disabled }) {
  const inputRef = React.useRef(null);

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.value = value;
  }, []);

  return (
    <input
      disabled={disabled}
      ref={inputRef}
      className="form-control"
      onBlur={event => onEditorSave({ event })}
      name={columnName}
    />
  );
}

function FeesTable(props) {
  if (!props.fees) return;

  return (
    <>
      {props.fees.map((fee, index) => (
        <Card className="p-4 my-2 d-flex flex-row align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            {props.clientCanViewFullInfo && (
              <div className="status d-flex align-items-center justify-content-center mr-3">
                {props.isCompanySpecific ? (
                  <>
                    {props.fees[index].fee_enabled && (
                      <CompanySpecificCheckbox
                        name="is_company_specific"
                        checked={props.fees[index].is_company_specific}
                        onChange={e => props.onFeeUpdate(e, index)}
                      />
                    )}
                  </>
                ) : (
                  <Checkbox
                    name="fee_enabled"
                    checked={props.fees[index].fee_enabled}
                    onChange={e => props.onFeeUpdate(e, index)}
                    color="primary"
                  />
                )}
              </div>
            )}
            <div className="main-info">
              <div className="title">{fee.fee_title}</div>
              <div className="description text-muted font-weight-semi-bold">{fee.fee_description}</div>
              {fee.is_company_specific && (
                <div className="description text-info font-weight-semi-bold">* This fee is company-specific</div>
              )}
            </div>
          </div>
          {props.fees[index].fee_enabled ? (
            <div>
              <div className="fields d-flex align-items-center">
                {!props.isCompanySpecific && (
                  <>
                    <FormInput
                      placeholder="Account Code"
                      w-title
                      type="text"
                      name="fee_account_code"
                      onChange={e => props.onFeeUpdate(e, index)}
                      value={props.fees[index].fee_account_code}
                      className="mx-2"
                    />
                    <FormInput
                      placeholder="Account ID"
                      w-title
                      type="text"
                      name="fee_account_id"
                      onChange={e => props.onFeeUpdate(e, index)}
                      value={props.fees[index].fee_account_id}
                      className="mx-2"
                    />
                  </>
                )}
              </div>
              <div className="fields d-flex align-items-center">
                {(props.isCompanySpecific || !props.fees[index].price_ranges) && (
                  <>
                    {props.isCompanySpecific && props.fees[index].is_company_specific ? (
                      <FormInput
                        placeholder="CSP's Price (entity-specific)"
                        w-title
                        type="number"
                        name="fee_price_company_specific"
                        onChange={e => props.onFeeUpdate(e, index)}
                        value={props.fees[index].fee_price_company_specific}
                        className="mx-2"
                      />
                    ) : (
                      <FormInput
                        placeholder="CSP's Price"
                        w-title
                        type="number"
                        name="fee_price"
                        onChange={e => props.onFeeUpdate(e, index)}
                        value={props.fees[index].fee_price}
                        className="mx-2"
                        disabled={props.isCompanySpecific && !props.fees[index].is_company_specific}
                      />
                    )}
                  </>
                )}

                <FormInput
                  placeholder="Regulator's Price"
                  w-title
                  type="number"
                  name="fee_regulator_price"
                  onChange={e => props.onFeeUpdate(e, index)}
                  value={props.fees[index].fee_regulator_price}
                  className="mx-2"
                  disabled={props.isCompanySpecific}
                />
                <FormInput
                  placeholder="Government's Price"
                  w-title
                  type="number"
                  name="fee_government_price"
                  onChange={e => props.onFeeUpdate(e, index)}
                  value={props.fees[index].fee_government_price}
                  className="mx-2"
                  disabled={props.isCompanySpecific}
                />

                {!props.fees[index].price_ranges && (
                  <div className="mx-2">
                    <div className="mb-2">Total price</div>
                    <div className="font-weight-bolder text-dark">
                      {(props.fees[index].fee_price_company_specific || props.fees[index].fee_price) +
                        (props.fees[index].fee_regulator_price || 0) +
                        (props.fees[index].fee_government_price || 0)}
                    </div>
                    <div className="text-muted font-weight-light mt-1">CSP's tax not included</div>
                  </div>
                )}
              </div>
              <div className="fields">
                {!props.isCompanySpecific && props.fees[index].price_ranges && (
                  <div>
                    <div className="mt-4 mb-2">CSP's prices (share capital-dependent): </div>
                    {props.fees[index].price_ranges.map(price_range => (
                      <FormInput
                        key={price_range.price_range_id}
                        placeholder={`${price_range.range_from || '-∞'}$ — ${price_range.range_to || '+∞'}$`}
                        w-title
                        type="number"
                        name={`range_price_${price_range.price_range_id}`}
                        onChange={e => props.onFeeUpdate(e, index)}
                        value={props.fees[index][`range_price_${price_range.price_range_id}`]}
                        className="mx-2"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {props.isCompanySpecific ? (
                <div className="disabled-text">
                  This fee is globaly disabled. Visit the CSP fees config page to enable this fee.
                </div>
              ) : (
                <div className="disabled-text">This fee is disabled.</div>
              )}
            </>
          )}
        </Card>
      ))}
    </>
  );
}

export default FeesTable;
