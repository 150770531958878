import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FormCheckbox, FormInput, FormSelect } from "../../components/Basic";

export default function EditShareModal(props) {
  const params = useParams();

  const [availableCurrencies, setAvailableCurrencies] = useState([])
  const [share, setShare] = useState(props.share);

  const onShareInfoChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // Trick with the spread operator to force the component to re-render
    // TODO @performance this can potentially be SLOW, will have to refactor
    setShare({ ...share, [e.target.name]: value });
  };

  useEffect(() => {
    setShare({ ...props.share });
  }, [props.share]);

  const saveShare = () => {
    const body = {
      authorized_shares: share.share_authorized_shares,
      certificates_required: share.share_certificates_required,
      class_type: share.share_class,
      currency: share.share_currency,
      director_approval_required: share.share_director_approval_required,
      has_restrictions: share.share_has_restrictions,
      issued_on: share.share_issued_on,
      class_name: share.share_name,
      value_per_share: share.share_nominal_value,
      restrictions: share.share_restrictions,
      rights_to_capital_on_winding_up:
        share.share_rights_to_capital_on_winding_up,
      rights_to_dividents: share.share_rights_to_dividents,
      voting_rights: share.share_voting_rights
    };

    if (share._state && share._state === "new") {
      // Create a new share class

      Axios.post(`company/${params.entity_id}/share-class`, body)
        .then(() => {
          if (props.onSave) props.onSave();
        })
        .catch(() => {});
    } else {
      // Update a share class

      Axios.put(
        `company/${params.entity_id}/share-class/${share.share_id}`,
        body
      )
        .then(() => {
          if (props.onSave) props.onSave();
        })
        .catch(() => {});
    }
  };

  const getCurrency = () => {
    Axios.get('/company/all-currency')
      .then(({ data }) => {
        setAvailableCurrencies(data.CUSTOMER_COMPANY_SHARE_CURRENCY)
      })
      .catch(() => { });
  }

  useEffect(() => {
    if(props.show && !availableCurrencies.length) {
      getCurrency()
    }
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {share._state === "new" ? "Create" : "Edit"} share class
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ui-form-middle">
          <FormInput
            w-title
            placeholder="Share Class Nickname"
            type="text"
            name="share_name"
            onChange={onShareInfoChange}
            value={share.share_name}
          />
          <FormSelect
            w-title="Share Class Type"
            type="text"
            name="share_class"
            onChange={onShareInfoChange}
            value={share.share_class}
          >
            <option value="-1">Share Class Type</option>
            <option value="ordinary">Ordinary Shares</option>
            <option value="deferred_ordinary">Deferred ordinary shares</option>
            <option value="nonvoting_ordinary">
              Non-Voting Ordinary Shares
            </option>
            <option value="redeemable">Redeemable Shares</option>
            <option value="preference">Preference Shares</option>
            <option value="cumulative_preference">
              Cumulative Preference Shares
            </option>
            <option value="redeemable_preference">
              Redeemable Preference Shares
            </option>
            <option value="advisory">Advisory Shares</option>
            <option value="institutional">Institutional shares</option>
          </FormSelect>
        </div>
        <FormInput
          w-title="Date issued"
          type="date"
          name="share_issued_on"
          onChange={onShareInfoChange}
          value={share.share_issued_on}
        />
        <div className="ui-form-middle">
          <FormInput
            w-title
            placeholder="Authorized shares"
            type="number"
            name="share_authorized_shares"
            onChange={onShareInfoChange}
            value={share.share_authorized_shares}
          />
          <FormInput
            w-title
            placeholder="Nominal Value per Share"
            type="number"
            name="share_nominal_value"
            onChange={onShareInfoChange}
            value={share.share_nominal_value}
          />
        </div>

        <FormSelect
          w-title="Currency"
          type="text"
          name="share_currency"
          onChange={onShareInfoChange}
          value={share.share_currency}
        >
          {availableCurrencies.map(option => (
            <option key={option.value} value={option.value}>{option.title}</option>
          ))}
        </FormSelect>

        <FormInput
          w-title="Voting Rights"
          placeholder="Voting Rights"
          type="number"
          name="share_voting_rights"
          onChange={onShareInfoChange}
          value={share.share_voting_rights}
        />

        <FormSelect
          w-title="Rights to dividends"
          type="text"
          name="share_rights_to_dividents"
          onChange={onShareInfoChange}
          value={share.share_rights_to_dividents}
        >
          <option value="-1">Rights to dividends (optional)</option>
          <option value="equal_right">Equal right to dividends</option>
          <option value="no_right">No Right to dividends</option>
        </FormSelect>

        <FormInput
          w-title="Rights to capital on winding up"
          placeholder="Rights to capital on winding up (optional)"
          type="text"
          name="share_rights_to_capital_on_winding_up"
          onChange={onShareInfoChange}
          value={share.share_rights_to_capital_on_winding_up}
        />

        <FormCheckbox
          w-title="Restrictions on share class"
          text="Put restrictions on this share class"
          name="share_has_restrictions"
          onChange={onShareInfoChange}
          checked={share.share_has_restrictions}
        />

        {share.share_has_restrictions && (
          <div className="mb-3">
            <FormInput
              placeholder="Restrictions on share class"
              type="text"
              multiline
              name="share_restrictions"
              onChange={onShareInfoChange}
              value={share.share_restrictions}
            />
          </div>
        )}

        <FormCheckbox
          text="Require director approval for transfers"
          name="share_director_approval_required"
          onChange={onShareInfoChange}
          checked={share.share_director_approval_required}
        />

        <FormCheckbox
          text="Certificates required to be issued"
          name="share_certificates_required"
          onChange={onShareInfoChange}
          checked={share.share_certificates_required}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={saveShare}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
