import { SET_INVITATION_URL } from "./urlActions";
import { SET_REDIRECTING_PATH } from "./urlActionTypes";

export default function urlReducer(state = {path: null, invitationUrl: ''}, action) {
  switch (action.type) {
    case SET_REDIRECTING_PATH:
      return {
        ...state,
        path: action.payload
      }
    case SET_INVITATION_URL: {
      return {
        ...state,
        invitationUrl: action.payload
      }
    }
    default: return state
  }
}