import React from 'react';
// import { CircularProgress } from '@material-ui/core';
import { toAbsoluteUrl } from '../../_helpers';

function closeOnTimeout() {}

export function SplashScreen(props) {
  return (
    <div className={'splash-screen overlay-fixed'}>
      <img src={toAbsoluteUrl('/media/logos/logo-dark.png')} alt="Metronic logo" />
      <svg className="splash-spinner m-8" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
      {props.textContent ? <h2>{props.textContent}</h2> : null}
      {/*<CircularProgress className="splash-screen-spinner m-8" style={{ color: '#5d78ff' }} />*/}
    </div>
  );
}
