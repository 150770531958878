import Axios from 'axios';
import './AddressForm.css';
import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, MenuItem } from '@material-ui/core';
import { availableQualifiers } from './Qualifiers';
import { FormButton, FormInput, FormSelect } from '../Basic';
import { capitalize } from '../Auxillary';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';

export function AddressForm({ qualifiers, setQualifiers }) {
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const searchLocation = () => {
    Axios.get(`user/find-location?query=${location}`)
      .then(({ data }) => {
        if (data.suggestions) {
          setSuggestions(data.suggestions);
        }
      })
      .catch(() => {});
  };

  const getPlaceQualifiers = place_id => {
    Axios.get(`user/get-place-qualifiers?place_id=${place_id}`)
      .then(({ data }) => {
        if (data.qualifiers) {
          const raw = [...Object.entries(data.qualifiers)];
          raw.forEach(item => {
            const index = qualifiers.findIndex(q => q.qualifier === item[0]);
            if (index === -1) {
              qualifiers.push({
                qualifier: item[0],
                value: item[1]
              });
            } else {
              qualifiers[index] = {
                qualifier: item[0],
                value: item[1]
              };
            }
          });
          setQualifiers([...qualifiers]);
        }
      })
      .catch(() => {});
  };

  const prettify = qualifier => {
    return capitalize(qualifier.replaceAll('_', ' '));
  };

  const handleSelectChange = (e, id) => {
    if (checkDuplicates(e.target.value)) {
      // If qualifier does not exist -> continue
      qualifiers[id].qualifier = e.target.value;
      setQualifiers([...qualifiers]);
    }
  };

  const handleInputChange = (e, id) => {
    qualifiers[id].value = e.target.value;
    setQualifiers([...qualifiers]);
  };

  const removeQualifier = id => {
    qualifiers.splice(id, 1);
    setQualifiers([...qualifiers]);
  };

  const addQualifier = () => {
    qualifiers.push({ qualifier: '', value: '' });
    setQualifiers([...qualifiers]);
  };

  const handleSuggestion = (desc, id) => {
    setLocation(desc);
    getPlaceQualifiers(id);
    setSuggestions([]);
  };

  const checkDuplicates = qualifier => {
    const qualifierExists = qualifiers.find(item => item.qualifier === qualifier);
    if (qualifierExists) {
      notify({
        type: 'ERROR',
        title: `Qualifier ${prettify(qualifier)} is already in use`,
        message: 'Please, select another qualifier or modify existing one'
      });
      return false;
    }
    return true;
  };

  return (
    <div className="ui-address-container">
      <div className="search-container d-flex align-items-center">
        <FormInput
          placeholder="Search for a place"
          type="text"
          value={location}
          onChange={e => {
            const value = e.target.value;
            setLocation(value);
          }}
        />
        <FormButton
          disableElevation={true}
          label="Search"
          formClassName="w-25"
          color="primary"
          onClick={searchLocation}
          className="font-size-h6 font-weight-bold px-9 py-4 w-25 ml-3 text-capitalize text-white"
        />
      </div>
      <div className="suggestions-container mt-4 p-2 ">
        {suggestions.map(({description, place_id}, key) => (
          <div
            key={key}
            className="btn btn-hover-bg-light p-4 suggestion text-left w-100"
            onClick={() => {
              handleSuggestion(description, place_id);
            }}
          >
            <div className="title">{description}</div>
          </div>
        ))}
      </div>
      <div className="location-qualifiers-container">
        {qualifiers.map((item, id) => {
          return (
            <div className="qualifier d-flex align-items-center" key={id}>
              <FormSelect mui value={qualifiers[id].qualifier} label="Qualifier type" onChange={e => handleSelectChange(e, id)}>
                {[...Object.entries(availableQualifiers)].map((item, i) => (
                  <MenuItem value={item[0]} key={i}>
                    {item[1]}
                  </MenuItem>
                ))}
              </FormSelect>
              <FormInput
                mui
                name={qualifiers[id].qualifier}
                value={qualifiers[id].value}
                className="ml-3 my-3"
                label="Qualifier"
                onChange={e => handleInputChange(e, id)}
              />
              <IconButton aria-label="delete" className="ml-1" onClick={() => removeQualifier(id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className="btn btn-success mt-2 qualifier-btn text-white" onClick={addQualifier}>
        Add a qualifier
      </div>
    </div>
  );
}