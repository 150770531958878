import { SET_MAIN_ASIDE_VISIBILITY, SET_SECOND_ASIDE, SET_SECOND_ASIDE_VISIBILITY, TOGGLE_ASIDE } from "./styleActions";

const asideMaxWidth = 265;
const asideMinWidth = 75;
const secondAsideWidth = 250;

export default function styleReducer(state = {
  asideMinimized: false,
  asideWidth: asideMaxWidth,
  secondAsideWidth: 0,
  secondAsideMenu: null,
  mainAsideVisibility: true,
  secondAsideVisibility: false
}, action) {
  switch (action.type) {
    case TOGGLE_ASIDE : {
      if (state.asideMinimized) {
        return {...state, asideMinimized: false, asideWidth: asideMaxWidth}
      } else {
        return {...state, asideMinimized: true, asideWidth: asideMinWidth}
      }
    }
    case SET_SECOND_ASIDE : {
      return {...state, secondAsideMenu: action.payload }
    }
    case SET_MAIN_ASIDE_VISIBILITY : {
      return {...state, mainAsideVisibility: action.payload}
    }
    case SET_SECOND_ASIDE_VISIBILITY : {
      return {...state, secondAsideVisibility: action.payload}
    }
    default: return state
  }
}