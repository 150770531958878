import React, { useState, createRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Axios from 'axios';
import CountrySelect from '../../components/CountrySelector';
import StockExchangeSelect from '../../components/StockExchangeSelector';
import { FormCheckbox, FormInput, FormSelect, UploadButton } from '../../components/Basic';
import { getURLQueryParams } from '../../../util';
import { initialValues } from './InitialValues';
import { validationSchema } from './ValidationSchema';
import {SuccessScreen} from '../SuccessScreen';

const current_year = new Date().getFullYear();

const STEP_NAMES = [
  /* page 0 */ '',
  /* page 1 */ 'Confirm Entity Information',
  /* page 2 */ 'Due Diligence Information',
  /* page 3 */ 'Entity Information',
  /* page 4 */ 'Success Screen'
];

const STEP_MAPPING = {
  former_names: 'edd_confirmation',

  date_of_incorporation: 'edd_basic',
  purpose: 'edd_basic',

  is_government_organisation: 'edd_dd',
  government_organisation_country: 'edd_dd',

  // a
  is_ex_a: 'edd_dd',
  ex_a_jurisdiction: 'edd_dd',
  ex_a_stock_exchange: 'edd_dd',
  ex_a_ticker: 'edd_dd',

  is_pension_fund_or_other: 'edd_dd',

  // b
  is_ex_b: 'edd_dd',
  ex_b_country: 'edd_dd',
  ex_b_regulator: 'edd_dd',
  ex_b_regulatory_activity: 'edd_dd',
  ex_b_registration_number: 'edd_dd',

  // c
  is_ex_c: 'edd_dd',
  ex_c_manager_name: 'edd_dd',

  // e1
  is_ex_e1: 'edd_dd',
  ex_e1_partnership_name: 'edd_dd',

  // e2
  is_ex_e2: 'edd_dd',
  ex_e2_approved_person: 'edd_dd',
  ex_e2_partnership_name: 'edd_dd',

  // f
  is_ex_f: 'edd_dd',
  ex_f_licensee_name: 'edd_dd'
};

const WIDE_PAGES = [3, 4];
const BOOLEAN_FIELDS = [
  'is_government_organisation',
  'is_ex_a',
  'is_pension_fund_or_other',
  'is_ex_b',
  'is_ex_c',
  'is_ex_e1',
  'is_ex_e2',
  'is_ex_f'
];

let config_available_regions = [];

export default function CreateCompanyPage(props) {
  const history = useHistory();
  const location = useLocation();

  const [isCompany, setIsCompany] = useState(false);
  const [page0Text, setPage0Text] = useState('Getting ready, please wait...');
  //SET 0
  const [currentPage, setCurrentPage] = useState(1);

  const [answersBasic, setAnswersBasic] = useState({});
  const [answersConfirmation, setAnswersConfirmation] = useState({});
  const [answersDD, setAnswersDD] = useState({
    is_government_organisation: false
  });

  const [controllingPersons, setControllingPersons] = useState([{}]);

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState({
    coi: [],
    cogs: [],
    cd: [],
    se: [],
    roo: [],
    rcp: []
  });

  const [currentEntity, setCurrentEntity] = useState({});

  const [locationQualifiers, setLocationQualifiers] = useState([]);

  const file_input_coi = createRef();
  const file_input_cogs = createRef();
  const file_input_cd = createRef();
  const file_input_se = createRef();
  const file_input_roo = createRef();
  const file_input_rcp = createRef();

  // ========= Company types and partnership types
  const [entityType, setEntityType] = useState('-1');
  const [entityTypeOptions, setEntityTypeOptions] = useState([]);

  const EntityTypeList = entityTypeOptions.map(text => <option key={text.id} value={text.id} label={text.title} />);

  const loadTexts = async type => {
    if (type === 'company') return (await import('../../../texts/CaymanCompanyTypes.json')).default;
    if (type === 'partnership') return (await import('../../../texts/CaymanPartnershipTypes.json')).default;
    return [];
  };

  // loading
  useEffect(() => {
    (async () => {
      if (answersConfirmation.entity_type) {
        const options = await loadTexts(answersConfirmation.entity_type);
        setEntityType(answersConfirmation.entity_type);
        setEntityTypeOptions([...options]);
      }
    })();
  }, [answersConfirmation.entity_type]);

  // ===== Form-related =====
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const page1 = useFormik({
    initialValues: initialValues.page1,
    validationSchema: validationSchema.page1,
    validateOnChange: true,
    onSubmit: async values => {
      await setAnswersConfirmation(values)
      nextPage();
    }
  });

  const page2 = useFormik({
    initialValues: initialValues.page2,
    validationSchema: validationSchema.page2,
    validateOnChange: true,
    onSubmit: async values => {
      await setAnswersDD(values)
      nextPage();
    }
  });

  const page3 = useFormik({
    initialValues: initialValues.page3,
    validationSchema: validationSchema.page3,
    validateOnChange: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // >>> ???
      answersBasic.purpose = values.purpose;
      enableLoading();
      setSubmitting(true);

      const form_data = new FormData();

      let file_i = 0;
      const file_descriptors = [];

      // Files
      for (const document_type in documents) {
        for (const file of documents[document_type]) {
          file_descriptors.push({
            document_type,
            file_id: file_i
          });

          form_data.append(`file_${file_i}`, file);

          file_i++;
        }
      }

      form_data.append('file_descriptors', JSON.stringify(file_descriptors));

      // Page 1
      form_data.append(`confirmation`, JSON.stringify(answersConfirmation));

      const final_basic_answers = answersBasic;
      for (const qualifier of locationQualifiers) {
        final_basic_answers[`address_${qualifier[0]}`] = qualifier[1];
      }

      // Page 2
      form_data.append(`basic`, JSON.stringify(final_basic_answers));

      // Page 3
      form_data.append(`due_diligence`, JSON.stringify(answersDD));

      Axios.post(
        `user/edd-data/${currentEntity.pc_id || currentEntity.company_id}?is_company=${currentEntity.is_company ? '1' : '0'}`,
        form_data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(() => {
          // Show Success Screen
          setCurrentPage(4);
        })
        .catch(error => {
          if (!!error.response) setStatus(error?.response?.data.error_message);

          disableLoading();
          setSubmitting(false);
        });
    }
  });

  // ===== API =====
  const getAvailableRegions = () => {
    Axios.get('csp/jurisdiction-regions')
      .then(({ data }) => {
        config_available_regions = data.jurisdiction_regions;
      })
      .catch(() => {});
  };

  const getPCInfo = () => {
    const url_parsed = getURLQueryParams(location.search);

    let url = '';

    if (url_parsed.pc_id) {
      url = `company/pc-edd/${url_parsed.pc_id}`;
    } else if (url_parsed.company_id) {
      url = `company/company-edd/${url_parsed.company_id}`;
    } else {
      return;
    }

    Axios.get(url)
      .then(({data}) => {
        const company_id = data.company_info.company_id;
        const is_company = data.company_info.company_category === 'company';
        const pc_name = data.company_info.company_name;

        if (data.company_info && is_company) {
          page1.values.isCompany = true;
          setIsCompany(true);
          setCurrentEntity({
            company_id,
            pc_name,
            is_company
          });

        } else if (data.pc_info) {
          setCurrentEntity(data.pc_info);

        } else {
          setCurrentEntity({
            company_id,
            pc_name,
            is_company: true
          });
        }

        setCurrentPage(1);

        const new_locationQualifiers = locationQualifiers;

        if (data.dd) {
          const current_data = {};

          for (const answer of data.dd) {
            if (answer.field_name.startsWith('address_')) {
              const field_name = answer.field_name.substring(8);

              new_locationQualifiers.push([field_name, answer.field_value]);
            }

            const value = BOOLEAN_FIELDS.includes(answer.field_name) ? answer.field_value === 'true' : answer.field_value;

            if (!current_data[STEP_MAPPING[answer.field_name]]) {
              current_data[STEP_MAPPING[answer.field_name]] = { [answer.field_name]: value };
            } else {
              current_data[STEP_MAPPING[answer.field_name]][answer.field_name] = value;
            }
          }

          if (current_data.edd_confirmation) {
            for (const name in current_data.edd_confirmation) {
              page1.values[name] = current_data.edd_confirmation[name];
            }
          }

          if (current_data.edd_dd) {
            for (const name in current_data.edd_dd) {
              page2.values[name] = current_data.edd_dd[name];
            }
          }

          if (current_data.edd_basic) {
            for (const name in current_data.edd_basic) {
              page3.values[name] = current_data.edd_basic[name];
            }
          }

          setAnswersDD(current_data.edd_dd || {});
          setAnswersConfirmation(current_data.edd_confirmation || {});
          setAnswersBasic(current_data.edd_basic || {});

          setLocationQualifiers(new_locationQualifiers);
        }
      })
      .catch(() => {
        setPage0Text("Looks like you don't have permission to edit this entity's DD");
      });
  };

  useEffect(() => {
    getPCInfo();
    getAvailableRegions();
  }, []);

  // ===== Navigation =====
  const nextPage = () => {
    if (currentPage < 3) {
      if (isCompany && currentPage === 1) {
        // skip 2nd page if type === company
        setCurrentPage(3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const goBack = () => {
    if (currentPage > 1) {
      if (isCompany && currentPage === 3) {
        // skip 2nd page if type === company
        setCurrentPage(1);
      } else {
        setCurrentPage(currentPage - 1);
      }
    } else {
      history.goBack()
    }
  };

  // ===== Data modification =====
  const setDocumentFiles = (e, scope) => {
    e.stopPropagation();
    e.preventDefault();

    const new_obj = documents;

    new_obj[scope] = [];

    for (const file of e.target.files) {
      new_obj[scope].push(file);
    }

    setDocuments({ ...new_obj });
  };

  const onControllingPersonInfoChange = (e, index) => {
    const new_controlling_persons = controllingPersons;

    new_controlling_persons[index][e.target.name] = e.target.value;

    // Trick with the spread operator to force the component to re-render
    // TODO @performance this can potentially be SLOW, will have to refactor
    setControllingPersons([...new_controlling_persons]);
  };

  const addControllingPerson = () => {
    // Trick with the spread operator to force the component to re-render
    setControllingPersons([...controllingPersons, {}]);
  };

  const removeControllingPerson = index => {
    // TODO @performance
    const new_controlling_persons = [...controllingPersons];

    new_controlling_persons.splice(index, 1);

    // Trick with the spread operator to force the component to re-render
    setControllingPersons([...new_controlling_persons]);
  };

  let cogs_required = false;

  if (answersBasic && answersBasic.date_of_incorporation) {
    const date_of_incorporation = new Date(answersBasic.date_of_incorporation);

    if (current_year - date_of_incorporation.getFullYear() > 2) {
      cogs_required = true;
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="create_csp_root"
        >
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <div className={`w-${WIDE_PAGES.includes(currentPage) ? '800' : '400'}px`}>
                <div className="text-center mb-10 mb-lg-10">
                  <h3 className="font-size-h1">Entity Due Diligence</h3>
                  {currentEntity.pc_name && (
                    <>
                      {currentEntity.is_company ? (
                        <div>{currentEntity.pc_name}</div>
                      ) : (
                        <div>
                          {currentEntity.pc_name} [{currentEntity.pc_registration_number}, {currentEntity.pc_jurisdiction_region}]
                        </div>
                      )}
                    </>
                  )}
                  {currentPage !== 0 && (
                    <p className="text-muted font-weight-semi-bold">
                      Step {currentPage}: {STEP_NAMES[currentPage]}
                    </p>
                  )}
                </div>

                <div className="form fv-plugins-bootstrap fv-plugins-framework">
                  {page3.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{page3.status}</div>
                    </div>
                  )}

                  {/* Page 0 */}
                  {currentPage === 0 && <div className="form-group text-center">{page0Text}</div>}

                  {/* Page 1 - Confirm Entity Information */}
                  {currentPage === 1 && (
                    <form onSubmit={page1.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                      <div className="form-group fv-plugins-icon-container">
                        <div className="font-size-h6 font-weight-bold mb-4 ml-2">
                          Confirm some information about {currentEntity.pc_name}
                        </div>

                        <FormInput
                          w-title="Former names / trading names"
                          placeholder="Former names / trading names"
                          name="former_names"
                          type="text"
                          onChange={page1.handleChange}
                          onBlur={page1.handleBlur}
                          value={page1.values.former_names}
                          error={page1.errors.former_names}
                          helperText={page1.errors.former_names}
                        />
                      </div>
                      <div className="form-group d-flex align-items-center">
                        <button
                          type="button"
                          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
                          onClick={goBack}
                        >
                          Back
                        </button>
                        <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100">
                          Next
                        </button>
                      </div>
                    </form>
                  )}

                  {/* Page 2 - Due Diligence */}
                  {currentPage === 2 && (
                    <form onSubmit={page2.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                      <div className="form-group fv-plugins-icon-container my-3">
                        <div className="font-size-h6 font-weight-bold mb-4 ml-2">Copy of statutory evidence</div>

                        {documents.se.map(document => (
                          <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer">
                            Document {document.name} ({document.size}b)
                          </div>
                        ))}

                        <input type="file" multiple ref={file_input_se} onChange={e => setDocumentFiles(e, 'se')} hidden />
                        <UploadButton onClick={() => file_input_se.current.click()} />
                      </div>

                      <div className="form-group fv-plugins-icon-container">
                        <FormCheckbox
                          name="is_government_organisation"
                          onChange={page2.handleChange}
                          checked={page2.values.is_government_organisation}
                          text={`Is ${currentEntity.pc_name} a central or
                            local government organisation, statutory body or
                            agency of government?`}
                        />

                        {page2.values.is_government_organisation && (
                          <>
                            <div className="mt-4 mb-2">Which country?</div>
                            <CountrySelect
                              name="government_organisation_country"
                              value={page2.values.government_organisation_country}
                              onChange={page2.handleChange}
                              placeholder="Select a country"
                              onBlur={page2.handleBlur}
                              error={page2.errors.government_organisation_country}
                              helperText={page2.errors.government_organisation_country}
                            />
                          </>
                        )}
                      </div>

                      <div className="form-group fv-plugins-icon-container my-5">
                        <FormCheckbox
                          name="is_ex_a"
                          onChange={page2.handleChange}
                          checked={page2.values.is_ex_a}
                          text={`Is ${currentEntity.pc_name} a company that is
                          listed on a recognised stock exchange and subject to
                          disclosure requirements which impose requirements to
                          ensure adequate transparency of beneficial
                          ownership, or majority owned subsidiary of a such
                          company?`}
                        />

                        <p className="text-muted font-weight-semi-bold">
                          (a) Recognised Stock exchange list or recognized stock exchange and subject to disclosure requirements
                          which impose requirements to ensure adequate transparency of beneficial ownership, or majority owned
                          subsidiary of a such company?
                        </p>

                        {page2.values.is_ex_a && (
                          <>
                            <div className="mt-4 mb-2">In which jurisdiction is the Exchange?</div>
                            <CountrySelect
                              name="ex_a_jurisdiction"
                              value={page2.values.ex_a_jurisdiction}
                              onChange={page2.handleChange}
                              placeholder="Select a jurisdiction"
                              error={page2.errors.ex_a_jurisdiction}
                              helperText={page2.errors.ex_a_jurisdiction}
                            />

                            <div className="mt-4 mb-2">Stock exchange</div>
                            <StockExchangeSelect
                              name="ex_a_stock_exchange"
                              value={page2.values.ex_a_stock_exchange}
                              onChange={page2.handleChange}
                              placeholder="Select stock exchange"
                              error={page2.errors.ex_a_stock_exchange}
                              helperText={page2.errors.ex_a_stock_exchange}
                            />

                            <FormInput
                              w-title="Ticker symbol"
                              placeholder="Ticker symbol"
                              type="text"
                              name="ex_a_ticker"
                              onChange={page2.handleChange}
                              value={page2.values.ex_a_ticker}
                              error={page2.errors.ex_a_ticker}
                              helperText={page2.errors.ex_a_ticker}
                            />
                          </>
                        )}
                      </div>

                      <div className="form-group fv-plugins-icon-container my-5">
                        <FormCheckbox
                          name="is_ex_b"
                          onChange={page2.handleChange}
                          checked={page2.values.is_ex_b}
                          text={`Is ${currentEntity.pc_name} currently regulated?`}
                        />

                        <p className="text-muted font-weight-semi-bold">
                          (b) registered or holding a licence under a regulatory law (Other than a company registered as an
                          excluded person under section 5(4) of the Securities Investment Business Law)
                        </p>

                        {page2.values.is_ex_b && (
                          <>
                            <div className="mt-4 mb-2">Which country</div>
                            <CountrySelect
                              name="ex_b_country"
                              value={page2.values.ex_b_country}
                              onChange={page2.handleChange}
                              placeholder="Select a country"
                              error={page2.errors.ex_b_country}
                              helperText={page2.errors.ex_b_country}
                            />

                            <FormInput
                              placeholder="Regulator"
                              w-title="Which regulator"
                              type="text"
                              name="ex_b_regulator"
                              onChange={page2.handleChange}
                              onBlur={page2.handleBlur}
                              value={page2.values.ex_b_regulator}
                              error={page2.errors.ex_b_regulator}
                              helperText={page2.errors.ex_b_regulator}
                            />

                            <FormInput
                              w-title="What type of Regulated Activity is the Entity involved in"
                              placeholder="eg. Bank, fund, trust company etc"
                              type="text"
                              name="ex_b_regulatory_activity"
                              onChange={page2.handleChange}
                              onBlur={page2.handleBlur}
                              value={page2.values.ex_b_regulatory_activity}
                              error={page2.errors.ex_b_regulatory_activity}
                              helperText={page2.errors.ex_b_regulatory_activity}
                            />

                            <FormInput
                              placeholder="Regulatory Registration number"
                              w-title
                              type="text"
                              name="ex_b_registration_number"
                              onChange={page2.handleChange}
                              onBlur={page2.handleBlur}
                              value={page2.values.ex_b_registration_number}
                              error={page2.errors.ex_b_registration_number}
                              helperText={page2.errors.ex_b_registration_number}
                            />
                          </>
                        )}
                      </div>

                      <div className="form-group fv-plugins-icon-container my-5">
                        <FormCheckbox
                          name="is_ex_c"
                          onChange={page2.handleChange}
                          checked={page2.values.is_ex_c}
                          text={`Has ${currentEntity.pc_name} appointed a professional manager that is an "Approved Person" in the Cayman Islands?`}
                        />

                        <p className="text-muted font-weight-semi-bold">
                          (c) managed, arranged, administered, operated or promoted by an approved person as a Special Purpose
                          Vehicle (SPV), Private Equity Fund, collective investment scheme, or investment fund, including where
                          the vehicle, fund or scheme is a Cayman Islands exempted limited partnership
                        </p>

                        {page2.values.is_ex_c && (
                          <>
                            <FormInput
                              placeholder="Name of Approved Person"
                              w-title
                              type="text"
                              name="ex_c_manager_name"
                              onChange={page2.handleChange}
                              onBlur={page2.handleBlur}
                              value={page2.values.ex_c_manager_name}
                              error={page2.errors.ex_c_manager_name}
                              helperText={page2.errors.ex_c_manager_name}
                            />
                          </>
                        )}
                      </div>

                      {answersConfirmation.entity_type === 'partnership' && (
                        <div className="form-group fv-plugins-icon-container my-5">
                          <FormCheckbox
                            name="is_ex_e1"
                            onChange={page2.handleChange}
                            checked={page2.values.is_ex_e1}
                            text={`Is ${currentEntity.pc_name} a registered or holds a license under regulatory law`}
                          />

                          {page2.values.is_ex_e1 && (
                            <div className="form-group fv-plugins-icon-container my-5">
                              <FormInput
                                placeholder="Name of the partnership"
                                w-title
                                type="text"
                                name="ex_e1_partnership_name"
                                onChange={page2.handleChange}
                                onBlur={page2.handleBlur}
                                value={page2.values.ex_e1_partnership_name}
                                error={page2.errors.ex_e1_partnership_name}
                                helperText={page2.errors.ex_e1_partnership_name}
                              />
                            </div>
                          )}

                          <FormCheckbox
                            name="is_ex_e2"
                            onChange={page2.handleChange}
                            checked={page2.values.is_ex_e2}
                            text={`Is ${currentEntity.pc_name} a managed, arranged, administered, operated or promoted by an approved person`}
                          />

                          {page2.values.is_ex_e2 && (
                            <div className="form-group fv-plugins-icon-container my-5">
                              <FormInput
                                placeholder="Name of the approved person"
                                w-title
                                type="text"
                                name="ex_e2_approved_person"
                                onChange={page2.handleChange}
                                onBlur={page2.handleBlur}
                                value={page2.values.ex_e2_approved_person}
                                error={page2.errors.ex_e2_approved_person}
                                helperText={page2.errors.ex_e2_approved_person}
                              />
                              <FormInput
                                placeholder="Name of the partnership"
                                w-title
                                type="text"
                                name="ex_e2_partnership_name"
                                onChange={page2.handleChange}
                                onBlur={page2.handleBlur}
                                value={page2.values.ex_e2_partnership_name}
                                error={page2.errors.ex_e2_partnership_name}
                                helperText={page2.errors.ex_e2_partnership_name}
                              />
                            </div>
                          )}
                        </div>
                      )}

                      <div className="form-group fv-plugins-icon-container my-5">
                        <FormCheckbox
                          name="is_ex_f"
                          onChange={page2.handleChange}
                          checked={page2.values.is_ex_f}
                          text={`Is ${currentEntity.pc_name} (f) holding directly a legal or beneficial interest in the shares of a legal entity which holds a Licence under the Banks and Trust Companies Law, the Companies Management Law, the Insurance Law, Part III of the Mutual Funds Law or the SIBL Law`}
                        />

                        {page2.values.is_ex_f && (
                          <>
                            <FormInput
                              placeholder="Name of Licensee"
                              w-title
                              type="text"
                              name="ex_f_licensee_name"
                              onChange={page2.handleChange}
                              onBlur={page2.handleBlur}
                              value={page2.values.ex_f_licensee_name}
                              error={page2.errors.ex_f_licensee_name}
                              helperText={page2.errors.ex_f_licensee_name}
                            />
                          </>
                        )}
                      </div>

                      <div className="form-group d-flex align-items-center">
                        <button
                          type="button"
                          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
                          onClick={goBack}
                        >
                          Back
                        </button>
                        <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100">
                          Next
                        </button>
                      </div>
                    </form>
                  )}

                  {/* Page 3 - Entity Information */}
                  {currentPage === 3 && (
                    <form onSubmit={page3.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                      <div className="form-group fv-plugins-icon-container my-3">
                        <div className="font-size-h6 font-weight-bold mb-4 ml-2">Copy of certificate of incorporation</div>

                        {documents.coi.map(document => (
                          <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer">
                            Document {document.name} ({document.size}b)
                          </div>
                        ))}

                        <input type="file" multiple ref={file_input_coi} onChange={e => setDocumentFiles(e, 'coi')} hidden />
                        <UploadButton onClick={() => file_input_coi.current.click()} />
                      </div>

                      {cogs_required && (
                        <div className="form-group fv-plugins-icon-container my-3">
                          <div className="font-size-h6 font-weight-bold mb-4 ml-2">Certificate of Good Standing</div>

                          {documents.cogs.map(document => (
                            <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer">
                              Document {document.name} ({document.size}b)
                            </div>
                          ))}

                          <input type="file" multiple ref={file_input_cogs} onChange={e => setDocumentFiles(e, 'cogs')} hidden />
                          <UploadButton onClick={() => file_input_cogs.current.click()} />
                        </div>
                      )}

                      <div className="form-group fv-plugins-icon-container my-3">
                        <div className="font-size-h6 font-weight-bold mb-4 ml-2">Copy of the constitutional documents</div>

                        {documents.cd.map(document => (
                          <div className="btn btn-hover-bg-secondary btn-sm cursor-pointer">
                            Document {document.name} ({document.size}b)
                          </div>
                        ))}

                        <input type="file" multiple ref={file_input_cd} onChange={e => setDocumentFiles(e, 'cd')} hidden />

                        <UploadButton onClick={() => file_input_cd.current.click()} />
                      </div>

                      <div className="form-group fv-plugins-icon-container">
                        <FormSelect
                          w-title={`What is the purpose of ${currentEntity.pc_name}`}
                          type="text"
                          name="purpose"
                          onChange={page3.handleChange}
                          value={page3.values.purpose}
                          onBlur={page3.handleBlur}
                          error={page3.errors.purpose}
                          helperText={page3.errors.purpose}
                        >
                          <option value="-1">Purpose of {currentEntity.pc_name}</option>
                          <option>Property Investing</option>
                          <option>Private Investment Portfolio</option>
                          <option>Captive Insurance Company</option>
                          <option>Other Insurance Company</option>
                          <option>General Partner</option>
                          <option>Hedge Fund</option>
                          <option>Investment Management Company</option>
                          <option>Trust or Foundation Structure related</option>
                          <option>Operating Company in finance</option>
                          <option>Operating Company in non-finance</option>
                          <option>Passively holding assets</option>
                          <option>Not for Profit / Charitable Purposes</option>
                          <option>Special Purpose Vehicle</option>
                          <option>Other</option>
                        </FormSelect>
                      </div>

                      <div className="form-group d-flex align-items-center">
                        <button
                          type="button"
                          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
                          onClick={goBack}
                        >
                          Back
                        </button>
                        <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100">
                          Next
                        </button>
                      </div>
                    </form>
                  )}
                  {/* Page 4  - Success Screen */}
                  {currentPage === 4 && (
                    <SuccessScreen message="Entity Due Diligence process has been successfully completed"/>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
