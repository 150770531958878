import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import SharesTransactionModal from "../../../components/SharesTransactionModal";
import AddShareholderModal from "../../../components/AddShareholderModal";
import { Table, TableHead, TableRow, TableCell, TableBody, makeStyles, withStyles } from '@material-ui/core';
import { Button, Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DataTable from "../../../components/DataTable/DataTable";
import EditShareModal from "../EditSharesModal";
import CancelSharesModal from "../CancelSharesModal";

const TRANSACTION_HISTORY_COLUMNS = [
  {
    id: 1,
    name: "class_name",
    title: "Share class",
    width: "200px",
    sortable: true
  },
  {
    id: 2,
    name: "transaction_type",
    title: "Transaction type",
    width: "200px",
    sortable: true
  },
  {
    id: 3,
    name: "from",
    title: "From",
    width: "200px",
    sortable: true
  },
  {
    id: 4,
    name: "to",
    title: "To",
    width: "200px",
    sortable: true
  },
  {
    id: 5,
    name: "transaction_number_of_shares",
    title: "Shares",
    width: "200px",
    sortable: true
  },
  {
    id: 6,
    name: "created_at",
    title: "Initiated on",
    width: "200px",
    sortable: true
  },
  {
    id: 7,
    name: "initiator",
    title: "Initiator",
    width: "200px",
    sortable: true
  },
  {
    id: 8,
    name: "transaction_status",
    title: "Status",
    width: "200px",
    sortable: true
  }
];

const OWNERS_COLUMNS = [
  {
    id: 1,
    name: "owner",
    title: "Owner",
    width: "200px",
    sortable: true
  },
  {
    id: 2,
    name: "type",
    title: "Owner type",
    width: "200px",
    sortable: true
  },
  {
    id: 3,
    name: "share_name",
    title: "Share class",
    width: "200px",
    sortable: true
  },
  {
    id: 4,
    name: "shares_held",
    title: "Shares held",
    width: "200px",
    sortable: true
  },
  {
    id: 5,
    name: "ownership_percentage",
    title: "Ownership percentage",
    width: "200px",
    sortable: true
  }
];

function sharesHeld(total_shares, shares_held) {
  return total_shares
    ? ((shares_held / total_shares) * 100).toFixed(2) + "%"
    : "...";
}

const sharesStyles = makeStyles({
  formControl: {
    minWidth: 180
  },
  searchInput: {
    "& input": {
      height: "60%"
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: 1
    },

    "& label": {
      transform: "translate(14px, 23px) scale(1)"
    }
  }
});

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

let transactions_filter_timeout;
let owners_filter_timeout;

export default function SharesRoute(props) {
  const params = useParams();

  const classes = sharesStyles();

  const [shareClasses, setShareClasses] = useState([]);

  // Cap table
  const [capTable, setCapTable] = useState([]);
  const [filteredCapTable, setFilteredCapTable] = useState([]);

  // Transactions table
  const [shareTransactions, setShareTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [cancelSharesModalShown, setCancelSharesModalShown] = useState(false);
  const [shareTransactionModalShown, setShareTransactionModalShown] = useState(
    false
  );
  const [addShareholderModalShown, setAddShareholderModalShown] = useState(
    false
  );

  const [editModalShown, setEditModalShown] = useState(false);
  const [selectedShare, setSelectedShare] = useState({ _state: "new" });
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const [transactionsFilters, setTransactionsFilters] = useState({
    share_id: "-1",

    date_from: null,
    date_to: null
  });

  const [ownersFilters, setOwnersFilters] = useState({
    search: ""
  });

  const updateFilters = (table_name, e) => {
    if (table_name === "transactions") {
      setTransactionsFilters({
        ...transactionsFilters,
        [e.target.name]: e.target.value
      });
    } else if (table_name === "owners") {
      setOwnersFilters({ ...ownersFilters, [e.target.name]: e.target.value });
    }
  };

  // Update the transaction filter
  useEffect(() => {
    // clearTimeout(transactions_filter_timeout);
    // transactions_filter_timeout = setTimeout(updateFilteredTransactions, 500);

    // Don't use debounce - we don't have any free text fields
    updateFilteredTransactions();
  }, [transactionsFilters]);

  // Update the owners filter
  useEffect(() => {
    clearTimeout(owners_filter_timeout);
    owners_filter_timeout = setTimeout(updateFilteredOwners, 500);
  }, [ownersFilters]);

  const updateFilteredTransactions = () => {
    const transactions = [];

    const date_from =
      transactionsFilters.date_from && new Date(transactionsFilters.date_from);
    const date_to =
      transactionsFilters.date_to && new Date(transactionsFilters.date_to);

    for (const transaction of shareTransactions) {
      // Filter by share id
      if (
        transactionsFilters.share_id !== undefined &&
        transactionsFilters.share_id !== "-1" &&
        transaction.share_id !== transactionsFilters.share_id
      ) {
        continue;
      }

      // Filter by date from
      if (date_from && transaction.created_at_timestamp < date_from) {
        continue;
      }

      // Filter by date to
      if (date_to && transaction.created_at_timestamp > date_to) {
        continue;
      }

      transactions.push(transaction);
    }

    setFilteredTransactions([...transactions]);
  };

  const updateFilteredOwners = () => {
    const owners = [];

    const search_term = ownersFilters.search.toLowerCase();

    for (const owner of capTable) {
      // // Filter by share id
      // if(
      //   transactionsFilters.share_id !== undefined &&
      //   transactionsFilters.share_id !== "-1" &&
      //   transaction.share_id !== transactionsFilters.share_id
      // ) {
      //   continue;
      // }

      // Search filter
      if (search_term && !owner.owner.toLowerCase().includes(search_term)) {
        continue;
      }

      owners.push(owner);
    }

    setFilteredCapTable([...owners]);
  };

  const showCreateNewShareModal = () => {
    setSelectedShare({ _state: "new" });
    setEditModalShown(true);
  };

  const showEditShareModal = selected_share => {
    setSelectedShare(selected_share);
    setEditModalShown(true);
  };

  const showCancelShareModal = selected_share => {
    setSelectedShare(selected_share);
    setCancelSharesModalShown(true);
  };

  const showTransferShareModal = (selected_share, transaction_type) => {
    setSelectedTransactionType(transaction_type);
    setSelectedShare(selected_share);
    setShareTransactionModalShown(true);
  };

  const getShares = () => {
    Axios.get(`company/${params.entity_id}/share-classes`)
      .then(({ data }) => {
        data.share_classes.map(share_class => {
          // TODO @hack
          share_class.share_issued_on = new Date(share_class.share_issued_on)
            .toJSON()
            .split("T", 1)[0];

          return share_class;
        });

        const transactions_table = [];
        const owners_table = [];
        const cap_table = [];

        for (const transaction of data.share_transactions) {
          transactions_table.push({
            transaction_id: transaction.transaction_id,
            share_id: transaction.customer_company_share.share_id,

            class_name: transaction.customer_company_share.share_name,
            transaction_type: transaction.transaction_type,

            from: transaction.from_user ? (
              <Link to={`/user/${transaction.from_user.user_id}`}>
                {transaction.from_user.user_full_name}
              </Link>
            ) : (
              "—"
            ),
            to: transaction.to_user ? (
              <Link to={`/user/${transaction.to_user.user_id}`}>
                {transaction.to_user.user_full_name}
              </Link>
            ) : (
              "—"
            ),

            transaction_number_of_shares:
              transaction.transaction_number_of_shares,
            created_at: transaction.created_at,
            initiator: transaction.initiator_user ? (
              <Link to={`/user/${transaction.initiator_user.user_id}`}>
                {transaction.initiator_user.user_full_name}
              </Link>
            ) : (
              "—"
            ),

            created_at_timestamp: new Date(transaction.created_at).getTime(),

            transaction_status: transaction.transaction_status
          });
        }

        for (const owner of data.cap_table) {
          let title_row = "";

          if (owner.user) title_row = owner.user.user_full_name;
          else if (owner.pc) title_row = owner.pc.pc_name;
          else if (
            owner.customer_company_available_shareholder.holder_type ===
            "real_company"
          )
            title_row =
              owner.customer_company_available_shareholder.holder_company_name;

          title_row += ` (${owner.customer_company_available_shareholder.holder_type})`;

          owners_table.push({
            shareholder_id: owner.shareholder_id,

            owner: title_row,
            type: owner.customer_company_available_shareholder.holder_type,
            share_name: owner.customer_company_share.share_name,
            shares_held: owner.shares_held,
            ownership_percentage: sharesHeld(
              data.total_shares_held,
              owner.shares_held
            )
          });

          cap_table.push({ ...owner, owner: title_row });
        }

        setShareTransactions(transactions_table);
        setFilteredTransactions(transactions_table);

        setCapTable(cap_table);
        setFilteredCapTable(owners_table);

        setShareClasses(data.share_classes);
      })
      .catch(() => {});
  };

  const updateShareCapTable = () => {
    Axios.put(`company/${params.entity_id}/update-share-cap`)
      .then(() => {
        getShares();
      })
      .catch(() => {});
  };

  const onShareUpdate = () => {
    // We have either created, cancelled or updated a share class. Close the edit modal and get the new shares

    setCancelSharesModalShown(false);
    setEditModalShown(false);
    getShares();
  };

  const onTransactionCreated = () => {
    setShareTransactionModalShown(false);
    getShares();
  };

  useEffect(() => {
    getShares();
  }, []);

  return (
    <div>
      <CancelSharesModal
        share={selectedShare}
        show={cancelSharesModalShown}
        onHide={state => setCancelSharesModalShown(state)}
        onCancel={onShareUpdate}
      />

      <EditShareModal
        share={selectedShare}
        show={editModalShown}
        onHide={state => setEditModalShown(state)}
        onSave={onShareUpdate}
      />

      <SharesTransactionModal
        type={selectedTransactionType}
        show={shareTransactionModalShown}
        onHide={state => setShareTransactionModalShown(state)}
        onSave={onTransactionCreated}
        shares={shareClasses}
        selected_share={selectedShare}
        entity_id={params.entity_id}
        cap_table={capTable}
      />

      <AddShareholderModal
        show={addShareholderModalShown}
        onHide={state => setAddShareholderModalShown(state)}
        entity_id={params.entity_id}
      />

      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Type</TableCell>
            {/* <TableCell align="center">Date Issued</TableCell>
            <TableCell align="center">Restrictions</TableCell> */}
            <TableCell align="center">Value per share</TableCell>
            <TableCell align="center">Authorised Shares</TableCell>
            <TableCell align="center">Outstanding Shares</TableCell>
            <TableCell align="center">Unissued Shares</TableCell>
            <TableCell align="center">Voting Rights per share</TableCell>
            <TableCell align="center">Director Approval required</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shareClasses.map(share => (
            <StyledTableRow>
              <TableCell align="center">{share.share_name}</TableCell>
              {/* <TableCell align="center">{ share.share_issued_on }</TableCell> */}
              {/* <TableCell align="center">{ share.share_restrictions || "—" }</TableCell> */}
              <TableCell align="center">{share.share_nominal_value}</TableCell>
              <TableCell align="center">
                {share.share_authorized_shares}
              </TableCell>
              <TableCell align="center">
                {share.share_outstanding_shares}
              </TableCell>
              <TableCell align="center">
                {share.share_authorized_shares - share.share_outstanding_shares}
              </TableCell>
              <TableCell align="center">{share.share_voting_rights}</TableCell>
              <TableCell align="center">
                {share.share_director_approval_required ? "Yes" : "No"}
              </TableCell>
              <TableCell align="center">
                <Dropdown drop="left">
                  <Dropdown.Toggle className="btn btn-icon btn-light btn-sm ui-dropdown-no-arrow">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Settings-2.svg"
                        )}
                      />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => showEditShareModal(share)}>
                      View/edit share capital
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => showCancelShareModal(share)}>
                      Cancel shares
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => showTransferShareModal(share, "issue")}
                    >
                      Issue shares
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => showTransferShareModal(share, "transfer")}
                    >
                      Transfer shares
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => showTransferShareModal(share, "buyback")}
                    >
                      Buyback shares
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <div className="my-4">
        <Button variant="primary" onClick={showCreateNewShareModal}>
          Add a new share class
        </Button>
        <Button variant="primary" onClick={updateShareCapTable}>
          Update share cap table
        </Button>
        <Button
          variant="primary"
          onClick={() => setAddShareholderModalShown(true)}
        >
          Add a new shareholder
        </Button>
      </div>

      {/* Owners */}
      <div className="ui-table-filters">
        <div className="fields">
          <div></div>
          <div>
            <TextField
              className={classes.searchInput}
              label="Search owners"
              variant="outlined"
              name="search"
              value={ownersFilters.search}
              onChange={e => updateFilters("owners", e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>

      <DataTable
        rows={filteredCapTable}
        idField="shareholder_id"
        columns={OWNERS_COLUMNS}
      />

      {/* Transactions */}
      <div className="ui-table-filters">
        <div className="fields">
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Share class
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                name="share_id"
                value={transactionsFilters.share_id}
                onChange={e => updateFilters("transactions", e)}
              >
                <MenuItem value="-1">
                  <em>All</em>
                </MenuItem>

                {shareClasses.map(share_class => (
                  <MenuItem
                    key={share_class.share_id}
                    value={share_class.share_id}
                  >
                    {share_class.share_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Date from"
              variant="outlined"
              className="ml-2"
              type="date"
              name="date_from"
              value={transactionsFilters.date_from}
              onChange={e => updateFilters("transactions", e)}
            />
            <TextField
              label="Date to"
              variant="outlined"
              className="ml-2"
              type="date"
              name="date_to"
              value={transactionsFilters.date_to}
              onChange={e => updateFilters("transactions", e)}
            />
          </div>
          <div></div>
        </div>
      </div>

      <DataTable
        rows={filteredTransactions}
        idField="transaction_id"
        columns={TRANSACTION_HISTORY_COLUMNS}
      />
    </div>
  );
}
