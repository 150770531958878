/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import '../ProfilePage.css';
import { Modal, Button } from 'react-bootstrap';
import { BrowseFile } from '../../components/Basic';
import moment from 'moment';
import { Table } from '../../modules/Auth/pages/customers/customers-table/CustomersTable';
import MoreDetailsDropdown from '../../components/Dropdown/MoreDetailsDropdown';
import UploadFilesModal from '../../components/UploadFilesModal/UploadFilesModal';
import { getDocument } from '../../api/document';

export default function TemplatesRoute(props) {
  const [templates, setTemplates] = useState([]);

  const templateCols = [
    {
      id: 1,
      name: 'document_title',
      title: 'Document title',
      component: row => {
        if (!row.document_title) return `Document ${row.document_id}`;
        return row.document_title;
      }
    },
    {
      id: 2,
      name: 'created_at',
      title: 'Created at',
      component: row => {
        return moment(row.created_at).format('DD MMM YYYY');
      }
    },
    {
      id: 4,
      name: 'actions',
      title: 'Actions',
      component: row => {
        return (
          <MoreDetailsDropdown
            options={[
              {
                id: 1,
                title: 'Download',
                onClick: () => getDocument(row.document_id, 'document', 'csp')
              },
              {
                id: 2,
                title: 'Reupload',
                onClick: () => onReuploadClick(row.document_id)
              },
            ]}
          />
        );
      }
    }
  ];

  const [newTemplateModalShown, setNewTemplateModalShown] = useState(false);
  const [reuploadTemplateModalShown, setReuploadTemplateModalShown] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const [newSelectedFiles, setNewSelectedFiles] = useState([]);

  const [newTemplateInfo, setNewTemplateInfo] = useState({ template_name: '' });

  const file_input = useRef();

  const addDocumentsClick = () => {
    file_input.current.click();
  };

  const fileInputChange = e => {
    e.stopPropagation();
    e.preventDefault();

    setNewSelectedFiles([...e.target.files]);

    // Get template name from the filename
    let new_template_name = e.target.files[0].name;
    new_template_name = new_template_name.substring(0, new_template_name.lastIndexOf('.'));

    setNewTemplateInfo({
      ...newTemplateInfo,
      template_name: new_template_name
    });
  };

  const onReuploadClick = document_id => {
    setSelectedTemplateId(document_id);
    setReuploadTemplateModalShown(true);
  };

  const reuploadTemplate = () => {

    const form_data = new FormData();

    form_data.append('template', newSelectedFiles[0]);

    Axios.post(`csp/templates-upload?document_id=${selectedTemplateId}`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        // Files uploaded, refresh the templates
        getTemplates();

        setReuploadTemplateModalShown(false);
        setNewSelectedFiles([]);
      })
      .catch(() => {
      });
  };

  const finishNewTemplateCreation = () => {

    const form_data = new FormData();

    form_data.append('title', newTemplateInfo.template_name);
    form_data.append('template', newSelectedFiles[0]);

    return Axios.post('csp/templates-upload', form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        // Files uploaded, refresh the templates

        setNewTemplateModalShown(false);
        setNewSelectedFiles([]);

        return getTemplates();
      })
      .catch(() => {
      });
  };

  const finishNewTemplateCreationNext = (e, documents) => {

    const form_data = new FormData();

    form_data.append('title', newTemplateInfo.template_name);
    form_data.append('template', documents[0].file);

    return Axios.post('csp/templates-upload', form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        // Files uploaded, refresh the templates

        setNewSelectedFiles([]);

        return getTemplates();
      })
      .finally(() => {
      })
  };

  const getTemplates = () => {
    return Axios.get(`/csp/templates`).then(response => {
      setTemplates(response.data.csp_templates);
    });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const updateNewTemplateInfo = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setNewTemplateInfo({ ...newTemplateInfo, [e.target.name]: value });
  };

  return (
    <>
      <Modal
        size="lg"
        show={newTemplateModalShown}
        onHide={() => setNewTemplateModalShown(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload a new template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-start">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFile" ref={file_input} onChange={fileInputChange} />
              <label class="custom-file-label" for="customFile" onClick={addDocumentsClick}>
                {newSelectedFiles.length ? (
                  newSelectedFiles.map((file, index) => (
                    <div key={file.name}>
                      {index}: {file.name} ({file.size}b)
                    </div>
                  ))
                ) : (
                  <div>(Documents to be uploaded)</div>
                )}
              </label>
            </div>
          </div>

          <input
            placeholder="Template name"
            type="text"
            className="form-control h-auto py-5 px-6 my-2"
            name="template_name"
            onChange={updateNewTemplateInfo}
            value={newTemplateInfo.template_name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setNewTemplateModalShown(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={finishNewTemplateCreation}>
            Add template
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={reuploadTemplateModalShown} onHide={() => setReuploadTemplateModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reupload a template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {newSelectedFiles.length ? (
            newSelectedFiles.map((file, index) => (
              <div key={file.name}>
                {index}: {file.name} ({file.size}b)
              </div>
            ))
          ) : (
            <div>(none)</div>
          )}
          <BrowseFile
            id="fileReupload"
            multiple={false}
            ref={file_input}
            onChange={fileInputChange}
            onClick={addDocumentsClick}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setReuploadTemplateModalShown(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={reuploadTemplate}>
            Reupload template
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-header">
        <div className="card-title w-50">CSP's templates</div>

        <div className="card-toolbar">
          {/* <button
            onClick={() => setNewTemplateModalShown(true)}
            className="btn btn-primary"
          >
            Add a template
          </button> */}
          <UploadFilesModal
            onSave={finishNewTemplateCreationNext}
            title="Upload new template"
            uploadButtonTitle="Upload template"
            inputTitle="Select files"
            notificationTitle="Success"
            notificationMessage="Template uploaded"
            editable
            fileName={newTemplateInfo.template_name}
            onFileNameChange={newName => setNewTemplateInfo(old => ({ ...old, template_name: newName }))}
          />
        </div>
      </div>

      <div className="card-body">
        <Table nonSelectable cols={templateCols} entities={templates} idField="document_id" />
      </div>
    </>
  );
}