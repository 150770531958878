/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import '../ProfilePage.css';
import { FeesTableConfig } from '../../components/FeesTable';
import moment from 'moment';
import CSPSettingsTable from '../csp-config-page/CSPSettingsTable';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions';
import { saveService, updateService, deleteService } from "../../api/services";
import SimpleModalFieldsConfiguration from "../../components/EditProfileForm/SimpleModalFields";
import * as yup from "yup";

export default function FeesConfigRoute(props) {
  const [newOtherFeesConfig, setNewOtherFeesConfig] = useState([]);

  const [setServiceFeesConfig] = useState([]);
  const [newServiceFeesConfig, setNewServiceFeesConfig] = useState([]);
  const [apiWarning, setApiWarning] = useState();

  const [exportSettings, setExportSettings] = useState({
    date_from: moment(),
    date_to: moment()
  });

  const [formattedDateSettings, setFormattedDateSettings] = useState('Select range');

  const [serviceModalState, setServiceModalState] = useState({
    isOpen: false,
    mode: 'add',
    data: {}
  });

  useEffect(() => {
    setFormattedDateSettings(
      exportSettings.date_from.format('DD MMM YYYY') + ' - ' + exportSettings.date_to.format('DD MMM YYYY')
    );
  }, [exportSettings]);

  const updateServiceNext = async (fieldName, value, index, row) => {
    if (fieldName === "service_enabled") {
      const service = {
        ...row,
        "service_enabled": value,
      }
      await updateServiceConfig(service);
    }
  };

  const setOtherFeeNext = (fieldName, value, index) => {
    setNewOtherFeesConfig(prevState => {
      prevState[index][fieldName] = value;
      return [...prevState];
    });
  };

  const generateSalesExport = () => {
    const date_from = exportSettings.date_from ? exportSettings.date_from.unix() * 1000 : null;
    const date_to = exportSettings.date_to ? exportSettings.date_to.unix() * 1000 : null;

    const url = `csp/generate-sales-export?from_date=${date_from}&to_date=${date_to}`;

    Axios.get(url)
      .then(response => {
        if (response.data) {
          notify({
            type: 'SUCCESS',
            title: 'Sales has been successfully exported',
            message: 'You can find it on vault page'
          });
        }
      })
      .catch(() => { });
  };

  const getFeeConfig = () => {
    Axios.get('csp/fees-config')
      .then(response => {
        if (response.data) {
          const other_fees = response.data.other_fees.map(fee => {
            if (!fee.fee_price && !fee.price_ranges) fee.fee_price = 0;

            return fee;
          });

          setNewOtherFeesConfig(other_fees);

          setServiceFeesConfig(response.data.service_fees);
          setNewServiceFeesConfig(response.data.service_fees);
        }
      })
      .catch(() => { });
  };

  const saveConfig = () => {
    // Create the fees config that we can send to the backend
    const other_fees = newOtherFeesConfig.map(fee => {
      const ranges = {};

      for (const field in fee) {
        if (field.startsWith('range_price_')) {
          const range_id = field.substring(12);

          ranges[range_id] = fee[field];
        }
      }

      return {
        fee_id: fee.fee_id,
        fee_enabled: fee.fee_enabled,
        fee_account_code: fee.fee_account_code,
        fee_account_id: fee.fee_account_id,
        fee_price: fee.fee_price,
        fee_price_ranges: ranges || undefined,
        fee_regulator_price: fee.fee_regulator_price,
        fee_government_price: fee.fee_government_price
      };
    });

    Axios.post('csp/fees-config', {
      deleted_services: [],
      other_fees,
      services: []
    })
      .then(response => {
        notify({
          type: 'SUCCESS',
          title: 'The CSP`s fees has been successfully saved',
        });
        setApiWarning(response.data.warning);
      })
      .catch((e) => {
        notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
      });
  };

  const onAddServiceClick = () => {
    const defaultService = {
      service_title: 'New service',
      service_billing_type: 'monthly',
      service_custom_billing_starting_date: null,
    };

    setServiceModalState({
      isOpen: true,
      mode: "add",
      data: defaultService,
    });
  };

  const onEditServiceClick = (service) => {
    setServiceModalState({
      isOpen: true,
      mode: "edit",
      data: service,
    });
  }

  const onCloseModalClick = () => {
    setServiceModalState(state => {
      return {
        mode: state.mode,
        data: state.data,
        isOpen: false
      }
    });
  };

  const onSaveServiceClick = (mode) => (service) => {
    const saveActionsMap = {
      "add": (service) => saveServiceConfig(service),
      "edit": (service) => updateServiceConfig(service),
    }

    const saveAction = saveActionsMap[mode];
    saveAction(service);
  }

  //For some reason, somewhere in the code 'id' property is being added,
  //which is not valid at backend side, so it's being deleted
  const sanitizeService = (service) => {
    if (service.id) delete service.id;
    return service
  }

  const setNewService = (service) => {
    setNewServiceFeesConfig(oldConfig => [
      ...oldConfig,
      service,
    ]);
  }

  const setUpdatedService = (service) => {
    setNewServiceFeesConfig(oldConfig => {
      const services = [...oldConfig];
      const indexOfService = services.findIndex((element) => element.service_id === service.service_id);
      services.splice(indexOfService, 1, service);
      return [...services];
    });
  }

  const removeDeletedService = (serviceId) => {
    setNewServiceFeesConfig(oldConfig => {
      const services = [...oldConfig];
      const indexOfService = services.findIndex((element) => element.service_id === serviceId);
      services.splice(indexOfService, 1);
      return [...services];
    });
  }

  const saveServiceConfig = async (service) => {
    try {
      const data = sanitizeService(service);
      const newService = await saveService(data);
      setNewService(newService);
      notify({
        type: 'SUCCESS',
        title: 'The new CSP`s service has been successfully saved',
      });
    } catch (e) {
      notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
    }
  }

  const updateServiceConfig = async (service) => {
    try {
      const data = sanitizeService(service);
      const updatedService = await updateService(data);
      setUpdatedService(updatedService);
      notify({
        type: 'SUCCESS',
        title: 'The CSP`s service has been successfully saved',
      });
    } catch (e) {
      notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
    }
  }

  const deleteServiceConfig = async (serviceId) => {
    try {
      const deletedServiceId = await deleteService(serviceId);
      removeDeletedService(deletedServiceId);
      notify({
        type: 'SUCCESS',
        title: 'The CSP`s service has been successfully deleted',
      });
    } catch (e) {
      notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error" })
    }
  }

  // Get the config on mount
  useEffect(() => {
    if (!Object.keys(newServiceFeesConfig).length && !Object.keys(newOtherFeesConfig).length) getFeeConfig();
  }, []);

  function AddOrEditServiceModal({ isOpen, mode, serviceData }) {
    const fieldsOptions = [
      {
        name: 'service_title',
        title: 'Service Title',
        type: 'text',
        validation: () => yup.string().required('Service title is required!'),
      },
      {
        name: 'service_description',
        title: 'Service Description',
        type: 'multiline-text',
        validation: () => yup.string().required('Service description is required!'),
      },
      {
        name: 'service_price',
        title: 'Service Price',
        type: 'number',
        validation: () => yup.number().required('Service price is required!'),
      },
      {
        name: 'service_billing_type',
        title: 'Billing type',
        type: "select",
        value: "monthly",
        options: [
          {
            value: 'one_time',
            title: 'One time'
          },
          {
            value: 'monthly',
            title: 'Monthly'
          },
          {
            value: 'quarterly',
            title: 'Quarterly'
          },
          {
            value: 'annually',
            title: 'Annually'
          },
          {
            value: 'custom',
            title: 'Custom'
          },
        ],
        validation: () => yup.string().required('One of billing types is required!'),
      },
      {
        name: 'service_account_id',
        title: 'Account ID',
        type: "number",
      },
      {
        name: 'service_account_code',
        title: 'Account code',
        type: "number",
      },
      {
        name: 'service_custom_billing_starting_date',
        title: 'Starting date',
        type: "date",
      },
      {
        name: 'service_custom_billing_frequency',
        title: 'Frequency (days)',
        type: "number",
      }
    ];

    const titles = {
      "add": "Add a new service",
      "edit": "Edit the service",
    };

    const currentOption = {
      options: fieldsOptions,
      title: titles[mode] || '',
    };

    return (
      <SimpleModalFieldsConfiguration
        open={isOpen}
        onClose={onCloseModalClick}
        onChange={onSaveServiceClick(mode)}
        currentOption={currentOption}
        value={serviceData}
      />
    )
  }

  const { isOpen, mode, data } = serviceModalState;
  return (
    <>
      <AddOrEditServiceModal isOpen={isOpen} serviceData={data} mode={mode} />
      <div className="card-header">
        <div className="card-title w-50 text-dark">CSP fees configuration</div>
        <div className="card-toolbar">
          <button onClick={onAddServiceClick} className="btn btn-success">
            Add a service
          </button>
        </div>
      </div>

      <div className="card-body">
        <div className="col-lg-12">
          <CSPSettingsTable settings={newServiceFeesConfig} removeService={deleteServiceConfig} editService={onEditServiceClick} onFeeUpdate={updateServiceNext} />
        </div>

        <div className="col-lg-12">

          <FeesTableConfig fees={newOtherFeesConfig} onFeeUpdate={setOtherFeeNext} clientCanViewFullInfo={true} />
        </div>

        {apiWarning && (
          <div className="my-5 alert alert-custom alert-light-warning alert-dismissible">
            <div className="alert-text font-weight-bold">{apiWarning}</div>
          </div>
        )}

        <div className="buttons-container d-flex justify-content-end">
          <button onClick={saveConfig} className="btn btn-primary mt-5">
            Save CSP's fees
          </button>
        </div>
      </div>

      <div className="card-footer">
        <div className="mt-4 d-flex align-items-center">
          <DateRangePicker
            onApply={(e, picker) => {
              setExportSettings({
                date_from: picker.startDate,
                date_to: picker.endDate
              });
            }}
            opens="left"
            locale={{
              format: 'DD-MM-YYYY',
              applyLabel: 'ok',
              customRangeLabel: 'user define'
            }}
          >
            <input type="text" value={formattedDateSettings} className="form-control col-lg-2 mr-5" />
          </DateRangePicker>

          <button onClick={generateSalesExport} className="btn btn-secondary">
            Generate Sales CSV
          </button>
        </div>
      </div>
    </>
  );
}