import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { redirectToTenant } from "../../util";
import {Tooltip} from "@material-ui/core";
import { SplashScreen } from '../../_metronic/_partials/controls';
import { triggerCheckUser } from "../../redux/Tenant/tenantActions";
import "./CreateCSPPage.css"
import { AddressForm } from '../components/AddressForm/AddressForm.jsx';

const initialValues = {
  name: '',
  slug: '',
  registration_number: '',
  license_number: '',
  jurisdiction_region: ''
};

export default function CreateCSPPage(props) {
  const history = useHistory();

  const csp_id = props.match.params.csp_id;

  const [addressQualifiers, setAddressQualifiers] = useState([]);

  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const regex = {
    company: /^[\w&\.\(\) -]{6,50}$/g,
    license: /^[\w -]{5,100}$/g,
    slug: /^[a-z-]{4,20}$/g,
  }
  function innerTrim(str){
    return str.replace(/\s\s+/g, ' ');
  }
  const CreateCSPSchema = Yup.object().shape({
    name: Yup.string()
      .min(6, 'Minimum 6 characters')
      .max(50, 'Maximum 50 characters')
      .matches(regex.company)
      .required('CSP company name is required'),
    slug: Yup.string()
      .matches(regex.slug)
      .required('Slug is required'),
    registration_number: Yup.string()
      .min(5, 'Minimum 5 characters')
      .max(100, 'Maximum 100 characters')
      .matches(regex.license, 'Invalid Registration Number format')
      .required('Registration number is required'),
    license_number: Yup.string()
      .min(5, 'Minimum 5 characters')
      .max(100, 'Maximum 100 characters')
      .matches(regex.license, 'Invalid License Number format')
      .required('License number is required'),
    jurisdiction_region: Yup.number()
      .min(0, 'Please, choose a jurisdiction region')
      .required('Jurisdiction region is required')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };


  const getValidDropdown = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'dropdown-is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'dropdown-is-valid';
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: CreateCSPSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      const address = {};

      for(const qualifier of addressQualifiers) {
        address[qualifier[0]] = qualifier[1];
      }

      // Create CSP
      Axios.post('csp/create', {
        csp_id,

        name: values.name,
        slug: values.slug,
        registration_number: values.registration_number,
        license_number: values.license_number,
        jurisdiction_region: values.jurisdiction_region,
        address
      })
        .then(() => {
          redirectToTenant(values.slug, true);
          disableLoading();
          setStatus();
          triggerCheckUser(new Date())
          history.push('/dashboard')
          window.location.reload()
        })
        .catch(error => {
          if (!!error.response) setStatus(error?.response?.data.error_message);

          disableLoading();
          setSubmitting(false);
        });
    }
  });

  const getAvailableRegions = () => {
    Axios.get('csp/jurisdiction-regions')
      .then(({ data }) => {
        setRegions(data.jurisdiction_regions);
      })
      .catch(() => {});
  };

  useEffect(() => {
    // TODO on mount check if the user has access to this CSP and it is in a draft state

    getAvailableRegions();
  }, []);

  function handleNameChange(value) {
    if (value.includes('  ')) {
      value = innerTrim(value)
    }
    formik.setFieldTouched('name', true, true);
    formik.setFieldValue('name', value);
  }

  function handleNameBlur(value) {
    formik.setFieldValue('name', value.trim());
  }

  return (
    <>
      {loading ? (
        <SplashScreen textContent="Your csp is being prepared, please wait" />
      ) : (
        <div className="d-flex flex-column flex-root">
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
            id="create_csp_root"
          >
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="create-csp-form w-500px">
                  <div className="text-center mb-10 mb-lg-10">
                    {!isSuccess && (
                      <>
                        <h3 className="font-size-h1">Create a New Corporate Service Provider (CSP) Account</h3>
                        <p className="text-muted font-weight-semi-bold">
                          Please complete all fields below to create your new account
                        </p>
                      </>
                    )}
                  </div>
                   <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                      {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                          <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                      )}

                      {isSuccess && (
                        <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                          <div className="alert-text font-weight-bold">
                            The CSP has been successfully created!{' '}
                            <strong>Please, allow up to a minute for the tenant to be completely ready</strong>. Your new CSP will
                            appear in the main menu as soon as this process is completed.
                          </div>
                        </div>
                      )}

                      {!isSuccess && (
                        <>
                          <div className="form-group fv-plugins-icon-container">
                            <Tooltip title="the legal name of this corporate service provider entity">
                              <input
                                placeholder="CSP name"
                                type="text"
                                className={`form-control h-auto py-5 px-6 ${getInputClasses('name')}`}
                                name="name"
                                {...formik.getFieldProps('name')}
                                onChange={e => {
                                  handleNameChange(e.target.value)
                                }}
                                onBlur={e => {
                                  handleNameBlur(e.target.value)
                                }}
                              />
                            </Tooltip>
                            {formik.touched.name && formik.errors.name ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group fv-plugins-icon-container">
                            <div className="d-flex align-items-center">
                              <input
                                placeholder="Slug"
                                type="text"
                                className={`form-control h-auto py-5 px-6 ${getInputClasses('slug')}`}
                                name="slug"
                                {...formik.getFieldProps('slug')}
                              />
                              <div className="mx-3">.offshoreapp.com</div>
                            </div>
                            {formik.touched.slug && formik.errors.slug ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  CSP slug should only contain lowercase letters and a minus symbol and should be 4-20 characters
                                  long
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group fv-plugins-icon-container">
                            <Tooltip title="i.e. CIMA # for CSP">
                              <input
                                placeholder="Regulatory License Number"
                                type="text"
                                className={`form-control h-auto py-5 px-6 ${getInputClasses('license_number')}`}
                                name="license_number"
                                {...formik.getFieldProps('license_number')}
                              />
                            </Tooltip>
                            {formik.touched.license_number && formik.errors.license_number ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.license_number}</div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group fv-plugins-icon-container">
                            <Tooltip title="from General Registry, i.e. CR-12345678">
                              <input
                                placeholder="Registration number"
                                type="text"
                                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                                  'registration_number'
                                )}`}
                                name="registration_number"
                                {...formik.getFieldProps('registration_number')}
                              />
                            </Tooltip>
                            {formik.touched.registration_number && formik.errors.registration_number ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.registration_number}</div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group fv-plugins-icon-container">
                            <Tooltip title="this will configure the settings of this account for CSPs operating in the selected Jurisdiction">
                              <select
                                className={`form-control h-auto py-5 rounded-lg font-size-h6 mr-5 ${getValidDropdown(
                                  'jurisdiction_region'
                                )}`}
                                type="number"
                                placeholder="Which Jurisdiction is this CSP operating?"
                                name="jurisdiction_region"
                                autoComplete="off"
                                {...formik.getFieldProps('jurisdiction_region')}
                              >
                                <option value="-1">Which Jurisdiction is this CSP operating?</option>
                                {regions.map(item => (
                                  <option key={item.region_id} value={item.region_id}>
                                    {item.region_title}
                                  </option>
                                ))}
                              </select>
                            </Tooltip>

                            {formik.touched.jurisdiction_region && formik.errors.jurisdiction_region ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.jurisdiction_region}</div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group fv-plugins-icon-container">
                            <AddressForm
                              qualifiers={ addressQualifiers }
                              setQualifiers={ setAddressQualifiers }
                            />
                          </div>
                        </>
                      )}

                      <div className="form-group d-flex flex-column align-items-center">
                        {!isSuccess ? (
                          <>
                            <button
                              id="kt_create_csp_submit"
                              type="submit"
                              disabled={formik.isSubmitting}
                              className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100"
                            >
                              <span>Create New CSP Account</span>
                              {/*{loading && (*/}
                              {/*  <span className="ml-3 spinner spinner-white"></span>*/}
                              {/*)}*/}
                            </button>
                            <Link to="/dashboard" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
                              Back
                            </Link>
                          </>
                        ) : (
                          <a href="#" onClick={e => {e.preventDefault(); triggerCheckUser(Date.now())}} className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100">
                            Go to my CSP
                          </a>
                        )}
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
