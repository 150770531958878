/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./style.css"

export default function CreateCompanyPage2(
  {
    config_available_services,
    formik,
    values_selected_services,
    totalServicesPrice,
    onServiceSelect,
    loading,
    goBack,
    onPageSubmit
  }) {
  return (
    <>
      {/* configReady &&  */config_available_services.map(service => (
        <div key={service.service_id} className="card mb-6 px-5 py-4">
          <div className="d-flex justify-content-between">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  name={`service_${service.service_id}`}
                  className="m-1"
                  {...formik.getFieldProps(`service_${service.service_id}`)}
                  onChange={e => onServiceSelect(e, service)}
                  checked={values_selected_services[service.service_id] !== undefined}
                />

                <span />

                <a className="ml-2 text-dark-75 font-weight-bold font-size-lg">
                  {service.service_title}
                </a>
              </label>
            </div>
            <div className="font-weight-bolder text-dark font-size-h6">${service.service_price}</div>
          </div>
          <div className="mt-2">
            {service.service_description}
            <div className="text-muted mt-2">{
              service.service_billing_type === "one_time"
                ? "One-time payment"
                : "Monthly payment"
            }</div>
          </div>
        </div>
      ))}

      <div style={{ height: "80px", background: "#FAF9F9" }} className=" align-items-center card card-custom d-flex flex-row justify-content-between mt-10 my-6 px-5 py-4">
        <div style={{ color: "black" }} className="font-size-lg">Total:</div>
        <div style={{ color: "black" }} className="font-weight-bolder font-size-h3">${totalServicesPrice}</div>
      </div>
      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="cancel-btn font-weight-bold px-9 py-4 my-3"
          onClick={goBack}
        >Back</button>
        <button
          disabled={loading}
          type="submit"
          onClick={onPageSubmit}
          className="submit-btn font-weight-bold px-9 py-4 my-3"
        >
          <span>
            Finish
          </span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>

      </div>
    </>
  )
}
