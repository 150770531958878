import { notify } from "./NotificationSnackbar/snackbarActions";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      // notify({type: "SUCCESS", title: "Test", message: "Server didn't respond"})
      return response;
    },
    async function (error) {
      if (error && error.response) {
        if (error.request.responseType === 'blob') {
          error.response.data = JSON.parse(await error.response.data.text())
        }

        if (error.response.status !== 401) {
          notify({type: "ERROR", title: error.response.data.error_title || "Server error", message: error.response.data.error_message || error.response.data})
        }
        
      } else {
        notify({type: "ERROR", title: "No response", message: "Server didn't respond"})
      }
      
      return Promise.reject(error);
    }
  );
}
