import { TextField, MenuItem, RadioGroup, FormControlLabel, Radio, Switch, Box, useTheme, Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/material.css'
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import CountrySelect from '../Dropdown/CountrySelect'
import FileInput from '../_common/FileInput'
import ColoredSelect from '../Dropdown/ColoredSelect'
import Phone from '../_common/Phone'
import { AddressForm } from '../AddressForm/AddressForm'
import AddressFormNext from '../_common/AddressFormNext'

function numberHandler(event) {
  if ((event.key >= 0 && event.key <= 9) || event.key === "Backspace") return;
  event.preventDefault()
}

export default function BaseFieldsConfiguration({ option, editTextFieldValue, errors, value, disabled }) {
  const theme = useTheme();
  const [openSwitch, setOpenSwitch] = useState(() => {
    if (option.type === 'open-switch' && value)
      return value[option.name]
    return false
  })

  const onChange = option.onChange ?? (() => { });
  
  useEffect(() => {
    if (option.type === 'open-switch' && value)
      setOpenSwitch(value[option.name])
  }, [option.type, option.name, value])

  if (option.visible && option.visible(value) === false) return null

  switch (option.type) {
    case 'component': {
      return (
        <div className="row">
          <div className="col-lg-12">
            {
              option.component && option.component({
                value: value?.[option.name],
                onChange: (v) => editTextFieldValue(v, option.name)
              })
            }
          </div>
          {
            errors?.[option.name] &&
            (<Box className='pl-10' style={{ color: theme.palette.error.main }}>{errors?.[option.name]}</Box>)
          }
        </div>
      )
    }
    case 'avatar': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <ProfileAvatar value={value?.[option.name]} onChange={e => { editTextFieldValue(e, option.name); onChange(value) }} />
          </div>
        </div>
      )
    }
    case 'text': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              variant="outlined"
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              fullWidth
              value={value?.[option.name]} />
          </div>
        </div>
      )
    }
    case 'number': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              variant="outlined"
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onKeyPress={e => numberHandler(e)}
              onChange={e => {
                editTextFieldValue(e.target.value, option.name);
                onChange(value)
              }}
              fullWidth
              value={value?.[option.name]} />
          </div>
        </div>
      )
    }
    case 'multiline-text': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              variant="outlined"
              multiline
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              fullWidth
              value={value?.[option.name]} />
          </div>
        </div>
      )
    }
    case 'phone': {
      return (<div className="form-group row">
        <label className="col-form-label col-3 d-flex align-items-center">Phone</label>
        <div className="col-9">
          <Phone
            disabled={option?.disabled || disabled}
            isValid={() => {
              if (errors?.[option.name]) return errors?.[option.name]
              else return true;
            }}
            onChange={v => { editTextFieldValue(v, option.name); onChange(value) }}
            value={value?.[option.name]}
            specialLabel=''
            inputClass="w-100 h-50"
            defaultErrorMessage={"Invalid phone number"}
          />
        </div>
      </div>)
    }
    case 'divider': {
      return (
        <div className="form-group row" key={option.name}>
          <hr className="w-100" />
        </div>
      )
    }
    case 'dropdown':
    case 'select': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              value={value?.[option.name]}
              select variant="outlined" fullWidth>
              {
                option?.options?.map(selectOption => (
                  <MenuItem disabled={option.unique && option.restricted.find(v => v === selectOption.value)} key={selectOption.value} value={selectOption.value}>{selectOption.title}</MenuItem>
                )) || null
              }
            </TextField>
          </div>
        </div>
      )
    }
    case 'color-select': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <ColoredSelect
              disabled={option?.disabled || disabled}
              options={option?.options}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              value={value?.[option.name]}
              onChange={v => { editTextFieldValue(v, option.name); onChange(value) }}
            />
          </div>
        </div>
      )
    }
    case 'yes-no': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              value={value?.[option.name]}
              select variant="outlined" fullWidth>
              <MenuItem value={true} >Yes</MenuItem>
              <MenuItem value={false} >No</MenuItem>
            </TextField>
          </div>
        </div>
      )
    }
    case 'yes-no-unknown': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              value={value?.[option.name]}
              select variant="outlined" fullWidth>
              <MenuItem value={'yes'} >Yes</MenuItem>
              <MenuItem value={'no'} >No</MenuItem>
              <MenuItem value={'unknown'} >Unknown</MenuItem>
            </TextField>
          </div>
        </div>
      )
    }
    case 'chips': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <TextField
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              SelectProps={{
                multiple: true
              }}
              helperText={errors?.[option.name]}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              value={value?.[option.name] || []}
              select variant="outlined" fullWidth>
              {
                option?.options?.map(selectOption => (
                  <MenuItem key={selectOption.value} value={selectOption.value}>{selectOption.title}</MenuItem>
                ))
              }
            </TextField>
          </div>
        </div>
      )
    }
    case 'country': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <CountrySelect
              disabled={option?.disabled || disabled}
              value={value?.[option.name]}
              bannedValues={option.bannedValues}
              onChange={v => { editTextFieldValue(v, option.name); onChange(value) }}
              options={option.options}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
            />
          </div>
        </div>
      )
    }
    case 'radio': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <RadioGroup
              disabled={option?.disabled || disabled}
              onChange={e => { editTextFieldValue(e.target.value, option.name); onChange(value) }}
              value={value?.[option.name]}
              aria-label="gender"
              name="radio-buttons-group"
            >

              {
                option?.options?.map(radioOption => (<FormControlLabel value={radioOption.value} control={<Radio />} label={radioOption.title} />))
              }

            </RadioGroup>
          </div>
        </div>

      )
    }
    case 'date': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                views={option?.views || ['date']}
                disabled={option?.disabled || disabled}
                error={errors?.[option.name]}
                helperText={errors?.[option.name]}
                onChange={v => { editTextFieldValue(v, option.name); onChange(value) }}
                value={value?.[option.name]}
                className="mt-0 mb-0"
                inputVariant="outlined"
                format={option.dateFormat || "DD-MMM-YYYY"}
                // maxDate={props.maxDate}
                // minDate={props.minDate}
                margin="normal"
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      )
    }
    case 'file': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9">
            <FileInput
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              onChange={file => { editTextFieldValue(file, option.name); onChange(value) }}
              value={value?.[option.name]} />
          </div>
        </div>
      )
    }
    case 'address': {
      return (
        <div className="form-group row" key={option.name}>
        <div className="col-lg-12">
          <AddressFormNext
            locationError={errors[option.name]}
            location={value[option.name]}
            qualifiers={value[option.qualifiers_name]}
            setLocation={(location) => editTextFieldValue(location, option.name)}
            setGooglePlaceId={(id) => editTextFieldValue(id, option.place_id_name)}
            setQualifiers={(qualifiers) => {editTextFieldValue(qualifiers, option.qualifiers_name)}}
            />
        </div>
      </div>
      )
    }
    case 'switch': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
          <div className="col-9 d-flex align-items-center justify-content-center">
            <Switch
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              variant="outlined"
              onChange={e => { editTextFieldValue(e.target.checked, option.name); onChange(value) }}
              checked={value?.[option.name]}
              fullWidth />
          </div>
        </div>
      )
    }
    case 'checkbox': {
      return (
        <div className="form-group row" key={option.name}>
          <label className="col-form-label col-3 d-flex align-items-center justify-content-end">{option.title}</label>
          <div className="col-9 d-flex align-items-center">
            <Checkbox
              disabled={option?.disabled || disabled}
              error={errors?.[option.name]}
              helperText={errors?.[option.name]}
              variant="outlined"
              onChange={e => { editTextFieldValue(e.target.checked, option.name); onChange(value) }}
              checked={value?.[option.name]}
              fullWidth />
          </div>
        </div>
      )
    }
    case 'open-switch': {
      return (
        <React.Fragment>
          <div className="form-group row" key={option.name}>
            <label className="col-form-label col-3 d-flex align-items-center">{option.title}</label>
            <div className="col-9 d-flex align-items-center justify-content-left">
              <Switch
                error={errors?.[option.name]}
                helperText={errors?.[option.name]}
                disabled={option.disabled ? option.disabled(value) : false}
                variant="outlined"
                onChange={e => { editTextFieldValue(e.target.checked, option.name); setOpenSwitch(e.target.checked); onChange(value) }}
                checked={value?.[option.name]}
                fullWidth />
            </div>
          </div>
          {
            openSwitch && option.options.map(option => {
              return (<BaseFieldsConfiguration {
                ...{
                  option,
                  editTextFieldValue,
                  errors,
                  value
                }
              } />)
            })
          }
        </React.Fragment>
      )
    }

    default: {
      return `@TODO ADD OPTION SPECIFICATION ${option.type}`
    }
  }
}