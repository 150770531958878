import React, { useEffect, useState } from 'react'
import MoreDetailsDropdown from "../../../components/Dropdown/MoreDetailsDropdown";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";
import "./style.css"
import { validateWithVisibleFields } from '../../../helpers/validation'
import * as Yup from "yup";
import { notify } from '../../../../redux/NotificationSnackbar/snackbarActions';
import ShareClassWindow from '../components/share-class-window';

const validationObject = {
  class_name: () => Yup.string()
    .required("Class name is required"),
  currency: () => Yup.string()
    .required("Currency is required"),
  class_type: () => Yup.string()
    .required("Class type is required"),
  authorized_shares: () => Yup.string()
    .required("Authorised shares are required"),
  value_per_share: () => Yup.string()
    .required("Value per share is required").min(0, 'Value per share must be greater than 0'),
  issued_on: () => Yup.date()
    .required("Issued on is required")
    .test('test_issue_date', 'Cannot be in future!', (value) => {
      return value <= Date.now()
    }),
  voting_rights: () => Yup.string()
    .required("Voting rights are required").min(0, 'Voting rights must be greater than 0'),
  restrictions: (context) => Yup.string()
    .test('restrictions', 'Required field!', (value) => {
      if (context.has_restrictions && !value) return false
      return true
    }),

}

export default function CreateCompanyPage3(
  {
    setCurrentPage,
    isPartnership,
    loading,
    goBack,
    abstractions = [],
    setAbstractions = () => { },
    ownershipTypes = [],
    visibleFields = {},
    currency = {}
  }
) {

  const [modalOpened, setModalOpened] = useState(false)
  const [editing, setEditing] = useState(-1)
  const [shareErrors, setShareErrors] = useState({})

  const defaultAbstraction = {
    class_type: '',
    class_name: '',
    authorized_shares: '',
    value_per_share: '',
    currency: '',
    issued_on: null,
    voting_rights: '',
    rights_to_dividents: false,
    rights_to_capital_on_winding_up: false,
    certificates_required: false,
    director_approval_required: false,
    has_restrictions: false,
    restrictions: '',
    equity_value: ''
  }
  const [current, setCurrent] = useState(defaultAbstraction)

  useEffect(() => {
    setCurrent(old => {
      old.equity_value =
        old.authorized_shares * old.value_per_share
        + ' ' + current.currency;
      return { ...old };
    });
  }, [current.currency, current.value_per_share, current.authorized_shares]);

  const editField = (fieldName, value) => {
    setCurrent(old => {
      old[fieldName] = value
      return { ...old }
    })
  }




  function addAbstraction() {
    setModalOpened(true)
    setEditing(-1)
    setCurrent(defaultAbstraction)
  }

  async function saveAbstraction() {
    if (await validateWithVisibleFields(validationObject, visibleFields, current, setShareErrors)) {
      if (editing === -1) {
        setAbstractions(old => ([...old, { ...current }]))
      } else if (editing >= 0) {
        setAbstractions(old => {
          old[editing] = { ...current }
          return [...old]
        })
      }
      setModalOpened(false)
    }

  }

  function editAbstraction(index) {
    setModalOpened(true)
    setEditing(index)
    setCurrent(abstractions[index])
  }

  function deleteAbstraction(index) {
    setAbstractions(old => {
      return old.filter((_, i) => i !== index)
    })
  }

  const share_cols = [
    {
      id: 1,
      name: 'class_type',
      title: 'OWNERSHIP TYPE',
      width: 300,
      extraData: ownershipTypes,
      component: (row, _, extraData) => {
        const classType = extraData.find(item => item.value === row.class_type)
        return (
          <span>{classType?.title}</span>
        )
      }
    },
    {
      id: 2,
      name: 'class_name',
      title: 'NICKNAME',
      width: 300
    },
    {
      id: 3,
      name: 'value_per_share',
      title: 'EQUITY VALUE',
      width: 300,
      component: (row) => {
        return (
          <span>{row.equity_value}</span>
        )
      }
    },
    {
      id: 4,
      name: 'actions',
      component: (row, rowIndex) => {
        return (
          <MoreDetailsDropdown row={row} options={[
            {
              id: 1,
              title: 'Edit',
              onClick: () => {
                editAbstraction(rowIndex)
              }
            },
            {
              id: 2,
              title: 'Delete',
              onClick: () => {
                deleteAbstraction(rowIndex)
              }
            },
          ]} />
        )
      }
    }
  ]

  function onNextClick() {
    if (!abstractions.length) {
      notify({ type: "ERROR", title: "Error", message: "Add at least one type" })
      return
    }
    setCurrentPage(prev => prev + 1)
  }

  return (
    <>
      {/* Share modal */}
      <ShareClassWindow {...{
        modalOpened,
        visibleFields,
        shareErrors,
        editField,
        current,
        ownershipTypes,
        currency,
        setModalOpened,
        saveAbstraction
      }} />

      <div className="d-flex align-items-center justify-content-between border-0 pt-5">
        <div className="w-50 ml-0 align-items-start flex-column">
          <div style={{ fontSize: "1.5rem", color: "black" }} className="fw-bolder font-size-h2  mt-6 ml-2 m-0">Ownership Types</div>
          <div style={{ fontSize: "10px", color: "#8F96A1" }} className="fw-bolder ml-2 m-0">Create categories for how you plan to distribute equity in this entity</div>
        </div>
        <div>
          <button
            className="ml-2 submit-btn font-weight-bold px-9 py-4 my-3"
            onClick={addAbstraction}
          >
            Add New
          </button>
        </div>
      </div>

      <Table nonSelectable entities={abstractions} setEntities={() => void 0} cols={share_cols} />

      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="cancel-btn font-weight-bold px-9 py-4 my-3"
          onClick={goBack}
        >Back</button>
        <button
          onClick={onNextClick}
          className="submit-btn font-weight-bold px-9 py-4 my-3"
        >
          <span>
            Next
          </span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>

      </div>
    </>

  )
}
