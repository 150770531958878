import Axios from "axios";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSubheader } from "../../_metronic/layout";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Modal, Button } from "react-bootstrap";
import MoreDetailsDropdown from '../components/Dropdown/MoreDetailsDropdown';
import { notify } from '../../redux/NotificationSnackbar/snackbarActions';
import { MenuItem, TextField } from '@material-ui/core';

const STATUS_COLORS = {
  pending: "",
  rejected: "label-light-danger",
  accepted: "label-light-success"
};

export const InvitationsPage = () => {
  const subheader = useSubheader();
  subheader.setTitle("Invitations");

  const [invitations, setInvitations] = useState([]);
  const [invitationType, setInvitationType] = useState("csp.admin");
  const [clientCompanies, setClientCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedTargetCSP, setSelectedTargetCSP] = useState("current");
  const [inviteeEmail, setInviteeEmail] = useState('');

  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [changeEmailAddressTargetId, setChangeEmailAddressTargetId] = useState(
    ""
  );

  const [changeEmailModalShow, setChangeEmailModalShow] = useState(false);

  const handleChangeEmailModalClose = () => setChangeEmailModalShow(false);

  const copyLink = item => {
    if (!item.invitation_code) return;

    navigator.clipboard
      .writeText(`${window.location.origin}/invite/${item.invitation_code}`)
      .then(() => {
        /* clipboard successfully set */
        // notify({type: "SUCCESS", title: "Copied", message: "Link was successfully to clipboard"})
        // TODO show a toast
      });
  };

  const getUserCompanies = () => {
    Axios.get("csp/my-companies")
      .then(({ data }) => {
        // This request available only for CSPs
        // No need additional checks
        setClientCompanies([...data.user_companies]);
      })
      .catch(() => { });
  };

  const createNewInvitation = () => {
    const invitation_type_split = invitationType.split('.');
    const target_company_id = parseInt(selectedCompanyId, 10);

    Axios.post('invitation/create', {
      scope: invitation_type_split[0],
      role: invitation_type_split[1],
      target_company: target_company_id,
      target_csp: selectedTargetCSP,
      invitee_email: inviteeEmail
    })
      .then(({ data }) => {
        const new_invitation = data.new_invitation;

        // Append the data about the company the new invitation is referring to
        if (target_company_id) {
          new_invitation.customer_company = clientCompanies.find(company => company.company_id === target_company_id);
        }

        invitations.unshift(new_invitation);

        setInvitations(invitations);

        notify({ type: "SUCCESS", message: "Invitation has been created", title: "Success!" });
      })
      .catch((e) => {
        notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error!" })
      });
  };

  const deleteInvitation = invitation_id => {
    return Axios.delete(`invitation/${invitation_id}`)
      .then(() => {
        return getInvitations()
          .then(() => {
            notify({ type: "SUCCESS", message: "Invitation has been deleted", title: "Success!" })
          })
      })
      .catch((e) => {
        notify({ type: "ERROR", message: e?.response?.data?.error_message, title: "Error!" })
      });
  };

  const getInvitations = () => {
    return Axios.get("invitation")
      .then(({ data }) => {
        const new_invitations = data.invitations.map(item => ({
          ...item,
          status_class: STATUS_COLORS[item.invitation_status]
        }));

        setInvitations(new_invitations);
      })
      .catch(() => { });
  };

  const onInvitationTypeChange = e => {
    setInvitationType(e.target.value);
  };

  const showChangeEmailAddressModal = (invitation_id, invitation_email) => {
    setChangeEmailAddressTargetId(invitation_id);
    setChangeEmailModalShow(true);
    setNewEmailAddress(invitation_email);
  };

  const changeEmailAddress = () => {
    setChangeEmailModalShow(false);

    Axios.put(`invitation/${changeEmailAddressTargetId}/update`, {
      new_email_address: newEmailAddress
    })
      .then(() => {
        // TODO @performance query all invitations again, on each email address update? Not very good. Not very bad either, to be honest
        getInvitations();
      })
      .catch(() => { });
  };

  useEffect(() => {
    getInvitations();

    // TODO @performance Get only when the user selected "company user" or "company admin" as a new scope+role
    getUserCompanies();
  }, []);

  return (
    <div>
      <Modal show={changeEmailModalShow} onHide={handleChangeEmailModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change invitation email address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            An invitation will be sent to the new email address as soon as you
            set it.
          </p>
          <TextField
            variant="outlined"
            name="new_email_address"
            label="New email address"
            value={newEmailAddress}
            onChange={e => setNewEmailAddress(e.target.value)}
            className="w-100"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleChangeEmailModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={changeEmailAddress}>
            Change email address
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-lg-12">
        <div className="card card-custom  gutter-b">
          <div className="card-header border-0 py-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                New Invitation
              </div>
              <div className="text-muted mt-3 font-weight-bold font-size-sm">
                Create a new invitation
              </div>
            </div>
          </div>
          <div className="card-body pt-2">
            <div className="form-group fv-plugins-icon-container">
              <TextField
                fullWidth
                variant='outlined'
                select
                className={`form-control h-auto py-4 px-4 rounded-lg`}
                value={invitationType}
                onChange={onInvitationTypeChange}
                placeholder="Invitation type"
                name="invitation_type"
                autoComplete="off"
              >
                <MenuItem value="csp.admin">CSP admin</MenuItem>
                <MenuItem value="csp.user">CSP user</MenuItem>
                <MenuItem value="company.admin">Entity admin</MenuItem>
                <MenuItem value="company.user">Entity user</MenuItem>
              </TextField>
            </div>

            {
              (() => {
                switch (invitationType) {
                  case "company.admin":
                  case "company.user": {
                    return (<div className="form-group fv-plugins-icon-container d-flex">
                      <TextField
                        select
                        fullWidth
                        variant='outlined'
                        className={`form-control h-auto py-4 px-4 rounded-lg mr-2`}
                        value={selectedCompanyId}
                        onChange={e => setSelectedCompanyId(e.target.value)}
                        placeholder="Target company"
                        name="target_company"
                        autoComplete="off"
                      >
                        {clientCompanies.map(company => (
                          <MenuItem key={company.company_id} value={company.company_id}>
                            {company.company_name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        variant='outlined'
                        className={`form-control h-auto py-4 px-4 rounded-lg ml-2`}
                        value={inviteeEmail}
                        onChange={e => setInviteeEmail(e.target.value)}
                        type="email"
                        placeholder="Invitee email"
                        name="invitee_email"
                        autoComplete="off"
                      />
                    </div>)
                  }

                  case "csp.admin": {
                    return (<TextField
                      select
                      fullWidth
                      variant='outlined'
                      className={`form-control h-auto py-4 px-4 rounded-lg mb-6`}
                      value={selectedTargetCSP}
                      onChange={e => setSelectedTargetCSP(e.target.value)}
                      placeholder="Target CSP"
                      name="target_csp"
                      autoComplete="off"
                    >
                      <MenuItem value="current">Invite to a current CSP</MenuItem>
                      <MenuItem value="new">Invite to create a new CSP</MenuItem>
                    </TextField>)
                  }

                  default: return null;
                }
              })()
            }

            <button
              className="px-5 btn btn-primary font-weight-bold"
              onClick={createNewInvitation}
            >
              Create a new invitation
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 py-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Invitations
              </div>
              <div className="text-muted mt-3 font-weight-bold font-size-sm">
                Current invitations
              </div>
            </div>
          </div>

          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th className="p-0" />
                    <th className="p-0" />
                    <th className="p-0" />
                    <th className="p-0" />
                    <th className="p-0" />
                    <th className="p-0" />
                    <th className="p-0" />
                  </tr>
                </thead>
                <tbody>
                  {invitations.map(item => (
                    <tr key={item.invitation_id}>
                      <td className="pl-0">
                        <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg limit-text mw-120">
                          {item.invitee_name || "—"}
                        </span>
                        <div>
                          <span className="text-muted">Invitee name</span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg limit-text mw-150">
                          {item.invitation_email
                            || (item.invitation_code && item.invitation_code.substring(0, 16) + "…")
                            || "(Link hidden)"}
                        </span>
                        <div>
                          <span className="text-muted">
                            Invite by{" "}
                            {item.invitation_email ? "email address" : "link"}
                          </span>
                        </div>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {item.invitation_role_scope} {item.invitation_new_role}
                        </span>
                        <span className="text-muted font-weight-bold">
                          Invitation role
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg limit-text mw-120">
                          {item.customer_company
                            ? item.customer_company.company_name
                            : "—"}
                        </span>
                        <span className="text-muted font-weight-bold">
                          Company
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                          {new Date(item.created_at).toLocaleString()}
                        </span>
                        <div>
                          <span className="text-muted">Invited on</span>
                        </div>
                      </td>
                      <td className="text-right">
                        <span
                          className={`label label-lg label-inline text-capitalize ${item.status_class}`}
                        >
                          {item.invitation_status}
                        </span>
                      </td>
                      {item.invitation_status === "pending" && (
                        <td>
                          <MoreDetailsDropdown
                            className="p-4"
                            options={[
                              {
                                id: 1,
                                title: 'Copy Link',
                                onClick: () => copyLink(item)
                              },
                              {
                                id: 2,
                                title: 'Edit Invitation',
                                onClick: () => showChangeEmailAddressModal(item.invitation_id, item.invitation_email),
                                render: item.invitation_email
                              },
                              {
                                id: 3,
                                title: 'Delete invitation',
                                onClick: () => deleteInvitation(item.invitation_id)
                              },
                            ]}
                          >
                            <div className="btn btn-light ui-dropdown dropdown-toggle">
                              <span className="svg-icon svg-icon-md svg-icon-primary" title=" ">
                                <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
                              </span>
                            </div>
                          </MoreDetailsDropdown>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
