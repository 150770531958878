import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { saveRR, getRR } from '../../../api/company'
import { getEddStatusColored, getRiskRatingColored, getRiskRatingExtendedColored, getRRYesNoColored } from '../../../api/ui'
import BaseFieldsConfiguration from '../../../components/EditProfileForm/BaseFieldsConfiguration'
import useRequest from '../../../components/useRequest/useRequest'
import LeaveBlocker from '../../../components/_common/LeaveBlocker'
import SaveButton from '../../../components/_common/SaveButton'
import { simpleValidate } from '../../../helpers/validation'
import useColoredTitles from '../../../hooks/useColoredTitles'
import useUnsaved from '../../../hooks/useUnsaved'
import NotesSection from './components/notes'
import RRCriteriaSection from './components/rr-criteria'
import StakeholderRRSection from './components/stakeholder-rr'
import useRiskCriteria from './hooks/useCriteria'
import useNotes from './hooks/useNotes'
import useStakeholderRR from './hooks/useStakeholderRR'
import { summarySchema } from './validation/schemas'

export default function EntityRiskRatingsPage({ entity_id }) {
  const [coloredEDDStatuses] = useColoredTitles(getEddStatusColored)
  const [coloredRiskRatings] = useColoredTitles(getRiskRatingColored)
  const [coloredRiskRatingsExtended] = useColoredTitles(getRiskRatingExtendedColored)
  const [coloredYN] = useColoredTitles(getRRYesNoColored)

  const [riskRatingConfig, setRiskRatingConfig] = useState({})
  const [riskRatingErrors, setRiskRatingErrors] = useState({})

  function editRR(value, name) {
    setRiskRatingConfig(old => {
      old[name] = value
      return { ...old }
    })
  }

  const [completeReview, loadingCompleteReview] = useRequest(async () => {
    if (await simpleValidate(summarySchema, riskRatingConfig, setRiskRatingErrors)) {
      await saveRR(entity_id, riskRatingConfig)
      await fetchRR()
      await fetchComments()
      setUnsaved(false)
      setUnsavedNotes(false)
    }
  })

  const [fetchRR, __, ended] = useRequest(() => getRR(entity_id), data => data && setRiskRatingConfig(data))

  const [unsaved, setUnsaved] = useUnsaved({ ended }, [riskRatingConfig])

  useEffect(() => {
    fetchRR()
  }, [])

  const {
    stakeholders,
    editStakeholderRR,
    stakeholderRiskOptions,
    saveStakeholderDecision,
    stakeholderDecisionLoading,
    stakeholdersTableCols,
    stakeHoldersRR,
    unsavedRR
  } = useStakeholderRR({
    entity_id,
    coloredRiskRatings
  })

  const {
    criteriaErrors,
    submitCriteria,
    loadingCriteria,
    editCriteria,
    riskCriteriaOptions,
    criteria,
    criteriaUnsaved
  } = useRiskCriteria({
    coloredRiskRatings,
    coloredRiskRatingsExtended,
    coloredYN,
    entity_id
  })

  const { comment, setComment, comments, saveComment, loadingSaveComment, fetchComments, unsavedNotes, setUnsavedNotes } = useNotes({ entity_id })

  const summaryOptions = [
    {
      name: 'edd_status',
      title: 'Due Diligence Status',
      type: 'color-select',
      options: coloredEDDStatuses,
      onChange: (context) => {
        setRiskRatingConfig(old => ({ ...old, date_last_reviewed: moment(new Date()) }))
      }
    },
    {
      name: 'summary_risk_rating',
      title: 'Summary Risk Rating',
      type: 'color-select',
      options: coloredRiskRatings
    },
    {
      visible: (context) => {
        return context['summary_risk_rating'] === 'high'
      },
      name: 'risk_rating_rationale',
      title: 'Risk Rating Rationale',
      type: 'multiline-text'
    },
    {
      name: 'date_last_reviewed',
      title: 'Date Last Reviewed',
      type: 'date'
    },
    {
      name: 'next_review_date',
      title: 'Next Review Date',
      type: 'date'
    }
  ]
  
  return (
    <>
      <LeaveBlocker unsavedExists={unsaved || unsavedNotes || unsavedRR || criteriaUnsaved} />
      <div className="col-lg-12">

        <div className="row">
          <div className="col-lg-12">
            <div className="card card-custom gutter-b">
              <div className="card-header">
                <div className="card-title w-50">
                  Risk Rating Summary
                </div>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-7">
                    {
                      summaryOptions.map(option =>
                      (<BaseFieldsConfiguration
                        option={option}
                        editTextFieldValue={editRR}
                        errors={riskRatingErrors}
                        value={riskRatingConfig}
                      />))
                    }
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>

              <div className="card-footer">
                <div className="row d-flex justify-content-end">
                  <SaveButton onClick={() => completeReview()} loading={loadingCompleteReview} Icon={null} title='Complete review' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <RRCriteriaSection {
          ... {
            riskCriteriaOptions,
            editCriteria,
            criteriaErrors,
            criteria,
            loadingCriteria,
            submitCriteria
          }
        } />

        <StakeholderRRSection {
          ...{
            stakeholderRiskOptions,
            editStakeholderRR,
            stakeHoldersRR,
            stakeholders,
            stakeholdersTableCols,
            stakeholderDecisionLoading,
            saveStakeholderDecision
          }
        } />

        <NotesSection entity_id={entity_id} {...{
          comment,
          setComment,
          comments,
          saveComment,
          loadingSaveComment
        }} />

      </div>
    </>
  )
}