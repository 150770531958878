import { TextField, MenuItem } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getCSPById, getJurisdictionRegions, updateCSP } from '../../api/csp'
import 'react-phone-input-2/lib/material.css'
import { useSelector } from 'react-redux'
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar'
import { notify } from '../../../redux/NotificationSnackbar/snackbarActions'
import * as Yup from 'yup';
import { useRef } from 'react'
import Phone from '../../components/_common/Phone'
import SaveButton from '../../components/_common/SaveButton'
import useRequest from '../../components/useRequest/useRequest'
import LeaveBlocker from '../../components/_common/LeaveBlocker'
import useUnsaved from '../../hooks/useUnsaved'

export default function GeneralCSPProfileSettings() {
  const [jurisdictionRegions, setJurisdictionRegions] = useState([])
  const [editCSP, setEditCSP] = useState({
    tenant: {
      tenant_slug: ''
    },
    csp_name: '',
    csp_jurisdiction_region: '',
    csp_license_number: '',
    csp_registration_number: '',
    csp_regulator_name: '',
    csp_website: '',
    csp_logo: '',
    csp_phone_number: ''
  })
  const cspId = useSelector(state => state.tenant.csp?.csp_id)

  const validationObject = {
    csp_name: Yup.string()
      .matches(/^[\w&\. -]{2,60}$/g)
      .required('CSP company name is required'),
    csp_registration_number: Yup.string()
      .required('Registration number is required'),
    csp_license_number: Yup.string()
      .min(5, 'Minimum 5 characters')
      .max(100, 'Maximum 100 characters')
      .required('License number is required'),
    csp_jurisdiction_region: Yup.number()
      .min(0, 'Please, choose a jurisdiction region')
      .required('Jurisdiction region is required'),
    csp_website: Yup.string().nullable()
      .test(
        {
          message: 'Enter valid url',
          test: (v) => /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(v)
        })
  }

  const [errors, setErrors] = useState({
    csp_name: '',
    csp_registration_number: '',
    csp_license_number: '',
    csp_jurisdiction_region: '',
    csp_website: ''
  })

  const [ended, setEnded] = useState(false)

  const [unsaved, setUnsaved] = useUnsaved({ended}, [editCSP])

  useEffect(() => {
    if (cspId)
      getCSPById(cspId).then(data => {
        setEditCSP(data.csp)
        setEnded(true)
      })
    getJurisdictionRegions().then(data => setJurisdictionRegions(data.jurisdiction_regions))

  }, [cspId])

  const validate = async () => {
    const newErrors = {
      csp_name: '',
      csp_registration_number: '',
      csp_license_number: '',
      csp_jurisdiction_region: '',
      csp_website: ''
    }
    let errCount = 0;
    for (const key in validationObject) {
      try {
        await validationObject[key].validate(editCSP[key])
      } catch (error) {
        newErrors[key] = error.message
        errCount++;
      }
    }

    setErrors({ ...newErrors })

    return !errCount;
  }

  const [onFormSubmit, loading] = useRequest(async (e) => {
    e.preventDefault();

    if (await validate()) {
      await updateCSP(editCSP)

      setUnsaved(false)

      notify({ type: "SUCCESS", message: 'CSP Updated', title: 'Success' })

      window.scrollTo({
        top: 0
      })
    } else {
      notify({ type: "ERROR", message: 'Some fields have invalid values', title: 'Validation errors' })
    }
  })

  const onChangeFieldValue = (value, name) => {
    setEditCSP(oldCSP => {
      return {
        ...oldCSP,
        [name]: value
      }
    })
  }

  return (
    <>
    <LeaveBlocker unsavedExists={unsaved} />
      <div className="card-header">
        <div className="card-title w-50 ml-0">
          <div style={{ fontSize: '1.5rem' }} className="fw-bolder m-0">
            CSP Profile Settings
          </div>
          <div className="text-muted mt-3 font-weight-bold font-size-sm"></div>
        </div>
      </div>
      <div className="card-body">
        <form className="form" id="kt_form" onSubmit={onFormSubmit} _lpchecked={1}>
          <div className="tab-content">
            {/*begin::Tab*/}
            <div className="tab-pane show px-7 active" id="kt_user_edit_tab_1" role="tabpanel">
              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-2" />
                <div className="col-xl-7 my-2">
                  {/*begin::Row*/}
                  {/*end::Row*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 text-lg-right text-left">CSP Logo</label>
                    <div className="col-9">
                      <ProfileAvatar value={editCSP.csp_logo} onChange={(url) => onChangeFieldValue(url, 'csp_logo')} />
                    </div>
                  </div>
                  {/*end::Group*/}

                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">CSP Slug</label>
                    <div className="col-9">
                      <TextField disabled variant="outlined" fullWidth value={editCSP.tenant.tenant_slug} />
                    </div>
                  </div>
                  {/*end::Group*/}


                  {/*begin::Group*/}
                  <div className="form-group row">
                    <hr className="w-100" />
                  </div>
                  {/*end::Group*/}

                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">CSP Name</label>
                    <div className="col-9">
                      <TextField
                        error={errors.csp_name}
                        helperText={errors.csp_name}
                        onChange={e => onChangeFieldValue(e.target.value, 'csp_name')} variant="outlined" fullWidth value={editCSP.csp_name}
                      />
                    </div>
                  </div>
                  {/*end::Group*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Jurisdiction</label>
                    <div className="col-9">
                      <TextField
                        error={errors.csp_jurisdiction_region}
                        helperText={errors.csp_jurisdiction_region}
                        onChange={e => onChangeFieldValue(e.target.value, 'csp_jurisdiction_region')} select variant="outlined" fullWidth value={editCSP.csp_jurisdiction_region}>
                        {
                          jurisdictionRegions.map(region => (
                            <MenuItem key={region.region_id} value={region.region_id}>{region.region_title}</MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  </div>
                  {/*end::Group*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Regulator Name</label>
                    <div className="col-9">
                      <TextField onChange={e => onChangeFieldValue(e.target.value, 'csp_regulator_name')} select variant="outlined" fullWidth value={editCSP.csp_regulator_name}>
                        <MenuItem value={'cima'}>{'CIMA'}</MenuItem>
                      </TextField>
                    </div>
                  </div>
                  {/*end::Group*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Regulator License Number</label>
                    <div className="col-9">
                      <TextField
                        error={errors.csp_license_number}
                        helperText={errors.csp_license_number}
                        onChange={e => onChangeFieldValue(e.target.value, 'csp_license_number')} variant="outlined" fullWidth value={editCSP.csp_license_number} />
                    </div>
                  </div>
                  {/*end::Group*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Registration Number</label>
                    <div className="col-9">
                      <TextField
                        error={errors.csp_registration_number}
                        helperText={errors.csp_registration_number}
                        onChange={e => onChangeFieldValue(e.target.value, 'csp_registration_number')} variant="outlined" fullWidth value={editCSP.csp_registration_number} />
                    </div>
                  </div>
                  {/*end::Group*/}

                  {/*begin::Group*/}
                  <div className="form-group row">
                    <hr className="w-100" />
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Phone</label>
                    <div className="col-9">
                      {/* <TextField variant="outlined" fullWidth /> */}
                      <Phone
                        onChange={value => onChangeFieldValue(value, 'csp_phone_number')}
                        value={editCSP.csp_phone_number}
                        specialLabel=''
                        inputClass="w-100 h-50"
                      />
                    </div>
                  </div>
                  {/*end::Group*/}
                  {/*begin::Group*/}
                  <div className="form-group row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-end">Website</label>
                    <div className="col-9">
                      <TextField
                        error={errors.csp_website}
                        helperText={errors.csp_website}
                        onChange={e => onChangeFieldValue(e.target.value, 'csp_website')} value={editCSP.csp_website} variant="outlined" fullWidth />
                    </div>
                  </div>
                  {/*end::Group*/}

                </div>
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Tab*/}
          </div>

          <div className="card-footer d-flex justify-content-end">
            <SaveButton loading={loading} type="submit">Save</SaveButton >
          </div>
        </form>


      </div>
    </>
  )
}