import React from 'react';
import SVG from 'react-inlinesvg';
import { Dropdown } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import {DropdownCustomToggler} from '../../../_metronic/_partials/dropdowns';
import { Link } from "react-router-dom";
import { DropdownMenu} from './DropdownMenu';
import Axios from 'axios';
import {CANNY_FEEDBACK_CENTER} from '../../../config';


export function QuickLinks({ className }) {

  const getToken = async (e) => {
    e.preventDefault();

    Axios.post('/auth/feedback-center-login')
      .then(response => {
        if(response.data.token) {
          localStorage.setItem('cannyToken', response.data.token);
          // TODO
          window.open(CANNY_FEEDBACK_CENTER, "_blank")
        }
      })
  }

  return (
    <div className={`card card-custom bg-gray-100 rounded-lg ${className}`} style={{height: '100%'}}>
      {/* Header */}
      <div className="card-header border-0 bg-success py-5">
        <h3 className="card-title font-weight-bolder text-white">Quick Links</h3>
        <div className="card-toolbar">
          <ItemDropdown item="" />
        </div>
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        <div className="card-rounded-bottom bg-success" style={{ height: 160 }} />

        {/* Stat */}
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Urgent-mail.svg')} />
              </span>
              <Link to="#" className="text-success font-weight-bold font-size-h6">
                My Inbox
              </Link>
            </div>
            <div className="col bg-light-warning px-6 py-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Add-Entity.svg')} />
              </span>
              <Link to="/entity-formation" className="text-warning font-weight-bold font-size-h6 mt-2">
                Add an Entity
              </Link>
            </div>
          </div>
          <div className="row m-0">
            <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Question-circle.svg')} />
              </span>
              <Link to="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                Help Center
              </Link>
            </div>
            <div className="col bg-light-primary px-6 py-8 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Like.svg')} />
              </span>
              <a href="#"  target="_blank" className="text-primary font-weight-bold font-size-h6 mt-2"  onClick={e => {getToken(e)}}>
                Feedback
              </a>
            </div>
          </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: '411px', height: '461px' }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = '#D13647';

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: 'area',
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return '$' + val + ' thousands';
        }
      },
      marker: {
        show: false
      }
    },
    colors: ['transparent'],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
}

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-sm btn-icon bg-hover-light"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-hor text-white" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
