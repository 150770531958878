/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

import { Modal, Button } from 'react-bootstrap';
import { FormInput, FormSelect } from './Basic';

function ConfirmationModal(props) {
  const onConfirm = () => {
    props.onHide();
    props.onConfirm();
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Restrictions confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>This share class has restrictions, displayed below. Do you confirm you have read, understood and agree to them?</div>
        <div className="mt-2 font-italic">{props.restrictions_text}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function SharesTransactionModal(props) {
  const [transactionDetails, setTransactionDetails] = useState({});
  const [shareClasses, setShareClasses] = useState([]);
  const [capTable, setCapTable] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [selectedShareClass, setSelectedShareClass] = useState();

  const [selectedSender, setSelectedSender] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState();

  const [actionConfig, setActionConfig] = useState({});

  const [confirmationModalShown, setConfirmationModalShown] = useState(false);

  const [availableFromApprovers, setAvailableFromApprovers] = useState([]);
  const [availableToApprovers, setAvailableToApprovers] = useState([]);

  const [selectedFromApprover, setSelectedFromApprover] = useState();
  const [selectedToApprover, setSelectedToApprover] = useState();

  const getCompanyAdmins = company_id => {
    return new Promise(resolve => {
      Axios.get(`company/users/${company_id}`)
        .then(({ data }) => {
          resolve(data.users.filter(user => user.user_role === 'company.admin'));
        })
        .catch(() => {});
    });
  };

  const onDetailsChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    // TODO move these to some other onDetailsChange function for select type inputs
    if (e.target.name === 'selected_shares') {
      // User has updated the selected share class. Update info on the screen about the selected class
      const target_share_class = shareClasses.find(share => share.share_id + '' === e.target.value);
      setSelectedShareClass(target_share_class);
    } else if (e.target.name === 'giver') {
      // User has updated the selected sender.
      const selected_sender = shareholders.find(shareholder => shareholder.shareholder_id + '' === e.target.value);

      if (selected_sender && selected_sender.type === 'real_company') {
        getCompanyAdmins(selected_sender.company.company_id).then(available_approvers => {
          setAvailableFromApprovers(available_approvers);
        });
      }

      setSelectedSender(selected_sender);
    } else if (e.target.name === 'recipient') {
      // User has updated the selected recipient.
      const selected_recipient = shareholders.find(shareholder => shareholder.shareholder_id + '' === e.target.value);

      if (selected_recipient && selected_recipient.type === 'real_company') {
        getCompanyAdmins(selected_recipient.company.company_id).then(available_approvers => {
          setAvailableToApprovers(available_approvers);
        });
      }

      setSelectedRecipient(selected_recipient);
    }

    // Trick with the spread operator to force the component to re-render
    // TODO @performance this can potentially be SLOW, will have to refactor
    setTransactionDetails({ ...transactionDetails, [e.target.name]: value });
  };

  const getShareholders = () => {
    Axios.get(`company/${props.entity_id}/shareholders`)
      .then(({ data }) => {
        setShareholders(data.shareholders);
      })
      .catch(() => {});
  };

  const getShares = () => {
    Axios.get(`company/${props.entity_id}/share-classes`)
      .then(({ data }) => {
        setShareClasses(data.share_classes);
        setCapTable(data.cap_table);
      })
      .catch(() => {});
  };

  const onSubmit = skip_confirmation => {
    if (!skip_confirmation && selectedShareClass.share_has_restrictions) {
      setConfirmationModalShown(true);

      return;
    }

    const base_url = `company/${props.entity_id}/share-class/${selectedShareClass.share_id}`;

    const approvers = {
      from_approver_user_id: selectedFromApprover,
      to_approver_user_id: selectedToApprover
    };

    if (props.type === 'issue') {
      Axios.post(`${base_url}/issue`, {
        number_of_shares: transactionDetails.shares_to_transfer,
        issue_date: transactionDetails.transfer_date,
        recipient: transactionDetails.recipient,
        ...approvers
      })
        .then(() => {
          if (props.onSave) props.onSave();
        })
        .catch(() => {});
    } else if (props.type === 'buyback') {
      Axios.post(`${base_url}/buyback`, {
        number_of_shares: transactionDetails.shares_to_transfer,
        buyback_date: transactionDetails.transfer_date,
        user: transactionDetails.giver,
        ...approvers
      })
        .then(() => {
          if (props.onSave) props.onSave();
        })
        .catch(() => {});
    } else if (props.type === 'transfer') {
      Axios.post(`${base_url}/transfer`, {
        number_of_shares: transactionDetails.shares_to_transfer,
        transfer_date: transactionDetails.transfer_date,
        recipient: transactionDetails.recipient,
        sender: transactionDetails.giver,
        ...approvers
      })
        .then(() => {
          if (props.onSave) props.onSave();
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    // Get the shares from the API or from the props (if provided by the parent)
    if (props.shares) {
      setShareClasses(props.shares);
      setCapTable(props.cap_table);
    } else if (shareClasses.length === 0) {
      getShares();
      console.debug('Getting the shares from the api');
    }

    // Update the action config
    let action_config;

    switch (props.type) {
      case 'transfer':
        {
          action_config = {
            title: 'Transfer Shares',
            description: "You are about to transfer entity's shares between holders.",
            transfer_date_text: 'Transfer date'
          };
        }
        break;

      case 'issue':
        {
          action_config = {
            title: 'Issue Shares',
            description: 'You are about to issue shares to an entity user.',
            transfer_date_text: 'Issue date'
          };
        }
        break;

      case 'buyback':
        {
          action_config = {
            title: 'Buyback Shares',
            description: 'You are about to buyback shares from a shareholder.',
            transfer_date_text: 'Transfer date'
          };
        }
        break;

      default:
        {
          action_config = {};
        }
        break;
    }

    setActionConfig(action_config);

    // Update the selected share class coming from the outside
    if (props.selected_share && (!selectedShareClass || selectedShareClass.share_id !== props.selected_share.share_id)) {
      setSelectedShareClass(props.selected_share);
      setTransactionDetails({ ...transactionDetails, selected_shares: props.selected_share.share_id });
    }
  }, [props.shares, props.type, props.selected_share]);

  // Reset on type change
  useEffect(() => {
    setSelectedSender();
    // setTransactionDetails({ ...transactionDetails, giver: undefined })
  }, [props.type]);

  // Get the company users on mount / every time a client opens a modal window
  // TODO @performance there are better ways to achieve this
  useEffect(() => {
    if(shareholders.length === 0 || props.show) {
      getShareholders();
    }
  }, [props.show]);

  const form_ok =
    selectedShareClass && // Check if user has selected some share class
    selectedShareClass.share_status !== 'pending' && // Check if selected share class' status is active
    (props.type === 'transfer' ? transactionDetails.giver && transactionDetails.giver >= 0 : true); // check if giver is ok

  return (
    <>
      <ConfirmationModal
        show={confirmationModalShown}
        onHide={() => setConfirmationModalShown(false)}
        onConfirm={() => onSubmit(true)}
        restrictions_text={selectedShareClass && selectedShareClass.share_restrictions}
      />
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{actionConfig.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{actionConfig.description}</div>

          <FormSelect
            w-title="Selected shares"
            type="number"
            name="selected_shares"
            onChange={onDetailsChange}
            value={transactionDetails.selected_shares}
          >
            <option value="-1">Select a share class</option>
            {shareClasses.map(share_class => (
              <option value={share_class.share_id}>{share_class.share_name}</option>
            ))}
          </FormSelect>

          {selectedShareClass && (
            <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
              <div className="container-title">Share class</div>

              <div className="pair">
                <div>Share class name</div>
                <div>{selectedShareClass.share_name}</div>
              </div>
              <div className="pair">
                <div>Date issued</div>
                <div>{new Date(selectedShareClass.share_issued_on).toLocaleDateString()}</div>
              </div>
              <div className="pair">
                <div>Voting rights per share</div>
                <div>{selectedShareClass.share_voting_rights}</div>
              </div>
              <div className="pair">
                <div>Unissued shares</div>
                <div>{selectedShareClass.share_authorized_shares - selectedShareClass.share_outstanding_shares}</div>
              </div>
              <div className="pair">
                <div>Director approval required</div>
                <div>{selectedShareClass.share_director_approval_required ? 'Yes' : 'No'}</div>
              </div>
              <div className="pair">
                <div>Status</div>
                <div className={`${selectedShareClass.share_status === 'pending' ? 'text-danger' : 'text-success'}`}>
                  {selectedShareClass.share_status}
                </div>
              </div>

              {selectedShareClass.share_has_restrictions && (
                <>
                  <div className="text-danger mt-2" style={{ fontWeight: '500' }}>
                    Share class restrictions:
                  </div>
                  <div>{selectedShareClass.share_restrictions}</div>
                </>
              )}
            </div>
          )}

          {!selectedShareClass ? (
            <div className="mt-4 mb-0 alert alert-custom alert-light-primary alert-dismissible">
              <div className="alert-text font-weight-bold">Please, select a share class.</div>
            </div>
          ) : (
            <>
              {selectedShareClass.share_status === 'pending' && (
                <div className="mt-4 mb-0 alert alert-custom alert-light-warning alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    You can not make actions upon share classes with `pending` status. Please, wait until this share class is
                    approved by your CSP.
                  </div>
                </div>
              )}
            </>
          )}

          {(props.type === 'transfer' || props.type === 'buyback') && (
            <>
              <FormSelect w-title="Giver" type="number" name="giver" onChange={onDetailsChange} value={transactionDetails.giver}>
                <option value="-1">Select a shareholder</option>
                {shareholders.map(shareholder => (
                  <option value={shareholder.shareholder_id}>
                    {shareholder.type === 'human' && shareholder.human.user_full_name}
                    {shareholder.type === 'pseudo_company' && shareholder.pc.pc_name}
                    {shareholder.type === 'real_company' && shareholder.company.company_name}({shareholder.hint})
                  </option>
                ))}
              </FormSelect>

              {(!transactionDetails.giver || transactionDetails.giver < 0) && (
                <div className="mt-4 mb-0 alert alert-custom alert-light-primary alert-dismissible">
                  <div className="alert-text font-weight-bold">Please, select a giver.</div>
                </div>
              )}

              {selectedSender && selectedSender.company && (
                <>
                  <FormSelect
                    w-title="Approver"
                    type="number"
                    name="from_approver"
                    onChange={e => setSelectedFromApprover(e.target.value)}
                    value={selectedFromApprover}
                  >
                    <option value="-1">Select a approver</option>
                    {availableFromApprovers &&
                      availableFromApprovers.map(approver => <option value={approver.user_id}>{approver.user_full_name}</option>)}
                  </FormSelect>
                </>
              )}
            </>
          )}

          {selectedSender && (
            <div className="card px-5 py-4 mt-4 ui-keyvalue-container">
              <div className="container-title">Giver</div>

              {selectedSender.human && (
                <div className="pair">
                  <div>Full name</div>
                  <div>{selectedSender.human.user_full_name}</div>
                </div>
              )}
              {selectedSender.pc && (
                <div className="pair">
                  <div>Unmanaged entity name</div>
                  <div>{selectedSender.pc.pc_name}</div>
                </div>
              )}
              {selectedSender.company && (
                <div className="pair">
                  <div>Entity name</div>
                  <div>{selectedSender.company.company_name}</div>
                </div>
              )}

              <div className="pair">
                <div>Number of shares held</div>
                <div className="text-info">
                  {capTable
                    ? (
                        capTable.find(
                          row =>
                            row.shareholder_id === selectedSender.shareholder_id && row.share_id === selectedShareClass.share_id
                        ) || {}
                      ).shares_held
                    : '...'}
                </div>
              </div>
            </div>
          )}

          {form_ok && (
            <>
              <FormInput
                w-title
                placeholder={`Number of shares to ${props.type}`}
                type="number"
                name="shares_to_transfer"
                onChange={onDetailsChange}
                value={transactionDetails.shares_to_transfer}
              />

              <FormInput
                w-title
                placeholder={actionConfig.transfer_date_text}
                type="datetime-local"
                name="transfer_date"
                onChange={onDetailsChange}
                value={transactionDetails.transfer_date}
              />

              {props.type !== 'buyback' && (
                <>
                  <FormSelect
                    w-title="Recipient"
                    type="number"
                    name="recipient"
                    onChange={onDetailsChange}
                    value={transactionDetails.recipient}
                  >
                    <option value="-1">Select a shareholder</option>
                    {shareholders.map(shareholder => (
                      <option value={`${shareholder.shareholder_id}`}>
                        {shareholder.type === 'human' && shareholder.human.user_full_name}
                        {shareholder.type === 'pseudo_company' && shareholder.pc.pc_name}
                        {shareholder.type === 'real_company' && shareholder.company.company_name}({shareholder.hint})
                      </option>
                    ))}
                  </FormSelect>

                  {selectedRecipient && selectedRecipient.company && (
                    <>
                      <FormSelect
                        w-title="Approver"
                        type="number"
                        name="to_approver"
                        onChange={e => setSelectedToApprover(e.target.value)}
                        value={selectedToApprover}
                      >
                        <option value="-1">Select a approver</option>
                        {availableToApprovers &&
                          availableToApprovers.map(approver => (
                            <option value={approver.user_id}>{approver.user_full_name}</option>
                          ))}
                      </FormSelect>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide(false)}>
            Close
          </Button>
          <Button variant="primary" disabled={!form_ok} onClick={() => onSubmit(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SharesTransactionModal;
