export const ENTITY_TYPE_STRINGS = {
    limited_liability_company: "Limited Liability Company",
    ordinary_resident_company: "Ordinary Resident Company",
  
    limited_partnership: "Limited Partnership",
    limited_liability_partnership: "Limited Liability Partnership",
    exempted_limited_partnership: "Exempted Limited Partnership",
    foreign_limited_partnership: "Foreign Limited Partnership"
}

export const FILE_EXTENSION_FROM_MIMETYPE = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/pdf": "pdf",
    "image/jpeg": "jpg",
    "image/png": "png",
    "text/csv": "csv",
    "application/msword": "doc",
    "application/octet-stream": "bin",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-excel": "csv",
    "application/csv": "csv",
    "application/vnd.oasis.opendocument.text": "odt",
    "application/vnd.oasis.opendocument.spreadsheet": "ods"
}

export const BROWSER_PREVIEWED_MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
];

export const CSP_AWAITING_STATUS = {
    TENANT_CREATION_STARTED : 'TENANT_CREATION_STARTED',
    TENANT_CREATION_FINISHED : 'TENANT_CREATION_FINISHED',
    TENANT_CREATION_ERROR: 'TENANT_CREATION_ERROR'
}

export const USER_ROLE = {
    GLOBAL_ADMIN: 'global_admin',
    CSP_ADMIN: 'csp.admin',
    CSP_USER: 'csp.user',
    COMPANY_USER: 'company.user',
    COMPANY_ADMIN: 'company.admin',
    COMPANY_SHAREHOLDER: 'company.shareholder'
}

export const documentCategory = {
    GENERATED_DOCUMENT : "generated_document",
    UPLOADED_DOCUMENT : "uploaded_document",
    TEMPLATE : "template",

    HDD_ID : "hdd_id",
    HDD_PROOF_OF_RESIDENCE : "hdd_por",

    EDD_PROOF_OF_RESIDENCE : "edd_por",
    EDD_CERTIFICATE_OF_INCORPORATION : "edd_coi",
    EDD_CERTIFICATE_OF_GOOD_STANDING : "edd_cogs",
    EDD_CONSTITUTIONAL_DOCUMENTS : "edd_cd",
    EDD_STATUATORY_EVIDENCE : "edd_se",
    EDD_REGISTER_OF_OWNERS : "edd_roo",
    EDD_REGISTRY_OF_CONTROLLING_PERSONS : "edd_rcp",
}

export const documentStatus = {
    UNREVIEWED : "unreviewed",
    APPROVED : "approved",
    REJECTED : "rejected"
}

export const userDocumentStatus = {
    PENDING: "Pending",
    VERIFIED: "Verified",
    EXPIRED: "Expired",
}

export const notificationStatus = {
    new: 'NEW',
    reviewed: 'REVIEWED',
    completed: 'COMPLETED'
}