import React from 'react'
import BaseFieldsConfiguration from '../../components/EditProfileForm/BaseFieldsConfiguration'
import { useTable } from '../../hooks/useTable'
import { eddTableSchema } from './api/validation-schema'

function RevenuesTable({ percents, countries, abstractions, setAbstractions, disabled }) {
  const defaultValue = {
    'jurisdiction': '',
    'percent': ''
  }

  const options = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction',
      type: 'country',
      options: countries
    },
    {
      name: 'percent',
      title: 'Percent of annual revenues',
      type: 'select',
      options: percents
    },
  ]
  const cols = [{
    name: 'jurisdiction',
    title: 'Jurisdiction',
    extraData: countries,
    component: (row, _, extraData) => extraData.find(o => o.value === row.jurisdiction)?.title
  },
  {
    name: 'percent',
    title: 'Percent of annual revenues',
    extraData: percents,
    component: (row, _, extraData) => extraData.find(o => o.value === row.percent)?.title
  }]

  const [table] = useTable({
    options,
    cols,
    abstractions,
    setAbstractions,
    defaultValue,
    modalTitle: 'Revenue',
    cardTitle: 'Revenues',
    limit: 3,
    schema: eddTableSchema,
    disabled
  })

  return table;
}

function ExpensesTable({ percents, countries, expenses, setExpenses, disabled }) {

  const defaultValue = {
    'jurisdiction': '',
    'percent': ''
  }

  const options = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction (top 3)',
      type: 'country',
      options: countries,
    },
    {
      name: 'percent',
      title: 'Percent of annual expenses',
      type: 'select',
      options: percents
    },
  ]

  const cols = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction (top 3)',
      extraData: countries,
      component: (row, _, extraData) => extraData.find(o => o.value === row.jurisdiction)?.title
    },
    {
      name: 'percent',
      title: 'Percent of annual expenses',
      extraData: percents,
      component: (row, _, extraData) => extraData.find(o => o.value === row.percent)?.title
    },
  ]

  const [table] = useTable({
    options,
    cols,
    abstractions: expenses,
    setAbstractions: setExpenses,
    defaultValue,
    cardTitle: 'Expenses',
    modalTitle: 'Expense',
    limit: 3,
    schema: eddTableSchema,
    disabled
  })

  return table
}

function ConductingRevenues({ percents, countries, revenues, setRevenues, disabled }) {

  const defaultValue = {
    'jurisdiction': '',
    'percent': ''
  }

  const options = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction (top 3)',
      type: 'country',
      options: countries
    },
    {
      name: 'percent',
      title: 'What percent of annual revenues?',
      type: 'select',
      options: percents
    },
  ]

  const cols = [
    {
      name: 'jurisdiction',
      title: 'Jurisdiction (top 3)',
      extraData: countries,
      component: (row, _, extraData) => extraData.find(o => o.value === row.jurisdiction)?.title
    },
    {
      name: 'percent',
      title: 'What percent of annual revenues?',
      extraData: percents,
      component: (row, _, extraData) => extraData.find(o => o.value === row.percent)?.title
    },
  ]

  const [table] = useTable({
    options,
    defaultValue,
    cols,
    abstractions: revenues,
    setAbstractions: setRevenues,
    modalTitle: 'Revenue',
    cardTitle: 'Revenues',
    limit: 3,
    schema: eddTableSchema,
    disabled
  })

  return table
}

export default function EDDFinancialSubPage({ countries, epfs, questions, setQuestions, percents, errors, disabled }) {

  const financialOptions = [
    {
      name: 'initial_source_of_funds',
      title: 'Initial Source of Funds',
      type: 'multiline-text'
    },
    {
      name: 'jurisdiction_originating',
      title: 'Jurisdiction Originating',
      type: 'country',
      options: countries
    },
    {
      name: 'divider one',
      type: 'divider'
    },
    {
      name: 'revenue_expected_this_year',
      title: 'Revenue Expected this Year',
      type: 'yes-no'
    },
    {
      visible: (context) => {
        return context['revenue_expected_this_year'] === true
      },
      name: 'revenue_expected_this_year_list',
      type: 'component',
      component: () => {
        return (<RevenuesTable disabled={disabled} abstractions={questions['revenue_expected_this_year_list']} setAbstractions={(callback) => {
          setQuestions(old => {
            const result = callback(questions['revenue_expected_this_year_list'])
            old['revenue_expected_this_year_list'] = [...result]
            return { ...old }
          })
        }} percents={percents} countries={countries} />)
      }
    },
    {
      name: 'divider two',
      type: 'divider'
    },
    {
      name: 'expenses_incurred_this_year',
      title: 'Expenses Incurred this Year',
      type: 'yes-no'
    },
    {
      visible: (context) => {
        return context['expenses_incurred_this_year'] === true
      },
      name: 'expenses_incurred_this_year_list',
      type: 'component',
      component: () => (<ExpensesTable disabled={disabled} percents={percents} countries={countries}
        expenses={questions['expenses_incurred_this_year_list']}
        setExpenses={(callback) => {
          setQuestions(old => {
            const result = callback(questions['expenses_incurred_this_year_list'])
            old['expenses_incurred_this_year_list'] = [...result]
            return { ...old }
          })
        }}
      />)
    },
    {
      name: 'div three',
      type: 'divider'
    },
    {
      name: 'conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies',
      title: 'Conducting business with any governments, supranational agencies or governmental agencies',
      type: 'yes-no'
    },
    {
      visible: (context) => {
        return context['conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies'] === true
      },
      name: 'conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list',
      type: 'component',
      component: () => (<ConductingRevenues disabled={disabled} percents={percents} countries={countries}
        revenues={questions['conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list']}
        setRevenues={
          (callback) => {
            setQuestions(old => {
              const result = callback(questions['conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list'])
              old['conducting_business_with_any_governments_supranational_agencies_or_governmental_agencies_list'] = [...result]
              return { ...old }
            })
          }} />)
    },
    {
      type: 'divider',
      name: 'div four'
    },
    {
      name: 'entity_produces_financial_statements',
      title: 'Entity Produces Financial Statements',
      type: 'select',
      options: epfs
    },
    {
      name: 'financials_audited',
      title: 'Financials Audited',
      type: 'yes-no-unknown',
    },
    {
      name: 'accounting_service_provider',
      title: 'Accounting Service Provider',
      type: 'yes-no-unknown',
    },
    {
      visible: (ctx) => ctx['accounting_service_provider'] === 'yes',
      name: 'contact_details_accounting_service_provider',
      title: 'Contact Details',
      type: 'multiline-text'
    },
    {
      name: 'legal_service_provider',
      title: 'Legal Service Provider',
      type: 'yes-no-unknown',
    },
    {
      visible: (ctx) => ctx['legal_service_provider'] === 'yes',
      name: 'contact_details_legal_service_provider',
      title: 'Contact Details',
      type: 'multiline-text'
    }
  ]
  return (
    <div className="row">
      <div className="col-lg-12 gutter-b gutter-t">
        <div className="card card-custom">
          <div className="card-header">
            <div className="card-title">
              Financial
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7">
                {
                  financialOptions.map(option => {
                    return (<BaseFieldsConfiguration
                      disabled={disabled}
                      errors={errors}
                      key={option.name}
                      option={option}
                      value={questions}
                      editTextFieldValue={(value, name) => {
                        setQuestions(old => {
                          old[name] = value
                          return { ...old }
                        })
                      }} />)
                  })
                }
              </div>
              <div className="col-lg-3"></div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}