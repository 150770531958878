import React, { } from "react";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import BaseFieldsConfiguration from "../../../components/EditProfileForm/BaseFieldsConfiguration";
import { getBool, getStatusAnswer } from "../../../api/ui";
import useTitles from "../../../hooks/useTitles";
import * as yup from 'yup'

export const regulatoryValidationSchema = {
  regulated_by_recognized_authority: () => yup.string().required(),
  license_type: (c) => ({
    validate(v) {
      if (c['regulatory_status'] === 'approved' && !v) {
        throw new Error('Required field')
      }
    }
  }),
  license_number: (c) => ({
    validate(v) {
      if (c['regulatory_status'] === 'approved' && !v) {
        throw new Error('Required field')
      }
    }
  }),
  manager_location: (context) => ({
    validate(v) {
      if (!!context['license_type']?.toLowerCase().includes('fund') && context.investment_manager_appointed && !v) {
        throw new Error('Required field')
      }
    }
  }),
  manager_regulator: (context) => ({
    validate(v) {
      if (!!context['license_type']?.toLowerCase().includes('fund') && context.investment_manager_appointed && !v) {
        throw new Error('Required field')
      }
    }
  }),
  manager_approved_person: (context) => ({
    validate(v) {
      if (!!context['license_type']?.toLowerCase().includes('fund') && context.investment_manager_appointed && !v) {
        throw new Error('Required field')
      }
    }
  }),
}

export default function RegulatorySection({ regulatoryErrors, regulatoryObject, setRegulatoryObject, selectedJurisdiction, cimas, countries }) {
  function editRegulatory(value, fieldName) {
    setRegulatoryObject(old => {
      old[fieldName] = value
      return { ...old }
    })
  }

  const [bools] = useTitles(getBool)
  const [statuses] = useTitles(getStatusAnswer)

  const regulatoryOptions = [
    {
      name: 'regulated_by_recognized_authority',
      title: 'Regulated by a recognized Regulatory Authority',
      type: 'select',
      options: bools
    },
    {
      name: 'regulatory_status',
      title: `Regulatory Status in ${selectedJurisdiction.title}`,
      type: 'select',
      options: statuses
    },
    {
      name: 'license_type',
      title: 'License Type',
      type: 'select',
      options: cimas,
      visible: (context) => {
        return context['regulatory_status'] === 'approved'
      }
    },
    {
      name: 'license_number',
      title: 'License number',
      type: 'text',
      visible: (context) => {
        return context['regulatory_status'] === 'approved'
      }
    },
    {
      name: 'giin',
      title: 'Global Intermediary Identification Number (GIIN #)',
      type: 'text'
    },
    {
      name: 'fin',
      title: 'Financial Institution Number (FI#)',
      type: 'text'
    },
    {
      name: 'investment_manager_appointed',
      title: 'Investment Manager appointed?',
      type: 'open-switch',
      visible: (context) => {
        return !!context['license_type']?.toLowerCase().includes('fund')
      },
      options: [
        {
          name: 'manager_location',
          title: 'Where Located?',
          type: 'country',
          options: countries
        },
        {
          name: 'manager_regulator',
          title: 'Which Regulator?',
          type: 'text'
        },
        {
          name: 'manager_approved_person',
          title: 'Name of Approved Person',
          type: 'text'
        }
      ]
    }
  ]



  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <h3>
          Regulatory Overview
        </h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-7">
              {
                regulatoryOptions.map(option => (<BaseFieldsConfiguration errors={regulatoryErrors} editTextFieldValue={editRegulatory} option={option} value={regulatoryObject} />))
              }
            </div>
            <div className="col-lg-3" />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}