// TODO for testing

export const FRONTEND_VERSION = "0.14.2-12.01.2022"

// !! Must end with a slash !!
export let API_BASE_URL;

if(process.env.REACT_APP_BACKEND_URL) API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
else {
    API_BASE_URL = process.env.NODE_ENV === "development"
    // needed for connecting from Virtual Machine
    ? `http://${document.domain}:3001/`
    // ? window.location.protocol + "//" + window.location.hostname + "/api/"
    : "https://bo2g1d23gd.execute-api.eu-west-1.amazonaws.com/";
}

export const GOOGLE_MAPS_EMBEDDED_API = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyAlkuCIXq94Gu5Kd938JGuAwEcyA-sQ-w4'
export const CANNY_FEEDBACK_CENTER = "https://vasylkrenta228.canny.io/"
