import React, { useState } from 'react'
import { getEddStatusColored, getRiskRatingExtendedColored } from '../../../api/ui'
import useRequest from '../../../components/useRequest/useRequest'
import useColoredTitles from '../../../hooks/useColoredTitles'
import { getRR } from '../../../api/company'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function EDDStatusTaskPage({
  entity_id,
}) {
  const [coloredEDDStatuses] = useColoredTitles(getEddStatusColored)
  const [coloredRiskRatingsExtended] = useColoredTitles(getRiskRatingExtendedColored)

  const [riskRatingConfig, setRiskRatingConfig] = useState({})

  const [fetchRR] = useRequest(() => getRR(entity_id), data => data && setRiskRatingConfig(data))

  useEffect(() => {
    if (entity_id) {
      fetchRR()
    }
  }, [entity_id])

  return (<>
    <div className="card card-custom">
      <div className="card-body">
        <div className="card-header pt-0 pl-0 pb-2">
          <Link className="font-weight-bold h4" to={`/entity/${entity_id}/entity-due-diligence`}>
            Entity Due Diligence
          </Link>

        </div>

        <div className="card-body pt-2 pl-0 pb-0 pr-0">
          <div className="d-flex justify-content-between">
            <div className="font-weight-bold">Risk Rating</div>
            <span style={{ color: coloredRiskRatingsExtended.find(c => c.value === riskRatingConfig.summary_risk_rating)?.color }}>
              {coloredRiskRatingsExtended.find(c => c.value === riskRatingConfig.summary_risk_rating)?.title || '<no review>'}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <div className="font-weight-bold">EDD Status</div>
            <span style={{ color: coloredEDDStatuses.find(c => c.value === riskRatingConfig.edd_status)?.color}}>
              {coloredEDDStatuses.find(c => c.value === riskRatingConfig.edd_status)?.title || '<no review>'}
            </span>
          </div>


        </div>
      </div>
    </div>

  </>)
}