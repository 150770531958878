import React from 'react';
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import { Checkbox, LinearProgress, makeStyles } from '@material-ui/core';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const useStyles = makeStyles({
  dataColumn: {
    fontSize: '16px',
    padding: 12,
    fontWeight: 500,
    display: 'flex',
    gap: 10,

  },
  columnsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0'
  },
  dropUpDown: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  dataTableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'yellow',
  },
  progressBar: {
    width: '50%',
    alignSelf: 'center',
    
    '& .MuiLinearProgress-root': {
      background: '#E9E9E9',

      '& .MuiLinearProgress-bar' : {
        background: '#333333'
      }
    }
  },
  loadMoreButton: {
    border: '1px solid black',
    padding: '12px 15px',
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'pointer',
    marginTop: 30
  }
})

const DataTableColumn = ({title, width, sortable}) => {
  const styles = useStyles();

  return (
    <div className={styles.dataColumn} style={{width}}>
      <span>{title}</span>

      {
        sortable && (
          <span className="cursor-pointer">
            <SVG src="/media/svg/SortUp.svg"/>
          </span>
        )
      }
      
    </div>
  )
}

const DataTableCell = ({col, row}) => {
  return (
    <>
        <div style={{width: col.width, padding: 15}}>
          {col.component ? col.component(row) : row[col.name]}
        </div>
    </>
  )
}

const DataTableRow = ({columns, row, odd, isCheckbox, artifactId, artifactBaseURL, onSelectionChange}) => {
  const styles = useStyles()
  
  const row_url = (artifactBaseURL && artifactId)
  ? `/${ artifactBaseURL }/${ artifactId }`
  : undefined;

  return (
    <Link
      to={ row_url }
      style={{background: !odd ? 'transparent' : '#f6f6f6'}}
      className={`${styles.dataTableRow} data-table-row`}
    >
      {
        isCheckbox && (
          <div style={{width: 37.5}}>
            <Checkbox onClick={ e => { e.stopPropagation(); onSelectionChange(row) } } />
          </div>
        )
      }
      {
        columns.map(col => (<DataTableCell key={col.name} col={col} row={row} />))
      }
    </Link>
  )
}

const DataTableHeader = ({columns, isCheckbox}) => {
  const styles = useStyles()
  
  return (
    <>
      <div className={styles.columnsRow}>
        {
          isCheckbox && (
            <div style={{width: 37.5}}>
              <Checkbox checkedIcon={<IndeterminateCheckBoxIcon />} />
            </div>
          )
        }
        
        {
          columns.map((column) => (
            <DataTableColumn sortable={column.sortable} width={column.width} key={column.name} title={column.title}/>
          ))
        }
      </div>
    </>
  )
}

/**
 * 
 * column: {
 *  name
 *  title
 *  sortable: boolean
 *  width: inline style width
 *  component(row) - custom component for cell
 * }
 * 
 * row: {
 *   <column names> : string value
 * }
 * 
 * isCheckbox: checkbox in left side of table
 * 
 * loadMoreText replacers: {{c}} - count, {{t}} - total
 * 
 * total
 */
// TODO: make logic for checkboxes
// TODO: make logic for sorting
export default function DataTable ({columns, rows, isCheckbox, loadMoreText, total, artifactBaseURL, onSelectionChange, idField = "id"}) {
  const styles = useStyles();


  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <DataTableHeader isCheckbox={isCheckbox} columns={columns} />
        <div className={styles.tableRow}>
        {
          rows.map((row, index) => (
            <DataTableRow
              key={ row[idField] || index }

              isCheckbox={isCheckbox}
              columns={columns}
              row={row}
              odd={index % 2}
            
              artifactBaseURL={ artifactBaseURL }
              artifactId={ row.artifactId }

              onSelectionChange={ onSelectionChange }
            />
          ))
        }
        </div>

        {
          !!loadMoreText && 
          (
            <>
              <span style={{textAlign: 'center', padding: '15px'}}>
                {loadMoreText.replace('{{c}}', rows.length).replace('{{t}}', total)}
              </span>    

              <div className={styles.progressBar}>
                <LinearProgress variant="determinate" value={rows.length * 100 / total}/>  
              </div>

              <div
                className={styles.loadMoreButton}
              >
                Load more
              </div> 
            </>
          )
        }
        
      </div>
    </>
  )
}