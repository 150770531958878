import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'

export default function LeaveBlocker({ unsavedExists = false, message = 'Changes you made may not be saved.' }) {


  useEffect(() => {
    if (!unsavedExists) return () => { }

    const beforeUnloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [unsavedExists, message])



  return (<Prompt when={unsavedExists} message={message} />)
}