import { useState } from "react"
import _ from 'lodash'

export default function useForm(abstractions, setAbstractions, defaultAbstraction, validationFn = () => true) {
  const [current, setCurrent] = useState(defaultAbstraction)
  const [modalOpened, setModalOpened] = useState(false)
  const [editing, setEditing] = useState(-1)
  const [errors, setErrors] = useState({})


  const editField = (fieldName, value) => {
    setCurrent(old => {
      old[fieldName] = value
      return { ...old }
    })
  }

  function addAbstraction(unique_key) {
    setModalOpened(true)
    setEditing(-1)
    if (unique_key === true) {
      setCurrent({...defaultAbstraction, unique_id: _.uniqueId()})
    } else {
      setCurrent(defaultAbstraction)
    }
  }

  function editAbstraction(index) {
    setModalOpened(true)
    setEditing(index)
    setCurrent(_.cloneDeep(abstractions[index]))
  }

  function deleteAbstraction(index) {
    setAbstractions(old => {
      return old.filter((_, i) => i !== index)
    })
  }

  async function saveAbstraction() {
    if (await validationFn(current, setErrors)) {
      if (editing === -1) {
        setAbstractions(old => {
          return [...old, { ...current }]
        })
      } else if (editing >= 0) {
        setAbstractions(old => {
          old[editing] = { ...current }
          return [...old]
        })
      }
    } else {
      return Promise.reject()
    }

  }

  return [current, modalOpened, setModalOpened, errors, editField, addAbstraction, editAbstraction, deleteAbstraction, saveAbstraction]
}