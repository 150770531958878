import React from "react";

const StockExchangeSelect = props => {
  const error = {borderColor: '#f018a6'}
  const helperText = { fontSize: '0.75rem', color: '#f018a6', marginLeft: 14, marginTop: 3}
  return (
    <>
      <select
        className="form-control h-auto py-5 px-6 my-2"
        type="text"
        style={props.error ? error : null}
        placeholder={ props.placeholder }
        name={ props.name }
        autoComplete="off"
        onChange={ props.onChange }
        value={ props.value }
      >
        <option value="-1">{ props.placeholder }</option>
        <option>American Stock Exchange</option>
        <option>Amman Stock Exchange</option>
        <option>Athens Stock Exchange (ATHEX)</option>
        <option>Australian Securities Exchange</option>
        <option>Australian Stock Exchange</option>
        <option>B3 - Brasil Bolsa Balcão</option>
        <option>B3 S.A.</option>
        <option>Bahrain Bourse</option>
        <option>BATS Global Markets</option>
        <option>Bermuda Stock Exchange</option>
        <option>BME Spanish Exchanges</option>
        <option>Bolsa de Comercio de Buenos Aires</option>
        <option>Bolsa de Comercio de Santiago</option>
        <option>Bolsa de Valores de Colombia</option>
        <option>Bolsa de Valores de Lima</option>
        <option>Bolsa Mexicana de Valores</option>
        <option>Borsa İstanbul</option>
        <option>Bourse de Casablanca</option>
        <option>Bourse de Montreal Inc</option>
        <option>BSE India Limited</option>
        <option>Bursa Malaysia</option>
        <option>Cayman Islands Stock Exchange</option>
        <option>CBOE Holdings, Inc.</option>
        <option>Channel Islands Stock Exchange</option>
        <option>Chicago Board of Trade</option>
        <option>Chicago Stock Exchange</option>
        <option>China Financial Futures Exchange</option>
        <option>CME Group</option>
        <option>Coffee, Sugar and Cocoa Exchange, Inc</option>
        <option>Colombo Stock Exchange</option>
        <option>Cyprus Stock Exchange</option>
        <option>Dalian Commodity Exchange</option>
        <option>Deutsche Börse AG</option>
        <option>Dubai Financial Market</option>
        <option>EUREX</option>
        <option>EuroCCP</option>
        <option>Euronext</option>
        <option>Hochiminh Stock Exchange</option>
        <option>Hong Kong Exchanges and Clearing</option>
        <option>ICAP Securities & Derivative Exchange Limited</option>
        <option>ICE Futures Europe</option>
        <option>ICE Futures U.S. Inc.</option>
        <option>Indonesia Stock Exchange</option>
        <option>Intercontinental Exchange, Inc.</option>
        <option>International Capital Market Association</option>
        <option>Irish Stock Exchange</option>
        <option>Japan Exchange Group, Inc.</option>
        <option>Johannesburg Stock Exchange</option>
        <option>Kansas City Board of Trade</option>
        <option>Kazakhstan Stock Exchange</option>
        <option>Korea Exchange</option>
        <option>LIFFE Administration and Management</option>
        <option>London Stock Exchange Plc</option>
        <option>Luxembourg Stock Exchange</option>
        <option>Malta Stock Exchange</option>
        <option>MidAmerica Commodity Exchange</option>
        <option>Minneapolis Grain Exchange</option>
        <option>Moscow Exchange</option>
        <option>Muscat Securities Market</option>
        <option>Nasdaq</option>
        <option>National Stock Exchange of India Limited</option>
        <option>New York Cotton Exchange</option>
        <option>New York Futures Exchange</option>
        <option>New York Mercantile Exchange Inc.</option>
        <option>New York Stock Exchange</option>
        <option>New Zealand Stock Exchange</option>
        <option>Nigerian Stock Exchange</option>
        <option>NZX Limited</option>
        <option>Osaka Securities Exchange</option>
        <option>Oslo Børs</option>
        <option>Pacific Exchange</option>
        <option>Palestine Exchange</option>
        <option>Philadelphia Stock Exchange</option>
        <option>Philippine Stock Exchange</option>
        <option>Qatar Stock Exchange</option>
        <option>Saudi Stock Exchange (Tadawul)</option>
        <option>Shanghai Futures Exchange</option>
        <option>Shanghai Stock Exchange</option>
        <option>Shenzhen Stock Exchange</option>
        <option>Singapore Exchange</option>
        <option>SIX Swiss Exchange</option>
        <option>South African Futures Exchange</option>
        <option>Stock Exchange of Mauritius</option>
        <option>Taipei Exchange</option>
        <option>Taiwan Futures Exchange</option>
        <option>Taiwan Stock Exchange</option>
        <option>Tel-Aviv Stock Exchange</option>
        <option>The Depository Trust & Clearing Corporation</option>
        <option>The Egyptian Stock Exchange</option>
        <option>The London Metal Exchange Limited</option>
        <option>The Options Clearing Corporation</option>
        <option>The Stock Exchange of Thailand</option>
        <option>TMX Group Inc.</option>
        <option>Tokyo International Financial Futures Exchange</option>
        <option>Tokyo Stock Exchange</option>
        <option>Toronto Stock Exchange</option>
        <option>Zhengzhou Commodity Exchange</option>
      </select>
      {props.error && props.helperText && (
        <div style={helperText}>{props.helperText}</div>
      )}
    </>
  )
}

export default StockExchangeSelect;
