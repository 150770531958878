import store from "../../redux/store";



export function notify({type, title="New message", message, duration = 5000}) {
  if (typeof message !== String) message = JSON.stringify(message)
  const notificationId = Date.now();
  store.dispatch(
    {
      type,
      payload: {
        id: notificationId,
        title,
        message,
        duration
      }
    }
  )

  setTimeout(() => {
    store.dispatch(
      {
        type: "POP_MESSAGE",
        payload: {
          id: notificationId
        }
      }
    )
  }, duration)
}