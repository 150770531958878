import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { MenuItem, TextField } from "@material-ui/core";

export function CompanyUsersPage() {
  const [users, setUsers] = useState([]);
  const [clientCompanies, setClientCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const getUserCompanies = () => {
    Axios.get("csp/my-companies")
      .then(({ data }) => {
        setClientCompanies(data.user_companies);
      })
      .catch(() => { });
  };

  const getUsers = () => {
    if (!selectedCompanyId || selectedCompanyId === "-1") return;

    Axios.get(`company/users/${selectedCompanyId}`)
      .then(({ data }) => {
        setUsers(data.users);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getUserCompanies();
  }, []);

  useEffect(() => {
    getUsers();
  }, [selectedCompanyId]);

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">Company selector</div>
          </div>
        </div>
        <div className="card-body pt-5">
          <TextField
            select
            variant='outlined'
            fullWidth
            value={selectedCompanyId}
            onChange={e => setSelectedCompanyId(e.target.value)}
            type="number"
            placeholder="Target company"
            name="target_company"
            autoComplete="off"
          >
            {clientCompanies.map(company => (
              <MenuItem key={company.company_id} value={company.company_id}>
                {company.company_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <div className="card-title w-50 ml-0 align-items-start flex-column">
            <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">Сompany users</div>
            <div className="text-muted mt-3 font-weight-bold font-size-sm"> A list of users with the access to '' company</div>
          </div>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <li className="nav-item">
                <Link
                  to="/invitations"
                  className="px-5 font-weight-bold btn btn-primary"
                >
                  Invite new users...
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="card-body pt-3 pb-0">
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "200px" }}></th>
                  <th className="p-0" style={{ minWidth: "200px" }}></th>
                  <th className="p-0" style={{ minWidth: "100px" }}></th>
                  <th className="p-0" style={{ minWidth: "80px" }}></th>
                </tr>
              </thead>
              <tbody>
                {users.map(item => (
                  <tr key={item.user_id}>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                        {item.user_full_name}
                      </span>
                      <div>
                        <span className="text-muted">
                          {item.user_name && <>aka @{item.user_name}</>}
                        </span>
                      </div>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.emails.map(email => (
                          <div key={email}>{email}</div>
                        ))}
                      </span>
                      <span className="text-muted font-weight-bold">
                        Email addresses
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                        {item.user_role}
                      </span>
                      <div>
                        <span className="text-muted">User's role</span>
                      </div>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                        {new Date(item.became_company_user_on).toLocaleString()}
                      </span>
                      <div>
                        <span className="text-muted">
                          Became company user on
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
