import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSecondAside } from "../../../../_metronic/layout/components/aside/SecondAside";

export default function useEntitySecondAside({ entity_id, csp_id }) {
  const [menuDetails, setMenuDetails] = useState([])

  const globalRole = useSelector(state => state.auth.user.global_user_role)
  const cspRole = useSelector(state => state.auth.user.user_csp_role)
  const entityRole = useSelector(state => state.auth.user.user_company_role)

  useEffect(() => {
    setMenuDetails([
      {
        name: `Home`,
        path: `/entity/${entity_id}`,
        icon: `/media/svg/icons/Home/Home.svg`
      },
      {
        id: 1,
        name: `General`,
        path: `/entity/${entity_id}/general`,
        icon: `/media/svg/icons/Code/Info-circle.svg`,
        submenu: [
          {
            path: `/entity/${entity_id}/profile`,
            title: `Profile`
          },
          {
            path: `/entity/${entity_id}/address`,
            title: `Address`
          },
          {
            // path: `/entity-due-diligence?entity_id=${entity_id}&csp_id=${csp_id}`,
            path: `/entity/${entity_id}/entity-due-diligence`,
            title: 'Entity Due Diligence'
          }
        ]
      },
      {
        id: 2,
        name: `Ownership`,
        icon: `/media/svg/icons/Shopping/Calculator.svg`,
        submenu: [
          {
            path: `/entity/${entity_id}/ownership-summary`,
            title: `Summary`
          },
          {
            path: `/entity/${entity_id}/ownership-config`,
            title: `Ownership Categories / Share Classes`
          },
          {
            path: `/entity/${entity_id}/ownership-distribution`,
            title: `Ownership Distribution`
          },
          {
            path: `/entity/${entity_id}/beneficial-owners`,
            title: `Beneficial Ownership`
          },
          {
            path: `/entity/${entity_id}/transaction-history`,
            title: `Transaction History`
          }
        ]
      },
      {
        id: 3,
        name: `Stakeholders`,
        icon: `/media/svg/icons/Communication/Adress-book2.svg`,
        submenu: [
          {
            path: `/entity/${entity_id}/stakeholders-owners`,
            title: `Owners`
          },
          {
            path: `/entity/${entity_id}/controlling-persons`,
            title: 'Controlling Persons'
          },
          // {
          //   path: `/entity/${entity_id}/stakeholders-directors_officers`,
          //   title: `Directors & Officers`
          // },
          {
            path: `/entity/${entity_id}/stakeholders-other`,
            title: `Other`
          },
          
          // {
          //   path: `/entity/${entity_id}/stakeholders-users`,
          //   title: `Company users`
          // },
          {
            path: `/entity/${entity_id}/stakeholders-log`,
            title: `Log`
          },

        ]
      },
      {
        id: 4,
        name: `Vault`,
        path: `/entity/${entity_id}/vault`,
        icon: `/media/svg/icons/Files/Folder-cloud.svg`
      },
      {
        id: 5,
        name: `Reports`,
        path: `/entity/${entity_id}/reporting`,
        icon: `/media/svg/icons/Files/Compiled-file.svg`
      },
      (entityRole && cspRole) && {
        id: 10,
        name: `Settings`,
        path: `/entity/${entity_id}/settings`,
        icon: `/media/svg/icons/General/Settings-2.svg`,
        submenu: [
          {
            path: `/entity/${entity_id}/risk-ratings`,
            title: `Risk ratings`
          },
          {
            title: `Entity configuration`,
            path: `/entity/${entity_id}/config`,
          },
          {
            title: `Entity's Fee Table`,
            path: `/entity/${entity_id}/fee-table`,
          }
        ]
      }
    ])
  }, [entity_id, csp_id, globalRole, cspRole, entityRole])



  useSecondAside(menuDetails);
}