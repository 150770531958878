import React, { useEffect } from 'react'
import { useState } from 'react'
import { getEntityStakeholders, getRRStakeholderDecision, saveRRStakeholderDecision } from '../../../../api/company'
import useRequest from '../../../../components/useRequest/useRequest'
import useUnsaved from '../../../../hooks/useUnsaved'

export default function useStakeholderRR({
  entity_id,
  coloredRiskRatings
}) {

  const [stakeholders, setStakeholders] = useState([])
  const [stakeHoldersRR, setStakeholdersRR] = useState({
    risk_rate: null
  })
  function editStakeholderRR(value, name) {
    setStakeholdersRR(old => {
      old[name] = value
      return { ...old }
    })
  }
  const [fetchStakeholders] = useRequest(() => getEntityStakeholders(entity_id), (data) => {
    setStakeholders(data)
  })

  const [fetchRRStakeholderDecision, __, ended] = useRequest(() => getRRStakeholderDecision(entity_id), data => {
    data && setStakeholdersRR(data)
  })

  const [unsaved, setUnsaved] = useUnsaved({ended}, [stakeHoldersRR])

  useEffect(() => {
    fetchStakeholders()
    fetchRRStakeholderDecision()
  }, [])

  const stakeholderRiskOptions = [
    {
      name: 'risk_rate',
      title: 'Stakeholder Risk',
      type: 'color-select',
      options: coloredRiskRatings
    }
  ]

  const [saveStakeholderDecision, stakeholderDecisionLoading] = useRequest(() => saveRRStakeholderDecision(entity_id, stakeHoldersRR)
    .then(() => setUnsaved(false)))

  const stakeholdersTableCols = [
    {
      name: 'person_name',
      title: 'Name'
    },
    {
      name: 'person_role',
      title: "Role"
    },
    {
      name: 'person_share',
      title: 'Ownership Share'
    },
    {
      name: 'person_status',
      title: 'Status'
    },
    {
      name: 'person_risk_rating',
      title: 'Risk Rating',
      extraData: coloredRiskRatings,
      component: (row, _, coloredRiskRatings) => {
        const found = coloredRiskRatings.find(c => c.value === row.person_risk_rating)
        return (
          <span style={{ color: found?.color }}>
            {found?.title}
          </span>
        )
      }
    }
  ]

  return {
    stakeholders,
    editStakeholderRR,
    stakeholderRiskOptions,
    saveStakeholderDecision,
    stakeholderDecisionLoading,
    stakeholdersTableCols,
    stakeHoldersRR,
    unsavedRR: unsaved
  }
}