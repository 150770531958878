import React, { useRef, useState } from "react";

import { Modal, Button } from "react-bootstrap";
import Axios from "axios";
import { ReactSketchCanvas } from "react-sketch-canvas";

import "./SignModal.css";

const canvas_styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
};

function SignModal(props) {
  const canvas = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [signAs, setSignAs] = useState("csp");

  const clearCanvas = () => {
    canvas.current.clearCanvas();
  }

  const canvasUndo = () => {
    canvas.current.undo();
  }

  const canvasRedo = () => {
    canvas.current.redo();
  }

  const sign = () => {
    setIsLoading(true);

    canvas.current.exportImage("png")
    .then(data => {
      // We have the image, send a request to the backend
      // TODO maybe send in the multipart form in the future?

      Axios.post(`/task/${ props.taskId }/sign`, {
        signature: data,
        sign_as: props.pendingDocumentId ? undefined : signAs,
        pending_document_id: props.pendingDocumentId || undefined
      })
      .then(() => {
        if(props.onSigned) props.onSigned(signAs);
        props.onHide();
        
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      })
    })
  }

  return (
    <Modal id="sign-modal" show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Leave a signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="panels-container">
          <div className="canvas-panel">
          <ReactSketchCanvas
            style={canvas_styles}
            ref={ canvas }
            strokeWidth={2}
            strokeColor="black"
          />
          </div>
          <div className={`right-panel${ isLoading ? " disabled" : "" }`}>
            { !props.pendingDocumentId && (
              <select
                className="form-control h-auto py-2 px-4 rounded-sm mb-1"
                type="text"
                name="purpose"
                onChange={ e => setSignAs(e.target.value) }
                value={ signAs }
              >
                <option value="csp">Sign as a CSP user</option>
                <option value="requester">Sign as a Requester</option>
                <option value="company_user">Sign as an Entity user</option>
                <option value="shareholder">Sign as a Shareholder</option>
                <option value="director">Sign as a company director</option>
              </select>
            ) }

            <Button className="btn btn-success" onClick={sign}>
              Sign
            </Button>
            <div className="spacer"></div>
            <Button variant="secondary" onClick={canvasUndo}>
              Undo
            </Button>
            <Button variant="secondary" onClick={canvasRedo}>
              Redo
            </Button>
            <div className="spacer"></div>
            <Button className="btn btn-danger" onClick={clearCanvas}>
              Clear
            </Button>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
            <div className="spacer"></div>
            <p>Leave your signature using your mouse, touchpad or a stylus.</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignModal;
