import React, { useState, useEffect } from "react";
import ProfileViewWidget from "../../../components/ProfileWiewVidget/ProfileViewWidget";

export default function ProfileOverview(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(!props.basicUserInfo || !props.basicUserInfo.dd) return;

    setItems([
        {
          name: 'Phone number:',
          value: props.basicUserInfo.dd.phone_number || "N/A",
        },
        {
          name: "Email",
          value: props.basicUserInfo.emails ? props.basicUserInfo.emails[0] : "N/A"
        },
        {
          name: "Country of birth:",
          value: props.basicUserInfo.dd.country_of_birth || "N/A"
        },
        {
          name: "Residential Country:",
          value: props.basicUserInfo.dd.physical_address_country || "N/A"
        },
        {
          name: "Regulatory Status:",
          value: "@clarify is this DD?",
        },
        {
          name: "Communication Preferences:",
          value: "@placeholder"
        }
    ]);
  }, [props.basicUserInfo])

    return(
      <ProfileViewWidget
        title="Overview"
        items={items}
      />
    )
}
