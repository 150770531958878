import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Checkbox } from '@material-ui/core'
import BaseFieldsConfiguration from '../../../components/EditProfileForm/BaseFieldsConfiguration'
import SimpleModal from '../../../components/Modals/SimpleModal'
import _ from 'lodash'
import * as yup from 'yup'
import { simpleValidate } from '../../../helpers/validation'

export const initialSubscriberSchema = {
  initial_subscriber: ctx => ctx.change_initial_subscriber && yup.string().nullable().required('Required field')
}

export function InitialSubscriberComponent({ region_name, shareholders = [], value = {}, onChange = () => { }, errors = {}, csp_name }) {

  const opts = [
    {
      name: 'change_initial_subscriber',
      title: 'Change initial subscriber',
      type: 'component',
      component: ({ value, onChange }) => {
        return (
          <div className="d-flex pb-5 pt-5">
            <Checkbox
              variant="outlined"
              onChange={e => { onChange(e.target.checked) }}
              checked={value}
              fullWidth />

            <div className='pl-5'>
              I wish to select a new initial subscriber / limited partner other than {csp_name}.<br />
              I understand that by selecting this box the formation of this entity will be delayed.
            </div>
          </div>
        )
      }
    },
    {
      visible: (ctx) => {
        return ctx.change_initial_subscriber
      },
      name: 'initial_subscriber',
      title: 'Initial subscriber / Limited Partner',
      type: 'select',
      options: shareholders.map(({ entity_name, name, shareholder_id, unique_id }) => ({
        value: shareholder_id || unique_id,
        title: entity_name || name
      }))
    }
  ]

  return (
    <>
      <div className="text-center">
        <p>
          Note: This entity will be incorporated / registered, with {csp_name} as the Initial Subscriber{' '}
          / Initial Limited Partner.  This is for expediency purposes, since the Initial Subscriber / Initial Limited Partner{' '}
          is required to sign all incorporation / registration documents and have those notarized.{' '}
          All shares of this entity held by {csp_name} will be transferred on the date of incorporation / registration{' '}
          to the Shareholders as previously defined.
        </p>
        <p>
          If for any reason this arrangement is not satisfactory for you, then you will need to select a new initial subscriber{' '}
          /  limited partner below, who will need to provide complete due diligence information, physically sign and notarize all{' '}
          required documents, then deliver originals to {csp_name} to in {region_name}.
        </p>



      </div>
      {
        opts.map(o => (
          <BaseFieldsConfiguration errors={errors} option={o} key={o.name} value={value} editTextFieldValue={(v, name) => onChange(name, v)} />
        ))
      }
    </>
  )
}

export function InitialSubscriberModal({
  shareholders = [],
  open = false,
  value = {},
  onClose = () => { },
  onSave = () => { } }) {
  const {csp_name, jurisdiction_region} = useSelector(state => state?.tenant?.csp)
  const [data, setData] = useState(value)
  const [errors, setErrors] = useState({})

  const prev = useRef()

  useEffect(() => {
    prev.current = value
    setData(value)
  }, [value])

  useEffect(() => {
    if (!data.change_initial_subscriber) {
      setData(old => ({ ...old, initial_subscriber: null }))
    }
  }, [data.change_initial_subscriber])

  async function submit() {
    if (await simpleValidate(initialSubscriberSchema, data, setErrors)) {
      onSave(data);
    } else {
      await Promise.reject()
    }
  }


  return (<>
    <SimpleModal
      size='lg'
      onClose={() => { onClose(); setData(prev.current) }}
      onSave={submit}
      title='Initial subscriber'
      open={open}>
      <InitialSubscriberComponent region_name={jurisdiction_region?.region_title} csp_name={csp_name} errors={errors} onChange={(n, v) => setData(old => { return { ...old, [n]: v } })}
        value={data}
        shareholders={shareholders} />
    </SimpleModal>
  </>)
}