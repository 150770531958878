import React from 'react'
import { WidgetTable } from '../WidgetTable/WidgetTable'

const cols = [
  {
    name: 'task',
    title: 'Task',
    width: 150
  },
  {
    name: 'due_date',
    title: 'Due date',
    width: 150
  }
]

const rows = [
  // {
  //   id: 1,
  //   task: 'AAAA',
  //   due_date:'A99.99.9999'
  // },
  // {
  //   id: 2,
  //   task: 'AAAA',
  //   due_date:'A99.99.9999'
  // },
  // {
  //   id: 3,
  //   task: 'AAAA',
  //   due_date:'A99.99.9999'
  // },
  // {
  //   id: 4,
  //   task: 'AAAA',
  //   due_date:'A99.99.9999'
  // },
  // {
  //   id: 5,
  //   task: 'AAAA',
  //   due_date:'A99.99.9999'
  // },
]

export default function NotificationWidget() {

  return (
    <WidgetTable
    {...{cols, rows,
      title: 'Notifications',
      subtitle: 'List of notifications'
    }}
    />
  )
}
