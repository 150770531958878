import React, { useEffect } from 'react'
import { FormCheckbox } from '../../../components/Basic';
import { Modal } from "react-bootstrap";
import { MenuItem, TextField } from "@material-ui/core";
import "./style.css"
import SaveButton from '../../../components/_common/SaveButton';

export default function ShareClassWindow({
  modalOpened,
  visibleFields,
  shareErrors,
  editField,
  current,
  ownershipTypes,
  currency,
  setModalOpened,
  saveAbstraction,
  loading
}) {

  return (
    <>
      <Modal show={modalOpened} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Ownership Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row" >
              <div className="col-sm-7 col-12 m-auto">
                {/* It have to be available for all entity categories */}
                {
                  visibleFields.class_type && (
                    <TextField
                      error={shareErrors.class_type}
                      helperText={shareErrors.class_type}
                      select
                      className="w-100"
                      label="Ownership type"
                      variant="outlined"
                      type="text"
                      name="class_type"
                      onChange={e => editField('class_type', e.target.value)}
                      value={current.class_type}
                    >
                      {ownershipTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                      ))}
                    </TextField>
                  )
                }

                {
                  visibleFields.class_name && (
                    <TextField
                      error={shareErrors.class_name}
                      helperText={shareErrors.class_name}
                      variant="outlined"
                      label="Nickname"
                      className="mt-5 w-100"
                      type="text"
                      name="class_name"
                      onChange={e => editField('class_name', e.target.value)}
                      value={current.class_name}
                    />
                  )
                }
                {
                  visibleFields.authorized_shares && (
                    <TextField
                      error={shareErrors.authorized_shares}
                      helperText={shareErrors.authorized_shares}
                      label="Authorized shares"
                      className="mt-5 w-100"
                      type="number"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      name="authorized_shares"
                      onChange={e => editField('authorized_shares', e.target.value)}
                      value={current.authorized_shares}
                    />
                  )
                }
                {
                  visibleFields.value_per_share && (
                    <TextField
                      error={shareErrors.value_per_share}
                      helperText={shareErrors.value_per_share}
                      variant="outlined"
                      className="mt-5 w-100"
                      label="Par Value"
                      type="number"
                      name="value_per_share"
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={e => editField('value_per_share', e.target.value)}
                      value={current.value_per_share}
                    />
                  )
                }
                {
                  visibleFields.currency && (
                    <TextField
                      error={shareErrors.currency}
                      helperText={shareErrors.currency}
                      select
                      className="mt-5 w-100"
                      label="Currency"
                      variant="outlined"
                      type="text"
                      name="currency"
                      onChange={e => editField('currency', e.target.value)}
                      value={current.currency}
                    >
                      {currency.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                      ))}
                    </TextField>
                  )
                }
                {
                  visibleFields.issued_on && (
                    <TextField
                      error={shareErrors.issued_on}
                      helperText={shareErrors.issued_on}
                      variant="outlined"
                      type="date"
                      format="dd-mmm-yyyy"
                      label="Date Authorised"
                      InputLabelProps={{ shrink: true }}
                      className="mt-5 w-100"
                      name="issued_on"
                      onChange={e => editField('issued_on', e.target.value)}
                      value={current.issued_on}
                    />
                  )
                }
                {
                  visibleFields.voting_rights && (
                    <TextField
                      error={shareErrors.voting_rights}
                      helperText={shareErrors.voting_rights}
                      variant="outlined"
                      label="Voting Rights per Share"
                      className="mt-5 pb-5 w-100"
                      type="number"
                      name="voting_rights"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      onChange={e => editField('voting_rights', e.target.value)}
                      value={current.voting_rights}
                    />
                  )
                }
              </div>
              <div>
                <div className="font-size-h5 font-weight-bold mb-3">Additional Details</div>
                {
                  visibleFields.rights_to_dividents && (
                    <FormCheckbox
                      text="Rights to dividends"
                      name="rights_to_dividents"
                      onChange={e => editField('rights_to_dividents', e.target.checked)}
                      checked={current.rights_to_dividents}
                    />
                  )
                }
                {
                  visibleFields.rights_to_capital_on_winding_up && (
                    <FormCheckbox
                      text="Rights to capital on winding up"
                      name="rights_to_capital_on_winding_up"
                      onChange={e => editField('rights_to_capital_on_winding_up', e.target.checked)}
                      checked={current.rights_to_capital_on_winding_up}
                    />
                  )
                }
                {
                  visibleFields.certificates_required && (
                    <FormCheckbox
                      text="Certificates required to be issued"
                      name="certificates_required"
                      onChange={e => editField('certificates_required', e.target.checked)}
                      checked={current.certificates_required}
                    />
                  )
                }
                {
                  visibleFields.director_approval_required && (
                    <FormCheckbox
                      text="Require director approval for transfers"
                      name="director_approval_required"
                      onChange={e => editField('director_approval_required', e.target.checked)}
                      checked={current.director_approval_required}
                    />
                  )
                }
                {
                  visibleFields.has_restrictions && (
                    <FormCheckbox
                      text="Add Restriction"
                      name="has_restrictions"
                      onChange={e => editField('has_restrictions', e.target.checked)}
                      checked={current.has_restrictions}
                    />
                  )
                }
                {current.has_restrictions && visibleFields.restrictions && (
                  <>
                    <TextField
                      helperText={shareErrors.restrictions}
                      error={shareErrors.restrictions}
                      placeholder="Restriction Details"
                      multiline
                      rows={8}
                      className=" mt-5 w-100"
                      rowsMax={10}
                      variant="outlined"
                      name="restrictions"
                      onChange={e => editField('restrictions', e.target.value)}
                      value={current.restrictions}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-btn font-weight-bold px-9 py-4 my-3" onClick={() => setModalOpened(false)}>
            Cancel
          </button>
          <SaveButton loading={loading} onClick={() => saveAbstraction()}/>
        </Modal.Footer>
      </Modal>
    </>
  )
}