export const STEP_MAPPING = {
	name_prefix: "hdd_basic",
	first_name: "hdd_basic",
	middle_name: "hdd_basic",
	last_name: "hdd_basic",
	date_of_birth: "hdd_identification",
	name_suffix: "hdd_basic",
	preferred_name: "hdd_basic",
	email_address: "hdd_basic",
	gender: "hdd_basic",
	alias: "hdd_basic",
	phone_number: "hdd_contact_details",

	country_of_birth: "hdd_identification",
	nationality: "hdd_identification",
	is_tax_resident: "hdd_identification",
	ids: "hdd_identification",
	tax_resident_country: "hdd_identification",

	country_of_issue: "hdd_id_document",
	document_type: "hdd_id_document",
	document_number: "hdd_id_document",
	document_issue_date: "hdd_id_document",
	document_expiry_date: "hdd_id_document",


	moved_to_physical_address_at: "hdd_contact_details",
	mailing_address_same_as_residential: "hdd_contact_details",
	more_addresses: "hdd_contact_details",
	also_operating_in_other_countries: "hdd_contact_details",
	also_operating_in_other_countries_country: "hdd_contact_details",

	approved_by_other_authority: "hdd_regulatory_status",
	approved_by_other_authority_country: "hdd_regulatory_status",
	approved_by_other_authority_name: "hdd_regulatory_status",
	approved_by_other_authority_license_number: "hdd_regulatory_status",
	has_professional_designations: "hdd_regulatory_status",
	professional_designations: "hdd_regulatory_status",

	involved_into_litigation: "hdd_kyc",
	involved_into_litigation_details: "hdd_kyc",
	ever_bankrupt: "hdd_kyc",
	ever_bankrupt_details: "hdd_kyc",
	ever_investigated: "hdd_kyc",
	ever_investigated_details: "hdd_kyc",
	ever_criminal_offense: "hdd_kyc",
	ever_criminal_offense_details: "hdd_kyc",
	ever_censured: "hdd_kyc",
	ever_censured_details: "hdd_kyc",
	held_position_political_office: "hdd_kyc",
	held_position_government_official: "hdd_kyc",
	held_position_political_advisor: "hdd_kyc",
	held_position_contacts_with_government: "hdd_kyc",
}