import React from 'react'
import { FormButton, FormInput, FormSwitch, SearchField } from '../../../components/Basic';

export default function HDDProfessionalAndRegulatory({
  page4Formik,
  selectOptions,
  goBack,
  loading
}) {
  return (
    <form onSubmit={page4Formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
      <div className="font-size-h6 font-weight-bold my-4 ml-2">Please select all that apply to you</div>
      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="I am already approved by some regulatory authority"
          name="approved_by_other_authority"
          className="m-1"
          value={page4Formik.values.approved_by_other_authority}
          onChange={page4Formik.handleChange}
        />
        {page4Formik.values.approved_by_other_authority && (
          <div className="form-group fv-plugins-icon-container mt-6">
            <div className="font-size-h6 font-weight-bold my-4 ml-2">Authority info</div>
            <SearchField
              readOnly={true}
              name="approved_by_other_authority_country"
              value={page4Formik.values.approved_by_other_authority_country}
              onChange={(_, val) => {
                page4Formik.setFieldValue('approved_by_other_authority_country', val);
              }}
              onBlur={() => page4Formik.setFieldTouched('approved_by_other_authority_country')}
              options={selectOptions.country}
              label="Country approved"
              error={
                page4Formik.errors.approved_by_other_authority_country &&
                page4Formik.touched.approved_by_other_authority_country
              }
              helperText={
                page4Formik.errors.approved_by_other_authority_country &&
                  page4Formik.touched.approved_by_other_authority_country
                  ? page4Formik.errors.approved_by_other_authority_country
                  : null
              }
            />
            <FormInput
              mui
              label="Name of Authority"
              name="approved_by_other_authority_name"
              value={page4Formik.values.approved_by_other_authority_name}
              onChange={page4Formik.handleChange}
              onBlur={page4Formik.handleBlur}
              error={
                page4Formik.errors.approved_by_other_authority_name &&
                page4Formik.touched.approved_by_other_authority_name
              }
              helperText={
                page4Formik.errors.approved_by_other_authority_name &&
                  page4Formik.touched.approved_by_other_authority_name
                  ? page4Formik.errors.approved_by_other_authority_name
                  : null
              }
            />
            <FormInput
              mui
              className="w-100 my-2"
              label="License or registration number"
              name="approved_by_other_authority_license_number"
              value={page4Formik.values.approved_by_other_authority_license_number}
              onChange={page4Formik.handleChange}
              onBlur={page4Formik.handleBlur}
              error={
                page4Formik.errors.approved_by_other_authority_license_number &&
                page4Formik.touched.approved_by_other_authority_license_number
              }
              helperText={
                page4Formik.touched.approved_by_other_authority_license_number &&
                  page4Formik.errors.approved_by_other_authority_license_number
                  ? page4Formik.errors.approved_by_other_authority_license_number
                  : null
              }
            />
          </div>
        )}
      </div>
      <div className="form-group fv-plugins-icon-container">
        <FormSwitch
          label="I have professional designations"
          name="has_professional_designations"
          className="m-1"
          value={page4Formik.values.has_professional_designations}
          onChange={page4Formik.handleChange}
        />

        {page4Formik.values.has_professional_designations && (
          <div className="form-group fv-plugins-icon-container mt-6">
            <div className="font-size-h6 font-weight-bold mb-4 ml-2">Professional designations</div>
            <FormInput
              mui
              multiline
              name="professional_designations"
              label="Designations"
              onBlur={page4Formik.handleBlur}
              onChange={page4Formik.handleChange}
              value={page4Formik.values.professional_designations}
              error={page4Formik.errors.professional_designations && page4Formik.touched.professional_designations}
              helperText={
                page4Formik.errors.professional_designations && page4Formik.touched.professional_designations
                  ? page4Formik.errors.professional_designations
                  : null
              }
            />
          </div>
        )}
      </div>
      <div className="form-group d-flex align-items-center">
        <button
          type="button"
          className="btn text-dark-50 text-hover-primary my-3 mr-2 w-100 text-center"
          onClick={goBack}
        >
          Back
        </button>
        <FormButton label="Next" color="secondary" id="kt_create_csp_submit" type="submit">
          {loading && <span className="ml-3 spinner spinner-white" />}
        </FormButton>
      </div>
    </form>
  )
}