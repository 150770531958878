import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FormInput } from "../../../components/Basic";
import { Button, Dropdown } from 'react-bootstrap';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
  withStyles
} from '@material-ui/core';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import ActionTab from "../ActionTab";

const useStyles = makeStyles({
  root: {
    width: "97%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 15,
    paddingBottom: 30
  },
  table: {
    width: "97%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
    background: "rgba(196, 196, 196, 0.15)"
  }
});

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const STAKEHOLDER_STATUS_TEXT = {
  pending: "On CSP review",
  active: "Active",
  invited: "Invited",
  awaiting_dd: "Awaiting DD",
  removed: "Removed",
  rejected: "Rejected by CSP"
};

export default function StakeholdersRoute(props) {
  const entity_id = props.match.params.entity_id;

  const classes = useStyles();

  // These three have slightly different formats. Be careful
  const [owners, setOwners] = useState({});
  const [directors, setDirectors] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [otherUsers, setOtherStaff] = useState([]);

  const [selectedCompanyUser, setSelectedCompanyUser] = useState();
  const [selectedOtherPerson, setSelectedOtherPerson] = useState({});

  const [stakeholdersLog, setStakeholdersLog] = useState([]);

  const [ownersTable, setOwnersTable] = useState([]);

  const [selectedView, setSelectedView] = useState("owners");

  const [selectedOwners, setSelectedOwners] = useState({});
  const [selectedDirectors, setSelectedDirectors] = useState({});
  const [selectedOfficers, setSelectedOfficers] = useState({});
  const [selectedOther, setSelectedOther] = useState({});

  const selectUser = (scope, id) => {
    switch (scope) {
      case "owners":
        setSelectedOwners({ ...selectedOwners, [id]: !selectedOwners[id] });
        return;

      case "directors":
        setSelectedDirectors({
          ...selectedDirectors,
          [id]: !selectedDirectors[id]
        });
        return;

      case "officers":
        setSelectedOfficers({
          ...selectedOfficers,
          [id]: !selectedOfficers[id]
        });
        return;

      case "other":
        setSelectedOther({ ...selectedOther, [id]: !selectedOther[id] });
        return;

      default:
        return;
    }
  };

  const onFormLeave = next_view => {
    setSelectedView(next_view);
    // TODO Don't recieve all the data, it's a waste
    getStakeholders();
  };

  const updateOtherUser = () => {
    Axios.put(`/company/${entity_id}/other-person`, selectedOtherPerson).then(
      response => {
        setSelectedView("other");

        // TODO @performance @refactor. We get the whole list of ALL the stakeholders + owners + log. This is very dumb
        getStakeholders();
      }
    );
  };

  const removeOthers = () => {
    const selected_persons = [];

    for (const id in selectedOther) {
      if (selectedOther[id]) selected_persons.push(id);
    }

    Axios.post(`/company/${entity_id}/remove-other-persons`, {
      selected_persons
    }).then(response => {
      // TODO @performance @refactor. We get the whole list of ALL the stakeholders + owners + log. This is very dumb
      getStakeholders();
    });
  };

  const getCompanyUsers = () => {
    Axios.get(`/company/users/${entity_id}`).then(response => {
      setCompanyUsers(response.data.users);
    });
  };

  const getStakeholders = () => {
    Axios.get(`/company/${entity_id}/stakeholders?get_owners=1`).then(
      response => {
        setOwners(response.data.owners);
        setDirectors(response.data.directors);
        setOfficers(response.data.officers);
        setOtherStaff(response.data.other_staff);
        setStakeholdersLog(response.data.stakeholders_log);

        // Total number of oustanding shares across all classes
        let company_outstanding_shares = 0;

        for (const share_id in response.data.share_classes) {
          company_outstanding_shares +=
            response.data.share_classes[share_id].share_outstanding_shares;
        }

        // Create the owners table
        const new_owners_table = [];

        for (const shareholder_id in response.data.owners) {
          const owner = response.data.owners[shareholder_id];

          const shares_held_els = [];

          // Total number of shares this user holds. Across all share classes
          let total_user_shares_held = 0;

          for (const share_id in owner.shares) {
            const shares_held = owner.shares[share_id].shares_held;
            total_user_shares_held += shares_held;

            if (response.data.share_classes[share_id]) {
              shares_held_els.push(
                <div>
                  {response.data.share_classes[share_id].share_name}:{" "}
                  {shares_held}
                </div>
              );
            }
          }

          new_owners_table.push(
            <StyledTableRow key={shareholder_id}>
              <TableCell align="center">
                <input
                  type="checkbox"
                  onChange={() => selectUser("owners", shareholder_id)}
                  checked={selectedOwners[shareholder_id]}
                />
              </TableCell>
              <TableCell align="center">
                {owner.user && owner.user.user_full_name + " (U)"}
                {owner.pc && owner.pc.pc_name + " (PC)"}
              </TableCell>
              <TableCell align="center">
                {owner.user &&
                  owner.user.emails.map(email => <div>{email}</div>)}
              </TableCell>
              <TableCell align="center">{shares_held_els}</TableCell>
              <TableCell align="center">
                {(total_user_shares_held / company_outstanding_shares) * 100}
              </TableCell>
            </StyledTableRow>
          );
        }

        setOwnersTable(new_owners_table);
      }
    );
  };

  useEffect(() => {
    getStakeholders();
    getCompanyUsers();
  }, []);

  const setView = view => {
    setSelectedCompanyUser(null);
    setSelectedView(view);
  };

  const showUserPromotionView = (stakeholder_type, user_id) => {
    setSelectedCompanyUser(user_id);
    setSelectedView(`add-${stakeholder_type}`);
  };

  // Get the selected user
  const showOtherEditView = () => {
    let person_id;

    for (const id in selectedOther) {
      if (selectedOther[id]) {
        person_id = parseInt(id, 10);
        break;
      }
    }

    const other_person = otherUsers.find(
      person => person.person_id === person_id
    );

    setSelectedOtherPerson(other_person || {});
    setSelectedView("edit-other");
  };

  const showOtherAddView = () => {
    setSelectedOtherPerson({});
    setSelectedView("add-other");
  };

  const updateOtherPersonInfo = e => {
    setSelectedOtherPerson({
      ...selectedOtherPerson,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
      <div className="d-flex my-4">
        <button
          className={`btn ${
            selectedView === "owners"
              ? "btn-secondary"
              : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("owners")}
        >
          Owners
        </button>
        <button
          className={`btn ${
            selectedView === "directors"
              ? "btn-secondary"
              : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("directors")}
        >
          Directors
        </button>
        <button
          className={`btn ${
            selectedView === "officers"
              ? "btn-secondary"
              : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("officers")}
        >
          Officers
        </button>
        <button
          className={`btn ${
            selectedView === "company_users"
              ? "btn-secondary"
              : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("company_users")}
        >
          Company users
        </button>
        <button
          className={`btn ${
            selectedView === "other"
              ? "btn-secondary"
              : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("other")}
        >
          Other
        </button>
        <button
          className={`btn ${
            selectedView === "log" ? "btn-secondary" : "btn-hover-bg-secondary"
          } btn-sm px-5 ml-2 w-25`}
          onClick={() => setSelectedView("log")}
        >
          Log
        </button>
      </div>

      {/* Add a director */}
      {selectedView === "add-director" && (
        <ActionTab
          action_type="add"
          user_type="director"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={directors}
          company_users={companyUsers}
          selected_user={selectedCompanyUser}
        />
      )}

      {/* Remove a director */}
      {selectedView === "remove-director" && (
        <ActionTab
          action_type="remove"
          user_type="director"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={directors}
          selected_stakeholders={selectedDirectors}
        />
      )}

      {/* Add an officer */}
      {selectedView === "add-officer" && (
        <ActionTab
          action_type="add"
          user_type="officer"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={directors}
          company_users={companyUsers}
          selected_user={selectedCompanyUser}
        />
      )}

      {/* Remove an officer */}
      {selectedView === "remove-officer" && (
        <ActionTab
          action_type="remove"
          user_type="officer"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={officers}
          selected_stakeholders={selectedOfficers}
        />
      )}

      {/* Add/Edit "other" */}
      {(selectedView === "add-other" || selectedView === "edit-other") && (
        <div id="edit-other" style={{ width: "50%", margin: "0 auto" }}>
          <FormInput
            w-title
            placeholder="Name"
            type="text"
            name="person_full_name"
            onChange={updateOtherPersonInfo}
            value={selectedOtherPerson.person_full_name}
          />

          <FormInput
            w-title
            placeholder="Role"
            type="text"
            name="person_role"
            onChange={updateOtherPersonInfo}
            value={selectedOtherPerson.person_role}
          />

          <FormInput
            w-title
            placeholder="Email address"
            type="email"
            name="person_email_address"
            onChange={updateOtherPersonInfo}
            value={selectedOtherPerson.person_email_address}
          />

          <FormInput
            w-title
            placeholder="Phone number"
            type="text"
            name="person_telephone_number"
            onChange={updateOtherPersonInfo}
            value={selectedOtherPerson.person_telephone_number}
          />

          <FormInput
            w-title
            placeholder="Location"
            type="text"
            name="person_location"
            onChange={updateOtherPersonInfo}
            value={selectedOtherPerson.person_location}
          />

          <div className="d-flex justify-content-between mb-3 mt-8">
            <Button onClick={() => setSelectedView("other")}>Back</Button>
            <Button color="primary" onClick={updateOtherUser}>
              Save
            </Button>
          </div>
        </div>
      )}

      {/* Remove "other" */}
      {selectedView === "remove-other" && (
        <ActionTab
          action_type="remove"
          user_type="other"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={directors}
          company_users={companyUsers}
        />
      )}

      {/* Replace a director */}
      {selectedView === "replace-director" && (
        <ActionTab
          action_type="replace"
          user_type="director"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={directors}
        />
      )}

      {/* Replace an officer */}
      {selectedView === "replace-officer" && (
        <ActionTab
          action_type="replace"
          user_type="officer"
          entity_id={entity_id}
          onLeave={onFormLeave}
          stakeholders={officers}
        />
      )}

      {/* Owners */}
      {selectedView === "owners" && (
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Shares</TableCell>
              <TableCell align="center">Ownership, %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{ownersTable}</TableBody>
        </Table>
      )}

      {/* Directors */}
      {selectedView === "directors" && (
        <div className="directors-tan">
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Issues</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {directors.map(director => (
                <StyledTableRow>
                  <TableCell align="center">
                    <input
                      type="checkbox"
                      onChange={() =>
                        selectUser("directors", director.staff_id)
                      }
                      checked={selectedDirectors[director.staff_id]}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {director.person_user
                      ? director.person_user.user_full_name
                      : director.person_pending_full_name}
                  </TableCell>
                  <TableCell align="center">
                    {director.person_user
                      ? director.person_user.emails.map(email => (
                          <div>{email}</div>
                        ))
                      : director.person_pending_email_address}
                  </TableCell>
                  <TableCell align="center">
                    {STAKEHOLDER_STATUS_TEXT[director.person_status] ||
                      director.person_status}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <Dropdown drop="right" className="mt-5">
            <Dropdown.Toggle className="btn btn-light">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setView("add-director")}>
                Add new
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setView("replace-director")}>
                Replace selected
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setView("remove-director")}>
                Remove selected
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {/* Officers */}
      {selectedView === "officers" && (
        <>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Position</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Issues</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {officers.map(owner => (
                <StyledTableRow>
                  <TableCell align="center">
                    <input
                      type="checkbox"
                      onChange={() => selectUser("officers", owner.staff_id)}
                      checked={selectedOfficers[owner.staff_id]}
                    />
                  </TableCell>
                  <TableCell>
                    {owner.person_user
                      ? owner.person_user.user_full_name
                      : owner.person_pending_full_name}
                  </TableCell>
                  <TableCell>{owner.person_position}</TableCell>
                  <TableCell>
                    {owner.person_user
                      ? owner.person_user.emails.map(email => (
                          <div>{email}</div>
                        ))
                      : owner.person_pending_email_address}
                  </TableCell>
                  <TableCell>
                    {STAKEHOLDER_STATUS_TEXT[owner.person_status] ||
                      owner.person_status}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <Dropdown drop="right" className="mt-5">
            <Dropdown.Toggle className="btn btn-light">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setView("add-officer")}>
                Add new
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setView("replace-officer")}>
                Replace selected
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setView("remove-officer")}>
                Remove selected
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}

      {/* Company users */}
      {selectedView === "company_users" && (
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Joined on</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUsers.map(user => (
              <StyledTableRow>
                <TableCell align="center">{user.user_full_name}</TableCell>
                <TableCell align="center">
                  {user.emails.map(email => (
                    <div>{email}</div>
                  ))}
                </TableCell>
                <TableCell align="center">
                  {user.became_company_user_on
                    ? new Date(user.became_company_user_on).toLocaleDateString()
                    : "Unknown"}
                </TableCell>
                <TableCell align="center">
                  <Dropdown drop="left">
                    <Dropdown.Toggle className="btn btn-icon btn-light btn-sm ui-dropdown-no-arrow">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Settings-2.svg"
                          )}
                        />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          showUserPromotionView("officer", user.user_id)
                        }
                      >
                        Promote to an Officer
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          showUserPromotionView("director", user.user_id)
                        }
                      >
                        Promote to a Director
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {/* Other users */}
      {selectedView === "other" && (
        <>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Role</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Telephone number</TableCell>
                <TableCell align="center">Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {otherUsers.map(person => (
                <StyledTableRow>
                  <TableCell align="center">
                    <input
                      type="checkbox"
                      onChange={() => selectUser("other", person.person_id)}
                      checked={selectedOther[person.person_id]}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {person.person_full_name}
                  </TableCell>
                  <TableCell align="center">{person.person_role}</TableCell>
                  <TableCell align="center">
                    {person.person_email_address}
                  </TableCell>
                  <TableCell align="center">
                    {person.person_telephone_number}
                  </TableCell>
                  <TableCell align="center">{person.person_location}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <Dropdown drop="right" className="mt-5">
            <Dropdown.Toggle className="btn btn-light">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={showOtherAddView}>Add new</Dropdown.Item>
              <Dropdown.Item onClick={showOtherEditView}>
                Edit selected
              </Dropdown.Item>
              <Dropdown.Item onClick={removeOthers}>
                Remove selected
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}

      {/* Stakeholders log */}
      {selectedView === "log" && (
        <>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Stakeholder</TableCell>
                <TableCell align="center">Action</TableCell>
                <TableCell align="center">As</TableCell>
                <TableCell align="center">Initiated by</TableCell>
                <TableCell align="center">Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stakeholdersLog.map(log_item => (
                <StyledTableRow>
                  <TableCell align="center">
                    <Link
                      to={`/user/${
                        log_item.staff_user.person_user
                          ? log_item.staff_user.person_user.user_id
                          : ""
                      }`}
                    >
                      {log_item.staff_user
                        ? log_item.staff_user.person_user
                          ? log_item.staff_user.person_user.user_full_name
                          : log_item.staff_user.person_pending_full_name
                        : log_item.staff_id}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {log_item.log_action_type}
                  </TableCell>
                  <TableCell align="center">
                    {log_item.log_as || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/user/${log_item.log_initiated_by}`}>
                      {log_item.initiator_user &&
                        log_item.initiator_user.user_full_name}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{log_item.created_at}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <Dropdown drop="right" className="mt-5">
            <Dropdown.Toggle className="btn btn-light">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setView("add-officer")}>
                Add new
              </Dropdown.Item>
              <Dropdown.Item>Replace selected</Dropdown.Item>
              <Dropdown.Item onClick={() => setView("remove-officer")}>
                Remove selected
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </div>
  );
}
