import axios from 'axios';

export async function saveService(service){
    const response = await axios.post('csp/fees-config/services', { service });
    if(response?.data){
        return response.data.service;
    }
}

export async function updateService(service){
    const response = await axios.put('csp/fees-config/services', { service });
    if(response?.data){
        return response.data.service;
    }
}

export async function deleteService(serviceId){
    const response = await axios.delete('csp/fees-config/services',
        { data: { service_id: serviceId }});
    if(response?.data){
        return response.data.service_id;
    }
}