import { IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

export default function BaseModal({ title, onSave, buttonBody, openButtonRef, children, onClose, error }) {
  const [modalOpened, setModalOpened] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeModal = (e) => {
    setModalOpened(false)
    onClose()
  }

  const onSaveClick = (e) => {
    (async () => {
      try {
        setLoading(true)
        await onSave(e);
        closeModal(e)
        // notify({type: 'SUCCESS', title: notificationTitle, message: notificationMessage})
      } catch (error) {

      } finally {
        setLoading(false)
      }

    })();
  }

  const openButtonClick = e => {
    setModalOpened(true)
    // onOpenButtonClick()
  }

  return (
    <>
      <Button className={error ? 'btn-danger' : 'btn-success'} style={{ display: openButtonRef && 'none' }} ref={openButtonRef} onClick={openButtonClick}>
        {buttonBody}
      </Button>
      <Modal
        show={modalOpened}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <IconButton onClick={closeModal}>
            <CloseIcon/>
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="btn-secondary">
            Cancel
          </Button>
          <Button disabled={loading} onClick={onSaveClick} className="d-flex align-items-center">
            {loading && (<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>)}
            {!loading && (<SaveIcon className="mr-2" />)}
            {!loading ? 'Save' : 'Saving...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}