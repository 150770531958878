import React, { useState } from 'react'

export default function useRequest(
  request = (...args) => Promise.resolve(args),
  onSuccess = (data) => Promise.resolve(data),
  onError = (error) => Promise.reject(error)
  ) {
  const [loading, setLoading] = useState(false)
  const [ended, setEnded] = useState(false)

  const handleRequest = async (...args) => {
    try {
      setLoading(true)
      const response = await request(...args)
      onSuccess(response, ...args)
    } catch (error) {
      onError(error, ...args)
    } finally {
      setLoading(false)
      setEnded(true)
    }
  }

  const apply = (...args) => {
    return handleRequest(...args)
  }

  return [apply, loading, ended]
}