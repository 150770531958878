import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../../_metronic/_partials/dropdowns';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Link } from 'react-router-dom';
import { DropdownMenu } from './DropdownMenu';

export function ProcessDashboards({ className, options }) {
  return (
    <div className={`card card-custom  rounded-lg ${className}`} style={{ height: '100%' }}>
      {/* Head */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark font-size-h3 w-50">Process Dashboards</h3>
        <div className="card-toolbar">
          <ItemDropdown item="" />
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-2">
        {options.map((item, key) => (
          <div
            className="d-flex align-items-center mb-10 border border-success rounded-lg py-4 px-6"
            style={{ borderWidth: 2 }}
            key={key}
          >
            <div className="symbol symbol-40 symbol-light-primary mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG className="h-75 align-self-end" src={toAbsoluteUrl(item.icon)} />
                </span>
              </span>
            </div>

            <div className="d-flex flex-column flex-grow-1">
              <Link to="#" className="text-dark-75 text-hover-primary font-weight-bolder font-size-h4 mb-1">
                {item.title}
              </Link>
              <span className="text-muted font-weight-bold">{item.sub}</span>
            </div>
            <div className="d-flex ml-auto align-items-center">
              <div className="text-dark-75 font-weight-bolder font-size-h2 mr-5">{item.value + item.valueType}</div>
              <div className="text-muted font-size-lg font-weight-bold">{item.status}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};