import * as Yup from 'yup';

const test = {
  selectField: {
    name: undefined,
    message: 'Invalid value selected',
    test: val => val !== '-1'
  },
  default: Yup.string()
    .required('This field is required')
    .matches(/[\w]/gi, 'Invalid value provided')
};

export const validationSchema = {
  page1: Yup.object().shape({
    former_names: Yup.string()
      .required('This field is required')
      .min(2, 'Former name is too short')
      .max(100, 'Former name is too long')
      .matches(/^[a-z0-9\., -]{2,100}$/gi, 'Invalid value provided')
  }),

  page2: Yup.object().shape({
    government_organisation_country: Yup.string().when('is_government_organisation', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .test(test.selectField)
    }),

    ex_a_stock_exchange: Yup.string().when('is_ex_a', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .test(test.selectField)
    }),

    ex_a_jurisdiction: Yup.string().when('is_ex_a', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .test(test.selectField)
    }),

    ex_a_ticker: Yup.string().when('is_ex_a', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .min(2, 'Field value is too short')
        .max(20, 'Field value is too long')
        .matches(/^[a-z]{2,10}$/gi, 'Invalid value provided')
    }),

    ex_b_country: Yup.string().when('is_ex_b', {
      is: true,
      then: Yup.string()
        .required('This field is required')
        .test(test.selectField)
    }),

    ex_b_regulator: Yup.string().when('is_ex_b', {
      is: true,
      then: test.default
    }),

    ex_b_regulatory_activity: Yup.string().when('is_ex_b', {
      is: true,
      then: test.default
    }),

    ex_b_registration_number: Yup.string().when('is_ex_b', {
      is: true,
      then: test.default
    }),

    ex_c_manager_name: Yup.string().when('is_ex_c', {
      is: true,
      then: test.default
    }),

    ex_f_licensee_name: Yup.string().when('is_ex_f', {
      is: true,
      then: test.default
    })
  }),

  page3: Yup.object().shape({
    purpose: Yup.string()
      .required('This field is required')
      .test(test.selectField)
  })
};
