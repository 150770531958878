import { useEffect, useState } from "react";
import useRequest from "../components/useRequest/useRequest";

export default function useTitlesWithRelations(current, relationsRequest = () => Promise.resolve(), titlesRequest = () => Promise.resolve()) {
  const [options, setOptions] = useState([])
  const [relations, setRelations] = useState([])
  const [titles, setTitles] = useState([])

  const [fetchRelations] = useRequest(relationsRequest, (data) => {
    setRelations(data)
  })

  const [fetchTitles] = useRequest(titlesRequest, (data) => {
    setTitles(data)
  })

  useEffect(() => {
    if (current && relations[current] && titles)
    setOptions(
      relations[current].map(key => ({
        value: key,
        title: titles[key]
      }))
    )
  }, [current, relations, titles])

  useEffect(() => {
    fetchRelations()
    fetchTitles()
  }, [])

  return [options]
}