import React, { useEffect, useState } from 'react'
import useRequest from '../components/useRequest/useRequest'

export default function useColoredTitles(getColoredTitles = () => Promise.resolve()) {
  const [options, setOptions] = useState([])
  const [request] = useRequest(getColoredTitles, (values) => {
    setOptions(
      Object.keys(values[0]).map(key => ({
        value: key,
        title: values[0][key],
        color: values[1][key]
      }))
    )
  })

  useEffect(() => {
    request()
  }, [])

  return [options]
}