import React, { useEffect, useState } from "react";
import { Table } from "../../../modules/Auth/pages/customers/customers-table/CustomersTable";

const logCols = [
  {
    id: 1,
    name: "_stakeholder",
    title: "Stakeholder",
    width: 600
  },
  {
    id: 2,
    name: "log_action_type",
    title: "Action",
    width: 300
  },
  {
    id: 3,
    name: "log_as",
    title: "As",
    width: 300
  },
  {
    id: 4,
    name: "_initiator",
    title: "Initiated by",
    width: 300
  },
  {
    id: 5,
    name: "_date",
    title: "Date",
    width: 300,
    is_date: true
  }
];

export default function LogRoute(props) {
  const [currentCompanyId, setCurrentCompanyId] = useState();

  useEffect(() => {
    if (
      props.basicEntityInfo.company_id &&
      props.basicEntityInfo.company_id !== currentCompanyId
    ) {
      props.queryAdditionalInfo("stakeholders");
      setCurrentCompanyId(props.basicEntityInfo.company_id);
    }
  }, [props.basicEntityInfo.company_id]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{ fontSize: "1.5rem" }} className="fw-bolder m-0">
                Stakeholders Log
              </div>
            </div>
          </div>

          <div className="card-body">
            {props.additionalEntityInfo &&
            props.additionalEntityInfo.stakeholders ? (
              <Table
                nonSelectable
                setEntities={() => void 0}
                entities={
                  props.additionalEntityInfo.stakeholders.stakeholders_log
                }
                cols={logCols}
              />
            ) : (
              "Loading the data..."
            )}
          </div>
        </div>
      </div>
    </>
  );
}
