import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { notify } from "../../../../redux/NotificationSnackbar/snackbarActions";
import { Link } from "react-router-dom";
import {FeesTableNext} from "../../../components/FeesTable";
import SaveIcon from '@material-ui/icons/Save';
import { useSelector } from "react-redux";



function FeesRoute() {
  const [newOtherFeesConfig, setNewOtherFeesConfig] = useState([]);
  const [clientCanViewFullInfo, setClientCanViewFullInfo] = useState(false);
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.auth.user)
  const isCSPUser = !!user.user_csp_role || !!user.global_user_role

  const params = useParams();

  const setOtherFeeNext = (fieldName, value, index) => {
    setNewOtherFeesConfig(prevState => {
      prevState[index][fieldName] = value
      return [...prevState]
    })
  }

  const getFeeConfig = () => {
    return Axios.get(`csp/fees-config?company_id=${ params.entity_id }`)
    .then(response => {
      if(response.data) {
        setClientCanViewFullInfo(response.data.client_can_view_full_info);
        setNewOtherFeesConfig(response.data.other_fees);
      }
    })
    .catch(() => {
    })
  }

  const saveCustomFees = () => {
    const custom_fees = {};

    for(const fee of newOtherFeesConfig) {
      if(fee.is_company_specific) {
        if(!fee.fee_price_company_specific) {
          notify({type: "ERROR", title: "Price not privided", message: `Please, provide a company-specific price for '${ fee.fee_title }' fee.`});
          return;
        }

        custom_fees[fee.fee_id] = fee.fee_price_company_specific;
      }
    }
    setLoading(true)
    return Axios.post(`company/fees-config/${ params.entity_id }`, {
      custom_fees
    })
    .then(_ => {
      return getFeeConfig()
    })
    .then(_ => {
      notify({title: 'Success!', type: 'SUCCESS', message: 'Fee config saved'})
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    })
    .catch(() => {
    })
    .finally(() => {
      setLoading(false)
    })
  }

  // On mount
  useEffect(() => {
    getFeeConfig();
  }, []);

  return (
    <div className="col-lg-12">
      <div className="card card-custom mb-5 mb-xl-10">
        <div className="card-header d-flex align-items-center justify-content-between border-0 pt-5">
          <div>
            <div className="card-title w-50 ml-0 align-items-start flex-column">
              <div style={{fontSize: "1.5rem"}} className="fw-bolder m-0">Company fees table</div>
            </div>
            <Link to={`/csp-config/fees-config`} className="navi-item px-8 cursor-pointer">
            <div className="link bg-light-hover text-black-50">
              Configure specific fee prices for this particular Entity. Remember that the fee has to be enabled in the CSP first.
            </div>
            </Link>
          </div>
        </div>

        <div className="card-body p-9">
          {/* <Table cols={columns} idField="fee_id" entities={newOtherFeesConfig}/> */}
          {/* <Table /> */}
          <div className="col-lg-12">
            <FeesTableNext
              isCSPUser={isCSPUser} 
              fees={ newOtherFeesConfig }
              onFeeUpdate={ setOtherFeeNext }
              isCompanySpecific
              clientCanViewFullInfo={ clientCanViewFullInfo }
            />
          </div>
        
          <div className="card-footer d-flex justify-content-end">

            <button
              onClick={ saveCustomFees }
              className="btn btn-primary"
              disabled={loading}
            >
              {loading && (<span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/>)}
              {!loading && (<SaveIcon className="mr-2" />)}
              {!loading ? 'Save' : 'Saving...'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeesRoute;
