import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../../components/Basic";
import { Avatar } from "@material-ui/core";

const avatarStyle = {
  width: 120,
  height: 120
}

export default function UserProfileHeader(props) {
  const history = useHistory()

  const [sendModalShown, setSendModalShown] = useState(false);

  const sendMessage = () => {
    /* Axios post*/
    setSendModalShown(false);
  };

  const setConfigField = () => {
  };

  let dd_status;
  let dd_risk_rating = "N/A";
  let country = "N/A";

  if(!props.basicUserInfo.dd) dd_status = "Not completed";
  else if(!props.basicUserInfo.dd_csp_decision) dd_status = "Completed, unchecked";
  else if(props.basicUserInfo.dd_csp_decision) {
    dd_status = "Completed, checked";
    dd_risk_rating = props.basicUserInfo.dd_csp_decision.risk_category
  }
  else dd_status = "Unknown"

  if(props.basicUserInfo.dd) {
    country = props.basicUserInfo.dd.physical_address_country || "N/A";
  }


  return (
    <>
      <div className="card card-custom gutter-b">
        <Modal show={sendModalShown} onHide={() => setSendModalShown(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Send a Message</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="field">
              <FormInput
                w-title="Send a message"
                placeholder="message"
                name="message"
                onChange={setConfigField}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSendModalShown(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={sendMessage}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="card-body">
          <div className="d-flex mb-9">
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <Avatar
                  style={avatarStyle}
                  variant='rounded'
                  src={props.basicUserInfo?.avatar}
                  alt="image"
                />
              </div>
              <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                <span className="font-size-h3 symbol-label font-weight-boldest">
                  User Profile
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between flex-wrap mt-1">
                <div className="d-flex align-items-center mr-3">
                  <div
                    className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >
                    { props.basicUserInfo.user_full_name || "..." }
                  </div>
                  <div
                    className="btn-sm btn-outline-success font-weight-bold mr-2"
                  >
                    <i className="flaticon2-check-mark icon-sm mr-2" />
                    { props.basicUserInfo.user_id ? dd_status : "..." }
                  </div>
                  <div
                    className="btn-sm btn-outline-primary font-weight-bold mr-2"
                  >
                    <i className="flaticon2-check-mark icon-sm mr-2" />
                    { dd_risk_rating }
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between mt-1">
                <div className="d-flex flex-column flex-grow-1 pr-8">
                  <div className="d-flex flex-wrap mb-4">
                    <span
                      href="#"
                      className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    >
                      <i className="flaticon2-new-email mr-2 font-size-lg" />
                      { props.basicUserInfo.emails ? props.basicUserInfo.emails[0] : "N/A" }
                    </span>
                    <span
                      href="#"
                      className="text-dark-50 text-hover-primary font-weight-bold"
                    >
                      <i className="flaticon2-placeholder mr-2 font-size-lg" />
                      { country }
                    </span>
                  </div>
                  {/* <span className="font-weight-bold text-dark-50">
                    @placeholder should we allow setting bios for users? 
                  </span> */}
                </div>
                {/* <div className="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                  <span className="font-weight-bold text-dark-75">
                    Progress
                  </span>
                  <div className="progress progress-xs mx-3 w-100">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "63%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <span className="font-weight-bolder text-dark">78%</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="separator separator-solid" />
          <div className="d-flex align-items-center flex-wrap mt-8">
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-chat-1 display-4 text-muted font-weight-bold" />
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Email</span>
                { props.basicUserInfo.emails ? props.basicUserInfo.emails[0] : "N/A" }
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-confetti display-4 text-muted font-weight-bold" />
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  Citizenship
                </span>
                { country }
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-pie-chart display-4 text-muted font-weight-bold" />
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  Registered
                </span>
                { props.basicUserInfo.created_at
                ? moment(props.basicUserInfo.created_at).format("DD/MMM/yyyy")
                : "N/A" }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
